import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject, Subscription } from 'rxjs';
import { ProgressService } from '../../progress.service';
import { AuthenticationService } from 'authentication';
import { I9RemediationService } from '../../i9-remediation.service';
import { MatTableDataSource } from '@angular/material/table';
import { PageEvent } from '@angular/material/paginator';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { SendReportMailComponent } from '../send-report-mail/send-report-mail.component';
import { SpreadsheetGuideComponent } from '../spreadsheet-guide/spreadsheet-guide.component';
import { FolderStructureGuideComponent } from '../folder-structure-guide/folder-structure-guide.component';
import { ToastrService } from 'ngx-toastr';
import { SelectionModel } from '@angular/cdk/collections';
import { ConfirmationModalComponent } from 'dirty-check-store';
import { LcaService } from 'src/app/services/lca.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-initiate-remediation',
  templateUrl: './initiate-remediation.component.html',
  styleUrls: ['./initiate-remediation.component.scss']
})
export class InitiateRemediationComponent implements OnInit {


  displayedColumns: string[] = ['iterations', 'dateTime', 'noOfEmployees', 'compliant', 'nonCompliant'];
  dataSource: MatTableDataSource<any>;

  pageSize = 25; // default
  pageIndex = 0; // default
  totalElementCount = 0;

  companyId;
  userId;
  documentUpload = true;
  documentParse = false;
  documentAnalyze = false;
  documentDetailsForm: FormGroup;

  progress$: Observable<number>;

  remediationStatus = '';
  remediationData;
  canAnalyze = false;
  remediationIterationId: number;
  remediationRunId: number;
  startAnalyzingAfterErrorFix;
  listOfClientCompanies: any[];
  selectedCompanyId: number;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private progressService: ProgressService,
    private i9RemediationService: I9RemediationService,
    private authenticationService: AuthenticationService,
    public dialog: MatDialog,
    private toastrService: ToastrService,
    private activeRoute: ActivatedRoute,
    private lcaApis: LcaService
  ) { }

  ngOnInit(): void {
    this.selectedCompanyId=0;
    this.getCompanyList();
    this.activeRoute.queryParams
      .subscribe(params => {
        this.startAnalyzingAfterErrorFix = params.startParsing;
      })
    this.companyId = parseInt(sessionStorage.getItem('companyId'));
    this.selectedCompanyId = this.companyId;
    this.userId = parseInt(sessionStorage.getItem('userId'));
    this.documentDetailsForm = this.fb.group({
      folderPath: ['', Validators.required],
      sheet: [null],
      toggle: [false],
      username: [{ value: '', disabled: true }, Validators.required],
      password: [{ value: '', disabled: true }, Validators.required]
    });

    this.getRemediationStatus();

    this.documentDetailsForm.get('toggle').valueChanges.subscribe(toggle => {
      if (toggle) {
        this.documentDetailsForm.get('username').enable();
        this.documentDetailsForm.get('password').enable();
      } else {
        this.documentDetailsForm.get('username').disable();
        this.documentDetailsForm.get('password').disable();
      }
    });
    this.progress$ = this.progressService.progress$;
  }

  getRemediationStatus() {
      this.i9RemediationService.getRemediationStatus(this.selectedCompanyId).subscribe(data => {
        if (data) {
          this.remediationData = data;
          this.remediationStatus = data.status;
          sessionStorage.setItem('remediationIterationId', data.remediationIterationId.toString());
          sessionStorage.setItem('remediationRunId', data.remediationRunId.toString());
          if (this.remediationData.details) {
            this.dataSource = new MatTableDataSource(this.remediationData.details);
            this.totalElementCount = this.remediationData.details.length;
          }
          this.assignStatuses();
          if (this.remediationStatus != '' && this.remediationStatus != null) {
            this.assignSheetData();
          }
          if(this.startAnalyzingAfterErrorFix) {
            this.router.navigate([], {
              queryParams: {
                'startParsing': null,
              },
              queryParamsHandling: 'merge'
            })
            this.analyzeRemediation();
          }
        } else {
          this.remediationStatus = '';
          this.assignStatuses();
        }
      },
      (error)=> {
        this.toastrService.error('Backend error');
      });
  }

  assignSheetData() {
    this.documentDetailsForm.patchValue( {
      folderPath: this.remediationData.drivePath,
      sheet: this.remediationData.spreadSheetName,
      toggle: this.remediationData.userName ? true : false,
      username: this.remediationData.userName,
      password: this.remediationData.password
    });
  }

  // getRemediationData() {
  //   this.i9RemediationService.getRemediationData().subscribe(data => {
  //     console.log(data);
  //     this.dataSource = new MatTableDataSource(data);
  //     this.totalElementCount = data.length;
  //   }, (error)=> {
  //     console.log(error);
  //     this.toastrService.error('Backend error');
  //   });
  // }

  backToDashboard() {
    this.router.navigate(['petitioner-landing/dashboard/petitioner-dashboard']);
  }

  onSubmit(): void {
    if (this.documentDetailsForm.valid) {
      console.log(this.documentDetailsForm.get('sheet').value);
    }

    const payload = `{
      "companyId": "${this.selectedCompanyId}",
      "drivePath": "${this.documentDetailsForm.get('folderPath').value}",
      "userName": "${this.documentDetailsForm.get('username').value}",
      "password": "${this.documentDetailsForm.get('password').value}",
      "userId": "${this.userId}",
      "status": "${this.remediationStatus == null ? '' : this.remediationStatus}",
      "spreadSheetName": "${this.documentDetailsForm.get('sheet').value.name}"
    }`;

    let fileData = new FormData();
    fileData.append('file', this.documentDetailsForm.get('sheet').value); 
    fileData.append('stringObject', payload); 
    

    this.i9RemediationService.saveDocumentAndSheetDetails(fileData).subscribe((data: any) => {
      this.toastrService.success(data.message);
      this.documentParse = true;
      this.getRemediationStatus();
    }, (error)=> {
      this.toastrService.error('Backend error');
    });

  }
  
  fixErrors() {
    const queryParamsObj = {};
    this.router.navigate(['../parsing-report-list'], { queryParams: queryParamsObj, relativeTo: this.route });
  }

  initiateRemediation() {
    this.i9RemediationService.initiateRemediation(this.companyId, this.remediationData.iterationNumber).subscribe(data => {
      this.toastrService.success(data.message);
      this.getRemediationStatus();
    },
    (error)=> {
      this.toastrService.error('Backend error');
    });
  }

  analyzeRemediation() {
    this.i9RemediationService.analyzeGapReport(this.companyId, this.remediationData.remediationIterationId, this.remediationData.remediationRunId).subscribe(data => {
      this.toastrService.success(data.message);
      this.getRemediationStatus();
    },
    (error)=> {
      this.toastrService.error('Backend error');
    });
  }

  startAnalyzing() {
    this.documentUpload = true;
    this.documentParse = true;
    this.documentAnalyze = true;
  }

  assignStatuses() {
    if (this.remediationStatus == '' || this.remediationStatus == null) {
      this.documentUpload = true;
      this.documentParse = false;
      this.documentAnalyze = false;
    } else if (this.remediationStatus == 'INITIATED' || this.remediationStatus == 'PARSING' || this.remediationStatus == 'PARSINGINPRGS' || this.remediationStatus == 'PARSINGCOMPLETED') {
      this.documentUpload = true;
      this.documentParse = true;
      this.documentAnalyze = false;
      if (this.remediationStatus == 'PARSINGCOMPLETED' && !this.remediationData.parsingErrors) {
        this.canAnalyze = true;
        this.documentAnalyze = true;
      }
    } else if (this.remediationStatus == 'ANALYSING' || this.remediationStatus == 'COMPLETED') {
      this.documentUpload = true;
      this.documentParse = true;
      this.documentAnalyze = true;
    } else {
      this.documentUpload = true;
      this.documentParse = false;
      this.documentAnalyze = false;
    }
  }

  paginationEvent(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
  }

  viewReportPdf() {
    const docPath = this.remediationData.docPath.substring(23);
    window.open(`${environment.docs}${docPath}`, '_blank');
  }

  sendReportToEmail() {
    this.dialog.open(SendReportMailComponent, {
      width: '680px',
      data: {
        reportPath: this.remediationData.docPath
      }
    }).afterClosed().subscribe(response => {
      if (response == true) {
        console.log('Sent')
      }
    });
  }

  endRemediation() {
    this.dialog.open(ConfirmationModalComponent, {
      width: '400px',
      data: {
        update: {
          title: 'End Remediation',
          message: 'Are you sure you want to end the remediation process?'
        },
        // buttons: ['No, Stay on the Page', 'Yes, Leave the Page']
        buttons: ['Cancel', 'End Now'],
      }
    }).afterClosed().subscribe(confirmed => {
      if (confirmed) {
        this.i9RemediationService.endRemediation(this.companyId).subscribe(data => {
          this.toastrService.success(data.message);
          this.router.navigate(['../manage-i9-remediations'], {relativeTo: this.route});
        },
        (error)=> {
          this.toastrService.error('Backend error');
        });
      }
    });
  }

  saveCompliantData() {
    this.i9RemediationService.saveCompliantData(this.companyId, this.remediationData.remediationRunId, this.remediationData.remediationIterationId).subscribe((data: any) => {
      this.toastrService.success(data.message);
    }, (error)=> {
      this.toastrService.error('Backend error');
    });
  }

  openGuideSpreadsheet() {
    this.dialog.open(SpreadsheetGuideComponent, {
      width: '680px',
    });
  }

  openGuideFolderStructure() {
    this.dialog.open(FolderStructureGuideComponent, {
      width: '680px',
    });
  }

  getCompanyList() {
    const companyId = sessionStorage.getItem('companyId');
    if (!companyId) return;
    this.lcaApis.companyHierarchy(parseInt(companyId)).pipe(take(1)).subscribe(data => {
      this.listOfClientCompanies = data.map((el) => {
        return { 'companyId': el['id'], 'companyName': el['companyName'] };
      });
      if (this.listOfClientCompanies.length) {
        this.selectedCompanyId = this.listOfClientCompanies[0].companyId;
       // this.documentDetailsForm.get('petitionerCompany').setValue(this.selectedCompanyId);
      }
    });
  }  
  
}
