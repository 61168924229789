import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ImagilityBaseResponse, PrimaryData, TaskStep } from 'app-models';
import { DirtyFormGuard, MarkCleanFormAction } from 'dirty-check-store';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { StepStatusUpdatePayloadService } from 'step-status-update-payload-service';
import { getStepDetails, getStepStatusUpdate, updateStepStatus, updateStepStatusToInitialState } from 'visa-store';

@Component({
  selector: 'app-vd-applicant-details',
  templateUrl: './vd-applicant-details.component.html',
  styleUrls: ['./vd-applicant-details.component.scss']
})
export class VdApplicantDetailsComponent implements OnInit, OnDestroy {
  @ViewChild('mainBody', { static: true }) mainBody: ElementRef;
  selectedItem: string;
  isLastTab = false;
  primaryData: PrimaryData;
  sectionCode: string;
  observableSubscription$ = new Subject();
  stepDetails: TaskStep;
  typeOfButton: string;
  tabList: {
    name: string;
    code: string;
    isLastTab: boolean;
  }[];
  userId: number;
  applicantsList: any = [];
  type = "family";
  lastTabObj: any;
  activeIndex = 0;

  constructor(
    private dynamicDialogRef: DynamicDialogRef,
    public dynamicDialogConfig: DynamicDialogConfig,
    private store: Store<any>,
    private stepStatusUpdatePayloadCostruction: StepStatusUpdatePayloadService,
    private guard: DirtyFormGuard) {
    this.primaryData = this.dynamicDialogConfig.data;
  }

  ngOnInit(): void {
    // console.log("$$$$ primary data",this.primaryData);
    const jsonInput = this.primaryData && this.primaryData.jsonInput ? this.primaryData.jsonInput : null;
    this.store.dispatch(new MarkCleanFormAction({
      dirty: false
    }));
    this.store.pipe(select(getStepStatusUpdate))
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe((response: ImagilityBaseResponse) => {
        if (response && response.status === 200) {
          this.sectionCode = null;
          setTimeout(() => {
            this.sectionCode = this.primaryData.layoutCode;
          }, 0);
        }
      });
    this.store.pipe(select(getStepDetails, { id: this.primaryData.stepId }))
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe((data: TaskStep) => {
        this.stepDetails = data;
        this.typeOfButton = this.stepDetails.isPetitionerAction === 1 || this.stepDetails.isAttorneyAction === 1 || this.stepDetails.isBeneficiaryAction === 1 ? 'MARK_COMPLETE' : 'MARK_SUBMIT';
      });
    //   console.log(jsonInput.applicantsList);
    if (jsonInput?.applicantsList?.length > 0) {
      jsonInput.applicantsList.forEach(applicant => {
        //  if(this.stepDetails.stepActionList[0].layoutCode === "SECONDARY_APPLICANT_DETAILS"){
        //   if(applicant.isPrimaryApplicant == false){
        //     this.applicantsList.push(applicant);
        //   }
        // }else{
        //   if(applicant.isPrimaryApplicant == true){
        //     this.applicantsList.push(applicant);
        //   }
        // }
        if (this.stepDetails.stepApplTo === 'SECAPPLCNT') {
          if (applicant.isPrimaryApplicant === false) {
            this.applicantsList.push(applicant);
          }
        } else if (this.stepDetails.stepApplTo === 'PRIMAPPLCNT') {
          if (applicant.isPrimaryApplicant === true || applicant.primaryApplicant === true) {
            this.applicantsList.push(applicant);
          }
        } else if (this.stepDetails.stepApplTo === 'SPOUSE') {
          if (applicant.isPrimaryApplicant === false && applicant.relationshipCode === 'SPOUSE') {
            this.applicantsList.push(applicant);
          }
        } else if (this.stepDetails.stepApplTo === 'CHILD') {
          if (applicant.isPrimaryApplicant === false && applicant.relationshipCode === 'CHILD') {
            this.applicantsList.push(applicant);
          }
        }
      });
      // if(this.primaryData.immigrationClassification === 'ASYLRELATIVE'){
      //   this.applicantsList = jsonInput.applicantsList;
      // } 
      if (this.stepDetails.stepApplTo === 'PRIMAPPLCNT' && this.primaryData.immigrationClassification === 'AFFDVTSUPT864' && jsonInput?.primaryApplicant) {
        this.applicantsList.push(jsonInput?.primaryApplicant);
      }
    } else {
      if (this.stepDetails.stepApplTo === 'PRIMAPPLCNT' && jsonInput?.primaryApplicant) {
        this.applicantsList.push(jsonInput?.primaryApplicant);
      } else {
        this.applicantsList = [];
      }
    }
    //  console.log("this.applicantsList",this.applicantsList);
    let additionalConfig = (this.stepDetails.additionalConfig) ? JSON.parse(this.stepDetails.additionalConfig) : null
    if (additionalConfig?.applicantTabs) {
      console.log('here');
      this.tabList = additionalConfig.applicantTabs.filter(x => x.showTab == true);
      this.lastTabObj = this.tabList.find(item => item.isLastTab === true);
      this.primaryData.reqDocType = additionalConfig?.docType ? additionalConfig?.docType : null;
      if (this.tabList.length === 1) {
        this.handleLeftPanelSelection(this.tabList[0].code, true);
      } else {
        this.handleLeftPanelSelection(this.tabList[0].code, false);
      }
    } else {
      this.buildTabsForOldVisas();
    }


    //this.userId = this.primaryData.familyId;

  }

  buildTabsForOldVisas() {
    if (['SECONDARY_APPLICANT_DETAILS', 'BEN_SECONDARY_APPLICANT_DETAILS', 'SPOUSE_DETAIL', 'CHILD_DETAIL', 'BEN_SPOUSE_DETAIL', 'BEN_CHILD_DETAIL'].includes(this.stepDetails.stepActionList[0].layoutCode)) {
      if (this.primaryData.getTaskType === 'I485') {
        this.tabList = [{
          name: 'Personal Details',
          code: 'PERSONAL_DETAILS',
          isLastTab: false
        }, {
          name: 'Address History',
          code: 'ADDR_HISTORY',
          isLastTab: false
        }, {
          name: 'Employment History',
          code: 'EMP_INFO',
          isLastTab: false
        }, {
          name: 'Immigration History',
          code: 'IMM_INFO',
          isLastTab: false
        }, {
          name: 'Parent Information',
          code: 'PARENT_INFO',
          isLastTab: false
        }, {
          name: 'Marital History',
          code: 'MARITAL_HISTORY',
          isLastTab: false
        },
        {
          name: 'Required Documents',
          code: 'DOC_INFO',
          isLastTab: true
        }];
      } else if (this.primaryData.immigrationClassification === 'REMVCONDTRES') {
        this.tabList = [{
          name: 'Personal Details',
          code: 'PERSONAL_DETAILS',
          isLastTab: false
        },
        {
          name: 'Required Documents',
          code: 'DOC_INFO',
          isLastTab: true
        }];


      } else if (['AFFDVTSUPT864'].includes(this.primaryData.immigrationClassification)) {
        this.tabList = [{
          name: 'Personal Details',
          code: 'PERSONAL_DETAILS',
          isLastTab: false
        },
        {
          name: 'Required Documents',
          code: 'DOC_INFO',
          isLastTab: true
        }];

      } else if (['SUSPDPRT881'].includes(this.primaryData.immigrationClassification)) {
        this.tabList = [{
          name: 'Personal Details',
          code: 'PERSONAL_DETAILS',
          isLastTab: false
        },
        {
          name: 'Address History',
          code: 'ADDR_HISTORY',
          isLastTab: false
        }, {
          name: 'Immigration History',
          code: 'IMM_INFO',
          isLastTab: false
        },
        {
          name: 'Required Documents',
          code: 'DOC_INFO',
          isLastTab: true
        }];

      } else {
        this.tabList = [{
          name: 'Personal Details',
          code: 'PERSONAL_DETAILS',
          isLastTab: false
        }, {
          name: 'Immigration History',
          code: 'IMM_INFO',
          isLastTab: false
        }, {
          name: 'Required Documents',
          code: 'DOC_INFO',
          isLastTab: true
        }];
      }
    } else {
      if (this.primaryData.getTaskType === 'I485') {
        this.tabList = [{
          name: 'Personal Details',
          code: 'PERSONAL_DETAILS',
          isLastTab: false
        }, {
          name: 'Address History',
          code: 'ADDR_HISTORY',
          isLastTab: false
        }, {
          name: 'Employment History',
          code: 'EMP_INFO',
          isLastTab: false
        }, {
          name: 'Immigration History',
          code: 'IMM_INFO',
          isLastTab: false
        }, {
          name: 'Parent Information',
          code: 'PARENT_INFO',
          isLastTab: false
        }, {
          name: 'Marital History',
          code: 'MARITAL_HISTORY',
          isLastTab: false
        }, {
          name: 'Required Documents',
          code: 'DOC_INFO',
          isLastTab: true
        }];
      }
      else if (['REMVCONDTRES', 'WVRINADMI601'].includes(this.primaryData.immigrationClassification)) {
        this.tabList = [{
          name: 'Personal Details',
          code: 'PERSONAL_DETAILS',
          isLastTab: false
        },

        {
          name: 'Required Documents',
          code: 'DOC_INFO',
          isLastTab: true
        }];

      } else if (['WVRUNLAWPRSNCI601A'].includes(this.primaryData.immigrationClassification)) {
        this.tabList = [{
          name: 'Personal Details',
          code: 'PERSONAL_DETAILS',
          isLastTab: false
        },
        {
          name: 'Parent Information',
          code: 'PARENT_INFO',
          isLastTab: false
        },
        {
          name: 'Required Documents',
          code: 'DOC_INFO',
          isLastTab: true
        }];

      } else if (['AFFDVTSUPT864'].includes(this.primaryData.immigrationClassification)) {
        this.tabList = [{
          name: 'Personal Details',
          code: 'PERSONAL_DETAILS',
          isLastTab: false
        },
        {
          name: 'Immigration History',
          code: 'IMM_INFO',
          isLastTab: false
        },
        {
          name: 'Required Documents',
          code: 'DOC_INFO',
          isLastTab: true
        }];

      } else if (['ASYLRELATIVE'].includes(this.primaryData.immigrationClassification)) {
        this.tabList = [{
          name: 'Personal Details',
          code: 'PERSONAL_DETAILS',
          isLastTab: false
        },
        {
          name: 'Immigration History',
          code: 'IMM_INFO',
          isLastTab: false
        },
        {
          name: 'Marital History',
          code: 'MARITAL_HISTORY',
          isLastTab: false
        },
        {
          name: 'Required Documents',
          code: 'DOC_INFO',
          isLastTab: true
        }];

      } else {
        this.tabList = [{
          name: 'Personal Details',
          code: 'PERSONAL_DETAILS',
          isLastTab: false
        }, {
          name: 'Immigration History',
          code: 'IMM_INFO',
          isLastTab: false
        }, {
          name: 'Required Documents',
          code: 'DOC_INFO',
          isLastTab: false
        },
        {
          name: 'Parent Information',
          code: 'PARENT_INFO',
          isLastTab: true
        }
        ];
      }
    }

    this.lastTabObj = this.tabList.find(item => item.isLastTab === true);
    if (this.tabList.length === 1) {
      this.handleLeftPanelSelection('PERSONAL_DETAILS', true);
    } else {
      this.handleLeftPanelSelection('PERSONAL_DETAILS', false);
    }
  }

  handleClose() {
    this.guard.canDeactivate().pipe(first()).subscribe((response: boolean) => {
      if (response) {
        this.store.dispatch(new MarkCleanFormAction({
          dirty: false
        }));
        this.dynamicDialogRef.close();
      }
      else {
        return false;
      }
    });
  }

  onScrollTop() {
    this.mainBody.nativeElement.scroll(0, 0);
  }

  handleLeftPanelSelection(selectedTab, isLastTab) {
    this.guard.canDeactivate().pipe(first()).subscribe((response: boolean) => {
      if (response) {
        this.store.dispatch(new MarkCleanFormAction({
          dirty: false
        }));
        this.isLastTab = isLastTab;
        this.selectedItem = selectedTab;

      } else {
        return false;
      }
    });
  }

  updateStepStatus(status) {
    this.store.dispatch(updateStepStatus({
      payload: this.stepStatusUpdatePayloadCostruction.payloadConstruction(this.stepDetails, status),
      subTaskId: this.stepDetails.taskId,
      stepId: this.dynamicDialogConfig.data.stepId,
      visaType: this.dynamicDialogConfig.data.visatype
    }));
  }

  handleTabChange(e) {
    console.log('Here', this.activeIndex);
    console.log(e);
  }

  ngOnDestroy() {
    this.observableSubscription$.next();
    this.observableSubscription$.complete();
    // Update the Step Status state to empty state
    this.store.dispatch(updateStepStatusToInitialState());
  }

}
