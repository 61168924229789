<div class="container-fluid">
    <div class="row pl--1rem8  d-flex align-items-center">
        <div class="col-md-8 pb-2">
            <small class="link" (click)="backToDashboard()">
                Home > I9 Remediations > Initiate Remediation</small>
            <div class="page-header">
                <h2>Initiate Remediation</h2>
            </div>
        </div>
    </div>
    <div class="bgWhite noMargin w100 position-relative">
        <div (click)="getRemediationStatus()" class="refresh-container">
            <i class="fa fa-refresh" aria-hidden="true"></i>
            <span>Refresh Status</span>
        </div>
        <div class="mat-elevation-z8 mt-2 row p-0">
            <div class="col-md-3 p-4 steps-container">
                <div class="title">Remediation in 3 easy steps</div>
                <div [ngClass]="{'fade-out': !documentUpload, 'active-step': documentUpload && !documentParse}" class="each-step-container"> 
                    <span class="circle each-step-number">
                        <span *ngIf="!documentParse" [ngClass]="{'download-complete': documentUpload}">1</span>
                        <span class="step-completed" *ngIf="documentUpload && documentParse">&#10003;</span>
                    </span>
                    <div class="download-container">
                        <p class="step-title">Document Upload</p>
                        <div class="step-points">
                            <p class="each-step-point">
                                <i class="fa fa-arrow-right"></i>
                                <span>Choose a drive and upload supporting document for each employee</span>
                            </p>
                            <p class="each-step-point">
                                <i class="fa fa-arrow-right"></i>
                                <span>Upload spreadsheet with details of each employee</span>
                            </p>
                        </div>
                    </div>
                    <mat-divider role="separator" class="mat-divider mat-divider-horizontal"
                        aria-orientation="horizontal"></mat-divider>
                </div>
                <div [ngClass]="{'fade-out': !documentParse, 'active-step': documentParse && !documentAnalyze}" class="each-step-container"> 
                    <span class="circle each-step-number">
                        <span *ngIf="!documentAnalyze" [ngClass]="{'download-complete': documentParse}">2</span>
                        <span class="step-completed" *ngIf="documentParse && documentAnalyze">&#10003;</span>
                    </span>
                    <div class="download-container">
                        <p class="step-title">Parse Uploaded Documents</p>
                        <div class="step-points">
                            <p class="each-step-point">
                                <i class="fa fa-arrow-right"></i>
                                <span>Once you click 'Parse', documents are uploaded and the system will parse to check for errors</span>
                            </p>
                        </div>
                    </div>
                    <mat-divider role="separator" class="mat-divider mat-divider-horizontal"
                        aria-orientation="horizontal"></mat-divider>
                </div>
                <div [ngClass]="{'fade-out': !documentAnalyze, 'active-step': documentAnalyze}" class="each-step-container"> 
                    <span class="circle each-step-number">
                        <span [ngClass]="{'download-complete': documentAnalyze}">3</span>
                        <span class="step-completed" *ngIf="documentParse && documentAnalyze && remediationStatus == 'COMPLETED'">&#10003;</span>
                    </span>
                    <div class="download-container">
                        <p class="step-title">Analyze and View Report</p>
                        <div class="step-points">
                            <p class="each-step-point">
                                <i class="fa fa-arrow-right"></i>
                                <span>Imagility will analyze all documents and provide a detailed report of the most recent iteration of the remediation process to view / download.</span>
                            </p>
                        </div>
                    </div>
                    <mat-divider role="separator" class="mat-divider mat-divider-horizontal"
                        aria-orientation="horizontal"></mat-divider>
                </div>

                <div *ngIf="remediationStatus != null" class="text-center">
                    <button type="button" (click)="endRemediation()" class="end-remediation-button">
                        <span>End Remediation</span>
                    </button>
                </div>
            </div>
            <div class="col-md-9 p-4">
                <div class="pb-4 pl-0 each-step-content-section document-upload-section">
                    <h5 class="m-0">Provide details to upload files</h5>
                    <div class="document-details-form-container mt-3">

                        <form [formGroup]="documentDetailsForm">
                            <div class="p-grid">
                                <div class="p-col-4">
                                    <div class="p-field">
                                        <label for="folderPath" class="required">Folder Path</label>
                                        <input id="folderPath" type="text" placeholder="Ex.: Google drive public location" pInputText formControlName="folderPath">
                                        <small *ngIf="documentDetailsForm.get('folderPath').invalid && documentDetailsForm.get('folderPath').touched" class="p-error">
                                            Folder Path is required
                                        </small>
                                    </div>
                                    <button class="guide-btn" color="primary" (click)="openGuideFolderStructure()">Guide on maintaining folder structure</button>
                                </div>
                            
                                <div class="p-col-4">
                                    <div class="p-field">
                                        <label for="sheet" class="required">Provide spreadsheet with details of each employee</label>
                                        <div>
                                            <span>
                                                <input type="file" #fileInput (change)="documentDetailsForm.get('sheet').setValue($event.target.files[0])" style="display:none" />
                                                <button (click)="fileInput.click()" mat-flat-button
                                                    color="default" class="text-uppercase _btn ml-0">
                                                    <mat-icon class="_add">file_upload</mat-icon> Upload Spreadsheet
                                                </button>
                                            </span>
                                            <p class="m-0" *ngIf="documentDetailsForm.get('sheet').value">{{documentDetailsForm.get('sheet').value.name ? documentDetailsForm.get('sheet').value.name : documentDetailsForm.get('sheet').value}}</p>
                                        </div>
                                        <button class="guide-btn" color="primary" (click)="openGuideSpreadsheet()">Guide on maintaining spreadsheet</button>
                                    </div>
                                </div>
                                <div class="p-col-3">
                                    <div class="p-field company-sec">
                                        <label class="required">Company Name</label>
                                        <p-dropdown appendTo="body" [(ngModel)]="selectedCompanyId"
                                            optionValue="companyId" optionLabel="companyName" [options]="listOfClientCompanies">
                                        </p-dropdown>
                                    </div>
                                </div>
                            
                                <!-- <div class="p-col-12 toggle-container">
                                    <label> <span class="mr-3">Is this folder password protected?</span> {{ documentDetailsForm.get('toggle').value ? 'Yes' : 'No' }}</label>
                                    <p-inputSwitch formControlName="toggle"></p-inputSwitch>
                                </div> -->
                            
                                <div class="p-col-4" *ngIf="documentDetailsForm.get('toggle').value">
                                    <div class="p-field">
                                        <label for="username" class="required">Username</label>
                                        <input id="username" type="text" pInputText formControlName="username">
                                        <small *ngIf="documentDetailsForm.get('username').invalid && documentDetailsForm.get('username').touched" class="p-error">
                                            Username is required
                                        </small>
                                    </div>
                                </div>
                            
                                <div class="p-col-4" *ngIf="documentDetailsForm.get('toggle').value">
                                    <div class="p-field">
                                        <label for="password" class="required">Password</label>
                                        <input id="password" type="password" pInputText formControlName="password">
                                        <small *ngIf="documentDetailsForm.get('password').invalid && documentDetailsForm.get('password').touched" class="p-error">
                                            Password is required
                                        </small>
                                    </div>
                                </div>
                                <div class="p-col-1">
                                    <button class="credentials-save-button _btn" pButton (click)="onSubmit()" label="Save" [disabled]="documentDetailsForm.invalid || remediationStatus == 'PARSING' || remediationStatus == 'ANALYSING'"></button>
                                </div>
                            
                                <div class="p-col-12 text-center">
                                    <button pButton class="_btn" (click)="initiateRemediation()" label="Parse" [disabled]="remediationStatus != 'INITIATED'"></button>
                                    <!-- <button *ngIf="remediationStatus == 'COMPLETED'" pButton class="_btn" (click)="initiateRemediation()" label="Re-Initiate" [disabled]="documentDetailsForm.invalid"></button> -->
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div *ngIf="documentParse" class="pb-4 pt-4 pl-0 each-step-content-section parsing-section">
                    <h5>Parse Uploaded Documents</h5>

                    <div *ngIf="remediationStatus == 'PARSING' || remediationStatus == 'PARSINGINPRGS'" class="parsing-status-section progress-section">
                        <div class="progress">
                            <div class="progress-bar" role="progressbar" [style.width.%]="100">
                            </div>
                        </div>
                        <p class="progress-text">Parsing In progress. Click on <span (click)="getRemediationStatus()" class="refresh-link">Refresh</span> to get the latest status</p>
                    </div>

                    <div *ngIf="remediationStatus == 'PARSINGCOMPLETED' && remediationData.parsingErrors" class="parsing-status-section">
                        <label><b>We have detected some errors in your documents</b></label>
                        <div>
                            <button pButton class="_btn mr-2" (click)="fixErrors()" label="Fix Errors"></button>
                            <!-- <button pButton class="no-bg" (click)="startAnalyzing()" label="Continue without fixing"></button> -->
                        </div>
                    </div>
                    <div *ngIf="(remediationStatus == 'PARSINGCOMPLETED' || remediationStatus == 'COMPLETED') && !remediationData.parsingErrors" class="parsing-status-section">
                        <p class="d-inline-block">
                            <i class="step-completed ml-1 mr-1">&#10003;</i> <span>Errors resolved successfully.</span>
                        </p>
                        <button pButton (click)="fixErrors()" class="ml-2 p-button-outlined" label="View/Update Errors"></button>
                    </div>
                    <!-- <div *ngIf="(remediationStatus == 'PARSINGCOMPLETED' && canAnalyze) || remediationStatus == 'ANALYSING' || remediationStatus == 'COMPLETED'" class="parsing-status-section">
                        <p>
                            <i class="step-completed mr-1">&#10003;</i> <span>Parsing successful</span>
                        </p>
                    </div> -->
                </div>
                <div *ngIf="documentAnalyze" class="pb-4 pt-4 pl-0 each-step-content-section analyzing-section">
                    <h5 class="m-0">Analyze and View Report</h5>
                    <div *ngIf="remediationStatus == 'PARSINGCOMPLETED' && canAnalyze" class="p-col-12 text-center">
                        <button pButton class="_btn" (click)="analyzeRemediation()" label="Analyze"></button>
                    </div>
                    <div class="analyzing-data-section">
                        <div *ngIf="remediationStatus == 'ANALYSING'" class="progress-section">
                            <div class="progress">
                                <div class="progress-bar" role="progressbar" [style.width.%]="100">
                                </div>
                            </div>
                            <p class="progress-text">Analyzing data</p>
                        </div>
                        <div *ngIf="remediationStatus == 'COMPLETED'">
                            <div class="col-md-12 row p-0">
                                <div class="col-md-4 p-0 pt-4">
                                    <button class="_btn" pButton (click)="saveCompliantData()" label="Save Compliant Data"></button>
                                </div>
                                <div class="col-md-8 p-0 pt-4 text-right">
                                    <button pButton (click)="viewReportPdf()" class="p-button-outlined" label="View Report"></button>
                                    <button pButton (click)="sendReportToEmail()" class="ml-2 p-button-outlined" label="Send Report"></button>
                                </div>
                            </div>
                            <div class="mat-elevation-z8 mt-2">
                                <table class="table" mat-table
                                    [dataSource]="dataSource" matSort>
                    
                                    <ng-container matColumnDef="iterations">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Iterations </th>
                                        <td mat-cell *matCellDef="let row"> {{row.iterationNumber}} </td>
                                    </ng-container>
                    
                                    <ng-container matColumnDef="dateTime">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Date and Time</th>
                                        <td mat-cell *matCellDef="let row"> {{row.dateTime | date:'dd MMM y / h:mm a'}} </td>
                                    </ng-container>
                    
                                    <ng-container matColumnDef="noOfEmployees">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header># of Employees for Remediation</th>
                                        <td mat-cell *matCellDef="let row"> {{row.employeesForRemediation}} </td>
                                    </ng-container>
                    
                                    <ng-container matColumnDef="compliant">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Compliant</th>
                                        <td mat-cell *matCellDef="let row"> {{row.compliant}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="nonCompliant">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Non - Compliant</th>
                                        <td mat-cell *matCellDef="let row"> {{row.nonCompliant}} </td>
                                    </ng-container>
                    
                                    <!-- <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Action </th>
                                        <td mat-cell *matCellDef="let row">
                                            <div class="row">
                                                <mat-icon matTooltip="settings menu" class="mr-1 icn settingsBorder"
                                                    [matMenuTriggerFor]="menu">
                                                    <mat-icon>settings</mat-icon>
                                                    <mat-icon>keyboard_arrow_down</mat-icon>
                                                </mat-icon>
                                                <mat-menu #menu="matMenu">
                                                    <button mat-menu-item (click)="viewReportPdf(row)">View Report PDF</button>
                                                    <button mat-menu-item (click)="sendReportToEmail(row)">Send Report</button>
                                                </mat-menu>
                                            </div>
                                        </td>
                                    </ng-container> -->
                    
                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <!-- <mat-paginator [pageSizeOptions]="[25, 50, 100]" [pageSize]="pageSize" [length]="totalElementCount"
                                    (page)="paginationEvent($event)"></mat-paginator> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>