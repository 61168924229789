<div mat-dialog-title class="text-left position-relative">
  <h5 class="mt-2 common-bene-class-for-translation">{{title}}</h5>
  <button *ngIf="showClose" mat-dialog-close mat-icon-button aria-label="Close" class="icon-button position-absolute" style="top:0;right:0">
      <mat-icon>close</mat-icon>
    </button>
    <hr />  
</div>

<mat-dialog-content class="mat-typography text-left">

<div [innerHTML]="message | keepHtml"></div>
</mat-dialog-content>

<mat-dialog-actions align="right" class="border-top mt-1">
  <div *ngIf="showButtons" class="float-right w-100 d-block">
  <button class="btn text-secondary" (click)="rejectPolicy()"><span class="common-bene-class-for-translation">Decline</span></button>
  <button class="btn btn-primary" (click)="acceptPolicy()">
    <i class="fa fa-chevron-right im-drop-icon"></i>
    <span><span class="common-bene-class-for-translation">Accept</span> & <span class="common-bene-class-for-translation">Continue</span></span>
  </button>
  </div>
</mat-dialog-actions>
