import { AfterContentInit, Component, ViewChild, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { environment } from 'environment-configurations';
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { Observable, of } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { fromEvent, Subscription } from 'rxjs';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { PetitionerService } from 'src/app/services/petitioner.service';
import { takeUntil, catchError } from 'rxjs/operators';
import { ImagilityBaseResponse } from 'app-models';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'bulk-upload-document',
  templateUrl: './bulk-upload-document.component.html',
  styleUrls: ['./bulk-upload-document.component.scss']
})
export class BulkUploadDocumentComponent implements OnInit, OnDestroy {

  //isButtonDisabled: boolean = true;
  downloadCompleted: boolean = false;
  uploadCompleted: boolean = false;
  viewfile: string;
  downloadButtonDisabled: boolean = false;
  columns: any;
  userId;
  companyId;
  observableSubscription$ = new Subject();
  uploadedFileName: string = '';
  item: any;
  displayGuidance: boolean = false;
  isResult: boolean = false;
  totalCount: any;
  successCount: any;
  failedCount: any;
  dataSource = new MatTableDataSource<any>([]);
  displayedColumns: string[] = ['beneName', 'birthDate', 'emailId', 'ssnNumber', 'errorMessage'];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  pageEvent: PageEvent;
  pageSizeOptions = [25, 50, 100];
  pageSize = 25; //default
  pageIndex = 0; //default
  totalRecordCount: number = 0;
  payload = {
    pageNumber: this.pageIndex,
    pageSize: this.pageSize
  };
  beneName: any;
  uploadedList: any;
  birthDate: any;
  emailId: any;
  ssnNumber: any;
  errorMessage: any;
  private destroy = new Subject<void>()
  prodAppURL: string;
  applicationURL: any;

  constructor(
    private petitionerService: PetitionerService,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute, private authenticationService: AuthenticationService,
  ) { }

  ngOnInit(): void {
    this.userId = this.authenticationService.currentUserValue['userId'];
    this.companyId = this.authenticationService.currentUserValue['companyId'];
  }

  paginationEvent(event) {
    this.payload.pageNumber = event.page + 1;
    this.payload.pageSize = event.rows;
    this.loadData();
  }

  loadData(): void {
    const payload = {
      pageNumber: this.pageIndex,
      pageSize: this.pageSize
    };
    this.fetchData(this.pageIndex, this.pageSize).subscribe(data => {
      this.totalRecordCount = data.totalCount;
      this.dataSource.data = data.records; // Use MatTableDataSource to update data
    });
  }

  fetchData(pageIndex: number, pageSize: number): Observable<any> {
    return this.petitionerService.getBulkUploadDocuments(pageIndex)
      .pipe(
        takeUntil(this.destroy),
        catchError(error => {
          console.error('Error fetching data:', error);
          return of({ totalCount: 0, records: [] }); // Return empty data on error
        })
      );
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  EmpData: any = [];

  downloadFile(): void {
    //this.downloadButtonDisabled = true;
    setTimeout(() => {
      this.downloadCompleted = true;
      let pathtoDownload='/application/ImagilityRemoteI9.zip';
      let url=environment.appUrl;
      let zipDownload = url + pathtoDownload;
      window.open(zipDownload, '_blank')
      
      // setTimeout(() => {
      //   this.isButtonDisabled = false;
      // }, 1000);
    }, 1000);
  }

  handleFileInput(event: any): void {
    debugger;
    const fileList: FileList | null = event.target.files;

    if (fileList && fileList.length > 0) {
      const selectedFile: File = fileList[0];
      if (this.isExcelFile(selectedFile)) {
        const formData = new FormData();
        formData.append('file', selectedFile);

        this.petitionerService.saveBulkUploadDocuments(this.companyId, formData)
          .pipe(takeUntil(this.observableSubscription$))
          .subscribe((response: ImagilityBaseResponse) => {
            debugger;
            if (response.status === 200) {
              debugger;
              this.uploadedFileName = selectedFile.name;
              this.toastr.success(response.message, "File Uploaded Successfully");
              this.uploadCompleted = true;
              //this.isButtonDisabled = true;
              this.getBulkUploadedDocs();
              this.isResult = true;
            } else {
              this.toastr.warning(response.message);
            }
          });
      } else {
        this.toastr.error("Please upload only Excel sheet files.");
      }
    }
  }

  isExcelFile(file: File): boolean {
    const allowedExtensions = ['xls', 'xlsx'];
    const extension = file.name.split('.').pop()?.toLowerCase();
    return extension && allowedExtensions.includes(extension);
  }

  showGuidance() {
    this.displayGuidance = true;
  }

  getBulkUploadedDocs() {
    this.petitionerService.getBulkUploadDocuments(this.companyId).pipe().subscribe((response: any) => {
      if (response.status === 200) {
        const docData = response.data;
        this.totalCount = docData.totalCount;
        this.successCount = docData.approvedCout;
        this.failedCount = docData.rejectedCount;
        this.uploadedList = docData.bulkUploadDocList;

        // Clear existing data before pushing new data
        this.EmpData = [];

        for (const obj of this.uploadedList) {
          var tempObj: any = {};
          tempObj.beneName = obj.firstName + ' ' + obj.lastName;
          tempObj.birthDate = obj.beneficiary?.dob;
          tempObj.emailId = obj.email;
          tempObj.ssnNumber = obj.documentNumber;
          tempObj.errorMessage = obj.uploadStatus;
          this.EmpData.push(tempObj);
        }

        // Update MatTableDataSource with new data
        this.dataSource.data = this.EmpData;
        console.log(this.dataSource.data);
      }
    });
  }

}
