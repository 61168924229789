import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import * as Roles from '../../../data/constants/roles';
import { VIDEOS } from '../../../data/constants/video-doc.data';
import { ReferenceLibraryService } from 'reference-library';

@Component({
  selector: 'app-help-video-overlay',
  templateUrl: './help-video-overlay.component.html',
  styleUrls: ['./help-video-overlay.component.scss'],
})
export class HelpVideoOverlayComponent implements OnInit {
  isOpen = false;
  tabStructure = false;
  position;
  position1;
  header;
  header1;
  dialogHeader;
  sectionName;
  videoLink;
  videoLink1;
  videos: any[] = [];
  vid: any;

  constructor(private router: Router,  private referenceLibraryService: ReferenceLibraryService, public sanitizer: DomSanitizer) {
    if (sessionStorage.getItem('userTypeRole') === Roles.ATTORNEY) {
      this.videos = VIDEOS.ATTORNEY_VIDEOS;
    } else if (sessionStorage.getItem('userTypeRole') === Roles.PETITIONER || sessionStorage.getItem('userTypeRole') === Roles.I9ADMIN) {
      this.videos = VIDEOS.PETITIONER_VIDEOS;
    } else if (sessionStorage.getItem('userTypeRole') === Roles.BENEFICIARY) {
      this.videos = VIDEOS.BENEFICIARY_VIDEOS;
    }

  }

  ngOnInit() {
    // this.getValue();
    if (this.videos?.length > 0) {
      this.vid = this.videos[0].sectionName;
      this.setValues('', '', this.vid);
    }
  }

  ngAfterViewInit() {
    this.translet();
  }

  translet() {
    setTimeout(() => {
      this.referenceLibraryService.transletConversion();
    }, 600);
  }

  setValues(position, header, sectionName) {
    let link: any;
    this.position = position;
    this.header = header;
    link = this.findingVideoUrl(sectionName, '');
    if (link) {
      this.videoLink = this.sanitizer.bypassSecurityTrustResourceUrl(link.url);
    }

  }

  findingVideoUrl(sectionName, headerSectionName) {
    let linkObj: any;
    linkObj = this.videos.find(
      (element) => element.sectionName === sectionName
    );
    if (!linkObj) {
      this.videos.forEach((element) => {
        if (element.sectionName == headerSectionName && element.links && element.links.length > 0) {
          linkObj = element.links.find((x) => x.sectionName === sectionName);
          return linkObj;
        }
      });
    }
    return linkObj;
  }

  setTabValues(position, dialogHeader, headerSectionName, sectionName, position1, header1, sectionName1) {
    let link1: any;
    let link2: any;
    this.dialogHeader = dialogHeader;
    this.position = position;
    this.header = headerSectionName;
    link1 = this.findingVideoUrl(sectionName, dialogHeader);
    this.videoLink = link1 ? this.sanitizer.bypassSecurityTrustResourceUrl(link1.url) : '';
    this.tabStructure = true;
    this.position1 = position1;
    this.header1 = header1;
    link2 = this.findingVideoUrl(sectionName1, dialogHeader);
    // link = this.videos.filter(x => x.sectionName == sectionName1);
    this.videoLink1 = link2 ? this.sanitizer.bypassSecurityTrustResourceUrl(link2.url) : '';
  }

  closeOverlay() {
    this.isOpen = !this.isOpen;
  }

  routeToResourcePage() {
    let resourceLink;
    const userType = sessionStorage.getItem('userTypeRole');
    if (userType == 'Petitioner') {
      resourceLink = '/petitioner-landing/resources';
    } else if (userType == 'Beneficiary') {
      resourceLink = '/beneficiary-landing/resources';
    } else if (userType == 'Attorney') {
      resourceLink = '/attorney-landing/resources';
    }
    this.router.navigate([resourceLink]);
  }
}
