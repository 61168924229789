import { Component, OnInit } from '@angular/core';

import { DialogModule } from 'primeng/dialog';
import { FormGroup, FormArray, FormBuilder, Validators, NgForm } from '@angular/forms'
import {I9Service} from '../services/i9-service'
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment'; 
import { environment } from 'src/environments/environment';
import { I9EverifyService } from '../modules/i9-evaluators/i9-everify.service';
import { ToastrService } from 'ngx-toastr';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { Subject } from 'rxjs/internal/Subject';
import { ImagilityBaseResponse } from 'app-models';

@Component({
  selector: 'app-evaluator-details',
  templateUrl: './evaluator-details.component.html',
  styleUrls: ['./evaluator-details.component.scss']
})
export class EvaluatorDetailsComponent implements OnInit {
  companyId:any;
  beneficiaryId:any;
  i9FormId:any;
  observableSubscription$ = new Subject();
  selectedDocument:any;
  fileName:any;
  url:any;
  id:any;
  evalFeedback:string;
  evalStatus:string;
  isUploadDisable:boolean=true;
  status:boolean=false;
  feedback:boolean=false;
  issubmitDisable:boolean=false;
  termsConditions:boolean=false;

  beneficiary:any = {
    citizenUnitedState:true,

    personalDetails:{
      firstName:'John',
      lastName:'Smith',
      middleName:'',
      otherFirstName:'',
      otherlastName:'',
      othermiddleName:'',
      dob:'25 July 1981',
      securityNumber:'12345677989',
      mobileNumber:'+91 9730446709',
      email:'jamessmith@gmail.com'
    },

    addressDetails: {        
    address1:' #23 7B Tower, Shobha Exotica',
    address2:'Thanisandra Main Road, K R Puram ',
    country:'India',
    state:'Maharastra',
    city:'Mumbai',
    locality:'',
    zipCode:'40111'},
    digitalSignature:{
      signature:'yes',
      name:'',
      signatureDate:'11 July 2023'
    },
    I9document:{ 
      us_Passport:{
        passportFile:'',
        fileName:''
      },
      nonUs_Passport:{
        foreginPassportFile:'',
        fileName:''
      },
      lawful_resident:{
        alignRegistrationNumber: '',
        uscisNumber: ''
      },
      alian_authorized_expiration:{
        expirtDate:'',
        formI94Number:'',
        foreignPassportNumber:'',
        countryOfIssues:''
      }
    },
    translatorDetails:{
      isPreparer: true,
      isTranslator:false,
      translatorInfo:{
        firstName: '',
        lastName: '',
        middleName: '',
        signature:'',
        signatureName:'',
        date: '',
        address1:' #23 7B Tower, Shobha Exotica',
        address2:'Thanisandra Main Road, K R Puram ',
        country:'India',
        state:'Maharastra',
        city:'Mumbai',
        locality:'',
        zipCode:'40111'
      }

    },
    antiDiscNotice:false,

    documentsEvaluation:[
      {docList:'List B and C', 
      docType:'Driving License or ID card',
      docName:'drivinglicense.pdf',
      docStatus:[
        {val:1,name:'Verified'},
        {val:2,name:'Request supporting documents'},
        {val:3,name:'Query on document'},
        {val:4,name:'Rejected'},
        {val:5,name:'Other'},
      ],
      docStatusSelected:{},      
      comment:''},
  
      {docList:'List B and C', 
      docType:'Driving License or ID card',
      docName:'drivinglicense.pdf',
      docStatus:[
        {val:1,name:'Verified'},
        {val:2,name:'Request supporting documents'},
        {val:3,name:'Query on document'},
        {val:4,name:'Rejected'},
        {val:5,name:'Other'},
      ],
      docStatusSelected:{},
      comment:''},
  
      {docList:'List A', 
      docType:'Driving License or ID card',
      docName:'drivinglicense.pdf',
      docStatus:[
        {val:1,name:'Verified'},
        {val:2,name:'Request supporting documents'},
        {val:3,name:'Query on document'},
        {val:4,name:'Rejected'},
        {val:5,name:'Other'},
      ],
      docStatusSelected:{},
      comment:''},
    ],
    documentList:[]
  };

  
  visible: boolean = false;
  documentsArr:any=[]; 
  expiredDate: string;
  todayDate: any;
  isDateExpired:boolean = false;
  isFormCompleted:boolean = false;
 

  constructor( 
    private activeRoute: ActivatedRoute, 
    private router: Router,  
    private apiService: I9Service,
    private i9EverifyService:I9EverifyService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    // this.getDocumentTypes();
    this.getQueryParams(); 
    this.todayDate = new Date();
    
  }





  getQueryParams(){
    this.activeRoute.queryParams
      .subscribe(params => {
        this.companyId = params.companyId;
        this.beneficiaryId = params.beneficiaryId;
        this.i9FormId = params.I9FormId;

        this.getDocInfo();
        this.getDocumentsEvaluation();
        this.evaluatorDocumentTypes('EVALTYPE');
        this.getDocumentTypes('EVALSTATUS'); 
        this.getI9Section1Info_b();
      }
    );
  }
  evaluationData:any=[];
  evaluatioTypeData:any;
  documentsEvaluation:any=[];
  getDocumentsEvaluation(){
    this.apiService.documentsEvaluation(this.i9FormId).pipe().subscribe((res: any) => {
      const data= res['data'];
     // this.documentsEvaluation =this.evaluationData;
    
      for(const evaldata  of data){
        
        if(evaldata.fileCategory.group!='EVALTYPE'){
          this.evaluationData.push(evaldata);
          this.documentsEvaluation =this.evaluationData;
        //   this.documentsEvaluation.push({
        //     fileCategory: evaldata.fileCategory.refGroupdesc ? evaldata.fileCategory.refGroupdesc : 'NA',
        //     fileType: evaldata.fileType,
        //     fileName: evaldata.fileName,
        // });
        }else if(evaldata.fileCategory.group=='EVALTYPE'){
          this.evaluatioTypeData=evaldata;
          this.fileName = this.evaluatioTypeData.fileName+"." + this.evaluatioTypeData.fileType;
          this.url = this.evaluatioTypeData.fileLocation;
          this.id = this.evaluatioTypeData.id;
          this.evalStatus=this.evaluatioTypeData.evalStatus;
          this.evalFeedback=this.evaluatioTypeData.evalFeedback;
        }
      }
      
      console.log('getDocumentsEvaluation>>>', this.documentsEvaluation);
    })
  }
  getEvaluationDoc(){
    this.apiService.documentsEvaluation(this.i9FormId).pipe().subscribe((res: any) => {
      const data= res['data'];
     // this.documentsEvaluation =this.evaluationData;
    
      for(const evaldata  of data){
        
     if(evaldata.fileCategory.group=='EVALTYPE'){
          this.evaluatioTypeData=evaldata;
          this.fileName = this.evaluatioTypeData.fileName+"." + this.evaluatioTypeData.fileType;
          this.url = this.evaluatioTypeData.fileLocation;
          this.id = this.evaluatioTypeData.id;
          this.evalStatus=this.evaluatioTypeData.evalStatus;
          this.evalFeedback=this.evaluatioTypeData.evalFeedback;
        }
      }
      
      console.log('getDocumentsEvaluation>>>', this.documentsEvaluation);
    })
  }

  docStatus:any=[];
  evaluatorDocumentTypes(type){
    this.apiService.evaluatorDocumentTypes(type).pipe().subscribe((res: any) => {  
     this.docStatus = res['data'];
     console.log('docStatus>>>', this.docStatus);
    })
  }

  translatorRadio(val){}
  lawfulResidentOptionChange(){}

 

  getComments:string='';
  getIndex:number=0;

  showDialog(i, row) { 
    let statusEval= row['evalStatus'];
    let feedbackEval= row['evalFeedback'];
    if((statusEval!='VERIFIED' && feedbackEval!='EVALREPORT') || !this.isUploadDisable)
    {
      this.getIndex=i;
    this.getComments= row['comment'];
    console.log(this.getComments);
    this.visible = true;
    }
    
  } 

  addComments(){
    this.documentsEvaluation[this.getIndex]['comment']= this.getComments;
    this.visible = false;
    console.log('>>>this.documentsEvaluation', this.documentsEvaluation)
  }

  count=0;
  selectedFile='';
  selectOption=0;
  isAgree:boolean=false;
  isAccept:boolean=false;
  error_selectOption:boolean=false;


 

  removeRow(row){
    let id = row.id;
    this.beneficiary.documentList = this.beneficiary.documentList.filter(ele=>{
     if(ele.id!==id) return ele 
    }); 
  }

  
  setOption(i, row, event) {
    let value = event.target.value;
  
        this.documentsEvaluation[i]['evalStatus'] = value;

    
    this.updateUploadButtonState();
}

setFeedback(i, row, event) {
    let value = event.target.value;
   
        this.documentsEvaluation[i]['evalFeedback'] = value;


    this.updateUploadButtonState();
}
verifiedRowsCount:any;
updateUploadButtonState() {
    this.verifiedRowsCount = this.documentsEvaluation.filter(row => row.evalStatus === "VERIFIED" && row.evalFeedback === "EVALREPORT").length;

    // Enable the upload button only when all rows meet the criteria
    this.isUploadDisable = this.verifiedRowsCount !== this.documentsEvaluation.length;
}


flag:boolean = true;
optionsError:any = [];

checkBlank(val){
  if(val == undefined || val=='') return 'NA';
  else val 
}
employee:any;


 

getDocumentTypes (type){  
  this.apiService.evaluatorDocumentTypes(type).pipe().subscribe((res: any) => { 
  this.documentsArr = res['data'];
  // console.log('type1>>>>>>>', res); 
   })
}
deleteDocument(docId){
  this.apiService.deleteI9BeneficiaryDocument(this.i9FormId, docId)
  .pipe()
  .subscribe((response: ImagilityBaseResponse) => {
    if(response.status==200)
    {
      this.toastr.success(response.message, "File deleted sucessfully");
      this.url='';
      this.id='';
      this.fileName='';
    }

  
  })
}
  getDocInfo(){ 
    this.apiService.employerAndVerifyDocInfo(this.companyId, this.i9FormId).pipe().subscribe((result: any) => { 
      console.log('>>>>', result)
     this.employee=result['data']; 

     let convertDob = new Date( this.employee.beneficiaryDetails.dob);
     this.employee.beneficiaryDetails.dob =  moment(convertDob).format('MM-DD-YYYY');
     let convertSinnDate = new Date( this.employee.beneficiaryDetails.beneficiarySignDate);
     this.employee.beneficiaryDetails.beneficiarySignDate =  moment(convertSinnDate).format('MM-DD-YYYY'); 
     
     })
  }

  viewDoc(row){ 
    if (row.fileLocation) {
      let file = `${environment.docs}${row.fileLocation.replace('/var/imagility/uploads/', '')}`; 
      window.open(file);
    }
  }

  downloadDoc(row){
    if (row.fileLocation) {
    let path = `${environment.docs}${row.fileLocation.replace('/var/imagility/uploads/', '')}`;  
    this.i9EverifyService.downloadDocuments(path);
    }
  }

 
 
  
i9Info:any; 
getI9Section1Info_b(){
  this.apiService.getI9Section1Info_b(this.companyId, this.i9FormId).pipe().subscribe((result: any) => {  
    const initiationDate = new Date(result.createdDate);
    initiationDate.setDate(initiationDate.getDate() + 1);
    const month = (initiationDate.getMonth() + 1).toString().padStart(2, '0');
    const day = initiationDate.getDate().toString().padStart(2, '0');
    const year = initiationDate.getFullYear().toString();
    const hr = initiationDate.getHours() > 12 ? (initiationDate.getHours() - 12).toString() : initiationDate.getHours().toString();
    const min = initiationDate.getMinutes().toString().padStart(2, '0');
    const sec = initiationDate.getSeconds().toString();
    const ampm = initiationDate.getHours() >= 12 ? 'PM' : 'AM';

    this.expiredDate = `${month}-${day}-${year} ${hr}:${min}:${sec} ${ampm}`;
    const expiredDateObj = new Date(this.expiredDate);

    //isDateExpired
    // if(this.todayDate > expiredDateObj){
    //   this.isDateExpired = true;
    // }else{
    //   this.isDateExpired = false;
    //   this.isFormCompleted = false;
    // }
    this.isFormCompleted = false;
    console.log('result get>>>>>>>', result); 
    this.i9Info=result; 
    if(this.i9Info.statusCode=="SEC1VERFD"){
      this.issubmitDisable=true;
    }
    for(const address of this.i9Info.beneficiaryDetails.benAddress){
      if(address.type.code == "CURR"){
      this.i9Info.beneficiaryDetails.benAddress[0].addressLine1 = address.addressLine1;
      this.i9Info.beneficiaryDetails.benAddress[0].addressLine2 = address.addressLine2;
      this.i9Info.beneficiaryDetails.benAddress[0].stateProvinceCode = address.stateProvinceCode;
      this.i9Info.beneficiaryDetails.benAddress[0].stateProvinceName = address.stateProvinceName;
      this.i9Info.beneficiaryDetails.benAddress[0].city = address.city;
      this.i9Info.beneficiaryDetails.benAddress[0].locality = address.locality;
      this.i9Info.beneficiaryDetails.benAddress[0].postCode = address.postCode;
      }
    }
  }) 
}

viewDocument(item) {
  const docPath = item.substring(23);
  window.open(`${environment.docs}${docPath}`, '_blank');
}
uploadEvaluatorDocument(_data: any){
  let files = _data[0];   
  this.selectedFile = files; 
  const formData = new FormData();
  formData.append('file', this.selectedFile);
  this.apiService.uploadI9BeneficiaryDocument(this.companyId, this.i9FormId, 'EVALTYPE', 'EVALREPORT', formData)
    .pipe(takeUntil(this.observableSubscription$))
    .subscribe((response: ImagilityBaseResponse) => {
      if (response.status == 200) {

        this.toastr.success(response.message, "File Uploaded Successfully");
        this.getEvaluationDoc();
      }
      else
      {
        this.toastr.warning(response.message);
      }
    })
}
updateEvalStatus(){
  let i9DocumentsList = [];
  for (let document of this.documentsEvaluation) {
    let i9Document = {
      comment: document.comment,
      evalFeedback: document.evalFeedback,
      evalStatus: document.evalStatus,
      fileCategoryOtherName: document.fileCategoryOtherName,
      fileLocation: document.fileLocation,
      fileName: document.fileName,
      fileSize: 0,
      fileType: document.fileType,
      id: document.id,
      status: 0
    };
    i9DocumentsList.push(i9Document); // Add the current i9Document object to the list
  }
  let payload = {
    i9DocumentsList: i9DocumentsList // Pass the array of i9Document objects in the payload
  };
  this.apiService.updateEvalStatus(this.i9FormId, payload).pipe().subscribe((result: any) => {  
     console.log('result updateEvalStatus>>>>>>>', result); 
    if(result.status==200){  
      this.toastr.success(result.message)
    } 
    this.getEvaluationDoc(); 
  }) 
}

 isBenAgree=true;
 isBenAccept=true;
  submitRequest(form: NgForm){  
    if(this.isAgree==false || this.isAccept==false){
      this.toastr.warning(`Please select required field!`);
      return;
    }
    if((!this.documentsEvaluation[0]['evalStatus']) || !this.documentsEvaluation[0]['evalFeedback']){
      this.toastr.warning(`Please select status and Feedback!`);
      return;
    }
    if (form.valid) {  
      let payload= {
        "beneficiaryDetails":  this.i9Info?.beneficiaryDetails ? this.i9Info?.beneficiaryDetails : {},
        "beneficiaryId": this.i9Info.beneficiaryId ? this.i9Info.beneficiaryId: 0,
        "companyId": this.i9Info.companyId ? this.i9Info.companyId: 0,
        "i9FormDtlId": this.i9Info.i9FormDtlId ? this.i9Info.i9FormDtlId: 0,
        "i9WorkAuthPreparerInfo":  this.i9Info?.i9WorkAuthPreparerInfo ? this.i9Info?.i9WorkAuthPreparerInfo : {},
        "isAntiDiscrAccpt": true,
        "statusCode": "SEC1COMPLTD",
      // "documentsEvaluation": this.documentsEvaluation, 
      }

      this.apiService.employerAndVerifyDocInfoPost(this.companyId, this.i9FormId, payload).pipe().subscribe((result: any) => { 
      // this.employee=result['data']; 
        //console.log('result post>>>>>>>', result); 
        if(result.status==200){ 
          this.updateEvalStatus();
          this.issubmitDisable=true;
          this.toastr.success(result.message)
        }
      }) 
    }
  } 

  openTerms(){
    this.termsConditions = true; 
  }
 
  // confirmTerms(){
  //   this.termsConditions = false; 
  // }

}
