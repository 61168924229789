<div class="container-fluid">
  <div class="mb-3">
    <h4 class="mb-0">Form/Visa Preference Settings</h4>
    <small>Customise your visas and forms selections to easily manage the forms your company or law firm supports.</small>
  </div>
  <div class="box-container">
    <div class="d-flex py-3 align-items-center">
      <div class="p-2">
        <h5 class="d-inline mr-4">Select your preferred Forms/Visas</h5>
      </div>
      <div class="ml-auto d-flex align-items-center">
        <mat-form-field appearance="outline" class="search-box">
          <input type="text" [formControl]="searchControl" placeholder="Search" />          
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>        
        <button type="button" class="btn btn-link text-capitalize text-decoration-none ml-2" (click)="clearSelection()">
          <i class="fa fa-repeat" aria-hidden="true"></i> Clear Selection </button>
      </div>
    </div>

    <div class="mt-3" *ngIf="!noDataFound" >
      <div class="blue-header d-flex align-items-center">
        <label class="select-all">
          Select All
          <mat-checkbox [(ngModel)]="isAllSelected" (change)="selectAllForms($event.checked)"></mat-checkbox>
        </label>
        <strong class="ml-2">Forms/Visas</strong>
      </div>

      <div class="forms-only-container">
        <mat-accordion multi [togglePosition]="'after'">
          <mat-expansion-panel *ngFor="let form of filteredForms; let i = index" [expanded]="form.isExpanded" [disabled]="!form.visaTypeSelections?.length">
            <mat-expansion-panel-header>
              <!-- Stop event propagation to prevent unwanted expansion -->
              <mat-checkbox [(ngModel)]="form.isFormSelected" (change)="toggleFormSelection(form)" (click)="$event.stopPropagation()"></mat-checkbox>
              <h6 class="form-title">
                {{ form.formName }} | {{ form.formShortDesc }}
              </h6>
            </mat-expansion-panel-header>            
            <div class="visa-selection px-3 py-2">
              <div class="row">
                <div class="col-md-3" *ngFor="let visa of form.visaTypeSelections">
                  <mat-checkbox [(ngModel)]="visa.isVisaTypeSelected" (change)="toggleVisaSelection(form)">
                    {{ visa.visaTypeId }}
                  </mat-checkbox>
                </div>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <div class="d-flex justify-start-end mt-3">
        <button type="button" class="btn btn-primary" [disabled]="isSaveDisabled()" (click)="saveFormPreference()">
          <i class="fa fa-floppy-o" aria-hidden="true"></i> SAVE
        </button>
      </div>
    </div>
    <div *ngIf="noDataFound" class="no-data">
      <h6>No Data Found</h6>
    </div>
  </div>
</div>