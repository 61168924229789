import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Subject, Subscription } from 'rxjs';
import { AuthenticationService } from 'authentication';
import { I9RemediationService } from '../../i9-remediation.service';

export interface ParsingReportListRecord {
  documentTitle: string;
  documentCount: number;
  totalErrors: number;
  correctedErrors: number;
  status: string;
  action: string;
}

@Component({
  selector: 'app-parsing-report-list',
  templateUrl: './parsing-report-list.component.html',
  styleUrls: ['./parsing-report-list.component.scss']
})
export class ParsingReportListComponent implements OnInit, AfterViewInit {

  radioForm: FormGroup;
  options = [
    { value: '1', label: 'Document Types' },
    //{ value: '2', label: 'Employees' }
  ];

  displayedColumns: string[] = ['documentTitle', 'documentCount', 'totalErrors', 'correctedErrors', 'status', 'action'];
  // dataSource: MatTableDataSource<any>;
  dataSource = new MatTableDataSource<ParsingReportListRecord>();

  pageSize = 25; // default
  pageIndex = 0; // default
  searchText = '';
  pageSizeOptions = [25, 50, 100];
  totalElementCount = 0;
  searchSubscription: Subscription;
  observableSubscription$ = new Subject();

  hidePageSize = false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;
  disabled = false;

  pageEvent: PageEvent;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  analysis: boolean;
  companyId;
  errorEmp: number;
  totalEmp: number;
  validEmp: number;
  documentTitle: any;
  remediationIterationId:any;
  remediationRunId:any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private authenticationService: AuthenticationService,
    private i9RemediationService: I9RemediationService
  ) { }

  ngOnInit(): void {
    this.companyId = parseInt(sessionStorage.getItem('companyId'));
    this.remediationIterationId= sessionStorage.getItem('remediationIterationId');
    this.remediationRunId = sessionStorage.getItem('remediationRunId');
    // this.remediationIterationId=1483
    // this.remediationRunId = 1459
    this.radioForm = this.fb.group({
      selectedOption: [this.options[0].value] // Default to the first option
    });
    this.radioForm.get('selectedOption').valueChanges.subscribe(value => {
      console.log('Selected option:', value);
    });

    this.dataSource.paginator = this.paginator;
    this.getParsedEmpStatus();
    this.getParsedEmpDoctypes();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  backToDashboard() {
    this.router.navigate(['petitioner-landing/dashboard/petitioner-dashboard']);
  }

  editRecord(row) {
    const queryParamsObj = { documentTitle: row.documentTitle};
    this.router.navigate(['../parsing-errors'], { queryParams: queryParamsObj, relativeTo: this.route });
   // this.getParsedEmpDoctype(row.documentTitle);
  }

  paginationEvent(e: PageEvent) {
    this.pageEvent = e;
    this.totalElementCount = e.length;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
  }

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
    }
  }

  startAnalysis(){
      const queryParamsObj = {
        startParsing: true
      };
      this.router.navigate(['../initiate-i9-remediation'], { queryParams: queryParamsObj, relativeTo: this.route });
  }

  getParsedEmpStatus(){
    this.i9RemediationService.getParseEmpStatus(this.companyId, this.remediationRunId,this.remediationIterationId).subscribe(data => {
      this.errorEmp = data.errorEmployees;
      this.totalEmp = data.totalEmployees;
      this.validEmp = data.validEmployees;
      this.checkAnalysisStatus();
    });
  }

  getParsedEmpDoctypes(){
    this.i9RemediationService.getParseEmpDocTypes(this.companyId, this.remediationRunId,this.remediationIterationId).subscribe(data => {
      this.dataSource.data = data;
      this.totalElementCount = data.length;
      this.documentTitle = data.documentTitle;
      data.forEach(dta => {
        if(dta.i9RecordAndEmployeeIdsListDTO!=null)
        {
          localStorage.setItem('i9RecordAndEmployeeIdsListDTO', JSON.stringify(dta.i9RecordAndEmployeeIdsListDTO));
        }
      });

    });
  }



  checkAnalysisStatus() {
    if(this.validEmp !== this.totalEmp)
    {
      this.analysis = false;
    }
    else{
      this.analysis = true;
    }
  }

  initiateNewRemediation() {
    const queryParamsObj = {}
    this.router.navigate(['../initiate-i9-remediation'], { queryParams: queryParamsObj, relativeTo: this.route });
  }

}
