<div class="fluid-container" *ngIf="!isSimplifyH1B">
  <div class="row pt-4 d-flex align-items-center">
    <div class="col-md-8 step-title p-0">
      <h5 class="mb-0">Provide Employer Employee Relationship</h5>
      <!-- <p class="mb-0" style="font-size:14px">Lorem ipsum lorem ipsum</p> -->
    </div>
    <div class="col-md-4 editBtn headerSubPortion px-0"> 
      <ul>
        <li class="col-auto px-0 firstPortion"> 
          <lib-step-status-update-component-library
        [visaType]="primaryData.caseType != 'I130' ? primaryData.visatype : primaryData.caseType"
        [stepDetails]="stepDetails" type="EDIT" [isViewMode]="primaryData['isViewMode']"
        [isCaseRequest]="primaryData['isCaseRequest']" class="float-right">
      </lib-step-status-update-component-library>
        </li>
        <li class="col-auto pr-0 pl-2 secondPortion">
          <lib-maximize-dialog-library [configData]="{'layoutCode':primaryData?.layoutCode}"></lib-maximize-dialog-library>
      <span (click)="handleClose();" class="close-icon pi pi-times pull-right"></span>
        </li>
      </ul>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 p-0">
      <lib-assigning-task-library [visaType]="primaryData.visatype" [stepDetails]="stepDetails"
        (updateStatus)="updateStepStatus($event)" [isViewMode]="primaryData['isViewMode']"
        [isCaseRequest]="primaryData['isCaseRequest']">
      </lib-assigning-task-library>
    </div>
  </div>
  <p-divider class="header-divider"></p-divider>
  <lib-last-update-library [inputDetails]="stepDetails" [primaryData]="primaryData" [sectionCode]="sectionCode">
  </lib-last-update-library>
  <!-- <div class="row mt-n3 mb-3">
    <div class="col-6 py-2 last-modified">
      <label class="last-modified-label mb-0">Last updated by : </label>
      <label class="last-modified-value mb-0 ml-2">{{ stepDetails.modifiedUserName ? stepDetails.modifiedUserName : '-'
        }}</label>
    </div>
    <div class="col-6 py-2 last-modified">
      <label class="last-modified-label mb-0">Last updated on : </label>
      <label class="last-modified-value mb-0 ml-2">{{ modifiedDate ? modifiedDate : '-' }}</label>
    </div>
  </div> -->


  <div class="main-body footer-adjust-v1">
    <div class="row">
      <div class="col-md-12">
        <h6 class="heading p-text-bold">Select project name from dropdown and enter project details below</h6>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-md-5">
        <label class="label d-block">Project Name</label>
        <p-dropdown appendTo="body" [options]="ListOfComanyProjects" [style]="{'width':'100%'}"
          [(ngModel)]="selectedProject" optionLabel="projectName" placeholder="Select Project"
          (onChange)="changeProject()" [disabled]="disabledControls"></p-dropdown>
      </div>
      <div class="col-md-7" *ngIf="selectedProject && selectedProject.id">
        <label class="label d-block">Save <small>(Project Details, Project Milestone, Project Staffing, Additional
            Details)</small></label>
        <button type="button" (click)="savePetition()" class="p-btn btn btn-primary mr-3">Save</button>
        <!-- <button type="button" (click)="saveMasterData()" class="p-btn btn btn-primary mr-3">Save Master Data</button> -->
      </div>
    </div>
    <div class="row" *ngIf="!isSimplifyH1B">
      <div class="card card-min no-border">
        <div class="im-card-tab-menu sticky-menu">
          <div class="im-list-group leftSidebar">
            <a class="im-list-group-item"
              [ngClass]="{'activeItem': selectedItem === 0, 'inActiveItem':  selectedItem !== 0 }"
              (click)="handleSupervisor()"> <span>Supervisor</span></a>
            <a class="im-list-group-item"
              [ngClass]="{'activeItem': selectedItem === 1, 'inActiveItem':  selectedItem !== 1, 'disabled': stepDetails.stepStatusDetails.stepStatusCode === 'NEW' }"
              (click)="handleDetails(1, false, true, 'Project Details', '(Brief about project)', null)"><span>Project
                Details</span></a>
            <a class="im-list-group-item"
              [ngClass]="{'activeItem': selectedItem === 2, 'inActiveItem':  selectedItem !== 2, 'disabled': stepDetails.stepStatusDetails.stepStatusCode === 'NEW' }"
              (click)="handleDetails(2, false, false, workLocationTypeCode, null, 'Enter Project Timelines')"><span>Project
                Milestone</span></a>
            <a class="im-list-group-item"
              [ngClass]="{'activeItem': selectedItem === 3, 'inActiveItem':  selectedItem !== 3, 'disabled': stepDetails.stepStatusDetails.stepStatusCode === 'NEW' }"
              (click)="handleDetails(3, false, false, workLocationTypeCode, null, 'Enter Project Staffing details')"><span>Project
                Staffing</span></a>
            <a class="im-list-group-item"
              [ngClass]="{'activeItem': selectedItem === 4, 'inActiveItem':  selectedItem !== 4, 'disabled': stepDetails.stepStatusDetails.stepStatusCode === 'NEW' }"
              (click)="handleDetails(4, false, false, workLocationTypeCode, null, 'Enter Any Other Relevant Details')"><span>Additional
                Details</span></a>
            <a class="im-list-group-item"
              [ngClass]="{'activeItem': selectedItem === 5, 'inActiveItem':  selectedItem !== 5, 'disabled': stepDetails.stepStatusDetails.stepStatusCode === 'NEW' }"
              (click)="handleDocuments(5, false, 'Upload Related Client Documents', 'PETDOCTYP')"><span>Client
                Documents</span></a>
            <a class="im-list-group-item"
              [ngClass]="{'activeItem': selectedItem === 6, 'inActiveItem':  selectedItem !== 6, 'disabled': stepDetails.stepStatusDetails.stepStatusCode === 'NEW' }"
              (click)="handleDocuments(6, true, 'Upload Related Project Documents', 'PETPROJDOC')"><span>Project
                Documents</span></a>
          </div>
        </div>
      </div>
      <div class="col-md pl-0 pr-0" *ngIf="payload">
        <ng-template [ngIf]="selectedItem === 0 && countryList && countryList.length > 0">
          <app-supervisor-details [payload]="payload" [primaryData]="primaryData" [stepDetails]="stepDetails"
            [workLocationTypeCode]="workLocationTypeCode" [countryList]="countryList">
          </app-supervisor-details>
        </ng-template>
        <ng-template [ngIf]="tabType && tabType === 'editor'">
          <app-project-details [projectList]="ListOfComanyProjects" [payload]="payload" [primaryData]="primaryData"
            [stepDetails]="stepDetails" [showProjectName]="showProjectName" [editorName]="editorName"
            [tabIndex]="selectedItem" [editorLabel]="editorLabel">
          </app-project-details>
        </ng-template>
        <ng-template [ngIf]="tabType && tabType === 'fileUpload'">
          <app-client-project-documents [projectId]="selectedProject ? selectedProject.id : null"
            [primaryData]="primaryData" [stepDetails]="stepDetails"
            [documentTypeDropdownCode]="documentTypeDropdownCode" [fileUploaderName]="fileUploaderName">
          </app-client-project-documents>
        </ng-template>
        <ng-template [ngIf]="selectedItem !== 0" dynamicComponentPlaceHolder></ng-template>
      </div>
    </div>
  </div>

  <p-divider></p-divider>
  <div class="row mt-n3">
    <lib-custom-questionnaire [stepId]="primaryData.stepId" [taskId]="primaryData.taskId" [viewQuestions]="false"
      [showSaveBtn]="true" [loadedIn]="stepDetails.stepName" [isSection]="false"></lib-custom-questionnaire>
  </div>
  <p-divider></p-divider>

  <div class="row pt-3 mt-n3" *ngIf="isLastTab">
    <div class="col-md-6"> </div>
    <div class="col-md-6 completeStep">
      <lib-step-status-update-component-library
        [visaType]="primaryData.caseType != 'I130' ? primaryData.visatype : primaryData.caseType"
        [stepDetails]="stepDetails" [type]="typeOfButton" [isViewMode]="this.primaryData['isViewMode']"
        [isCaseRequest]="this.primaryData['isCaseRequest']" [showPreviousStepBtn]="showPrev" [showNextStepBtn]="showNext" class="d-flex col-12 justify-content-end">
      </lib-step-status-update-component-library>
      <!-- <gc-status-update [stepDetails]="stepDetails" [type]="typeOfButton">
      </gc-status-update> -->
    </div>


  </div>
  <div class="row pt-3 mt-n3 lastTab" *ngIf="!isLastTab">
    <span>
      <span class="pi pi-info-circle pr-2 info-line-height"></span>This step can be marked
      complete when you
      reach the last tab, i.e., Project Documents
    </span>
  </div>
</div>

<div id="simplifySteps" class="fluid-container" *ngIf="isSimplifyH1B">
  <div class="row pt-1 d-flex align-items-center">
    <div class="col-md-8 step-title headPortion">
      <h5 class="mb-0">Provide Employer Employee Relationship</h5>
      <!-- <p class="mb-0" style="font-size:14px">Lorem ipsum lorem ipsum</p> -->
    </div>
    <div class="col-md-4 editBtn headerSubPortion"> 
      <ul>
        <li class="firstPortion"> 
          <lib-step-status-update-component-library
          [visaType]="primaryData.caseType != 'I130' ? primaryData.visatype : primaryData.caseType"
          [stepDetails]="stepDetails" type="EDIT" [isViewMode]="primaryData['isViewMode']"
          [isCaseRequest]="primaryData['isCaseRequest']">
        </lib-step-status-update-component-library>
        </li>
        <li class="secondPortion">
          <lib-maximize-dialog-library [configData]="{'layoutCode':primaryData?.layoutCode}"></lib-maximize-dialog-library>
          <span (click)="handleClose();" class="close-icon pi pi-times pull-right"></span>
        </li>
      </ul>  
    </div> 

  </div>
  <div class="row">
    <div class="col-md-12 p-0">
      <lib-assigning-task-library [visaType]="primaryData.visatype" [stepDetails]="stepDetails"
        (updateStatus)="updateStepStatus($event)" [isViewMode]="primaryData['isViewMode']"
        [isCaseRequest]="primaryData['isCaseRequest']">
      </lib-assigning-task-library>
    </div>
  </div>
  <lib-last-update-library [inputDetails]="stepDetails" [primaryData]="primaryData" [sectionCode]="sectionCode">
  </lib-last-update-library>
  <div class="row">
    <div class="col-12 infoPortion">
      <p class="m-0">How to ON/OFF Skip Step?</p><mat-icon matTooltip="1. To skip any of the steps, use the slider. 
      2. Turning ON the slider (to the right, shows in blue) skips the step. 
      3. Turning OFF the slider (default left, shows in grey) retains the step in the petition.">info</mat-icon> 
    </div>
  </div>
  <!-- <div class="row mt-n3 mb-3">
    <div class="col-6 py-2 last-modified">
      <label class="last-modified-label mb-0">Last updated by : </label>
      <label class="last-modified-value mb-0 ml-2">{{ stepDetails.modifiedUserName ? stepDetails.modifiedUserName : '-'
        }}</label>
    </div>
    <div class="col-6 py-2 last-modified">
      <label class="last-modified-label mb-0">Last updated on : </label>
      <label class="last-modified-value mb-0 ml-2">{{ modifiedDate ? modifiedDate : '-' }}</label>
    </div>
  </div> -->


  <div>
    <div class="row">
      <div class="col-md-12">
        <h6 class="custom-heading p-text-bold">Select project name from dropdown and enter project details below</h6>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-md-5">
        <label class="label d-block">Project Name</label>
        <p-dropdown appendTo="body" [options]="ListOfComanyProjects" [style]="{'width':'100%'}"
          [(ngModel)]="selectedProject" optionLabel="projectName" placeholder="Select Project"
          (onChange)="changeProject()" [disabled]="disabledControls"></p-dropdown>
      </div>
      <div class="col-md-7" *ngIf="selectedProject && selectedProject.id">
        <label class="label d-block">Save <small>(Project Details, Project Milestone, Project Staffing, Additional
            Details)</small></label>
        <button type="button" (click)="savePetition()" class="p-btn btn btn-primary mr-3">Save</button>
        <!-- <button type="button" (click)="saveMasterData()" class="p-btn btn btn-primary mr-3">Save Master Data</button> -->
      </div>
    </div>
  </div>

  <div *ngIf="isSimplifyH1B">
 
    <p-accordion *ngFor="let item of skipList" [ngSwitch]="item?.layOutCode">
      <!-- Supervisor Details -->
      <p-accordionTab [selected]="selectedItem === 0" *ngSwitchCase="item?.layOutCode === 'SUPERVISOR' ? 'SUPERVISOR' : 'BEN_SUPERVISOR'" (click)="handleCustSupervisor()"
        [ngClass]="{'disabled': stepDetails.stepStatusDetails.stepStatusCode === 'NEW' }"
        [disabled]="stepDetails.stepStatusDetails.stepStatusCode === 'NEW'">
        <ng-template pTemplate="header">
          <div class="stepHeader">
            <div class="headPortion"> 
              <h6 [ngClass]="item && item.disabled == true ? 'disableAccordion' : ''">Supervisor</h6><mat-icon *ngIf="item && item.disabled && item.child && item.child.length > 0" [matTooltip]="infoMsg">info</mat-icon>
            </div>
            <div class="skipPortion" [ngClass]="disabledControls == true ? 'disableAccordion' : ''">Skip Step
              <p-inputSwitch [(ngModel)]="item && item.disabled" class="pl-2"  (onChange)="handleSwitchChange($event, item)">
              </p-inputSwitch>
            </div>
          </div>
        </ng-template>
        <ng-template pTemplate="content">
          <app-supervisor-details *ngIf="payload && countryList && countryList.length > 0" [payload]="payload"
            [primaryData]="primaryData" [stepDetails]="stepDetails" [workLocationTypeCode]="workLocationTypeCode"
            [countryList]="countryList" [simplifyH1b]="true" [ngClass]="item.disabled == true ? 'disableAccordion' : ''" style="width:100%">
          </app-supervisor-details>
        </ng-template>
        
      </p-accordionTab>

      <!-- Project Details -->
      <p-accordionTab [selected]="selectedItem === 1" *ngSwitchCase="item?.layOutCode === 'PROJECT_DETAILS' ? 'PROJECT_DETAILS' : 'BEN_PROJECT_DETAILS'"
        (click)="handleDetails(1, false, true, 'Project Details', '(Brief about project)', null)"
        [disabled]="stepDetails.stepStatusDetails.stepStatusCode === 'NEW'"
        [ngClass]="{'disabled-tab': stepDetails.stepStatusDetails.stepStatusCode === 'NEW' }">
        <ng-template pTemplate="header">
          <div class="stepHeader">
            <div class="headPortion">
              <h6 [ngClass]="item && item.disabled == true ? 'disableAccordion' : ''">Project Details</h6><mat-icon *ngIf="item && item.disabled && item.child && item.child.length > 0" [matTooltip]="infoMsg">info</mat-icon>
            </div>
            <div class="skipPortion" [ngClass]="disabledControls == true ? 'disableAccordion' : ''">Skip Step
              <p-inputSwitch [(ngModel)]="item && item.disabled" class="pl-2"  (onChange)="handleSwitchChange($event, item)">
              </p-inputSwitch>
            </div>
          </div>
        </ng-template>
        <ng-template pTemplate="content">
          <app-project-details *ngIf="payload && tabType && tabType === 'editor'" [projectList]="ListOfComanyProjects" [ngClass]="item.disabled == true ? 'disableAccordion' : ''"
          [payload]="payload" [primaryData]="primaryData" [stepDetails]="stepDetails"
          [showProjectName]="showProjectName" [editorName]="editorName" [tabIndex]="1" [editorLabel]="editorLabel" [simplifyH1b]="true">
        </app-project-details>
        </ng-template>
        
      </p-accordionTab>

      <!-- Project Milestone -->
      <p-accordionTab [selected]="selectedItem === 2" *ngSwitchCase="item?.layOutCode === 'PROJECT_MILESTONE' ? 'PROJECT_MILESTONE' : 'BEN_PROJECT_MILESTONE'" 
        (click)="handleDetails(2, false, false, workLocationTypeCode, null, 'Enter Project Timelines')"
        [ngClass]="{'disabled-tab': stepDetails.stepStatusDetails.stepStatusCode === 'NEW' }"
        [disabled]="stepDetails.stepStatusDetails.stepStatusCode === 'NEW'">
        <ng-template pTemplate="header">
          <div class="stepHeader">
            <div class="headPortion">
              <h6 [ngClass]="item && item.disabled == true ? 'disableAccordion' : ''">Project Milestone</h6><mat-icon *ngIf="item && item.disabled && item.child && item.child.length > 0" [matTooltip]="infoMsg">info</mat-icon>
            </div>
            <div class="skipPortion" [ngClass]="disabledControls == true ? 'disableAccordion' : ''">Skip Step
              <p-inputSwitch [(ngModel)]="item && item.disabled" class="pl-2"  (onChange)="handleSwitchChange($event, item)">
              </p-inputSwitch>
            </div>
          </div>
        </ng-template>
        <ng-template pTemplate="content">
          <app-project-details *ngIf="payload && tabType && tabType === 'editor'" [projectList]="ListOfComanyProjects" [ngClass]="item.disabled == true ? 'disableAccordion' : ''"
          [payload]="payload" [primaryData]="primaryData" [stepDetails]="stepDetails"
          [showProjectName]="showProjectName" [editorName]="editorName" [tabIndex]="2" [editorLabel]="editorLabel" [simplifyH1b]="true">
        </app-project-details>
        </ng-template>
        
      </p-accordionTab>

      <!-- Project Staffing -->
      <p-accordionTab [selected]="selectedItem === 3" *ngSwitchCase="item?.layOutCode === 'PROJECT_STAFFING' ? 'PROJECT_STAFFING' : 'BEN_PROJECT_STAFFING'" 
        (click)="handleDetails(3, false, false, workLocationTypeCode, null, 'Enter Project Staffing details')"
        [ngClass]="{'disabled-tab': stepDetails.stepStatusDetails.stepStatusCode === 'NEW' }"
        [disabled]="stepDetails.stepStatusDetails.stepStatusCode === 'NEW'">
        <ng-template pTemplate="header">
          <div class="stepHeader">
            <div class="headPortion">
              <h6 [ngClass]="item && item.disabled == true ? 'disableAccordion' : ''">Project Staffing</h6><mat-icon *ngIf="item && item.disabled && item.child && item.child.length > 0" [matTooltip]="infoMsg">info</mat-icon>
            </div>
            <div class="skipPortion" [ngClass]="disabledControls == true ? 'disableAccordion' : ''">Skip Step
              <p-inputSwitch [(ngModel)]="item && item.disabled" class="pl-2"  (onChange)="handleSwitchChange($event, item)">
              </p-inputSwitch>
            </div>
          </div>
        </ng-template>
        <ng-template pTemplate="content">
          <app-project-details *ngIf="payload && tabType && tabType === 'editor'" [projectList]="ListOfComanyProjects" [ngClass]="item.disabled == true ? 'disableAccordion' : ''" 
          [payload]="payload" [primaryData]="primaryData" [stepDetails]="stepDetails"
          [showProjectName]="showProjectName" [editorName]="editorName" [tabIndex]="3" [editorLabel]="editorLabel" [simplifyH1b]="true">
        </app-project-details>
        </ng-template>
        
      </p-accordionTab>

      <!-- Additional Details -->
      <p-accordionTab [selected]="selectedItem === 4" *ngSwitchCase="item?.layOutCode === 'ADDITIONAL_DETAILS' ? 'ADDITIONAL_DETAILS' : 'BEN_ADDITIONAL_DETAILS'"  
        (click)="handleDetails(4, false, false, workLocationTypeCode, null, 'Enter Any Other Relevant Details')"
        [ngClass]="{'disabled-tab': stepDetails.stepStatusDetails.stepStatusCode === 'NEW' }"
        [disabled]="stepDetails.stepStatusDetails.stepStatusCode === 'NEW'">
        <ng-template pTemplate="header">
          <div class="stepHeader">
            <div class="headPortion">
              <h6 [ngClass]="item && item.disabled == true ? 'disableAccordion' : ''">Additional Details</h6><mat-icon *ngIf="item && item.disabled && item.child && item.child.length > 0" [matTooltip]="infoMsg">info</mat-icon>
            </div>
            <div class="skipPortion" [ngClass]="disabledControls == true ? 'disableAccordion' : ''">Skip Step
              <p-inputSwitch [(ngModel)]="item && item.disabled" class="pl-2"  (onChange)="handleSwitchChange($event, item)">
              </p-inputSwitch>
            </div>
          </div>
        </ng-template>
        <ng-template pTemplate="content">
          <app-project-details [ngClass]="item.disabled == true ? 'disableAccordion' : ''" *ngIf="payload && tabType === 'editor'" [projectList]="ListOfComanyProjects"
          [payload]="payload" [primaryData]="primaryData" [stepDetails]="stepDetails"
          [showProjectName]="showProjectName" [editorName]="editorName" [tabIndex]="4" [editorLabel]="editorLabel" [simplifyH1b]="true">
        </app-project-details>
        </ng-template>
        
      </p-accordionTab>

      <!-- Client Documents -->
      <p-accordionTab [selected]="selectedItem === 5" *ngSwitchCase="item?.layOutCode === 'CLIENT_DOCUMENTS' ? 'CLIENT_DOCUMENTS' : 'BEN_CLIENT_DOCUMENTS'"  
        (click)="handleDocuments(5, false, 'Upload Related Client Documents', 'PETDOCTYP')"
        [ngClass]="{'disabled-tab': stepDetails.stepStatusDetails.stepStatusCode === 'NEW' }"
        [disabled]="stepDetails.stepStatusDetails.stepStatusCode === 'NEW'">
        <ng-template pTemplate="header">
          <div class="stepHeader">
            <div class="headPortion">
              <h6 [ngClass]="item && item.disabled == true ? 'disableAccordion' : ''">Client Documents</h6><mat-icon *ngIf="item && item.disabled && item.child && item.child.length > 0" [matTooltip]="infoMsg">info</mat-icon>
            </div>
            <div class="skipPortion" [ngClass]="disabledControls == true ? 'disableAccordion' : ''">Skip Step
              <p-inputSwitch [(ngModel)]="item && item.disabled" class="pl-2"  (onChange)="handleSwitchChange($event, item)">
              </p-inputSwitch>
            </div>
          </div>
        </ng-template>
        <ng-template pTemplate="content">
          <app-client-project-documents [ngClass]="item.disabled == true ? 'disableAccordion' : ''"  *ngIf="payload && tabType && tabType === 'fileUpload'" [projectId]="selectedProject ? selectedProject.id : null"
          [primaryData]="primaryData" [stepDetails]="stepDetails" [documentTypeDropdownCode]="documentTypeDropdownCode"
          [fileUploaderName]="fileUploaderName">
        </app-client-project-documents>
        </ng-template>
        
      </p-accordionTab>

      <!-- Project Documents -->
      <p-accordionTab [selected]="selectedItem === 6" *ngSwitchCase="item?.layOutCode === 'PROJECT_DOCUMENTS' ? 'PROJECT_DOCUMENTS' : 'BEN_PROJECT_DOCUMENTS'" 
        (click)="handleDocuments(6, false, 'Upload Related Project Documents', 'PETPROJDOC')"
        [ngClass]="{'disabled-tab': stepDetails.stepStatusDetails.stepStatusCode === 'NEW' }"
        [disabled]="stepDetails.stepStatusDetails.stepStatusCode === 'NEW'">
        <ng-template pTemplate="header">
          <div class="stepHeader">
            <div class="headPortion">
              <h6 [ngClass]="item && item.disabled == true ? 'disableAccordion' : ''">Project Documents</h6><mat-icon *ngIf="item && item.disabled && item.child && item.child.length > 0" [matTooltip]="infoMsg">info</mat-icon>
            </div>
            <div class="skipPortion" [ngClass]="disabledControls == true ? 'disableAccordion' : ''">Skip Step
              <p-inputSwitch [(ngModel)]="item && item.disabled" class="pl-2"  (onChange)="handleSwitchChange($event, item)">
              </p-inputSwitch>
            </div>
          </div>
        </ng-template>
        <ng-template pTemplate="content">
          <app-client-project-documents [ngClass]="item.disabled == true ? 'disableAccordion' : ''" *ngIf="payload && tabType && tabType === 'fileUpload'" [projectId]="selectedProject ? selectedProject.id : null"
          [primaryData]="primaryData" [stepDetails]="stepDetails" [documentTypeDropdownCode]="documentTypeDropdownCode"
          [fileUploaderName]="fileUploaderName">
        </app-client-project-documents>
      </ng-template>
      </p-accordionTab>

      <p-accordionTab [selected]="selectedItem === 7" *ngSwitchCase="item?.layOutCode === 'PETITIONER_DETAILS' ? 'PETITIONER_DETAILS' : 'BEN_PETITIONER_DETAILS'" (click)="handleDocuments(7, true, 'petitioner details', 'COMPOTHDOC')"
        [ngClass]="{'disabled-tab': stepDetails.stepStatusDetails.stepStatusCode === 'NEW' }"
        [disabled]="stepDetails.stepStatusDetails.stepStatusCode === 'NEW'"> 
        <ng-template pTemplate="header">
          <div class="stepHeader">
            <div class="headPortion">
              <h6 [ngClass]="item && item.disabled == true ? 'disableAccordion' : ''">Petitioner Details</h6><mat-icon *ngIf="item && item.disabled && item.child && item.child.length > 0" [matTooltip]="infoMsg">info</mat-icon>
            </div>
            <div class="skipPortion" [ngClass]="disabledControls == true ? 'disableAccordion' : ''">Skip Step
              <p-inputSwitch [(ngModel)]="item && item.disabled" class="pl-2"  (onChange)="handleSwitchChange($event, item)">
              </p-inputSwitch>
            </div>
          </div>
        </ng-template>
        <ng-template pTemplate="content">
          <petitioner-details-library [ngClass]="item.disabled == true ? 'disableAccordion' : ''"  [primaryData]="primaryData" [isSimplifyH1B]="true"
          [isForeignCompany]="false" style="width:100%"></petitioner-details-library>
        </ng-template>
        <!-- <h1b-petitioner-details [isSimplifyH1B] = "true"
          style="width:100%" ></h1b-petitioner-details> -->
          
      </p-accordionTab>
    </p-accordion>
  </div>

  <p-divider></p-divider>
  <div class="row pt-3 mt-n3">
    <lib-custom-questionnaire [stepId]="primaryData.stepId" [taskId]="primaryData.taskId" [viewQuestions]="false"
      [showSaveBtn]="true" [loadedIn]="stepDetails.stepName" [isSection]="false"></lib-custom-questionnaire>
  </div>
  <p-divider></p-divider>

  <div class="row pt-3 mt-n3" *ngIf="isLastTab">
    <div class="col-md-6"> </div>
    <div class="col-md-6 completeStep">
      <lib-step-status-update-component-library
        [visaType]="primaryData.caseType != 'I130' ? primaryData.visatype : primaryData.caseType"
        [stepDetails]="stepDetails" [type]="typeOfButton" [isViewMode]="this.primaryData['isViewMode']"
        [isCaseRequest]="this.primaryData['isCaseRequest']" [showPreviousStepBtn]="showPrev" [showNextStepBtn]="showNext" class="d-flex col-12 justify-content-end">
      </lib-step-status-update-component-library>
      <!-- <gc-status-update [stepDetails]="stepDetails" [type]="typeOfButton">
      </gc-status-update> -->
    </div>


  </div>
  <div class="row pt-3 mt-n3 lastTab" *ngIf="!isLastTab">
    <span>
      <span class="pi pi-info-circle pr-2 info-line-height"></span>This step can be marked
      complete when you
      reach the last tab, i.e., Project Documents
    </span>
  </div>
</div>