<div class="container-fluid containerBox" *ngIf="stepDetails && primaryData">
  <div class="row pt-4 d-flex align-items-center">
    <div class="col-md-7 step-title p-0">
      <h5 class="mb-0 common-bene-class-for-translation" id="empHistoryLbl">Employment History for the last five years</h5>
      <p class="mb-0 common-bene-class-for-translation" id="dataFromBeneProfileLbl" style="font-size:14px">Data fetched from the Beneficiary's profile.</p>
    </div>
  </div>

  <div class="btn-group">
    <ng-container>
      <ng-container *ngIf="!isEditWorkExperience">
        <button pButton [disabled]="disabledControls" (click)="sync()" type="button"
          class="p-button-rounded p-button-text pull-right syncBtn common-bene-class-for-translation"  pTooltip="Sync With Profile"
          icon="pi pi-arrow-right-arrow-left"><span class="common-bene-class-for-translation" id="syncBtnId">SYNC</span></button>
        <button pButton [disabled]="disabledControls" (click)="merge()" type="button"
          class="p-button-rounded p-button-text pull-right syncBtn" pTooltip="Merge Both Profile & Petition Data" icon="pi pi-sync
          "><span class="common-bene-class-for-translation" id="mergeBtnId">MERGE</span></button>
        <button pButton [disabled]="disabledControls" (click)="reset()" type="button"
          class="p-button-rounded p-button-text pull-right syncBtn" pTooltip="Reset Petition Data"
          icon="pi pi-undo"> <span class="common-bene-class-for-translation" id="resetBtnId">RESET</span></button>
      </ng-container>
    </ng-container>
    <button type="button" pButton class="p-button-outlined  btnData" [pTooltip]="isEditWorkExperience ? 'Close' : 'Edit'"
      [disabled]="disabledControls" (click)="updateExperience()">
      <mat-icon class="mr-1 icn">{{ isEditWorkExperience ? 'cancel' : 'edit' }}</mat-icon>
     <span class="common-bene-class-for-translation" id="editCloseBtn"> {{ isEditWorkExperience ? 'Close' : 'Edit' }} </span>
    </button>
  </div>

  <p-divider></p-divider>

  <div *ngIf="isEditWorkExperience">
    <lib-generic-beneficiary-work-experience [primaryData]="primaryData" [isWizard]="true" [isTimeLine]="true">
    </lib-generic-beneficiary-work-experience>
      <!-- <edit-work-experience-details [primaryData]="primaryData" [userId]="userId" [type]="type"></edit-work-experience-details> -->
  </div>

  <div *ngIf="!isEditWorkExperience">
    <div class="row mb-2">
      <div class="col-8"></div>
    </div>
    <div class="row pt-3">
      <div class="profile-title">
        <h6 class="common-bene-class-for-translation" id="addedWorkExpLbl">Added Work Experience Details</h6>
      </div>
      <p-table [columns]="experienceCols" *ngIf="gcWorkExperienceDetailsResponseList.length > 0; else noWorkExp"
        class="profile-table pa-table im-card-no-border-table im-card-table-noborder-spacing table"
        styleClass="p-datatable-gridlines" [value]="gcWorkExperienceDetailsResponseList" dataKey="id">
        <ng-template pTemplate="header" let-columns>
          <tr class="text-uppercase">
            <th style="width: 3rem"> </th>
            <th *ngFor="let col of columns; let i = index">
              <span class="common-bene-class-for-translation" id="workExpDetailsCol+{{i}}">{{col.header}}</span>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-expanded="expanded" let-columns="columns" let-rowIndex="rowIndex">
          <tr>
            <td class="expandIcon">
              <button type="button" pButton pRipple [pRowToggler]="rowData"
                class="p-button-text p-button-rounded p-button-plain"
                [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'">
              </button>
            </td>
            <td class="common-bene-class-for-translation" id="designationColId">{{rowData['designation']}}</td>
            <td class="common-bene-class-for-translation" id="companyNameId"> {{rowData['companyName']}} </td>
            <td> {{rowData['startDate'] | VisaDateFormat}} </td>
            <td>
              <span *ngIf="rowData['endDate']">{{rowData['endDate'] | VisaDateFormat}}</span>
              <span *ngIf="!rowData['endDate']" class="common-bene-class-for-translation" id="presentTillDtId">Present</span>
            </td>
            <td class="table-btn">
              <button type="button" pButton icon="pi pi-trash" pTooltip="Delete Experience" title="Delete Experience"
                (click)="deleteExperienceList(rowData)" class="p-button-text p-button-rounded"
                [disabled]="disabledControls"></button>
              <button type="button" pButton icon="pi pi-refresh" pTooltip="Sync" title="Sync"
                (click)="handleExperienceSync(rowData)" class="p-button-text p-button-rounded"
                [disabled]="disabledControls"></button>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="rowexpansion" let-subexperiencelist>
          <tr>
            <td colspan="6">
              <div class="p-p-3 sub-task-type">
                <p-table class="sub-task-table profile-table" [columns]='experienceSubCols'
                  [value]="[subexperiencelist]" dataKey="subTaskId">
                  <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns; let i = index">
              <span class="common-bene-class-for-translation" id="subExpHeader+{{i}}">{{col.header}}</span>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr>
            <td *ngFor="let col of columns; let i = index">
              <span class="common-bene-class-for-translation" id="subExpCol+{{i}}">
                {{rowData[col.field]}}
              </span>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="5" class="common-bene-class-for-translation" id="noDataMsg">No Data</td>
          </tr>
        </ng-template>
      </p-table>
      <div class="courseList pt-4" *ngIf="subexperiencelist?.clients?.length > 0">
        <h6 class="common-bene-class-for-translation" id="clientDetailsLblId">Clients Details</h6>
        <p-card class="courses-card">
          <div class="row document-header">
            <div class="col-md-4">
              <h6 class="common-bene-class-for-translation" id="clientDetailNameLbl">Client Name</h6>
            </div>
            <div class="col-md-4">
              <h6 class="common-bene-class-for-translation" id="clientStartDateLbl">Start Date</h6>
            </div>
            <div class="col-md-4">
              <h6 class="common-bene-class-for-translation" id="clientEndDateLbl">End Date</h6>
            </div>
          </div>
          <div class="row courses courses-title d-flex align-items-center"
            *ngFor="let item of subexperiencelist.clients; let i = index">
            <div class="col-md-4 common-bene-class-for-translation" id="clientNameColText+{{i}}">
              {{ item.clientName}}
            </div>
            <div class="col-md-4">
              {{ item.startDate | VisaDateFormat}}
            </div>
            <div class="col-md-4">
              {{ item.endDate | VisaDateFormat}}
            </div>
          </div>
        </p-card>
      </div>
      <div class="courseList pt-4" *ngIf="subexperiencelist?.jobDuties?.length > 0">
        <h6 class="common-bene-class-for-translation" id="dutiesAndSubDutiesLbl">Duties & Sub-duties</h6>
        <p-card class="courses-card">
          <div class="row courses courses-title mt-2 mb-2"
            *ngFor="let item of subexperiencelist.jobDuties; let i = index">
            <div class="col-md-1 mt-3 text-center"><span class="duty-list">{{i+1}}</span></div>
            <div class="col-md-11 pl-0">
              <div class="row col-md-12 duty-title d-flex align-items-center">
                <div class="col-md-11 pr-0 pl-0 common-bene-class-for-translation" id="dutyLbl" style="font-weight: 600;">
                  Duty
                </div>
                <div class="col-md-1 pr-0 pl-0">
                  <button pButton pTooltip="Delete Duty" title="Delete Duty" [disabled]="disabledControls" type="button"
                    (click)="dutiesDelete(item, subexperiencelist)" class="p-button-rounded p-button-text pull-right"
                    icon="pi pi-trash"></button>
                </div>
              </div>
              <div class="row col-md-12 duty-txt common-bene-class-for-translation" id="dutyTextId+{{i}}" style="font-size: 14px;">
                {{item.duty}}
              </div>
              <div class="row col-md-12 duty-title common-bene-class-for-translation" id="subDutyLbl" style="font-size: 13px;font-weight: 600;"
                *ngIf="item.subDuties.length > 0"> Sub-Duty
              </div>
              <div class="row col-md-12 p-0" *ngFor="let subDuty of item.subDuties">
                <div class="row col-md-12  duty-txt">
                  <div class="col-md-11 p-0 common-bene-class-for-translation" id="subDutyDesc+{{i}}" style="font-size: 14px;">
                    {{subDuty.subDutyDescription}}
                  </div>
                  <div class="col-md-1 p-0">
                    <button pButton [disabled]="disabledControls" (click)="subDutiesDelete(subDuty, subexperiencelist)"
                      type="button" class="p-button-rounded p-button-text pull-right" pTooltip="Delete Sub Duty"
                      title="Delete Sub Duty" icon="pi pi-trash"></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </p-card>
      </div>
      <div class="courseList pt-4" *ngIf="subexperiencelist?.tools?.length > 0">
        <h6 class="common-bene-class-for-translation" id="addedToolsAndTechLbl">Added Tools & Technology</h6>
        <div>
          <div class="tools-and-technologies clearfix">
            <span *ngIf="!subexperiencelist.tools || (subexperiencelist.tools && subexperiencelist.tools.length === 0)"
              class="no-records common-bene-class-for-translation" id="NoRecordsMsg">No
              Tools & Technologies added.</span>
            <div class="tools-and-technologies-chips" [ngClass]="{'alt': i%2 !== 0}"
              *ngFor="let tool of subexperiencelist.tools; let i = index;">
              <div class="suty-slno"><span>{{ i + 1 }}</span></div> <span class="common-bene-class-for-translation" id="skillName+{{i}}">{{ tool.skillName }}</span>
              <!-- <button type="button" *ngIf="!disabledControls" (click)="handleToolsDelete(tool)" pButton
                icon="pi pi-trash" class="p-panel-header-icon p-link">
              </button> -->
            </div>
          </div>
          <!-- <p-chips separator="," disabled="false" class="greenCard-chip" allowDuplicate="false"
              [(ngModel)]="subexperiencelist.tools">
              <ng-template let-item pTemplate="item" (onRemove)="handleToolsDelete(item)">
                {{item | json}}
              </ng-template>
            </p-chips> -->
        </div>
      </div>
      <div class="courseList pt-4" *ngIf="subexperiencelist?.documents?.length > 0">
        <h6 class="common-bene-class-for-translation" id="docListLbl">List of Documents</h6>
        <p-card class="courses-card">
          <div class="row document-header">
            <div class="col-md-4">
              <h6 class="common-bene-class-for-translation" id="docTypeLbl">Document Type</h6>
            </div>
            <div class="col-md-4">
              <h6 class="common-bene-class-for-translation" id="docNameLbl">Document Name</h6>
            </div>
            <div class="col-md-4">
              <h6 class="common-bene-class-for-translation" id="actionLbl">Action</h6>
            </div>
          </div>
          <div class="row courses courses-title d-flex align-items-center"
            *ngFor="let item of subexperiencelist.documents; let i = index">
            <div class="col-md-4">
              <span class="duty-list">{{i+1}}</span>
              <span class="common-bene-class-for-translation" id="fileCatNameId+{{i}}">
                {{ item?.fileCategory.name }}
              </span>
            </div>
            <div class="col-md-4">
              <span *ngIf="item.isPresent" class="common-bene-class-for-translation" id="fileNameId+{{i}}">
                {{ item.fileName}}
              </span>
              <span *ngIf="!item.isPresent" class="redColor d-flex align-items-center  common-bene-class-for-translation" id="docMissingLbl">
                <i class="pi pi-exclamation-triangle pr-2"></i> Document Missing
              </span>
            </div>
            <div class="col-md-4 pull-right d-flex align-items-center" *ngIf="item?.isPresent">
              <button pButton type="button" icon="pi pi-eye" pTooltip="View" title="View"
                [disabled]="!item.fileLocation" (click)="openDoc(item)" class="p-button-text p-button-rounded"></button>
              <lib-send-back-component *ngIf="userType !== 'Beneficiary'" [disabledControls]="disabledControls"
                [stepDetails]="stepDetails" [primaryData]="primaryData" [item]="item">
              </lib-send-back-component>
              <button pButton pRipple type="button" icon="pi pi-trash" pTooltip="Delete" title="Delete"
                [disabled]="disabledControls" (click)="deleteDocuments(subexperiencelist, item)"
                class="p-button-text p-button-rounded"></button>
            </div>
            <div class="col-md-2" *ngIf="!item?.isPresent">
              <notification-component-library *ngIf="userType !== 'Beneficiary'" [disabledControls]="disabledControls"  [destination]="notifyDestinationUser"
                [stepDetails]="stepDetails" [primaryData]="primaryData" [item]="item">
              </notification-component-library>
              <!-- <button *ngIf="userType !== 'Beneficiary'" pButton pRipple pTooltip="Not Applicable"
                                    type="button" icon="pi pi-ban" [disabled]="disabledControls"
                                    class="p-button-text p-button-rounded"></button> -->
            </div>
          </div>
        </p-card>
      </div>
    </div>
    </td>
    </tr>
    </ng-template>
    </p-table>
    <ng-template #noWorkExp>
      <p class="pb-3 common-bene-class-for-translation" id="beforeSyncText">Work experience details need to be filled under Beneficiary profile before syncing work
        experience data here.</p>
    </ng-template>
  </div>
  </div>


</div>
