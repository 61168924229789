import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import moment from 'moment';
import { Subject, Subscription } from 'rxjs';
import * as I9SectionOneSelector  from 'src/app/app-state/selectors/i9-section-one.selector';
import { AppState } from 'src/app/data/models/app-state';
import { CacheCountryService } from 'src/app/services/cacheCountries.service';
import { CustomValidationService } from 'src/app/services/custom-validation.service';
import * as errorUtils from 'src/app/modules/utility/global-utils';
import { I9Service } from 'src/app/services/i9-service';
import { CountryApiService } from '../../petitioner/services/country.service';
import { MarkCleanFormAction } from 'src/app/app-state/actions/dirty-form.actions';
import { MatDialog } from '@angular/material/dialog';
import { SignatureUploadComponent } from '../signature-upload/signature-upload.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-i9-beneficiary-details',
  templateUrl: './i9-beneficiary-details.component.html',
  styleUrls: ['./i9-beneficiary-details.component.scss']
})
export class I9BeneficiaryDetailsComponent implements OnInit {
  @Input() section1Info;
  @Input() beneSignature;
  @Input() isEdit: boolean; 
  beneSelfDetailsForm: FormGroup;
  submitted: boolean = false;
  currentAddressObj: any = null;
  primaryEmail: string;
  mobileNumber: string;
  listCountry: [];
  listStates: [];
  listForeignCountry = [];
  today = moment().toLocaleString();
  beneFormChangesSubscription: Subscription;
  destroy$ = new Subject<void>();
  currentDate = moment().format('MM-DD-YYYY');
  signatureImage = '';
  ssn_hide: boolean = false;
  beneSignedDate = moment().format('MM-DD-YYYY');
  showCropperSection: boolean = false;
  signatureImageForm = new FormData();
  beneficiaryId: number = 0;
  constructor(private store: Store<AppState>,
    private cacheCountryService: CacheCountryService,
    private fb: FormBuilder,
    private customValidationService: CustomValidationService,
    private i9Service: I9Service,
    private countryApiService: CountryApiService,
    public dialog: MatDialog,
    private toastr: ToastrService
    ) {

    this.cacheCountryService.getCachedCountryList().subscribe(data => {
      if (data.listCountries.length > 0) {
        this.listCountry = data.listCountries;
        this.listForeignCountry = this.listCountry;//this.listCountry.filter((item) => item['countryCode'] !== 'USA');
      }
    });
    // this.beneSelfDetailsForm = this.fb.group(
    //   {
    //     firstName: ['', Validators.required],
    //     lastName: ['', Validators.required],
    //     middleName: [''],
    //     dob: ['', [Validators.required, this.customValidationService.currentDateValidator(this.currentDate)]],
    //     ssn: ['', [Validators.pattern(/^((\\+91-?)|0)?[0-9]{9}$/)]],
    //     benEmail: this.fb.array([
    //       this.fb.group({
    //         email: ['', [Validators.required, Validators.email]],
    //         id: [''],
    //         type: this.fb.group({
    //           code: ['PRIM']
    //         })
    //       })
    //     ]),
    //     benPhone: this.fb.array([
    //       this.fb.group({
    //         phoneNo: ['', [Validators.required, Validators.pattern(/^-?(0|[0-9]\d*)?$/)]],
    //         id: [''],
    //         type: this.fb.group({
    //           code: ['MOBL']
    //         }),
    //         countryCode: this.fb.group({
    //           countryCode: ['', [Validators.required]],
    //           phoneCode: ['']
    //         })
    //       })
    //     ]),
    //     benAddress: this.fb.array([
    //       this.fb.group({
    //         addressLine1: ['', [Validators.required]],
    //         addressLine2: [''],
    //         postCode: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9 ]+$/)]],
    //         doorNo: [''],
    //         id: [''],
    //         streetNo: [''],
    //         countryCode: this.fb.group({
    //           countryCode: ['', [Validators.required]]
    //         }),
    //         stateProvinceCode: [null, [Validators.required]],
    //         stateProvinceName: [''],
    //         city: ['', [Validators.required]],
    //         locality: [''],
    //         type: this.fb.group({            
    //           code: ['CURR']
    //         })
    //       }),
    //       this.fb.group({
    //         addressLine1: ['', [Validators.required]],
    //         addressLine2: [''],
    //         postCode: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9 ]+$/)]],
    //         doorNo: [''],
    //         id: [''],
    //         streetNo: [''],
    //         countryCode: this.fb.group({
    //           countryCode: ['', [Validators.required]]
    //         }),
    //         stateProvinceCode: [null],
    //         stateProvinceName: [''],
    //         city: ['', [Validators.required]],
    //         locality: [''],
    //         type: this.fb.group({            
    //           code: ['PERM']
    //         })
    //       }),
    //       this.fb.group({
    //         addressLine1: [''],
    //         addressLine2: [''],
    //         postCode: ['', [Validators.pattern(/^[a-zA-Z0-9 ]+$/)]],
    //         doorNo: [''],
    //         id: [''],
    //         streetNo: [''],
    //         countryCode: this.fb.group({
    //           countryCode: ['']
    //         }),
    //         stateProvinceCode: [null, [Validators.required]],
    //         stateProvinceName: [''],
    //         city: [''],
    //         locality: [''],
    //         type: this.fb.group({
    //           code: ['FORN']
    //         })
    //       })
    //     ]),      
    //     benSignImage: [''],
    //     beneficiarySignDate: [this.currentDate, [Validators.required]]
    //     }
    // );
  }

  ngOnInit(){    
    this.store.pipe(select(I9SectionOneSelector.selectI9SectionOne)).subscribe( state =>{
      if(state){
        this.section1Info = state;
        let beneData = this.section1Info.beneficiaryDetails || null;
        this.beneficiaryId = this.section1Info.beneficiaryId || 0;
        if(beneData){
          this.currentAddressObj = (beneData?.benAddress && beneData?.benAddress.length>0) ? beneData.benAddress.filter(x => x.type.code === 'CURR')[0]: null;
          this.primaryEmail = (beneData?.benEmail && beneData?.benEmail.length>0) ? beneData.benEmail.filter(x => x.type.code === 'PRIM')[0].email : '';
          this.mobileNumber = (beneData?.benPhone && beneData?.benPhone.length>0) ? beneData.benPhone.filter(x => x.type.code === 'MOBL')[0].phoneNo : '';
          this.beneSignedDate = (beneData.beneficiarySignDate)? moment(beneData.beneficiarySignDate).format('MM-DD-YYYY') : this.currentDate;
          // this.beneSelfDetailsForm.patchValue(beneData);
          // this.beneSelfDetailsForm.updateValueAndValidity();
        }      
      }
    });
  }
  ngOnChanges(e: SimpleChanges) {
    console.log(e);
    if(e?.isEdit?.currentValue){
      this.isEdit = e.isEdit.currentValue;
      console.log('edit check');
      console.log(this.isEdit);
    }
    if(e.beneSignature?.currentValue){
      this.beneSignature = `data:image/jpeg;base64,${e.beneSignature.currentValue}`;
    }
  }
  getGlobalErrorMessages(error: string) {
    return errorUtils.errorMessages.get(error);
  }
  
  dataURItoBlob(dataURI) {
    const binary = atob(dataURI.split(',')[1]);
    const array = [];
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], {
      type: 'image/png',
    });
  }

  selectSignatureImage(){
    this.showCropperSection = false;
    const dialogRef = this.dialog.open(SignatureUploadComponent, {
      panelClass: 'custom-dialog'
    });
  
    dialogRef.afterClosed().subscribe( result => {
      const file = this.dataURItoBlob(result);  
      this.signatureImageForm = new FormData();
      this.signatureImageForm.append("file",file , "mysignature.png");  
      this.i9Service.uploadBeneficiarySignature(this.beneficiaryId, this.signatureImageForm).subscribe((response) => {
            if (response['status'] === 200) {
                 this.toastr.success('Image Uploaded successfully', 'Success');
                 this.beneSignature = `data:image/jpeg;base64,${response['data']['signImage']}`;                     
            }
      }); 
    });
  }

  
  // handleCountryChange(countryCode: string) {
  //   this.listStates = [];
  //   this.countryApiService.getStates(countryCode).subscribe((states: []) => {
  //     this.listStates = states;           
  //     this.applyStateValidation(states);
  //   });
  // }

  // applyStateValidation(states) {
  //   if (states.length > 0) {
  //     this.beneSelfDetailsForm.get('address')['controls'][0].get('stateProvinceCode').setValidators([Validators.required]);
  //   } else {
  //     this.beneSelfDetailsForm.get('address')['controls'][0].get('stateProvinceName').clearValidators();      
  //   }
  //   this.beneSelfDetailsForm.get('address')['controls'][0].get('stateProvinceCode').updateValueAndValidity();
  //   this.beneSelfDetailsForm.get('address')['controls'][0].get('stateProvinceName').updateValueAndValidity();
  // }

  // handleStateChange(statecode, stateIndex) {
  //   this.beneSelfDetailsForm.get('address')['controls'][stateIndex].get('stateProvinceCode').clearValidators();
  //   if (statecode === "null") {
  //     this.beneSelfDetailsForm.get('address')['controls'][stateIndex].get('stateProvinceCode').setValidators(null);
  //     this.beneSelfDetailsForm.get('address')['controls'][stateIndex].get('stateProvinceCode').value = null;
  //     this.beneSelfDetailsForm.get('address')['controls'][stateIndex].get('stateProvinceName').value = '';
  //     this.beneSelfDetailsForm.updateValueAndValidity();
  //   }
  // }
  ngOnDestroy() {
    //this.beneFormChangesSubscription.unsubscribe();
    // this.destroy$.next();
    // this.destroy$.complete();
    // this.store.dispatch(new MarkCleanFormAction({dirty: false}));
  }
}
