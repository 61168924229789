import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { I9RemediationService } from '../../i9-remediation.service'; 
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-folder-structure-guide',
  templateUrl: './folder-structure-guide.component.html',
  styleUrls: ['./folder-structure-guide.component.scss']
})
export class FolderStructureGuideComponent implements OnInit {
  listOfEmails = '';
  constructor(
    public dialogRef: MatDialogRef<FolderStructureGuideComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private i9RemediationService: I9RemediationService,
    private toastrService: ToastrService,
  ) { }

  ngOnInit(): void {
  }
  
  onDismiss() {
    this.dialogRef.close(false);
  }

}
