import { Injectable } from '@angular/core';
import { ImagilityBaseResponse } from 'app-models';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class I9MultiUploadDocumentsServiceService {

  addedDoc = new BehaviorSubject(null);

  constructor(private httpClient:HttpClient) { }

  getUploadedDataForBeneficiary(companyId, I9FormId){
    return this.httpClient.get(`/i9admin/company/${companyId}/i9form/${I9FormId}/getMuitpleUploadDocs`, {}).pipe(
     map((response: ImagilityBaseResponse) => {
       if (response.status === 200) {
         return response;
       }
       else {
         return null;
       }
     }));
  }

  saveDocuments(payload, companyId, beneficiaryId, I9FormId, showListBC, sectionName, obj) {
      return this.httpClient.post(`/i9admin/document/beneficiaries/company/${companyId}/${beneficiaryId}/${obj.groupCode}/${I9FormId}/${obj.refCode}/${obj.issueAuth}/${obj.docNum}/${obj.expDate}/${showListBC}/${sectionName}/saveMultipleDocs`, payload).pipe(
        map((response: ImagilityBaseResponse) => {
          if (response.status === 200) {
            return response;
          }
          else {
            return null;
          }
        }));
  }

  saveDocumentsNew(payload) {
    return this.httpClient.post(`/i9admin/document/beneficiaries/company/saveMultipleDocs`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
        else {
          return null;
        }
      }));
}


  deleteSingleDocument(item, I9FormId) {
    return this.httpClient.delete(`/i9admin/document/i9/${I9FormId}/document/${item.docId}/${item.id}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
        else {
          return null;
        }
      }));
  }

  updateDocumentBulkUpload(payload, companyId, benefiicaryId, I9FormId) {
    return this.httpClient.post(`/i9admin/updateDocs/${companyId}/${benefiicaryId}/${I9FormId}`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
        else {
          return null;
        }
      }));
  }

}
