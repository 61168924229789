import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, filter, map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import * as RolesRouteMap from '../../../data/constants/roles';
import { ConfirmationService } from 'primeng/api';
import { FdnsService } from 'src/app/services/fdns.service';
import { ToastrService } from 'ngx-toastr';
import moment from 'moment';
import { FDNSVisit } from 'src/app/data/models/fdns.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-fdns-visit',
  templateUrl: './fdns-visit.component.html',
  styleUrls: ['./fdns-visit.component.scss'],
  animations: [
    trigger('rowExpansionTrigger', [
      state('void', style({
        transform: 'translateX(-10%)',
        opacity: 0
      })),
      state('active', style({
        transform: 'translateX(0)',
        opacity: 1
      })),
      transition('* <=> *', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ])
  ]
})
export class FdnsVisitComponent implements OnInit {

  fdnsVisitList: FDNSVisit[] = [];
  cols: any = [];
  nestedCols: any = [];
  searchSubscription: Subscription;
  searchText: string;
  totalVisits = 0;
  totalElementCount: number = 0;
  pageSizeOptions = [10, 20, 30];
  pageIndex: number = 0;//default
  pageSize: number = 10;//default
  fdnsVisitId;

  payload: any = {
    "pageNumber": 1,
    "recordsPerPage": 10,
    "searchLocationText": "",
    "visitDate": ""
  }
  @ViewChild("searchFdnsVisitsTable", { static: true }) searchFdnsVisitsTable: ElementRef;

  constructor(private router: Router, private route: ActivatedRoute, private confirmationService: ConfirmationService, private fdnsService: FdnsService, private toastr: ToastrService) { }

  ngOnInit() {

    this.getFdnsVistitList();



    this.cols = [
      { field: 'visitDate', header: 'Date' },
      { field: 'companyName', header: 'Company Name' },
      { field: 'visitLocationName', header: 'Visit Location' },
      { field: 'visitOfficerName', header: 'Visit Officer' },
      { field: 'modeOfVistName', header: 'Mode Of Visit' },
      { field: 'visitOutcomeStatusName', header: 'Status' }


    ];

    this.nestedCols = [
      { field: 'beneficiaryName', header: 'Beneficiary Name' },
      { field: 'visaType', header: 'Visa Type' },
      { field: 'petitionType', header: 'Petition Type' },
      { field: 'jobTitle', header: 'Job Title' }
    ];

    this.searchSetUp();


  }
  searchSetUp() {
    this.searchSubscription = fromEvent(this.searchFdnsVisitsTable.nativeElement, 'keyup').pipe(
      map((event: any) => { return event.target.value; })
      , filter(res => res.length > 2)
      , debounceTime(1000)).subscribe((text: string) => {
        this.searchText = text.trim();
        this.getFdnsVistitList();
      });
  }

  getFdnsVistitList() {
    this.payload.searchLocationText = this.searchText;
    this.payload.pageNumber = this.pageIndex + 1;
    this.payload.recordsPerPage = this.pageSize;

    this.fdnsService.getFdnsVisitList(this.payload).subscribe((response) => {
      // console.log("FDNS Visit list",response)
      this.fdnsVisitList = response.fdns;
      this.totalElementCount = response.totalNumberOfRecords;

    })

  }

  onRowExpand(e) {

  }
  handleEditFdns(fdnsVisit) {
    // this.router.navigate(['./manage-fdns/',fdnsVisit.id], { relativeTo: this.route });
    this.router.navigate([`${RolesRouteMap.userRoleRouteMapping[sessionStorage.getItem('userTypeRole')]}/companies/compliance/compliance/fdns-visit/manage-fdns`, fdnsVisit.id]);

  }

  handleDeleteFdns(fdnsVisit) {
    // console.log("$$$$",fdnsVisit)
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete?',
      header: 'Delete',
      accept: () => {
        this.fdnsService.deleteFdns(fdnsVisit.id).subscribe((response: any) => {
          if (response.status == 200) {
            this.toastr.success(response.message, 'Success');
            if (this.fdnsVisitList.length === 1) {
              this.pageIndex = this.pageIndex > 0 ? (this.pageIndex - 1) : 0;
            }

            this.getFdnsVistitList();
          }
        });
      },
      reject: () => {
      }
    });

  }
  addNewVisit() {
    this.router.navigate([`${RolesRouteMap.userRoleRouteMapping[sessionStorage.getItem('userTypeRole')]}/companies/compliance/compliance/fdns-visit/manage-fdns`]);

  }
  resetSearch(type?) {
    if (type === 'calender') {
      this.payload.visitDate = null;
    } else if (type === 'location') {
      this.searchText = ''; //reset search
    } else {
      this.searchText = ''; //reset search
      this.payload.visitDate = null;
    }

    this.getFdnsVistitList();

  }

  getFilteredData() {
    this.payload.visitDate = this.payload.visitDate ? moment(this.payload.visitDate).format('MM-DD-YYYY') : null;
    this.getFdnsVistitList();

  }

  paginationEvent(event) {
    this.pageSize = event.rows;
    this.pageIndex = event.page;
    this.getFdnsVistitList();

  }

  displayDocument(item) {
    const docPath = item.substring(23);
    window.open(`${environment.docs}${docPath}`, "_blank");
  }
}
