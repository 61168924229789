import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, pluck, catchError, toArray, take } from 'rxjs/operators';
import { ImagilityBaseResponse } from '../../../data/models/response.model';
import { environment } from 'src/environments/environment';
import { EMPTY, Observable, of, throwError } from 'rxjs';
import { BeneficiaryImmigrationInterface } from './beneficiary-immigration.interface';
import { BehaviorSubject } from 'rxjs';
import { EncryptService } from '../../core/http/http-encrypt.service';
import { saveAs } from 'file-saver';

@Injectable({
    providedIn: 'root'
})
export class ApiServicesService {
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };
    // IM-3066 - remove deleted file.
    public deletedfile$ = new BehaviorSubject<any>({});
    public showBeneficiary = new BehaviorSubject<any>(false);

  constructor(private http: HttpClient, private encryptService: EncryptService) { }

    getClientsList(companyId: number) {
        return this.http.get(`/company/${companyId}/addBeneficiaryByPetitioner`).pipe(
            map((response: ImagilityBaseResponse) => {
                return response.status === 200 ? response.data : [];
            })
        );
    }

  getCombinedVisas() {
    return this.http.get(`/task/getAllVisaTypeForBene`).pipe(
      map((response: ImagilityBaseResponse) => {
        return response.status === 200 ? response.data : [];
      })
    );
  }


    saveTrainingDetails(beneficiaryId: number, type: string, payload: any, familyId?) {
        const profileFamilyString = familyId ? `?familyId=${familyId}` : '';
        return this.http.post(`/beneficiary/${beneficiaryId}/profile/professional/${type}${profileFamilyString}`, payload).pipe(
            map((response: ImagilityBaseResponse) => response)
        );
    }

    markComplete(payload, benenficiaryId, companyId) {
        return this.http.post(`/attor/markComplete?benenficiaryId=${benenficiaryId}&companyId=${companyId}`, payload).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status === 200) {
                    return response;
                }
            })
        );
    }

  updateDocDates(beneficiaryId, payload) {
    return this.http.post(`/updateBeneficiary/documentValidityDates/${beneficiaryId}`, payload);
  }


    getTrainingsDetails(beneficiaryId: number, type: string, familyId?) {
        const profileFamilyString = familyId ? `?familyId=${familyId}` : '';
        return this.http.get(`/beneficiary/${beneficiaryId}/profile/professional/${type}${profileFamilyString}`).pipe(
            map((response: ImagilityBaseResponse) => response.data)
        );
    }

    deleteTrainingsDetails(beneficiaryId: number, type: string, professionalId: number, familyId?) {
        const profileFamilyString = familyId ? `?familyId=${familyId}` : '';
        return this.http.delete(`/beneficiary/${beneficiaryId}/profile/professional/${professionalId}/${type}${profileFamilyString}`).pipe(
            map((response) => {
                // IM-3066 - remove deleted file.
                this.deletefile(beneficiaryId);
                return response;
            })
        );
    }

    // Immigration Fmaily

    saveImmgFamilyDetails(beneficiaryId: number, payload: any) {
        return this.http.post(`/beneficiary/${beneficiaryId}/immigration/family`, payload).pipe(
            map((response: ImagilityBaseResponse) => response)
        );
    }

    getImmgFamilyDetails(beneficiaryId: number) {
        return this.http.get(`/beneficiary/${beneficiaryId}/immigration/family`).pipe(
            map((response: ImagilityBaseResponse) => response.data)
        );
    }

    deleteImmgFamilyDetails(beneficiaryId: number, familyId: number) {
        return this.http.delete(`/beneficiary/${beneficiaryId}/immigration/family/${familyId}`).pipe(
            map((data) => {
                // IM-3066 - remove deleted file.
                this.deletefile(beneficiaryId);
                return data;
            })
        );
    }


    saveBeneficiaryDetails(beneficiaryId: number, type: string, payload: any, familyId?) {
        const profileFamilyString = familyId ? `?familyId=${familyId}` : '';
        return this.http.post(`/beneficiary/${beneficiaryId}/profile/${type}${profileFamilyString}`, payload).pipe(
            map((response) => response)
        );
    }

  saveSimpleBeneficiaryDetails(beneficiaryId: number, type: string, payload: any, familyId?) {
    const profileFamilyString = familyId ? `?familyId=${familyId}` : '';
    return this.http.post(`/beneficiary/${beneficiaryId}/profile/${type}?isSimplifiedProfile=true${profileFamilyString}`, payload).pipe(
      map((response) => response)
    );
  }

  saveEmployeeFB(payload: any) {
    return this.http.post(`/vd/formBuilder/invite/employee`, payload).pipe(
      map((response) => response)
    );
  }

  getBeneficiaryDetails(beneficiaryId: number, type: string, familyId?) {
        const profileFamilyString = familyId ? `?familyId=${familyId}` : '';
    return this.http.get(`/beneficiary/${beneficiaryId}/profile/${type}${profileFamilyString}`).pipe(
      map((response: ImagilityBaseResponse) => response.data)
    );
  }

    getEmployeeFBDetails(moduleId: number, pageFormId: number, companyId: number, beneficiaryId: number) {
        return this.http.get(`/vd/formBuilder/module/${moduleId}/pageForm/${pageFormId}/company/${companyId}/beneficiary/${beneficiaryId}/employee
    `).pipe(
            map((response: ImagilityBaseResponse) => response.data)
        );
    }

    getFBEmployeeDetails(fbEmployeeId: number) {
        return this.http.get(`/vd/formBuilder/fbInviteEmployee/${fbEmployeeId}/employee`).pipe(
            map((response: ImagilityBaseResponse) => response.data)
        );
    }

    getBeneficiaryFamilyDetails(beneficiaryId: number, type: string) {
        return this.http.get(`/beneficiary/${beneficiaryId}/profile/${type}`).pipe(
            map((response: ImagilityBaseResponse) => response.data)
        );
    }

    saveBeneficiaryFamilyDetails(beneficiaryId: number, type: string, payload: any) {
        return this.http.post(`/beneficiary/${beneficiaryId}/profile/${type}`, payload).pipe(
            map((response) => response)
        );
    }
    getFamilyDocumentsList(beneficiaryId: number, familyId: number) {
        const profileFamilyString = familyId ? `?familyId=${familyId}` : '';
        return this.http.get(`/beneficiary/${beneficiaryId}/profile/documents${profileFamilyString}`).pipe(
            map((response) => response));
    }

    getBeneficiaries(companyId) {
        return this.http.get('/company/beneficiaries/company/' + companyId).pipe(
            pluck('data'),
            map((benes: Array<any>) => {
                benes.forEach(item => {
                    item.name = item.fName + ' ' + item.lName,
                        item.address = (item.address.length > 0) ? item.address[0].addressLine1 : '',
                        item.email = (item.emailContacts.length > 0) ? item.emailContacts[0].email : '',
                        item.phone = (item.phoneContacts.length > 0) ? item.phoneContacts[0].phoneNo : '';
                });
                return benes;
            })
        );
    }

    generateUserid(payload) {
        return this.http.post('/user/generate/userId', payload).pipe(
            map((response: ImagilityBaseResponse) => response.data)
        );
    }

    sendExternalBeneficiaryInvite(payload) {
        return this.http.post('/petitioner/dashboard/external/beneficiaries/create', payload).pipe(
            map((response: ImagilityBaseResponse) => response)
        );
    }

    updateCommunicationEmail(payload) {
        return this.http.post('/updateCommunicationEmail', payload).pipe(
            map((response: ImagilityBaseResponse) => response)
        );
    }

    updateCommunicationEmailId(queryParams) {
        return this.http.post(`/i9admin/updateEmail?beneficiaryId=${queryParams.beneficiaryId}&companyId=${queryParams.companyId}&email=${queryParams['email']}`, '').pipe(
            map((response: ImagilityBaseResponse) => response)
        );
    }

    updateUserId(payload) {
        return this.http.post('/updateUserId', payload).pipe(
            map((response: ImagilityBaseResponse) => response)
        );
    }

    updateModeOfCreation(payload) {
        return this.http.post('/updateModeOfCreation', payload).pipe(
            map((response: ImagilityBaseResponse) => response)
        );
    }

    sendInviteToAttorneyCreatedBeneficiary(beneficiaryId, companyId) {
        return this.http.post(`/attorneySendInviteToBeneficiary/${beneficiaryId}/${companyId}`, {}).pipe(
            map((response: ImagilityBaseResponse) => response)
        );
    }

    sendBeneficiaryInvite(payload) {
        return this.http.post('/petitioner/dashboard/beneficiaries/create/', payload).pipe(
            map((response: ImagilityBaseResponse) => response)
        );
    }

    sendBeneficiaryInviteByPetitioner(payload) {
        return this.http.post('/petitioner/dashboard/beneficiaries/create/', payload).pipe(
            map((response: ImagilityBaseResponse) => response)
        );
    }
    // IM-3352 (IM-3335) user story
    resendBenificaryInvite(linkRequestId) {
        return this.http.post(`/linkRequest/${linkRequestId}/resend`, {}).pipe(
            map((response) => response)
        );
    }
  getPasportDoc(beneficiaryId) {
    return this.http.get(`/beneficiary/${beneficiaryId}/profile/passportDocuments`).pipe(
      map((response) => response)
    );
  }

  sendInviteToClient(companyId, petitionerId) {
    return this.http.post(`/attorneySendInviteToPetitioner/${companyId}/${petitionerId}`, {}).pipe(
      map((response) => response)
    );
  }

    withdrawanBenificaryInvite(linkRequestId) {
        return this.http.post(`/linkRequest/${linkRequestId}/withdraw`, {}).pipe(
            map((response) => response)
        );
    }

    getBeneficiariesListForPetitioner(companyId, payload) {
        return this.http.post('/petitioner/dashboard/beneficiaries/company/' + companyId, payload).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status === 200) {
                    return response.data;
                }
                else {
                    return null;
                }
            }));
    }
  getCategoryList() {
    return this.http.get('/referenceLibrary/TEMPLTCATGRY').pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        }
        else {
          return null;
        }
      }));
  }

  delinkBeneficiary(beneficiaryId, attorneyId) {
    return this.http.post(`/beneficiary/deLinkWithAttorney/${beneficiaryId}/${attorneyId}`, {}).pipe(
      map((response: ImagilityBaseResponse) => {
        return response.status === 200 ? response : null;
      }));
  }

    getEmployeesListForI9Admin(companyId, requestType) {
        return this.http.post(`/i9admin/getBeneficiaryDetailsList/${companyId}`, requestType).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status === 200) {
                    return response.data;
                }
                else {
                    return null;
                }
            }));
    }

    getBeneficiariesByCompanyId(companyId, payload) {
        return this.http.get(`/petitioner/dashboard/beneficiaries/company/associated/${companyId}/list`).pipe(
            // pluck('data'),
            // pluck('beneficiaryList'),
            // map((benes: Array<any>) => {
            //   benes.forEach(item => {
            //     item.firstname = item.beneficiaryEmployeeFName ? item.beneficiaryEmployeeFName : '',
            //       item.lastname = item.beneficiaryEmployeeLName ? item.beneficiaryEmployeeLName : '',
            //       item.middlename = item.beneficiaryEmployeeMName ? item.beneficiaryEmployeeMName : '-',
            //       item.company = (item.companyName) ? item.companyName : '';
            //   });
            //   return benes;
            // })
            map((response: ImagilityBaseResponse) => {
                if (response.status === 200 && response.data) {
                    return response.data;
                } else {
                    return []
                }
            })
        );
    }

    saveBeneficiaryEducationByPetitioner(petitionId: number, type: string, payload: any) {
        return this.http.post(`/petitionBuilder/qualification/profile/${type}/petition/${petitionId}`, payload).pipe(
            map((response) => response)
        );
    }

    saveBeneficiaryTrainingLicenseByPetitioner(petitionId: number, type: string, payload: any) {
        return this.http.post(`/petitionBuilder/qualification/profile/${petitionId}/professional/${type}/`, payload).pipe(
            map((response: ImagilityBaseResponse) => response)
        );
    }

    saveBeneficiaryExperienceByPetitioner(petitionId: number, payload: any) {
        return this.http.post(`/petitionBuilder/workexperience/petition/${petitionId}`, payload).pipe(
            map((response: ImagilityBaseResponse) => response.data)
        );
    }

    updateProfilePic(beneficiaryId, payload) {
        return this.http.post(`/beneficiary/${beneficiaryId}/profile/self/profileimage`, payload).pipe(
            map((response) => response)
        );
    }

    updateFamilyProfilePic(beneficiaryId, familyId, payload) {
        return this.http.post(`/beneficiary/${beneficiaryId}/profile/family/${familyId}/profileimage`, payload).pipe(
            map((response) => response)
        );
    }

    getStates(countryCode: string) {
        return this.http.get(`/countries/states?countryCode=${countryCode}`).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status) {
                    return response.data;
                }
            })
        );
    }

    saveBeneficiaryCurrentImmigration(beneficiaryId, payload) {
        return this.http.post(`/beneficiary/${beneficiaryId}/immigration/self/current`, payload).pipe(
            map((response) => response)
        );
    }

    getBeneficiaryCurrentImmigration(beneficiaryId) {
        return this.http.get(`/beneficiary/${beneficiaryId}/immigration/self/current`).pipe(
            map((response) => response)
        );
    }

    getBeneficiarysTasks(beneficiaryId: number) {
        return this.http.get(`/beneficiary/${beneficiaryId}/task`).pipe(
            map((response) => response));

    }

    getPastImmigrationDetails(beneficiaryId: number) {
        return this.http.get(`/beneficiary/${beneficiaryId}/immigration/self/past`).pipe(
            map((response) => response));
    }

    savePastImmigrationDetails(beneficiaryId, payload) {
        return this.http.post(`/beneficiary/${beneficiaryId}/immigration/self/past`, payload).pipe(
            map((response) => response)
        );
    }

    deleteBeneExperience(beneficiaryId: number, experienceId: number, familyId?) {
        const profileFamilyString = familyId ? `?familyId=${familyId}` : '';
        return this.http.delete(`/beneficiary/${beneficiaryId}/profile/experience/${experienceId}${profileFamilyString}`).pipe(
            map((data) => {
                // IM-3066 - remove deleted file.
                this.deletefile(beneficiaryId);
                return data;
            })
        );
    }

    saveGroundsOfExclusion(beneficiaryId, payload) {
        return this.http.post(`/beneficiary/${beneficiaryId}/immigration/self/exclusion`, payload).pipe(
            map((data) => data)
        );
    }

    getQuestionsForBeneficiary(groupCode: string, beneficiaryId: number) {
        return this.http.get(`/question/category/${groupCode}/beneficiary/${beneficiaryId}`).pipe(
            map((response: ImagilityBaseResponse) => {
                return response.data;
            })
        );
    }


    getGroundsOfExclusion(beneficiaryId) {
        return this.http.get(`/beneficiary/${beneficiaryId}/immigration/self/exclusion`).pipe(
            map((data) => data)
        );
    }

    // Beneficiary education detials

    getEducationDetails(beneficiaryId: number, familyId?: number) {
        const url = familyId ? `/beneficiary/${beneficiaryId}/profile/education?familyId=${familyId}` : `/beneficiary/${beneficiaryId}/profile/education`;
        console.log('url', url);
        return this.http.get(url).pipe(
            map((data) => data)
        );
    }
    getDocumentsList(beneficiaryId: number) {
        return this.http.get(`/beneficiary/${beneficiaryId}/profile/documents`).pipe(
            map((response) => response));
    }
    saveEducatinDegreeDetails(beneficiaryId: number, payload: any, familyId?: number) {
        const url = familyId ? `/beneficiary/${beneficiaryId}/profile/education?familyId=${familyId}` : `/beneficiary/${beneficiaryId}/profile/education`;
        return this.http.post(url, payload).pipe(
            map((response: ImagilityBaseResponse) => response)
        );
    }
    deleteEducatinDegreeDetails(beneficiaryId: number, educationId: number, familyId?: number) {
        const profileFamilyString = familyId ? `?familyId=${familyId}` : '';
        return this.http.delete(`/beneficiary/${beneficiaryId}/profile/education/${educationId}${profileFamilyString}`).pipe(
            map((data) => {
                // IM-3066 - remove deleted file.
                this.deletefile(beneficiaryId);
                return data;
            })
        );
    }

    addCurrentImmigrationDetails(beneficiaryId: number, payload: any) {
        return this.http.post(`/beneficiary/${beneficiaryId}/immigration/self/current`, payload).pipe(
            map((response: ImagilityBaseResponse) => response, catchError(this.errorHandler))
        );
    }

    addPastImmigrationDetails(beneficiaryId: number, payload: any) {
        return this.http.post(`/beneficiary/${beneficiaryId}/immigration/self/past`, payload).pipe(
            map((response: ImagilityBaseResponse) => response, catchError(this.errorHandler))
        );
    }

    errorHandler(error: any) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            errorMessage = error.error.message;
        } else {
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        return throwError(errorMessage);
    }

    saveVisaMetaInfo(beneficiaryId: number, payload, familyId?) {
        const profileFamilyString = familyId ? `?familyId=${familyId}` : '';
        return this.http.post(`/beneficiary/${beneficiaryId}/immigration/meta/self${profileFamilyString}`, payload).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status && response.status === 200) {
                    return response;
                }
            })
        );
    }

    saveVisaDetailsInfo(beneficiaryId: number, payload, familyId?) {
        const profileFamilyString = familyId ? `?familyId=${familyId}` : '';
        return this.http.post(`/beneficiary/${beneficiaryId}/immigration/self${profileFamilyString}`, payload).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status && response.status === 200) {
                    return response;
                }
            })
        );
    }

    getVisaDetails(beneficiaryId: number, familyId?) {
        const profileFamilyString = familyId ? `?familyId=${familyId}` : '';
        return this.http.get(`/beneficiary/${beneficiaryId}/immigration/self${profileFamilyString}`).pipe(
            map((response) => response));
    }

    getVisaMetaInfo(beneficiaryId: number, familyId?) {
        const profileFamilyString = familyId ? `?familyId=${familyId}` : '';
        return this.http.get(`/beneficiary/${beneficiaryId}/immigration/meta/self${profileFamilyString}`).pipe(
            map((response) => response));
    }


    saveTravelHistory(beneficiaryId: number, immigrationId: number, payload, familyId?) {
        const profileFamilyString = familyId ? `?familyId=${familyId}` : '';
        return this.http.post(`/beneficiary/${beneficiaryId}/immigration/${immigrationId}/travelInfo${profileFamilyString}`, payload).pipe(
            map((response: ImagilityBaseResponse) => response, catchError(this.errorHandler))
        );
    }

    getTravelHistory(beneficiaryId: number, immigrationId: number, familyId?) {
        const profileFamilyString = familyId ? `?familyId=${familyId}` : '';
        return this.http.get(`/beneficiary/${beneficiaryId}/immigration/${immigrationId}/travelInfo`).pipe(
            map((response) => {
                // IM-3066 - remove deleted file.
                this.deletefile(beneficiaryId);
                return response;
            }));

    }

    deleteImmigration(beneficiaryId: number, immigrationId: number, familyId?) {
        const profileFamilyString = familyId ? `?familyId=${familyId}` : '';
        return this.http.delete(`/beneficiary/${beneficiaryId}/immigration/${immigrationId}${profileFamilyString}`).pipe(
            map((response) => {
                // IM-3066 - remove deleted file.
                this.deletefile(beneficiaryId);
                return response;
            }));
    }

    deleteTravelInfo(beneficiaryId: number, immigrationId: number, travelInfoId: number) {
        return this.http.delete(`/beneficiary/${beneficiaryId}/immigration/${immigrationId}/travelInfo/${travelInfoId}`).pipe(
            map((response) => {
                // IM-3066 - remove deleted file.
                this.deletefile(beneficiaryId);
                return response;
            }));

    }

    // IM-3066 - remove deleted file.
    deletefile(beneficiaryId: number) {
        this.deletedfile$.next(true);
    }

    getSignatureImageBen(beneficiaryId) {
        return this.http.get(`/beneficiary/${beneficiaryId}/profile/signpic`).pipe(
            map((data: Array<any>) => {
                console.log('from servpicget', data);
                return data;
            }));
    }

    getFamilySignatureImageBen(beneficiaryId, familyId) {
        return this.http.get(`/beneficiary/${beneficiaryId}/profile/family/${familyId}/signpic`).pipe(
            map((data: Array<any>) => {
                console.log('from servpicget', data);
                return data;
            }));
    }

    // for benificiary sign Upload
    postSignatureImageBen(beneficiaryId, payload) {
        console.log('post data', payload);
        return this.http.post(`/beneficiary/${beneficiaryId}/profile/signpic`, payload).pipe(
            map((data: Array<any>) => {
                console.log('from servpicpost', data);
                return data;
            })
        );
    }

    // for benificiary sign Upload for family
    postFamilySignatureImageBen(beneficiaryId, familyId, payload) {
        console.log('post data', payload);
        return this.http.post(`/beneficiary/${beneficiaryId}/profile/family/${familyId}/signPic`, payload).pipe(
            map((data: Array<any>) => {
                console.log('from servpicpost', data);
                return data;
            })
        );
    }

    getBenficiaryLists(payload) {
        return this.http.post(`/beneficiaries/list`, payload).pipe(
            map((response: ImagilityBaseResponse) => {
                return response.status === 200 ? response.data : [];
            })
        );
    }

    // deleteFileFromDocumentList(){
    // }

    deleteFileFromDocumentList(beneficiaryId: number, catagoryGroup, catagoryCode, fileId) {
        return this.http.delete(`/document/beneficiary/${beneficiaryId}/category/${catagoryGroup}/fileCategory/${catagoryCode}/${fileId}`).pipe(
            map((data) => {
                // IM-3066 - remove deleted file.
                this.deletefile(beneficiaryId);
                return data;
            })
        );
    }

    // Inviting beneficiary through attorney login
    sendBeneficiaryConnectionInvite(attorneyId: number, beneficiaryId: number, payload) {
        return this.http.post(`/beneficiary/link/${attorneyId}/${beneficiaryId}`, payload).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status && response.status === 200) {
                    return response;
                } else {
                    return null;
                }
            })
        );
    }

    // Attorney Beneficiary Connection Status
    getAttorneyBeneficiaryConnectionStatus(attorneyId: number, beneficiaryId: number) {
        return this.http.get(`/beneficiary/link/${attorneyId}/${beneficiaryId}`).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status && response.status === 200) {
                    return response;
                }
            })
        );
    }

    getPetitionerBeneficiaryConnectionStatus(attorneyId: number, beneficiaryId: number) {
        return this.http.get(`/petitioner/beneficiary/link/${attorneyId}/${beneficiaryId}`).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status && response.status === 200) {
                    return response;
                }
            })
        );
    }


    attorneyCreateBeneficiary(payload) {
        return this.http.post(`/attorney/beneficiary/create`, payload).pipe(
            map((response: ImagilityBaseResponse) => response, catchError(this.errorHandler))
        );
    }

    petitionerCreateBeneficiary(payload) {
        return this.http.post(`/attorney/beneficiary/create`, payload).pipe(
            map((response: ImagilityBaseResponse) => response, catchError(this.errorHandler))
        );
    }

    getBeneficiariesListForAttorney(companyId, payload) {
        return this.http.post(`/attorney/${companyId}/beneficiaries/list`, payload).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status === 200) {
                    return response.data;
                }
                else {
                    return null;
                }
            }));
    }
    getUndocImmigrantListForPetitioner(companyId, payload) {
        return this.http.post(`/lp/undocImmigrants/${companyId}`, payload).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status && response.status === 200) {
                    return response['data'];
                }
            })
        );
    }

    sendNotification(payload) {
        return this.http.post(`/mail/beneficiary`, payload).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status === 200) {
                    return response;
                }
            })
        );
    }

    getRequestDataDocuments(data: any) {
        // beneficiaryId=1&companyId=1&immigrationType=asd&petitionType=asd&taskType=ww&visaType=ss
        let tempData;
        if (data.immigrationType === 'VISA') {
            tempData = `beneficiaryId=${data.beneficiaryId}&companyId=${data.companyId}&immigrationType=${data.immigrationType}&petitionType=${data.petitionType}&taskType=${data.taskType}&visaType=${data.visaType}`;
        } else {
            tempData = `beneficiaryId=${data.beneficiaryId}&companyId=${data.companyId}&immigrationCategory=${data.immigrationCategory}&immigrationClassfication=${data.immigrationClassfication}&taskType=${data.taskType}`;
        }

        if (data.taskTypeId) {
            tempData = `${tempData}&taskTypeId=${data.taskTypeId}`;
        } else {
            tempData = `${tempData}`;
        }

        return this.http.get(`/attrorpetnr/qdisplaySections?${tempData}`).pipe(
            map((response: ImagilityBaseResponse) => response.data)
        );
    }

    getQuestionnaireRequestDataDocuments(data: any, isCaseRequestSatus: boolean, isBeneficiaryQuestionnaireStatus: boolean) {

        // beneficiaryId=1&companyId=1&immigrationType=asd&petitionType=asd&taskType=ww&visaType=ss
        let tempData;
        if (data.immigrationType === 'VISA') {
            if (isCaseRequestSatus || isBeneficiaryQuestionnaireStatus) {
                tempData = `companyId=${data.companyId}&immigrationType=${data.immigrationType}&petitionType=${data.petitionType}&taskType=${data.taskType}&visaType=${data.visaType}`;
            } else {
                tempData = `beneficiaryId=${data.beneficiaryId}&companyId=${data.companyId}&immigrationType=${data.immigrationType}&petitionType=${data.petitionType}&taskType=${data.taskType}&visaType=${data.visaType}`;
            }

        } else {
            if (isCaseRequestSatus || isBeneficiaryQuestionnaireStatus) {
                tempData = `companyId=${data.companyId}&immigrationCategory=${data.immigrationCategory}&immigrationClassfication=${data.immigrationClassfication}&taskType=${data.taskType}`;
            } else {
                tempData = `beneficiaryId=${data.beneficiaryId}&companyId=${data.companyId}&immigrationCategory=${data.immigrationCategory}&immigrationClassfication=${data.immigrationClassfication}&taskType=${data.taskType}`;
            }

        }

        if (data.taskTypeId) {
            tempData = `${tempData}&taskTypeId=${data.taskTypeId}`;
        } else {
            tempData = `${tempData}`;
        }

        if (isCaseRequestSatus) {
            return this.http.get(`/platformadmin/displaySections?${tempData}&isCaseRequestInitiated=true&isUsedForBenQuestionnaryLibrary=false`).pipe(
                map((response: ImagilityBaseResponse) => response.data)
            );
        } else if (isBeneficiaryQuestionnaireStatus) {
            return this.http.get(`/platformadmin/displaySections?${tempData}&isCaseRequestInitiated=false&isUsedForBenQuestionnaryLibrary=true`).pipe(
                map((response: ImagilityBaseResponse) => response.data)
            );
        } else {
            return this.http.get(`/attrorpetnr/qdisplaySections?${tempData}`).pipe(
                map((response: ImagilityBaseResponse) => response.data)
            );
        }

    }

    getAccessCodeAndLinkForBeneficiary(id: any) {
        return this.http.get(`/attor/getAccessCodeAndLinkForBeneficiary/${id}`).pipe(
            map((response: ImagilityBaseResponse) => response.data)
        );
    }

    getPlatformDisplaySection(data: any) {
        // beneficiaryId=1&companyId=1&immigrationType=asd&petitionType=asd&taskType=ww&visaType=ss
        let tempData;
        if (data.immigrationType === 'VISA') {
            tempData = `immigrationType=${data.immigrationType}&petitionType=${data.petitionType}&taskType=${data.taskType}&visaType=${data.visaType}`;
        } else {
            tempData = `immigrationType=${data.immigrationType}&immigrationCategory=${data.immigrationCategory}&immigrationClassfication=${data.immigrationClassfication}&taskType=${data.taskType}`;
        }

        return this.http.get(`/platformadmin/displaySections?${tempData}`).pipe(
            map((response: ImagilityBaseResponse) => response.data)
        );
    }

    saveRequestDataDocuments(reqLoad: any, payLoad: any) {
        return this.http.post(`/attor/saveQtsections?benenficiaryId=${reqLoad.beneficiaryId}&companyId=${reqLoad.companyId}`, payLoad).pipe(
            map((response: ImagilityBaseResponse) => response)
        );
    }

    saveCaseRequestDataDocuments(reqLoad: any, payLoad: any, isCaseRequestSatus: boolean, isBeneficiaryQuestionnaireStatus: boolean) {
        let dynamicUrl;
        if (isCaseRequestSatus) {
            dynamicUrl = `/attor/saveQtsections?companyId=${reqLoad.companyId}&isCaseRequestInitiated=true&isUsedForBenQuestionnaryLibrary=false`
        } else if (isBeneficiaryQuestionnaireStatus) {
            dynamicUrl = `/attor/saveQtsections?companyId=${reqLoad.companyId}&isCaseRequestInitiated=false&isUsedForBenQuestionnaryLibrary=true`
        }
        return this.http.post(dynamicUrl, payLoad).pipe(
            map((response: ImagilityBaseResponse) => response)
        );
    }

    // Call new api by Rahul
    saveRequestDataDocumentsAttorney(reqLoad: any, payLoad: any, externalEmployeeId: any) {
        return this.http.post(`/petnr/sendDataAndDocument/extrnalAttorney?benenficiaryId=${reqLoad.beneficiaryId}&companyId=${reqLoad.companyId}&externalEmployeeId=${externalEmployeeId}`, payLoad).pipe(
            map((response: ImagilityBaseResponse) => response)
        );
    }

    saveQuestionnairePlatformAdmin(reqLoad: any, payLoad: any) {
        return this.http.post(`/platformadmin/saveQtsections`, payLoad).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status && response.status === 200) {
                    return response['data'];
                }
            })
        );
    }

    getTaskFordisplay(data: any) {
        return this.http.post(`/getTaskFordisplaySection`, data).pipe(
            map((response: ImagilityBaseResponse) => response.data)
        );
    }



    getRequiredDocuments(data: any) {
        // let path = `/documentChecklist/${data.visaType}/${data.taskType}/${data.caseId}`;
        // let path = `/documentChecklist/${data.visaType}/${data.taskType}/${data.taskId}`;
        let path = `/documentChecklist/${data.visaType}/${data.taskType}/${data.petitioncaseId}`;
        return this.http.get(path).pipe(
            map((response: ImagilityBaseResponse) => {
                //if (response.status === 200) {
                return response; // send  full data
                //}
            })
        );
    }

    getTask(visaType, taskId) {
        return this.http.get(`/${visaType}/task/${taskId}`).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status === 200) {
                    return response;
                }
            })
        );
    }

    // getCustomQuestionsDetailView(companyId, taskId) {
    //   return this.http.get(`/getCustomQuestionnaryDetails/company/${companyId}?taskTypeId=${taskId}`).pipe(
    //       map((response: ImagilityBaseResponse) => {
    //           if (response.status === 200) {
    //               return response;
    //           }
    //       })
    //   );
    // }

    getCustomQuestionsDetails(payload) {
        return this.http.get(`/getCustomJson/${payload.companyId}/${payload.beneficiaryId}?taskTypeId=${payload.taskTypeId}`).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status === 200) {
                    return response;
                }
            })
        );
    }

    getQuestionnaireCustomQuestionsDetails(payload, isCaseRequestSatus, isBeneficiaryQuestionnaireStatus) {
        let customUrl;
        if (isCaseRequestSatus) {
            // customUrl = `/getCustomQuestionnaryDetails/company/${payload.companyId}?isUsedForPetition=false&taskTypeId=${payload.taskTypeId}`
            customUrl = `/getCustomJson/${payload.companyId}/0?taskTypeId=${payload.taskTypeId}&isCaseRequestInitiated=true&isUsedForBenQuestionnaryLibrary=false`;
        } else if (isBeneficiaryQuestionnaireStatus) {
            customUrl = `/getCustomJson/${payload.companyId}/0?taskTypeId=${payload.taskTypeId}&isCaseRequestInitiated=false&isUsedForBenQuestionnaryLibrary=true`;
        } else {
            customUrl = `/getCustomJson/${payload.companyId}/${payload.beneficiaryId}?taskTypeId=${payload.taskTypeId}`;
        }
        return this.http.get(customUrl).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status === 200) {
                    return response;
                }
            })
        );
    }


    saveCustomQuestions(data: any, payLoad: any) {
        return this.http.post(`/saveCustomQuestionnary/${data}`, payLoad).pipe(
            map((response: ImagilityBaseResponse) => response)
        );
    }

    getQuestionListSteps(companyId, taskTypeId) {
        let dynamicUrl;
        dynamicUrl = `/getCustomQuestionnaryDetails/company/${companyId}?taskTypeId=${taskTypeId}`;
        return this.http.get(dynamicUrl).pipe(
            map((response: ImagilityBaseResponse) => response.data)
        );
    }

    saveEmployeeJobDetails(payLoad: any) {
        return this.http.post(`/vd/formBuilder/create/fbEmployee/jobSummary`, payLoad).pipe(
            map((response: ImagilityBaseResponse) => response)
        );
    }

    getEmployeeJobDetails(beneficaryId) {
        return this.http.get(`/vd/formBuilder/get/fbEmployee/jobSummary/${beneficaryId}`).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status === 200) {
                    return response;
                }
            })
        );
    }

    getCompanyList_ddl(companyId) {
        return this.http.get(`/company/${companyId}/heierachy`).pipe(
            pluck('data'), map((data: Array<any>) => {
                return data;
            })
        );
    }

    getEmployeeList_ddl(companyId) {
        return this.http.get(`/vd/formBuilder/get/fbEmployeeList/company/${companyId}`).pipe(
            pluck('data'), map((data: Array<any>) => {
                return data;
            })
        );
    }

    getDepartmentList_ddl(companyId) {
        return this.http.get(`/vd/formBuilder/get/fbDepartmentList/company/${companyId}`).pipe(
            pluck('data'), map((data: Array<any>) => {
                return data;
            })
        );
    }

    getShiftList_ddl(departmentId) {
        return this.http.get(`/vd/formBuilder/get/fbShiftList/department/${departmentId}`).pipe(
            pluck('data'), map((data: Array<any>) => {
                return data;
            })
        );
    }

    getDataSourceList(dataSourceType) {
        return this.http.get(`/vd/dataSource/${dataSourceType}/elements`).pipe(
            pluck('data'), map((data: Array<any>) => {
                return data;
            })
        );
    }


    /* Bank Details Apis */
    getBankDetails(bankId: number, type: string) {
        return this.http.get(`/formBuilder/employee/bankView/${bankId}`).pipe(
            map((response: ImagilityBaseResponse) => response.data)
        );
    }

    saveBankDetails(payload: any) {
        return this.http.post(`/formBuilder/employee/bankDetails`, payload).pipe(
            map((response) => response)
        );
    }

    deleteBankFileFromDocumentList(empId: number) {
        return this.http.delete(`/formBuilder/employee/bankDetails/delete/${empId}`).pipe(
            map((data) => {
                this.deletefile(empId);
                return data;
            })
        );
    }

    /* W4 Details Apis */
    getW4Details(empId: number, type: string) {
        return this.http.get(`/formBuilder/employee/w4Details/view/${empId}`).pipe(
            map((response: ImagilityBaseResponse) => response.data)
        );
    }

    saveW4Details(payload: any) {
        return this.http.post(`/formBuilder/employee/w4details/createorupdate`, payload).pipe(
            map((response) => response)
        );
    }
    deleteW4FileFromDocumentList(empId: number, categoryName: string, fileCategory: string) {
        return this.http.delete(`/formBuilder/employee/w4Details/delete/${empId}/category/${categoryName}/fileCategory/${fileCategory}`).pipe(
            map((data) => {
                this.deletefile(empId);
                return data;
            })
        );
    }
    /* Form16 Details Apis */
    getForm16Details(empId: number, type: string) {
        return this.http.get(`/formBuilder/employee/form16details/view/${empId}`).pipe(
            map((response: ImagilityBaseResponse) => response.data)
        );
    }

    saveForm16Details(payload: any) {
        return this.http.post(`/formBuilder/employee/form16details/createorupdate`, payload).pipe(
            map((response) => response)
        );
    }

    deleteForm16FileFromDocumentList(empId: number) {
        return this.http.delete(`/formBuilder/employee/form16details/delete/${empId}`).pipe(
            map((data) => {
                this.deletefile(empId);
                return data;
            })
        );
    }





    searcFilterNewUI(payload) {
        let _data = {
            "pageNumber": 1,
            "recordsPerPage": 50,
            "searchCriteria": "",
            "searchText": "",
            "sortBy": "beneficiaryName",
            "sortOrder": "asc",
            "status": [
                "ACTIVE"
            ]
        }
        return this.http.post(`/company/${payload.companyId}/name/${payload.name}/employeeHaving/${payload.type}/getAllEmployeesBySearchAndFilter`, _data).pipe(
            map((data) => {
                if (!data) {
                    return data = [];
                }
                return data;
            })
        );
    }

    getInactiveEmployeesListForI9Admin(companyId, requestType) {
        return this.http.post(`/i9admin/getInactiveEmployees?companyId=${companyId}`, requestType).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status === 200) {
                    return response.data;
                }
                else {
                    return null;
                }
            }));
    }

    isEmailExist(companyId, email) {
        const encryptedEmail = this.encryptService.toEncrypt(email);
        return this.http.get(`/i9admin/checkEmialExisists/${companyId}/${encryptedEmail}`).pipe(
            map((response: ImagilityBaseResponse) => response.data.emailExisists)
        );
    }

  getHrEmployeeList_ddl(companyId) {
    return this.http.get(`/vd/formBuilder/get/hrAdmin/company/${companyId}`).pipe(
      pluck('data'), map((data: Array<any>) => {
        return data;
      })
    );
  }
  getRmEmployeeList_ddl(companyId) {
    return this.http.get(`/vd/formBuilder/get/reportingManager/company/${companyId}`).pipe(
      pluck('data'), map((data: Array<any>) => {
        return data;
      })
    );
  }

  getYearsDataSourceList(dataSourceType) {
    return this.http.get(`/vd/dataSource/${dataSourceType}/elements`).pipe(
      pluck('data'), map((data: Array<any>) => {
        return data;
      })
    );
  }
  getMonthsDataSourceList(dataSourceType) {
    return this.http.get(`/vd/dataSource/${dataSourceType}/elements`).pipe(
      pluck('data'), map((data: Array<any>) => {
        return data;
      })
    );
  }

  getPayslipDetails(payload: any) {
    return this.http.post(`/vd/formBuilder/externalSystem/employee/paystub/details`, payload).pipe(
      map((response) => response)
    );
  }
  getSalaryBreakupDetails(payload: any) {
    return this.http.post(`/vd/formBuilder/externalSystem/employee/payroll/details`, payload).pipe(
      map((response) => response)
    );
  }
  getPayslipDownloadDetails(payload: any) {
    return this.http.post(`/vd/formBuilder/employee/payroll/payslip/details`, payload).pipe(
      map((response) => response)
    );
  }
  downloadDocuments(path: string, fileName: string) {
    this.http.get(path, { responseType: "arraybuffer" })
    .pipe(take(1))
    .subscribe(pdf => {
      const blob = new Blob([pdf], { type: 'application/pdf' });
      saveAs(blob, fileName);
    });
  }
  // Employee Onboadring APIs

  getBeneficiariesListForApprovedPetitions(companyId, payload) {
    return this.http.post('/datamigration/listofbeneficiaries/' + companyId, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        }
        else {
          return null;
        }
      }));
  }

  migrateOrOnboardEmployees(companyId, payload: any) {
    return this.http.post('/datamigration/migrate/beneficiaries/' + companyId, payload).pipe(
      map((response) => response)
    );
  }

  getPetitionDropdownList(beneficiaryId) {
    return this.http.get(`/beneficiary/${beneficiaryId}/petitions`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        }
      })
    );
  }

  getTaskListForBeneficiary(beneficiaryId, payload) {
    return this.http.post(`/superTask/beneficiary/${beneficiaryId}`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        }
      })
    );
  }

  getCompanyListAssociatedToBene(beneficiaryId) {
    return this.http.get(`/beneficiary/${beneficiaryId}/petitioner`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        }
      })
    );
  }
  // Immigration details component services

  getUsStayHistory(beneficiaryId: number) {
    return this.http.get(`/beneficiary/${beneficiaryId}/immigration/self/`).pipe(
      map((response) => response));
  }

  saveUsStayHostory(beneficiaryId: number, payload) {
    return this.http.post(`/beneficiary/${beneficiaryId}/travelInfo`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status && response.status === 200) {
          return response;
        }
      })
    );
  }

  saveNotes(beneficiaryId: number, payload) {
    return this.http.post(`/beneficiary/${beneficiaryId}/note/create`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status && response.status === 200) {
          return response;
        }
      })
    );
  }

  updateNotes(postId: number, payload) {
    return this.http.post(`/note/${postId}/edit`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status && response.status === 200) {
          return response;
        }
      })
    );
  }

  getAllNotes(beneficiaryId: number) {
    return this.http.get(`/beneficiary/${beneficiaryId}/notelist`).pipe(
      map((response) => response));
  }

  deleteNote(noteId: number){    
      return this.http.delete(`/beneficiary/note/${noteId}/delete`).pipe
      (map((response) =>{
          return response;
      }))
  }

  deletePetitionNotes(noteId: number, customStep: boolean, timelineSteps?: string, taskId?: number){
    let url = `/L1/task/${taskId}/note/${noteId}/delete?isCustomStep=${customStep}`;
    if(!timelineSteps){ // to delete the non step (genral notes)
      url = `/genNotes/L1/task/${taskId}/note/${noteId}/delete`;
    }
      return this.http.delete(url).pipe
      (map((response) =>{
          return response;
      }))
  }

  getBeneficiaryLCA(beneficiaryId: number) {
    return this.http.get(`/dashboard/petitioner/lca/beneficiary/${beneficiaryId}/list/`).pipe(
      map((response: ImagilityBaseResponse) => response.data)
    );
  }

  getBeneficiary(accessCode: number){
    return this.http.get(`/admin/getQuestionaryByAccessToken/${accessCode}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status) {
          return response;
        }
      })
    );
  }

  saveRecentActivity(payload) {
    return this.http.post(`/saveRecentWorkDetail`, payload).pipe(
      map((response: any) => {        
        if (response.status === 200) {          
          return response;
        }
      })
    );
  }

  getBeneficiaryEConsentCompany(beneficiaryId: number, companyId: number){
    return this.http.get(`/econsent/details/beneficiary/${beneficiaryId}?companyId=${companyId}`).pipe(
      map((response: ImagilityBaseResponse) => response)
    );
  }

  getBeneficiaryEConsentLogin(beneficiaryId: number, isRequestFromLogin: boolean){
    return this.http.get(`/econsent/details/beneficiary/${beneficiaryId}?isRequestFromLogin=${isRequestFromLogin}`).pipe(
      map((response: ImagilityBaseResponse) => response)
    );
  }

  getBeneficiaryEConsentReqData(beneficiaryId: number, isRequestFromLogin: boolean, accessTokenCode?: number){
    return this.http.get(`/econsent/details/beneficiary/${beneficiaryId}?isRequestFromLogin=${isRequestFromLogin}&accessTokenCode=${accessTokenCode}`).pipe(
      map((response: ImagilityBaseResponse) => response)
    );
  }

  getEconsentMessage(companyId: number){
    return this.http.get(`/econsent/details/company/${companyId}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        }
      })
    );
  }

  saveEconsent(payload: any){
    return this.http.post(`/beneficiary/econsent/details`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }

  getDependentList(beneficiaryId) {
    return this.http.get(`/vd/beneficiary/${beneficiaryId}/dependentDetails`).pipe(
      map(response => {
        return response['data'];
      })
    )
  }

}
