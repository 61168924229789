<form [formGroup]="section2Form">
    <fieldset>
        <div class="mb-4">
            <h5>Section 2. Employer Review and Verification: </h5>
            <span class="info-text"> Employers or their authorized representative must complete and sign Section 2
                within three
                business days after the employee's first day of employment, and must physically examine, or examine
                consistent
                with an alternative procedure
                authorized by the Secretary of DHS, documentation from List A OR a combination of documentation from
                List B and
                List C. Enter any additional
                documentation in the Additional Information box; see Instructions. </span>
        </div>

        <div>
            <!-- Radio Buttons for List A or List B and List C -->
            <div>
                <label class="mr-4">
                    <input type="radio" formControlName="listType" value="listA" />
                    <label>List A</label>
                </label>
                <label>
                    <input type="radio" formControlName="listType" value="listBC" />
                    <label>List B and C</label>
                </label>
            </div>

            <div class="listA-documents" *ngIf="section2Form.get('listType')?.value === 'listA'">
                <h5>Enter document details for List A</h5>
                <div class="row">
                    <div class="col-md-4">
                        <div>
                            <label for="documentTitle1">Document Title 1:</label>
                            <input id="documentTitle1" formControlName="documentTitle1" />
                        </div>
                        <div>
                            <label for="issuingAuthority1">Issuing Authority:</label>
                            <input id="issuingAuthority1" formControlName="issuingAuthority1" />
                        </div>
                        <div>
                            <label for="documentNumber1">Document Number (if any):</label>
                            <input id="documentNumber1" formControlName="documentNumber1" />
                        </div>
                        <div>
                            <label for="expirationDate1">Expiration Date (if any):</label>
                            <input id="expirationDate1" formControlName="expirationDate1" type="date" />
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div>
                            <label for="documentTitle2">Document Title 2 (if any):</label>
                            <input id="documentTitle2" formControlName="documentTitle2" />
                        </div>
                        <div>
                            <label for="issuingAuthority2">Issuing Authority:</label>
                            <input id="issuingAuthority2" formControlName="issuingAuthority2" />
                        </div>
                        <div>
                            <label for="documentNumber2">Document Number (if any):</label>
                            <input id="documentNumber2" formControlName="documentNumber2" />
                        </div>
                        <div>
                            <label for="expirationDate2">Expiration Date (if any):</label>
                            <input id="expirationDate2" formControlName="expirationDate2" type="date" />
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div>
                            <label for="documentTitle3">Document Title 3 (if any):</label>
                            <input id="documentTitle3" formControlName="documentTitle3" />
                        </div>
                        <div>
                            <label for="issuingAuthority3">Issuing Authority:</label>
                            <input id="issuingAuthority3" formControlName="issuingAuthority3" />
                        </div>
                        <div>
                            <label for="documentNumber3">Document Number (if any):</label>
                            <input id="documentNumber3" formControlName="documentNumber3" />
                        </div>
                        <div>
                            <label for="expirationDate3">Expiration Date (if any):</label>
                            <input id="expirationDate3" formControlName="expirationDate3" type="date" />
                        </div>
                    </div>
                </div>
            </div>

            <div class="listBC-documents row" *ngIf="section2Form.get('listType')?.value === 'listBC'">
                <div class="col-md-6 pl-0">
                    <h5>Enter document details for List B</h5>
                    <div>
                        <label for="documentTitle1">Document Title 1:</label>
                        <input id="documentTitle1" formControlName="documentTitle1" />
                    </div>
                    <div>
                        <label for="issuingAuthority1">Issuing Authority:</label>
                        <input id="issuingAuthority1" formControlName="issuingAuthority1" />
                    </div>
                    <div>
                        <label for="documentNumber1">Document Number (if any):</label>
                        <input id="documentNumber1" formControlName="documentNumber1" />
                    </div>
                    <div>
                        <label for="expirationDate1">Expiration Date (if any):</label>
                        <input id="expirationDate1" formControlName="expirationDate1" type="date" />
                    </div>
                </div>
                <div class="col-md-6">
                    <h5>Enter document details for List C</h5>
                    <div>
                        <label for="documentTitle2">Document Title 2 (if any):</label>
                        <input id="documentTitle2" formControlName="documentTitle2" />
                    </div>
                    <div>
                        <label for="issuingAuthority2">Issuing Authority:</label>
                        <input id="issuingAuthority2" formControlName="issuingAuthority2" />
                    </div>
                    <div>
                        <label for="documentNumber2">Document Number (if any):</label>
                        <input id="documentNumber2" formControlName="documentNumber2" />
                    </div>
                    <div>
                        <label for="expirationDate2">Expiration Date (if any):</label>
                        <input id="expirationDate2" formControlName="expirationDate2" type="date" />
                    </div>
                </div>
            </div>

            <div>
                <label for="additionalInfo">Additional Information:</label>
                <textarea id="additionalInfo" formControlName="additionalInfo"></textarea>
            </div>
            <div>
                <input id="alternativeProcedure" type="checkbox" formControlName="alternativeProcedure" />
                <label for="alternativeProcedure">Check here if you used an alternative procedure authorized by DHS to
                    examine
                    documents.</label>
            </div>
        </div>


        <fieldset>
            <div class="mb-4">
                <h6>Certification: </h6>
                <span class="info-text"> I attest, under penalty of perjury, that (1) I have examined the documentation
                    presented by the
                    above-named
                    employee, (2) the above-listed documentation appears to be genuine and to relate to the employee
                    named, and (3) to the
                    best of my knowledge, the employee is authorized to work in the United States. </span>
            </div>
            <div>
                <label for="firstDayOfEmployment">First Day of Employment (mm/dd/yyyy):</label>
                <input id="firstDayOfEmployment" formControlName="firstDayOfEmployment" type="date" />
            </div>

            <div>
                <label for="employerName">Last Name, First Name and Title of Employer or Authorized
                    Representative:</label>
                <input id="employerName" formControlName="employerName" />
            </div>

            <div>
                <label for="employerSignature">Signature of Employer or Authorized Representative:</label>
                <input id="employerSignature" type="checkbox" formControlName="employerSignature" />
            </div>
            <div>
                <label for="todayDate">Today's Date (mm/dd/yyyy):</label>
                <input id="todayDate" formControlName="todayDate" type="date" />
            </div>
            <div>
                <label for="businessName">Employer's Business or Organization Name:</label>
                <input id="businessName" formControlName="businessName" />
            </div>
            <div>
                <label for="businessAddress">Employer's Business or Organization Address, City or Town, State, ZIP
                    Code:</label>
                <input id="businessAddress" formControlName="businessAddress" />
            </div>
        </fieldset>
    </fieldset>
</form>

<!-- Supplement B -->
<form [formGroup]="supplementBForm">
    <fieldset>
        <h5>Supplement B, Reverification and Rehire (formerly Section 3)</h5>

        <div class="row">
            <div class="col-md-4 pl-0">
                <label for="lastName">Last Name (Family Name) from Section 1:</label>
                <input id="lastName" formControlName="lastName" />
            </div>
            <div class="col-md-4 pl-0">
                <label for="firstName">First Name (Given Name) from Section 1:</label>
                <input id="firstName" formControlName="firstName" />
            </div>
            <div class="col-md-4 pl-0">
                <label for="middleInitial">Middle Initial (if any) from Section 1:</label>
                <input id="middleInitial" formControlName="middleInitial" />
            </div>
        </div>
        <div class="mb-4">
            <fieldset>
                <h6>Instructions: </h6>
                <span class="info-text"> This supplement replaces Section 3 on the previous version of Form I-9. Only
                    use this page if your employee requires
                    reverification, is rehired within three years of the date the original Form I-9 was completed, or
                    provides proof of a legal name change. Enter
                    the employee's name in the fields above. Use a new section for each reverification or rehire. Review
                    the Form I-9 instructions before
                    completing this page. Keep this page as part of the employee's Form I-9 record. Additional guidance
                    can be found in the
                    <a href="https://www.uscis.gov/i-9-central/form-i-9-resources/handbook-for-employers-m-274"
                        target="_blank">Handbook for Employers: Guidance for Completing Form I-9 (M-274)</a> </span>
            </fieldset>
        </div>

        <fieldset>
            <div formArrayName="rehireRecords">
                <div class="mb-4 each-rehire-section" *ngFor="let record of rehireRecords.controls; let i = index"
                    [formGroupName]="i">
                    <div class="row mb-4">
                        <div class="col-md-8 pl-0">
                            <h5 class="m-0">Rehire {{i+1}}</h5>
                        </div>
                        <div class="col-md-4 pr-0 text-right">
                            <button type="button" (click)="deleteRehireRecord(i)">Delete</button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3 pl-0">
                            <label for="rehireDate{{i}}">Date of Rehire (if applicable):</label>
                            <input id="rehireDate{{i}}" formControlName="rehireDate" type="date" />
                        </div>
                        <div class="col-md-3 pl-0">
                            <label for="newLastName{{i}}">New Last Name (if applicable):</label>
                            <input id="newLastName{{i}}" formControlName="newLastName" />
                        </div>
                        <div class="col-md-3 pl-0">
                            <label for="newFirstName{{i}}">New First Name (if applicable):</label>
                            <input id="newFirstName{{i}}" formControlName="newFirstName" />
                        </div>
                        <div class="col-md-3 pl-0">
                            <label for="newMiddleInitial{{i}}">New Middle Initial (if applicable):</label>
                            <input id="newMiddleInitial{{i}}" formControlName="newMiddleInitial" />
                        </div>
                    </div>
    
    
                    <div class="mb-4">
                        <h6>Reverification: </h6>
                        <span class="info-text"> If the employee requires reverification, your employee can choose to
                            present any acceptable List A or List C documentation to show
                            continued employment authorization. Enter the document information in the spaces below.</span>
                    </div>
    
                    <div class="row">
                        <div class="col-md-4 p-0">
                            <label for="documentTitle{{i}}">Document Title (if reverification required):</label>
                            <input id="documentTitle{{i}}" formControlName="documentTitle" />
                        </div>
                        <div class="col-md-4 p-0">
                            <label for="documentNumber{{i}}">Document Number (if any):</label>
                            <input id="documentNumber{{i}}" formControlName="documentNumber" />
                        </div>
                        <div class="col-md-4 p-0">
                            <label for="expirationDate{{i}}">Expiration Date (if any):</label>
                            <input id="expirationDate{{i}}" formControlName="expirationDate" type="date" />
                        </div>
                    </div>
    
                    <div class="mb-4">
                        <span class="info-text">I attest, under penalty of perjury, that to the best of my knowledge, this
                            employee is authorized to work in the United States, and if the
                            employee presented documentation, the documentation I examined appears to be genuine and to
                            relate to the individual who presented it</span>
                    </div>
    
                    <div class="row">
                        <div class="col-md-4 pl-0">
                            <label for="employerName{{i}}">Name of Employer or Authorized Representative:</label>
                            <input id="employerName{{i}}" formControlName="employerName" />
                        </div>
                        <div class="col-md-4 pl-0">
                            <label for="employerSignature{{i}}">Signature of Employer or Authorized Representative:</label>
                            <input id="employerSignature{{i}}" type="checkbox" formControlName="employerSignature" />
                        </div>
                        <div class="col-md-4 pl-0">
                            <label for="todayDate{{i}}">Today's Date (mm/dd/yyyy):</label>
                            <input id="todayDate{{i}}" formControlName="todayDate" type="date" />
                        </div>
                    </div>
    
                    <div class="row">
                        <div class="col-md-8 pl-0">
                            <label for="additionalInfo{{i}}">Additional Information (Initial and date each
                                notation):</label>
                            <textarea id="additionalInfo{{i}}" formControlName="additionalInfo"></textarea>
                        </div>
                        <div class="pl-0">
                            <input id="alternativeProcedure{{i}}" type="checkbox" formControlName="alternativeProcedure" />
                            <label for="alternativeProcedure{{i}}">Check here if you used an alternative procedure
                                authorized by
                                DHS to examine documents.</label>
                        </div>
                    </div>
    
                </div>
            </div>
        </fieldset>

        <button type="button" (click)="addRehireRecord()" [disabled]="rehireRecords.length >= 3" class="mr-4 mt-4">Add
            Rehire</button>
    </fieldset>
</form>

<div class="row text-center">
    <div class="col-md-12">
        <button type="submit" [disabled]="!supplementBForm.valid" (click)="submitSections()" class="mt-4">Submit</button>
    </div>
</div>