<div style="margin:20px ; padding:10px; background-color: #F8F8F8 !important;">
  <div class="nav home icn">
    <span (click)="backtoDashboard()"> Home > </span><span>Services > </span> <span
      (click)="backtoMangaeBilling()">Manage Billing and Invoicing
    </span>
  </div>
  <div class="row pl-0 pr-0">
    <div class="col-md-12 page-heading page-header">
      <h2>Generate Invoice</h2>
      <p>Enter invoice details and send to a particular client</p>
    </div>

  </div>
  <div class="content-container pb-1">
    <div class="row pull-right "> 
    </div>
    <div class="content-container pb-0">
      <mat-horizontal-stepper #stepper labelPosition="bottom" [selectedIndex]="currentStep">
        <mat-step *ngIf="!editInvoiceItem">
          <ng-template matStepLabel>Invoice Details</ng-template>
          <app-invoice-deatils [isClientGenerateInvoice]="isClientGenerateInvoice" [petitionerId]="petitionerId"
            (valueChange)="enableNext($event)" (invoiceTypChanged)="positionNext($event)"></app-invoice-deatils>
          <div class="pull-right " *ngIf="!isClientGenerateInvoice">

            <button mat-button matStepperNext type="button" class="im-btn im-btn-primary im-btn-md"
              [disabled]="invoiceId == null" (click)="sendDataTosecondPage()"
              [ngClass]="{'pos-end': invType, 'pos-up': !invType}">Next</button>
          </div>

          <div class="pull-right " *ngIf="isClientGenerateInvoice">

            <button mat-button matStepperNext type="button" class=" im-btn im-btn-primary im-btn-md"
              [disabled]="invoiceId == null" (click)="sendDataTosecondPage()"
              [ngClass]="{'pos-top':invType,'pos-client-end':!invType}">Next</button>
          </div>
        </mat-step>

        <mat-step *ngIf="editInvoiceItem">
          <ng-template matStepLabel>Edit Invoice Details</ng-template>
          <app-invoice-deatils (valueChange)="enableNext($event)" (invoiceTypChanged)="positionNext($event)"
            [editItem]="editInvoiceItem"></app-invoice-deatils>
          <div class="pull-right ">
            <button mat-button matStepperNext type="button" class="im-btn im-btn-primary im-btn-md"
              (click)="sendDataTosecondPage()" [ngClass]="{'pos-edit-gen': invType, 'pos-up': !invType}">Next</button>
          </div>
        </mat-step>

        <mat-step>
          <ng-template matStepLabel>Send Invoice</ng-template>
          <div *ngIf="!sendDraftItemId && !editInvoiceItem">
            <div *ngIf="(invoiceId || editInvoiceId) && (form)">
              <app-send-invoice [invoiceObj]='invoiceId || editInvoiceId' [obj]="form"></app-send-invoice>
            </div>
          </div>

          <div *ngIf="sendDraftItemId">
            <app-send-invoice [sendDraftItemId]="sendDraftItemId"></app-send-invoice>
          </div>

          <div *ngIf="editInvoiceItem">
            <app-send-invoice [sendDraftItemId]="editInvoiceId"></app-send-invoice>
          </div>
        </mat-step>
      </mat-horizontal-stepper>
    </div>
  </div>
</div>

