import { Injectable, OnInit } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { pluck, map, take, catchError } from 'rxjs/operators';
import { Observable, of, BehaviorSubject, throwError } from 'rxjs';
import { ImagilityBaseResponse } from '../data/models/response.model';
import { RecentlyVisitedFilterData } from '../data/models/dashboard-widgets.model';
import { saveAs } from 'file-saver';
import { ToastrService } from 'ngx-toastr';

import { EncryptService } from '../modules/core/http/http-encrypt.service';
import * as CryptoJS from 'crypto-js';
@Injectable({
    providedIn: 'root'
})
export class PetitionerService {

  public taskFilters$ = new BehaviorSubject<any>({});
  public interpreterInfo$ = new BehaviorSubject<any>({});
  public interpreterInfo = {};
  public taskViewTypeBSubject = new BehaviorSubject<number>(1);
  encriptedData:any;
  constructor(private httpClient: HttpClient, private toastr: ToastrService, private encryptService: EncryptService) { 
    this.interpreterInfo$.subscribe(d=>{ 
      this.interpreterInfo=d;
    });
  } 

  getLcaCodesWizard(companyId) {
    return this.httpClient.get(`/wizard/${companyId}/lca/list/`).pipe(
      pluck('data'),
      map((codes: Array<any>) => {
        return codes;
      })
    )
  }

    getLcaCodes(userType, companyId, payload) {
        return this.httpClient.post(`${environment.baseUrl}/dashboard/petitioner/lca/${userType}/${this.toEncrypt(companyId)}/list/`, payload).pipe(
            pluck('data'),
            map((codes: Array<any>) => {
                return codes;
            })
        )
    }
    getPostedLcaCodes(userType, companyId, payload) {
      return this.httpClient.post(`${environment.baseUrl}/dashboard/petitioner/lca/${companyId}`, payload).pipe(
        pluck('data'),
        map((codes: Array<any>) => {
          return codes;
        })
      )
    }
  getAssociates(id) {
    return this.httpClient.get(`/company/${id}/associates`).pipe(
      map((data) => {
        return data;
      })
    );
  }

  saveVisa(payload, visaType) { 
    payload = this.setPayload(payload);
    let url;
    if (payload.isBeneficiaryIntiate) {
      url = `/${visaType}/task/addBene`
    } else {
      url = `/${visaType}/task/add`
    }
    return this.httpClient.post(url, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }
  
  addTaskFromWizard(payload, visaType) {
    return this.httpClient.post(`/wizard/${visaType}/task/add`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }

  updateTaskFromWizard(payload, visaType) {
    return this.httpClient.post(`/${visaType}/task/${payload?.id}/update`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }

  getPositionDetails(payload, visaType, taskId) {
    return this.httpClient.post(`/${visaType}/PETITION/${taskId}/positionDetails`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }

    getArchivedLcaCodes(userType, companyId, payload) {
        return this.httpClient.post(environment.baseUrl + `/dashboard/petitioner/lca/${companyId}`, payload).pipe(
            pluck('data'),
            map((codes: Array<any>) => {
                return codes;
            })
        )
    }

    getLcafilterDetails(companyId, payload) {
        return this.httpClient.post(`${environment.baseUrl}/dashboard/petitioner/lca/company/${companyId}/list/`, payload).pipe(
            pluck('data'),
            map((codes: Array<any>) => {
                return codes;
            })
        )
    }

    getLcaPostedfilterDetails(companyId, payload) {
        return this.httpClient.post(`${environment.baseUrl}/dashboard/petitioner/lca/noticeBoard/postedLca/${this.toEncrypt(companyId)}`, payload).pipe(
            pluck('data'),
            map((codes: Array<any>) => {
                return codes;
            })
        )
    }
    getLcaArchivedfilterDetails(companyId, payload) {
        return this.httpClient.post(`${environment.baseUrl}/dashboard/petitioner/lca/noticeBoard/archivedPostedLca/${this.toEncrypt(companyId)}`, payload).pipe(
            pluck('data'),
            map((codes: Array<any>) => {
                return codes;
            })
        )
    }
    getLcaById(petitionerId, lcaId): Observable<any> {
        return this.httpClient.get(`${environment.baseUrl}'dashboard/petitioner/lca/petitioner/${petitionerId}/lca/${lcaId}`).pipe(
            map((response: ImagilityBaseResponse) => response.data)
        );
    }

    getLcaDetailsById(lcaId): Observable<any> {
        return this.httpClient.get(`/dashboard/petitioner/lca/${lcaId}`).pipe(
            map((response: ImagilityBaseResponse) => response.data)
        );
    }

    getLcaDetailsPrivateById(companyid, taskid): Observable<any> {
        return this.httpClient.get(`/compliance/company/${companyid}/privateAccessFiles/task/${taskid}`).pipe(
            map((response: ImagilityBaseResponse) => response.data)
        );
    }

    // saveLca(petitionerId, payload) {
    //   return this.httpClient.post(environment.baseUrl + 'dashboard/petitioner/lca/petitioner/'+petitionerId, payload).pipe(
    //     map((response: ImagilityBaseResponse) => response.data)
    // }

    getPetitionsByCompanyId(companyId: number, body) {
        return this.httpClient.post(`/petitioner/dashboard/petitions/company/${companyId}/`, body).pipe(
            map((response: ImagilityBaseResponse) => response.data)
        );
    }

    getForeignCompanyListForEVisa(visaType, companyId) {
        return this.httpClient.get(`/${visaType}/company/${companyId}/list`).pipe(
            pluck('data'), map((data: any[]) => {
                let returnResult = [];
                if (data && data.length > 0) {
                    returnResult = data.map(x => {
                        return {
                            ...x,
                            id: x.companyId
                        };
                    });
                }
                return returnResult;
            })
        );
    }

    getPetitionerClientList(companyId, countryType: string = '') {
        let apiUrl = `/company/${companyId}/hierarchy`;
        if (countryType !== '') {
            apiUrl = `${apiUrl}?${countryType}=USA`;
        }
        return this.httpClient.get(apiUrl).pipe(
            pluck('data'), map((data: Array<any>) => {
                return data;
            })
        );
    }

    deleteLca(id) {
        return this.httpClient.delete(`/dashboard/petitioner/lca/${id}`).pipe(
            map((data) => {
                return data;
            })
        );
    }


    linkBeneficiaryToPetitioner(linkId, payload) {
        this.httpClient = this.httpClient.disableJwt();
        return this.httpClient.post(`/beneficiary/linkBeneficiaryToPetitioner/${linkId}`, payload).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status == 200) {
                    return response.data;
                }
                else {
                    return null;
                }
            })
        );
    }

    setPayload(obj) {
        let merged = { ...obj, ...this.interpreterInfo };
        if (merged.interpreterId === '') {
            merged.interpreterId = '0';
        }
        if (merged.interpreterLangId === '') {
            merged.interpreterLangId = '0';
        }
        return merged;
    }

    saveTask(payload, immigrantType, immigrantCode?) {
        payload = this.setPayload(payload);
        if (immigrantCode === 'IMMPET') {
            return this.httpClient.post(`/task/immigrantCode/${immigrantCode}/immigrationCategory/${payload.immCategoryCode}/immigrationClasification/${payload.immClassificationCode}`, payload).pipe(
                map((response: ImagilityBaseResponse) => {
                    if (response.status === 200) {
                        return response;
                    }
                })
            );
        } else if (payload.taskTypeCode === 'RFE') {
            // return this.httpClient.post(`/task/immigrantCode/${immigrantCode}/immigrationCategory/${payload.immCategoryCode}/immigrationClasification/${payload.immClassificationCode}`, payload).pipe(
            return this.httpClient.post(`/task/petitionType/${payload.petitionType}/visaType/${payload.visaType}/immigrantCode/${immigrantType}`, payload).pipe(
                //  task/petitionType/{petitionTypeCode}/visaType/{visaTypeCode}/immigrantCode/{immigrantTypeCode}
                map((response: ImagilityBaseResponse) => {
                    if (response.status === 200) {
                        return response;
                    }
                })
            );
        } else {
            return this.httpClient.post(`/task/petitionType/${payload.petitionType}/visaType/${payload.visaType}/immigrantCode/${immigrantType}?outsideImmagility=${payload.isOutsideImagility === 1}`, payload).pipe(
                map((response: ImagilityBaseResponse) => {
                    if (response.status === 200) {
                        return response;
                    }
                })
            );
        }
    }
    saveTaskDetails(payload, immigrantType) {
        return this.httpClient.post(`/task/petitionType/${payload.petitionType}/visaType/${payload.visaType}/immigrantCode/${immigrantType}/check`, payload).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status === 200) {
                    return response;
                }
            })
        );
    }


    saveRfeTask(payload) {
        return this.httpClient.post(`/rfe/task`, payload).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status === 200) {
                    return response;
                }
            })
        );
    }

    getDocumentTypes() {
        return this.httpClient.get(`/task/getDocumentTypes`).pipe(
            map((response: ImagilityBaseResponse) => response.data)
        );
    }

  getTaskbyTaskType(id, taskId: number) {
    return this.httpClient.get(`/${id}/task/${taskId}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        }
      })
    );
  }

    // }
    getTimelineSteps(companyId, petitionType, visaType, immigrantType, selectedTaskType) {
        this.httpClient = this.httpClient.enableJwt();
        return this.httpClient.get(`/task/step?immigrationType=${immigrantType}&petitionType=${petitionType}&taskType=${selectedTaskType}&visaType=${visaType}&companyId=${companyId}`).pipe(
            map((response: ImagilityBaseResponse) => response.data)
        );
    }

    getSuperTask(superTaskId) {
        return this.httpClient.get(`/superTask/${superTaskId}/overview`).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status === 200) {
                    return response.data;
                } else {
                    return null;
                }
            })
        );
    }

    getPetitionId(superTaskId): Observable<any> {
        return this.httpClient.get(`/superTask/${superTaskId}/overview`);
    }
    // getTimelineStepsForRFE(selectedTaskType) {
    //   return this.httpClient.get(`/task/step?taskType=${selectedTaskType}`).pipe(
    //     map((response: ImagilityBaseResponse) => response.data)
    //   );
    // }
    getTimelineStepsForRFE(selectedTaskType) {
        return this.httpClient.get(`/task/step?taskType=${selectedTaskType}`).pipe(
            map((response: ImagilityBaseResponse) => response.data)
        );
    }


    getTimelineStepsI130(
        selectedTaskType: any,
        payload: {
            immigrationCategory: string;
            immigrationClassification: string;
            immigrationType: string;
            parentStepId: number;
            petitionType: string;
            taskType: any;
            visaType: string;
        }) {
        return this.httpClient.post(`/${selectedTaskType}/timelineSteps`, payload).pipe(
            map((response: ImagilityBaseResponse) => response.data)
        );
    }

    getTask(taskId) {
        return this.httpClient.get(`/task/${taskId}`).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status === 200) {
                    return response.data;
                }
            })
        );
    }

    getRecentVisitedTask(viewBy, payload) {
        return this.httpClient.post(`/recentlyVisitedTask/${viewBy}`, payload).pipe(
            map((response: any) => response.data)
        );
    }

    getTaskAudit(taskId) {
        return this.httpClient.get(`/task/${taskId}/audit`).pipe(
            map((response: ImagilityBaseResponse) => response.data)
        );
    }



    postNotification(payload) {
        return this.httpClient.post(`/notification/save`, payload).pipe(
            map((response: ImagilityBaseResponse) => response)
        );
    }

    getTaskQuestioneryOnCategory(quesCategory: string) {
        return this.httpClient.get(`/question/category/${quesCategory}`).pipe(
            map((response: ImagilityBaseResponse) => response.data)
        );
    }

    getTaskQuestionery() {
        return this.httpClient.get(`/question/category/TASKQUES`).pipe(
            map((response: ImagilityBaseResponse) => response.data)
        );
    }

    detectTaskFilterChanges(task, immigrantType) {
        this.taskFilters$.next({
            visaType: task.visaType,
            petitionType: task.petitionType,
            taskTypeCode: task.taskTypeCode,
            immigrantType
        });
    }

    getPetitionsByBeneficiaryId(beneficiaryId: number, companyId) {
        return this.httpClient.get(`/beneficiary/${beneficiaryId}/profile/company/${companyId}/getPetition`).pipe(
            map((response: ImagilityBaseResponse) => response.data)
        );
    }

    getpetitionerTeamList(companyId) {
        return this.httpClient.get(`/company/${companyId}/employee`).pipe(
            map((response: ImagilityBaseResponse) => response.data)
        );
    }

    getI29ProcessingTimeNotifications(serviceCenter) {
        this.httpClient = this.httpClient.enableJwt();
        return this.httpClient.get(`/processTime?form=I-129&serviceCenter=${serviceCenter}`).pipe(
            map((response: ImagilityBaseResponse) => response.data)
        );
        /*Additional filters  &formType=   &serviceCenter=    &visaType=H1-B  */
    }

    getPublicAccessFiles(companyId, payload) {
        return this.httpClient.post(`${environment.baseUrl}/compliance/company/${this.toEncrypt(companyId)}/publicAccessFiles/`, payload).pipe(
            pluck('data'),
            map((codes: Array<any>) => {
                return codes;
            })
        )
    }

    getI9ManageList(i9AdminCompanyId, loggedinUserId, payload) {
        return this.httpClient.post(environment.baseUrl + `/i9admin/bulkUploadEmployess?i9AdminCompanyId=${i9AdminCompanyId}&loggedinUserId=${loggedinUserId}`, payload).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status && response.status === 200) {
                    return response;
                }
            })
        );
    }

    deleteBulkUploadDocumentList(beneficiaryId, companyId) {
        return this.httpClient.delete(`/i9admin/bulkUploadBenficiary/${beneficiaryId}/${companyId}`).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status === 200) {
                    return response;
                }
                else {
                    return null;
                }
            }));
    }



    getPrivateAccessFiles(companyId, payload) {
        return this.httpClient.post(`${environment.baseUrl}/compliance/company/${this.toEncrypt(companyId)}/privateAccessFiles/`, payload).pipe(
            pluck('data'),
            map((codes: Array<any>) => {
                return codes;
            })
        )
    }

    downloadDocuments(path: string, fileName: string) {
        this.httpClient.get(path, { responseType: "arraybuffer" })
            .pipe(take(1))
            .subscribe(pdf => {
                const blob = new Blob([pdf], { type: 'application/pdf' });
                saveAs(blob, fileName);
            });
    }


    stepStatusUpdate(payload, taskId, stepId) {
        return this.httpClient.post(`/task/${taskId}/step/${stepId}`, payload).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status && response.status === 200) {
                    return response;
                }
            })
        );
    }

    stepStatusUpdateRFE(payload, rfeId, stepId) {
        return this.httpClient.post(`/rfe/${rfeId}/step/${stepId}`, payload).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status && response.status === 200) {
                    return response;
                }
            })
        );
    }

    getOutsidePetitionDocuments(companyId, petitionId) {
        return this.httpClient.get(`/document/company/${companyId}/petition/${petitionId}`).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status === 200) {
                    return response.data;
                }
            })
        );
    }

    saveOutsidePetitionDocument(companyId, petitionId, categoryName, fileCategory, payload) {
        return this.httpClient.post(`/document/company/${companyId}/petition/${petitionId}/category/${categoryName}/fileCategory/${fileCategory}`, payload).pipe(
            map((response: ImagilityBaseResponse) => {
                return response; // send  full data
            })
        );
    }

    saveReceipt(taskStepId, receiptType, receiptNumber, receiptCategory, filingDate, receiptDate, payload) {
        if (filingDate)
            return this.httpClient.post(`/document/taskStep/${taskStepId}/category/${receiptType}/entity/${receiptNumber}/fileCategory/${receiptCategory}?fillingDate=${filingDate}&receiptDate=${receiptDate}`, payload).pipe(
                map((response: ImagilityBaseResponse) => {
                    return response; // send  full data
                })
            );
        else
            return this.httpClient.post(`/document/taskStep/${taskStepId}/category/${receiptType}/entity/${receiptNumber}/fileCategory/${receiptCategory}?receiptDate=${receiptDate}`, payload).pipe(
                map((response: ImagilityBaseResponse) => {
                    return response; // send  full data
                })
            );
    }

    deleteOutsidePetitionDocument(documentId, companyId, petitionId) {
        return this.httpClient.delete(`/document/${documentId}/company/${companyId}/petition/${petitionId}`).pipe(
            map((response: ImagilityBaseResponse) => {
                return response; // send  full data
            })
        );
    }

    // Updating step status for GC I140
    updateStepStatus(payload, subTaskId, actionCode) {
        return this.httpClient.post(`/greenCard/task/${subTaskId}/actionCode/${actionCode}`, payload).pipe(
            map((response: ImagilityBaseResponse) => {
                return response;
            })
        );
    }

    getUploadedReceiptList(taskStepId, receiptType) {
        return this.httpClient.get(`/document/taskStep/${taskStepId}/category/${receiptType}`).pipe(
            pluck('data'), map((data: Array<any>) => {
                return data;
            }));
    }

    deleteUploadedReceipt(taskStepId, receiptType, receiptNumber, receiptCategory) {
        return this.httpClient.delete(`/document/taskStep/${taskStepId}/category/${receiptType}/entity/${receiptNumber}/fileCategory/${receiptCategory}`).pipe(
            map((response: ImagilityBaseResponse) => {
                return response; // send  full data
            })
        );
    }

    getPolicyList(payload) {
        return this.httpClient.post(`/policy/beneficiary/taskAccessList`, payload).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status && response.status === 200) {
                    return response;
                }
            })
        );
    }

    getBeneficiaryList(payload) {
        return this.httpClient.post(`/policy/beneficiary/task/accessList `, payload).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status && response.status === 200) {
                    return response;
                }
            })
        );
    }

    getTaskBeneficiarySummery(isBeneficiaryVisible, taskId) {
        return this.httpClient.post(`/policy/beneficiary/task/update/accessStatus?isBeneficiaryVisible=${isBeneficiaryVisible}&taskId=${taskId}`, {}).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status && response.status === 200) {
                    return response;
                }
            })
        );
    }

    getPolicyDetails(payload) {
        return this.httpClient.post(`/policy/beneficiary/taskAccess/info`, payload).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status && response.status === 200) {
                    return response;
                }
            })
        );
    }

    getBeneficiaryDetails(accessId) {
        return this.httpClient.get(`/policy/beneficiary/task/${accessId}`).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status && response.status === 200) {
                    return response;
                }
            })
        );
    }

    getPolicyTabList(policyType) {
        return this.httpClient.get(`/policy/${policyType}/tablist`).pipe(
            pluck('data'), map((data: Array<any>) => {
                return data;
            }));
    }

    savePolicy(payload) {
        return this.httpClient.post(`/policy/beneficiary/taskAccess`, payload).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status && response.status === 200) {
                    return response;
                }
            })
        );
    }

    saveTaskBeneficiary(payload) {
        return this.httpClient.post(`/policy/beneficiary/task/createOrUpdate`, payload).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status && response.status === 200) {
                    return response;
                }
                if (response.status && response.status === 202) {
                    return response;
                }
            })
        );
    }

    saveTaskBeneficiaryPetitinerAdmin(payload) {
        return this.httpClient.post(`/policy/beneficiary/task/petitioner/createOrUpdate`, payload).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status && response.status === 200) {
                    return response;
                }
                if (response.status && response.status === 202) {
                    return response;
                }
            })
        );
    }

    deletePolicy(payload) {
        return this.httpClient.delete(`/policy/beneficiary/taskAccess/${payload.companyId}/${payload.superTaskTypeId}/${payload.visaTypeId}/${payload.subTaskTypeId}/${payload.petitionTypeId}/${payload.policyType}/delete`).pipe(
            map((response) => {
                if (response && response['status'] === 200) {
                    return response;
                }
            })
        );
    }

    deleteTaskBeneficiary(accessId) {
        return this.httpClient.delete(`/policy/beneficiary/task/${accessId}/delete`).pipe(
            map((response) => {
                if (response && response['status'] === 200) {
                    return response;
                }
            })
        );
    }

    getI9BeneficiaryList(payload) {
        return this.httpClient.post(`/i9/I9EachBeneficiaryList`, payload).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status === 200) {
                    return response.data;
                }
            })
        );
    }

    getI9BeneficiaryDetailsbyId(beneficiaryId) {  ///api/v1/i9/company/{companyId}/i9form/{i9FormId}/I9Section1Info
        return this.httpClient.get(`/beneficiary/${beneficiaryId}/profile/self`).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status === 200) {
                    return response.data;
                }
            })
        );
    }

    getdocumentList(groupReferenceCode) {
        return this.httpClient.get(`/referenceLibrary/${groupReferenceCode}`).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status === 200) {
                    return response.data;
                }
            })
        );
    }

    getI9EmployerDetails(companyId: number, i9FormId: number) {
        return this.httpClient.get(`/i9admin/company/${companyId}/i9form/${i9FormId}/employerAndVerifyDocInfo`).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status === 200) {
                    return response.data;
                }
            })
        );
    }

    uploadI9Document(companyId: number, i9FormId: number, fileDetails, payload) {
        return this.httpClient.post(`/document/i9/company/${companyId}/i9form/${i9FormId}/category/${fileDetails.group}/fileCategory/${fileDetails.code}`, payload).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status === 200) {
                    return response;
                }
            })
        );
    }


    saveI9Section2(companyId: number, i9FormId: number, payload) {
        return this.httpClient.post(`/i9admin/company/${companyId}/i9form/${i9FormId}/employerAndVerifyDocInfo`, payload).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status === 200) {
                    return response;
                }
            })
        );
    }

    getI9VerificationDocuemt(file, i9FormId: number) {///document/i9/3/category/I9IDDOC/fileCategory/VOTREGCARD
        return this.httpClient.get(`/document/i9/${i9FormId}/category/${file.group}/fileCategory/${file.code}`).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status === 200) {
                    return response.data;
                }
            })
        );
    }

    getGeneratedI9Form(companyId: number, i9FormId: number) {
        return this.httpClient.get(`/i9admin/document/i9/company/${companyId}/i9form/${i9FormId}`).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status === 200) {
                    return response.data;
                }
            })
        );
    }

    generateFullI9Form(i9FormId) {
        return this.httpClient.get(`/i9admin/doc/document/i9/${i9FormId}`).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status === 200) {
                    return response.data;
                }
            })
        );
    }

    generateI9Form(payload:any) {///I9/Company/359/I9form/3/generate/
        return this.httpClient.post(`/i9admin/Company/I9form/generate`, payload).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status === 200) {
                    return response;
                }
            })
        );
    }
    updateI9Status(i9FormId: number, stausCode) {
        return this.httpClient.post(`/i9/${i9FormId}/status/${stausCode}/`, {}).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status === 200) {
                    return response;
                }
            })
        );
    }

    getPetitionDropdownList(beneficiaryId) {
        return this.httpClient.get(`/beneficiary/${beneficiaryId}/petitions`).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status === 200) {
                    return response.data;
                }
            })
        );
    }

    getTaskDetails(taskId) {
        return this.getTask(taskId);
    }

    getImmigrationTaskDetails(taskId) {
        return this.httpClient.get(`/I130/task/${taskId}`).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status === 200) {
                    return response.data;
                }
            })
        );
    }

    getTaskTimeline(taskId) {
        return this.httpClient.get(`/task/${taskId}/timeline`).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status === 200) {
                    return response.data;
                }
            })
        );
    }

    postTaskTimelineCompletedPetitions(beneficiaryId, payload) {
        return this.httpClient.post(`/beneficiary/${beneficiaryId}/completedPetitions`, payload).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status === 200) {
                    return response.data;
                }
            })
        );
    }

    getCompanyListAssociatedToBene(beneficiaryId) {
        return this.httpClient.get(`/beneficiary/${beneficiaryId}/petitioner`).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status === 200) {
                    return response.data;
                }
            })
        );
    }


    //------------------------------------------- Petition Notification---------------------------------------
    getPetitionNotificationList(payload, type) {
        // this.httpClient = this.httpClient.disableJwt();
        return this.httpClient.post(`/policies/petitionNotificationSettings/list/${type}`, payload).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status == 200) {
                    return response.data;
                }
                else {
                    return null;
                }
            })
        );
    }

    getPetitionNotificationSettings(notificationId) {
        return this.httpClient.get(`/policies/petitionNotificationSettings/${notificationId}`).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status == 200) {
                    return response.data;
                }
                else {
                    return null;
                }
            })
        );
    }

    deletePetitionNotification(petitionNotificationIdId: number) {
        return this.httpClient.delete(`/policies/petitionNotificationSettings/${petitionNotificationIdId}`).pipe(
            map((response) => {
                if (response && response['status'] === 200) {
                    return response;
                }
            })
        );
    }

    getCustomeStepsList(payload) {
        return this.httpClient.post(`/getCustomStepByTaskType`, payload).pipe(
            map((response) => {
                if (response && response['status'] === 200) {
                    return response;
                }
            })
        )
    }
    getAllStepsList(payload, isEdit) {
        let url;
        // if(isEdit){
        //   url= `/getAllSteps?isUpdate=true`;
        // }
        // else {
        //   url= `/getAllSteps`;
        // }

        if (isEdit) {
            url = `/getAllSteps`;
        }
        else {
            url = `/getAllSteps?isCreate=true`;
        }
        return this.httpClient.post(url, payload).pipe(
            map((response) => {
                if (response && response['status'] === 200) {
                    return response;
                }
            })
        );
    }
    getAllTemplateList(payload) {
        // passing document type. 
        return this.httpClient.post(`/emailTemplates/list/EMALTEMPLT`, payload).pipe(
            map((response) => {
                if (response && response['status'] === 200) {
                    return response;
                }
            })
        );
    }

    getStates() {
        return this.httpClient.get(`/countries/states?countryCode=USA`).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status === 200) {
                    return response.data;
                }
            })
        );
    }

    getCountries() {
        return this.httpClient.get(`/countries`).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status === 200) {
                    return response.data;
                }
            })
        );
    }

    resendDataLogs(id: number) {
        return this.httpClient.post(`/resendDataLog/${id}`, '').pipe(
            map((response: any) => {
                if (response.status === 200) {
                    return response;
                }
            })
        );
    }

    deleteDataLogs(id: number) {
        return this.httpClient.delete(`/deleteDataLog/${id}`).pipe(
            map((response: any) => {
                if (response.status === 200) {
                    return response;
                }
            })
        );
    }


    getState() {
        return this.httpClient.get(`/countries/states?countryCode=USA`).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status === 200) {
                    return response.data;
                }
            })
        );
    }

    uploadPublicDocument(companyId, categoryName, entityId, fileCategory, file) {
        return this.httpClient.post(`/document/company/${companyId}/category/${categoryName}/entity/${entityId}/fileCategory/${fileCategory}`, file).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status === 200) {
                    return response;
                }
            })
        );
    }

    getLcaDataById(lcaId): Observable<any> {
        return this.httpClient.get(`/dashboard/petitioner/lca/${lcaId}`).pipe(
            map((response: ImagilityBaseResponse) => response.data)
        );
    }

    deleteUploadDocument(companyId, categoryName, fileCategory, fileId) {
        return this.httpClient.delete(`/i9admin/document/company/${companyId}/category/${categoryName}/fileCategory/${fileCategory}/${fileId}?isi9AdminDeleted=1`).pipe(
            map((response) => response)
        )
    }

    uploadPrivateDocument(file, apiUrl) {
        return this.httpClient.post(apiUrl, file).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status === 200) {
                    return response;
                }
            })
        );
    }

    saveBulkUploadDocuments(company_id, file) {
        return this.httpClient.post(`/i9admin/document/beneficiaries/company/${company_id}/saveBulkuploadDocuments`, file).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status === 200) {
                    return response;
                }
            })
        );
    }

    getBulkUploadDocuments(company_id): Observable<ImagilityBaseResponse> {
        const requestData = {};
        return this.httpClient.post(`/i9admin/document/beneficiaries/company/${company_id}/getBulkUploadDocuments`, requestData)
            .pipe(
                map((response: ImagilityBaseResponse) => {
                    return response;
                })
            );
    }

    archiveOrRestoreLCA(lcaID, companyId, lcaStatus) {
        const encryptedLcaId = this.toEncrypt(lcaID);
        const encryptedCompId = this.toEncrypt(companyId);
        return this.httpClient.post(environment.baseUrl + `/dashboard/petitioner/lca/noticeBoard/archive/${encryptedLcaId}/${encryptedCompId}/${lcaStatus}`, {}).pipe(
            pluck('data'),
            map((response: Array<any>) => {
                return response;
            })
        )
    }

    deleteArchivedLca(lcaID, companyId) {
        const encryptedLcaId = this.toEncrypt(lcaID);
        const encryptedCompId = this.toEncrypt(companyId);
        return this.httpClient.delete(environment.baseUrl + `/dashboard/petitioner/lca/noticeBoard/${encryptedLcaId}/${encryptedCompId}`).pipe(
            pluck('data'),
            map((response: Array<any>) => {
                return response;
            })
        )
    }

    takeDownLCA(lcaID) {
        const encryptedLcaId = this.toEncrypt(lcaID);
        return this.httpClient.post(environment.baseUrl + `/dashboard/petitioner/lca/noticeBoard/takedown/${encryptedLcaId}`, {}).pipe(
            pluck('data'),
            map((response: Array<any>) => {
                return response;
            })
        )
    }


    deleteEmployee(beneficiaryId, companyId) {
        return this.httpClient.delete(`/i9admin/${companyId}/User/${beneficiaryId}`).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status === 200) {
                    return response;
                }
                else {
                    return null;
                }
            }));
    }

    getEmployeeDetails(companyId, beneficiaryId): Observable<any> {
        return this.httpClient.get(`/i9admin/getBeneficiaryDetails/${companyId}/${beneficiaryId}`).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status === 200) {
                    return response.data;
                }
            }),
            catchError((error: HttpErrorResponse) => {
                // Handle HTTP errors (e.g., 500 Internal Server Error)
                if (error.status === 500) {
                    this.toastr.error('Internal Server Error. Please try again later.');
                } else {
                    this.toastr.error('An unexpected error occurred.');
                }
                return throwError(error); // Re-throw the error so that the caller knows an error occurred
            })
        )
    };


    updateEmployee(payload): Observable<any> {
        return this.httpClient.post(`/i9admin/updateBeneficiaryDetails`, payload).pipe(
            map((response: ImagilityBaseResponse) => response)
        )
    };

    // updateEmployeeStatus(beneficiaryId, companyId, status) {
    //     return this.httpClient.get(`/i9admin/inactivateEmployees/${companyId}/${beneficiaryId}/${status}`).pipe(
    //         map((response: ImagilityBaseResponse) => {
    //             if (response.status === 200) {
    //                 return response;
    //             }
    //             else {
    //                 return null;
    //             }
    //         }));
    // }

    updateEmployeeStatus(payload) {
        return this.httpClient.post(`/i9admin/inactivateEmployees`, payload).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status === 200) {
                    return response;
                }
                else {
                    return null;
                }
            }));
    }

  getAllTemplateListForTask(payload) {
    // passing document type. 
    return this.httpClient.post(`/emailTemplate/list/EMALTEMPLT?tempCategory='GENERIC'&tempCategory='PETITION'&tempCategory='TASK'`, payload).pipe(
      map((response) => {
        if (response && response['status'] === 200) {
          return response;
        }
      })
    );
  } 

  getEmailTemplateById(emailTemplateId) {
    let upperCaseParam;
    return this.httpClient.get(`/emailTemplates/${emailTemplateId}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status == 200) {
          return response.data;
        }
        else {
          return null;
        }
      })
    );
  }


  getEmailTemplateByIdBenId(emailTemplateId, benId, currentSelection) {
    debugger

    if(currentSelection == 'BENEFICIARY' || currentSelection == 'INDIVIDUAL'){
      return this.httpClient.get(`/emailTemplates/emailTemplateId/${emailTemplateId}?beneficiaryId=${benId}`).pipe(
        map((response: ImagilityBaseResponse) => {
          if (response.status == 200) {
            return response.data;
          }
          else {
            return null;
          }
        })
      );
    }
    else if(currentSelection == 'PETITIONER'){
      return this.httpClient.get(`/emailTemplates/emailTemplateId/${emailTemplateId}?petitionerId=${benId}`).pipe(
        map((response: ImagilityBaseResponse) => {
          if (response.status == 200) {
            return response.data;
          }
          else {
            return null;
          }
        })
      );
    }


    else if(currentSelection == 'PETITION'){

      var subTaskId = JSON.parse(sessionStorage.getItem('selectedTaskItem'));
      let staskId = subTaskId.subTaskId;

      return this.httpClient.get(`/emailTemplates/emailTemplateId/${emailTemplateId}?taskId=${staskId}`).pipe(
        map((response: ImagilityBaseResponse) => {
          if (response.status == 200) {
            return response.data;
          }
          else {
            return null;
          }
        })
      );
    }

    else{
      return this.httpClient.get(`/emailTemplates/emailTemplateId/${emailTemplateId}`).pipe(
        map((response: ImagilityBaseResponse) => {
          if (response.status == 200) {
            return response.data;
          }
          else {
            return null;
          }
        })
      );
    }

   
  }


  getBeneficiariesListForPetitioner(companyId) {
    let userId = sessionStorage.getItem('companyId');
    let payload = {"pageNumber":1,"recordsPerPage":200,"searchCriteria":"","searchText":"","sortBy":"beneficiaryName","sortOrder":"asc","status":["ACTIVE"]};
    return this.httpClient.post(`/petitioner/dashboard/beneficiaries/company/${userId}`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        }
        else {
          return null;
        }
      }));
  }

  getBeneficiariesListForIndividual(companyId) {
    let userId = sessionStorage.getItem('companyId');
    return this.httpClient.get(`/attorney/${userId}/allbeneficiaries/list`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        }
        else {
          return null;
        }
      }));
  }



  getListForPetitioner(companyId) {
    return this.httpClient.get(`/company/${companyId}/hierarchy`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        }
        else {
          return null;
        }
      }));
  }


  getListForAttorney(companyId) {
    let payload = {"searchText":"","searchCriteria":"","sortBy":"asc","sortOrder":"","pageNumber":1,"recordsPerPage":200,"fromAttorneyCreatePetitioner":true,"includeAssociate":false}
    return this.httpClient.post('/attorney/dashboard/petitioner/company/' + companyId, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        }
        else {
          return null;
        }
      }));
  }


  postAutoPopulateData(payload) {
    return this.httpClient.post(`/autopopulate`, payload).pipe(
      map((response: ImagilityBaseResponse) => response.data)
    );
  }

  getDataLogs(taskTypeId: number, companyId: number, beneficiaryId: number) {
    return this.httpClient.post(`/getDataLog/${taskTypeId}/${companyId}/${beneficiaryId}`,'').pipe(
      map((response: any) => {        
        if (response.status === 200) {          
          return response.data;
        }
      })
    );
  }

  getTaskLevelData(taskTypeId: number, companyId: number, beneficiaryId: number) {
    return this.httpClient.post(`/getTaskTypeDetails/${taskTypeId}/${companyId}/${beneficiaryId}`,'').pipe(
      map((response: any) => {        
        if (response.status === 200) {          
          return response.data;
        }
      })
    );
  } 

  getJobPostDetails(companyId: string, fromDate: string, toDate: string): Observable<any> {
    const params = new HttpParams()
      .set('fromDate', this.toEncrypt(fromDate))
      .set('toDate', this.toEncrypt(toDate));
    return this.httpClient.get(`/imagility/company/${companyId}`, { params }).pipe(
      map((response: ImagilityBaseResponse) => response.data)
    )
  };

  getRecentActivity(isBenActivity: boolean) {
    const params = new HttpParams().set('isBenActivity', String(isBenActivity));
    return this.httpClient.get(`/getRecentWorkDetails`, { params }).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        }
        return null;
      })
    );
  }

  toEncrypt(_data) {
    var pharase = 'c63a423527b24f65'
    var data = _data;
    var key  = CryptoJS.enc.Latin1.parse(pharase);
    var iv   = CryptoJS.enc.Latin1.parse(pharase);  
    var encrypted = CryptoJS.AES.encrypt(
      data,
      key,
      {iv:iv,mode:CryptoJS.mode.CBC,
        padding:CryptoJS.pad.ZeroPadding
    }).toString();

    var e64 = CryptoJS.enc.Base64.parse(encrypted);
    var eHex = e64.toString(CryptoJS.enc.Hex);

   return this.encriptedData = eHex
  }

  getTreatyCountries(visaType){
    return this.httpClient.get(`/${this.toEncrypt(visaType)}/treatyCountries `).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.data) {          
          return response.data;
        }
      })
    );
  }
 
}
