<div class="main-container">
    <div class="back-section" *ngIf="isAdminViewing">
        <button class="back-button d-inline-flex align-items-center pr-3 pl-3 mr-3" (click)="goBack()"><i
                class="fa fa-chevron-circle-left pr-1" aria-hidden="true"></i>Back</button>
    </div>


    <header *ngIf="!isEdit">
        <div class="headerDiv">
            <img src="../../../../assets/weblogo.png" alt="Logo">
        </div>
    </header>

    <section *ngIf="!isEdit">
        <div class="d-flex notification-main">

            <div class="px-0 col-4">
                <h4 class="mb-0">{{bName ? bName : 'NA'}}</h4>
                <p>{{bEmil}}</p>
            </div>

            <div class="px-2 col-8 notification-portion">
                <ul class="nav justify-content-end align-items-center overflow-auto">

                    <li class="accessDate">
                        <span class="wrap">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                class="bi bi-arrow-up" viewBox="0 0 16 16">
                                <path fill-rule="evenodd"
                                    d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z" />
                            </svg>
                        </span>
                        <span class="title-portion">Importance</span><br>
                        <span class="priority1 priority-default">High</span>
                    </li>

                    <!-- <li class="accessDate">
            <span class="wrap">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar3"
                viewBox="0 0 16 16">
                <path
                  d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z" />
                <path
                  d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
              </svg>
            </span>
            <span class="title-portion">Access Till Date</span><br>
            <span class="access-date"><b>{{expiredDate}}</b></span>
          </li> -->
                </ul>

                <div *ngIf="isFormCompleted" class="days-remaining pageValidty text-success mt-2 text-right">Form is
                    Submitted,
                    incase any changes please contact Administrator </div>
                <div *ngIf="isDateExpired" class="days-remaining pageValidty text-success mt-2 text-right"> Form is
                    Expired,
                    Incase any concern please contact Administrator</div>

            </div>
        </div>
    </section>

    <!-- Personal Details -->

    <div class="container-child">
        <div class="col-md-12">
            <h2>Personal Details</h2>
        </div>
    </div>

    <form (ngSubmit)="beneficiaryForm(form)" #form="ngForm" (keydown.enter)="onEnterPress($event)">

        <div class="container-child">

            <div class="col-md-4 mb-15 float-left">
                <label class="required">First Name</label>

                <ng-container *ngIf="!isFormCompleted">
                    <input type="text" class="form-control" [disabled]="isDateExpired" required name="firstName"
                        #firstName="ngModel" [(ngModel)]="beneficiaryData.beneficiaryDetails.firstName" />
                    <div class="invalid-message"
                        *ngIf="(firstName?.touched || form.submitted) && firstName?.errors?.required">
                        Required field.
                    </div>
                </ng-container>

                <div class="input" *ngIf="isFormCompleted">{{beneficiaryData.beneficiaryDetails.firstName}}</div>
            </div>

            <div class="col-md-4 mb-15 float-left">
                <label class="required">Last Name</label>
                <ng-container *ngIf="!isFormCompleted">
                    <input type="text" class="form-control" [disabled]="isDateExpired" required
                        [(ngModel)]="beneficiaryData.beneficiaryDetails.lastName" name="lastName" #lastName="ngModel"
                        required />

                    <div class="invalid-message"
                        *ngIf="(lastName?.touched || form.submitted) && lastName?.errors?.required">
                        Required field.
                    </div>

                </ng-container>
                <div class="input" *ngIf="isFormCompleted">{{beneficiaryData.beneficiaryDetails.lastName}}</div>
            </div>

            <div class="col-md-4 mb-15 float-left">
                <label>Middle Name</label>
                <ng-container *ngIf="!isFormCompleted">
                    <input [disabled]="isFormCompleted || isDateExpired" type="text" class="form-control"
                        name="middleName" [(ngModel)]="beneficiaryData.beneficiaryDetails.middleName">
                </ng-container>
                <div class="input" *ngIf="isFormCompleted">{{beneficiaryData.beneficiaryDetails.middleName}}</div>
            </div>

        </div>

        <div style="clear:both"></div>


        <div>
            <div class="col-md-4 mb-15 float-left">
                <label class="required">Date of Birth</label>
                <ng-container *ngIf="!isFormCompleted">
                    <p-calendar placeholder="MM/DD/YYYY" [disabled]="isFormCompleted || isDateExpired"
                        [monthNavigator]="true" [yearNavigator]="true" yearRange="1900:2100" name="dob" #dob="ngModel"
                        [(ngModel)]="beneficiaryData.beneficiaryDetails.dob" [showIcon]="true" required>
                    </p-calendar>
                    <div class="invalid-message" *ngIf="(dob?.touched || form.submitted) && dob?.errors?.required">
                        Required field.
                    </div>

                </ng-container>

                <div class="input" *ngIf="isFormCompleted">{{beneficiaryData.beneficiaryDetails.dob |
                    date:'MM/dd/YYYY'}}</div>


            </div>

            <div class="col-md-4 mb-15  float-left">
                <label>US. Social Security Number</label>
                <ng-container *ngIf="!isFormCompleted">
                    <input [disabled]="isFormCompleted || isDateExpired" type="text" max="9" name="ssn" #ssn="ngModel"
                        placeholder="Enter 9 Digits Valid SSN" class="form-control"
                        [(ngModel)]="beneficiaryData.beneficiaryDetails.ssn">
                </ng-container>

                <div class="input" *ngIf="isFormCompleted">{{beneficiaryData.beneficiaryDetails.ssn}}</div>

            </div>
        </div>

        <div style="clear:both"></div>


        <div class="container-child border-bottom-dotted">
            <div class="col-md-4 mb-15  float-left">

                <label>Mobile Number</label>

                <input *ngIf="!isFormCompleted" [disabled]="isFormCompleted || isDateExpired" type="number"
                    class="form-control" [(ngModel)]="beneficiaryData.beneficiaryDetails.benPhone[0].phoneNo"
                    name="phoneNo" />
                <div class="input" *ngIf="isFormCompleted">{{beneficiaryData.beneficiaryDetails.benPhone[0].phoneNo}}
                </div>
            </div>

            <div class="col-md-4 mb-15  float-left">
                <label>Email Address</label>

                <ng-container *ngIf="!isFormCompleted">
                    <input [disabled]="isFormCompleted || isDateExpired" type="text" class="form-control" name="email"
                        #email="ngModel" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                        [(ngModel)]="beneficiaryData.beneficiaryDetails.benEmail[0].email" />
                    <div class="invalid-message" *ngIf="email?.touched && email?.invalid">
                        Invalid values.
                    </div>
                </ng-container>

                <div class="input" *ngIf="isFormCompleted">{{beneficiaryData.beneficiaryDetails.benEmail[0].email}}
                </div>
            </div>
        </div>

        <div style="clear:both"></div>

        <div class="container-child">
            <div class="col-md-12">
                <h2>Address Details</h2>
            </div>
        </div>

        <div style="clear:both"></div>

        <div class="row container-child mb-15 p-0">
            <div class="col-md-8">
                <label class="required">Address 1</label>

                <ng-container *ngIf="!isFormCompleted">
                    <input [disabled]="isFormCompleted || isDateExpired" type="text" required name="address1"
                        #address1="ngModel" class="form-control"
                        [(ngModel)]="beneficiaryData.beneficiaryDetails.benAddress[0].addressLine1" />

                    <div class="invalid-message"
                        *ngIf="(address1?.touched || form.submitted) && address1?.errors?.required">
                        Required field.
                    </div>
                </ng-container>

                <div class="input" *ngIf="isFormCompleted">
                    {{beneficiaryData.beneficiaryDetails.benAddress[0].addressLine1}}
                </div>
            </div>
            <div class="col-md-4">
                <label>Address 2</label>
                <input *ngIf="!isFormCompleted" [disabled]="isFormCompleted || isDateExpired" type="email"
                    class="form-control" [(ngModel)]="beneficiaryData.beneficiaryDetails.benAddress[0].addressLine2"
                    name="addressLine2">
                <div class="input" *ngIf="isFormCompleted">
                    {{beneficiaryData.beneficiaryDetails.benAddress[0].addressLine2}}
                </div>
            </div>
        </div>

        <div style="clear:both"></div>

        <div class="container-child row p-0">
            <!-- <div class="col-md-4 mb-15  float-left">
        <label class="required">Country</label>
        <input *ngIf="!isFormCompleted" [disabled]="true" type="text" class="form-control" name="country"
          #country value="USA">
        <div class="input" *ngIf="isFormCompleted">{{'USA'}}</div>
      </div> -->

            <div class="col-md-4 mb-15  float-left">
                <label class="required">City</label>

                <ng-container *ngIf="!isFormCompleted">
                    <input [disabled]="isFormCompleted || isDateExpired" type="text" class="form-control" required
                        name="city" #city="ngModel"
                        [(ngModel)]="beneficiaryData.beneficiaryDetails.benAddress[0].city" />

                    <div class="invalid-message" *ngIf="(city?.touched || form.submitted) && city?.errors?.required">
                        Required field.
                    </div>
                </ng-container>

                <div class="input" *ngIf="isFormCompleted">{{beneficiaryData.beneficiaryDetails.benAddress[0].city}}
                </div>
            </div>

            <div class="col-md-4 mb-15 float-left">
                <label class="required">State</label>
                <div class="dropdownsState">

                    <ng-container *ngIf="!isFormCompleted">
                        <p-dropdown inputId="states" [disabled]="isFormCompleted || isDateExpired" [options]="stateList"
                            placeholder="Select State" optionLabel="stateProvinceName" optionValue="stateProvinceCode"
                            [(ngModel)]="beneficiaryData.beneficiaryDetails.benAddress[0].stateProvinceName" required
                            name="states" #states="ngModel" (onChange)="getStateValue($event)"
                            class="state-list-sec w-100">
                        </p-dropdown>
                        <div class="invalid-message"
                            *ngIf="(states?.touched || form.submitted) && states?.errors?.required">
                            Required field.
                        </div>
                        <!-- required -->
                    </ng-container>

                    <div class="input" *ngIf="isFormCompleted">
                        {{beneficiaryData.beneficiaryDetails.benAddress[0].stateProvinceName}}</div>

                </div>

            </div>

            <div class="col-md-4 mb-15  float-left">
                <label class="required">Zip/Postal Code</label>

                <ng-container *ngIf="!isFormCompleted">

                    <input [disabled]="isFormCompleted || isDateExpired" type="text" class="form-control" required
                        name="zipCode" #zipCode="ngModel" pattern="^\d{5}$" placeholder="Enter 5 Digits Valid Zipcode"
                        [(ngModel)]="beneficiaryData.beneficiaryDetails.benAddress[0].postCode" [maxlength]="5">

                    <div class="invalid-message"
                        *ngIf="(zipCode?.touched || form.submitted) && zipCode?.errors?.required">
                        Required field.
                    </div>

                    <div class="invalid-message" *ngIf="zipCode?.touched && zipCode?.invalid">
                        Invalid values.
                    </div>

                </ng-container>

                <div class="input" *ngIf="isFormCompleted">{{beneficiaryData.beneficiaryDetails.benAddress[0].postCode}}
                </div>

            </div>

        </div>

        <div style="clear:both"></div>

        <div class="container-child mt-4">
            <div class="col-md-12 p-0">
                <h2>Check one of the following options to attest your citizenship or immigration status </h2>
            </div>
        </div>
        <div class="row tabContainer m-0 mb-4"
            [ngClass]="{'disableDiv': isLockedAction || isFormCompleted || isDateExpired}">

            <ul id="myTab" role="tablist">
                <li *ngFor="let key of formDocuments; let i = index;"
                    (click)="radioButton(key.category, key.fileCategory, i)" [title]="key.desc" [alt]="key.desc">
                    <a id="{{key.category}}-tab" data-toggle="tab" href="#{{key.category}}" role="tab"
                        aria-controls="{{key.category}}" aria-selected="true"
                        [ngClass]="{'active': key.category == getAuthorizationType}">{{key.sortName}}</a>
                </li>
            </ul>

            <div class="tab-content pb-2" id="myTabContent">
                <div class="tab-pane fade show active" id="CITZN" role="tabpanel" aria-labelledby="CITZN-tab"
                    [ngClass]="{'active in': getAuthorizationType == 'CITZN'}" title="U.S Passport or PR Card"
                    alt="U.S Passport or PR Card">
                    <div class="clearfix"></div>
                </div>
                <div class="tab-pane fade show" id="NONCITZNNATIONAL" role="tabpanel"
                    aria-labelledby="NONCITZNNATIONAL-tab"
                    [ngClass]="{'active in': getAuthorizationType == 'NONCITZNNATIONAL'}"
                    title="An individual born in American Samoa, certain former citizens of the former Trust Territory of the Pacific Islands, and certain children of noncitizen nationals born abroad"
                    alt="An individual born in American Samoa, certain former citizens of the former Trust Territory of the Pacific Islands, and certain children of noncitizen nationals born abroad">
                    <div class="clearfix"></div>
                </div>

                <div class="tab-pane fade show" id="PERMRESIDENT" role="tabpanel" aria-labelledby="PERMRESIDENT-tab"
                    [ngClass]="{'active in': getAuthorizationType == 'PERMRESIDENT'}"
                    title="Refers to an individual who is a LPR of the US, known as a green card holder. This term includes conditional residents. Employees choosing this status should enter their seven- to nine-digit Alien Registration Number or USCIS Number. Asylees and refugees should not choose this option; instead, they should select A noncitizen authorized to work."
                    alt="Refers to an individual who is a LPR of the US, known as a green card holder. This term includes conditional residents. Employees choosing this status should enter their seven- to nine-digit Alien Registration Number or USCIS Number. Asylees and refugees should not choose this option; instead, they should select A noncitizen authorized to work.">
                    <br>
                    <div class="col-md-4 float-left" style="margin-top: 5px;">
                        <p-dropdown [disabled]="isLockedAction || isFormCompleted || isDateExpired"
                            [style]="{'minWidth':'300px', 'margin-bottom':'10px'}" [options]="lawfulResidentOption"
                            placeholder="Select" (onChange)="lawfulResidentOptionChange($event)"
                            [(ngModel)]="lawfulSelectedValue" name="lawful" #lawful="ngModel"
                            [style]="{'width':'100%'}">

                            <ng-template let-item pTemplate="selectedlawfulResident">
                                {{item}}
                            </ng-template>

                            <ng-template let-item pTemplate="item">
                                {{item}}
                            </ng-template>
                        </p-dropdown>
                    </div>

                    <div *ngIf="selectedlawfulResident == 'A Number'" class="col-md-4 float-left">
                        <input type="text" required name="registration_number" #registration_number
                            [(ngModel)]="beneficiaryData.categoryNumber" [disabled]="isLockedAction || isFormCompleted || isDateExpired"
                            placeholder="Enter A Number" />
                    </div>


                    <div *ngIf="selectedlawfulResident == 'USCIS Number'" class="col-md-4 float-left">
                        <input [disabled]="isLockedAction || isFormCompleted || isDateExpired" name="USCIS_Number"
                            #USCIS_Number required type="text" [(ngModel)]="beneficiaryData.categoryNumber"
                            placeholder="Enter USCIS Number" />
                    </div>

                    <div class="col-md-4 float-left"
                        *ngIf="selectedlawfulResident == 'USCIS Number' || selectedlawfulResident == 'A Number'">
                        <input type="text" disabled [(ngModel)]="selectedLawfulCountry" value="USA"
                            placeholder="United States of America" name="country" #country="ngModel">
                        <div class="clearfix"></div>
                    </div>

                </div>

                <div class="tab-pane fade show" [ngClass]="{'active in': getAuthorizationType == 'WORKAUTHALIEN'}"
                    id="WORKAUTHALIEN" role="tabpanel" aria-labelledby="WORKAUTHALIEN-tab"
                    alt="Individual authorized to work in the US, who is not a U.S. citizen or national, nor a lawful permanent resident. This includes asylees, refugees, and certain citizens of the Federated States of Micronesia, the Republic of the Marshall Islands, or Palau. Employees must also provide the expiration date of their employment authorization. If no expiration date exists, enter N/A. If employment authorization has been automatically extended, provide the expiration date of the extension. Additionally, employees must enter one of the following: Alien Registration Number (A-Number)/USCIS Number, Form I-94 Admission Number, or Foreign Passport Number and Country of Issuance. Example H-1B, STEM, OPT, EAD, L-1’s"
                    title="Individual authorized to work in the US, who is not a U.S. citizen or national, nor a lawful permanent resident. This includes asylees, refugees, and certain citizens of the Federated States of Micronesia, the Republic of the Marshall Islands, or Palau. Employees must also provide the expiration date of their employment authorization. If no expiration date exists, enter N/A. If employment authorization has been automatically extended, provide the expiration date of the extension. Additionally, employees must enter one of the following: Alien Registration Number (A-Number)/USCIS Number, Form I-94 Admission Number, or Foreign Passport Number and Country of Issuance. Example H-1B, STEM, OPT, EAD, L-1’s">
                    <br>

                    <div class="mb-15 col-md-3">
                        <div>
                            <label style="margin-bottom:0px;">Expiration Date<small>(if any)</small></label>
                        </div>
                        <div class="clearfix"></div>
                        <input [disabled]="isLockedAction || isFormCompleted || isDateExpired" type="date"
                            name="expDate" #expDate="ngModel" class="form-control"
                            [ngClass]="{'disabled' :notApplicable}"
                            [(ngModel)]="beneficiaryData.i9WorkAuthPreparerInfo.expirationDate"
                            style="margin-left: 0px;" />
                        <div class="invalid-message" *ngIf="expDate?.errors?.required">
                            Required field.
                        </div>
                    </div>

                    <div class="col-md-3 float-left" style="margin-top: 5px;">
                        <p-dropdown [disabled]="isLockedAction || isFormCompleted || isDateExpired" inputId="everify"
                            [(ngModel)]="selectedValue" [options]="categoryList" placeholder="Select Category"
                            optionLabel="categoryName" optionValue="categoryCode"
                            (onChange)="alienAuthWorkClick($event)" name="categoryEverify" #category="ngModel"
                            [style]="{'width':'100%'}">
                        </p-dropdown>
                    </div>

                    <div class="col-md-3 float-left" *ngIf="selectedValue != 'FORGNPASSPORTNUM'">
                        <input type="text" [disabled]="isLockedAction || isFormCompleted || isDateExpired"
                            [required]="selectedValue == 'FORGNPASSPORTNUM'" placeholder="Enter USCIS/i94/Alien Number"
                            (change)="categoryOnChangeInput($event)" [(ngModel)]="beneficiaryData.categoryNumber" name="categoryNumber"
                            #categoryNumber1="ngModel" />
                    </div>

                    <div class="col-md-3 float-left" *ngIf="selectedValue == 'FORGNPASSPORTNUM'">
                        <input [disabled]="isLockedAction || isFormCompleted || isDateExpired" type="text"
                            [(ngModel)]="beneficiaryData.forgnPassportNum" name="FORGNPASSPORTNUM" #passportNum="ngModel"
                            [attr.required]="selectedValue == 'FORGNPASSPORTNUM'"
                            placeholder="Foreign Passport Number" />
                        <div class="clearfix"></div>
                        <div class="invalid-message"
                            *ngIf="(passportNum?.touched || form.submitted) && passportNum?.errors?.required">
                            Required field.
                        </div>
                    </div>

                    <div class="col-md-3 float-left" *ngIf="selectedValue == 'I94NUM'">
                        <input [disabled]="isLockedAction || isFormCompleted || isDateExpired" type="text"
                            [(ngModel)]="beneficiaryData.forgnPassportNum94" name="FORGNPASSPORTNUM94" #passportNum="ngModel"
                            placeholder="Foreign Passport Number" />
                        <div class="clearfix"></div>
                        <div class="invalid-message"
                            *ngIf="(passportNum?.touched || form.submitted) && passportNum?.errors?.required">
                            Required field.
                        </div>
                    </div>

                    <div class="col-md-3 float-left" style="margin-top: 5px;"
                        *ngIf="(selectedValue == 'FORGNPASSPORTNUM' || selectedValue == 'I94NUM') && selectedValue != null">
                        <p-dropdown [disabled]="isLockedAction || isFormCompleted || isDateExpired" inputId="country"
                            [(ngModel)]="selectedCountry" [options]="countryList" placeholder="Select Country"
                            optionLabel="countryName" optionValue="countryCode" (onChange)="countryOnChange($event)"
                            name="country" #country="ngModel" [attr.required]="selectedValue == 'FORGNPASSPORTNUM'"
                            [style]="{'width':'200px', 'float':'left'}">
                        </p-dropdown>
                        <div class="clearfix"></div>
                        <div class="invalid-message"
                            *ngIf="(country?.touched || form.submitted) && country?.errors?.required">
                            Required field.
                        </div>
                    </div>

                </div>
            </div>

            <div class="clearfix"></div>
        </div>

        <div class="container-child border-bottom-dotted">

            <!-- <div class="col-md-12"> -->
            <h2 style="margin-bottom: 0px;">Digital Signature</h2>
            <h4 style="font-size: 14px;">Please Draw your signature or upload here</h4>


            <div class="mb-15">
                <label class="mr-2">Date</label>
                <div class="clearfix"></div>
                <p-calendar *ngIf="!isFormCompleted" placeholder="MM/DD/YYYY"
                    [disabled]="isFormCompleted || isDateExpired" name="todaysignDate" #todaysignDate="ngModel"
                    [(ngModel)]="beneficiaryData.beneficiaryDetails.beneficiarySignDate" [showIcon]="true"
                    [value]="todayDate">
                </p-calendar>
                <div class="input" *ngIf="isFormCompleted">{{beneficiaryData.beneficiaryDetails.beneficiarySignDate }}
                </div>
            </div>

            <div class="clearfix"></div>

            <div *ngIf="!isEdit" class="float-left" [ngClass]="{disabledbutton: isFormCompleted || isDateExpired}">
                <app-signature-pad (digiSign)="getDigiSign($event)"></app-signature-pad>
            </div>

            <div *ngIf="!isEdit" class="col-md-1 float-left" style="font-size: 18px; text-align:center"><b>OR</b></div>

            <div *ngIf="!isFormCompleted && !isEdit" class="mb-15 upload-btn-wrapper float-left">
                <button [disabled]="isFormCompleted || isDateExpired" [disabled]="isLockedAction"
                    class="btn-primary btn">Upload
                    Signature</button>
                <input [disabled]="isFormCompleted || isDateExpired" [disabled]="isLockedAction" #uploadDocFile1
                    type="file" required name="sign" #sign accept="image/png, image/gif, image/jpeg"
                    (change)="uploadBeneficiarySignature($event.target.files)" />
            </div>


            <div style="border: 1px solid #cecece; padding: 8px; margin-right: 50px;" *ngIf="isSignaturePic"
                class="col-md-2 mb-15 upload-btn-wrapper float-right">
                <img *ngIf="isSignaturePic" class="signatureImageBox p-0" style=" width:150px;"
                    [src]="'data:image/jpg;base64,'+ signaturePic">
            </div>

        </div>

        <div style="clear:both"></div>

        <div class="container-child">

            <h3>Select One documents from list A OR One from both list B and C.
                <span class="see-list-allowed-docs" (click)="viewImageDocument()" >List of Acceptable documents</span>
            </h3>
            <div class="container citizenRadioGroup bgListConteiner">

                <div class="flex flex-wrap gap-3 bgList">

                    <div class="float-left" [ngClass]="{disabledbutton: isFormCompleted}">
                        <p-radioButton class="pRadioAlign" name="sdfsdfds" [ngClass]="{disabledbutton: isDateExpired}"
                            value="A" [(ngModel)]="selectedListGroup" inputId="sel1"
                            (click)="listSelected('A')"></p-radioButton>
                        <label class="ml-2 mr-2">SELECT LIST A</label>
                    </div>

                    <div class="float-left radioList" [ngClass]="{disabledbutton: isFormCompleted}">
                        <p-radioButton class="pRadioAlign" name="sdfds" [ngClass]="{disabledbutton: isDateExpired}"
                            value="BC" [(ngModel)]="selectedListGroup" inputId="sel2"
                            (click)="listSelected('BC')"></p-radioButton>
                        <label class="ml-2 mr-2">SELECT LIST B&C</label>
                    </div>
                </div>
                <!-- *ngIf="selectedListGroup == 'A'" -->
                <div class="container-child">

                    <div class="col-md-12">
                        <h2>I-9 Form Details <span>(Update or Enter data fetched from your profile)</span></h2>
                        <h3 style="margin:0px 10px 20px 0px;" *ngIf="selectedListGroup == 'A'">Select ONE of the below
                            options </h3>
                        <h3 class="required float-left" *ngIf="selectedListGroup == 'BC'"
                            style="margin:0px 10px 20px 0px; font-size: 16px;">Select Documents from List B and C</h3>
                    </div>


                    <div class="clearfix"></div>

                    <div class="row tabContainer"
                        [ngClass]="{'disableDiv': isLockedAction || isFormCompleted || isDateExpired}">
                        <app-multi-upload-documents [showListBC]="selectedListGroup" [listBC]="selectedListGroup"
                            [sectionName]="'Section-1'" [companyId]="companyId" [beneficiaryId]="beneficiaryId"
                            [I9FormId]="i9FormId"
                            [isViewButton]="!isLockedAction && !isFormCompleted && !isDateExpired ? true : false">
                        </app-multi-upload-documents>

                    </div>
                </div>

                <div style="clear:both"></div>
            </div>

        </div>

        <div style="clear:both"></div>



        <div class="container-child border-bottom-dotted">

            <div class="col-md-12">
                <h2 style="margin-bottom:10px;">Let us know if you have used Preparer OR Translator to help you fill
                    this form</h2>
            </div>

            <div class="radioContainer">
                <label>
                    <input type="radio" name="1" id="1" class="documentRadio" [checked]="!isPreparer"
                        (click)="translatorRadio('Translator')"
                        [disabled]="isLockedAction || isFormCompleted || isDateExpired">
                    I did not use a preparer or translator
                </label>
            </div>

            <div class="radioContainer">
                <label>
                    <input type="radio" name="1" id="2" class="documentRadio"
                        [disabled]="isLockedAction || isFormCompleted || isDateExpired" [checked]="isPreparer"
                        (click)="translatorRadio('Preparer')" />
                    A preparer/translator assisted me in completing Section-1 for filling the I-9 Form
                </label>
            </div>


            <div *ngIf="isPreparer">

                <div class="container-child">

                    <div class="container-child">
                        <div class="col-md-12">
                            <h2>Personal Details</h2>
                        </div>
                    </div>

                    <div class="clearfix"></div>

                    <div class="col-md-4 mb-15 float-left">
                        <label class="required">First Name</label>
                        <input *ngIf="!isFormCompleted" [disabled]="isFormCompleted || isDateExpired" type="text"
                            class="form-control" required
                            [(ngModel)]="beneficiaryData.i9WorkAuthPreparerInfo.preparerInfo.firstName"
                            name="preparerName" #preparerName />

                        <div class="input" *ngIf="isFormCompleted">
                            {{beneficiaryData.i9WorkAuthPreparerInfo.preparerInfo.firstName}}
                        </div>
                    </div>

                    <div class="col-md-4 mb-15 float-left">
                        <label class="required">Last Name</label>
                        <input *ngIf="!isFormCompleted" [disabled]="isFormCompleted || isDateExpired" type="text"
                            class="form-control" required
                            [(ngModel)]="beneficiaryData.i9WorkAuthPreparerInfo.preparerInfo.lastName"
                            name="preparerLastName" #preparerLastName />

                        <div class="input" *ngIf="isFormCompleted">
                            {{beneficiaryData.i9WorkAuthPreparerInfo.preparerInfo.lastName}}
                        </div>
                    </div>

                    <div class="col-md-4 mb-15 float-left">
                        <label>Middle Name</label>
                        <input *ngIf="!isFormCompleted" [disabled]="isFormCompleted || isDateExpired" type="text"
                            class="form-control"
                            [(ngModel)]="beneficiaryData.i9WorkAuthPreparerInfo.preparerInfo.middleName">

                        <div class="input" *ngIf="isFormCompleted">
                            {{beneficiaryData.i9WorkAuthPreparerInfo.preparerInfo.middleName}}</div>
                    </div>

                    <div class="clearfix"></div>


                    <div *ngIf="!isFormCompleted" class="col-md-4 mb-15 mb-15 upload-btn-wrapper float-left"
                        style="margin-top: 32px;">
                        <button class="btn-success btn" [disabled]="isLockedAction || isDateExpired">Upload
                            Signature</button>
                        <input [disabled]="isFormCompleted" [disabled]="isLockedAction || isDateExpired" #uploadDocFile1
                            type="file" required name="preparerSign" #preparerSign
                            accept="image/png, image/gif, image/jpeg"
                            (change)="uploadSignatureTranlator($event.target.files)" />
                    </div>

                    <div *ngIf="isPrepSignaturePic" class="col-md-4 mb-15 mb-15 upload-btn-wrapper float-left"
                        style="margin-top: 32px;">
                        <img *ngIf="isPrepSignaturePic" class="signatureImageBox p-0"
                            [src]="'data:image/jpg;base64,'+ prepSmallSignImage">
                    </div>


                    <div class="col-md-4 mb-15  float-left">
                        <label class="mr-2">Date</label>
                        <p-calendar *ngIf="!isFormCompleted" placeholder="mm/dd/yy"
                            [disabled]="isFormCompleted || isDateExpired" name="prepDate" #prepDate="ngModel"
                            [(ngModel)]="beneficiaryData.i9WorkAuthPreparerInfo.preparerInfo.preparerSignDate"
                            [showIcon]="true" [value]="preparerTodayDate">
                        </p-calendar>
                        <div class="input" *ngIf="isFormCompleted">
                            {{beneficiaryData.i9WorkAuthPreparerInfo.preparerInfo.preparerSignDate | date: 'MM/dd/YYYY'
                            }}</div>
                    </div>


                </div>

                <div class="clearfix"></div>

                <br><br>
                <div class="container-child">
                    <div class="col-md-12">
                        <h2>Address Details</h2>
                    </div>
                </div>

                <div class="clearfix"></div>

                <div class="container-child">
                    <div class="col-md-8 mb-15">
                        <label class="required">Address 1</label>
                        <input *ngIf="!isFormCompleted" [disabled]="isFormCompleted || isDateExpired" type="text"
                            required name="preparerAddr1" #preparerAddr1 class="form-control"
                            [(ngModel)]="beneficiaryData.i9WorkAuthPreparerInfo.preparerInfo.prepAddress[0].addressLine1" />

                        <div class="input" *ngIf="isFormCompleted">
                            {{beneficiaryData.i9WorkAuthPreparerInfo.preparerInfo.prepAddress[0].addressLine1}}</div>

                    </div>
                </div>

                <div class="clearfix"></div>

                <div class="container-child">
                    <div class="col-md-8 mb-15">
                        <label>Address 2</label>
                        <input *ngIf="!isFormCompleted" [disabled]="isFormCompleted || isDateExpired" type="email"
                            class="form-control"
                            [(ngModel)]="beneficiaryData.i9WorkAuthPreparerInfo.preparerInfo.prepAddress[0].addressLine2">

                        <div class="input" *ngIf="isFormCompleted">
                            {{beneficiaryData.i9WorkAuthPreparerInfo.preparerInfo.prepAddress[0].addressLine2}}</div>
                    </div>
                </div>

                <div class="clearfix"></div>

                <div class="container-child">

                    <div class="col-md-4 mb-15  float-left">
                        <label class="required">Country</label>
                        <input *ngIf="!isFormCompleted" type="text" class="form-control" required placeholder="USA"
                            value="USA" [disabled]="true" name="preparerCountry" #preparerCountry>

                        <div class="input" *ngIf="isFormCompleted">{{'USA'}}</div>

                    </div>

                    <div class="col-md-4 mb-15  float-left">
                        <label class="required">State</label>
                        <div class="dropdownsState">


                            <p-dropdown inputId="prepstates" *ngIf="!isFormCompleted"
                                [disabled]="isFormCompleted || isDateExpired" [options]="stateList"
                                placeholder="Select State" optionLabel="stateProvinceName"
                                optionValue="stateProvinceCode"
                                [(ngModel)]="beneficiaryData.i9WorkAuthPreparerInfo.preparerInfo.prepAddress[0].stateProvinceCode"
                                required name="prepstates" #prepstates="ngModel"
                                (onChange)="preparerStateOnChange($event)" class="state-list-sec">
                            </p-dropdown>

                            <div class="input" *ngIf="isFormCompleted">
                                {{beneficiaryData.i9WorkAuthPreparerInfo.preparerInfo.prepAddress[0].stateProvinceCode}}
                            </div>

                        </div>
                    </div>

                    <div class="col-md-4 mb-15  float-left">
                        <label class="required">City</label>
                        <input *ngIf="!isFormCompleted" [disabled]="isFormCompleted || isDateExpired" type="text"
                            class="form-control" required name="preparerCity" #preparerCity
                            [(ngModel)]="beneficiaryData.i9WorkAuthPreparerInfo.preparerInfo.prepAddress[0].city" />

                        <div class="input" *ngIf="isFormCompleted">
                            {{beneficiaryData.i9WorkAuthPreparerInfo.preparerInfo.prepAddress[0].city}}</div>

                    </div>

                </div>

                <div class="clearfix"></div>

                <div class="container-child">

                    <div class="col-md-4 mb-15 float-left">
                        <label>Locality</label>
                        <input *ngIf="!isFormCompleted" [disabled]="isFormCompleted || isDateExpired" type="text"
                            class="form-control"
                            [(ngModel)]="beneficiaryData.i9WorkAuthPreparerInfo.preparerInfo.prepAddress[0].locality"
                            name="locality2">

                        <div class="input" *ngIf="isFormCompleted">
                            {{beneficiaryData.i9WorkAuthPreparerInfo.preparerInfo.prepAddress[0].locality}}</div>
                    </div>

                    <div class="col-md-4 mb-15  float-left">
                        <label class="required">Zip/Postal Code</label>

                        <input *ngIf="!isFormCompleted" [disabled]="isFormCompleted || isDateExpired" max="5"
                            type="text" class="form-control" required name="preparerZip" #preparerZip
                            [(ngModel)]="beneficiaryData.i9WorkAuthPreparerInfo.preparerInfo.prepAddress[0].postCode">

                        <div class="input" *ngIf="isFormCompleted">
                            {{beneficiaryData.i9WorkAuthPreparerInfo.preparerInfo.prepAddress[0].postCode}}</div>
                    </div>

                </div>
                <div class="clearfix"></div>

            </div>

            <div style="clear:both"></div>
            <div class="row">
                <div>
                    <p-checkbox name="agreedCheckbox" binary="true" inputId="agreedCheckbox"
                        [(ngModel)]="agreeCheckboxSelection" class="align-middle ml-4 mr-1"
                        [disabled]="isLockedAction || isFormCompleted || isDateExpired"></p-checkbox>
                </div>
                <div class="col-11" *ngIf="!isAdmin">
                    <label for="agreedCheckbox">I am aware that federal law provides for imprisonment and/or fines for
                        false statements, or the use of false documents, in connection with the completion of this form.
                        I attest, under penalty of perjury, that this information, including my selection of the box
                        attesting to my citizenship or immigration status, is true and correct</label>
                </div>
                <div *ngIf="!isAdmin"></div>
                <div class="col-8">
                    <p-button type="button" [disabled]="isLockedAction || isFormCompleted || isDateExpired"
                        (click)="showNotice()" label="Anti-discrimination notice" styleClass="p-button-link"
                        class="antidisc-sec"></p-button>
                </div>
            </div>
            <br />
            <button type="submit" class="btn btn-primary"
                [disabled]="isLockedAction || isFormCompleted || !agreeCheckboxSelection || isDateExpired">Submit</button>
            <br /> <br />
            <div class="col-md-12 pl-0" *ngIf="isEvalReport">
                <h2 class="mb-0">Evaluator Report</h2>
                <div>{{fileName}} <button pButton *ngIf="url !== ''" type="button" icon="pi pi-eye" pTooltip="View"
                        class="p-button-text p-button-rounded eval-doc" (click)="viewEvalDocument(url)"></button></div>
            </div>

        </div>
    </form>

</div>
<p-dialog header="Anti-discrimination notice" [(visible)]="displayNotice" [modal]="true" [responsive]="true"
    [style]="{width: '800px', minWidth: '200px', height:'250px'}" [minY]="70" [maximizable]="false"
    [baseZIndex]="60000">
    <div [innerHTML]="antiDiscriminatoryData | keepHtml"></div>
</p-dialog>