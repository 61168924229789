<nav class="im-navbar navbar-expand-xl navbar-light mb-3">
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      <!-- <li class="im-nav-item dropdown" routerLinkActive="active" *ngIf="isAdmin">
        <a joyrideStep="fourthStep" [stepContent]="petitionerFourthStepContent" class="im-nav-link dropdown-toggle"
          id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <img src="/assets/images/menu-dashboard.svg" width="17px" class="mr-1" alt=""> DASHBOARD
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" routerLink="dashboard" (click)="adminPetitioner(false)">Petitioner</a>
          <a class="dropdown-item" routerLink="dashboard" (click)="adminPetitioner(true)">Administrator</a>
        </div>
      </li> -->
      <!-- <li class="im-nav-item overflow-auto" *ngIf="!isAdmin && !i9_Admin" routerLinkActive="active">
        <a joyrideStep="fourthStep" [stepContent]="petitionerFourthStepContent" class="im-nav-link"
          routerLink="dashboard"><img src="/assets/images/menu-dashboard.svg" width="17px" class="mr-1" alt="">
          Dashboard</a>
      </li> -->
      <li class="im-nav-item overflow-auto" routerLinkActive="active">
        <a joyrideStep="fourthStep" [stepContent]="petitionerFourthStepContent" class="im-nav-link"
          routerLink="dashboard/i9-admin-dashboard"><img src="/assets/images/menu-dashboard.svg" width="17px" class="mr-1" alt="">
          Dashboard</a>
      </li>
      <!-- *ngIf="!i9_Admin" -->
      <li class="im-nav-item overflow-auto" *ngIf="!i9_Admin" routerLinkActive="active" >
        <a joyrideStep="secondStep" [stepContent]="petitionerSecondStepContent" class="im-nav-link"
          routerLink="companies/task-list"><img src="/assets/images/menu-petitions.png" width="20px" alt=""> Tasks</a>
      </li>


      <ng-template #nextButton><button class="btn btn-primary">Next</button></ng-template>

      <li class="im-nav-item overflow-auto" routerLinkActive="active">
        <a joyrideStep="firstStep" [stepContent]="petitionerFirstStepContent" class="im-nav-link"
          routerLink="beneficiaries/list"><img src="/assets/images/menu-beneficiary.png" width="10px" class="mr-1" alt="">
          <span *ngIf="!i9_Admin">Beneficiaries</span>
          <span *ngIf="i9_Admin">Employee Management</span>
        </a>
      </li>

      <ng-template #petitionerFirstStepContent>
        <app-guided-tour-steps [content]="videoTourContentObj[0]"></app-guided-tour-steps>
      </ng-template>
      <ng-template #petitionerSecondStepContent>
        <app-guided-tour-steps [content]="videoTourContentObj[1]"></app-guided-tour-steps>
      </ng-template>
      <ng-template #petitionerThirdStepContent>
        <app-guided-tour-steps [content]="videoTourContentObj[2]"></app-guided-tour-steps>
      </ng-template>
      <ng-template #petitionerFourthStepContent>
        <app-guided-tour-steps [content]="videoTourContentObj[3]"></app-guided-tour-steps>
      </ng-template>


      <li class="im-nav-item overflow-auto" *ngIf="!i9_Admin">
        <a joyrideStep="thirdStep" [stepContent]="petitionerThirdStepContent" class="im-nav-link"
          routerLink="attorneys/attorney-connections"><img src="/assets/images/menu-attorneys.png" width="20px"
            class="mr-1" alt=""> Lawfirms</a>
      </li>

      <!-- <li class="im-nav-item" routerLinkActive="active" *ngIf="i9_Admin">
        <a class="im-nav-link" routerLink="bulk-upload-list"><img src="/assets/images/menu-reports.png" width="20px" alt="">
          I-9 Management</a>
      </li> -->

      <li class="im-nav-item" routerLinkActive="active" *ngIf="i9_Admin">
        <a class="im-nav-link" routerLink="manage-i9-remediations"><img src="/assets/images/menu-reports.png" width="20px" alt="">
          I-9 Remediation</a>
      </li>
      <!-- Removed as a part of https://upstreamgs.atlassian.net/browse/IMGLT-645 -->
      <!-- <li class="im-nav-item dropdown" *ngIf="i9_Admin">
        <a class="im-nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          <img src="/assets/images/menu-data-setup.png" class="mr-1" width="18px" alt=""> Remote I-9
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" routerLink="i9-evaluators/initiations/1">I-9 Initiations</a> 
          <a class="dropdown-item" routerLink="i9-evaluators/initiations/2">Evaluation Request</a>
          <a class="dropdown-item" routerLink="i9-evaluators/initiations/3">Remote I-9 Verificataion</a>
        </div> 
      </li> -->

      <li class="im-nav-item dropdown" routerLinkActive="active" *ngIf="!i9_Admin">
        <a class="im-nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          <img src="../../../../assets/images/invoice/service_icon.svg" width='20' /> Services
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" routerLink="case-status">Case Status</a>
          <a class="dropdown-item" routerLink="billing">Billing & Payment</a>
        </div>
      </li>
      <li class="im-nav-item dropdown" routerLinkActive="active" *ngIf="!i9_Admin">
        <a class="im-nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          <img src="/assets/images/menu-data-setup.png" class="mr-1" width="18px" alt=""> Data Setup
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" routerLink="lca"  *ngIf="!i9_Admin">LCAs</a>
          <a class="dropdown-item" routerLink="template/template-list">Template Builder</a>
          <a class="dropdown-item" routerLink="template/email-list">Email Template Builder</a>
          <a class="dropdown-item" routerLink="rfe/template-list" *ngIf="!i9_Admin">Defined RFE Responses</a>
          <a class="dropdown-item" routerLink="receipts-notices/receipts-list" *ngIf="!i9_Admin">Receipt & Notice ETA</a>
          <a class="dropdown-item" routerLink="task-allocation" *ngIf="!i9_Admin">Steps Time Configurations</a>
          <a class="dropdown-item" *ngxPermissionsOnly="['JD_AND_PD']" routerLink="master-data/manage">Master Data</a>
          <a class="dropdown-item" routerLink="faq/manage-faq">FAQ Details</a>
          <a class="dropdown-item" *ngxPermissionsOnly="['VIEW_QUESTIONNAIRE']" routerLink="custom-questionaire">Custom
            Questionaire</a>
          <a class="dropdown-item" routerLink="visa-preference-settings">Visa Preference Settings</a>
        </div>
      </li>
      <!-- <li class="im-nav-item" routerLinkActive="active">
        <a class="im-nav-link" routerLink="companies/compliance"><i class="fa fa-file-text"></i> Compliance</a>
      </li> -->

      <!-- <li class="im-nav-item" routerLinkActive="active" *ngIf="!i9_Admin">
        <a class="im-nav-link" routerLink="resources"><img src="/assets/images/Resources_icon.png" width="20px" alt="">
          Resources</a>
      </li> -->

      <li class="im-nav-item dropdown">
        <a class="im-nav-link" routerLink="companies/compliance"><i class="fa fa-file-text"></i> Compliance</a>
        <!-- <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" routerLink="lca">LCA's</a>
          <a class="dropdown-item" routerLink="public-access">Public Access File</a> 
          <a class="dropdown-item" routerLink="private-access">Private Access File</a>
          <a class="dropdown-item" routerLink="fdns-qa">Questions and Answers (Q&A)</a>
          <a class="dropdown-item" routerLink="fdns-visit">Visits</a> -->
          <!-- <a class="dropdown-item" routerLink="reports">Reports</a> -->
        <!-- </div>  -->
      </li>

      <!-- <li class="im-nav-item dropdown" routerLinkActive="active" *ngIf="i9_Admin">
        <a class="im-nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          <img src="../../../../assets/images/invoice/service_icon.svg" width='20' /> Compliance
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          
          <a class="dropdown-item" routerLink="lca">LCA's</a>
        </div>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" routerLink="public-access">Public Access File</a>
        </div>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" routerLink="private-access">Private Access File</a>
        </div>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" routerLink="fdns-qa">Questions and Answers (Q&A)</a>
        </div>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" routerLink="fdns-visit">Visits</a>
        </div>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" routerLink="reports">Reports</a>
        </div>
      </li> -->

      <li class="im-nav-item dropdown" routerLinkActive="active" *ngIf="i9_Admin">
        <a class="im-nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          <img src="../../../../assets/images/invoice/service_icon.svg" width='20' /> Setup
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" routerLink="lca">LCAs</a>
          <!-- <a class="dropdown-item" routerLink="bulk-upload-document">Upload Bulk I-9 Records</a> -->
          <a class="dropdown-item" routerLink="i9-evaluators/evaluators">I-9 Evaluators</a>
        </div>
      </li>
      <!-- <li class="im-nav-item" routerLinkActive="active" *ngIf="i9_Admin">
        <a class="im-nav-link" routerLink="reports"><img src="/assets/images/menu-reports.png" width="20px" alt="">
          Reports</a>
      </li> -->
      <!-- <li class="im-nav-item" routerLinkActive="active" *ngIf="i9_Admin">
        <a class="im-nav-link" routerLink="past-documents-menu"><img src="/assets/images/menu-reports.png" width="20px" alt="">
          Load-Documents</a>
      </li> -->
      <!-- <li class="im-nav-item" routerLinkActive="active">
        <a class="im-nav-link" routerLink="audits"><i class="fa fa-file-text"></i>
          Audits</a>
      </li> -->

      <li class="im-nav-item dropdown" routerLinkActive="active" *ngIf="isAdmin || isAdminSelected || i9_Admin">
        <a class="im-nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          <i class="fa fa-home"></i> Admin
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" routerLink="companies/petitioner/company/details"
            routerLinkActive="active">Company</a>
          <a class="dropdown-item" routerLink="users" routerLinkActive="active" *ngIf="!i9_Admin">Users</a>
          <a class="dropdown-item" routerLink="roles" routerLinkActive="active" *ngIf="!i9_Admin">Roles</a>
          <a class="dropdown-item" routerLink="policies" routerLinkActive="active" *ngIf="!i9_Admin">Policies</a>
          <a class="dropdown-item" routerLink="bulk-upload" routerLinkActive="active" *ngIf="!i9_Admin">Bulk Upload Petition</a>
        </div>
      </li>      
      <li class="im-nav-item dropdown" routerLinkActive="active" *ngIf="!i9_Admin">
        <a class="im-nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false"><img src="/assets/images/Resources_icon.png" width="20px" alt="">
          Resources</a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" routerLink="forms-we-support">Forms/Visas We Support</a>
          <a class="dropdown-item" routerLink="resources">Help Videos</a>
        </div>
      </li>

      <li class="im-nav-item" routerLinkActive="active" *ngIf="isAdmin">
        <a class="im-nav-link" routerLink="reports"><img src="/assets/images/menu-reports.png" width="20px" alt="">
          Reports</a>
      </li>

      <!-- <li class="im-nav-item" routerLinkActive="active" *ngIf="i9_Admin">
        <a class="im-nav-link" routerLink="employeedetails"><img src="/assets/images/menu-reports.png" width="20px" alt="">
          Employee View</a>
      </li> -->

    </ul>
  </div>
  <div>
    <app-switch-app [appRoleData]="null"></app-switch-app>
  </div>

</nav>
