import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { map } from 'rxjs/operators';
import { FDNSQA } from 'src/app/data/models/fdns.model';
import { FdnsService } from 'src/app/services/fdns.service';
import { environment } from '../../../../environments/environment';
import * as errorUtils from "src/app/modules/utility/global-utils";
import { ReferenceLibraryService } from 'reference-library';
import { ReferenceLibrary } from 'src/app/data/models/referenceLibrary.model';
import * as moment from 'moment';
import { ConfirmationService } from 'primeng/api';
import { NgForm } from '@angular/forms';
import { acceptedFileTypes } from 'app-models';
@Component({
  selector: 'app-fdns-qa',
  templateUrl: './fdns-qa.component.html',
  styleUrls: ['./fdns-qa.component.scss']
})
export class FdnsQaComponent implements OnInit {
  @ViewChild('payStubForm', { static: false }) payStubForm: NgForm;
 // formChangesSubscription: Subscription;
  beneficiaryList: any[];
  showFdnsQADetailSection: boolean = false;
  fdnsQAList: FDNSQA[] = [];
  fdnsDocuments: any[];
  fdnsDocumentsList: any[];
  cols: any[];
  fdnsQaCols: any[];
  userType: string;
  selectedDocIndex: any;
  showFileuploadSection: boolean = false;
  uploadedFile: any;
  activeIndex: number = 0;
  companyId: string;
  petitionerId: string;
  beneficiaryId: any;
  accordianActivedIndex:any = -1;
  fdnsQADocumentsArr = new Map();
  payStubObj:any = {
    frequency:'',
    payPeriodEndDate:''
  };
  frequencyPayTypeList:ReferenceLibrary[];
  acceptedTypes = acceptedFileTypes;
  constructor(private fdnsService: FdnsService,  private toastr: ToastrService, private referenceLibraryService:ReferenceLibraryService,private confirmationService:ConfirmationService) { }

  ngOnInit(): void {
    debugger;
    this.userType = sessionStorage.getItem('userTypeRole');
    this.companyId = sessionStorage.getItem("companyId");
    if (this.userType === 'I9Admin') {
      debugger;
      this.petitionerId = sessionStorage.getItem("employeeId");
    }else{
      this.petitionerId = this.companyId;
    }
    if (this.userType === 'Beneficiary') {
      this.showFdnsQADetailSection = true;
      this.beneficiaryId = sessionStorage.getItem('beneficiaryId');
      this.getBeneficiaryQA(this.beneficiaryId);
      this.getFdnsDocuments()

    } else {
      this.showFdnsQADetailSection = false;
    }

    this.getBeneficiaryListByPetitioner();


    this.cols = [
      { field: 'beneficiaryName', header: 'Name' },
      { field: 'visaType', header: 'Visa Type' },
      { field: 'petitionType', header: 'Petition Type' },
      { field: 'companyName', header: 'Company' }
    ];

    this.fdnsQaCols = [
      { field: 'questionText', header: 'Questions' },
      { field: 'answerText', header: 'Answers' }

    ];
    this.referenceLibraryService.getReferenceData('PAYTYP').subscribe((response: ReferenceLibrary[]) => {
      this.frequencyPayTypeList = response;
    });
  }

  ngAfterViewInit() {
    this.translet();
  }

  translet() {
    setTimeout(() => {
      this.referenceLibraryService.transletConversion();
    }, 600);
  }
  getBeneficiaryQA(beneId) {
    this.fdnsService.getFdnsQAForBeneficiary(beneId)
      .subscribe((data) => {
        this.fdnsQAList = data ? data : [];
        // console.log("beneficiary QA 123", data);
     });
}

  getFdnsQAByPetitioner(beneId) {
    debugger;
    this.fdnsService.getFdnsQAForPetitioner(this.petitionerId, beneId)
      .subscribe((data) => {
       this.fdnsQAList = data ? data : [];
     });
}



  getBeneficiaryListByPetitioner() {
    if(!isNaN(parseInt(this.companyId))){
      this.fdnsService.getBeneficiaryListForPetitioner(this.companyId)
      .subscribe((data) => {
         this.beneficiaryList = data;
      });
    }
  }

  getFdnsDocuments() {
    if(!isNaN(parseInt(this.beneficiaryId))){
      this.fdnsService.getFdnsQADocuments(this.beneficiaryId)
        .subscribe((data) => {
        //  console.log("beneficiary documents", data);
        this.fdnsDocuments = data;
        if (this.fdnsDocuments.length > 0) {
          this.modifyDocumentsData();
        }
      });
    }

  }

  modifyDocumentsData() {

    this.fdnsQADocumentsArr.clear();
    for (let item of this.fdnsDocuments) {
      if (this.fdnsQADocumentsArr && this.fdnsQADocumentsArr.size > 0 && this.fdnsQADocumentsArr.get(item.fileRefName) != null) {
        let temp = this.fdnsQADocumentsArr.get(item.fileRefName);

        let singleDoc: any = { ...item };
        if(singleDoc.fileName && singleDoc.filePath){
          temp.filesArray.push(singleDoc);
        }
       this.fdnsQADocumentsArr.set(item.fileRefName, temp);
      } else {
        let temp: any = {};
        temp.filesArray = [];
        let singleDoc: any = { ...item };
        temp.fileRefName = item.fileRefName;
        if(singleDoc.fileName && singleDoc.filePath){
          temp.filesArray.push(singleDoc);
        }

        this.fdnsQADocumentsArr.set(item.fileRefName, temp);
      }
    }
   // console.log(" $$$$ Modified Data", Array.from(this.fdnsQADocumentsArr.values()));
    this.fdnsDocumentsList = Array.from(this.fdnsQADocumentsArr.values());

  }

  showFdnsQADetail(beneData) {
   // console.log("bene data", beneData);
    this.beneficiaryId = beneData.beneficiaryId;
    //this.getBeneficiaryQA(beneData.beneficiaryId);
    this.getFdnsQAByPetitioner(beneData.beneficiaryId)
    this.getFdnsDocuments();
    this.showFdnsQADetailSection = true;

  }
  onDocumentOpen(e) {
    this.selectedDocIndex = e.index;
  }
  viewDocument(item) {
    const docPath = item.substring(23);
    window.open(`${environment.docs}${docPath}`, "_blank");
  }
  uploadDocument() {
    this.showFileuploadSection = true;
    // this.payStubObj.frequency='';
    // this.payStubObj.payPeriodEndDate='';

  }
  onUpload(event, fileUpload) {

    for (let file of event.files) {
      this.uploadedFile = file;
      this.uploadedFile["fileName"] = file.name;
    }
    fileUpload.clear();
    let payload = new FormData();

    payload.append("file", this.uploadedFile, this.uploadedFile.name);
    this.payStubObj.payPeriodEndDate = this.payStubObj.payPeriodEndDate ? moment(this.payStubObj.payPeriodEndDate).format('YYYY-MM-DD'):null;
    this.fdnsService.saveFdnsQaDocuments(this.beneficiaryId, 146, "PAY", payload,this.payStubObj).subscribe((response: any) => {
      // console.log("response", response);
      if (response.status == 200) {
        this.showFileuploadSection = false;
        this.toastr.success(response.message, 'Success');
        this.payStubForm.reset();
        this.getFdnsDocuments();
        this.accordianActivedIndex = this.selectedDocIndex;


      }
    })

  }

  confirmDelete(docId) {
    this.confirmationService.confirm({
        message: 'Do you want to delete this record?',
        header: 'Delete',
        accept: () => {
          this.deleteDocument(docId);

        },
        reject: () => {

        }
    });
  }

  deleteDocument(docId){
    this.fdnsService.deleteFdnsQAPaystubDocument(docId).subscribe((response)=>{
    //console.log("delete response",response)
    if (response.status == 200) {
     this.toastr.success(response.message, 'Success');
     this.getFdnsDocuments();
     this.accordianActivedIndex = this.selectedDocIndex;
    }
    })
  }

  handleChange(e) {
    this.accordianActivedIndex = -1;
  }

  backToList() {
    this.showFdnsQADetailSection = false;
    this.activeIndex = 0;
  }
  getGlobalErrorMessages(error: string) {
    return errorUtils.errorMessages.get(error);
  }



}
