import { Component, Input, OnInit, ViewChild, ChangeDetectorRef, Output, Inject  } from '@angular/core';
import { PetitionerService } from '../../../../services/petitioner.service';
import { forkJoin } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import moment from 'moment';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
declare var $: any;

@Component({
  selector: 'select-upload-document-list',
  templateUrl: './select-upload-document-list.component.html',
  styleUrls: ['./select-upload-document-list.component.scss']
})
export class SelectUploadDocumentListComponent implements OnInit {

  getDocumentData: any;
  @Output() sendDocumentData: any;
  listBC:string = '';
  i9Section={name:'', code:'', group:'', isCheked: false};
  List_A;
  List_B;
  List_C; 
  List_B1;
  currentSection = ''; 

  arry = [];
  currentSelectedRadio:any;

  constructor(
    private petitinerService: PetitionerService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private cRef: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<SelectUploadDocumentListComponent>
  ) {
    this.getDocumentData = data;
  }

  ngOnInit(): void {
    this.currentSection = this.data.sectionName ? this.data.sectionName : null;
    this.listBC = this.data.listBC;
    this.loadAllDocumentLists();
  }


  loadAllDocumentLists() {
    let response1 = this.petitinerService.getdocumentList('I9IDEMPLAUTHDOC');
    let response2 = this.petitinerService.getdocumentList('I9IDDOC');
    let response3 = this.petitinerService.getdocumentList('I9EMPLAUTHDOC');
    let response4 = this.petitinerService.getdocumentList('I9IDDOCBLW18');
    forkJoin([response1, response2, response3, response4]).subscribe(([response1, response2, response3, response4]) => {
      this.List_A = response1;
      this.List_B = response2;
      this.List_C = response3;
      this.List_B1 = response4;
      
    });
  }

  section_Change(item) {
    if(this.getDocumentData.sectionName == 'Section1' || this.getDocumentData.sectionName == 'Section-1'){
      this.i9Section = {name: 'Section-1 I9 Form', code:'UPLOADI9FORMSEC1', group:'I9FORMSECTION',  isCheked: item.isCheked};
    }
    else if(this.getDocumentData.sectionName == 'Section2' || this.getDocumentData.sectionName == 'Section-2'){
      this.i9Section = {name: 'Section-2 I9 Form', code:'UPLOADI9FORMSEC2', group:'I9FORMSECTION',  isCheked: item.isCheked};
    }
    else if(this.getDocumentData.sectionName == 'Section3' || this.getDocumentData.sectionName == 'Section-3'){
      this.i9Section = {name: 'Section-3 I9 Form', code:'UPLOADI9FORMSEC3', group:'I9FORMSECTION',  isCheked: item.isCheked};
    }

    this.currentSelectedRadio = this.i9Section;
  }

  list_A_Change(item) {
    this.currentSelectedRadio = item;
  }
 
  list_B_Change(item) {
    this.currentSelectedRadio = item;
  }

  list_C_Change(item) {
    this.currentSelectedRadio = item;
  } 

  addDoc(){
    this.dialogRef.close({ data: this.currentSelectedRadio })

  }

  handleCancel(){
    this.dialogRef.close({ data: null })
  }
 
}
