import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ImagilityBaseResponse } from '../data/models/response.model';


@Injectable({
  providedIn: 'root'
})
export class FdnsService {

  constructor(private httpClient: HttpClient) { }



  getFdnsVisitById(fdnsVisitId: string) {
    return this.httpClient.get(`/compliance/fdnsVisit/${fdnsVisitId}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status) {
          return response.data;
        }
      })
    );
  }
  getFdnsBeneListByCompanyId(companyId: any) {
    return this.httpClient.get(`/fdns/visit/company/${companyId}/beneficiary`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status) {
          return response.data;
        }
      })
    );
  }

  getFdnsVisitList(payload) {
    return this.httpClient.post(`/compliance/fdnsVisit/`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status) {
          return response.data;
        }
      })
    );
  }



  getFdnsQAForBeneficiary(beneficiaryId) {
    return this.httpClient.get(`/fdns/beneficiary/${beneficiaryId}/qa/list`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status) {
          return response.data;
        }
      })
    );
  }

  getFdnsQAForPetitioner(petitionerId: string, beneficiaryId) {
    return this.httpClient.get(`/fdns/petitioner/${petitionerId}/beneficiary/${beneficiaryId}/qa/list`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status) {
          return response.data;
        }
      })
    );
  }

  getBeneficiaryListForPetitioner(companyId) {
    return this.httpClient.get(`/fdns/company/${companyId}/beneficiary/list`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status) {
          return response.data;
        }
      })
    );
  }

  getEmployeeI9Details(companyId, beneficiaryId){
    return this.httpClient.get(`/compliance/company/${companyId}/${beneficiaryId}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status) {
          return response.data;
        }
      })
    );
  }

  getFdnsQADocuments(beneficiaryId) {
    return this.httpClient.get(`/fdns/beneficiary/${beneficiaryId}/documents`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status) {
          return response.data;
        }
      })
    );
  }

  createFdnsVisit(payload) {
    return this.httpClient.post(`/compliance/fdnsVisit/create`, payload).pipe(
      map((response) => response)
    );

  }
  saveFdnsQaDocuments(beneficiaryId, fileCategoryId, fileRefCode, payload, payStubObj) {
    return this.httpClient.post(`/fdns/beneficiary/${beneficiaryId}/file/${fileCategoryId}/fileCode/${fileRefCode}/documents?frequency=${payStubObj.frequency}&payPeriodEndDate=${payStubObj.payPeriodEndDate}`, payload).pipe(
      map((response) => response)
    );

  }
  saveFdnsVisitDocuments(visitId, beneficiaryId, fileCategoryId, fileRefCode, payload) {
    return this.httpClient.post(`/fdns/fdnsVisit/${visitId}/beneficiary/${beneficiaryId}/file/${fileCategoryId}/fileCode/${fileRefCode}/document/upload`, payload).pipe(
      map((response) => response)
    );

  }

  deleteFdnsVisitDocuments(fdnsVisitId, documentId) {
    return this.httpClient.delete(`/fdns/fdnsVisit/documentId/${documentId}/visit/${fdnsVisitId}/delete`).pipe(
      map((response: ImagilityBaseResponse) => {
        return response;
      })
    );
  }
  deleteFdnsQAPaystubDocument(documentId) {
    return this.httpClient.delete(`/fdns/document/${documentId}`).pipe(
      map((response: ImagilityBaseResponse) => {
        return response;
      })
    );
  }

  deleteFdns(fdnsVisitId) {
    return this.httpClient.delete(`/compliance/fdnsVisit/${fdnsVisitId}`).pipe(
      map((response: ImagilityBaseResponse) => {
        return response;
      })
    );
  }

  getFaqListByCompanyId(companyId: string, payload: any) {
    return this.httpClient.post(`/company/${companyId}/faqInfo`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status) {
          return response;
        }
      })
    );
  }



  getFaqListBySearch(beneficiaryId: string, searchText: string) {
    return this.httpClient.get(`/beneficiary/${beneficiaryId}/faqInfoSearch?searchText= ${searchText}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status) {
          return response.data;
        }
      })
    );
  }

  

}


