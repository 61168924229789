import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { AppComponent } from './app.component';
import { CoreModule } from './modules/core/core.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { HeaderComponent } from './modules/shared/header/header.component';
import { FooterComponent } from './modules/shared/footer/footer.component';
import { ConfigMenuComponent } from './modules/shared/config-menu/config-menu.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { HttpService } from './modules/core/http/http.service';
import { AppRoutingModule } from './app-routing.module';
import {
  NgxUiLoaderModule, NgxUiLoaderConfig, SPINNER, POSITION,
  PB_DIRECTION, NgxUiLoaderHttpModule, NgxUiLoaderRouterModule
} from 'ngx-ui-loader';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { CompanyEffects } from './app-state/effects/company.effects';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatDialogModule } from '@angular/material/dialog';
import { MultiSelectModule } from 'primeng/multiselect';
// import { FullCalendarModule } from '@fullcalendar/angular'; // must go before plugins
// import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
// import {DragDropModule} from '@angular/cdk/drag-drop';
// import { DragDropModule } from "primeng/dragdrop";
// import {DragDropModule} from '@angular/cdk/drag-drop';
// import { DragDropModule } from "primeng/dragdrop";

import { SharedModule } from './modules/shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ErrorModule } from './modules/error/error.module';
import { SelectivePreloadingStrategyService } from './selective-preloading-strategy.service';
import { CommonModule } from '@angular/common';
import { LandingPetitionerModule } from './modules/petitioner-landing/petitioner-landing.module';
import { LandingBeneficiaryModule } from './modules/beneficiary-landing/beneficiary-landing.module';
import { LandingAttorneyModule } from './modules/attorney-landing/attorney-landing.module';
import { LandingPlatformAdminModule } from './modules/platform-admin-landing/platform-admin.module';
import { NgxMaskModule } from 'ngx-mask';
import { LandingModule } from './modules/landing/landing.module';
import { CalendarModule } from 'primeng/calendar';
// import { AuthGuardService } from './auth-guard.service';
// import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
// import 'froala-editor/js/plugins.pkgd.min.js';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { MomentModule } from 'angular2-moment';
import { LogoutComponent } from './logout/logout.component';
import { reducers } from './app.reducer';
import { JoyrideModule } from 'ngx-joyride';
import { SidebarModule } from 'primeng/sidebar';
import { ButtonModule } from 'primeng/button';
import { i9SectionOneEffects } from './app-state/effects/i9-section-one.effects';
import { SchedulingModule } from './modules/scheduling/scheduling.module';
import { InvitedCollectedLeadsComponent } from './modules/invited-collected-leads/invited-collected-leads.component';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { SnoozeComponent } from './snooze/snooze.component';
import { AddSnoozeComponent } from './add-snooze/add-snooze.component';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { BeneficiaryProfileLandingComponent } from './modules/beneficiary-questionnaire/beneficiary-profile-landing/beneficiary-profile-landing.component';
// import { DocumentChecklistComponent } from './modules/beneficiary-questionnaire/document-checklist/document-checklist.component';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { EditorModule } from '@progress/kendo-angular-editor';
import { EmailKendoeditorComponent } from './modules/marketing/common/components/email-kendoeditor/email-kendoeditor.component';
import { CustomKendoModule } from './modules/custom-kendo/custom-kendo.module';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { UploadsModule } from '@progress/kendo-angular-upload';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { AccordionModule } from 'primeng/accordion';
import { EncryptService } from './modules/core/http/http-encrypt.service';
import { TempJsonManupilateComponent } from './temp-json-manupilate/temp-json-manupilate.component';
import { ImagilityComposeEmailComponent } from './modules/email-client/unified-email-component/imagility-compose-email/imagility-compose-email.component';
import { CustomErrorToastModule } from 'custom-error-toast';




const ngxUiLoaderConfig: any = {
  bgsColor: 'red',
  bgsPosition: POSITION.centerCenter,
  bgsSize: 60,
  blur: 5,
  overlayColor: 'rgba(40, 40, 40, 0.8)',
  bgsType: SPINNER.rectangleBounce, // background spinner type
  fgsType: SPINNER.rectangleBounce, // foreground spinner type
  pbDirection: PB_DIRECTION.leftToRight, // progress bar direction
  pbThickness: 5, // progress bar thickness
  delay: 0, // 3500,
  minTime: 0
};


// FullCalendarModule.registerPlugins([ // register FullCalendar plugins
//   dayGridPlugin,
//   // interactionPlugin
// ]);


@NgModule({
  declarations: [	
    AppComponent,
    HeaderComponent,
    ConfigMenuComponent,
    FooterComponent,
    LogoutComponent,
    InvitedCollectedLeadsComponent,
    SnoozeComponent,
    AddSnoozeComponent,
    BeneficiaryProfileLandingComponent,
    // DocumentChecklistComponent,
    EmailKendoeditorComponent,
    TempJsonManupilateComponent,
    ImagilityComposeEmailComponent
      
   ],
  imports: [
    CommonModule,
    BrowserModule,
    CalendarModule,
    AccordionModule,
    // FullCalendarModule,
    SidebarModule,
    ButtonModule,
    CoreModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    SharedModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
      closeButton: true,
      // timeOut: 3000, // Notification will be visible for 3 seconds
      // extendedTimeOut: 10000, // Additional time after user hovers over the toast
    }),
    CustomErrorToastModule,
    AppRoutingModule,
    // Swati : Added below code to display chart in summary page. Need to change this storemodule.
    StoreModule.forRoot(reducers, {
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      },
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
    EffectsModule.forRoot([CompanyEffects, i9SectionOneEffects]),
    MatButtonModule,
    MatSidenavModule,
    MatListModule,
    MatDialogModule,
    MultiSelectModule,
    NgbModule,
    MatTableModule,
    MatSortModule,
    NgxPermissionsModule.forRoot(),
    ErrorModule,
    LandingPetitionerModule,
    LandingBeneficiaryModule,
    LandingAttorneyModule,
    LandingPlatformAdminModule,
    NgxMaskModule.forRoot(),
    LandingModule,
    // FroalaEditorModule.forRoot(), FroalaViewModule.forRoot(),
    MomentModule,
    NgIdleKeepaliveModule.forRoot(),
    JoyrideModule.forRoot(),
    NgIdleKeepaliveModule.forRoot(),
    NgxUiLoaderHttpModule.forRoot({ showForeground: true, delay: 0, minTime: 0 }),
    AutoCompleteModule,
    SchedulingModule,
    DynamicDialogModule,
    EditorModule,
    CustomKendoModule,
    DialogsModule,
    UploadsModule,
    InputsModule,
    ButtonsModule
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
  providers: [
    EncryptService,
    SelectivePreloadingStrategyService,
    {
      provide: HttpClient,
      useClass: HttpService
    },
    { provide: 'environment', useValue: environment }],
  bootstrap: [AppComponent]
})
export class AppModule { }
