<div class="container-fluid">
    <div class="row pl--1rem8  d-flex align-items-center">
        <div class="col-md-6 pb-2">
            <small class="link" (click)="initiateNewRemediation()">
                Home > I9 Remediations > Initiate Remediation
            </small> 
            <span class="normal-text">> Parsing Report List</span>
            <div class="page-header">
                <h2>Fix Errors</h2>
            </div>
        </div>
        <div class="col-md-6 pb-2">
            <div class="col-4 pull-right">
                <div class="count-sec">
                    Done correcting all errors
                    <button mat-flat-button color="default" (click)="startAnalysis()" [disabled]="!analysis" class="btn btn-analysis">
                        Start Analysis <mat-icon>keyboard_backspace</mat-icon>
                    </button>
                </div>
            </div>
            <div class="col-3 pull-right">
                <div class="count-sec">
                    <div class="count">{{errorEmp}} / {{totalEmp}}</div>
                    Employee data pending for correction
                </div>
            </div>
        </div>
    </div>
    <div class="bgWhite mat-elevation-z8 w100 mt-3 p-4">
        <div class="row p-0">
            <div class="col-md-6 pb-2">
                <div class="page-header">
                    <h2>Parsing Report</h2>
                    <div class="flex flex-wrap gap-3">
                        <form [formGroup]="radioForm">
                            <span class="view">View By: </span>
                            <mat-radio-group formControlName="selectedOption" aria-label="Select an option">
                              <mat-radio-button *ngFor="let option of options" [value]="option.value">
                                {{ option.label }}
                              </mat-radio-button>
                            </mat-radio-group>
                        </form>
                    </div>
                </div>
            </div>
            <div class="col-md-6 pb-2 d-flex justify-content-end align-items-center">
                <span class="total-count-sec mr-2">
                    Total Employees Parsed: <b>{{totalEmp}}</b>
                </span>
                <span class="parsed-count mr-2 pass">
                    {{validEmp}} Good
                </span>
                <span class="parsed-count fail">
                    {{errorEmp}} Errors
                </span>
            </div>
        </div>
        <div class="row p-0">
            <table class="im-card-no-border-table table im-card-table-noborder-spacing table-striped-simple" mat-table
                [dataSource]="dataSource" matSort>

                <ng-container matColumnDef="documentTitle">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Document Type</th>
                    <td mat-cell *matCellDef="let row"> {{row.documentTitle}} </td>
                </ng-container>

                <ng-container matColumnDef="documentCount">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Total Documents</th>
                    <td mat-cell *matCellDef="let row"> {{row.documentCount}} </td>
                </ng-container>

                <ng-container matColumnDef="totalErrors">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Total Errors </th>
                    <td mat-cell *matCellDef="let row"> {{row.totalErrors}} </td>
                </ng-container>

                <ng-container matColumnDef="correctedErrors">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Corrected Errors</th>
                    <td mat-cell *matCellDef="let row"> {{row.correctedErrors}} </td>
                </ng-container>

                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
                    <td class="status" mat-cell *matCellDef="let row">
                      <ng-container *ngIf="row.status.toLowerCase() === 'needs corrections'">
                        <mat-icon color="warn">warning</mat-icon>
                        {{ row.status }}
                      </ng-container>
                      <ng-container *ngIf="row.status.toLowerCase() === 'corrected'">
                        <mat-icon class="primary">description</mat-icon>
                        {{ row.status }}
                      </ng-container>
                      <ng-container *ngIf="row.status.toLowerCase() !== 'needs corrections' && row.status.toLowerCase() != 'corrected'">
                        {{ row.status }}
                      </ng-container>
                    </td>
                </ng-container>

                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Actions </th>
                    <td mat-cell *matCellDef="let row">
                        <div class="row">
                            <button class="btn action-btn" (click)="editRecord(row)"><mat-icon>edit</mat-icon></button>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                </tr>
            </table>
            <div class="col-12 pull-right">
                <mat-paginator #paginator
                    class="demo-paginator"
                    (page)="paginationEvent($event)"
                    [length]="totalElementCount"
                    [pageSize]="pageSize"
                    [disabled]="disabled"
                    [showFirstLastButtons]="showFirstLastButtons"
                    [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : [25, 50, 100]"
                    [hidePageSize]="hidePageSize"
                    [pageIndex]="pageIndex"
                    aria-label="Select page">
                </mat-paginator>
            </div>
            
        </div>
    </div>
    
</div>