<div class="container-fluid">
  <div class="row d-flex align-items-center">
    <div class="col-md-12 step-title">
      <h5 class="mb-0 common-bene-class-for-translation">Parent Information</h5>
    </div>
  </div>
  <p-divider></p-divider>
  <div>
    <div class="col-12 pl-0">
      <div class="row w-100">
        <h5 class="ml-3 common-bene-class-for-translation">Information about Parents</h5>
      </div>
      <div>
        <div class="row col-md-12 mt-2">
          <h6 class="common-bene-class-for-translation">Father's Information</h6>
        </div>
        <div class="row mt-2">
          <div class="col-4">
            <label class="label-cls required common-bene-class-for-translation">Title</label>
            <div class="readOnly-label">{{fatherDetails?.title}}</div>
            <small class="d-block text-danger" *ngIf="(!fatherDetails || !fatherDetails?.title) && verified">missing</small>
          </div>
          <div class="col-4">
            <label class="label-cls required common-bene-class-for-translation">First Name</label>
            <div class="readOnly-label">{{fatherDetails?.firstName}}</div>
            <small class="d-block text-danger" *ngIf="(!fatherDetails || !fatherDetails?.firstName) && verified">missing</small>
          </div>
          <div class="col-4">
            <label class="label-cls required common-bene-class-for-translation">Last Name</label>
            <div class="readOnly-label">{{fatherDetails?.lastName}}</div>
            <small class="d-block text-danger" *ngIf="(!fatherDetails || !fatherDetails?.lastName) && verified">missing</small>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-4 mt-2">
            <label class="label-cls common-bene-class-for-translation">Middle Name</label>
            <div class="readOnly-label">{{fatherDetails?.middleName}}</div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-4 mt-2">
            <label class="label-cls required common-bene-class-for-translation">Gender</label>
            <div class="readOnly-label">{{fatherDetails?.gender}}</div>
            <small class="d-block text-danger" *ngIf="(!fatherDetails || !fatherDetails?.gender) && verified">missing</small>
          </div>
          <div class="col-4 mt-2">
            <label class="label-cls required common-bene-class-for-translation">Date of Birth </label>
            <div class="readOnly-label">{{fatherDetails?.dob | VisaDateFormat}}</div>
            <small class="d-block text-danger" *ngIf="(!fatherDetails || !fatherDetails?.dob) && verified">missing</small>
          </div>
          <div class="col-4 mt-2">
            <label class="label-cls required common-bene-class-for-translation">Country of Birth</label>
            <div class="readOnly-label">{{fatherDetails?.birthCountry}}</div>
            <small class="d-block text-danger" *ngIf="(!fatherDetails || !fatherDetails?.birthCountry) && verified">missing</small>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-4 mt-2">
            <label class="label-cls required common-bene-class-for-translation">City of Birth</label>
            <div class="readOnly-label">{{fatherDetails?.birthCity}}</div>
            <small class="d-block text-danger" *ngIf="(!fatherDetails || !fatherDetails?.birthCity) && verified">missing</small>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-6 mt-2">
            <label class="label-cls common-bene-class-for-translation"> Current Country of Residence (if living)</label>
            <div class="readOnly-label">{{fatherDetails?.benCurrentAddress?.countryName}}</div>
          </div>
          <div class="col-6 mt-2">
            <label class="label-cls common-bene-class-for-translation">Current city of Residence (if living)</label>
            <div class="readOnly-label">{{fatherDetails?.benCurrentAddress?.city}}</div>
          </div>
        </div>
      </div>
    </div>
    <p-divider class="d-block"></p-divider>
    <div class="row col-md-12 mt-2">
      <h6 class="common-bene-class-for-translation">Mother's Information</h6>
    </div>
    <div class="row mt-2">
      <div class="col-4">
        <label class="label-cls required common-bene-class-for-translation">Title</label>
        <div class="readOnly-label">{{motherDetails?.title}}</div>
        <small class="d-block text-danger" *ngIf="(!motherDetails || !motherDetails?.title) && verified">missing</small>
      </div>
      <div class="col-4">
        <label class="label-cls required common-bene-class-for-translation">First Name</label>
        <div class="readOnly-label">{{motherDetails?.firstName}}</div>
        <small class="d-block text-danger" *ngIf="(!motherDetails || !motherDetails?.firstName) && verified">missing</small>
      </div>
      <div class="col-4">
        <label class="label-cls required common-bene-class-for-translation">Last Name</label>
        <div class="readOnly-label">{{motherDetails?.lastName}}</div>
        <small class="d-block text-danger" *ngIf="(!motherDetails || !motherDetails?.lastName) && verified">missing</small>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-4 mt-2">
        <label class="label-cls common-bene-class-for-translation">Middle Name</label>
        <div class="readOnly-label">{{motherDetails?.middleName}}</div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-4 mt-2">
        <label class="label-cls required common-bene-class-for-translation">Gender</label>
        <div class="readOnly-label">{{motherDetails?.gender}}</div>
        <small class="d-block text-danger" *ngIf="(!motherDetails || !motherDetails?.gender) && verified">missing</small>
      </div>
      <div class="col-4 mt-2">
        <label class="label-cls required common-bene-class-for-translation">Date of Birth </label>
        <div class="readOnly-label">{{motherDetails?.dob | VisaDateFormat}}</div>
        <small class="d-block text-danger" *ngIf="(!motherDetails || !motherDetails?.dob) && verified">missing</small>
      </div>
      <div class="col-4 mt-2">
        <label class="label-cls required common-bene-class-for-translation"> Country of Birth</label>
        <div class="readOnly-label">{{motherDetails?.birthCountry}}</div>
        <small class="d-block text-danger" *ngIf="(!motherDetails || !motherDetails?.birthCountry) && verified">missing</small>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-4 mt-2">
        <label class="label-cls required common-bene-class-for-translation">City of Birth</label>
        <div class="readOnly-label">{{motherDetails?.birthCity}}</div>
        <small class="d-block text-danger" *ngIf="(!motherDetails || !motherDetails?.birthCity) && verified">missing</small>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-6 mt-2">
        <label class="label-cls common-bene-class-for-translation"> Current Country of Residence(if living)</label>
        <div class="readOnly-label">{{motherDetails?.benCurrentAddress?.countryName}}</div>
      </div>
      <div class="col-6 mt-2">
        <label class="label-cls common-bene-class-for-translation">Current city of Residence(if living)</label>
        <div class="readOnly-label">{{motherDetails?.benCurrentAddress?.city}}</div>
      </div>
    </div>
  </div>
  <p-divider></p-divider>
  <div class="row pt-3">
    <div class="col-md-6">
      <button pButton [disabled]="disabledControls" type="button" title="Verify" class="save-btn"
        (click)="handleVerify()">
      <span class="common-bene-class-for-translation">VERIFY</span>
      </button>
    </div>
  </div>
</div>
