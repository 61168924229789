
<div class="modal-header p-0">
    <h4 mat-dialog-title>
    {{header}}
    </h4>
    <button mat-dialog-close class="close"><i class="fa fa-times-circle"></i></button>
</div>
    <mat-dialog-content>
<form #communicationForm="ngForm">
  <div class="form-group mt-2">
    <div class="row">
        <label class="text-secondary">Beneficiary Name</label>
    </div>
    <div class="row">
    <label class="text-secondary">{{benName}}</label>
</div>
      <label *ngIf="beneficiaryValue.type == 'email'" for="email">Communication Email ID*</label>
         <input placeholder="Enter"
         pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$"
         *ngIf="beneficiaryValue.type == 'email'" type="text" class="form-control" id="email"
               required
               [(ngModel)]="beneficiaryValue.email" name="email">
        <div *ngIf="beneficiaryValue.email" [hidden]="!beneficiaryValue.email.valid"
             class="invalid-message">
          Email is required
        </div>

        <label *ngIf="beneficiaryValue.type == 'eer'">Convert external benefiary to employee? </label>
    </div>
    
</form>
<hr class="mb-0"/>
</mat-dialog-content>

<mat-dialog-actions class="float-left">
        <button [disabled]="communicationForm.invalid" (click)="updateData()" mat-button class="im-btn im-btn-primary im-btn-md">Update</button>
  </mat-dialog-actions>