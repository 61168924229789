import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class VideoLinkService {

  constructor(private http: HttpClient) { }

  getVideoLink(id) {
    return this.http.get(`/i9admin/getVideoLink/${id}`)
  }

  saveVideoLink(payload) {
    return this.http.post(`/i9admin/saveVideoLink`, payload)
    
  }

  deleteVideoLink(id){
    return this.http.delete(`/i9admin/deleteVideoLink/${id}`)
  }
}

