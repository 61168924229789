<h5 mat-dialog-title>
    <div class="col-md-12 p-0 row delete-header">
        <div class="col-md-10 p-0 send-mail-title">Send Email</div>
        <div class="col-md-2 p-0 justify-content-end d-flex">
        <i (click)="onDismiss();" class="close-icon pi pi-times"></i>
        </div>
    </div>
</h5>
<div class="send-mail-container" mat-dialog-content>
    <label for="listOfEmails">Please enter the emails IDs of all the recipients, seperated by commas</label>
    <input type="text" name="listOfEmails" placeholder="Enter emails" [(ngModel)]="listOfEmails" />
</div>

<div mat-dialog-actions class="float-right">
  <button mat-button (click)="onDismiss()">I will do it Later</button>
  <button class="send-mail-button" pButton [disabled]="listOfEmails == ''" (click)="sendReportToEmail()">Send</button>
</div>

<div class="clearfix"></div>