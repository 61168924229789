<div class="container-fluid">
    <div class="row pl--1rem8  d-flex align-items-center">
        <div class="col-md-10 pb-2">
            <small class="link" (click)="backToDashboard()">
                Home > Employee</small>
            <div class="page-header">
                <h2>Manage I-9</h2>
                <p> View & manage new I-9 records and past I-9 record for record keeping</p>
            </div>
        </div>
    </div>

    <div class="bgWhite noMargin w100 bxShadow">
        <div class="row">
            <div class="col-md-9">
                <div class="mt-2 row tab-container">
                    <div class="col-4 tab-label tab-height activeI9Records">
                        <div class="lbl-text"> Bulk Upload I-9 Records({{ totalElementCount }})</div>
                    </div>
                </div>
            </div>

            <div class="col-md-3 mt-3 mb-3">
                <div class="input-group">
                    <input class="form-control py-2 rounded-pill" type="search" #pastDocTable
                        (input)="onSearchChange($event.target.value)" placeholder="Search Employee Name">
                    <span class="input-group-append">
                        <button class="btn rounded-pill border-0 ml-n4r" type="button">
                            <i class="fa fa-search"></i>
                        </button>
                    </span>
                </div>
            </div>
        </div>

        <div class="mat-elevation-z8 mt-2">
            <table class="im-card-no-border-table table im-card-table-noborder-spacing table-striped-simple" mat-table
                [dataSource]="dataSource" matSort>

                <ng-container matColumnDef="fname">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Employee Name </th>
                    <td mat-cell *matCellDef="let row"> {{row.beneficiaryEmployeeFName+" "+row.beneficiaryEmployeeLName}} </td>
                </ng-container>

                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Employee Email</th>
                    <td mat-cell *matCellDef="let row"> {{row.beneficiaryEmail}} </td>
                </ng-container>

                <ng-container matColumnDef="modeOfCreation">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Mode of Creation</th>
                    <td mat-cell *matCellDef="let row"> {{row.modeOfCreationName?row.modeOfCreationName:'Uploaded'}} </td>
                </ng-container>


                <ng-container matColumnDef="empName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Uploaded By</th>
                    <td mat-cell *matCellDef="let row"> {{row.comments ?  row.empName :'-'}} </td>
                </ng-container>

                <ng-container matColumnDef="startDate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Uploaded On</th>
                    <td mat-cell *matCellDef="let row">{{row.comments ?(row.startDate | date : 'MM/dd/yyyy') : '-'}}</td>
                </ng-container>

                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Action </th>
                    <td mat-cell *matCellDef="let row">
                        <div class="row" *ngIf="row.comments == 'BULKUPLOAD' || (row.comments != 'BULKUPLOAD' && (row.i9Status == 'COMPLTD' || row.meetingStatus == 'MEETSCHDL'))">
                            <mat-icon matTooltip="settings menu" class="mr-1 icn settingsBorder"
                                [matMenuTriggerFor]="menu">
                                <mat-icon>settings</mat-icon>
                                <mat-icon>keyboard_arrow_down</mat-icon>
                            </mat-icon>
                            <mat-menu #menu="matMenu">
                                <button mat-menu-item (click)="editPastDoc(row)">Manage I9</button>
                                <button mat-menu-item (click)="confirmPastDocDelete(row)">Delete</button>
                            </mat-menu>
                        </div>
                        <div class="row" *ngIf="row.comments != 'BULKUPLOAD' && row.i9Status != 'COMPLTD' && row.meetingStatus != 'MEETSCHDL'">
                            <mat-icon matTooltip="settings menu" class="mr-1 icn settingsBorder" [matMenuTriggerFor]="menu">
                              <mat-icon>settings</mat-icon>
                              <mat-icon>keyboard_arrow_down</mat-icon>
                            </mat-icon>
                            <mat-menu #menu="matMenu">
                              <!-- <button mat-menu-item (click)="openI9Profile(row)" *ngIf="beneficiaryStatus == 'ACTIVE'">I9 Profile</button> -->
                              <button mat-menu-item (click)="updateInfo(row,'email')" *ngIf="beneficiaryStatus == 'ACTIVE'">
                                Update Communication Email ID
                              </button>
                              <button mat-menu-item (click)="openInitiateI9(row)">Initiate I-9</button>   
                              <button mat-menu-item (click)="openUpdateEmployment(row, 'i9')">Generate I-9</button>
                              <button mat-menu-item (click)="openUpdateEmployment(row, 'start')"
                                *ngIf="beneficiaryStatus === 'ACTIVE' && userType == 'Beneficiary'">
                                <span >Update Employment</span>
                              </button>
                            </mat-menu>
                          </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                </tr>
            </table>
            <mat-paginator [pageSizeOptions]="[25, 50, 100]" [pageSize]="pageSize" [length]="totalElementCount"
                (page)="paginationEvent($event)"></mat-paginator>
        </div>
    </div>
    <p-confirmDialog header="Delete" key="deletePastDoc"></p-confirmDialog>
</div>

<p-confirmDialog [style]="{width: '50vw'}" rejectButtonStyleClass="p-button-text"></p-confirmDialog>
 

<!-- I9 FORM DETAILS --> 
<p-dialog header="Fill I-9 Profile" [(visible)]="I9Form" [modal]="true" [style]="{ width: '70vw' }" [draggable]="false" [resizable]="false">
 <form (ngSubmit)="i9FormSubmit(form)" #form="ngForm">
  <div class="row">
    <div class="col-md-4">
      <label class="label-cls required">First Name</label>
      <input type="text" placeholder="Enter First Name" [(ngModel)]="i9form.fname" name="fname" #fname="ngModel" required />
      <div class="invalid-message"  *ngIf="fname?.touched && fname?.errors?.required">Required field.</div>
    </div>
    <div class="col-md-4">
      <label class="label-cls">Middle Name</label>
      <input type="text" placeholder="Enter Middle Name" [(ngModel)]="i9form.mname" name="mname" #mname="ngModel" />
    </div>
    <div class="col-md-4">
      <label class="label-cls required">Last Name</label>
      <input type="text" placeholder="Enter Last Name" name="lname" [(ngModel)]="i9form.lname" #lname="ngModel" required />
      <div class="invalid-message"  *ngIf="lname?.touched && lname?.errors?.required">Required field.</div>
    </div>
   
  </div>

  <div class="row mt-3">    
    
    <div class="col-md-4">
      <label class="label-cls required">Date of Birth</label>
      <div classs="clearfix"></div>
      <p-calendar placeholder="mm/dd/yy"  name="dob" #dob="ngModel" [(ngModel)]="i9form.dob" [showIcon]="true" [monthNavigator]="true" [yearNavigator]="true" yearRange="1900:2100" appendTo="body" [style]="{'width':'100%'}"></p-calendar>
      <div class="invalid-message"  *ngIf="dob?.touched && dob?.errors?.required">Required field.</div>
    </div>    
    
    <div class="col-md-4">
      <label class="label-cls required">Mobile Number</label>
      <input type="text" placeholder="Enter Mobile Number" name="mobile" #mobile="ngModel" required [(ngModel)]="i9form.mobile"  />
      <div class="invalid-message"  *ngIf="mobile?.touched && mobile?.errors?.required">Required field.</div>
    </div>
    <div class="col-md-4">
      <label class="label-cls required">Email</label>
      <input type="text" placeholder="Enter Email" name="email" #email="ngModel" required [(ngModel)]="i9form.email"  />
      <div class="invalid-message"  *ngIf="email?.touched && email?.errors?.required">Required field.</div>
    </div> 
  </div>

  <div class="row mt-3">
    <div class="col-md-4">
      <label class="label-cls required">Address (Street Name & Number)</label>
      <input type="text" placeholder="Enter Address" name="address" #address="ngModel" required [(ngModel)]="i9form.address"  />
      <div class="invalid-message"  *ngIf="address?.touched && address?.errors?.required">Required field.</div>
    </div>

    <div class="col-md-4">
      <label class="label-cls">Apt Number</label>
      <input type="text" placeholder="Enter Apartment" name="apartment" #apartment="ngModel" [(ngModel)]="i9form.apartment"  />
    </div>

    <div class="col-md-4">
      <label class="label-cls required">City/Town</label>
      <input type="text" placeholder="Enter City" name="city" #city="ngModel" required [(ngModel)]="i9form.city"  />
      <div class="invalid-message"  *ngIf="city?.touched && city?.errors?.required">Required field.</div>
    </div>
  </div>

  <div class="row mt-3">
    
    <div class="col-md-4">
      <label class="label-cls required">State</label>
      <!-- <p-dropdown 
      inputId="states"
      [options]="stateList" 
      placeholder="Select State" 
      optionLabel="stateProvinceName" 
      optionValue="stateProvinceCode"
      required
      (onChange)="profileSateChange($event, state)"
      (click)="profileSateChange($event, state)"
      [(ngModel)]="i9form.stateProvinceName" 
      name="state"
      #state="ngModel"
      >
    </p-dropdown> -->


  <p-dropdown #dd
  [options]="stateList"
  [(ngModel)]="i9form.stateProvinceCode"
  [style]="{'width':'100%'}"
  scrollHeight="200px"
  optionLabel="stateProvinceName" 
  optionValue="stateProvinceCode"
  filter="true"
  filterBy="stateProvinceName,stateProvinceCode"
  resetFilterOnHide="true"
  (onChange)="profileSateChange($event, state)"
  (keyup.enter)="profileSateChange($event, state)"
  name="state"
  #state="ngModel">
  
    <ng-template let-state pTemplate="item">
      <div class="item-drop1">
        <mat-list-item>
            <span>{{state.stateProvinceName}}</span>
        </mat-list-item>
      </div>
    </ng-template>    
</p-dropdown>


    <div class="invalid-message"  *ngIf="state?.touched && state?.errors?.required">Required field.</div>
    </div>   
    

    <div class="col-md-4">
      <label class="label-cls required">Zip Code</label>
      <input type="text" placeholder="Enter ZipCode" name="zipcode" #zipcode="ngModel" required [(ngModel)]="i9form.zipcode"  />
      <div class="invalid-message"  *ngIf="zipcode?.touched && zipcode?.errors?.required">Required field.</div>
    </div>

    <div class="col-md-4">
      <label class="label-cls">U.S Social Security Number</label>
      <input type="text" placeholder="Enter SSN"  class="form-control" name="ssn" [(ngModel)]="i9form.ssn" #ssn="ngModel" />
    </div> 

  </div>

  <!-- <div class="row mt-3">

    <div class="col-md-4">
      <label class="label-cls required">Employment Start Date</label>
      <input type="date"  placeholder="Enter Employment Start Date"  class="form-control" name="empStartDate" [(ngModel)]="i9form.empStartDate"  required #empStartDate="ngModel" />
      <div class="invalid-message"  *ngIf="empStartDate?.touched && empStartDate?.errors?.required">Required field.</div>
    </div> 

    <div class="col-md-4">
      <label class="label-cls">Employment End Date</label>
      <input type="date"  placeholder="Enter Employment End Date"  class="form-control" name="empEndDate" [(ngModel)]="i9form.empEndDate" #empEndDate="ngModel" />
    </div> 

  </div> -->

  <div class="row mt-3 mb-3">
    <div class="col-md-12">
      <button class="btn btn-primary float-right" [disabled]="form.invalid">Submit</button>
    </div>
  </div>
</form>

</p-dialog>