import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ImagilityBaseResponse } from "app-models";
import { map } from "rxjs/operators";
import * as CryptoJS from 'crypto-js';

@Injectable({
    providedIn: 'root'
})
export class EditCompleteEducationDetailService {
  encriptedData: any;

    constructor(private http: HttpClient) {

    }

    getBeneEducationInformation(superTaskId, subTaskId, isUsedForBenEdit: boolean=false) {
      return this.http.get(`/superTask/${superTaskId}/subTask/${subTaskId}/gc/i140Case/educationDetails?isUsedForBenEdit=${isUsedForBenEdit}`);
    }

    getBeneEducationInformationPerm(gcId: number, isUsedForBenEdit: boolean=false) {
      return this.http.get(`/gc/perm/${gcId}/educationDetails?isUsedForBenEdit=${isUsedForBenEdit}`);
    }

    getTrainingInformation(superTaskId, subTaskId, isUsedForBenEdit: boolean=false) {
      return this.http.get(`/superTask/${superTaskId}/subTask/${subTaskId}/gc/i140Case/education/training?isUsedForBenEdit=${isUsedForBenEdit}`);
    }

    getLicensesInformation(superTaskId, subTaskId, isUsedForBenEdit: boolean=false) {
      return this.http.get(`/superTask/${superTaskId}/subTask/${subTaskId}/gc/i140Case/education/license?isUsedForBenEdit=${isUsedForBenEdit}`);
    }

    savegcI140DegreeDetails(gcI140Id: number, payload) {
      return this.http.post(`/gcCase/saveEducation/${gcI140Id}?isGcI140Case=true&isGcPermCase=false`, payload);
    }

    savePermDegreeDetails(permId: number, payload) {
      return this.http.post(`/gcCase/saveEducation/${permId}?isGcI140Case=false&isGcPermCase=true`, payload);
    }

    savegcI140TrainingDetails(gcI140Id: number, payload, type: string) {
      const encriptedData = this.toEncrypt(type);
      return this.http.post(`/gcCase/saveTrainingOrLicense/${gcI140Id}/${encriptedData}?isGcI140Case=true&isGcPermCase=false`, payload);
    }

    savegcI140LicenseDetails(gcI140Id: number, payload, type: string) {
      const encriptedData = this.toEncrypt(type);
      return this.http.post(`/gcCase/saveTrainingOrLicense/${gcI140Id}/${encriptedData}?isGcI140Case=true&isGcPermCase=false`, payload);
    }

    savePermTrainingDetails(permId: number, payload, type: string) {
      const encriptedData = this.toEncrypt(type);
      return this.http.post(`/gcCase/saveTrainingOrLicense/${permId}/${encriptedData}?isGcI140Case=false&isGcPermCase=true`, payload);
    }

    savePermLicenseDetails(permId: number, payload, type: string) {
      const encriptedData = this.toEncrypt(type);
      return this.http.post(`/gcCase/saveTrainingOrLicense/${permId}/${encriptedData}?isGcI140Case=false&isGcPermCase=true`, payload);
    }

    /** New */
    deletegcI140DegreeDetails(superTaskId, subTaskId, educationId) {
      return this.http.delete(`/superTask/${superTaskId}/subTask/${subTaskId}/gc/i140Case/education/${educationId}/delete`).pipe(
        map((response: ImagilityBaseResponse) => {
          return response;
        })
      );
    }

    /** New */
    deletei140TrainingDetails(superTaskId, subTaskId, trainingId) {
      return this.http.delete(`/superTask/${superTaskId}/subTask/${subTaskId}/gc/i140Case/education/training/${trainingId}/delete`).pipe(
        map((response: ImagilityBaseResponse) => {
          return response;
        })
      );
    }

    /** New */
    deleteLicensesInformation(superTaskId, subTaskId, licenseId) {
      return this.http.delete(`/superTask/${superTaskId}/subTask/${subTaskId}/gc/i140Case/education/license/${licenseId}/delete`).pipe(
        map((response: ImagilityBaseResponse) => {
          return response;
        })
      );
    }
       toEncrypt(_data) {
          var pharase = 'c63a423527b24f65'
          var data = _data;
          var key  = CryptoJS.enc.Latin1.parse(pharase);
          var iv   = CryptoJS.enc.Latin1.parse(pharase);  
          var encrypted = CryptoJS.AES.encrypt(
            data,
            key,
            {iv:iv,mode:CryptoJS.mode.CBC,
              padding:CryptoJS.pad.ZeroPadding
          }).toString();
      
          var e64 = CryptoJS.enc.Base64.parse(encrypted);
          var eHex = e64.toString(CryptoJS.enc.Hex);
      
         return this.encriptedData = eHex
        }
}