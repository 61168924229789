<div class="container-update-employment mt-5">
  <div class="container-update-employment bg-grey">
    <div class="row">
    <p class="col-11 header-title mb-4">
      <span *ngIf="userType == 'Beneficiary'">Update Employment</span>
      <span *ngIf="userType == 'I9Admin'">OnBoarding Employee</span> 
    </p>
    
    <div class="col pointer"><span class="pi pi-times" (click)="goBack()"></span></div>
  </div>

    <div class="container-update-employment bg-white py-3">
      <div class="row py-3 border-bottom-dashed" *ngIf="userType == 'Beneficiary'">
        <div class="col-3">
          <p-radioButton name="groupname" class="updt-emp-rb" [value]="true" label="Start Employment"
            [(ngModel)]="isOnboarding">
          </p-radioButton>
        </div>
        <div class="col-3">
          <p-radioButton name="groupname" disabled class="updt-emp-rb" [value]="false" label="End Employment"
            [(ngModel)]="isOnboarding">
          </p-radioButton>
        </div>
      </div>
      <div class="row" *ngIf="userType == 'Beneficiary'">
        <div class="col-md-12">          
          <div class="tab">            
          <p-tabView [(activeIndex)]="index" class="summaryTask-tab"  (onChange)="handleChange($event)">
            <p-tabPanel>
              <ng-template pTemplate="header">
                <div class="row">
                  <span class="header-icon border rounded-circle p-3">
                    <img style="height: 20px;width:20px;" src='{{"assets/images/Petition_icon.png" }}'>
                  </span>
                  <hr  style="width: 25%;position: absolute;top: 3rem;right:0" />
                </div>
                <label>Employment Details</label>
              </ng-template>

            </p-tabPanel>
            <p-tabPanel [disabled]="!i9FormId">
              <ng-template pTemplate="header">
                <div class="row">
                  <hr  style="width: 34%;position: absolute;top: 3rem;" />
                  <span class="header-icon border rounded-circle p-3">
                    <img  style="height: 20px;width:20px;" src='{{ "assets/images/RFE_icon.png" }}'>
                  </span>
                </div>
                <label>I-9 Details</label>
              </ng-template>
            </p-tabPanel>
          </p-tabView>
        </div>
        </div>
      </div>
      <router-outlet></router-outlet>
      
      <!-- <app-onboard-employment [employmentDetails]="employmentDetails" (formSubmitted)="onFormSubmitted()">
      </app-onboard-employment> -->
    </div>
  </div>
</div>
