<div class="row m-4 pb-4">
    <div class="col-md-12">
        <div>
            <div>
                <h5 class="rowHeading py-3">I-9 Form Details <span class="subHeading">( Update or Enter data fetched
                        from your profile )</span>
                    <!-- <p-button class="btn-accordion" title="Edit" (onClick)="Edit()"><i class="fa fa-pencil">Edit</i></p-button>-->
                </h5>
            </div>
        </div>
        <div>
            <h6 class="rowHeading pb-3">Select <span>One</span> of the below options</h6>
        </div>
        <form [formGroup]="i9WorkAuthPreparerForm">
            <div class="row ">

                <div class="col-md-5 pl-0">
                    <div style="width: 100%;">
                        <mat-radio-group required (change)="handleToggle('sectionSwitch')" aria-label="Select an option"
                            name="switchSection" formControlName="workAuthorizationType">
                            <mat-radio-button [disabled]="!isEdit" (change)="resetParent($event);" class="d-block mt-3"
                                *ngFor="let option of workAuthTypes" [value]="option.code">
                                {{ option.desc }}
                            </mat-radio-button>
                        </mat-radio-group>

                    </div>
                </div>

                <div class="col-md-7" style="border-left:1px dashed #e4e4e4">
                    <div class="row ml-3" *ngIf="showSection == 'CITZN' ">
                        <div class="w-100">
                            <b class="mb-3 d-inline-block">Select documents related to your United States
                                Citizenship</b>
                            <!-- <div class="row mb-2">
                                <div class="col-md-12 pl-0">
                                    <label class="mb-0 d-block">Expiration Date</label>
                                    <p-calendar [disabled]="!isEdit" formControlName="expirationDate" [showIcon]="true"
                                        dateFormat="dd-mm-yy" inputId="expiryPick1" [monthNavigator]="true"
                                        [yearNavigator]="true" yearRange="1900:2100"></p-calendar>
                                </div>
                            </div> -->
                          

                          
                        </div>
                    </div>

                    <div class="row ml-3" *ngIf="showSection == 'NONCITZNNATIONAL' ">
                        <div class="w-100">
                            <b class="mb-3 d-inline-block">Select documents related to non-citizen national</b>
                            <!-- <div class="row mb-2">
                                <div class="col-md-12 pl-0">
                                    <label class="mb-0 d-block">Expiration Date</label>
                                    <p-calendar [disabled]="!isEdit" formControlName="expirationDate" [showIcon]="true"
                                        dateFormat="dd-mm-yy" inputId="expiryPick1" [monthNavigator]="true"
                                        [yearNavigator]="true" yearRange="1900:2100"></p-calendar>
                                </div>
                            </div> -->

                         
                        </div>
                    </div>

                    <!-- <div class="col-md-12" *ngIf="showSection == 'PERMRESIDENT' ">
                        <b class="mb-3 d-inline-block">Details related to your permanent residentship</b>
                        <div class="row pl-0 pr-0 mb-1">
                            <div class="col-12 px-0">
                                <mat-radio-group required aria-label="Select an option" name="permResident"
                                    formControlName="workAuthorizationProof">
                                    <mat-radio-button (change)="resetValue($event);" [disabled]="!isEdit"
                                        [disabled]="!isEdit" (click)="$event.stopPropagation()" class="d-block mt-3"
                                        *ngFor="let option of permDocumentTypes" [value]="option.code">
                                        <div *ngIf="option.code == 'ALIENREGNUM'">
                                            <div class="d-block">
                                                <label class="mb-0 d-block">{{option.desc}}</label>
                                                <input
                                                    *ngIf="i9WorkAuthPreparerForm.get('workAuthorizationProof').value == 'ALIENREGNUM'"
                                                    type="text" placeholder="Enter Registration Number"
                                                    formControlName="idNumber">
                                                <input
                                                    *ngIf="i9WorkAuthPreparerForm.get('workAuthorizationProof').value != 'ALIENREGNUM'"
                                                    type="text" placeholder="Enter Registration Number"
                                                    formControlName="newVal">
                                                <span class="invalid-message"
                                                    *ngIf="i9WorkAuthPreparerForm.get('workAuthorizationProof').value == 'ALIENREGNUM' && i9WorkAuthPreparerForm.get('idNumber').errors?.required">
                                                    {{ getGlobalErrorMessages("REQUIRED") }}
                                                </span>
                                            </div>
                                        </div>

                                        <div *ngIf="option.code == 'USCISNUM'">
                                            <div class="d-block">
                                                <label class="mb-0 d-block">{{option.desc}}</label>
                                                <input
                                                    *ngIf="i9WorkAuthPreparerForm.get('workAuthorizationProof').value == 'USCISNUM'"
                                                    type="text" placeholder="Enter USCIS Number"
                                                    formControlName="idNumber">
                                                <input
                                                    *ngIf="i9WorkAuthPreparerForm.get('workAuthorizationProof').value != 'USCISNUM'"
                                                    type="text" placeholder="Enter USCIS Number"
                                                    formControlName="newVal">
                                                <span class="invalid-message"
                                                    *ngIf="i9WorkAuthPreparerForm.get('workAuthorizationProof').value == 'USCISNUM' && i9WorkAuthPreparerForm.get('idNumber').errors?.required">
                                                    {{ getGlobalErrorMessages("REQUIRED") }}
                                                </span>
                                            </div>
                                        </div>

                                        <div class="clearfix"></div>
                                    </mat-radio-button>

                                    <div class="col-md-6 mt-2 mb-4">
                                        <div class="d-block" style="margin-left: 15px;">
                                            <div><label style="color:rgba(0,0,0,.38); margin: 0px;">Country</label>
                                            </div>
                                            <p-dropdown [options]="listCountry" [style]="{'width':'201px'}"
                                                name="permanentResidentCountry" formControlName="countryCode"
                                                optionLabel="countryName" optionValue="countryCode"
                                                placeholder="Country">
                                            </p-dropdown>

                                        </div>
                                    </div>
                                </mat-radio-group>
                            </div>
                            <div class="col-12 px-0">
                                <span class="invalid-message"
                                    *ngIf="i9WorkAuthPreparerForm.get('workAuthorizationProof').errors?.required">
                                    {{ getGlobalErrorMessages("REQUIRED") }}
                                </span>
                            </div>
                           
                        </div>
                    </div> -->
                    <div class="container-child row" *ngIf="showSection == 'PERMRESIDENT'">
                        <div class="col-md-6 mb-15 mt-3">
                            <label *ngIf="i9WorkAuthPreparerInfo?.workAuthorizationProof == 'USCISNUM'">USCIS Number</label>
                            <label *ngIf="i9WorkAuthPreparerInfo?.workAuthorizationProof == 'ALIENREGNUM'">A Number</label>
                            <div class="input">{{i9WorkAuthPreparerInfo?.idNumber}}</div>
                        </div>
                        <div class="col-md-6 mb-15 mt-3">
                            <label>Country</label>
                            <div class="input">{{i9WorkAuthPreparerInfo?.countryCode}}</div>
                        </div>
                    </div>
                    <div class="container-child row" *ngIf="showSection == 'WORKAUTHALIEN'">
                        <div class="col-md-4 mb-15 mt-3" *ngIf="i9WorkAuthPreparerInfo?.workAuthorizationProof == 'I94NUM'">
                            <label>I94 Number</label>
                            <div class="input">{{i9WorkAuthPreparerInfo?.idNumber}}</div>
                        </div>
                        <div class="col-md-4 mb-15 mt-3" *ngIf="i9WorkAuthPreparerInfo?.workAuthorizationProof == 'USCISNUM'">
                            <label>USCIS Number</label>
                            <div class="input">{{i9WorkAuthPreparerInfo?.idNumber}}</div>
                        </div>
                        <div class="col-md-4 mb-15 mt-3" *ngIf="i9WorkAuthPreparerInfo?.workAuthorizationProof == 'FORGNPASSPORTNUM'">
                            <label>Foreign Passport Number</label>
                            <div class="input">{{i9WorkAuthPreparerInfo?.idNumber}}</div>
                        </div>
                        <div class="col-md-4 mb-15 mt-3" *ngIf="i9WorkAuthPreparerInfo?.workAuthorizationProof == 'FORGNPASSPORTNUM'">
                            <label>Country</label>
                            <div class="input">{{i9WorkAuthPreparerInfo?.countryCode}}</div>
                        </div>
                        <div class="col-md-4 mb-15 mt-3" *ngIf="i9WorkAuthPreparerInfo?.expirationDate">
                            <label>Expiration Date</label>
                            <div class="input">{{i9WorkAuthPreparerInfo?.expirationDate}}</div>
                        </div>
                    </div>
                    <!-- <div class="col-md-12" *ngIf="showSection == 'WORKAUTHALIEN' ">
                        <b class="mb-3 d-inline-block">Details related to authorisation to work until expiration</b>
                        <div class="row pl-0 pr-0 mb-1">
                            <div class="col-md-12 pl-0">
                                <label>Expiration Date</label>
                            </div>
                            <div class="row">
                                <p-calendar [disabled]="!isEdit" formControlName="expirationDate"
                                    [showIcon]="true" dateFormat="dd-mm-yy" inputId="expiryPick1"
                                    [monthNavigator]="true" [yearNavigator]="true" yearRange="1900:2100"></p-calendar> 
                            </div>
                        </div>
                        <div class="row pl-0 pr-0 mb-1">
                            <div class="col-12 px-0">
                                <mat-radio-group required class="row" aria-label="Select an option" name="alientSection"
                                    formControlName="workAuthorizationProof">

                                    <mat-radio-button [disabled]="!isEdit" (change)="resetValue($event)"
                                        (click)="$event.stopPropagation()"
                                        class="mt-3 col-md-12 px-0 line-height-inherit"
                                        *ngFor="let option of alienDocumentTypes" [value]="option.code"
                                        [ngClass]="{'uscis-number-outer': option.code == 'USCISNUM'}">
                                        <div *ngIf="option.code == 'ALIENREGNUM'">
                                            <div class="d-block">
                                                <label class="mb-0 d-block">{{option.desc}}</label>
                                                <input
                                                    *ngIf="i9WorkAuthPreparerForm.get('workAuthorizationProof').value == 'ALIENREGNUM'"
                                                    type="text" placeholder="Enter Registration Number"
                                                    formControlName="idNumber">
                                                <input
                                                    *ngIf="i9WorkAuthPreparerForm.get('workAuthorizationProof').value != 'ALIENREGNUM'"
                                                    type="text" placeholder="Enter Registration Number"
                                                    formControlName="newVal">
                                                <span class="invalid-message"
                                                    *ngIf="i9WorkAuthPreparerForm.get('workAuthorizationProof').value == 'ALIENREGNUM' && i9WorkAuthPreparerForm.get('idNumber').errors?.required">
                                                    {{ getGlobalErrorMessages("REQUIRED") }}
                                                </span>
                                            </div>
                                        </div>

                                        <div *ngIf="option.code == 'USCISNUM'" style="width: 200px;">
                                            <div class="d-block">
                                                <label class="mb-0 d-block">{{option.desc}}</label>
                                                <input
                                                    *ngIf="i9WorkAuthPreparerForm.get('workAuthorizationProof').value == 'USCISNUM'"
                                                    type="text" placeholder="Enter USCIS Number"
                                                    formControlName="idNumber">
                                                <input
                                                    *ngIf="i9WorkAuthPreparerForm.get('workAuthorizationProof').value != 'USCISNUM'"
                                                    type="text" placeholder="Enter USCIS Number"
                                                    formControlName="newVal">
                                                <span class="invalid-message"
                                                    *ngIf="i9WorkAuthPreparerForm.get('workAuthorizationProof').value == 'USCISNUM' && i9WorkAuthPreparerForm.get('idNumber').errors?.required">
                                                    {{ getGlobalErrorMessages("REQUIRED") }}
                                                </span>
                                            </div>
                                        </div>

                                        <div *ngIf="option.code == 'I94NUM'">

                                            <div class="d-block float-left" style="width: 40%;">
                                                <label class="mb-0 d-block">{{option.desc}}</label>
                                                <input
                                                    *ngIf="i9WorkAuthPreparerForm.get('workAuthorizationProof').value == 'I94NUM'"
                                                    type="text" placeholder="Enter Admission Number"
                                                    formControlName="idNumber">
                                                <input
                                                    *ngIf="i9WorkAuthPreparerForm.get('workAuthorizationProof').value != 'I94NUM'"
                                                    type="text" placeholder="Enter Admission Number"
                                                    formControlName="newVal">
                                                <span class="invalid-message"
                                                    *ngIf="i9WorkAuthPreparerForm.get('workAuthorizationProof').value == 'I94NUM' && i9WorkAuthPreparerForm.get('idNumber').errors?.required">
                                                    {{ getGlobalErrorMessages("REQUIRED") }}
                                                </span>
                                            </div>

                                            <div class="d-block float-left ml-2" style="width:25%; margin-left: 15px!important; margin-right: -4px;">
                                                <label class="mb-0 d-block" style="padding-bottom:5px;">Country</label>
                                                <p-dropdown [options]="listCountry" [style]="{'width':'100%'}"
                                                    name="passportIssueCountry" formControlName="countryCode"
                                                    optionLabel="countryName" optionValue="countryCode"
                                                    placeholder="Country">
                                                </p-dropdown>
                                            </div>

                                            <div class="d-block float-left ml-2" style="width: 30%;">
                                                <label class="mb-0 d-block">Foreign Passport</label>
                                                <input type="text" placeholder="Foreign Passport"
                                                    formControlName="forgnPassportIdNumber"
                                                    name="forgnPassportIdNumber" />
                                            </div>

                                        </div>

                                        <div *ngIf="option.code == 'FORGNPASSPORTNUM'" class="row">
                                            <div style="width: 100%;"><label>{{option.desc}}</label></div>
                                            <div *ngIf="i9WorkAuthPreparerForm.get('workAuthorizationProof').value == 'FORGNPASSPORTNUM'"
                                                class="row">
                                                <div class="col-md-6 px-0 pr-2">
                                                    <p-dropdown [options]="listCountry" [style]="{'width':'100%'}"
                                                        name="passportIssueCountry" formControlName="countryCode"
                                                        optionLabel="countryName" optionValue="countryCode"
                                                        placeholder="Country">
                                                    </p-dropdown>

                                                    <span class="invalid-message"
                                                        *ngIf="i9WorkAuthPreparerForm.get('countryCode').errors?.required">
                                                        {{ getGlobalErrorMessages("REQUIRED") }}
                                                    </span>
                                                </div>
                                                <div class="col-md-6 px-0" >
                                                    <input type="text" placeholder="Enter Passport Number"
                                                        formControlName="idNumber">
                                                    <span class="invalid-message"
                                                        *ngIf=" i9WorkAuthPreparerForm.get('idNumber').errors?.required">
                                                        {{ getGlobalErrorMessages("REQUIRED") }}
                                                    </span>
                                                </div>
                                            </div>



                                            <div class="clearfix"></div>


                                            <div class="row"
                                                *ngIf="i9WorkAuthPreparerForm.get('workAuthorizationProof').value != 'FORGNPASSPORTNUM'">
                                                <div class="col-md-6 px-0 pr-2">
                                                    <p-dropdown [options]="listCountry" [style]="{'width':'100%'}"
                                                        name="passportIssueCountry" formControlName="newCountryVal"
                                                        optionLabel="countryName" optionValue="countryCode"
                                                        placeholder="Country"></p-dropdown>
                                                </div>
                                                <div class="col-md-6 px-0" style="margin-top: -5px;">
                                                    <input class="w-100" type="text" placeholder="Enter Passport Number"
                                                        formControlName="newVal">
                                                </div>
                                            </div>

                                        </div>
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <div class="col-12 px-0">
                                <span class="invalid-message"
                                    *ngIf="i9WorkAuthPreparerForm.get('workAuthorizationProof').errors?.required">
                                    {{ getGlobalErrorMessages("REQUIRED") }}
                                </span>
                            </div>
                        </div>
                    </div> -->
                </div> 


            </div>
        </form>

        <div class="multiDocContainer">
         <app-multi-upload-documents 
            [showListBC]="selectedListGroup"
            [sectionName]="'Section-1'"
            [listBC]="false"
            [companyId]="companyId" 
            [beneficiaryId]="beneficiaryId" 
            [I9FormId]="i9FormId" 
            [isViewButton]="false"></app-multi-upload-documents>
        </div>
    </div>
</div>

<p-confirmDialog header="Delete"></p-confirmDialog>