<div class="container-fluid p-0">
    <div *ngIf="!listOnly" class="float-left" class="bulk-pdialog d-flex justify-content-between">
        <!-- <label class="required"> <b>Uploading Section Forms is mandatory</b></label> -->
        <!-- <div class="input-group"> 
            <div class="i9Inputs">
                <label class=" docCheckbox">I9 Forms
                <input name="listA" type="radio" class="btnRadio" name="docTypeitems" [checked]="i9Section.isCheked"
                (change)="section_Change(i9Section)" value="i9Section.code">
                <span class="checkmark"></span> -->
                <!-- <input type="checkbox" [(ngModel)]="i9Section" (change)="list_A_Change(i9Section)" /><span>I-9 Section 1</span> -->
                <!-- </label>
            </div>
        </div> -->
        <label class="pt-3"><b>Select and upload <span class="text-primary">documents from document list A </span>or a combination of <span class="text-primary">one document from document list B</span> and <span class="text-primary">one document from document list C</span></b></label>
        <button *ngIf="fileA?.length || (fileB && fileC)" class="btn btn-primary" type="button" (click)="addDocTolist()">Add</button>
    </div>


    <div class="row rowBorders mb-2">
        <!-- [ngClass]="{'col-md-6': currentSection == 'Section-3'}" -->
        <ng-container>
            <div class="col-md-4 my-3 position-relative" *ngFor="let file of fileA; let i = index">
                <i class="fa fa-close position-absolute" (click)="removeFileA(i, file)" style="right:17px; top: 5px"></i>
                <div class="border p-2">
                <h6><b>List A Document</b></h6>
                <label class="d-block">{{file.type}}</label>
                <div><b>Selected doc:</b> <span class="ml-2 badge badge-primary">{{file?.value?.name}}</span></div>
                </div>
            </div>
        </ng-container>
        <div class="col-md-4 my-3 position-relative" *ngIf="fileB">
            <i class="fa fa-close position-absolute" (click)="removeFileB()" style="right:17px; top: 5px"></i>
            <div class="border p-2">
              <h6><b>List B Document</b></h6>
              <label class="d-block">{{fileCategoryB}}</label>
              <div><b>Selected doc:</b> <span class="ml-2 badge badge-primary">{{fileB?.value?.name}}</span></div>
            </div>
        </div>
        <div class="col-md-4 my-3 position-relative" *ngIf="fileC">
            <i class="fa fa-close position-absolute" (click)="removeFileC()" style="right:17px; top: 5px"></i>
            <div class="border p-2">
              <h6><b>List C Document</b></h6>
              <label class="d-block">{{fileCategoryC}}</label>
              <div><b>Selected doc:</b> <span class="ml-2 badge badge-primary">{{fileC?.value?.name}}</span></div>
            </div>
        </div>

        <div class="col-md-4 p-0 rightBorder" *ngIf="!lockA && List_A?.length && fileA?.length < 3">
            <h6 class="doclist-heading text-center">
                <b>List A<br>
                   Documents that establish both identity and employment authorization
                </b>
            </h6>
            <ul>
                <ng-container *ngFor="let item of List_A; let i = index">
                    <li [ngClass]="item.selected ? 'disableSelection' : ''" class="d-block mb-2">
                        <div class="w-100 d-flex align-items-center justify-content-between">
                            <label class="p-0 docCheckbox mb-0">{{item.name}}
                            <!-- <input name="listA" type="radio" class="btnRadio" name="docTypeitems" [checked]="item.isCheked"
                                (change)="list_A_Change(item)" value="item.code">
                                <span class="checkmark"></span> -->
                            </label>
                            <i *ngIf="!listOnly" class="fa fa-upload mt-1" style="cursor: pointer"></i>
                            <input id="{{item.name}}" class="uploadHidden" type="file" name="fileA" (change)="uploadFileA($event.target.files, item.name, item.code, item.group)" />
                        </div>
                        <div class="w-100" *ngIf="item?.subcategories">
                            <ul class="i9-docs-subcategory">
                                <li *ngFor="let subItem of item.subcategories">
                                    {{subItem}}
                                </li>
                            </ul>
                        </div>
                    </li>
                </ng-container>
            </ul>
        </div>
       
        <div  class="col-md-4 p-0 rightBorder" *ngIf="!fileB && !lockBandC && List_B?.length">
            <h6 class="doclist-heading text-center">
                <b>List B<br>
                   Documents that establish identity
                </b>
            </h6>
            <ul>
                <li *ngFor="let item of List_B" class="d-block mb-2">
                    <div class="w-100 d-flex align-items-center justify-content-between">
                        <label class="p-0 docCheckbox mb-0">{{item.name}}
                        <!-- <input name="listB" type="radio" class="btnRadio" name="docTypeitems" value="item.code"
                            (change)="list_B_Change(item)" [checked]="item.isCheked">
                            <span class="checkmark"></span> -->
                        </label>
                        <i *ngIf="!listOnly" class="fa fa-upload mt-1" style="cursor: pointer"></i>
                        <input class="uploadHidden" type="file" name="fileA" (change)="uploadFileB($event.target.files, item.name, item.code, item.group)" />
                    </div>
                    <div class="w-100" *ngIf="item?.subcategories">
                        <ul class="i9-docs-subcategory">
                            <li *ngFor="let subItem of item.subcategories">
                                {{subItem}}
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>
            <ul>
                <li *ngFor="let item of List_B1" class="d-block mb-2">
                    <div class="w-100 d-flex align-items-center justify-content-between">
                        <label class="p-0 docCheckbox mb-0">{{item.name}}
                        <!-- <input name="listB" type="radio" name="docTypeitems" class="btnRadio" value="item.code"
                            (change)="list_B_Change(item)" [checked]="item.isCheked">
                            <span class="checkmark"></span> -->
                        </label>
                        <i *ngIf="!listOnly" class="fa fa-upload mt-1" style="cursor: pointer"></i>
                        <input class="uploadHidden" type="file" name="fileA" (change)="uploadFileB($event.target.files, item.name, item.code, item.group)" />
                    </div>

                    <div class="w-100" *ngIf="item?.subcategories">
                        <ul class="i9-docs-subcategory">
                            <li *ngFor="let subItem of item.subcategories">
                                {{subItem}}
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>
            
        </div>

        <div class="col-md-4 p-0 rightBorder" *ngIf="!fileC && !lockBandC && List_C?.length">
            <h6 class="doclist-heading text-center">
                <b>List C<br>
                   Documents that establish employment authorization
                </b>
            </h6>
            <ul>
                <li *ngFor="let item of List_C" class="d-block mb-2">
                    <div class="w-100 d-flex align-items-center justify-content-between">
                        <label class="p-0 docCheckbox mb-0">{{item.name}}
                        <!-- <input name="listC" type="radio" name="docTypeitems" class="btnRadio" value="item.code"
                            (change)="list_C_Change(item)" [checked]="item.isCheked">
                            <span class="checkmark"></span>-->
                        </label>
                        <i *ngIf="!listOnly" class="fa fa-upload mt-1" style="cursor: pointer"></i>
                        <input class="uploadHidden" type="file" name="fileA" (change)="uploadFileC($event.target.files, item.name, item.code, item.group)" />
                    </div>
                    <div class="w-100" *ngIf="item?.subcategories">
                        <ul class="i9-docs-subcategory">
                            <li *ngFor="let subItem of item.subcategories">
                                {{subItem}}
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>
        </div> 
    </div>
</div>