import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
// import { JwtHelperService } from '@auth0/angular-jwt'; // temp fix - don't delete

import { User } from '../data/models/user.model';
import { Router } from '@angular/router';
import { ImagilityBaseResponse } from '../data/models/response.model';
import { ImagilityWebStorage } from './webStorage.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { IdleService } from '../modules/core/services/idle.service';
import { EncryptService } from '../modules/core/http/http-encrypt.service';
import * as utilsProject from 'error-message-utility';
import * as utils from 'src/app/modules/utility/global-utils';
import { SessionRelatedServiceService } from 'app-services-library';


@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;
  domainName: any;

  constructor(
    private router: Router,
    private http: HttpClient,
    private webStorage: ImagilityWebStorage,
    private permissionsService: NgxPermissionsService,
    private idleService: IdleService,
    private encryptService: EncryptService,
    private sessionRelatedServiceService: SessionRelatedServiceService
  ) {
    this.currentUserSubject = new BehaviorSubject<any>(this.webStorage.getWebStorageItem('currentUser'));
    this.currentUser = this.currentUserSubject.asObservable();

    this.domainName = window.location.host;
    const regexExp = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/gi;
    let footerNameString = regexExp.test(this.domainName);

    if (footerNameString == false && this.domainName == 'imagility.co' || footerNameString == true || footerNameString == false && this.domainName == 'localhost:4200') {
      this.domainName = 'imagility.co'
    }
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  // public jwtHelper: JwtHelperService = new JwtHelperService(); // temp fix - don't delete

  login(payload) {
    //  let domainName = this.domainName;
    payload = {
      ...payload,
      domainName: this.domainName
    }
    // this.http = this.http.disableJwt();
    return this.http.post<any>(`/authenticate`, payload)
      .pipe(map((user: ImagilityBaseResponse) => {
        if (user && user.status === 200) {
          if (user.data['linkRequestCode'] == null ||
            user.data['linkRequestCode'] == null) {
            this.mapUserData(user);
          }
        } else {
          sessionStorage.setItem('attemptedUserName', payload.username);
          sessionStorage.setItem('userId', user.data['userId']);
        }
        return user;
      }));
  }

  loginCheck(username: string, password: string) {
    let domainName = this.domainName;
    return this.http.post<any>(`/authenticate`, { username, password, domainName }).pipe(map((user: ImagilityBaseResponse) => {
      return user;
    }));
  }

  loginExternalBeneficiary(username: string, password: string, linkRequestCode: string) {
    return this.http.post<any>(`/authenticate/link`, { linkRequestCode, password, username })
      .pipe(map((user: ImagilityBaseResponse) => {
        return user;
      }));
  }

  logout() {
    const token = localStorage.getItem('UserToken');
    if (token && token !== 'null') {
      return this.http.post<any>(`/authenticate/invalidate`, {
        'jwt': 'Bearer ' + token
      });
    } else {
      return of(null);
    }
  }

  setCookie(jwt) {
    document.cookie = '';
    const d = new Date();
    d.setTime(d.getTime() + (3600 * 1000 * 24 * 365 * 1));
    const expires = 'expires=' + d.toUTCString();
    // document.cookie = "UserAcceptanceCookie=1;path=/;domain=imagility.com;" + expires;
    document.cookie = 'UserToken=' + jwt + ';path=/;';
  }
  validateUser(token) {
    let domainName = this.domainName;
    if (token && token !== 'null') {
      const jwt = 'Bearer ' + token;
      let payload: any = { jwt, domainName };
      if ('presentApp' in sessionStorage) {
        payload = {
          ...payload,
          appName: sessionStorage.getItem('presentApp')
        };
      }
      return this.http.post<any>(`/authenticate`, payload)
        .pipe(
          map((user: ImagilityBaseResponse) => {
            if (user && user.status === 200) {
              this.mapUserData(user);
              return user;
            } else {
              return of(null);
            }
          }),
          catchError(error => {
            this.logout()
              .pipe(take(1))
              .subscribe(() => {
                this.clearUserData();
                this.router.navigate(['/login']);
              });
            throw error;
          })
        );
    }
    return of(null);
  }
  mapUserData(user) {
    sessionStorage.setItem('isCredentialsExpired', user['isCredentialsExpired']);
    this.webStorage.setWebStorageItem('currentUser', user.data);
    localStorage.setItem('UserToken', user.data ? user.data['jwt'] : null);
    localStorage.setItem('userTypeRole', user.data ? user.data['userType'] : null);
    sessionStorage.setItem('companyId', user.data ? user.data['companyId'] : null);
    sessionStorage.setItem('userId', user.data ? user.data['userId'] : null);
    sessionStorage.setItem('employeeId', user.data ? user.data['employeeId'] : null);
    sessionStorage.setItem('loginEmployeeId', user.data ? user.data['employeeId'] : null);
    sessionStorage.setItem('beneficiaryId', user.data && user.data['beneficiaryId'] ? user.data['beneficiaryId'] : (sessionStorage.getItem('beneficiaryId') ? sessionStorage.getItem('beneficiaryId') : null));
    sessionStorage.setItem('loggedInBeneficiaryId', user.data ? user.data['beneficiaryId'] : null);
    sessionStorage.setItem('avatar', user.data ? (user.data['profileSmallImage']) ? user.data['profileSmallImage'] : undefined : undefined);
    sessionStorage.setItem('userTypeRole', user.data ? user.data['userType'] : null);
    sessionStorage.setItem('userTypeId', user.data ? user.data['userId'] : null);
    sessionStorage.setItem('roleName', user.data?.roleName ?? null);
    this.permissionsService.loadPermissions(user.data ? user.data['permissions'] : []);
    if (user?.data?.modules?.length > 0) {
      sessionStorage.setItem('isFBUser', 'true');
      sessionStorage.setItem('fbEmpId', user.data.fbEmployeeId ? user.data.fbEmployeeId : null);
      sessionStorage.setItem('fbModuleList', JSON.stringify(user.data.modules));
      sessionStorage.setItem('fbDesignation', user.data.designation ? user.data.designation : '');
      sessionStorage.setItem('isFbHrManager', user.data.isFbHrManager);
      sessionStorage.setItem('fbRoleId', user.data?.roleId ?? null);
      sessionStorage.setItem('fbRole', user.data?.roleName ?? null);
    }
    sessionStorage.setItem('roleList', user.data?.roles?.length > 0 ? JSON.stringify(user.data.roles) : '[]');
    this.currentUserSubject.next(user.data);
  }
  clearUserData() {
    localStorage.setItem('UserToken', null);
    localStorage.setItem('userTypeRole', null);
    if ('currentUser' in sessionStorage) {
      this.webStorage.removeWebStorageItem('currentUser');
    }
    if ('presentApp' in sessionStorage) {
      this.webStorage.removeWebStorageItem('presentApp');
    }
    this.currentUserSubject.next(null);
    this.webStorage.sessionEndTimestamping(); /*IM3069*/
    this.sessionRelatedServiceService.clearSessionStorage();
    this.idleService.get().stop();
  }

  logoCalling(url) {

    var logo = this.encryptService.toEncrypt('IMAGILITY.CO');
    return this.http.get(`/company/logo/` + logo).pipe(
      map((response: ImagilityBaseResponse) => {
        return response;
      })
    );
  }
  clearCache(){
    return this.http.get(`/clear-cache`).pipe(
      map((response: ImagilityBaseResponse) => {
        return response;
      })
    );
  }

  async fetchTranslations() {
    await utils.fetchTranslations(this.http);
    await utilsProject.fetchTranslations(this.http);
  }
}
