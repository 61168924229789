import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CacheCountryService } from 'src/app/services/cacheCountries.service';
import * as errorUtils from "src/app/modules/utility/global-utils";
import { ToastrService } from 'ngx-toastr';
import { ImagilityBaseResponse } from '../../../../data/models/response.model';
import { select, Store } from '@ngrx/store';
import { GCI140State } from '../../store';
import { getStepDetails } from '../../store/selectors/sub-task-details.selectors';
import { TaskStep } from 'src/app/data/models/task-step.model';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { ReferenceLibraryService } from '../../../../services/referenceLibrary.service';
import { ReferenceLibrary } from 'src/app/data/models/referenceLibrary.model';
import { ApiServicesService } from '../../../beneficiary/services/api-services.service';
import moment from 'moment';
import { updatei140StepStatus, updateStepStatusToInitialState } from '../../store/actions/step-status-update.actions';
import { getStepStatusUpdate } from '../../store/selectors/step-status-update.selectors';
import { loadSubTaskDetails } from '../../store/actions/sub-task-details.actions';
import { Subject } from 'rxjs/internal/Subject';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { StepStatusUpdatePayload } from '../../utility/services/stepStatusUpdate.service';
import { first } from 'rxjs/internal/operators/first';
import { MarkCleanFormAction, MarkDirtyFormAction } from 'src/app/app-state/actions/dirty-form.actions';
import { DirtyFormGuard } from 'src/app/dirty-form.guard';
import { PetitionBuilderService } from 'src/app/services/petition-builder.service';
import { DeleteDialogConfigurations } from '../../utility/configurations/dialog.configuration';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';
import { AppServicesLibraryService } from 'app-services-library';

@Component({
  selector: 'app-dependent-details',
  templateUrl: './dependent-details.component.html',
  styleUrls: ['./dependent-details.component.scss']
})
export class DependentDetailsComponent implements OnInit {

  @ViewChild('scrollContainer')
  depdentDetailsElementRef: ElementRef;
  dependentForm: FormGroup;
  countryList: [];
  stateList: [];
  listTitle: ReferenceLibrary[];
  listGender: ReferenceLibrary[];
  listRelationship: ReferenceLibrary[];
  disabledControls: boolean;
  selectedCountryCode: any;
  typeOfButton: string; // This is required for status update button
  stepDetails: TaskStep;
  currentDate: any;
  dependentList: any;
  rowdata: any;
  showDependentDetailsForm: boolean;
  uniqueFamilyCodes: any[] = ['FATHER','MOTHER','FIANCE','SPOUSE'];
  relastionShipEditType : boolean;

  observableSubscription$ = new Subject();
  formChangesSubscription: any;
  guardSubscription: any;
  maxDate: Date;
  stepId: any;
  showNext: boolean = false;
  showPrev: boolean = false;

  constructor(private dependentDetailsBuilder: FormBuilder,
    private cacheCountryService: CacheCountryService,
    private referenceLibraryService: ReferenceLibraryService,
    private store: Store<GCI140State>,
    private toastr: ToastrService,
    public dynamicDialogRef: DynamicDialogRef,
    public dynamicDialogConfig: DynamicDialogConfig,
    public dialogService: DialogService,
    private apiService: ApiServicesService,
    private pbService: PetitionBuilderService,
    private guard: DirtyFormGuard,
    private stepStatusUpdatePayloadCostruction: StepStatusUpdatePayload,
    private appServicesLibraryService: AppServicesLibraryService
  ) {
    this.disabledControls = false;
    this.relastionShipEditType = false;
    this.countryList = [];
    this.stateList = [];
    this.dependentList = [];
    this.listRelationship = [];
    this.currentDate = moment().format('YYYY-MM-DD');
    this.showDependentDetailsForm = false;
    let today = new Date();
    let month = today.getMonth();
    let year = today.getFullYear();
    let nextMonth = (month === 12) ? 0 : month + 0;
    let nextYear = (nextMonth === 0) ? year + 1 : year;
    this.maxDate = new Date();
    this.maxDate.setMonth(nextMonth);
    this.maxDate.setFullYear(nextYear);
    this.maxDate.setDate(this.maxDate.getDate() + 0);
    this.showNext = this.dynamicDialogConfig.data?.showNext; 
    this.showPrev = this.dynamicDialogConfig.data?.showPrev;
    
  }

  ngOnInit(): void {
    this.loadRefLibraries();
    this.stepId = this.dynamicDialogConfig.data.stepId;
    // Selector for step status update
    this.store.pipe(select(getStepStatusUpdate)).pipe(takeUntil(this.observableSubscription$)).pipe(takeUntil(this.observableSubscription$)).subscribe((response: ImagilityBaseResponse) => {
      if (response && response.status === 200) {
        this.toastr.success(response.message, 'Success');
        this.store.dispatch(loadSubTaskDetails({ taskId: this.stepDetails.taskId }));
      }
    });

    this.dependentForm = this.dependentDetailsBuilder.group({
      id: [''],
      relationshipType: ['', Validators.required],
      title: [''],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      middleName: [''],
      // otherCheck: [''],
      // otherNames: this.dependentDetailsBuilder.array([
      //   this.dependentDetailsBuilder.group({
      //     id: [''],
      //     title: [''],
      //     firstName: [''],
      //     middleName: [''],
      //     lastName: [''],
      //   })
      // ]),
      birthCountryCode: ['', Validators.required],
      gender: ['', Validators.required],
      dob: ['', [Validators.required]],
      phone: this.dependentDetailsBuilder.array([
        this.dependentDetailsBuilder.group({
          id: [''],
          countryCode: [''],
          phoneNo: ['', [Validators.pattern(/^-?(0|[0-9]\d*)?$/)]],
          type: ['MOBL'],
        })
      ]),
      email: this.dependentDetailsBuilder.array([
        this.dependentDetailsBuilder.group({
          id: [''],
          mailId: ['', [Validators.email]],
          type: ['PRIM'],
        })
      ]),
      isAdjustmentStatus: [],
      isVisaAboroad: [],
    });

    // this.updateFormControls();
    this.store.pipe(select(getStepDetails, { id: this.stepId })).pipe(takeUntil(this.observableSubscription$)).subscribe((data: TaskStep) => {
      this.getDependentDetails();
      this.typeOfButton = data.isPetitionerAction === 1 || data.isAttorneyAction === 1 ? "MARK_COMPLETE" : "MARK_SUBMIT";
      this.stepDetails = data;
      this.toggleControlsStatus();
      // if (this.stepDetails.stepStatusDetails.stepStatusCode === 'NEW') {
      //   this.updateStepStatus('INPROGRESS');
      // }
    });
  }

  getFormArrayControls(formArrayName: string) {
    return (this.dependentForm.get(formArrayName) as FormArray).controls;
  }

  loadRefLibraries() {
    this.getCountryList();
    this.getListTitle();
    this.getListGender();
    this.getListRelationship();
  }

  ngAfterViewInit() {
    this.formChangesSubscription = this.dependentForm.valueChanges.pipe(takeUntil(this.observableSubscription$)).subscribe(() => {
      if (this.dependentForm.dirty) {
        this.store.dispatch(new MarkDirtyFormAction({
          dirty: true
        }));
      } else {
        this.store.dispatch(new MarkCleanFormAction({
          dirty: false
        }));
      }
    });
  }

  // updateFormControls() {
  //   this.dependentForm.get('otherCheck').valueChanges.pipe(takeUntil(this.observableSubscription$)).subscribe((value) => {
  //     if (value) {
  //       ((this.dependentForm.controls['otherNames'] as FormArray).controls[0] as FormGroup).controls['firstName'].setValidators(Validators.required);
  //     } else {
  //       ((this.dependentForm.controls['otherNames'] as FormArray).controls[0] as FormGroup).controls['firstName'].clearValidators();
  //       ((this.dependentForm.controls['otherNames'] as FormArray).controls[0] as FormGroup).controls['firstName'].reset();
  //     }
  //     ((this.dependentForm.controls['otherNames'] as FormArray).controls[0] as FormGroup).controls['firstName'].updateValueAndValidity();
  //   });
  // }

  getListTitle() {
    this.referenceLibraryService.getReferenceData('TITL').pipe(takeUntil(this.observableSubscription$)).subscribe((response: ReferenceLibrary[]) => {
      this.listTitle = response;
    });
  }

  getListGender() {
    this.referenceLibraryService.getReferenceData('GENDER').pipe(takeUntil(this.observableSubscription$)).subscribe((response: ReferenceLibrary[]) => {
      this.listGender = response;
    });
  }

  getListRelationship() {
    this.referenceLibraryService.getReferenceData('FAMRELTYP').pipe(takeUntil(this.observableSubscription$)).subscribe((response: ReferenceLibrary[]) => {
      this.listRelationship = response;
    });
  }

  getDependentDetails() {
    this.pbService.getDependentDetail(this.dynamicDialogConfig.data.superTaskId, this.dynamicDialogConfig.data.subTaskId, this.dynamicDialogConfig.data.i140CaseId).pipe(takeUntil(this.observableSubscription$)).subscribe((response: ImagilityBaseResponse) => {
      if (response && response.data) {
        // response.data.map((element) => element.otherCheck = element.otherNames && element.otherNames.length > 0 ? true : false);
        this.dependentList = response.data;
        if(this.dependentList){
          const alreadyAddedMembersCode = [];
          this.dependentList.forEach((element)=>{ alreadyAddedMembersCode.push(element.relationshipType)});
          
          this.listRelationship = this.listRelationship.map((relType)=>{
            if(this.uniqueFamilyCodes.includes(relType.code) && alreadyAddedMembersCode.includes(relType.code)){
              relType['inactive'] = true;
            }
            return relType;
          });
          console.log(this.listRelationship)
        }
      }
      else {
        this.dependentList = [];
      }
    })
  }

  syncDependentDetails() {
    this.pbService.syncDependentDetails(this.dynamicDialogConfig.data.superTaskId, this.dynamicDialogConfig.data.subTaskId, this.dynamicDialogConfig.data.i140CaseId).pipe(takeUntil(this.observableSubscription$)).subscribe((response: ImagilityBaseResponse) => {
      if (response) {
        this.toastr.success(response.message, 'Success');
        this.getDependentDetails();
      }
    })
  }

  editDependentDetails(selectedRow) {
    // selectedRow['otherNames'] = selectedRow['otherNames'] ? selectedRow['otherNames'] : [{}];
    this.showDependentDetailsForm = true;
    selectedRow['dob'] = new Date(selectedRow['dob']);
    this.dependentForm.patchValue(selectedRow);
    this.relastionShipEditType = true;
    // selectedRow['relationshipType'] = selectedRow['relationshipType'] ? this.relastionShipEditType = true : this.relastionShipEditType = false;
    (document.getElementsByClassName('p-dialog-content')[0]).scrollTop = 0;
  }

  addNewDependent() {
    this.loadRefLibraries();
    this.getDependentDetails();
    this.showDependentDetailsForm = true;
    this.dependentForm.reset({
      id: '',
      relationshipType: '',
      title: '',
      firstName: '',
      lastName: '',
      middleName: '',
      // otherCheck: '',
      // otherNames: [{
      //   id: '',
      //   title: '',
      //   firstName: '',
      //   middleName: '',
      //   lastName: '',
      // }],
      birthCountryCode: '',
      birthStateCode: '',
      birthStateProcince: '',
      birthCity: '',
      gender: '',
      citizenshipCountryCode: '',
      dob: '',
      phone: [{
        id: '',
        countryCode: '',
        phoneNo: '',
        type: 'MOBL',
      }],
      email: [{
        id: '',
        mailId: '',
        type: 'PRIM',
      }],
      isAdjustmentStatus: null,
      isVisaAboroad: null,
    });
    this.relastionShipEditType = false;
  }

  deleteDependentDetails(input) {
    const deleteDialogRef = this.dialogService.open(DeleteDialogComponent, DeleteDialogConfigurations);
    deleteDialogRef.onClose.pipe(takeUntil(this.observableSubscription$)).pipe(takeUntil(this.observableSubscription$)).subscribe((response: boolean) => {
      if (response) {
        this.pbService.deleteDependentDetails(this.dynamicDialogConfig.data.superTaskId, this.dynamicDialogConfig.data.subTaskId, this.dynamicDialogConfig.data.i140CaseId, input.id).pipe(takeUntil(this.observableSubscription$)).subscribe((response: ImagilityBaseResponse) => {
          this.toastr.success(response.message, 'Success');
          this.getDependentDetails();
        })
      }
    });
  }

  //Toggle controls status based on step status
  toggleControlsStatus() {
    this.disabledControls = this.appServicesLibraryService.formToBeDisabled(this.stepDetails.stepStatusDetails.stepStatusCode);
    // this.disabledControls = this.stepDetails.stepStatusDetails.stepStatusCode === "NEW" ||this.stepDetails.stepStatusDetails.stepStatusCode === "COMPLETE" || this.stepDetails.stepStatusDetails.stepStatusCode === "SUBMIT" ? true : false;
  }

  //To fetch  list of countries
  getCountryList() {
    this.cacheCountryService.getCountry().pipe(takeUntil(this.observableSubscription$)).subscribe();
    this.cacheCountryService.getCachedCountryList().subscribe(data => {
      if (data.listCountries.length > 0) {
        this.countryList = data.listCountries;
        this.selectedCountryCode = this.countryList['birthCountryCode'];
      }
    });
  }


  postDependent() {
    const payload = this.dependentForm.value;
    payload['dob'] = moment(payload['dob']).format('MM-DD-YYYY');
    this.pbService.postDependentDetails(this.dependentForm.value, this.dynamicDialogConfig.data.superTaskId, this.dynamicDialogConfig.data.subTaskId, this.dynamicDialogConfig.data.i140CaseId).pipe(takeUntil(this.observableSubscription$)).subscribe((response: ImagilityBaseResponse) => {
      this.toastr.success(response.message, 'Success');
      this.showDependentDetailsForm = false;
      this.store.dispatch(new MarkCleanFormAction({
        dirty: false
      }));
      this.getDependentDetails();
    });
  }

  updateStepStatus(status) {
    this.store.dispatch(updatei140StepStatus({
      payload: this.stepStatusUpdatePayloadCostruction.payloadConstruction(this.stepDetails, status), subTaskId: this.stepDetails.taskId, actionCode:
        this.stepDetails.stepActionList[0].layoutCode
    }));
  }

  getGlobalErrorMessages(error: string) {
    return errorUtils.errorMessages.get(error);
  }

  onRowExpand(event) {
    console.log(event.data);
  }

  handleCancel() {
    this.dependentForm.reset();
    this.showDependentDetailsForm = false;
    this.getDependentDetails();
  }

  handleClose() {
    this.guardSubscription = this.guard.canDeactivate().pipe(first()).subscribe((response: boolean) => {
      if (response) {
        this.dynamicDialogRef.close();
      }
      else {
        return false;
      }
    });
  }

  ngOnDestroy() {
    this.observableSubscription$.next();
    this.observableSubscription$.complete();
    // Update the Step Status state to empty state
    this.store.dispatch(updateStepStatusToInitialState());
  }
}
