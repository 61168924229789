import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ImagilityBaseResponse } from '../../../data/models/response.model';
import { EncryptService } from '../../core/http/http-encrypt.service';

@Injectable({
    providedIn: 'root'
})
export class CaseStatusService {

    constructor(private http: HttpClient, private encryptService: EncryptService) {
    }

    getServiceCenters(isPremium) {
      //var encryptText = this.encryptService.toEncrypt(isPremium);
        return this.http.get(`/serviceCenter/${isPremium}`).pipe(
            map((response: ImagilityBaseResponse) => response.data)
        );
    }

    getImmiServiceCenters(isPremium,taskTypeId) {
      var encryptText = this.encryptService.toEncrypt(isPremium);
        return this.http.get(`/taskType/${taskTypeId}/serviceCenter/${encryptText}`).pipe(
          map((response: ImagilityBaseResponse) => response.data)
        );
    }

    getSubTasksType(superTaskType) {
        return this.http.get(`/task/superTask/${superTaskType}/taskTypes`).pipe(
          map((response: ImagilityBaseResponse) => {
            if (response.status === 200) {
              return response.data;
            }
          })
        );
    }
    getBeneficiariesByCompanyId(companyId) {
      return this.http.get(`/petitioner/dashboard/beneficiaries/company/associated/${companyId}/list`).pipe(
        map((response: ImagilityBaseResponse) => {
          if (response.status === 200 && response.data) {
            return response.data;
          }else {
            return []
          }
        })
      )
    }

    addNewCase(payload: any) {
      const url = '/casestatus/createorupdate';
      return this.http.post(url, payload).pipe(
        map((response: ImagilityBaseResponse) => {
          if (response.status === 200) {
            return response;
          }
        })
      );
    }

    updateCase(caseStatusId, payload) {
      const url = '/casestatus/edit/' + caseStatusId;
      return this.http.post(url, payload).pipe(
        map((response: ImagilityBaseResponse) => {
          return response;
        })
      );
    }

    getCaseStatuses(payload: any,attorneyId:any = null){
      let pathFlag = 'list';
      if(attorneyId){
        pathFlag = `attorney/${attorneyId}`;
      }
      return this.http.post(`/casestatus/${pathFlag}`, payload).pipe(
        map((response: ImagilityBaseResponse) => {
          if (response.status === 200) {
            return response.data;
          }
        })
      );
    }

    deleteCase(caseStatusId: number) {
      return this.http.delete(`/casestatus/delete/${caseStatusId}`).pipe(
        map((response: ImagilityBaseResponse) => {
          return response;
        })
      );
    }

    refereshCases(payload: any){
      return this.http.post(`/casestatusupdate/listlevel`, payload).pipe(
        map((response: ImagilityBaseResponse) => {
          if (response.status === 200) {
            return response.data;
          }
        })
      );
    }

    refreshCase(receiptNumber: string){
      return this.http.get(`/casestatus/caselevelupdate/popuprefresh?receiptNumber=${receiptNumber}`).pipe(
        map((response: ImagilityBaseResponse) => {
          if (response.status === 200) {
            return response.data;
          }
        })
      );
    }

    fetchContentWithreceiptNumber(receiptNumber: string){
      return this.http.get(`/casestatus/caselevelupdate/popuprefresh?receiptNumber=${receiptNumber}`).pipe(
        map((response: ImagilityBaseResponse) => {
          if (response.status === 200) {
            return response.data;
          }
        })
      );
    }

    caseEdit(_id: string){
      return this.http.get(`/casestatus/getcase/${_id}`).pipe(
        map((response: ImagilityBaseResponse) => {
          if (response.status === 200) {
            return response.data;
          }
        })
      );
    }
    getSubTasksTypeListImmigrant(immgCategoryCode, immgClasificationCode) {
      return this.http.get(`/subtask/list/immgCategory/${immgCategoryCode}/immgClasification/${immgClasificationCode}`).pipe(
        map((response: ImagilityBaseResponse) => {
          if (response.status === 200) {
            return response.data;
          }
        })
      );
    }

    getTask(taskId) {
      return this.http.get(`/task/${taskId}`).pipe(
        map((response: ImagilityBaseResponse) => {
          if (response.status === 200) {
            return response.data;
          }
        })
      );
    }

    getCaseStatusList(payload: any){
      return this.http.post(`/casestatus/allcompanies/list`, payload).pipe(
        map((response: ImagilityBaseResponse) => {
          if (response.status === 200) {
            return response.data;
          }
        })
      );
    }

    updateCases(payload: any){
      return this.http.post(`/usciscasestatus/update`, payload).pipe(
        map((response: ImagilityBaseResponse) => {
          if (response.status === 200) {
            return response;
          }
        })
      );
    }

    getAllCompanies() {
      return this.http.get(`/company/allCompanies`).pipe(
        map((response: ImagilityBaseResponse) => {
          if (response.status === 200) {
            return response;
          }
        })
      );
    }

    updateCaseStatusTracker() {
      return this.http.get('/caseStatusTracker/update').pipe(
        map((response: ImagilityBaseResponse) => {
          if (response.status === 200) {
            return response;
          }
        })
      );
    }
}
