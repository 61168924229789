<nav class="im-navbar navbar-expand-xl navbar-light mb-4">
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      <li class="im-nav-item" routerLinkActive="active">
        <a class="im-nav-link" routerLink="dashboard"> <i class="fa fa-home"></i> DASHBOARD</a>
      </li>
      <li class="im-nav-item" routerLinkActive="active">
        <a class="im-nav-link" routerLink="companies/company-list"><i class="fa fa-file-alt"></i>
          Petitioners</a>
      </li>
      <!-- <li class="im-nav-item" routerLinkActive="active">
                <a class="im-nav-link" routerLink="beneficiaries"><i class="fa fa-file-alt"></i> Beneficiaries</a>
            </li> -->
      <li class="im-nav-item" routerLinkActive="active">
        <a class="im-nav-link" routerLink="beneficiary-list"><i class="fa fa-file-alt"></i> Beneficiaries</a>
      </li>
      <li class="im-nav-item" routerLinkActive="active" *ngxPermissionsOnly="['VIEW_COMPANY']">
        <a class="im-nav-link" routerLink="attorneys/companies"><i class="fa fa-file-alt"></i> Lawfirms</a>
      </li>
      <li class="im-nav-item dropdown" routerLinkActive="active">
        <a class="im-nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          <i class="fa fa-home"></i> Data Setup
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" routerLink="task-allocation">Steps Time Configurations</a>
          <a class="dropdown-item" routerLink="free-trial">Free Trial Setup</a>
          <a class="dropdown-item" routerLink="lca">Paid Subscription Setup</a>
          <a class="dropdown-item" routerLink="master-data/manage/page-designer/module-list">Master Data Management</a>
        </div>
      </li>
      <li class="im-nav-item" routerLinkActive="active">
        <a class="im-nav-link"><i class="fa fa-file-alt"></i> Projects</a>
      </li>
      <li class="im-nav-item dropdown" routerLinkActive="active">
        <a class="im-nav-link dropdown-toggle" role="button" id="navbarDropdown" data-toggle="dropdown"
        aria-haspopup="true" aria-expanded="false"><img src="/assets/images/menu-reports.png" width="20px" alt=""> Reports</a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" routerLink="download-reports">User Reports</a>
        </div>
      </li>
      <!-- <li class="im-nav-item" routerLinkActive="active">
                <a class="im-nav-link" routerLink="visa-designer"><i class="fa fa-file-alt"></i> Visa Designer</a>
            </li> -->
      <li class="im-nav-item dropdown" routerLinkActive="active">
        <a class="im-nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          <i class="fa fa-home"></i> Design Services
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" routerLink="page-designer">Page / Forms Designer</a>

          <a class="dropdown-item" routerLink="visa-designer">Visa Designer</a>

        </div>
      </li>

      <li class="im-nav-item dropdown" routerLinkActive="active">
        <a class="im-nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          <i class="fa fa-home"></i> Admin
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" routerLink="data-migration">Data Migration</a>

          <a class="dropdown-item" routerLink="policies" routerLinkActive="active">Policies</a>

        </div>
      </li>
    </ul>
  </div>
  <!-- <a class="navbar-rgt-btn" href="#">
    <div>RECOMMENDED<br>TOOLS FOR YOU</div>
  </a> -->
</nav>
