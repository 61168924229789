<div class="add-edit-case-status">
    <div class="pag-caption">
        <small class="link" (click)="breadCrumbNavigation()">Home</small> &raquo; <small> Services</small> &raquo; <small> Case Status</small>
    </div>
    <div class="page-caption col-md-12 px-0">
        <h5 class="mb-0" *ngIf="editCaseData">Edit Case Status</h5>
        <h5 class="mb-0" *ngIf="!editCaseData">Create New Status</h5>
        
    </div>
    <div class="row"  *ngIf="editCaseData">
       
        <button class="back-button back-btn-white-bg pl-0"  *ngIf="editCaseData" (click)="cancelHandler()"><i class="fa fa-chevron-circle-left" aria-hidden="true"></i>
         Back
        </button>
      </div>
    <div class="content-container pb-1 mt-3">
        <div *ngIf="!editCaseData || (editCaseData && !editCaseData.taskId)" class="row page-heading page-header">
            <div class="col-12">
                <h2>Enter Case Details</h2>
            </div>
        </div>
        <div class="row" *ngIf="editCaseData && editCaseData.taskId">
            <div class="col-12">
                <p class="w-100">Details shown below is fetched from task. To update, click "GO TO TASK" and update under "Get Receipts" Step.</p>
                <button type="submit" class="btn btn-primary" (click)="goToTask()">GO TO TASK</button>
            </div>
            <p-divider type="dashed" class="col-12 mt-4"></p-divider>
        </div>
        <form [formGroup]="newcaseForm">
            <!-- First Row -->
            <div class=" row mt-3 mb-4">
                <div *ngIf="userTypeRole ==='Attorney'" class="col-4">
                    <p class="label-cls required" for="">Company</p>
                    <p-dropdown [style]="{'minWidth':'100%'}"  (onChange)="handleCompanyChange($event)" [options]="listOfClientCompanies"
                        formControlName="companyId" formControlName="companyId" optionValue="petitionerId"
                        placeholder="Select Company" optionLabel="petitionerName" [showClear]="false"></p-dropdown>
                </div>

                <div class="col-4">
                    <p class="label-cls required" for="">Beneficiary Name</p>
                    <p-dropdown [style]="{'minWidth':'100%'}"  [options]="beneFiciaries" optionValue="beneficiaryEmployeeId"
                        optionLabel="beneficiaryEmployeeFullName" formControlName="beneficiaryId"
                        placeholder="Select Beneficiary"></p-dropdown>
                    <small *ngIf="newcaseForm.get('beneficiaryId').touched && newcaseForm.get('beneficiaryId').errors"
                        class="p-error">
                        <span *ngIf="newcaseForm.get('beneficiaryId').errors?.required">{{ getGlobalErrorMessages("REQUIRED") }}</span>
                    </small>
                </div>
           </div>

           <!-- Second Row -->
            <div class="row mt-3 mb-4">
                <div class="col-4" [ngClass]="{'mb-4': newcaseForm.get('superTaskType').value}">
                    <p class="label-cls required" for="">Task Type</p>
                    <p-dropdown [style]="{'minWidth':'100%'}" [options]="taskType"
                        optionValue="code" optionLabel="name" (onChange)="onTaskTypeChange()"
                        placeholder="Select Task Type" formControlName="superTaskType"></p-dropdown>

                    <small *ngIf="newcaseForm.get('superTaskType').touched && newcaseForm.get('superTaskType').errors"
                        class="p-error">
                        <span *ngIf="newcaseForm.get('superTaskType').errors?.required">{{ getGlobalErrorMessages("REQUIRED") }}</span>
                    </small>
                </div>

                <div class="col-4" *ngIf="newcaseForm.get('superTaskType').value ==='NONIMMPET'">
                    <p class="label-cls required">Visa Type </p>
                   
                    <p-dropdown [style]="{'minWidth': '100%'}" [options]="visaTypes" optionValue="code"
                        formControlName="visaType" optionLabel="name" (onChange)="onVisaTypeChange()"
                        placeholder="Select Visa Type"></p-dropdown>

                    <small *ngIf="newcaseForm.get('visaType').touched && newcaseForm.get('visaType').errors"
                        class="p-error">
                        <span *ngIf="newcaseForm.get('visaType').errors?.required">{{ getGlobalErrorMessages("REQUIRED") }}</span>
                    </small>
                </div>

                <div class="col-4 mb-4" *ngIf="newcaseForm.get('superTaskType').value ==='IMMPET'">
                    <p class="label-cls required">Immigration Category</p>
                    <p-dropdown [style]="{'minWidth':'100%'}"  optionValue="code" [options]="immCategoryTypes" formControlName="immCategoryCode"
                    placeholder="Select Immigration Category" optionLabel="name" (onChange)="onIMMCategory()">
                    </p-dropdown>
                    <small
                        *ngIf="newcaseForm.get('immCategoryCode').touched && newcaseForm.get('immCategoryCode').errors"
                        class="p-error">
                        <span *ngIf="newcaseForm.get('immCategoryCode').errors?.required">{{ getGlobalErrorMessages("REQUIRED") }}</span>
                    </small>
                </div>

                <div class="col-4" *ngIf="newcaseForm.get('superTaskType').value ==='IMMPET'">
                    <p class="label-cls required">Immigration Classification</p>
                    <p-dropdown [style]="{'minWidth':'100%'}"  optionValue="code" [options]="immClassificationTypes"
                        formControlName="immClassificationCode" placeholder="Select Immigration Category"
                        optionLabel="name" (onChange)="onIMMClassificationChange()">
                    </p-dropdown>
                    <small
                        *ngIf="newcaseForm.get('immClassificationCode').touched && newcaseForm.get('immClassificationCode').errors"
                        class="p-error">
                        <span *ngIf="newcaseForm.get('immClassificationCode').errors?.required">{{ getGlobalErrorMessages("REQUIRED") }}</span></small>
                </div>
                <div class="col-4" *ngIf="newcaseForm.get('superTaskType').value ==='NONIMMPET'">
                    <p class="label-cls required">Petition Type</p>
                    <p-dropdown [style]="{'minWidth':'100%'}"  placeholder="Select Petition Type" optionValue="code"
                        [options]="petitionTypes" formControlName="petitionType" optionLabel="name"></p-dropdown>

                    <small *ngIf="newcaseForm.get('petitionType').touched && newcaseForm.get('petitionType').errors"
                        class="p-error">
                        <span *ngIf="newcaseForm.get('petitionType').errors?.required">{{ getGlobalErrorMessages("REQUIRED") }}</span>
                    </small>
                </div>

                <div class="col-4" *ngIf="newcaseForm.get('superTaskType').value ==='IMMPET'">
                    <p class="label-cls required">Sub Task Type</p>
                    <p-dropdown [style]="{'minWidth':'100%'}"  
                        optionValue="code" 
                        [options]="subTaskTypes" 
                        defaultLabel="Select SubTask"
                        placeholder="Select Sub Task Type" 
                        formControlName="subTaskType"
                        optionLabel="name">
                    </p-dropdown>
                    
                    <small *ngIf="newcaseForm.get('subTaskType').touched && newcaseForm.get('subTaskType').errors" class="p-error">
                        <span *ngIf="newcaseForm.get('subTaskType').errors?.required">{{ getGlobalErrorMessages("REQUIRED") }}</span>
                    </small>
                </div>
                <div class="col-4">
                    <p class="label-cls required">Processing Type</p>
                    <p-dropdown [style]="{'minWidth':'100%'}"  placeholder="Select Processing Type " [options]="processTypes" optionLabel="name"
                        optionValue="code" formControlName="processType" (onChange)="getServiceCenters(newcaseForm.get('processType').value);"></p-dropdown>
                    <small *ngIf="newcaseForm.get('processType').touched && newcaseForm.get('processType').errors"
                        class="p-error">
                        <span *ngIf="newcaseForm.get('processType').errors?.required">{{ getGlobalErrorMessages("REQUIRED") }}</span></small>
                </div>

                <div class="col-4">
                    <p class="label-cls required">Service Center</p>
                    <p-dropdown [style]="{'minWidth':'100%'}"  optionValue="id" [options]="serviceCenters" placeholder="Select Service Center" formControlName="serviceCenterId"
                        optionLabel="name"></p-dropdown>
                    <small
                        *ngIf="newcaseForm.get('serviceCenterId').touched && newcaseForm.get('serviceCenterId').errors"
                        class="p-error">
                        <span *ngIf="newcaseForm.get('serviceCenterId').errors?.required">{{ getGlobalErrorMessages("REQUIRED") }}</span></small>
                </div>
            </div>

            <!-- Fourth Row -->
            <div class="row mt-3 mb-4">
                <div class="col-4">
                    <p class="label-cls d-inline required" required>Receipt Number</p>&nbsp;<i *ngIf="editCaseData && editCaseData?.shortStatus" class="pi pi-info-circle tooltip-icon" pTooltip="Receipt number cannot be edited because the Platform Admin has updated the case status." tooltipPosition="right"></i>
                    <input type="text" formControlName="receiptNumber" (keyup)="onKey($event)"
                        pInputText [pattern]="receiptNumberRegexp" placeholder="ABC0123456789">
                        <small
                        *ngIf="newcaseForm.get('receiptNumber').touched && newcaseForm.get('receiptNumber').errors"
                        class="p-error">
                        <span *ngIf="newcaseForm.get('receiptNumber').errors?.required">{{ getGlobalErrorMessages("REQUIRED") }}</span>
                        <span *ngIf="newcaseForm.get('receiptNumber').errors?.pattern">{{ getGlobalErrorMessages("INVALID_FORMAT") }}</span>
                        </small>
                </div>
            </div>


            <!-- Action Button --Last Row -->
            <div class="row mt-3 mb-5" *ngIf="!editCaseData || (editCaseData && !editCaseData.taskId)">
                <div class="col-12">
                    <button type="submit" class="btn btn-primary"                
                    [disabled]="!newcaseForm.valid" 
                    (click)="saveCase()">Save</button>
                    <button class="btn btn-secondary ml-2" (click)="cancelHandler()"> Cancel</button>
                </div>
            </div>
        </form>
    </div>

</div>