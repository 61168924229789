import { HttpClient } from '@angular/common/http';
import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnoozeService } from '../services/snooze.service';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-snooze',
  templateUrl: './add-snooze.component.html',
  styleUrls: ['./add-snooze.component.scss']
})
export class AddSnoozeComponent implements OnInit {
  addSnoozeForm: FormGroup;
  minimumDate = new Date();
  companyId: number;
  snoozeDate: any;
  snoozeTime: any;
  message: string;
  remindAfter: any;
  active = 1;
  leadSnoozes: any;
  constructor(
    public dialogRef: MatDialogRef<AddSnoozeComponent>,
    private fb: FormBuilder,
    private http: HttpClient,
    public ngZone: NgZone,
    public toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data,
    private snoozeService: SnoozeService) { }

  ngOnInit(): void {
    this.addSnoozeForm = this.fb.group({
      reminderDate: ['', Validators.required],
      reminderDesc: ['', Validators.required],
    });
    this.snoozeService.getSnoozes(parseInt(sessionStorage.getItem('userId'))).subscribe((response: any) => {
      if(response.data!=null){
        this.snoozeService.snoozes = response.data;
        this.leadSnoozes = response.data.filter(snooze => snooze.featureRecordId === this.data.id).reverse();
      }
      });
  }

  save() {
    if (!this.addSnoozeForm.valid) {
      return;
    }
    let reqPayload = this.addSnoozeForm.value;
    reqPayload["reminderTime"] = moment(reqPayload.reminderDate).format('HH:mm:ss');
    reqPayload["reminderDate"] = moment(reqPayload.reminderDate).format('YYYY-MM-DD');
    reqPayload["featureRecordId"] = this.data.id;
    reqPayload["featureType"] = "MRKTLEAD";
    reqPayload["id"] = 0;
    reqPayload["reminderTimeInterval"] = 1;
    reqPayload["reminderTimeUnitId"] = "MINUTE";
    reqPayload["reminderTimeZone"] = Intl.DateTimeFormat().resolvedOptions().timeZone;
    reqPayload["companyId"] = parseInt(sessionStorage.getItem('companyId'));
    this.snoozeService.saveSnooze(reqPayload).subscribe((response: any) => {
      if(response.message === "Saved snooze Successfully."){
      this.addSnoozeForm.reset();
      this.toastr.success(response.message, 'Success');
      this.snoozeService.triggerSnoozes(response.message);

      this.ngZone.run(() => {
        this.dialogRef.close();
      });
    }
      // "Saved snooze Successfully."
      //   this.snoozeService.getSnoozes(parseInt(sessionStorage.getItem('userId'))).subscribe((response) => {
      //     console.log(response);
      // })
    });
  }
  cancelreminder() {
    this.snoozeService.triggerSnoozes('Saved snooze Successfully.');
    this.ngZone.run(() => {
      this.dialogRef.close();
    });
  }
}
