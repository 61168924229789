<div class="container-fluid">
    <div class="row col-12 d-flex justify-content-between">
      <div>
        <div>
            <span (click)="backtoDashboard()"> Home > </span> <span (click)="backtoMangaeBilling()">Manage Billing and
                Invoicing
            </span>
        </div>
        <div class="pt-2">
            <div class="mat-title titleColor font-weight-bold mb-0">Manage Billing and Invoicing</div>
            <div class="mat-subheading-1 titleColor">Manage Invoices and bills sent to client</div>
        </div>

      </div>
      <div class="col-7 d-flex justify-content-between">
        <div style="margin-left: 100px;margin-top: 24px;"class="task-btn">
            <div class="d-flex flex-row">
            <span  style="margin:15px">
            <span *ngIf="!succesResponse"style="margin: 15px"><i [pTooltip]=merchantMsg tooltipPosition="bottom" class="bi bi-clock" style="color:darkgray"></i></span>
            <span *ngIf="succesResponse"><i class="bi bi-check-circle"  [pTooltip]=merchantMsg tooltipPosition="bottom" style="font-size: 20px;color: green; "></i></span>
            </span>
            <!-- <button  type="button" class="btn btn-primary gnt-voice"> <a  [href]=merchandUrl ><p style="color:white; padding: 10px;text-wrap: nowrap;">Sign Up with AffiniPay</p></a></button> -->
           <!-- <span><p style="width: 250px; border-radius: 10px;border: 2px solid #418FE9;">
                <a class="btn-large" [href]=merchandUrl><img style="vertical-align: middle; padding-right: 10px;height: 40px;" src="../../../../assets/AffiniPay-Logo.png">Connect with AffiniPay</a>
        </p></span> -->
        <span><p style="width: 250px; border-radius: 10px;border: 2px solid #418FE9;">
                <a class="btn-large" href="javascript:void(0)" (click)="showAffinipay()"><img style="vertical-align: middle; padding-right: 10px;height: 40px;" src="../../../../assets/AffiniPay-Logo.png">Connect with AffiniPay</a>
        </p></span>
            <p-dialog header=" " [(visible)]="affinipayDialog" [modal]="true" [style]="{ width: '50vw' }">
                <p style="    font-weight: bolder;font-size: x-large;">Connect with AffiniPay</p>
                <hr class="hr" />
                <div class="row">
                <div class="col-3">
                <img src="../../../../assets/AffiniPay-Logo.png" width="300px" height="250">
            </div>
            <div>
                <p style="font-weight: bolder;">Already created an account with Affinipay?</p>
            <p style="width: 160px; border-radius: 10px;border: 2px solid #418FE9;">
                <a style="font-size:larger" class="btn-large" [href]=merchandUrlSignUp>Login Now</a>
        </p>
        <!-- <hr class="dashed-2" />
        <p style="font-weight: bolder;">Account not yet created in Affinipay?</p>
        <p style="width: 250px; border-radius: 10px;border: 2px solid #418FE9;">
                <a style="font-size:larger" class="btn-large" (click)="singUpMerchantpage()">Register with affiniPay</a>
        </p> -->
    </div>
    </div>
        </p-dialog>
    </div>
         </div>
         <div style="margin-top: 25px;">
       
            <div class="task-btn">
               <button  routerLink="../generate-invoice" type="button" class="btn btn-primary gnt-voice"> Generate Invoice</button>
            </div>
          </div>
      </div>
      
    </div>
    
    <p-divider class="pb-15"></p-divider>

    <div class="mat-elevation-z8">
        <div class="row tab-container">
            <div class="col-1 tab-label tab-height p-2">
                <div class="lbl-text align-tabs" [ngClass]="{'activeTimeLine': toggleStatus === 'all'}">
                    <a (click)="getTableHeadersData();toggleMode('all')"> All({{ getAllTabsDataRecordCount }})</a>
                </div>
            </div>
            <div class="col-1 tab-label tab-height p-2">
                <div class="lbl-text align-tabs drafttext" [ngClass]="{'activeTimeLine': toggleStatus === 'draft'}">
                    <a (click)="getDraftTab();toggleMode('draft')"> Drafts({{
                        getAllDraftDataRecordCount }})</a>
                </div>
            </div>
            <div class="col-2 tab-label tab-height p-2">
                <div class="lbl-text align-tabs drafttext" [ngClass]="{'activeTimeLine': toggleStatus === 'payment'}">
                    <a (click)="paymentHistory();toggleMode('payment')">Payment Received History</a>
                </div>
            </div>
            <div class="col fliter-width"></div>
            <div  *ngIf="payment" class="filter col-1 mt-2" >
                <p-overlayPanel class="invoicefilterPanel" #invoicefilterPanel [dismissable]="false"
                    [showCloseIcon]="true">
                    <ng-template pTemplate>
                      <input type="text" pInputText [(ngModel)]="searchInvoice" (ngModelChange)="invoiceFilter($event)" placeholder="Invoice ID" />
                        <div class="d-flex">
                              <span style="padding: 20px;"><p-dropdown [options]="reciveFrom" (onChange)="recivedFromFilter($event)" placeholder="select" optionLabel="name"></p-dropdown>
                              </span>
                              <span style="padding: 20px;"> <input  [(ngModel)]="searchAmount" (ngModelChange)="amountFilter($event)" placeholder="Amount" ></span>
                            </div>
                        <div style="width: 290px;"><span style="padding: 20px;"> <input [(ngModel)]="searchTransactionId" (ngModelChange)="transactionIdFilter($event)" placeholder="Transaction Id" ></span></div>
                    </ng-template>

                </p-overlayPanel>
               
                <span class="pull-right">
                    <button type="text" class="taskFilter-btn clorBtn" pButton label="Filter"
                        (click)="invoicefilterPanel.toggle($event)" icon="pi pi-filter" iconPos="right"> </button>
                </span>
            </div>


            <div *ngIf="!payment" class="filter col-1 mt-2" >
                <p-overlayPanel class="invoicefilterPanel" #invoicefilterPanel [dismissable]="false"
                    [showCloseIcon]="true">
                    <ng-template pTemplate>
                        <div class="row col-md-12 pl-0 pr-0 pb-2">
                            <div class="col-md-6 p-0 pb-2 pr-1">
                                <p-dropdown class="w-100" optionValue="clientId" [options]="clientNameFilterList"
                                    [(ngModel)]="filter.clientName" placeholder="Client Name" optionLabel="clientName"
                                    (onChange)="onClientName(filter.clientName)">
                                </p-dropdown>
                            </div>
                            <div class="col-md-6 p-0 pb-2">
                                <p-dropdown class="w-100" optionValue="statusId" [options]="paymentStatus"
                                    [(ngModel)]="filter.status" placeholder="Status" optionLabel="status"
                                    (onChange)="handleStatusChange(filter.status)">
                                </p-dropdown>
                            </div>
                            <div *ngIf="isFilterstatus" class="text-hour ml-2">
                                Please select the payment status.
                            </div>
                        </div>
                        <div class="row">
                            <label for="">Select Date Range</label>
                        </div>
                        <div class="row col-md-12 pl-0 pr-0 pb-2">

                            <div class="col-md-5 p-0 pb-2">
                                <p-calendar [(ngModel)]="filter.StartDate" placeholder="From Date"
                                    (onSelect)="onStartDate($event)" id="calendar" [showIcon]="true">Start Date
                                </p-calendar>
                            </div>
                            <div class="col-md-5 p-0 pb-2 ml-2">
                                <p-calendar [(ngModel)]="filter.endDate" placeholder="To Date" id="calendar"
                                    [showIcon]="true" (onSelect)="onEndDate($event)" [minDate]="minimumDate">End Date
                                </p-calendar>
                            </div>
                        </div>
                        <mat-divider></mat-divider>
                        <div class="row col-md-12 pt-3">
                            <div class="col-ms-2"></div>
                            <div class="col-md-4 ml-2">
                                <button pButton type="button" label="Cancel" (click)="filterCancel(invoicefilterPanel);"
                                    class="p-button-outlined pull-right"></button>
                            </div>
                            <div class="col-md-2 p-0">
                                <button pButton type="button" label="Apply" [disabled]="isFilterstatus"
                                    (click)="handleFilterChange(filter.status, invoicefilterPanel)"
                                    class="p-button-primary p-button apply-btn"></button>
                            </div>
                            <div class="col-md-2 p-0 clrBtn">
                                <button pButton type="button" label="Clear"
                                    (click)="handleFilterClear(invoicefilterPanel)"
                                    class="p-button-primary p-button apply-btn"></button>
                            </div>
                        </div>
                    </ng-template>
                </p-overlayPanel>
                <div class="pull-right">
                    <button type="text" class="taskFilter-btn clorBtn" pButton label="Filter"
                        (click)="invoicefilterPanel.toggle($event)" icon="pi pi-filter" iconPos="right"> </button>
                </div>
            </div>
        </div>
        <div *ngIf="getAll">
            <div class="tableGrid">
                <p-table [columns]="stepsTypeCols"
                    class="pa-table im-card-no-border-table im-card-table-noborder-spacing table"
                    styleClass="p-datatable-striped" [value]="getAllTabsData" dataKey="clientName"
                    *ngIf="getAllTabsData?.length > 0 ">
                    <ng-template pTemplate="header" let-columns>
                        <tr class="text-uppercase">
                            <th style="width: 3rem"></th>
                            <th class="text-capitalize col-3" *ngFor="let col of columns">
                                {{col.header}}
                            </th>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="body" let-product let-expanded="expanded" let-columns="columns">
                        <tr class="data-style" style="border: 1px solid #EEF4FA;">
                            <td>
                                <button style="height:20px ;" type="button" pButton pRipple [pRowToggler]="product"
                                    class="p-button-text p-button-rounded p-button-plain"
                                    (click)="clientRow(product, '', 'all', 'invoiveList')"
                                    [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-up'"></button>
                            </td>
                            <td style="width: 3rem" class="data-style">{{product.clientName}}</td>
                            <td style="width: 3rem" class="data-style">{{product.communicationEmail}}</td>
                            <td style="width: 3rem" class="data-style">{{product.phoneNumber}}</td>
                            <td style="width: 3rem" class="data-style">{{product.dueDate}}</td>
                            <td style="width: 3rem"><span
                                    [class]="'product-badge status-' + product.paymentStatus">{{product.paymentStatus}}</span>
                            </td>
                            <td style="width: 3rem"></td>
                        </tr>

                    </ng-template>
                    <ng-template pTemplate="rowexpansion" let-product>
                        <tr>
                            <td colspan="7">
                                <div class="p-3">
                                    <p-table [value]="product.invoiceListData" styleClass="p-datatable-striped"
                                        dataKey="invoiceNumber">
                                        <ng-template pTemplate="header">
                        <tr class="h-50">
                            <th class="row-text text-capitalize">Invoice Number </th>
                            <th class="row-text text-capitalize">Generated On</th>
                            <th class="row-text text-capitalize">Due Date </th>
                            <th class="row-text text-capitalize">Payment Date </th>
                            <th class="row-text text-capitalize">Times Resent</th>
                            <th class="row-text text-capitalize">Payment Status </th>
                            <th class="row-text text-capitalize">Invoice Status </th>
                            <th class="row-text text-capitalize">Actions</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-order>
                        <tr class="data-style" style="border: 1px solid #EEF4FA;">
                            <td class="row-text data-style">{{order.invoiceNumber}}</td>
                            <td class="row-text data-style">{{order.createdDate}}</td>
                            <td class="row-text data-style">{{order.dueDate}}</td>
                            <td *ngIf="order.modifiedByUserType === 'Attorney'" class="row-text data-style">
                                {{order.paymentDate}}</td>
                            <td *ngIf="order.modifiedByUserType === 'Petitioner'" class="row-text data-style"></td>
                            <td class="row-text data-style">{{order.reSentCount}}</td>
                            <td class="row-text data-style">
                                <a (click)="showproofofPaymentDialog(order)"><i *ngIf="order.proofPaymentLocation !== null" class="pi pi-paperclip"
                                        ></i></a>
                                <span [class]="'order-badge order-' + order.paymentStatus">
                                    {{order.paymentStatus}}</span>
                            </td>
                            <td *ngIf="order.paymentStatus === 'Pending'" class="row-text data-style"><i
                                    class="email-send"></i></td>
                            <td *ngIf="order.paymentStatus === 'Paid'" class="row-text data-style"><i
                                    class="email-icon"></i></td>
                            <td *ngIf="order.paymentStatus === 'Cancelled'" class="row-text data-style"><i
                                    class="email-not-send"></i></td>
                            <td *ngIf="order.paymentStatus === 'Overdue'" class="row-text data-style"><i
                                    class="email-not-send"></i></td>
                            <td *ngIf="order.paymentStatus === 'Pending'">
                                <div class="row row-icon">
                                    <mat-icon matTooltip="settings menu" class="mr-1 icn settingsBorder"
                                        [matMenuTriggerFor]="menu">
                                        <mat-icon>settings</mat-icon>
                                        <mat-icon>keyboard_arrow_down</mat-icon>
                                    </mat-icon>
                                    <mat-menu #menu="matMenu">
                                        <button mat-menu-item (click)="cancelInvoice(order)">
                                            Cancel sent Invoice</button>
                                        <button mat-menu-item (click)="resendInvoice(order)">
                                            Re-send Invoice</button>
                                        <button mat-menu-item (click)="onViewInvoice(order)">
                                            View</button>
                                        <button mat-menu-item (click)="downloadInvoice(order)">
                                            Download</button>
                                        <button mat-menu-item (click)="updateStatus(order)">
                                            Update Status</button>
                                    </mat-menu>
                                </div>
                            </td>
                            <td *ngIf="order.paymentStatus === 'Overdue'">
                                <div class="row row-icon">
                                    <mat-icon matTooltip="settings menu" class="mr-1 icn settingsBorder"
                                        [matMenuTriggerFor]="menu">
                                        <mat-icon>settings</mat-icon>
                                        <mat-icon>keyboard_arrow_down</mat-icon>
                                    </mat-icon>
                                    <mat-menu #menu="matMenu">
                                        <button mat-menu-item (click)="cancelInvoice(order)">
                                            Cancel sent Invoice</button>
                                        <button mat-menu-item (click)="resendInvoice(order)">
                                            Re-send Invoice</button>
                                        <button mat-menu-item (click)="onViewInvoice(order)">
                                            View</button>
                                        <button mat-menu-item (click)="downloadInvoice(order)">
                                            Download</button>
                                        <button mat-menu-item (click)="updateStatus(order)">
                                            Update Status</button>
                                    </mat-menu>
                                </div>
                            </td>
                            <td *ngIf="order.paymentStatus === 'Paid'">
                                <div class="row row-icon">
                                    <mat-icon matTooltip="settings menu" class="mr-1 icn settingsBorder"
                                        [matMenuTriggerFor]="menu">
                                        <mat-icon>settings</mat-icon>
                                        <mat-icon>keyboard_arrow_down</mat-icon>
                                    </mat-icon>
                                    <mat-menu #menu="matMenu">
                                        <button mat-menu-item (click)="onViewInvoice(order)">
                                            View</button>
                                        <button mat-menu-item (click)="updateStatus(order)">
                                            Update Status</button>
                                    </mat-menu>
                                </div>
                            </td>
                            <td *ngIf="order.paymentStatus === 'Cancelled'">
                                <div class="row row-icon">
                                    <mat-icon matTooltip="settings" class="mr-1 icn settingsBorder">
                                        <mat-icon>settings</mat-icon>
                                        <mat-icon>keyboard_arrow_down</mat-icon>
                                    </mat-icon>
                                </div>
                            </td>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="6">There are no invoices for this client.</td>
                        </tr>
                    </ng-template>
                </p-table>
                <p-paginator [rows]="pageSize" (onPageChange)="paginationEvent($event, product, 'all', 'invoiveList')"
                    *ngIf="totalListElementCount > 0" [totalRecords]="totalListElementCount"
                    [rowsPerPageOptions]="pageSizeOptions" length="totalListElementCount">
                </p-paginator>
            </div>
            </td>
            </tr>
            </ng-template>

            </p-table>
        </div>
        <div *ngIf="getAllTabsData?.length == 0" class="mt-4 text-center">No Data</div>
        <p-paginator [rows]="pageSize" (onPageChange)="allTabspaginationEvent($event, 'all')"
            *ngIf="getAllTabsData?.length > 0" [totalRecords]="totalAllElementCount"
            [rowsPerPageOptions]="pageSizeOptions" length="totalAllElementCount">
        </p-paginator>
    </div>
    <div *ngIf="payment" class="tableGrid">
        <p-table [columns]="paymentCol" [paginator]="true" [rows]="10" class="pa-table im-card-no-border-table im-card-table-noborder-spacing table"
            styleClass="p-datatable-striped" [value]="getAllPaymentHis" dataKey="clientName"
            *ngIf="getAllPaymentHis?.length > 0 ">
            <ng-template pTemplate="header" let-columns>
                <tr class="text-uppercase">
                    <th *ngFor="let col of columns" class="text-capitalize">
                        {{col.header}}
                    </th>

                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr style="border: 1px solid #EEF4FA;">
                    <td style="width: 3rem">{{rowData?.invoiceid}}</td>
                    <td style="width: 3rem">{{rowData?.invoiceFrom}}</td>
                    <td style="width: 3rem">{{rowData?.paymentDate}}</td>
                    <td style="width: 3rem">{{rowData?.transactionId}}</td>
                    <td style="width: 3rem"><img src="../../../../assets/images/invoice/dolar-sign.svg"
                        class="dollar img-style" style="width:12px" />{{rowData?.amount}}</td>
                </tr>

            </ng-template>
    </p-table>
    <div *ngIf="getAllPaymentHis?.length == 0" class="mt-4 text-center">No Data</div>
    <p-paginator [rows]="pageSize" (onPageChange)="allTabspaginationEvent($event, 'draft')"
        *ngIf="getAllPaymentHis?.length > 0" [totalRecords]="totalAllElementCount" [rowsPerPageOptions]="pageSizeOptions"
        length="totalAllElementCount">
    </p-paginator>
</div>

    <div *ngIf="isDraft" class="tableGrid">
        <p-table [columns]="stepsTypeCols" class="pa-table im-card-no-border-table im-card-table-noborder-spacing table"
            styleClass="p-datatable-striped" [value]="getAllDraftData" dataKey="clientName"
            *ngIf="getAllDraftData?.length > 0 ">
            <ng-template pTemplate="header" let-columns>
                <tr class="text-uppercase">
                    <th style="width: 3rem"></th>
                    <th *ngFor="let col of columns" class="text-capitalize">
                        {{col.header}}
                    </th>
                    <th style="width: 3rem"></th>
                    <th style="width: 3rem"></th>
                    <th style="width: 3rem"></th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-product let-expanded="expanded" let-columns="columns">
                <tr style="border: 1px solid #EEF4FA;">
                    <td>
                        <button style='height:20px' type="button" pButton pRipple [pRowToggler]="product"
                            class="p-button-text p-button-rounded p-button-plain"
                            (click)="clientRow(product, '', 'draft', 'invoiveList')"
                            [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-up'"></button>
                    </td>
                    <td style="width: 3rem">{{product.clientName}}</td>
                    <td style="width: 3rem">{{product.communicationEmail}}</td>
                    <td style="width: 3rem">{{product.phoneNumber}}</td>
                    <td style="width: 3rem"></td>
                    <td style="width: 3rem"></td>
                    <td style="width: 3rem"></td>
                </tr>

            </ng-template>
            <ng-template pTemplate="rowexpansion" let-product>
                <tr>
                    <td colspan="7">
                        <div class="p-3">
                            <p-table [value]="product.invoiceListData" styleClass="p-datatable-striped"
                                dataKey="invoiceNumber">
                                <ng-template pTemplate="header">
                <tr class="h-50" style="border: 1px solid #EEF4FA;">
                    <th style="width: 3rem"></th>
                    <th class="row-text text-capitalize">Invoice Number </th>
                    <th class="row-text text-capitalize">Generated On</th>
                    <th class="row-text text-capitalize">Last Date </th>
                    <th class="row-text text-capitalize">Actions</th>
                    <th style="width: 3rem"></th>
                    <th style="width: 3rem"></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-order>
                <tr style="border: 1px solid #EEF4FA;">
                    <td style="width: 3rem"></td>
                    <td class="row-text">{{order.invoiceNumber}}</td>
                    <td class="row-text">{{order.createdDate}}</td>
                    <td class="row-text">{{order.dueDate}}</td>

                    <td>
                        <div class="row row-icon">
                            <mat-icon matTooltip="settings menu" class="mr-1 icn settingsBorder"
                                [matMenuTriggerFor]="menu">
                                <mat-icon>settings</mat-icon>
                                <mat-icon>keyboard_arrow_down</mat-icon>
                            </mat-icon>
                            <mat-menu #menu="matMenu">
                                <button mat-menu-item (click)="resendInvoice(order)">
                                    Send Invoice</button>
                                <button mat-menu-item (click)="onViewInvoice(order)">
                                    View Invoice</button>
                                <button mat-menu-item (click)="editInvoice(order)">
                                    Edit Invoice</button>
                                <button mat-menu-item (click)="deleteInvoiceList(order)">
                                    Delete Invoice</button>
                            </mat-menu>
                        </div>
                    </td>
                    <td style="width: 3rem"></td>
                    <td style="width: 3rem"></td>
                </tr>
            </ng-template>

            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="6">There are no invoices for this client.</td>
                </tr>
            </ng-template>
        </p-table>
        <p-paginator [rows]="pageSize" (onPageChange)="paginationEvent($event, product, 'draft', 'invoiveList')"
            *ngIf="totalDraftListElementCount > 0" [totalRecords]="totalDraftListElementCount"
            [rowsPerPageOptions]="pageSizeOptions" length="totalDraftListElementCount">
        </p-paginator>
    </div>
    </td>
    </tr>
    </ng-template>

    </p-table>
    <div *ngIf="getAllTabsData?.length == 0" class="mt-4 text-center">No Data</div>
    <p-paginator [rows]="pageSize" (onPageChange)="allTabspaginationEvent($event, 'draft')"
        *ngIf="getAllTabsData?.length > 0" [totalRecords]="totalAllElementCount" [rowsPerPageOptions]="pageSizeOptions"
        length="totalAllElementCount">
    </p-paginator>
</div>
</div>
</div>
<p-dialog header="Resend Invoice" [(visible)]="resendcancelInvoiceDialog" [modal]="true" [style]="{width: '50vw'}"
    [draggable]="false" [resizable]="false">
    <div class="divider">

    </div>
    <div class="row mt-2">
        <i class=" pi pi-exclamation-triangle" style="color:orange ; font-size: 1.5rem;"></i>
        <p>You can re-send only 3 invoices at a time</p> <br>

    </div>
    <div class="row mt-1 ml-2" *ngIf="resentCount === 1">
        <p>You have already sent 1. You are now permitted to send only 2 invoices. </p>
    </div>

    <div class="row mt-1 ml-2" *ngIf="resentCount > 1">
        <p>Do you wish to resend this invoice now ?</p>
    </div>
    <div class="divider mt-3">

    </div>

    <div class="row d-flex justify-content-end mt-2">

        <button type="button" [disabled]="false" class="btn btn-light" (click)="cancelpaymentStatus()">Cancel</button>
        <button class="btn btn-primary ml-2" (click)="resend()"> Yes , Send it</button>

    </div>
</p-dialog>
<p-dialog header="Cancel send Invoice" [(visible)]="cancelInvoiceDialog" [modal]="true" [style]="{width: '50vw'}"
    [draggable]="false" [resizable]="false">
    <div class="divider">

    </div>
    <div class="row mt-2">
        <h5>If you cancel the invoice will no longer will be visible to the client</h5> <br>
        <p> Are you sure want to <span class="cancel"> Cancel?</span></p>
    </div>
    <div class="divider mt-3 mb-3">

    </div>

    <div class="row d-flex justify-content-end">


        <button type="button" class="btn btn-danger" (click)="invoiceCancel()">Yes , Cancel it</button>

    </div>
</p-dialog>
<!--update status dialog-->

<p-dialog header="{{updateDeleteHeader}}" [(visible)]="updatePaymentStatusDialog" [modal]="true"
    [style]="{width: '60vw'}" [draggable]="false" [resizable]="false" id="update-payment">

    <div class="divider mt-3 mb-3">

    </div>
    <div *ngIf="!deletePaymentDialog">
        <div class="row mt-2 nopadding">
            Have you received payment for this invoice?
        </div>
        <div class="row mt-2">
            <p>Received payment</p>
        </div>
        <div class="row mt-2">
            <div class="col-4 nopadding">
                <p-radioButton class='mr-1' name="groupname" value="1" label="Yes" [(ngModel)]="Updatepayment"
                    (click)="updatechangeType()">
                </p-radioButton>
                <p-radioButton class="ml-3" name="groupname" value="0" label="No" [(ngModel)]="Updatepayment"
                    (click)="updatechangeType()">
                </p-radioButton>
            </div>
            <div class="col-8">

            </div>
        </div>

        <form *ngIf="viewPaymentpage" [formGroup]="clientPaymentForm">

            <div class="row mt-5 mb-5">
                <div class="col-6  nopadding">
                    <label class="label-cls required"> Payment done on?</label>
                    <div style="margin-top:30px ;">
                        <p-calendar [(ngModel)]="paymentDate" [showIcon]="true" inputId="icon"
                            formControlName="paymentDate" appendTo="body"></p-calendar>
                    </div>
                </div>
                <div  class="col-6 nopadding">
                    <label class="label-cls required mb-2">Mode of Payment</label>
                    <div style="margin-top: 20px ;"></div>
                    <p-dropdown class="w-100" placeholder='select payment mode' [options]="paymentmodeType"
                        optionValue="code" optionLabel="name" [(ngModel)]="paymentMode" formControlName="paymentMode" (onChange)="paymentmodeTypechanged($event)" appendTo="body">
                    </p-dropdown>
                </div>

            </div>
            <div class="row mt-3 mb-5">
                <div *ngIf="!cashMode" class="col-6 mt-2 nopadding">
                    <p class="label-cls required ">Reference Number</p>
                    <input type="text" [(ngModel)]="referenceNumber" formControlName="referenceNumber">

                </div>
                <div class="col-6 mt-4">
                    <button class="btn btn-primary ml-2" (click)="updatepaymnetStatus()"
                        [disabled]="!this.clientPaymentForm.valid"> save</button>
                </div>

            </div>
            <div class="divider mt-2 mb-2">

            </div>

        </form>

        <div class=" row ml-2" *ngIf="!viewPaymentpage">
            <div class="col-6 mb-2 nopadding">
                <i class=" pi pi-exclamation-triangle pt-4" style="color:orange ; font-size: 1.5rem;"></i>
                <span class="ml-2 heading-text">Oh thats not good</span><br>
                <div>Take next steps to receive due payment</div>
            </div>
            <div class="col-6 mt-4 pt-4">
                <button type="button" class="btn btn-outline-primary" (click)="notifyClient()">Notify Client</button>
            </div>
        </div>
    </div>
    <div *ngIf="deletePaymentDialog" class="ml-5">
        <div class="row mt-2">
            <p>You are about to delete the invoice for</p>
        </div>
        <div>
            <strong>Payment</strong> - <span>{{grandTotalAmount}}</span>
        </div>
        <div>
            <strong>Due Date</strong> - <span>{{deleteDueDate}}</span>
        </div>
        <div class="mt-5">
            <strong>If you delete it you will not be able to recover it again</strong>
        </div>
        <div class="mt-4">
            <p>Are you sure you want to <span class="text-color"> delete?</span></p>
        </div>
        <div class="divider">

        </div>
        <div class="row">
            <div class="col-3 mt-4 align-lft">
                <button class="btn btn-primary ml-2 " (click)="deleteCancel('cancel')"> Cancel</button>
            </div>
            <div class="d-flex justify-content-end mt-4">
                <button type="button" class="btn-delete" (click)="deleteCancel('delete')">Yes, Delete it</button>
            </div>
        </div>

    </div>
</p-dialog>

<!--download popup-->
<p-dialog header="Do you want to Download Invoice" [(visible)]="downloadInvoiceDialog" [modal]="true"
    [style]="{width: '50vw'}" [draggable]="false" [resizable]="false">
    <div class="row  justify-content-center d-flex">
        <button type="button" class="btn btn-primary" (click)="taskcomplete('yes')">Yes</button>
        <button type="button" class="btn btn-primary ml-4" (click)="taskcomplete('no')">No</button>
    </div>

</p-dialog>
<p-dialog header="{{sendInvoiceTitle}} " [(visible)]="viewInvoiceDialog" *ngIf="viewInvoiceDialog" [modal]="true"
    [style]="{width: '90vw'}" [draggable]="false" [resizable]="false">

    <div class="divider">

    </div>
    <div class="divider-dashed">

    </div>
    <app-preview [invoiceId]="invoiceId" [title]="sendInvoiceTitle"></app-preview>
    <div class="row mt-3" *ngIf="resendInvoiceDialog">
        <button type="button" class="btn btn-primary" (click)="resentInvoice('invoiveList')">Resend</button>
        <button type="button" (click)="cancelpaymentStatus()" class="btn btn-outline-primary ml-3">cancel</button>
    </div>
</p-dialog>
