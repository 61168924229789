import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { I9EverificationService } from '../modules/i9-evaluators/i9-everification.service';

@Component({
  selector: 'app-callback',
  template: `
    <div *ngIf="loading">Processing...</div>
    <div *ngIf="error">{{ error }}</div>
  `
})
export class CallbackComponent implements OnInit {

  loading = true;
  error: string;

  constructor(
    private route: ActivatedRoute,
    private i9Service: I9EverificationService
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      const code = params['code']; // Extract authorization code from query parameters
      if (code) {
        this.i9Service.getZoomAccessToken(code).subscribe((response) => {
            this.setCookieforZoomAccess(response); // Complete authentication process
        })
      } else {
        this.error = 'Authorization code not found.';
        this.loading = false;
      }
    });
  }

  setCookieforZoomAccess(value) {
    let date = new Date();
    date.setTime(date.getTime() + (0.5 * 60 * 60 * 1000));
    let expires = "expires=" + date.toUTCString();
    document.cookie = 'zoomToken' + "=" + value + ";" + expires + ";path=/";
    localStorage.setItem('zoomTokenUpdated', new Date().toISOString());
    if (value) {
      window.close();
    }
  }

}
