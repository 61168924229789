import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { ReferenceLibraryService } from 'reference-library';

@Component({
  selector: 'app-immigration-timeline',
  templateUrl: './immigration-timeline.component.html',
  styleUrls: ['./immigration-timeline.component.scss']
})
export class ImmigrationTimelineComponent implements OnInit, AfterViewInit {
  @Input() immigrationData;
  @Input() showEditBtn;
  constructor(private router: Router, private referenceLibraryService: ReferenceLibraryService) { }

  ngOnInit() {
    
  }
  routeToProfile(){
  this.router.navigate(['beneficiary-landing/beneficiaries/profile/immigration',{listview:true}]);
  }

  ngAfterViewInit() {
    this.translet(); 
  }

  translet(){
    setTimeout(() => {
      this.referenceLibraryService.transletConversion();   
    }, 600);  
  }

}
