<div class="bene-quest-container" *ngIf="isAcceptEConsent">
  <div class="bene-quest-card" style="height: 100vh;">
    <header class="d-flex align-items-center justify-content-between">
      <div class="d-flex align-items-center justify-content-start">
        <img class="attorneyLogo" *ngIf="companyLogoImage!==null" [src]="companyLogoImage">
        <div class="attorneyLogo" *ngIf="companyLogoImage===null">{{companyName}}</div>
        <div class="bene-quest-vr"></div>
        <div class="poweredbyLogo-wrapper">
          <small class="bene-poweredbyLogotext">Powered By</small>
          <img class="poweredbyLogo" src="../../../assets/weblogo.png">
        </div>
      </div>
    </header>
    <h3 class="mt-4">You have denied the E-Consent Acknowledgement. Please accept to continue</h3>
    <button type="button" class="btn btn-primary mt-5" (click)="backToEConsent()">GO BACK</button>
  </div>
</div>

<p-dialog [header]="eConsentData?.econsentTitle" [modal]="true" [closable]="false" [(visible)]="isEConsentModalVisible"
  [style]="{ width: '50rem' }">
  <hr class="mt-0" />
  <p class="my-3">{{eConsentData?.econsentDesc}}</p>
  <p class="my-3">
    <button type="button" class="btn btn-primary mr-2" (click)="acceptEConsent()">ACCEPT</button>
    <button type="button" class="btn btn-outline-danger" (click)="denyEConsent()">DENY</button>
  </p>
</p-dialog>