<div>

    <div class="row p-3">
        <h5 class="rowHeading p-3">Personal Information <span class="subHeading">( Fetched from Profile data
                )</span><br>
            <span class="subHeading">Personal and address details are fetched from the Beneficiary profile. To update
                information, go to the Beneficiary profile menu and make relevant changes.</span>

        </h5>
        <div class="col-md-3">
            <label>First Name</label>
            <label class="viewDetails">{{section1Info?.beneficiaryDetails?.firstName}}</label>
            <!-- <input type="text" > -->
        </div>
        <div class="col-md-3">
            <label>Last Name</label>
            <label class="viewDetails">{{section1Info?.beneficiaryDetails?.lastName}}</label>
            <!-- <input type="text" > -->
        </div>
        <div class="col-md-3">
            <label>Middle Name</label>
            <label class="viewDetails">{{section1Info?.beneficiaryDetails?.middleName}}</label>
            <!-- <input type="text" > -->
        </div>
    </div>

    <div class="row p-3">
        <div class="col-md-3">
            <label>Date of Birth</label>
            <label class="viewDetails">{{section1Info?.beneficiaryDetails?.dob | date: 'MM/dd/yyyy'}}</label>
            <!-- <input type="text" > -->
        </div>
        <div class="col-md-6">
            <label>U.S. Social Security Number</label>
            <label class="viewDetails">{{section1Info?.beneficiaryDetails?.ssn}}</label>
            <!-- <input type="text" > -->
        </div>

    </div>


    <div class="row border-bottom-dashed py-3">
        <div class="col-md-3 px-0">
            <label>Mobile No.</label>
            <label class="viewDetails">{{mobileNumber}}</label>
            <!-- <input type="text" > -->
        </div>
        <div class="col-md-6">
            <label>Email ID</label>
            <label class="viewDetails">{{primaryEmail}}</label>
            <!-- <input type="text" > -->
        </div>

    </div>

    <div class="row p-3">
        <h5 class="rowHeading p-3">Address Details</h5>
        <div class="row col-md-12 p-0">
            <div class="col-md-8">
                <label>Address 1</label>
                <label class="viewDetails">{{currentAddressObj?.addressLine1 || '--'}}</label>    
            </div>
            <div class="col-md-4">
                <label>Address 2</label>
                <label class="viewDetails">{{currentAddressObj?.addressLine2 || '--'}}</label>
            </div>
        </div>
    </div>

    <div class="row col-md-12 p-3">
        <div class="col-md-4">
            <label>City</label>
            <label class="viewDetails">{{currentAddressObj?.city || '--'}}</label>
        </div>
        <div class="col-md-4">
            <label>State</label>
            <label class="viewDetails">{{currentAddressObj?.stateProvinceName || '--'}}</label>
        </div>
        <div class="col-md-4">
            <label>ZIP / Postal code</label>
            <label class="viewDetails">{{currentAddressObj?.postCode || '--'}}</label>
        </div>
    </div>
    <!-- <div class="row py-3 border-bottom-dashed">
        <div class="col-md-3 px-0">
            <label>Locality</label>
            <label class="viewDetails">{{currentAddressObj?.locality || '--'}}</label>
        </div>
        <div class="col-md-3">
            <label>ZIP / Postal code</label>
            <label class="viewDetails">{{currentAddressObj?.postCode || '--'}}</label>
        </div>
    </div> -->

    <!-- <div class="row p-3 border-bottom-solid">
        <h5 class="rowHeading p-3" style="width: 100%;">Digital Signature</h5>
        
        <div class="col-md-3">
                <label class="d-block">Signature of Employee</label>
                <img  *ngIf='beneSignature' class = "signatureImageBox p-0" [src]="beneSignature">
                
        </div>
        <div class="col-md-3">
                <label>Date</label>
                <label class="viewDetails">{{today | date: 'MM/dd/yyyy'}}</label>
        </div> 
    </div> -->
    <div class="row p-3">
        <h5 class="rowHeading p-3">Digital Signature</h5>
    </div>
    <div class="row p-3" *ngIf="isEdit" [class.border-bottom-dashed]="!beneSignature">
        <div class="col-3 signature-upload">
            <p></p>
            <button pButton pRipple type="button" label="Upload Now!" icon="pi pi-upload" class="p-button-outlined"
                (click)="selectSignatureImage()"></button>
        </div>
        <!-- <div class="col-3">
          <p class="label-cls">Date</p>
          <p-calendar [(ngModel)]="beneSignedDate" [monthNavigator]="true" 
            [yearNavigator]="true" [showIcon]="true" 
            inputId="signeddate" [disabled]="!isEdit" dateFormat="mm-dd-yy" 
            required>
          </p-calendar>
        </div> -->
    </div>
    <div class="row mt-2 mb-2 border-bottom-dashed" *ngIf="beneSignature">
        <div class="col-6">
            <img class="signatureImageBox" [src]="beneSignature">
        </div>
        <div class="col-3">
            <label>Date</label>
            <label class="viewDetails">{{beneSignedDate}}</label>
        </div>
    </div>

</div>

<!-- <div class="row m-1" *ngIf="isEdit">
        <form [formGroup]="beneSelfDetailsForm" class="col-12" #f="ngForm">
            <div class="row">
              <label class="mt-2 main-label"> Personal Information</label>
            </div>
            <div class="row mt-2">        
              <div class="col-3">
                <p class="label-cls required">First Name</p>
                <input type="text" [readonly]="!isEdit" class="im-input" [ngClass]="{
                    'im-input-error':
                      (beneSelfDetailsForm.get('firstName').touched || submitted) &&
                      beneSelfDetailsForm.get('firstName').errors?.required
                  }" pattern="^[a-zA-Z ]*$" formControlName="firstName" placeholder="Enter your First Name" />
                <span class="invalid-message" *ngIf="
                    (beneSelfDetailsForm.get('firstName') || submitted) &&
                    beneSelfDetailsForm.get('firstName').errors?.required
                  ">
                  {{ getGlobalErrorMessages("REQUIRED") }}
                </span>
                <span class="invalid-message" *ngIf="
                    (beneSelfDetailsForm.get('firstName').touched || submitted) &&
                    beneSelfDetailsForm.get('firstName').errors?.pattern
                  ">
                  {{ getGlobalErrorMessages("ALPHABETS_ONLY") }}
                </span>
              </div>
              <div class="col-3">
                <p class="label-cls required">Last Name</p>
                <input type="text" [readonly]="!isEdit" class="im-input" [ngClass]="{
                    'im-input-error':
                      (beneSelfDetailsForm.get('lastName').touched || submitted) &&
                      (beneSelfDetailsForm.get('lastName').errors?.required)
                  }" pattern="^[a-zA-Z ]*$" formControlName="lastName" placeholder="Enter your Last Name" />
                <span class="invalid-message" *ngIf="
                    (beneSelfDetailsForm.get('lastName').touched || submitted) &&
                    (beneSelfDetailsForm.get('lastName').errors?.required)
                  ">
                  {{ getGlobalErrorMessages("REQUIRED") }}
                </span>
                <span class="invalid-message" *ngIf="
                    (beneSelfDetailsForm.get('lastName').touched || submitted) &&
                    (beneSelfDetailsForm.get('lastName').errors?.pattern)
                  ">
                  {{ getGlobalErrorMessages("ALPHABETS_ONLY") }}
                </span>
              </div>
              <div class="col-3">
                <p class="label-cls">Middle Name</p>
                <input type="text" [readonly]="!isEdit" class="im-input" [ngClass]="{
                    'im-input-error':
                      (beneSelfDetailsForm.get('middleName').touched || submitted) &&
                      beneSelfDetailsForm.get('middleName').errors?.pattern
                  }" pattern="^[a-zA-Z ]*$" formControlName="middleName" placeholder="Enter your Middle Name" />
                <span class="invalid-message" *ngIf="
                    (beneSelfDetailsForm.get('middleName').touched || submitted) &&
                    beneSelfDetailsForm.get('middleName').errors?.pattern
                  ">
                  {{ getGlobalErrorMessages("ALPHABETS_ONLY") }}
                </span>
              </div>        
            </div>  
            
            <div class="row border-bottom-dashed mt-4">
            </div>
            <div class="row mt-4">
              <div class="col-12">
                <label class="mt-2 main-label">Address Details</label>
              </div>
            </div>
            <div formArrayName="benAddress">
              <div [formGroupName]="0">
                <div class="row mt-2">
                  <div class="col-9">
                    <p class="label-cls required">Address 1</p>
                    <input type="text" class="im-input col-md" [ngClass]="{
                        'im-input-error':
                          (submitted ||
                          beneSelfDetailsForm
                              .get('benAddress')
                              ['controls']['0'].get('addressLine1').touched) &&
                              beneSelfDetailsForm
                            .get('benAddress')
                            ['controls']['0'].get('addressLine1').errors?.required
                      }" formControlName="addressLine1" placeholder="Address 1" maxLength="99" />
                    <span class="invalid-message" *ngIf="
                        (submitted ||
                        beneSelfDetailsForm
                            .get('benAddress')
                            ['controls']['0'].get('addressLine1').touched) &&
                            beneSelfDetailsForm
                          .get('benAddress')
                          ['controls']['0'].get('addressLine1').errors?.required
                      ">
                      {{ getGlobalErrorMessages("REQUIRED") }}
                    </span>
                  </div>
                </div>
                 <div class="row mt-2">
                  <div class="col-9">
                    <p class="label-cls">Address 2</p>
                    <input type="text" [readonly]="!isEdit" class="im-input col-md" [ngClass]="{
                        'im-input-error':
                          submitted &&
                          beneSelfDetailsForm
                            .get('benAddress')
                            ['controls']['0'].get('addressLine2').errors?.required
                      }" formControlName="addressLine2" placeholder="Address 2" maxLength="99" />
                    <span class="invalid-message" *ngIf="
                        submitted &&
                        beneSelfDetailsForm
                          .get('benAddress')
                          ['controls']['0'].get('addressLine2').errors?.required
                      ">
                      {{ getGlobalErrorMessages("REQUIRED") }}
                    </span>
                  </div>
                </div>
                <div class="row mt-2">            
                  <div class="col-3" formGroupName="countryCode">
                    <p class="label-cls required">Country</p>
                    <select class="im-input col-md" [attr.disabled]="!isEdit? true:null" formControlName="countryCode" (change)="
                        handleCountryChange($event.target.value)
                      ">
                      <option *ngFor="let code of listCountry" [value]="code.countryCode">
                        {{ code.countryName }}
                      </option>
                    </select>             
                    <span class="invalid-message" *ngIf="
                        (submitted ||
                        beneSelfDetailsForm
                            .get('benAddress')
                            ['controls']['0'].get('countryCode').touched) &&
                            beneSelfDetailsForm
                          .get('benAddress')
                          ['controls']['0'].get('countryCode')
                          .get('countryCode').errors?.required
                      ">
                      {{ getGlobalErrorMessages("REQUIRED") }}
                    </span>
                  </div>
                  <div class="col-3" *ngIf="listStates.length > 0">
                    <p class="label-cls required">State</p>
                    <select class="im-input col-md" [attr.disabled]="!isEdit? true:null" formControlName="stateProvinceCode" placeholder="Select State"
                      (change)="handleStateChange($event.target.value, 0)">
                      <option *ngFor="let item of listStates" [value]="item.stateProvinceCode">
                        {{ item.stateProvinceName }}
                      </option>
                    </select>
                    <span class="invalid-message" *ngIf="
                        (submitted ||
                        beneSelfDetailsForm
                            .get('benAddress')
                            ['controls']['0'].get('stateProvinceCode').touched) &&
                            beneSelfDetailsForm
                          .get('benAddress')
                          ['controls'][0].get('stateProvinceCode').errors?.required
                      ">
                      {{ getGlobalErrorMessages("REQUIRED") }}
                    </span>
                  </div>
                  <div class="col-3" *ngIf="listStates.length <= 0">
                    <p class="label-cls">State</p>
                    <input class="im-input" [readonly]="!isEdit" [ngClass]="{
                        'im-input-error':
                          (submitted ||
                          beneSelfDetailsForm
                              .get('benAddress')
                              ['controls']['0'].get('stateProvinceName').touched) &&
                              beneSelfDetailsForm
                            .get('benAddress')
                            ['controls']['0'].get('stateProvinceName').errors?.required
                      }" formControlName="stateProvinceName" type="text" placeholder="State" />
                    <span class="invalid-message" *ngIf="
                        (submitted ||
                        beneSelfDetailsForm
                            .get('benAddress')
                            ['controls']['0'].get('stateProvinceName').touched) &&
                            beneSelfDetailsForm
                          .get('benAddress')
                          ['controls']['0'].get('stateProvinceName').errors?.required
                      ">
                      {{ getGlobalErrorMessages("REQUIRED") }}
                    </span>
                  </div>
                  <div class="col-3">
                    <p class="label-cls required">City</p>
                    <input class="im-input" [readonly]="!isEdit" [ngClass]="{
                        'im-input-error':
                          (submitted ||
                          beneSelfDetailsForm
                              .get('benAddress')
                              ['controls']['0'].get('city').touched) &&
                              beneSelfDetailsForm
                            .get('benAddress')
                            ['controls']['0'].get('city').errors?.required
                      }" formControlName="city" type="text" placeholder="City" />
                    <span class="invalid-message" *ngIf="
                        (submitted ||
                        beneSelfDetailsForm
                            .get('benAddress')
                            ['controls']['0'].get('city').touched) &&
                            beneSelfDetailsForm.get('benAddress')['controls']['0'].get('city')
                          .errors?.required
                      ">
                      {{ getGlobalErrorMessages("REQUIRED") }}
                    </span>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-4">
                    <p class="label-cls">Locality</p>
                    <input class="im-input" [readonly]="!isEdit" formControlName="locality" type="text" placeholder="Area" />
                  </div>            
                  <div class="col-2">
                    <p class="label-cls required">Zip / Postal Code</p>
                    <input class="im-input" [readonly]="!isEdit" [ngClass]="{
                        'im-input-error':
                          ((submitted ||
                          beneSelfDetailsForm
                              .get('benAddress')
                              ['controls']['0'].get('postCode').touched) &&
                              beneSelfDetailsForm
                              .get('benAddress')
                              ['controls']['0'].get('postCode').errors?.pattern) ||
                          (submitted &&
                          beneSelfDetailsForm
                              .get('benAddress')
                              ['controls']['0'].get('postCode').errors?.required)
                      }" formControlName="postCode" type="text" placeholder="Zip Code" maxLength="10" />
                    <span class="invalid-message" *ngIf="
                        (submitted ||
                        beneSelfDetailsForm
                            .get('benAddress')
                            ['controls']['0'].get('postCode').touched) &&
                        beneSelfDetailsForm
                          .get('benAddress')
                          ['controls']['0'].get('postCode').errors?.required
                      ">
                      {{ getGlobalErrorMessages("REQUIRED") }}
                    </span>
                    <span class="invalid-message" *ngIf="
                        (submitted ||
                        beneSelfDetailsForm
                            .get('benAddress')
                            ['controls']['0'].get('postCode').touched) &&
                            beneSelfDetailsForm
                          .get('benAddress')
                          ['controls']['0'].get('postCode').errors?.pattern
                      ">
                      {{ getGlobalErrorMessages("ZIPCODE_ONLY") }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row border-bottom-dashed mt-4">
            </div>
            <div class="row mt-2">
              <div class="col-12">
                <label class="mt-2 main-label">Signature of Preparer or Translator</label>
              </div>        
            </div>    
            <div class="row mt-2">
                <div class="col-3 signature-upload">  
                  <p></p>          
                  <button pButton pRipple type="button" label="Upload Now!" icon="pi pi-upload" class="p-button-outlined" (click)="selectSignatureImage()"></button>  
                </div>
                <div class="col-3">
                  <p class="label-cls">Date</p>
                  <p-calendar  formControlName="beneficiarySignDate"
                   [showIcon]="true" dateFormat="mm-dd-yy"
                  inputId="signDate" required></p-calendar>
              <span class="invalid-message"
                  *ngIf="(beneSelfDetailsForm.get('beneficiarySignDate').touched || beneSelfDetailsForm.get('beneficiarySignDate').dirty) && beneSelfDetailsForm.get('beneficiarySignDate').errors?.required">
                  {{ getGlobalErrorMessages("REQUIRED") }}</span>
                </div>
            </div>
            <div class="row mt-2" *ngIf="signatureImage">
              <div class="col-6">
                <img class="signatureImageBox" [src]="signatureImage">
               </div>
            </div>
            <div class="row border-bottom-dashed mt-4">
            </div>
            <div class="row mt-2">
                    <div class="col-6" formArrayName="benPhone">
                        <div class="row" [formGroupName]="0">
                            <div class="col-4">
                                <p class="label-cls  required">Country Code</p>
                                <select class="im-input col-md phoneCodeSelect" formControlName="countryCode">
                                  <option *ngFor="let code of listCountry" [value]="code.countryCode">
                                    {{ code.countryName }} - {{ code.phoneCode }}
                                  </option>
                                </select>
                            </div>
                            <div class="col">
                                <p class="label-cls required">Phone Number</p>
                                <input type="text" class="im-input col-md" [ngClass]="{
                                    'im-input-error':
                                      (submitted ||
                                      beneSelfDetailsForm
                                          .get('benPhone')
                                          ['controls']['0'].get('phoneNo').touched) &&
                                          beneSelfDetailsForm
                                        .get('benPhone')
                                        ['controls']['0'].get('phoneNo').errors?.required &&
                                        beneSelfDetailsForm
                                        .get('benPhone')
                                        ['controls']['0'].get('phoneNo').errors?.pattern
                                  }" formControlName="phoneNo" placeholder="Phone No" />
                                <span class="invalid-message" *ngIf="
                                    (submitted ||
                                    beneSelfDetailsForm
                                        .get('benPhone')
                                        ['controls']['0'].get('phoneNo').touched) &&
                                        beneSelfDetailsForm
                                      .get('benPhone')
                                      ['controls']['0'].get('phoneNo').errors?.required
                                  ">
                                  {{ getGlobalErrorMessages("REQUIRED") }}
                                </span>
                                <span class="invalid-message" *ngIf="
                                    (submitted ||
                                    beneSelfDetailsForm
                                        .get('benPhone')
                                        ['controls']['0'].get('phoneNo').touched) &&
                                        beneSelfDetailsForm
                                      .get('benPhone')
                                      ['controls']['0'].get('phoneNo').errors?.pattern
                                  ">
                                  {{ getGlobalErrorMessages("REQUIRED") }}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <p class="label-cls required">Phone Number</p>
                        <input type="text" class="im-input col-md" [ngClass]="{
                            'im-input-error':
                              (submitted ||
                              beneSelfDetailsForm
                                  .get('benPhone')
                                  ['controls']['0'].get('phoneNo').touched) &&
                                  beneSelfDetailsForm
                                .get('benPhone')
                                ['controls']['0'].get('phoneNo').errors?.required &&
                                beneSelfDetailsForm
                                .get('benPhone')
                                ['controls']['0'].get('phoneNo').errors?.pattern
                          }" formControlName="phoneNo" placeholder="Phone No" />
                        <span class="invalid-message" *ngIf="
                            (submitted ||
                            beneSelfDetailsForm
                                .get('benPhone')
                                ['controls']['0'].get('phoneNo').touched) &&
                                beneSelfDetailsForm
                              .get('benPhone')
                              ['controls']['0'].get('phoneNo').errors?.required
                          ">
                          {{ getGlobalErrorMessages("REQUIRED") }}
                        </span>
                        <span class="invalid-message" *ngIf="
                            (submitted ||
                            beneSelfDetailsForm
                                .get('benPhone')
                                ['controls']['0'].get('phoneNo').touched) &&
                                beneSelfDetailsForm
                              .get('benPhone')
                              ['controls']['0'].get('phoneNo').errors?.pattern
                          ">
                          {{ getGlobalErrorMessages("REQUIRED") }}
                        </span>
                    </div>
                    <div class="col-6" formArrayName="benEmail">
                        <div class="row" [formGroupName]="0">
                        </div>
                    </div>
                </div>
               
        </form>
      
    </div> -->