<div class="page-caption row ml-4 mr-4">
    <div class="col-md-12 px-0">
        <h5 class="mb-0">Edit / Update Status</h5>
    </div>
</div>
<div class="row ml-4 mr-4">
    <div class="mat-elevation-z8 mt-3 manage-case-status col-md-12">
        <div class="row py-0">
            <div class="col-md-2 pt-3 pb-3">
                <h6>Receipt Number</h6>
                <span><b style="font-size: 20px">{{receiptNumber}}</b> <i class="fa fa-clone ml-2" (click)="copyRecieptNo()" pTooltip="Copy receipt number" style="cursor: pointer; color:#007bff"></i></span>
            </div>
            <div class="col-md-2 pt-3 pb-3">
                <a class="view-case-link" target="_blank" href="https://egov.uscis.gov/">View Case Status</a>
            </div>
        </div>
        <div class="clear"></div>
        <div class="container-fluid px-0 py-0">
            <form [formGroup]="updatecaseForm" >
                <div class="row mt-2 mb-4">
                    <div class="col-md-4">
                        <p class="label-cls">Case Status <span class="text-danger">*</span></p>
                        <input type="text" formControlName="shortStatus" pInputText placeholder="Enter Case Status" maxlength="75"/>
                        <div class="text-danger" *ngIf="updatecaseForm.get('shortStatus').invalid && updatecaseForm.get('shortStatus').touched">
                            Case Status is required.
                        </div>
                    </div>
                </div>                
                <div class="clear"></div>
                <div class="row mt-2 mb-4">
                    <div class="col-md-10">
                        <p class="label-cls">Case Status Details</p>
                        <textarea class="case-details-sec" formControlName="caseDetail" pInputText placeholder="Enter Case Status Details" maxlength="750"></textarea>
                    </div>
                </div>
                <div class="clear"></div>
                <div class="row mt-2 mb-4">
                    <div class="col-md-12">
                        <button class="btn btn-primary" (click)="saveCase()">Save</button>
                        <button class="btn btn-secondary ml-2" (click)="cancelHandler()"> Cancel</button>
                    </div>
                </div>
                <div class="clear"></div>
            </form>
        </div>
    </div>
</div>