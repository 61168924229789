<h5 mat-dialog-title>
    <div class="col-md-12 p-0 row delete-header">
        Guide on Maintaining the "Company Employee Mapping" Spreadsheet
    </div>
    <button mat-icon-button (click)="onDismiss()">
        <mat-icon>close</mat-icon>
    </button>
</h5>
<div class="dialog-sec" mat-dialog-content>
        <p>To ensure data consistency and accuracy in the I-9 Remediation process, it's essential to maintain a standardized Excel template for employee information. Follow this guide to properly manage your "Company Employee Mapping".</p>
        
        <h6>Step-by-Step Guide:</h6>
        
        <h6>Step 1: Create and Name the Spreadsheet</h6>
        <ol>
          <li>Open Excel:
            <ul>
              <li>Launch Microsoft Excel or any other spreadsheet application.</li>
            </ul>
          </li>
          <li>Create a New Workbook:
            <ul>
              <li>Click on "New Workbook" to start a new spreadsheet.</li>
            </ul>
          </li>
          <li>Name the Spreadsheet:
            <ul>
              <li>Save the file with the name “company employee mapping”.</li>
              <li>Example: company_employee_mapping.xlsx</li>
            </ul>
          </li>
        </ol>
        
        <h6>Step 2: Define the Columns and Headers</h6>
        <ol>
          <li>Set Up Headers:
            <ul>
              <li>In the first row of the spreadsheet, define the headers for each column. Each header should provide a clear description of the data it will contain.</li>
            </ul>
          </li>
          <li>Headers and Descriptions:
            <ul>
              <li>Column A: Employee Folder
                <ul>
                  <li>Description: The folder name for the employee, saved as "FirstNameLastName".</li>
                </ul>
              </li>
              <li>Column B: Employee First Name
                <ul>
                  <li>Description: The First name of the employee.</li>
                </ul>
              </li>
              <li>Column C: Employee Last Name
                <ul>
                  <li>Description: The Last name of the employee.</li>
                </ul>
              </li>
              <li>Column D: Exit Date
                <ul>
                  <li>Description: The exit date for inactive employees (if applicable).</li>
                </ul>
              </li>
            </ul>
          </li>
        </ol>
        
        <h6>Example Header Row:</h6>
        <table class="table table-border">
          <tr>
            <th>Employee Folder</th>
            <th>Employee First Name</th>
            <th>Employee Last Name</th>
            <th>Exit Date</th>
          </tr>
          <tr>
            <td>JohnDoe</td>
            <td>John</td>
            <td>Doe</td>
            <td>2023-12-31</td>
          </tr>
          <tr>
            <td>JaneSmith</td>
            <td>Jane</td>
            <td>Smith</td>
            <td></td>
          </tr>
        </table>
        
        <h6>Step 4: Maintain and Update the Spreadsheet</h6>
        <ol>
          <li>Regular Updates:
            <ul>
              <li>Update the spreadsheet regularly with new hires, changes in employee status, and exit dates for inactive employees.</li>
              <li>Ensure all information is current and accurate.</li>
            </ul>
          </li>
        </ol>
</div>

<div mat-dialog-actions class="float-right">
    <button mat-stroked-button color="primary" (click)="onDismiss()">Close</button>
</div>

<div class="clearfix"></div>