<div class="position-relative" *ngIf="!isI9Admin">
  <app-help-video-overlay #helpVideoComponent></app-help-video-overlay>
</div>
<p-card styleClass="tasklist-card">
  <div class="pt-2 p-d-flex"  *ngIf="!isI9Admin">
    <div class="col-md-6 pl-0 d-flex">
      <div class="py-3 pl-2 col-4 pl-0 align-middle task-tab" [ngClass]="{'activeTasksTab': activeTasksTab === 'mine' }" (click)="onActiveTaskTab('mine')"><span class="pl-3">My Tasks</span></div>
      <div class="py-3 pl-2 col-4 pl-0 align-middle task-tab" [ngClass]="{'activeTasksTab': activeTasksTab === 'all' }" (click)="onActiveTaskTab('all')"><span class="pl-3">All Tasks</span></div>
    </div>
 
    <div class="py-2 col-md-{{ isOutsideImagility ? 4 : 6 }} d-flex justify-content-end" *ngIf="!roleName">
      <button type="button" (click)="addNewTask()"
          class="create-task-petition" [ngClass]="{'mr-4': !isOutsideImagility}">
          <i class="fa fa-plus-circle ml-0 p-0"></i> Create Task / Petition</button>
    </div>
    <div class="py-2 col-md-2" *ngIf="isOutsideImagility">
      <div class="imagilityFlagIcon pull-right">
        <span> <img src="/assets/flag-1.svg" alt=""> </span>
        <span class="flag-text">Petition Not Created Through Imagility </span>
      </div>
    </div>
  </div>
  <div class="pt-2 p-d-flex border-bottom" *ngIf="isI9Admin">
    <div class="col-md-6 pl-0 d-flex">
      <div class="py-3 pl-2 col-4 pl-0 align-middle task-tab"><span class="pl-3">I-9 Updates</span></div>
    </div>
 
    <div class="py-2 col-md-{{ isOutsideImagility ? 4 : 6 }} d-flex justify-content-end" *ngIf="!roleName">
      <p-dropdown 
        [options]="employeeActions" 
        placeholder="Create Employee" 
        [(ngModel)]="selectedAction" 
        (onChange)="onActionSelect($event)"
        class="create-task-petition create-drpdown-btn">
      </p-dropdown>
    </div>
  </div>
  <div class="content-holder pt-0 pb-0">
    <div class="mt-3 mb-4 row tab-container" *ngIf="isI9Admin">
        <div class="col mx-1 tab-label" (click)="toggleStatus = 'I-9_Initiations'; getI9AdminDashboardList();"
        [ngClass]="{'activeTimeLine': toggleStatus === 'I-9_Initiations' }">
          <circle-progress class="float-left" [percent]="i9InitiationsCount" [radius]="27" [outerStrokeWidth]="6"
            [innerStrokeWidth]="6" [space]="-6" [outerStrokeColor]="'#19a0da'" [innerStrokeColor]="'#e7e8ea'"
            [titleFontSize]="20" [showSubtitle]="false" [animation]="true" [animationDuration]="300"
            [startFromZero]="false" [responsive]="false" units="" title="{{i9InitiationsCount}}">
          </circle-progress>
        
          <div class="lbl-text">I-9 Initiations</div>
        </div>
        <div class="col mx-1 tab-label" (click)="toggleStatus = 'I9-Evaluations'; getI9AdminDashboardList();"
        [ngClass]="{'activeTimeLine': toggleStatus === 'I9-Evaluations' }">
          <circle-progress class="float-left" [percent]="i9EvaluationsCount" [radius]="27" [outerStrokeWidth]="6"
            [innerStrokeWidth]="6" [space]="-6" [outerStrokeColor]="'#19a0da'" [innerStrokeColor]="'#e7e8ea'"
            [titleFontSize]="20" [showSubtitle]="false" [animation]="true" [animationDuration]="300"
            [startFromZero]="false" [responsive]="false" units="" title="{{i9EvaluationsCount}}">
          </circle-progress>
        
          <div class="lbl-text">I-9 Evaluations</div>
        </div>
        <div  class="col mx-1 tab-label" (click)="toggleStatus = 'I-9 E-Verifications'; getI9AdminDashboardList();"
        [ngClass]="{'activeTimeLine': toggleStatus === 'I-9 E-Verifications' }">
            <circle-progress class="float-left" [percent]="i9EverificationsCount" [radius]="27" [outerStrokeWidth]="6"
              [innerStrokeWidth]="6" [space]="-6" [outerStrokeColor]="'#19a0da'" [innerStrokeColor]="'#e7e8ea'"
              [titleFontSize]="20" [showSubtitle]="false" [animation]="true" [animationDuration]="300"
              [startFromZero]="false" [responsive]="false" units="" title="{{i9EverificationsCount}}">
            </circle-progress>
            
            <div class="lbl-text">I-9 E-Verifications</div>
        </div>
    </div>  
  </div>
  <div class="content-holder pt-2 pb-0" [class.six-header]="cols?.length == 6" [class.five-header]="cols?.length == 5">
    <div class="mt-2 mb-1 row tab-container"  *ngIf="!isI9Admin">
      
      <div class="col pb-3 px-0 mx-1 tab-label mb-3" (click)="toogleTaskTab('NEW',$event);"
        [ngClass]="{'activeTab': toggleStatus === 'NEW' }">
        <div class="lbl-text text-center"><span class="tab-heading-lable">In Progress</span></div>
      </div>
      <!-- IM-3329 and IM-3342 updated code for the Change in Task Widget -->
      <div class="col pb-3 mx-1 px-0 tab-label mb-3" (click)="toogleTaskTab('FILED',$event);"
        [ngClass]="{'activeTab': toggleStatus === 'FILED'}">
        <div class="lbl-text text-center"><span class="tab-heading-lable">Awaiting Decision</span></div>
      </div>
      <div class="col pb-3 px-0 mx-1 tab-label mb-3" (click)="toogleTaskTab('COMPLETE',$event);"
        [ngClass]="{'activeTab': toggleStatus === 'COMPLETE' }">
        <div class="lbl-text text-center"><span class="tab-heading-lable">Completed</span></div>
      </div>
    </div>
    
 
    <div class="row">  
      <div class="col px-1 task-table">
        <p-table [columns]="cols" class="pa-table im-card-no-border-table im-card-table-noborder-spacing table"
          [scrollable]="true" scrollHeight="300px" styleClass="p-datatable-gridlines" [value]="taskList" dataKey="id"
          *ngIf="taskList?.length > 0" (onRowExpand)="onRowExpand($event)" (sort)="onSort($event)">
          <ng-template pTemplate="header" let-columns>
            <tr class="text-uppercase">
                <th style="width: 3rem" *ngIf="toggleStatus !== 'FILED' && toggleStatus !== 'COMPLETE' && toggleStatus !== 'I-9_Initiations' && toggleStatus !== 'I9-Evaluations' && toggleStatus !== 'I-9 E-Verifications'"> </th>
                <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                    {{col.header}}
                    <p-sortIcon [field]="col.field" *ngIf="isI9Admin"></p-sortIcon>
                </th>
            </tr>
        </ng-template>
 
          <ng-template pTemplate="body" let-task let-expanded="expanded" let-columns="columns">
            <tr>
              <td class="imagilityFlagIcon" *ngIf="toggleStatus !== 'FILED' && toggleStatus !== 'COMPLETE' && toggleStatus !== 'I-9_Initiations' && toggleStatus !== 'I9-Evaluations' && toggleStatus !== 'I-9 E-Verifications'">
                <span *ngIf="isOutsideImagility"> <img src="/assets/flag-1.svg" alt=""></span>
                <button type="button" pButton pRipple [pRowToggler]="task"
                  class="p-button-text p-button-rounded p-button-plain"
                  [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'">
                </button>
              </td>
 
              <td *ngFor="let col of columns">
 
                <span *ngIf="col.field === 'companyName'">
                  {{ task['companyId'] ? task['companyName'] : '&mdash;' }}
                </span>
                
                <span *ngIf="col.field === 'beneficiaryName'">
                  {{ task['beneficiaryId'] ? task['beneficiaryFirstName'] + ' ' + task['beneficiaryLastName'] :
                  task['sponsorFirstName'] + ' ' + task['sponsorLastName'] }}
                </span>

                <span *ngIf="col.field === 'visaType'">
                  {{ task['visaType'] || task['immCategory'] }}
                </span>
                
                <span *ngIf="col.field === 'petitionType'">
                  {{ task['petitionType'] || task['immClassification'] }}
                </span>
 
                <span *ngIf="col.field === 'status' && !isI9Admin">
                  {{task['taskStatusId']['name']}}
                </span>
                <span class="taskId" *ngIf="col.field === 'taskId'" (click)="handleTask(task)">
                  {{task['id']}}
                </span>
                
                <span *ngIf="!['companyName', 'beneficiaryName', 'visaType', 'petitionType', 'status', 'taskId'].includes(col.field) && !isI9Admin">
                  {{task[col.field]}}
                </span>                  
 
                <span *ngIf="col.field === 'fname'">
                  {{ task[col.field] ? task[col.field] : '&mdash;' }}
                </span>
 
                <span *ngIf="col.field !== 'i9StatusCode' && col.field !== 'everifyStatus' && (col.field === 'startDate' || col.field === 'endDate')">
                  {{task[col.field] |  date: 'MM/dd/yyyy'}}
                </span>  
                <ng-container *ngIf="isI9Admin">
                <span class="text-blue" *ngIf="col.field === 'everifyStatus'">                  
                    {{task[col.field]}}
                </span>
 
                <span class="text-blue" *ngIf="col.field === 'i9StatusCode' && task['i9FormDetails']['status']['code'] != 'COMPLTD'">
                  <span  [ngClass]="{
                    'text-blue4': task[col.field] == 'INITIATED',
                    'text-success': task[col.field] == 'COMPLTD',
                    'text-blue1': task[col.field] == 'SEC1COMPLTD',
                    'text-blue2': task[col.field] == 'SEC1VERFD',
                    'text-blue3': task[col.field] == 'SENTBACK',
                    'text-red': task[col.field] == 'REJECTED'
                    }">
                        {{getText(task[col.field])}}
                  </span>
                </span>  
 
                <span class="text-success" *ngIf="col.field === 'i9StatusCode' && task['i9FormDetails']['status']['code'] === 'COMPLTD'">
                  Completed
                </span>
                 
                <span class="set_action" *ngIf="col.field === 'action' && toggleStatus != 'COMPLETE'">
 
                    <a *ngIf="task['i9StatusCode'] !== 'COMPLTD' && !listOfI9Status.includes(task['everifyStatus'])" [href]="viewInfo(task['i9StatusCode'], task)"
                    class="setLink view"  style="font-size: 12px!important; color: cornflowerblue;"> View</a>
                    <!-- <a *ngIf="listOfI9Status.includes(task['everifyStatus'])" class="setLink"
                      href="javascript:void(0)"  
                      (click)="viewEverifyStatus(task)"
                      >View</a> -->
                    <!-- popup -->
                    <span *ngIf="toggleStatus === 'I-9_Initiations'">
                      <!-- [href]="getLink(task['i9StatusCode'], task)"  -->
                      <a style="cursor: pointer; color: cornflowerblue;"
                      *ngIf="task['i9StatusCode'] !== 'REJECTED' && task['i9StatusCode'] !== 'COMPLTD'" class="setLink"
                      
                     [href]="getLink(task['i9StatusCode'], task)">
                      {{getAction(task['i9StatusCode'])}}  
                    </a>
                      
                      
                      <a
                      *ngIf="task['i9StatusCode'] === 'COMPLTD'" class="setLink"
                      href="javascript:void(0)"  
                      (click)="GenerateI9(task['i9StatusCode'], task)"
                      >
                      {{getAction(task['i9StatusCode']) }}
                      </a>
                    </span>
    
                    <span *ngIf="toggleStatus === 'I9-Evaluations'">
                        <a *ngIf="task['i9StatusCode'] === 'QDOC' || task['i9StatusCode'] === 'OTHER' || task['i9StatusCode'] === 'RSDOC'"
                         class="setLink" [href]="getLink(task['i9StatusCode'], task)" style="font-size: 12px!important; color: cornflowerblue;"> Sent Back</a>
                        <a *ngIf="task['i9StatusCode'] === 'REJECTED'" (click)="deleteExistI9Form(task)" class="setLink" [href]="getLink(task['i9StatusCode'], task)" style="font-size: 12px!important; color: cornflowerblue;"> Re-Initiate</a>
                        <a *ngIf="task['i9StatusCode'] === 'VERIFIED' && task['i9FormDetails']['status']['code'] === 'SEC1COMPLTD'" class="setLink"
                        [href]="getLink(task['i9StatusCode'], task)" style="font-size: 12px!important; color: cornflowerblue;"> Initiate I-9 </a>
                        <a *ngIf="task['i9StatusCode'] === 'VERIFIED' && task['i9FormDetails']['status']['code'] === 'SEC1VERFD'" class="setLink"
                          [href]="getLink(task['i9StatusCode'], task)" style="font-size: 12px!important; color: cornflowerblue;"> Generate I-9 </a>
                        <a *ngIf="task['i9StatusCode'] === 'VERIFIED' && task['i9FormDetails']['status']['code'] === 'COMPLTD'" class="setLink text-success"
                          href="javascript:void(0)" (click)="GenerateI9(task['i9StatusCode'], task)" style="font-size: 12px!important; color: cornflowerblue;"> I-9 Download</a>
                    </span>
 
                </span>  
                <span *ngIf="col.field === 'caseNumber'">
                  {{ task[col.field] ? task[col.field] : '&mdash;' }}
                </span>
 
                <span class="set_action" *ngIf="col.field === 'action' && toggleStatus == 'COMPLETE'">
                  <span (click)="gotoVerify()" style="font-size: 12px; color: cornflowerblue;">Go To E-Verify</span>
                </span>
                <span *ngIf="col.field === 'evalComment'">
                 {{ task[col.field] ? task[col.field] : '&mdash;' }}
                </span>
              </ng-container>
              </td>
            </tr>
          </ng-template>
        <ng-template pTemplate="rowexpansion" let-task>
              <tr>
                <td colspan="8">
                  <div class="p-p-3 sub-task-type">
                    <p-table class="sub-task-table" [columns]='nestedCols' [value]="task.subTaskList" dataKey="subTaskId">
                      <ng-template pTemplate="header" let-columns>
                <tr>
                <th *ngFor="let col of columns">
                  {{col.header}}
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="6">No Data</td>
              </tr>
            </ng-template>
          </p-table>
        </div>
        </td>
        </tr>
        </ng-template>
      </p-table>
      
      <div *ngIf="taskList?.length == 0" class="mt-4 text-center">No Data</div>
      <div class="show-more-box bg-white" *ngIf="taskList?.length > 0"><a class="show-more" (click)="showMore()">Show
          More</a>
      </div>
 
  </div>
  </div>
    </div>
</p-card>

<p-dialog [draggable]="false" styleClass="bgColorChange" *ngIf="statusModal" [(visible)]="statusModal" [style]="{width: '50vw'}" [baseZIndex]="10000" appendTo="body">
  <hr>

  <ng-template pTemplate="header">
    <div class="eVerifyHeader">
      <h3>View Status</h3>
    </div>
  </ng-template>

  <div class="eVerifyContent">

    <div class="clearfix"></div>    
    <div class="row">
      <div class="col-md-12">
        <label>Employee Name: <strong> {{empName}}</strong></label>
      </div>
      <hr class="w-100">
      <div class="col-md-6">
        <label class="d-block">Status</label>
        <strong>{{selectedStatus}}</strong>
      </div>

      <div class="col-md-6">
        <label class="d-block">Case Number</label>
        <strong>{{caseNumberUpdate}}</strong>
      </div>
    </div>   
  
  </div>

  <div class="clearfix py-3"></div>

</p-dialog>