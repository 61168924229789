import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// import * as RolesRouteMap from '../../../data/constants/roles';

@Component({
  selector: 'app-past-documents-menu',
  templateUrl: './past-documents-menu.component.html',
  styleUrls: ['./past-documents-menu.component.scss']
})


export class PastDocumentsMenuComponent implements OnInit {

  index = 0;
  selectNotificationTab:boolean=false;

  constructor(private activatedRoute: ActivatedRoute, private router: Router,) { }

  ngOnInit(): void {
  }

}
