<div class="row ml-4 mr-4">
    <div class="col-12 px-0">
        <div class="download-reports-main">
            <div class="pag-caption">
                <small class="link" (click)="breadCrumbNavigation()">Home</small> &raquo; <small> Reports</small> &raquo; <small> User Reports</small>
            </div>
            <div class="page-caption col-md-12 px-0">
                <h5 class="mb-0">User Reports</h5>
            </div>
            <div class="content-container pb-1 mt-3">
                <div class="row page-heading page-header">
                    <div class="col-12">
                        <h2>Enter Details</h2>
                    </div>
                </div>
                <form [formGroup]="downloadReportsForm">
                    <!-- First Row -->
                    <div class="row mt-3 mb-4">
                        <div class="col-4">
                            <p class="label-cls required" for="">Companies</p>
                            <p-multiSelect class="w-100 associate-company-multiselect" optionValue="companyId" [options]="listOfCompanies"
                                formControlName="companies" appendTo="body" defaultLabel="Select" optionLabel="companyName">
                            </p-multiSelect>
                            <!-- <p-autoComplete 
                            class="attorney-autocomplete platform-admin-ac w-100 d-block" formControlName="company" 
                            field="companyName" 
                            [suggestions]="listOfCompanies" 
                            (completeMethod)="filterCompanies($event)" 
                            (onSelect)="handleCompanyChange($event)"
                            dataKey="id"
                            [dropdown]="true"
                            [placeholder]="'Search'"
                            [forceSelection]="true"
                            name="company"
                            [showEmptyMessage]="true">
                            </p-autoComplete> -->
                            <small *ngIf="downloadReportsForm.get('companies').touched && downloadReportsForm.get('companies').errors"
                                class="p-error">
                                <span *ngIf="downloadReportsForm.get('companies').errors?.required">{{ getGlobalErrorMessages("REQUIRED") }}</span>
                            </small>
                        </div>
                        <!-- <div class="col-4">
                            <p class="label-cls" for="">Associate Companies</p>
                            <p-multiSelect class="w-100 associate-company-multiselect" optionValue="id" [options]="listOfAssociateCompanies"
                                formControlName="associateCompanies" appendTo="body" defaultLabel="Select" optionLabel="companyName">
                            </p-multiSelect>
                            <small *ngIf="downloadReportsForm.get('associateCompanies').touched && downloadReportsForm.get('associateCompanies').errors"
                                class="p-error">
                                <span *ngIf="downloadReportsForm.get('associateCompanies').errors?.required">{{ getGlobalErrorMessages("REQUIRED") }}</span>
                            </small>
                        </div> -->
                </div>
                <!-- Second Row -->
                    <div class="row mt-3 mb-4">
                        <div class="col-4">
                            <p class="label-cls required" for="">From Date</p>
                            <p-calendar #sd [style]="{'width':'100%'}" placeholder="From Date" styleClass="p-form-datepicker"
                            dateFormat="mm/dd/yy" [showIcon]="true" formControlName="fromDate" [maxDate]="maxFromDate" (onSelect)="setMinToDate($event)" [yearNavigator]="true" yearRange="1900:2100">
                            </p-calendar>
                            <small *ngIf="downloadReportsForm.get('fromDate').touched && downloadReportsForm.get('fromDate').errors"
                                class="p-error">
                                <span *ngIf="downloadReportsForm.get('fromDate').errors?.required">{{ getGlobalErrorMessages("REQUIRED") }}</span>
                            </small>
                        </div>

                        <div class="col-4">
                            <p class="label-cls required">To Date</p>
                            <p-calendar #sd [style]="{'width':'100%'}" placeholder="To Date" styleClass="p-form-datepicker"
                            dateFormat="mm/dd/yy" [showIcon]="true" formControlName="toDate" [minDate]="minToDate" [disabled]="!downloadReportsForm.get('fromDate').value" [yearNavigator]="true" yearRange="1900:2100">
                            </p-calendar>
                            <small *ngIf="downloadReportsForm.get('toDate').touched && downloadReportsForm.get('toDate').errors"
                                class="p-error">
                                <span *ngIf="downloadReportsForm.get('toDate').errors?.required">{{ getGlobalErrorMessages("REQUIRED") }}</span>
                            </small>
                        </div>
                    </div>
                    <!-- Action Button --Last Row -->
                    <div class="row mt-3 mb-5">
                        <div class="col-12">
                            <button type="submit" class="btn btn-primary"                
                            [disabled]="!downloadReportsForm.valid" 
                            (click)="downloadReport()">Download</button>
                        </div>
                    </div>
                </form>
            </div>

        </div>
    </div>
</div>