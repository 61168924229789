<form [formGroup]="createNewBene" #inviteBene="ngForm" (ngSubmit)="createBeneFormSub()">
    <div class="create-beneficiary container p-0">
        <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col mr-3">
                <label class="required">First Name</label>
                <input id="firstName" type="text" pInputText formControlName="firstName" (blur)="isFormValid()"
                    [ngClass]="{ 'im-input-error': (f.firstName.touched || isSubmitted) && f.firstName.errors  }" />
                <small
                    *ngIf="createNewBene.get('firstName').value === null && createNewBene.get('firstName').touched && createNewBene.get('firstName').errors"
                    class="p-error">
                    <span *ngIf="createNewBene.get('firstName').errors['required']">Required</span>
                </small>
            </div>
            <div class="p-field p-col mr-3">
                <label for="lastName" class="required">Last Name</label>
                <input id="lastName" type="text" pInputText formControlName="lastName" (blur)="isFormValid()"
                    [ngClass]="{ 'im-input-error': (f.lastName.touched || isSubmitted) && f.lastName.errors  }" />
                <small
                    *ngIf="createNewBene.get('lastName').value === null && createNewBene.get('lastName').touched && createNewBene.get('lastName').errors"
                    class="p-error">
                    <span *ngIf="createNewBene.get('lastName').errors['required']">Required</span>
                </small>
            </div>


            <div class="p-field p-col">

                <label for="middleName">Middle Name</label>
                <input id="middleName" type="text" pInputText formControlName="middleName" />
            </div>

        </div>
        <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col mr-3">
                <label class="required">Gender</label>
                <p-dropdown placeholder="Select" appendTo="body" optionLabel="name" optionValue="code"
                    [options]="listGender" formControlName="genderType" (blur)="isFormValid()">
                </p-dropdown>
                <small
                    *ngIf="createNewBene.get('genderType').value === null && createNewBene.get('genderType').touched && createNewBene.get('genderType').errors"
                    class="p-error">
                    <span *ngIf="createNewBene.get('genderType').errors['required']">Required</span>
                </small>
            </div>
            <div class="p-field p-col mr-3">
                <label for="date" class="required">Date of Birth</label>
                <p-calendar appendTo="body" formControlName="date" (blur)="isFormValid()" name="date"
                    styleClass="p-form-datepicker" dateFormat="mm/dd/yy" [maxDate]="currentDate"
                    placeholder="MM/DD/YYYY" [showIcon]="true" [readonlyInput]="false" [monthNavigator]="true"
                    [yearNavigator]="true" yearRange="{{fromYear}}:{{toYear}}">
                </p-calendar>
                <small
                    *ngIf="createNewBene.get('date').value === null && createNewBene.get('date').touched && createNewBene.get('date').errors"
                    class="p-error">
                    <span *ngIf="createNewBene.get('date').errors['required']">Required</span>
                </small>
            </div>
            <div class="p-field p-col">
                <label for="emailId" class="required">Communication Email ID</label>
                <input id="emailId" type="text" pInputText formControlName="emailId" (keyup)="checkEmail()"
                    [ngClass]="{ 'im-input-error': (f.emailId.touched || isSubmitted) && f.emailId.errors  }" />
                <small *ngIf="createNewBene.get('emailId').touched && createNewBene.get('emailId').errors"
                    class="p-error">
                    <span *ngIf="createNewBene.get('emailId').errors['required']">Required</span>
                    <span *ngIf="createNewBene.get('emailId').errors['email']">Email address is invalid</span>
                </small>
            </div>
        </div>

        <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-4 p-0 pr-4">
                <label>Company Name</label>
                <p-dropdown placeholder="Select Company Name" appendTo="body" formControlName="selectedCompanyId" name="selectedCompanyId"
                    optionValue="petitionerId" optionLabel="petitionerName" [options]="listOfClientCompanies">
                </p-dropdown>
            </div>
        </div>

        <div class="p-fluid p-formgrid p-grid pl-half-rem" *ngIf="userType !== 'I9Admin'">
            <span class="imm-title mt-3 pb-3 required">What is the immigration type
                <span *ngIf="userType == 'Beneficiary'">Beneficiary</span>
                <span *ngIf="userType == 'I9Admin'">Employee</span> is applying for?</span>
            <div class="p-field-radiobutton pr-3">
                <p-radioButton class="pr-3" *ngFor="let item of familyType" name="immType" formControlName="immType"
                    (onClick)="onImmTypeSelected()" [value]="item.code" [label]="item.name" [inputId]="item.id"
                    [disabled]="userType==='Petitioner'">
                </p-radioButton>
            </div>
            <small
                *ngIf="createNewBene.get('immType').value === null && createNewBene.get('immType').touched && createNewBene.get('immType').errors"
                class="p-error">
                <span *ngIf="createNewBene.get('immType').errors['required']">Required</span>
            </small>
        </div>
        <div *ngIf="userType !== 'I9Admin'">
            <ng-template [ngIf]="createNewBene.get('immType').value">
                <div class="p-fluid p-formgrid p-grid pl-half-rem">
                    <div class="p-field p-col-4 p-0 pr-4" *ngIf="createNewBene.get('immType').value === 'GCEB'">
                        <label class="required">Company Name</label>
                        <p-dropdown placeholder="Select" appendTo="body" [(ngModel)]="selectedCompanyId"
                            optionValue="petitionerId" optionLabel="petitionerName" [options]="listOfClientCompanies"
                            formControlName="petitionerCompany">
                        </p-dropdown>
                        <small
                            *ngIf="createNewBene.get('petitionerCompany').value === null && createNewBene.get('petitionerCompany').touched && createNewBene.get('petitionerCompany').errors"
                            class="p-error">
                            <span
                                *ngIf="createNewBene.get('petitionerCompany').errors['petitionerCompanyIsRequired']">Required</span>
                        </small>
                    </div>
                    <div class="p-field p-col-4 p-0 pr-4">
                        <label class="required">
                            <span *ngIf="userType == 'Beneficiary'">Beneficiary</span>
                            <span *ngIf="userType == 'I9Admin'">Employee</span>
                            Type</label>
                        <p-dropdown placeholder="Select" appendTo="body" optionLabel="name" optionValue="code"
                            [options]="taskType" formControlName="benType" (onChange)="onChangeBeneType($event)">
                        </p-dropdown>
                        <small
                            *ngIf="createNewBene.get('benType').value === null && createNewBene.get('benType').touched && createNewBene.get('benType').errors"
                            class="p-error">
                            <span *ngIf="createNewBene.get('benType').errors['required']">Required</span>
                        </small>
                    </div>
                    <div class="p-field p-formgroup-inline p-col-4" *ngIf="isBeneUndocumented">
                        <span class="imm-title mt-2 pb-3 required">
                            <span *ngIf="userType == 'Beneficiary'">Beneficiary</span>
                            <span *ngIf="userType == 'I9Admin'">Employee</span>
                            currently living in the U.S.?</span>
                        <div class="p-field-radiobutton">
                            <p-radioButton (click)="benLivingUSCheck(1)" name="benLivingUS" value="1"
                                formControlName="benLivingUS" inputId="ben-livingUS1">
                            </p-radioButton>
                            <label for="ben-livingUS1">Yes</label>
                        </div>
                        <div class="p-field-radiobutton pl-3">
                            <p-radioButton (click)="benLivingUSCheck(0)" name="benLivingUS" value="0"
                                formControlName="benLivingUS" inputId="ben-livingU2">
                            </p-radioButton>
                            <label for="ben-livingUS2">No</label>
                        </div>
                        <small
                            *ngIf="createNewBene.get('benLivingUS').value === null && createNewBene.get('benLivingUS').touched && createNewBene.get('benLivingUS').errors"
                            class="p-error">
                            <span
                                *ngIf="createNewBene.get('benLivingUS').errors['benLiveInUSIsRequired']">Required</span>
                        </small>
                    </div>
                </div>
                <ng-container *ngIf="residingUs && isBeneUndocumented">
                    <div class="p-fluid p-formgrid p-grid pl-half-rem">
                        <div class="p-field p-col-4 p-0 mr-5 pr-4">
                            <span class="imm-title mt-2 pb-3 required">On which visa did
                                <span *ngIf="userType == 'Beneficiary'">Beneficiary</span>
                                <span *ngIf="userType == 'I9Admin'"> Employee</span>

                                enter U.S.?</span>
                            <p-dropdown [options]="visaTypes" appendTo="body" formControlName="selectedBenType"
                                placeholder="Select" optionValue="code" optionLabel="name"></p-dropdown>
                            <small
                                *ngIf="createNewBene.get('selectedBenType').value === null && createNewBene.get('selectedBenType').touched && createNewBene.get('selectedBenType').errors"
                                class="p-error">
                                <span
                                    *ngIf="createNewBene.get('selectedBenType').errors['selectedBenTypeIsRequired']">Required</span>
                            </small>
                        </div>
                    </div>
                    <div class="p-fluid p-formgrid p-grid pl-half-rem">
                        <div class="p-field p-formgroup-inline p-col p-0">
                            <span class="imm-title mt-2 pb-3 required">Has
                                <span *ngIf="userType == 'Beneficiary'">Beneficiary</span>
                                <span *ngIf="userType == 'I9Admin'">Employee</span>

                                maintained immigration status?</span>
                            <div class="p-field-radiobutton">
                                <p-radioButton name="hasImmigration" value="1" formControlName="hasImmigration"
                                    inputId="ben-livingUS3">
                                </p-radioButton>
                                <label for="ben-livingUS3">Yes</label>
                            </div>
                            <div class="p-field-radiobutton pl-3">
                                <p-radioButton name="hasImmigration" value="0" formControlName="hasImmigration"
                                    inputId="ben-livingUS4">
                                </p-radioButton>
                                <label for="ben-livingUS4">No</label>
                            </div>
                            <small
                                *ngIf="createNewBene.get('hasImmigration').value === null && createNewBene.get('hasImmigration').touched && createNewBene.get('hasImmigration').errors"
                                class="p-error">
                                <span
                                    *ngIf="createNewBene.get('hasImmigration').errors['hasImmigrationIsRequired']">Required</span>
                            </small>
                        </div>
                    </div>
                </ng-container>
            </ng-template>
        </div>
        <button *ngIf="!config.data" [disabled]="!createNewEnable" pButton pRipple type="submit" title="Create New"
            label="Create New" icon="pi pi-plus"></button>
        <button *ngIf="config.data" [disabled]="config.data.i9StatusCode" pButton pRipple type="submit" title="Update"
            label="Update" icon="pi pi-plus"></button>

    </div>
</form>