import { NgModule } from '@angular/core';
import { PlatformAdminLandingRoutingModule } from './platform-admin-routing.module';
import { PlatformAdminLandingComponent } from './platform-admin-landing/platform-admin-landing.component';
import { PlatformAdminMenuComponent } from './platform-admin-menu/platform-admin-menu.component';

import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import {ButtonModule} from 'primeng/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { NgxPermissionsModule } from 'ngx-permissions';
import { CommonModule } from '@angular/common';
import { BeneficiaryListComponent } from './beneficiary-list/beneficiary-list.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatDividerModule } from '@angular/material/divider';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {DropdownModule} from 'primeng/dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatTooltipModule} from '@angular/material/tooltip';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import { AttorneyInvoiceModule } from '../attorney-invoice/attorney-invoice.module';
import { TaskAllocationModule } from '../task-allocation/task-allocation.module';
import { DownloadReportsComponent } from './download-reports/download-reports.component';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { MultiSelectModule } from 'primeng/multiselect';
import { CaseStatusModule } from '../case-status/case-status.module';

@NgModule({
    imports: [
        CommonModule,
        PlatformAdminLandingRoutingModule,
        MatToolbarModule,
        MatButtonModule,
        ButtonModule,
        MatButtonToggleModule,
        MatMenuModule,
        MatIconModule,
        MatTabsModule,
        MatTableModule,
        MatPaginatorModule,
        MatFormFieldModule,
        MatSelectModule,
        MatDividerModule,
        ConfirmDialogModule,
        DropdownModule,
        FormsModule,
        MatTooltipModule,
        OverlayPanelModule,
        AttorneyInvoiceModule,
        TaskAllocationModule,
        NgxPermissionsModule.forChild(),
        AutoCompleteModule,
        ReactiveFormsModule,
        CalendarModule,
        MultiSelectModule,
        CaseStatusModule 
    ],
    declarations: [
        PlatformAdminLandingComponent,
        PlatformAdminMenuComponent,
        BeneficiaryListComponent,
        DownloadReportsComponent
    ]
})

export class LandingPlatformAdminModule { }