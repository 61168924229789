<div #eventpageDiv *ngIf="eventpageLoaded" class="p-3">
    <form [formGroup]="eventsForm" class="edit-event-form">

        <div class="mt-3">
            <!-- this is for zoom meeting link -->
            <div *ngIf="meetingBy == 'zoom'" class="mx-2">
                <div class="d-flex align-items-center mb-4">
                    <label class="edit-event-labels required" for="meeting-link">
                        <i class="fa fa-link" aria-hidden="true"></i>
                    </label>
                    <div class="mx-2 flex-fill">
                        <input id="meeting-link" type="text" formControlName="meetingLink">
                        <small *ngIf="eventsForm.get('meetingLink').touched && eventsForm.get('meetingLink').errors"
                            class="p-error">
                            <span *ngIf="eventsForm.get('meetingLink').errors['required']">Required</span>
                        </small>
                    </div>
                    <!-- <div>
                        <input class="checkbox" id="useImagilityZoomAccount" title="Please reach out to your Imagility contact for the Zoom account credentials" type="checkbox"
                            formControlName="useImagilityZoomAccount" (change)="populateImagilityZoom()" />
                        <label for="useImagilityZoomAccount">Use Imagility zoom account</label>
                    </div> -->
                </div>
            </div>

            <div class="d-flex align-items-center mb-4">
                <label class="edit-event-labels required" for="first-name">
                    <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                </label>
                <div class="mx-2 flex-fill">
                    <input id="first-name" type="text" formControlName="subject">
                    <small *ngIf="eventsForm.get('subject').touched && eventsForm.get('subject').errors"
                        class="p-error">
                        <span *ngIf="eventsForm.get('subject').errors['required']">Required</span>
                    </small>
                </div>
            </div>

            <div class="d-flex align-items-center mb-4">
                <label class="edit-event-labels required">
                    <i class="fa fa-users" aria-hidden="true"></i>
                </label>
                <div class="mx-2 flex-fill">
                    <div class="p-fluid">
                        <p-chips styleClass="p-chips" formControlName="reqattendiesList" [(ngModel)]="requiredEmails"
                            separator=","></p-chips>
                        <small
                            *ngIf="eventsForm.get('reqattendiesList').touched && eventsForm.get('reqattendiesList').errors"
                            class="p-error">
                            <span *ngIf="eventsForm.get('reqattendiesList').errors['required']">Required</span>
                        </small>
                    </div>
                </div>
                <div *ngIf="meetingBy !== 'zoom'" class="mx-2">
                    <button type="button" class="btn btn-primary" (click)="requiredImagilityEmail()">Add Imagility
                        emails</button>
                </div>
            </div>
            <p-dialog [style]="{height: '500px'}" header="Add Imagility Attendes" [(visible)]="required" [modal]="true"
                [style]="{width: '50vw'}" [draggable]="false" [resizable]="false">
                <p-autoComplete [style]="{'width':'100%'}" [suggestions]="filtered"
                    (completeMethod)="serachAttendes($event)" field="name" [multiple]="true"
                    formControlName="reqImagilityattendiesList" [(ngModel)]="requiredImgEmails">
                </p-autoComplete>
                
                <p class="mt-3" *ngIf="empNotfound"> Employee not Found</p>
                <div class="mt-2">
                    <button class="btn btn-primary" (click)="addEmail()">Add</button>
                    <button class="btn btn-primary ml-3" (click)="closeEmailpopup()">close</button>
                </div>
                <div [style]="{height: '200px'}">

                </div>
            </p-dialog>

            <!-- <div class="d-flex align-items-center mb-4">
                <label class="edit-event-labels required">
                    <i class="fa fa-users" aria-hidden="true"></i>
                </label>
                <div class="mx-2 flex-fill">
                    <span><i class="fa fa-plus ml-2" (click)="addImagilityEmail()"></i></span>
                    <span>(click on plus icon add Imagility emails)</span>
                    <div class="p-fluid">
                        <p-chips styleClass="p-chips" [(ngModel)]="optEmail" formControlName="optattendiesList"
                            separator=","></p-chips>
                    </div>
                </div>
            </div> -->
            <!-- <p-dialog header="Add Imagility Attendes" [(visible)]="optional" [modal]="true" [style]="{width: '50vw'}"
                [draggable]="false" [resizable]="false">
                <p-autoComplete [style]="{'width':'100%'}" [suggestions]="filtered"
                    (completeMethod)="serachAttendes($event)" field="name" [multiple]="true"
                    formControlName="optimagilityattendiesList" [(ngModel)]="optImgEmail">
                </p-autoComplete>
                <p class="mt-3" *ngIf="empNotfound"> Employee not Found</p>
                <div class="mt-2">
                    <button class="btn btn-primary" (click)="addoptEmail()">Add</button>
                    <button class="btn btn-primary ml-3" (click)="closeEmailpopup()">close</button>
                </div>
                <div [style]="{height: '200px'}">

                </div>
            </p-dialog> -->

            <div class="d-flex align-items-center mb-4">
                <label class="edit-event-labels required" for="location">
                    <i class="fa fa-map-marker" aria-hidden="true"></i>
                </label>
                <div class="mx-2 flex-fill">
                    <input id="location" type="text" formControlName="meetingLocation">
                    <small *ngIf="eventsForm.get('meetingLocation').touched && eventsForm.get('meetingLocation').errors"
                        class="p-error">
                        <span *ngIf="eventsForm.get('meetingLocation').errors['required']">Required</span>
                    </small>
                </div>
            </div>

            <div class="d-flex align-items-center mb-4">
                <label class="edit-event-labels location-label required" for="location">
                    <i class="fa fa-clock-o" aria-hidden="true"></i>
                </label>
                <div class="row">
                    <div class="col">
                        <label class="label-cls required"> Start Date</label>
                        <p-calendar #sd yearRange="2000:2099" formControlName="startDate" name="date"
                            styleClass="p-form-datepicker" dateFormat="mm/dd/yy" placeholder="MM/DD/YYYY" [showIcon]="true"
                            [monthNavigator]="true" [yearNavigator]="true" [minDate]="minimumDate"
                            (onSelect)="checkEndDateTouched(sd)">
                        </p-calendar>
                        <small *ngIf="eventsForm.get('startDate').touched && eventsForm.get('startDate').errors"
                            class="p-error">
                            <span *ngIf="eventsForm.get('startDate').errors['required']">Required</span>
                        </small>
                    </div>
                    <div class="col">
                        <label class="label-cls required">Start Time</label>
                        <input type="time" formControlName="startTime">
                        <small *ngIf="eventsForm.get('startTime').touched && eventsForm.get('startTime').errors"
                            class="p-error">
                            <span *ngIf="eventsForm.get('startTime').errors['required']">Required</span>
                        </small>
                    </div>
                    <div class="col-sm-1 mt-4 text-center">
                        <i class="pi pi-arrow-right" style="font-size: 1rem"></i>
                    </div>
                    <div class="col">
                        <label class="label-cls required"> End Date</label>
                        <p-calendar formControlName="endDate" name="date" styleClass="p-form-datepicker"
                            dateFormat="mm/dd/yy" placeholder="MM/DD/YYYY" [showIcon]="true"
                            [minDate]="sd.value || minimumDate" [monthNavigator]="true" [yearNavigator]="true"
                            yearRange="2000:2099">
                        </p-calendar>
                        <small *ngIf="eventsForm.get('endDate').touched && eventsForm.get('endDate').errors"
                            class="p-error">
                            <span *ngIf="eventsForm.get('endDate').errors['required']">Required</span>
                        </small>
                    </div>
                    <div class="col">
                        <label class="label-cls required">End Time {{endTime}}</label>
                        <input type="time" formControlName="endTime">
                        <small *ngIf="eventsForm.get('endTime').touched && eventsForm.get('endTime').errors"
                            class="p-error">
                            <span *ngIf="eventsForm.get('endTime').errors['required']">Required</span>
                        </small>
                    </div>
                </div>
              
            </div>


            <div *ngIf="meetingBy !== 'zoom'" class="d-flex align-items-center mb-4">
                <label class="edit-event-labels required" for="recuurenceType">
                    <i class="fa fa-retweet" aria-hidden="true"></i>
                </label>
                <div class="mx-2 flex-fill" id="rec-dropdown">
                    <p-dropdown [options]="recurrencedropdown" [(ngModel)]="selectedRecurrence" (onChange)="recurrenceTypechanged($event)"
                        placeholder="Select" optionLabel="name" optionValue="code" formControlName="recuurenceType">
                    </p-dropdown>
                </div>
            </div>

            <div class="row mt-2">
                <div class="mt-4" *ngIf="scheduleType ==='WEEKLY'">
                    <div class=" mt-2">
                        <h6 style="font-weight:bolder;">Set Recurrence</h6>
                    </div>
                    <!-- value={{day.day}} formControlName="selectedWeeks" -->
                    <div>
                        <p-checkbox name="days" class="m-2" *ngFor="let day of daysList" label="{{day.day}}"
                            value={{day.day}} formControlName="selectedWeeks" [(ngModel)]="selectedWeeks"
                            (onChange)="onChangeWeekDays(day,$event)">
                        </p-checkbox>
                    </div>
                    <br>
                </div>
                <br>
                <div class="mt-4" *ngIf="scheduleType ==='BIWEEKLY'">
                    <div class=" mt-2">
                        <h6 style="font-weight:bolder;">Set Recurrence</h6>
                    </div>
                    <!-- value={{day.day}} formControlName="selectedWeeks" -->
                    <div class="row w-100">
                        <p-checkbox name="days" class="m-2" *ngFor="let day of daysList" label="{{day.day}}"
                            value={{day.day}} formControlName="selectedWeeks" [(ngModel)]="selectedWeeks"
                            (onChange)="onChangeWeekDays(day,$event)">
                        </p-checkbox>
                    </div>
                    <br>
                </div>
                <!--Bi-week-->
                <!-- <div class="mt-3 days" *ngIf="scheduleType==='BIWEEK'">
                                    <div class="mt-2">
                                        <mat-radio-group [(ngModel)]="weekNo" formControlName="weekNo">
                                            <mat-radio-button name="Bi-Weekly" value="1"> First Week </mat-radio-button>
                                            <mat-radio-button name="Bi-Weekly" value="2"> Second Week </mat-radio-button>
                                        </mat-radio-group>
                                    </div>
                                    <div class="mt-2">        
                                        <div>
                                            <p-checkbox name="days" class="m-2" *ngFor="let day of daysList" label="{{day.day}}"
                                                value={{day.value}} formControlName="selectedWeeks" [(ngModel)]="selectedWeeks"
                                                (onChange)="onChangeWeekDays(day,$event)">
                                            </p-checkbox>
                                        </div>
            
                                        <div class="p-2 mt-2 mb-3 timeOnly d-flex" *ngIf="scheduleType==='BIWEEK'">
                                            <span class="mr-2 mt-2">Time</span>
                                            <p-calendar  placeholder="00:00" hourFormat="24"
                                                formControlName="scheduleTime" timeOnly="true"></p-calendar>
                                        </div>
                                    </div>
                                </div> -->
                <div class=" mt-3 mb-3 " *ngIf="scheduleType==='MONTHLY' || scheduleType==='ABSOLUTEMONTHLY'">
                    <div class=" mt-2">
                        <h6 style="font-weight:bolder;" class="cls required">Set Recurrence</h6>
                    </div>
                    <div class="d-flex">
                        <span class="mt-2">Day</span>
                        <input type="text" formControlName="dayOfMonth" class="form-control w-25 ml-2 mr-2"
                            placeholder="Enter">
                        <span class="mt-2">of every month</span> <br>
                    </div><br>
                </div>
                <div class=" mt-3 mb-2 " *ngIf="scheduleType==='YEARLY' || scheduleType==='ABSOLUTEYEARLY'">
                    <div class="mt-2">
                        <h6 style="font-weight:bolder;">Set Recurrence</h6>
                    </div>
                    <div class="d-flex">
                        <span class="mt-2 mr-3">On</span>
                        <!-- <input type="text" class="form-control w-25 ml-2 mr-2" formControlName="monthOfYear"  > -->
                        <p-calendar placeholder="mm/dd" dateFormat="mm/dd" hourFormat="24" formControlName="monthOfYear"
                            showIcon="true"></p-calendar>
                        <span class="mt-2 ml-2">of every year</span>
                    </div>
                    <br>
                </div>
                <!-- <div class=" mt-3 mb-2 " *ngIf="scheduleType==='CUSTOM'">
                    <div class="row">
                        <div class="col-2">
                            <label for="custom">Repeats Every</label>
                        </div>
                        <div class="col-2">
                            <input type="text" formControlName="customInterval">
                        </div>
                        <div class="col-8">
                            <p-dropdown [options]="customRecurrence" (onChange)="customrecurrenceTypechanged($event)"
                                placeholder="Select" optionLabel="name" optionValue="code"
                                formControlName="customrecuurenceType">
                            </p-dropdown>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-12" *ngIf="subrecurrenceType ==='WEEKLY'">
                            <p-checkbox name="days" class="m-2" *ngFor="let day of daysList" label="{{day.day}}"
                                value={{day.day}} formControlName="customselectedWeeks"
                                (onChange)="onChangeWeekDays(day,$event)">
                            </p-checkbox>
                        </div>
                    </div>
                    <div class="row mt-2" *ngIf="subrecurrenceType ==='MONTHLY'">
                        <span class="mt-2">On Day</span>
                        <input type="text" formControlName="customdayOfMonth" class="form-control w-25 ml-2 mr-2"
                            placeholder="Enter">
                        <span>of every Month</span>
                    </div>
                    <div class="row mt-2" *ngIf="subrecurrenceType ==='MONTHLY'">
                        <div class="col-2">
                            <span class="mt-2">On Day</span>
                        </div>
                        <div class="col-4">
                            <p-dropdown class="ml-2" [options]="weeKnumber" placeholder="Select" optionLabel="name"
                                optionValue="code" formControlName="customWeekNo">
                            </p-dropdown>
                        </div>
                        <div class="col-4">
                            <p-dropdown class="ml-2" [options]="daysList" placeholder="Select" optionLabel="day"
                                optionValue="day" formControlName="customday">
                            </p-dropdown>
                        </div>
                    </div>
                </div> -->
            </div>

            <div class="row mt-2">
                <div class="col-12 nopadding">
                    <label for="">Description/Agenda</label>
                    <kendo-editor #commonkendoeditor style="height: 250px;" name="description"
                        formControlName="description">
                        <kendo-toolbar>
                            <kendo-toolbar-buttongroup>
                                <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
                                <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
                                <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
                                <kendo-toolbar-button kendoEditorStrikethroughButton></kendo-toolbar-button>
                            </kendo-toolbar-buttongroup>
                            <kendo-toolbar-buttongroup>
                                <kendo-toolbar-button kendoEditorSubscriptButton></kendo-toolbar-button>
                                <kendo-toolbar-button kendoEditorSuperscriptButton></kendo-toolbar-button>
                            </kendo-toolbar-buttongroup>
                            <kendo-toolbar-buttongroup>
                                <kendo-toolbar-button kendoEditorAlignLeftButton></kendo-toolbar-button>
                                <kendo-toolbar-button kendoEditorAlignCenterButton></kendo-toolbar-button>
                                <kendo-toolbar-button kendoEditorAlignRightButton></kendo-toolbar-button>
                                <kendo-toolbar-button kendoEditorAlignJustifyButton></kendo-toolbar-button>
                            </kendo-toolbar-buttongroup>
                            <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>
                            <kendo-toolbar-dropdownlist kendoEditorFontSize></kendo-toolbar-dropdownlist>
                            <kendo-toolbar-dropdownlist kendoEditorFontFamily></kendo-toolbar-dropdownlist>
                            <kendo-toolbar-colorpicker kendoEditorForeColor></kendo-toolbar-colorpicker>
                            <kendo-toolbar-colorpicker kendoEditorBackColor view="gradient"></kendo-toolbar-colorpicker>
                            <kendo-toolbar-buttongroup>
                                <kendo-toolbar-button kendoEditorInsertUnorderedListButton></kendo-toolbar-button>
                                <kendo-toolbar-button kendoEditorInsertOrderedListButton></kendo-toolbar-button>
                                <kendo-toolbar-button kendoEditorIndentButton></kendo-toolbar-button>
                                <kendo-toolbar-button kendoEditorOutdentButton></kendo-toolbar-button>
                            </kendo-toolbar-buttongroup>
                            <kendo-toolbar-button kendoEditorBlockquoteButton></kendo-toolbar-button>
                            <kendo-toolbar-button kendoEditorSelectAllButton></kendo-toolbar-button>
                            <kendo-toolbar-buttongroup>
                                <kendo-toolbar-button kendoEditorUndoButton></kendo-toolbar-button>
                                <kendo-toolbar-button kendoEditorRedoButton></kendo-toolbar-button>
                            </kendo-toolbar-buttongroup>
                            <kendo-toolbar-buttongroup>
                                <kendo-toolbar-button kendoEditorCreateLinkButton></kendo-toolbar-button>
                                <kendo-toolbar-button kendoEditorUnlinkButton></kendo-toolbar-button>
                            </kendo-toolbar-buttongroup>
                            <kendo-toolbar-button kendoEditorInsertFileButton></kendo-toolbar-button>
                            <kendo-toolbar-button kendoEditorViewSourceButton></kendo-toolbar-button>
                            <kendo-toolbar-button kendoEditorCleanFormattingButton></kendo-toolbar-button>
                            <kendo-editor-insert-table-button></kendo-editor-insert-table-button>
                            <kendo-toolbar-buttongroup>
                                <kendo-toolbar-button kendoEditorAddColumnBeforeButton></kendo-toolbar-button>
                                <kendo-toolbar-button kendoEditorAddColumnAfterButton></kendo-toolbar-button>
                                <kendo-toolbar-button kendoEditorAddRowBeforeButton></kendo-toolbar-button>
                                <kendo-toolbar-button kendoEditorAddRowAfterButton></kendo-toolbar-button>
                            </kendo-toolbar-buttongroup>
                            <kendo-toolbar-buttongroup>
                                <kendo-toolbar-button kendoEditorDeleteColumnButton></kendo-toolbar-button>
                                <kendo-toolbar-button kendoEditorDeleteRowButton></kendo-toolbar-button>
                                <kendo-toolbar-button kendoEditorDeleteTableButton></kendo-toolbar-button>
                            </kendo-toolbar-buttongroup>
                            <kendo-toolbar-buttongroup>
                                <kendo-toolbar-button kendoEditorMergeCellsButton></kendo-toolbar-button>
                                <kendo-toolbar-button kendoEditorSplitCellButton></kendo-toolbar-button>
                            </kendo-toolbar-buttongroup>
                            <kendo-toolbar-button kendoEditorPrintButton></kendo-toolbar-button>
                            <kendo-toolbar-buttongroup>
                                <kendo-toolbar-button kendoEditorInsertImageButton></kendo-toolbar-button>
                                <kendo-toolbar-button title="Upload’s Image at cursor point"
                                    text="Upload Image"></kendo-toolbar-button>
                            </kendo-toolbar-buttongroup>
                        </kendo-toolbar>
                    </kendo-editor>
                </div>
                <small *ngIf="eventsForm.get('description').touched && eventsForm.get('description').errors"
                    class="p-error">
                    <span *ngIf="eventsForm.get('description').errors['required']">Required</span>
                </small>
            </div>

            <!-- <div class="d-flex flex-column mt-2">
                <div class="d-flex align-items-center">
                    <p-fileUpload #createUploader name="demo[]" mode="basic" chooseLabel="Upload" [auto]="true" customUpload="true"
                        (uploadHandler)="Upload($event, createUploader)" accept="{{acceptedFiles}}" multiple="multiple"
                        showUploadButton="false" showCancelButton="true" maxFileSize="10485760">
                    </p-fileUpload>
                    <p class="label-cls ml-2 mb-2 mt-3"><span><i class="pi pi-info-circle" pTooltip="Allowed File Types: .pdf, .jpg, .png, .doc, .docx, .xls, .xlsx, .csv"
                                tooltipPosition="top"></i></span></p>
                </div>
                <div class="colp-0">
                    <ul *ngIf="(uploadedFiles$ | async)?.length" class="list-group list-group-horizontal mt-1 flex-wrap">
                        <li *ngFor="let file of (uploadedFiles$ | async); let i=index" class="list-group-item">{{file.name}} - <span
                                id="deleteCls" (click)="deleteFile(i)">X</span></li>
                    </ul>
                </div>
            </div>
            <div class="mt-3" *ngIf="alreadyStoredFiles.length > 0">
                <div>
                    <label for="upload"> Uploaded documents</label>
                </div>
                <table>
                    <tr>
                        <th>File Name</th>
                        <th>Type</th>                        
                    </tr>
                    <tr *ngFor="let file of alreadyStoredFiles">
                        <td><span class="download-link" (click)="decodeBase64(file)">{{file.name}}</span></td>                        
                        <td>{{file.contentType}}</td>
                    </tr>
                </table>
            </div> -->
           
        </div>
        <div class="row mt-5 mb-5">
            <button type="button" [disabled]="!this.eventsForm.valid" class="btn btn-primary" *ngIf="meetingBy === 'zoom'"
                (click)="saveZoomMeeting()">{{'Save'}} </button>
            <button type="button" [disabled]="!this.eventsForm.valid" class="btn btn-primary" *ngIf="meetingBy === 'microsoft'"
                (click)="save()">{{ meetingId ? 'Update' : 'Save'}} </button>
            <button type="button" [disabled]="!this.eventsForm.valid" class="btn btn-primary" *ngIf="meetingBy === 'google'"
                (click)="saveGoogleMeeting()">{{ meetingId ? 'Update' : 'Save'}} </button>
            <button *ngIf="!meetingId && meetingBy != 'zoom'" type="button" class="btn btn-light ml-2" (click)="eventcancel()">Cancel</button>
            <button *ngIf="meetingId && meetingBy != 'zoom'" type="button" class="btn btn-primary ml-3" (click)="cancel()"> Cancel Event</button>
        </div>
    </form>
</div>