import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { ImagilityBaseResponse, TaskStep, PrimaryData, I140FileUploadConfiguration } from 'app-models';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { SyncDetailsService } from './../../utility/services/sync-details.service';
import { ToastrService } from 'ngx-toastr';
import { getStepDetails, getStepStatusUpdate, loadSubTaskDetails, updateStepStatus, updateStepStatusToInitialState } from 'visa-store';
import { Subject } from 'rxjs/internal/Subject';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { StepStatusUpdatePayload } from '../../utility/services/stepStatusUpdate.service';
import { sync_petitioner_document_code } from '../../utility/constants/sync-code';
import { PetitionerDetailsForeignCompanyService } from './petitioner-details-foreign-company.service';
import { VisaExtendedState } from '../../utility/interfaces/visa-extended-state';

import { environment } from 'environment-configurations';
import { I140FileUploadService } from 'src/app/services/i140-file-upload.service';
import { NoteDialogConfigurations, DeleteDialogConfigurations } from 'dialog-configuration-library';
import { NoteDialogComponent } from '../note-dialog/note-dialog.component';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';

@Component({
  selector: 'app-petitioner-details-foreign-company',
  templateUrl: './petitioner-details-foreign-company.component.html',
  styleUrls: ['./petitioner-details-foreign-company.component.scss']
})
export class PetitionerDetailsForeignCompanyComponent implements OnInit, OnDestroy {
  otherDocumentsFileUploadConfigurations: I140FileUploadConfiguration;

  disabledControls: boolean;
  typeOfButton: string; // This is required for status update button
  stepDetails: TaskStep;
  PetitionerDetailsForeignCompany;
  petitionerDetailsForeignDocuments;
  observableSubscription$ = new Subject();
  inputData: PrimaryData;
  userType: string;
  showNext: boolean = false;
  showPrev: boolean = false;

  constructor(
    private store: Store<VisaExtendedState>,
    public dynamicDialogRef: DynamicDialogRef,
    public dynamicDialogConfig: DynamicDialogConfig,
    private petitionerDetailsForeignCompanyService: PetitionerDetailsForeignCompanyService,
    public dialogService: DialogService,
    private toaster: ToastrService,
    private stepStatusUpdatePayloadConstruction: StepStatusUpdatePayload,
    private uploadService: I140FileUploadService,
    private syncDetailsService: SyncDetailsService
  ) {
    this.disabledControls = false;
    this.inputData = this.dynamicDialogConfig.data;
    this.showNext = this.inputData?.showNext; 
    this.showPrev = this.inputData?.showPrev;
  }

  ngOnInit(): void {
    console.log('this.inputData',this.inputData);
    console.log('this.inputData isViewMode',this.inputData['isViewMode']);
    console.log('this.inputData isCaseRequest',this.inputData['isCaseRequest']);
    console.log('Petitioner details foreign company component visa-store after ::', this.stepDetails)
    this.userType = sessionStorage.getItem('userTypeRole');
    this.getPetitionerDetails();
    this.getPetitionerDocumentDetails();
    // Get Specific step details by passing step code
    this.store.pipe(select(getStepDetails, { id: this.dynamicDialogConfig.data.stepId }))
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe((data: TaskStep) => {
        this.typeOfButton = data.isPetitionerAction === 1 || data.isAttorneyAction === 1 ? 'MARK_COMPLETE' : 'MARK_SUBMIT';
        this.stepDetails = data;
        // this.stepDetails.foreignCompanyId = 288;

        // if (this.stepDetails.stepStatusDetails.stepStatusCode === 'NEW') {
        //   this.updateStepStatus('INPROGRESS');
        // }
        this.toggleControlsStatus();
      });

  }

  syncPetitionerDocuments() {
    this.petitionerDetailsForeignCompanyService.syncProfileData(
      this.dynamicDialogConfig.data.visatype,
      this.dynamicDialogConfig.data.caseType,
      this.dynamicDialogConfig.data.caseId,
      this.dynamicDialogConfig.data.foreignCompanyId,
      'COMPGENDOC',
      this.dynamicDialogConfig.data.superTaskId,
      this.dynamicDialogConfig.data.subTaskId).subscribe((response: ImagilityBaseResponse) => {
        if (response) {
          this.toaster.success(response.message, 'Success');
          this.getPetitionerDocumentDetails();
        }
      });
  }

  getPetitionerDetails() {
    this.petitionerDetailsForeignCompanyService.getPetitionerDetailsForeignCompany(
      this.dynamicDialogConfig.data.visatype,
      this.dynamicDialogConfig.data.foreignCompanyId,
      this.dynamicDialogConfig.data.superTaskId,
      this.dynamicDialogConfig.data.subTaskId)
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe((response: ImagilityBaseResponse) => {
        this.PetitionerDetailsForeignCompany = response.data;
      });
  }

  getPetitionerDocumentDetails() {
    this.petitionerDetailsForeignCompanyService.getPetitionerUploadDetails(
      this.dynamicDialogConfig.data.visatype,
      this.dynamicDialogConfig.data.caseType,
      this.dynamicDialogConfig.data.caseId,
      this.dynamicDialogConfig.data.foreignCompanyId,
      'COMPGENDOC')
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe((response: ImagilityBaseResponse) => {
        if (response && response.data) {
          this.toaster.success(response.message, 'Success');
          this.petitionerDetailsForeignDocuments = response.data;
        }
      });
  }

  updateStepStatus(status) {
    this.store.dispatch(updateStepStatus({
      payload: this.stepStatusUpdatePayloadConstruction.payloadConstruction(this.stepDetails, status),
      subTaskId: this.stepDetails.taskId,
      visaType: this.inputData.caseType === 'I130' ?
        this.inputData.caseType : this.inputData.visatype,
      stepId: this.dynamicDialogConfig.data.stepId
    }));
  }

  // Toggle controls status based on step status
  toggleControlsStatus() {
    this.disabledControls =
    this.stepDetails.stepStatusDetails.stepStatusCode === "NEW" ||
      this.stepDetails.stepStatusDetails.stepStatusCode === 'COMPLETE' ||
      this.stepDetails.stepStatusDetails.stepStatusCode === 'SUBMIT';

    this.otherDocumentsFileUploadConfigurations = Object.assign({}, {
      disableControls: this.disabledControls,
      isDocumentTypeRequired: true,
      isDocumentUploadRequired: true,
      isViewEnabled: true,
      isDownloadEnabled: true,
      isDeleteEnabled: true,
      getDocTypeApiUrl: `/${this.dynamicDialogConfig.data.visatype}/petitionType/${this.dynamicDialogConfig.data.petitionType}/group/${sync_petitioner_document_code.SYNC_TASK_TYPE_BENE_IMMIGRATION_Other_Doc_Code}/documentTypes`,
      getApiUrl: `/${this.dynamicDialogConfig.data.visatype}/${this.dynamicDialogConfig.data.caseType}/${this.dynamicDialogConfig.data.caseId}/document/category/${sync_petitioner_document_code.SYNC_TASK_TYPE_BENE_IMMIGRATION_Other_Doc_Code}/`,
      postApiUrl: `/${this.dynamicDialogConfig.data.visatype}/${this.dynamicDialogConfig.data.caseType}/${this.dynamicDialogConfig.data.caseId}/document/category/${sync_petitioner_document_code.SYNC_TASK_TYPE_BENE_IMMIGRATION_Other_Doc_Code}/fileCategory/`,
      deleteApiUrl: `/${this.dynamicDialogConfig.data.visatype}/${this.dynamicDialogConfig.data.caseType}/${this.dynamicDialogConfig.data.caseId}/document/`,
      documentTypeDropdownCode: 'COMPPETDOCFORGN',
      isPetitionerDetailsDocuments: true,
      petitionerDetailsDocumentsCompanyId: this.inputData.foreignCompanyId
    });
  }

  handleClose() {
    this.dynamicDialogRef.close();
  }

  openDoc(item) {
    const docPath = item.fileLocation.substring(23);
    window.open(`${environment.docs}${docPath}`, '_blank');
  }

  download(file) {
    this.uploadService.downloadDocuments(environment.docs + file.fileLocation.substring(23), file.fileLocation.substring(23));
  }

  handleSendBack(item) {
    // console.log('$$$', this.stepDetails)
    NoteDialogConfigurations.data = {
      title: `Rejected Document - ${item.fileCategory.name} `,
      payload: {
        // petitionerId: this.stepDetails && this.stepDetails.petitionerId ? this.stepDetails.petitionerId : null,
        companyId: this.stepDetails.companyId ? this.stepDetails.companyId : null,
        employeeId: this.inputData.immSpecialistId,
        notificationType: 'DOCREJECT',
        text: null,
        title: null,
        data: JSON.stringify({
          petitionId: this.dynamicDialogConfig.data.i140CaseId,
          taskId: this.stepDetails.taskId || null,
          documentType: item.fileCategory.name,
          documentCategory: item.fileCategory.group
        }),
        channelType: '',
        notifyAllBeneficiaries: false,
        notifyAllEmployees: false,
        priority: '',
      }
    };
    this.dialogService.open(NoteDialogComponent, NoteDialogConfigurations);
  }

  handleNotification(item) {
    NoteDialogConfigurations.data = {
      title: `Missing Document - ${item.fileCategory.name} `,
      payload: {
        //  beneficiaryId: this.stepDetails.beneficiaryId ? this.stepDetails.beneficiaryId : null,
        companyId: this.stepDetails.companyId ? this.stepDetails.companyId : null,
        employeeId: this.inputData.immSpecialistId,
        notificationType: 'DOCMISS',
        text: null,
        title: null,
        data: JSON.stringify({
          petitionId: this.dynamicDialogConfig.data.i140CaseId,
          taskId: this.stepDetails.taskId || null,
          documentType: item.fileCategory.name,
          documentCategory: item.fileCategory.group
        }),
        channelType: '',
        notifyAllBeneficiaries: false,
        notifyAllEmployees: false,
        priority: '',
      }
    };
    this.dialogService.open(NoteDialogComponent, NoteDialogConfigurations);
  }

  deleteImmigrationDocuments(item) {
    const deleteDialogRef = this.dialogService.open(DeleteDialogComponent, DeleteDialogConfigurations);
    deleteDialogRef.onClose
      .pipe(takeUntil(this.observableSubscription$))
      // .pipe(takeUntil(this.observableSubscription$))
      // .pipe(takeUntil(this.observableSubscription$))
      // .pipe(takeUntil(this.observableSubscription$))
      .subscribe((response: boolean) => {
        if (response) {
          this.syncDetailsService.deleteDocuments(this.dynamicDialogConfig.data?.visatype , this.dynamicDialogConfig.data.caseType,
            this.dynamicDialogConfig.data.caseId, item.id)
            .pipe(takeUntil(this.observableSubscription$))
            .subscribe((responseDeleteDocuments: ImagilityBaseResponse) => {
              this.toaster.success(responseDeleteDocuments.message, 'Success');
              this.getPetitionerDocumentDetails();
            });
        }
      });
  }

  ngOnDestroy() {
    this.observableSubscription$.next();
    this.observableSubscription$.complete();
    // Update the Step Status state to empty state
    this.store.dispatch(updateStepStatusToInitialState());
  }

}
