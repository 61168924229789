import { Injectable } from '@angular/core';
import {  HttpClient, HttpParams } from '@angular/common/http';
import { map} from 'rxjs/operators';
import { ImagilityBaseResponse } from '../data/models/response.model';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class LcaService {

  constructor(private httpClient: HttpClient) { }

  createLCA(lca) {
    return this.httpClient.post(`/dashboard/petitioner/lca/`, lca).pipe(
      map((response) => response)
    );
  }

  companyHierarchy(companyId) {
    return this.httpClient.get(`/company/${companyId}/hierarchy`).pipe(
      map((response: ImagilityBaseResponse) => {
        return response.data;
      })
    )
  }

  addWithdrawn(payload,lcaId) {
    return this.httpClient.post(`/dashboard/petitioner/lca/${lcaId}/status/withdraw`, payload).pipe(
      map((response) => response)
    );
  }

  getStates(countryCode: string) {
    return this.httpClient.get(`/countries/states?countryCode=${countryCode}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status) {
          return response.data;
        }
      })
    );
  }

  getContactPersonList(companyId: number) {
    return this.httpClient.get(`/company/${companyId}/contactperson/list`).pipe(
      map((response: ImagilityBaseResponse) => {
        return response.status === 200 ? response.data : [];
      })
    );
  }

  getLawFirmList(companyId: number, reqJson) {
    return this.httpClient.post(`/petitioner/dashboard/attorney/company/${companyId}`, reqJson).pipe(
      map((response: ImagilityBaseResponse) => {
        return response;
      })
    );
  }

  getEmployeesOfCompany(companyId) {
    return this.httpClient.get('/company/' + companyId + '/employee').pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status) {
          return response.data;
        }
      })
    );
  }

  getAttorneyCompanyList(companyId) {
    return this.httpClient.get(`/company/${companyId}`).pipe(
      map((response: ImagilityBaseResponse) => {
        return response.data;
      })
    )
  }

  

  createEFileLCA(efile) {
    return this.httpClient.post(`/dashboard/petitioner/lca/efile`, efile).pipe(
      map((response) => response)
    );
  }

  downloadEfileTracker(OS) {
    return this.httpClient.get(`/dashboard/petitioner/lca/downloadlink/`+ OS.toUpperCase()).pipe(
      map((response) => response)
    );
  }
  getUserDetailsEfileTracker(userid) {
    return this.httpClient.get(`/dashboard/petitioner/lca/flag/`+ userid).pipe(
      map((response) => response)
    );
  }

  saveUserRecoredTrackerFile(efile) {
    //http://52.54.81.36:5651/api/v1/dashboard/petitioner/lca/flag/user
    return this.httpClient.post(`/dashboard/petitioner/lca/flag/user`, efile).pipe(
      map((response) => response)
    );
  }

  

  getEFileDetails(id) {
    return this.httpClient.get(`/dashboard/petitioner/lca/${id}`).pipe(
      map((response: ImagilityBaseResponse) => {
        return response.data;
      })
    )
  }
  getDataFromService(isEfile) {
    return this.httpClient.post(`/dashboard/petitioner/lca/getSocCodeList?isEfile=`+isEfile,'' ).pipe(
      map((response: ImagilityBaseResponse) => {
        return response;
      })
    )
  }


  getquestionnaireDetails(payload) {
    return this.httpClient.post(`/platformadmin/questionnaire/list`,payload).pipe(
      map((response: ImagilityBaseResponse) => {
        return response.data;
      })
    )
  }
  deletequestionnaireDetails(payload) {
    return this.httpClient.delete('/dashboard/petitioner/lca/' + payload).pipe(
      map((data) => {
        return data;
      })
    );
  }
  getDatagetSocCodeList(isEfile,value) {
    return this.httpClient.post(`/dashboard/petitioner/lca/getSocCodeList/${value}?isEfile=`+isEfile ,'' ).pipe()
  }

  // getCompanyNaicsList(companyId) {
  //   return this.httpClient.get(`/companyNaics?companyCode=${companyId}`).pipe(
  //     map((response: ImagilityBaseResponse) => {
  //       return response.data;
  //     })
  //   )
  // } 

  getCompanyNaics(companyId) {
    return this.httpClient.get(`/companyNaics/${companyId}`).pipe(
      map((response: ImagilityBaseResponse) => {
        return response.data;
      })
    )
  } 

  getWagesOfLevels(socCode, stateName, countyName, isEducation: boolean = false) {
    let state: any;
    if(Array.isArray(stateName)){
      state =  stateName[0].toUpperCase();
    } else {
      state =  stateName.toUpperCase();
    }
    const flag = isEducation ? `?flcType=education` : '';
    return this.httpClient.get<ImagilityBaseResponse>(`/flcdata/wage/${socCode}/${state}/${countyName}${flag}`).pipe(
      map((response: ImagilityBaseResponse) => {
        return response;
      })
    );
  }

  postLcaDetails(payload) {
    return this.httpClient.post(`/dashboard/petitioner/lca/noticeBoard/postLca`,payload).pipe(
      map((response: ImagilityBaseResponse) => {
        return response.data;
      })
    )
  }



  getJobPostDetails(companyId: string, fromDate: string, toDate: string): Observable<any> {

    let fromDates = '2021-01-01';
    let toDates = '2024-12-02'
    const params = new HttpParams()
      .set('fromDate', fromDates)
      .set('toDate', toDates);
    return this.httpClient.get(`/imagility/company/${companyId}`, { params }).pipe(
      map((response: ImagilityBaseResponse) => response.data)
    )
  };

  getCountyAreas(stateName, countyName) {
    const state = stateName.toUpperCase();
    return this.httpClient.get<ImagilityBaseResponse>(`/flagCounty/areas/?stateCode=${state}&countyCode=${countyName}`).pipe(
      map((response: ImagilityBaseResponse) => {
        return response;
      })
    );
  }

  convertUploadDocument(
    type: string, companyId: number, payload) {
    return this.httpClient.post(`/wizard/upload/${type}/company/${companyId}/lcaDoc`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        return response;
      })
    );
  }

  updateLcaDecision(lcaId, payload) {
    return this.httpClient.post(`/dashboard/petitioner/lca/${lcaId}/status/update`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        return response;
      })
    );
  }
}
