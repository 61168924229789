<div class="quick-access-main w-100 p-3 bg-white">
    <div class="row p-0 quick-access-header">
        <div class="col-5 p-0">
            <h5 class="quick-access-heading">Quick Access</h5>
        </div>
        <div class="col-7 p-0">
            <div class="d-flex justify-content-end">
                <span title="Go to My Mail" class="user-time icn pr-2" routerLink="email-client">
                    <mat-icon> mail_outline</mat-icon>
                    <span class="pull-right calendar pl-1 pr-1">
                        Mail</span>
                </span>
                <span class="vertical-separator"></span>
                <span title="Go to My Calendar" class="user-time icn pl-2" routerLink="scheduling">
                    <span class="material-icons pull-left pr-1">calendar_today</span>
                    <span class="pull-right calendar">
                        Calendar</span>
                    </span>
            </div>
        </div>
    </div>
    <div class="row p-0 pt-2 quick-access-content">
        <div class="col-4 p-0 pr-1">
            <div class="quick-access-item py-1">
                <div class="icon-and-text m-2 justify-content-center bg-white text-center d-flex" (click)="handleAddClick('addClientCompany')">
                    <span class="add-icon add-client-icon">
                        <!-- <svg>
                            <use xlink:href="#add-client" />
                        </svg> -->
                    </span>
                    <span class="text-link  p-2">Add Clients/ Company</span>
                </div>
                <!-- <div class="link-text justify-content-center text-center">
                    <p-button label="View All" styleClass="p-button-link" (click)="viewClients()"></p-button>
                </div> -->
            </div>
        </div>
        <div class="col-4 p-0 pr-1">
            <div class="quick-access-item py-1">
                <div class="icon-and-text m-2 justify-content-center bg-white text-center d-flex"  (click)="addNewTask()">
                    <span class="add-icon create-task-icon">
                        <!-- <svg>
                            <use xlink:href="#create-task" />
                        </svg> -->
                    </span>
                    <span class="text-link p-2">Create <br/> Task</span>
                </div>
                <!-- <div class="link-text justify-content-center text-center">
                    <p-button label="View All" styleClass="p-button-link" (click)="viewTasks()"></p-button>
                </div> -->
            </div>
        </div>
        <div class="col-4 p-0">
            <div class="quick-access-item py-1">
                <div class="icon-and-text m-2 justify-content-center bg-white text-center d-flex"(click)="handleAddClick('addBeneficiary')">
                    <span class="add-icon add-bene-icon">
                        <!-- <svg>
                            <use xlink:href="#add-bene" />
                        </svg> -->
                    </span>
                    <span class="text-link p-2">Add Individual/ Sponsor</span>
                </div>
                <!-- <div class="link-text justify-content-center text-center">
                    <p-button label="View All" styleClass="p-button-link" (click)="viewBeneficiaries()"></p-button>
                </div> -->
            </div>
        </div>
    </div>
</div>
<svg display="none">
    <symbol xmlns="http://www.w3.org/2000/svg" width="57.55" height="51.378" viewBox="0 0 57.55 51.378" id="add-client">
        <g id="user-plus-svgrepo-com" transform="translate(-2 -2)">
          <path id="Path_62587" data-name="Path 62587" d="M27.6,16.8A10.8,10.8,0,1,1,16.8,6,10.8,10.8,0,0,1,27.6,16.8Z" transform="translate(6.258 6.258)" fill="none" opacity="0.282"/>
          <path id="Path_62588" data-name="Path 62588" d="M3,26.259a1,1,0,0,1-.958-1.287,11.69,11.69,0,0,1,6.717-7.926C11.931,15.631,16.341,15,23.06,15s11.129.631,14.3,2.046a11.69,11.69,0,0,1,6.717,7.925,1,1,0,0,1-1.916.574,9.7,9.7,0,0,0-5.616-6.673C33.643,17.577,29.484,17,23.06,17s-10.583.577-13.486,1.872a9.7,9.7,0,0,0-5.616,6.673A1,1,0,0,1,3,26.259Z" transform="translate(0 27.12)" fill="currentColor"/>
          <path id="Path_62589" data-name="Path 62589" d="M16.8,5A11.8,11.8,0,1,1,8.457,8.457,11.724,11.724,0,0,1,16.8,5Zm0,21.6A9.8,9.8,0,1,0,7,16.8,9.813,9.813,0,0,0,16.8,26.6Z" transform="translate(6.258 6.258)" fill="currentColor"/>
          <path id="Path_62590" data-name="Path 62590" d="M33.517,7H15a1,1,0,0,1,0-2H33.517a1,1,0,0,1,0,2Z" transform="translate(25.033 6.258)" fill="currentColor"/>
          <path id="Path_62591" data-name="Path 62591" d="M18,22.517a1,1,0,0,1-1-1V3a1,1,0,0,1,2,0V21.517A1,1,0,0,1,18,22.517Z" transform="translate(31.292)" fill="currentColor"/>
        </g>
    </symbol>
    <symbol xmlns="http://www.w3.org/2000/svg" width="42.631" height="42.631" viewBox="0 0 42.631 42.631" id="create-task">
        <g id="task-square-svgrepo-com" transform="translate(-1.25 -1.25)">
          <path id="Path_62593" data-name="Path 62593" d="M23.167,9.63H12.37a.75.75,0,0,1,0-1.5h10.8a.75.75,0,0,1,0,1.5Z" transform="translate(10.956 7.269)" fill="currentColor"/>
          <path id="Path_62594" data-name="Path 62594" d="M7.922,12.757a.75.75,0,0,1-.53-.22L5.85,11A.75.75,0,0,1,6.91,9.934l1.012,1.012,4.1-4.1A.75.75,0,0,1,13.08,7.91L8.453,12.537A.75.75,0,0,1,7.922,12.757Z" transform="translate(4.628 5.684)" fill="currentColor"/>
          <path id="Path_62595" data-name="Path 62595" d="M23.167,16.63H12.37a.75.75,0,1,1,0-1.5h10.8a.75.75,0,0,1,0,1.5Z" transform="translate(10.956 14.665)" fill="currentColor"/>
          <path id="Path_62596" data-name="Path 62596" d="M7.922,19.757a.748.748,0,0,1-.53-.22L5.85,18A.75.75,0,0,1,6.91,16.934l1.012,1.012,4.1-4.1A.75.75,0,0,1,13.08,14.91L8.453,19.537A.748.748,0,0,1,7.922,19.757Z" transform="translate(4.628 13.08)" fill="currentColor"/>
          <path id="Path_62597" data-name="Path 62597" d="M28.735,43.881H16.4a23.825,23.825,0,0,1-6.8-.835,11.005,11.005,0,0,1-4.783-2.727,11.005,11.005,0,0,1-2.727-4.783,23.825,23.825,0,0,1-.835-6.8V16.4a23.825,23.825,0,0,1,.835-6.8A11.005,11.005,0,0,1,4.812,4.812,11.005,11.005,0,0,1,9.594,2.085a23.825,23.825,0,0,1,6.8-.835H28.735a23.825,23.825,0,0,1,6.8.835,11.005,11.005,0,0,1,4.783,2.727,11.005,11.005,0,0,1,2.727,4.783,23.825,23.825,0,0,1,.835,6.8V28.735a23.825,23.825,0,0,1-.835,6.8,10.426,10.426,0,0,1-7.509,7.509A23.825,23.825,0,0,1,28.735,43.881ZM16.4,2.75c-5.05,0-8.393.992-10.524,3.122S2.75,11.346,2.75,16.4V28.735c0,5.05.992,8.393,3.122,10.524s5.474,3.122,10.524,3.122H28.735c5.05,0,8.393-.992,10.524-3.122s3.122-5.474,3.122-10.524V16.4c0-5.05-.992-8.393-3.122-10.524S33.785,2.75,28.735,2.75Z" fill="currentColor"/>
        </g>
    </symbol>
    <symbol xmlns="http://www.w3.org/2000/svg" width="57.512" height="37.144" viewBox="0 0 57.512 37.144" id="add-bene">
        <path id="users-more-svgrepo-com" d="M57.012,41.394a.75.75,0,0,1-.639-.357L48.735,28.628a.75.75,0,0,1,.826-1.119,10.928,10.928,0,0,1,8.2,10.589v2.546a.75.75,0,0,1-.75.75ZM51.166,29.715l5.1,8.278A9.433,9.433,0,0,0,51.166,29.715ZM1,41.394a.75.75,0,0,1-.75-.75V38.1a10.928,10.928,0,0,1,8.2-10.589.75.75,0,0,1,.826,1.119L1.639,41.037A.75.75,0,0,1,1,41.394ZM6.846,29.715a9.433,9.433,0,0,0-5.1,8.278ZM44.282,41.394H13.73a.75.75,0,0,1-.75-.75V38.1A10.934,10.934,0,0,1,23.914,27.164H34.1A10.934,10.934,0,0,1,45.032,38.1v2.546A.75.75,0,0,1,44.282,41.394Zm-29.8-1.5H43.532V38.1A9.445,9.445,0,0,0,34.1,28.664H23.914A9.445,9.445,0,0,0,14.48,38.1ZM29.006,21.026a8.388,8.388,0,1,1,8.388-8.388A8.4,8.4,0,0,1,29.006,21.026Zm0-15.276a6.888,6.888,0,1,0,6.888,6.888A6.9,6.9,0,0,0,29.006,5.75ZM44.282,20.591a.75.75,0,0,1-.732-.913A45.9,45.9,0,0,1,45.9,11.7c.775-1.758,1.538-2.577,2.4-2.577.381,0,1.082.175,1.5,1.345a6.682,6.682,0,0,1,.326,2.172,8.4,8.4,0,0,1-5.592,7.91A.749.749,0,0,1,44.282,20.591Zm3.973-9.911a5.435,5.435,0,0,0-1.136,1.979A41.64,41.64,0,0,0,45.361,18.5a6.9,6.9,0,0,0,3.262-5.858A4.245,4.245,0,0,0,48.255,10.68ZM9.714,16.155c-.381,0-1.082-.175-1.5-1.345a6.682,6.682,0,0,1-.326-2.172,8.4,8.4,0,0,1,5.592-7.91.75.75,0,0,1,.982.87,45.9,45.9,0,0,1-2.348,7.981C11.339,15.336,10.576,16.155,9.714,16.155ZM12.65,6.78a6.9,6.9,0,0,0-3.262,5.858A4.245,4.245,0,0,0,9.757,14.6a5.435,5.435,0,0,0,1.136-1.979A41.639,41.639,0,0,0,12.65,6.78Z" transform="translate(-0.25 -4.25)" fill="currentColor"/>
    </symbol>
  </svg>
  