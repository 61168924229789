<div class="fluid-container">
  <div class="row pt-4 d-flex align-items-center">
    <div class="col-md-8 step-title p-0">
      <h5 class="mb-0">Specialty Occupation</h5>
      <p class="mb-0">Enter Specialty Occupation Details</p>
    </div>
    <div class="col-md-4 editBtn headerSubPortion px-0"> 
      <ul>
        <li class="col-auto px-0 firstPortion"> 
          <lib-step-status-update-component-library
        [visaType]="inputData.caseType != 'I130' ? inputData.visatype : inputData.caseType" [stepDetails]="stepDetails"
        type="EDIT" class="float-right">
      </lib-step-status-update-component-library>
        </li>
        <li class="col-auto pr-0 pl-2 secondPortion">
          <lib-maximize-dialog-library [configData]="{'layoutCode':inputData?.layoutCode}"></lib-maximize-dialog-library>
      <i (click)="handleClose();" class="close-icon pi pi-times pull-right"></i>
        </li>
      </ul>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 p-0">
      <!-- <lib-assigning-task-library [visaType]="inputData.visatype" [stepDetails]="stepDetails"
        (updateStatus)="updateStepStatus($event)">
      </lib-assigning-task-library> -->
        <lib-assigning-task-library [visaType]="inputData.visatype" [stepDetails]="stepDetails"
          (updateStatus)="updateStepStatus($event)" [isViewMode]="this.inputData['isViewMode']" [isCaseRequest]="this.inputData['isCaseRequest']">
        </lib-assigning-task-library>
      
    </div>
  </div>
  <p-divider class="header-divider"></p-divider>
  <div class="main-body footer-adjust-v2">
    <lib-last-update-library [inputDetails]="stepDetails" [primaryData]="inputData" sectionCode="OCC_DTL">
    </lib-last-update-library>
    <help-video-overlay-library #helpVideoComponent></help-video-overlay-library>
    <div class="row mt-2">
      <div class="card card-min no-border">
        <div class="im-card-tab-menu">
          <div class="im-list-group leftSidebar">
            <a class="im-list-group-item"
              [ngClass]="{'activeItem': selectedItem === 0 , 'inActiveItem':  selectedItem !== 0 }"
              (click)="handleOccupationDetermination()"> <span>Occupation
                Determination</span></a>
            <a class="im-list-group-item"
              [ngClass]="{'activeItem': selectedItem === 1 , 'inActiveItem':  selectedItem !== 1 }"
              (click)="handleOccupationDetails()"><span>Occupation
                Details</span></a>
            <a class="im-list-group-item"
              [ngClass]="{'activeItem': selectedItem === 2 , 'inActiveItem':  selectedItem !== 2 }"
              (click)="handleOccupationDetails(true)"><span>Additional Details</span></a>
          </div>
        </div>
      </div>
      <div class="col-md pl-0 pr-0" [hidden]="showAdditionalDetails">
        <ng-template dynamicComponentPlaceHolder></ng-template>
      </div>
      <div class="col-md pl-0 pr-0" *ngIf="showAdditionalDetails">
        <lib-custom-questionnaire [stepId]="inputData.stepId" [taskId]="inputData.taskId" [viewQuestions]="false" [showSaveBtn]="true" [loadedIn]="stepDetails.stepName" [isSection]="false"></lib-custom-questionnaire>
      </div>
    </div>
    </div>
  <p-divider></p-divider>
  <div class="row" *ngIf="isLastTab">
    <div class="col-md-6"> </div>
    <div class="col-md-6 completeStep">
      <lib-step-status-update-component-library
        [visaType]="inputData.caseType != 'I130' ? inputData.visatype : inputData.caseType" [stepDetails]="stepDetails"
        [type]="typeOfButton" [showPreviousStepBtn]="true" [showNextStepBtn]="true" class="d-flex col-12 justify-content-end"></lib-step-status-update-component-library>
    </div>
  </div>
  <div class="row pt-2" *ngIf="!isLastTab">
    <span class="lastTab">
      <i class="pi pi-info-circle pr-2" style="color: #19A0DA;"></i>This step can be marked complete when you
      reach the last tab, i.e., Occupation Details
    </span>
  </div>
</div>
