<div class="personalDetailContainer">
  <div id="profileContainer">
     <div class="row mt-2">
        <div class="col-2">
            <b class="title-display common-bene-class-for-translation" id="documentsListTitle">Documents List</b>
        </div>
     </div>
      
   
      <div class="row mt-2 doc-selection">
          <div class="col-3 p-0">
              <p *ngIf="onlyFormBuilder !== 'FB'" class="label-cls required common-bene-class-for-translation" id="selectFamilyDependentsLabel">Select Family/Dependents</p>
              <select class="im-input col-md mt-2" [(ngModel)]="selectedId" (change)="fetchFamilyDocument()">
                  <ng-container *ngFor="let family of selectFamilyDetails">
                      <ng-container *ngIf="family.relation !== 'SELF'">
                          <option  [value]="family.familyid" >
                            
                              <!-- {{ family.relation }} - {{ family.name }} -->
                              {{ family.relationName }}
                            
                          </option> 
                      </ng-container>
                      <ng-container *ngIf="family.relation === 'SELF'">
                          <option  [value]="family.familyid" [selected]="boolvar" >
                           
                              <!-- {{ family.relation }} - {{ family.name }} -->
                            {{ family.relationName }}
                          </option>
                      </ng-container>
                 </ng-container>
              </select>
          </div>
          <div class="col-1 ml-3 d-flex">
            <lib-bulk-documents-download  [selectedFiles]="selectedFiles" fileName="Beneficiary-Documents.zip"></lib-bulk-documents-download>
          </div>
      </div>
      <div class="row mt-4" *ngFor="let document of selectedMenu; let i=index">
          <b class="title-display common-bene-class-for-translation" id="documentSectionTitle-{{i}}">{{document}}</b>
          <div class="row mt-2">
              
            <div class="col-12 p-0" *ngIf="loadData">
                  <p-table [value]="loadData.Personal" [resizableColumns]="true" styleClass="p-datatable-gridlines" *ngIf="document === 'My Personal Documents'">
                      <ng-template pTemplate="header">
                          <tr>
                            <th style="width:5%"><p-checkbox *ngIf="bulkDownloadCheckboxes['Personal']" value="true" name="groupnamePersonal" inputId="documentTypePersonal" binary="true" [(ngModel)]="bulkDownloadCheckboxes['Personal']['isAllSelected']"
                                (onChange)="selectAllDocs($event,bulkDownloadCheckboxes['Personal'])" [disabled]="!loadData.Personal?.length"></p-checkbox></th>
                                <th class="common-bene-class-for-translation" id="personalDocumentsType{{i}}">Type</th>
                                <th class="common-bene-class-for-translation" id="personalDocumentsName{{i}}">Name</th>
                                <th class="common-bene-class-for-translation" id="personalDocumentsAction{{i}}">Action</th>
                          </tr>
                      </ng-template>
                      <ng-template pTemplate="body" let-loadData>
                          <tr>
                            <td><p-checkbox *ngIf="bulkDownloadCheckboxes['Personal']" value="true" inputId="documentTypePersonal{{loadData?.id}}" binary="true" name="groupname{{loadData?.id}}" [(ngModel)]="bulkDownloadCheckboxes['Personal']['childItems'][loadData['itemIndex']]['isSelected']" (onChange)="selectDoc($event,bulkDownloadCheckboxes['Personal'])"></p-checkbox></td>
                              <td class="common-bene-class-for-translation" id="personalDocType-{{loadData?.id}}-{{i}}">{{loadData?.fileCategory?.name}}</td>
                              <td class="common-bene-class-for-translation" id="personalDocName-{{loadData?.id}}-{{i}}">{{loadData?.fileName}}</td>
                              <td>
                                  <div class="row">
                                    <div><i class="fa fa-eye pr-2 icn" title="View" (click)="openDoc(loadData)"></i> </div>
                                    <div><i class="fa fa-download pr-2 icn" title="Download" (click)="download(loadData)"></i> </div>
                                    <div> <i class="fa fa-trash icn" title="Delete" (click)="deleteDoc(loadData)"></i> </div>
                                  </div>
                              </td>
                          </tr>
                      </ng-template>
                      <ng-template pTemplate="emptymessage" let-loadData>
                          <tr>
                            <td [attr.colspan]="3" class="common-bene-class-for-translation" id="personalDocsEmptyMessage">
                               No records found
                            </td>
                          </tr>
                        </ng-template>
                  </p-table>

                  <p-table [value]="loadData.Education" [resizableColumns]="true" styleClass="p-datatable-gridlines" *ngIf="document === 'My Educational Documents'">
                      <ng-template pTemplate="header">
                          <tr>
                            <th style="width:5%"><p-checkbox *ngIf="bulkDownloadCheckboxes['Education']" value="true" inputId="documentTypeEducation" binary="true"  name="groupnameEducation" [(ngModel)]="bulkDownloadCheckboxes['Education']['isAllSelected']"
                                (onChange)="selectAllDocs($event,bulkDownloadCheckboxes['Education'])" [disabled]="!loadData.Education?.length"></p-checkbox></th>
                                <th class="common-bene-class-for-translation" id="educationalDocumentsType{{i}}">Type</th>
                                <th class="common-bene-class-for-translation" id="educationalDocumentsName{{i}}">Name</th>
                                <th class="common-bene-class-for-translation" id="educationalDocumentsAction{{i}}">Action</th>
                          </tr>
                      </ng-template>
                      <ng-template pTemplate="body" let-loadData style="border: 1px solid red;">
                          <tr>
                            <td style="width:5%"><p-checkbox *ngIf="bulkDownloadCheckboxes['Education']" value="true" inputId="documentTypeEducation{{loadData?.id}}" binary="true" name="groupname{{loadData?.id}}" [(ngModel)]="bulkDownloadCheckboxes['Education']['childItems'][loadData['itemIndex']]['isSelected']" (onChange)="selectDoc($event,bulkDownloadCheckboxes['Education'])"></p-checkbox></td>
                            <td class="common-bene-class-for-translation" id="educationalDocType-{{loadData?.id}}-{{i}}">{{loadData?.fileCategory?.name}}</td>
                            <td class="common-bene-class-for-translation" id="educationalDocName-{{loadData?.id}}-{{i}}">{{loadData?.fileName}}</td>
                            <td>
                                <div class="row">
                                  <div><i class="fa fa-eye pr-2 icn" title="View" (click)="openDoc(loadData)"></i> </div>
                                    <div><i class="fa fa-download pr-2 icn" title="Download"  (click)="download(loadData)"></i> </div>
                                    <div> <i class="fa fa-trash icn" title="Delete" (click)="deleteDoc(loadData)"></i> </div>
                                    </div>
                            </td>
                          </tr>
                      </ng-template>
                      <ng-template pTemplate="emptymessage" let-loadData>
                          <tr>
                            <td [attr.colspan]="3" class="common-bene-class-for-translation" id="educationalDocsEmptyMessage">
                               No records found
                            </td>
                          </tr>
                        </ng-template>
                  </p-table>

                  <p-table [value]="loadData.Experience" [resizableColumns]="true" styleClass="p-datatable-gridlines" *ngIf="document === 'My Work Experience Documents'">
                      <ng-template pTemplate="header">
                          <tr>
                            <th style="width:5%"><p-checkbox *ngIf="bulkDownloadCheckboxes['Experience']" value="true" inputId="documentTypeExperience" binary="true"  name="groupnameExperience" [(ngModel)]="bulkDownloadCheckboxes['Experience']['isAllSelected']"
                                (onChange)="selectAllDocs($event,bulkDownloadCheckboxes['Experience'])" [disabled]="!loadData.Experience?.length"></p-checkbox></th>
                                <th class="common-bene-class-for-translation" id="experienceDocumentsType{{i}}">Type</th>
                                <th class="common-bene-class-for-translation" id="experienceDocumentsName{{i}}">Name</th>
                                <th class="common-bene-class-for-translation" id="experienceDocumentsAction{{i}}">Action</th>
                          </tr>
                      </ng-template>
                      <ng-template pTemplate="body" let-loadData>
                          <tr>
                            <td style="width:5%"><p-checkbox *ngIf="bulkDownloadCheckboxes['Experience']" value="true" inputId="documentTypeExperience{{loadData?.id}}" binary="true" name="groupname{{loadData?.id}}" [(ngModel)]="bulkDownloadCheckboxes['Experience']['childItems'][loadData['itemIndex']]['isSelected']" (onChange)="selectDoc($event,bulkDownloadCheckboxes['Experience'])"></p-checkbox></td>
                            <td class="common-bene-class-for-translation" id="experienceDocType-{{loadData?.id}}-{{i}}">{{loadData?.fileCategory?.name}}</td>
                            <td class="common-bene-class-for-translation" id="experienceDocName-{{loadData?.id}}-{{i}}">{{loadData?.fileName}}</td>
                            <td>
                                <div class="row">
                                  <div><i class="fa fa-eye pr-2 icn" title="View" (click)="openDoc(loadData)"></i> </div>
                                    <div><i class="fa fa-download pr-2 icn" title="Download" (click)="download(loadData)"></i> </div>
                                    <div> <i class="fa fa-trash icn" title="Delete" (click)="deleteDoc(loadData)"></i> </div>
                                    </div>
                            </td>
                          </tr>
                      </ng-template>
                      <ng-template pTemplate="emptymessage" let-loadData>
                          <tr>
                            <td [attr.colspan]="3" class="common-bene-class-for-translation" id="experienceDocsEmptyMessage">
                              No records found
                           </td>
                          </tr>
                        </ng-template>
                  </p-table>

                  <p-table [value]="loadData.Immigration" [resizableColumns]="true" styleClass="p-datatable-gridlines" *ngIf="document === 'My Immigration Documents'">
                      <ng-template pTemplate="header">
                          <tr>
                                <th style="width: 5%;"><p-checkbox *ngIf="bulkDownloadCheckboxes['Immigration']" value="true" inputId="documentTypeImmigration" binary="true" name="groupnameImmigration" [(ngModel)]="bulkDownloadCheckboxes['Immigration']['isAllSelected']"
                                (onChange)="selectAllDocs($event,bulkDownloadCheckboxes['Immigration'])" [disabled]="!loadData.Immigration?.length"></p-checkbox></th>
                                <th class="common-bene-class-for-translation" id="immigrationDocumentsType{{i}}">Type</th>
                                <th class="common-bene-class-for-translation" id="immigrationDocumentsName{{i}}">Name</th>
                                <th class="common-bene-class-for-translation" id="immigrationDocumentsAction{{i}}">Action</th>
                          </tr>
                      </ng-template>
                      <ng-template pTemplate="body" let-loadData>
                          <tr>
                            <td style="width:5%"><p-checkbox *ngIf="bulkDownloadCheckboxes['Immigration']" value="true" inputId="documentTypeImmigration{{loadData?.id}}" binary="true" name="groupname{{loadData?.id}}" [(ngModel)]="bulkDownloadCheckboxes['Immigration']['childItems'][loadData['itemIndex']]['isSelected']" (onChange)="selectDoc($event,bulkDownloadCheckboxes['Immigration'])"></p-checkbox></td>
                            <td class="common-bene-class-for-translation" id="immigrationDocType-{{loadData?.id}}-{{i}}">{{loadData?.fileCategory?.name}}</td>
                            <td class="common-bene-class-for-translation" id="immigrationDocName-{{loadData?.id}}-{{i}}">{{loadData?.fileName}}</td>
                              <td>
                                  <div class="row">
                                      <div><i class="fa fa-eye pr-2 icn" title="View" (click)="openDoc(loadData)"></i> </div>
                                      <div><i class="fa fa-download pr-2 icn" title="Download" (click)="download(loadData)"></i> </div>
                                      <div> <i class="fa fa-trash icn" title="Delete" (click)="deleteDoc(loadData)"></i> </div>
                                   </div>
                              </td>
                          </tr>
                      </ng-template>
                      <ng-template pTemplate="emptymessage" let-loadData>
                          <tr>
                            <td [attr.colspan]="3" class="common-bene-class-for-translation" id="immigrationDocsEmptyMessage">
                              No records found
                            </td>
                          </tr>
                        </ng-template>
                  </p-table>

                  <p-table  [value]="loadData.FBAdditionalDocuments" [resizableColumns]="true" styleClass="p-datatable-gridlines" *ngIf="document === 'Additional Documents'">
                    <ng-template pTemplate="header">
                        <tr>
                              <th style="width: 5%;"><p-checkbox *ngIf="bulkDownloadCheckboxes['FBAdditionalDocuments']" value="true" inputId="documentTypeFBAdditionalDocuments" binary="true" name="groupnameImmigration" [(ngModel)]="bulkDownloadCheckboxes['FBAdditionalDocuments']['isAllSelected']"
                              (onChange)="selectAllDocs($event,bulkDownloadCheckboxes['FBAdditionalDocuments'])" [disabled]="!loadData.FBAdditionalDocuments?.length"></p-checkbox></th>
                              <th class="common-bene-class-for-translation" id="additionalDocumentsType{{i}}">Type</th>
                              <th class="common-bene-class-for-translation" id="additionalDocumentsName{{i}}">Name</th>
                              <th class="common-bene-class-for-translation" id="additionalDocumentsAction{{i}}">Action</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-loadData>
                        <tr>
                          <td style="width:5%"><p-checkbox *ngIf="bulkDownloadCheckboxes['FBAdditionalDocuments']" value="true" inputId="documentTypeFBAdditionalDocuments{{loadData?.id}}" binary="true" name="groupname{{loadData?.id}}" [(ngModel)]="bulkDownloadCheckboxes['FBAdditionalDocuments']['childItems'][loadData['itemIndex']]['isSelected']" (onChange)="selectDoc($event,bulkDownloadCheckboxes['FBAdditionalDocuments'])"></p-checkbox></td>
                          <td class="common-bene-class-for-translation" id="additionalDocType-{{loadData?.id}}-{{i}}">{{loadData?.fileCategory?.name}}</td>
                          <td class="common-bene-class-for-translation" id="additionalDocName-{{loadData?.id}}-{{i}}">{{loadData?.fileName}}</td>
                            <td>
                                <div class="row">
                                  <div><i class="fa fa-eye pr-2 icn" title="View" (click)="openDoc(loadData)"></i> </div>
                                    <div><i class="fa fa-download pr-2 icn" title="Download" (click)="download(loadData)"></i> </div>
                                    <div> <i class="fa fa-trash icn" title="Delete" (click)="deleteDoc(loadData)"></i> </div>
                                 </div>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage" let-loadData>
                        <tr>
                          <td [attr.colspan]="3" class="common-bene-class-for-translation" id="additionalDocsEmptyMessage">
                            No records found
                         </td>
                        </tr>
                      </ng-template>
                </p-table>

              </div>
          </div>
      </div>
  </div>
</div>
