<div class="container-fluid">
    <div class="row pl--1rem8  d-flex align-items-center">
        <div class="col-md-6 pb-2">
            <small class="link" (click)="initiateNewRemediation()">
                Home > I9 Remediations > Initiate Remediation
            </small>
            <span class="normal-text">> Parsing Errors</span>
            <div class="page-header select-sec">
                <mat-form-field>
                    <mat-select [(ngModel)]="selectedDocumentType"
                        (selectionChange)="onDocumentTypeChange($event.value)">
                        <mat-option *ngFor="let option of options" [value]="option.value">
                            {{ option.viewValue }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="col-md-6 pb-2">
            <div class="col-4 pull-right">
                <div class="count-sec">
                    Done correcting all errors
                    <button mat-flat-button color="default" [disabled]="isContinueButtonActive()"
                        (click)="continueAnalysis()" class="btn btn-analysis">
                        Continue <mat-icon>keyboard_backspace</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="bgWhite mat-elevation-z8 w100 mt-3 p-4 position-relative">
        <div class="row p-0">
            <div class="col-md-6 pb-2">
                <div class="page-header">
                    <h2>{{ empName }}</h2>
                </div>
            </div>
        </div>
        <div class="row p-0">
            <div class="col-md-6 pb-2">
                <ng-container *ngIf="isPdf; else imageTemplate">
                    <pdf-viewer [src]="pdfSrc" [render-text]="true"
                        [ngClass]="{'pdf-sec': true, 'pdf-sec-large': selectedDocumentType === 'I-9'}">
                    </pdf-viewer>
                </ng-container>
                <ng-template #imageTemplate>
                    <div class="img-container">
                        <img [src]="pdfSrc" alt="Document Image" class="img-sec">
                    </div>
                </ng-template>
            </div>
            <ng-container *ngIf="selectedDocumentType != 'I-9'">
                <div class="col-md-6 pb-2">
                    <div class="doc-header mb-4">
                        <div class="col-8">
                            <div class="page-header">
                                <h2 class="m-0">{{ getSelectedDocumentViewValue() }}</h2>
                            </div>
                        </div>
                        <div class="col-4 error-container">
                            <div class="error-sect">
                                <mat-icon color="warn">warning</mat-icon> {{docError}} {{ docError === 1 ? 'Error' :
                                'Errors' }}
                            </div>
                        </div>
                    </div>
                    <div class="form-sec">
                        <form [formGroup]="documentForm">
                            <div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="documentName">Document Name</label>
                                            <!-- <input type="text" id="documentName" formControlName="documentName"
                                                class="form-control"> -->

                                            <p-dropdown [options]="documentTitles"
                                                formControlName="documentName"
                                                placeholder="Select a document type"></p-dropdown>
                                            <div *ngIf="documentForm.get('documentName').invalid && (documentForm.get('documentName').dirty || documentForm.get('documentName').touched)"
                                                class="error">
                                                Document Name is required.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="documentNumber">Document Number</label>
                                            <input type="text" id="documentNumber" formControlName="documentNumber"
                                                class="form-control">
                                            <div *ngIf="documentForm.get('documentNumber').invalid && (documentForm.get('documentNumber').dirty || documentForm.get('documentNumber').touched)"
                                                class="error">
                                                Document Number is required.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="issuingAuthority">Issuing Authority</label>
                                            <input type="text" id="issuingAuthority" formControlName="issuingAuthority"
                                                class="form-control">
                                            <div *ngIf="documentForm.get('issuingAuthority').invalid && (documentForm.get('issuingAuthority').dirty || documentForm.get('issuingAuthority').touched)"
                                                class="error">
                                                Issuing Authority is required.
                                            </div>
                                        </div>
                                    </div>
                                    <ng-container *ngIf="selectedDocumentType != 'SSN'">
                                        <div class="col-md-6">
                                            <div class="form-group calendar-sec">
                                                <label for="expiryDate">Expiry Date</label>
                                                <!-- <input type="date" id="expiryDate" formControlName="expiryDate" class="form-control"> -->
                                                <p-calendar id="expiryDate" class="form-control"
                                                    formControlName="expiryDate" 
                                                    [yearNavigator]="true" [monthNavigator]="true"
                                                    [yearRange]="'1900:2050'" [showIcon]="true"
                                                    placeholder="MM/DD/YYYY"></p-calendar>
                                                <div *ngIf="documentForm.get('expiryDate').invalid && (documentForm.get('expiryDate').dirty || documentForm.get('expiryDate').touched)"
                                                    class="error">
                                                    Expiry Date is required.
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="form-group ml-3">
                                <button type="button" class="btn btn-primary" (click)="onSave()">Save</button>
                                <button type="button" class="btn btn-secondary" (click)="onSkip()">Skip</button>
                            </div>
                        </form>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="selectedDocumentType == 'I-9'">
                <div class="col-md-6 pb-2">
                    <div class="doc-header mb-4">
                        <div class="col-7">
                            <div class="page-header">
                                <h2 class="m-0">{{ getSelectedDocumentViewValue() }}</h2>
                            </div>
                        </div>
                        <div class="col-2 error-container pr-0">
                            <div class="error-sect">
                                <mat-icon color="warn">warning</mat-icon> {{docError}} {{ docError === 1 ? 'Error' :
                                'Errors' }}
                            </div>
                        </div>
                        <div class="col-3 error-container pr-0">
                            <button type="button" class="btn btn-primary pull-right" (click)="submitSections()">Save
                                All</button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group custom-radio-group mb-4">
                                <label for="primaryI9" class="mr-2 font-weight-bold">Is this Primary I-9 ?</label>
                                <mat-radio-group [(ngModel)]="primaryI9" class="radio-grp-sec">
                                    <div class="col-1 pl-0">
                                        <label class="custom-radio-label pl-0">
                                            <mat-radio-button value="yes">Yes</mat-radio-button>
                                        </label>
                                    </div>
                                    <div class="col-1 pl-0">
                                        <label class="custom-radio-label">
                                            <mat-radio-button value="no">No </mat-radio-button>
                                        </label>
                                    </div>
                                </mat-radio-group>
                            </div>
                        </div>
                    </div>
                    <div>
                        <mat-accordion>
                            <mat-expansion-panel [expanded]="true">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        Section 1. Employee Information and Attestation
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <form class="form-sec" [formGroup]="documentForm">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="lastName">Last Name (Family Name)</label>
                                                <input type="text" formControlName="lastName" class="form-control">
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="firstName">First Name (Given Name)</label>
                                                <input type="text" formControlName="firstName" class="form-control">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="middleName">Middle Initial (if any)</label>
                                                <input type="text" formControlName="middleName" class="form-control">
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="otherLastName">Other Last Names Used (if any)</label>
                                                <input type="text" formControlName="otherLastName" class="form-control">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="address1">Address (Street Number and Name)</label>
                                                <input type="text" formControlName="address1" class="form-control">
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="address2">Apt. Number (if any)</label>
                                                <input type="text" formControlName="address2" class="form-control">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="city">City or Town</label>
                                                <input type="text" formControlName="city" class="form-control">
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="state">State</label>
                                                <input type="text" formControlName="state" class="form-control">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="zipCode">ZIP Code</label>
                                                <input type="text" formControlName="zipCode" class="form-control">
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group calendar-sec">
                                                <label for="dob">Date of Birth (mm/dd/yyyy)</label>
                                                <p-calendar class="form-control" formControlName="dob"
                                                     placeholder="MM/DD/YYYY"
                                                    [yearNavigator]="true" [monthNavigator]="true"
                                                    [yearRange]="'1900:2050'" [showIcon]="true" [readonlyInput]="true">
                                                </p-calendar>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="ssn">U.S. Social Security Number</label>
                                                <input type="text" formControlName="ssn" class="form-control">
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="email">Employee's Email Address</label>
                                                <input type="text" formControlName="email" class="form-control">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="telNumber">Employee's Telephone Number</label>
                                                <input type="text" formControlName="telNumber" class="form-control">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group custom-radio-group mb-4">
                                                <mat-radio-group formControlName="selectedList" class="radio-grp-sec">
                                                    <div class="col-3 pl-0">
                                                        <label class="custom-radio-label pl-0">
                                                            <mat-radio-button value="A">List A</mat-radio-button>
                                                        </label>
                                                    </div>
                                                    <div class="col-3 pl-0">
                                                        <label class="custom-radio-label">
                                                            <mat-radio-button value="BC">List B & List
                                                                C</mat-radio-button>
                                                        </label>
                                                    </div>
                                                </mat-radio-group>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div
                                            *ngIf="documentForm.get('selectedList')?.value === 'A' || documentForm.get('selectedList')?.value === 'BC'">
                                            <div class="col-md-12">
                                                <div class="form-group custom-radio-group vertical-radio">
                                                    <mat-radio-group formControlName="selectedABList"
                                                        class="radio-grp-sect">
                                                        <label class="custom-radio-label pl-0">
                                                            <mat-radio-button value="CITZN">A citizen of the United
                                                                States</mat-radio-button>
                                                        </label>
                                                        <label class="custom-radio-label pl-0">
                                                            <mat-radio-button value="NONCITZNNATIONAL">A noncitizen
                                                                national
                                                                of the United
                                                                States</mat-radio-button>
                                                        </label>
                                                        <label class="custom-radio-label pl-0">
                                                            <mat-radio-button value="PERMRESIDENT">A lawful permanent
                                                                resident</mat-radio-button>
                                                        </label>
                                                        <label class="custom-radio-label pl-0">
                                                            <mat-radio-button value="WORKAUTHALIEN">A noncitizen
                                                                authorized to
                                                                work</mat-radio-button>
                                                        </label>
                                                    </mat-radio-group>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row"
                                        *ngIf="documentForm.get('selectedABList')?.value === 'PERMRESIDENT'">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="uscisAlienNumber">USCIS Number / Alien Number</label>
                                                <input type="text" formControlName="uscisNumber" class="form-control">
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="issuance">Country of Issuance</label>
                                                <input type="text" formControlName="issuance" class="form-control">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row"
                                        *ngIf="documentForm.get('selectedABList')?.value === 'WORKAUTHALIEN'">
                                        <div class="col-md-6">
                                            <div class="form-group calendar-sec">
                                                <label for="workAuthExpiryDate">Work Authorization Expiry Date
                                                    (mm/dd/yyyy)</label>
                                                <p-calendar class="form-control" formControlName="workAuthExpiryDate"
                                                     placeholder="MM/DD/YYYY"
                                                    [yearNavigator]="true" [monthNavigator]="true"
                                                    [yearRange]="'1900:2050'" [showIcon]="true" [readonlyInput]="true">
                                                </p-calendar>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="uscisi94AlienNumber">USCIS Number / I-94 / Alien
                                                    Number</label>
                                                <input type="text" formControlName="uscisi94AlienNumber"
                                                    class="form-control">
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="forgnPassNum">Foreign Passport Number</label>
                                                <input type="text" formControlName="forgnPassNum" class="form-control">
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="issuance">Country of Issuance</label>
                                                <input type="text" formControlName="issuance" class="form-control">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="employee-signature">Signature of Employee</label>
                                                <p-dropdown [options]="signatureOptions"
                                                    formControlName="selectedSignature"
                                                    placeholder="Select a status"></p-dropdown>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group calendar-sec">
                                                <label for="todayDate">Today's Date (mm/dd/yyyy)</label>
                                                <p-calendar class="form-control" formControlName="todayDate"
                                                     placeholder="MM/DD/YYYY"
                                                    [yearNavigator]="true" [monthNavigator]="true"
                                                    [yearRange]="'1900:2050'" [showIcon]="true" [readonlyInput]="true">
                                                </p-calendar>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="form-group ml-3">
                                        <button type="button" class="btn btn-primary" (click)="onSave()">Save</button>
                                        <button type="button" class="btn btn-secondary" (click)="onSkip()">Skip</button>
                                    </div> -->
                                </form>
                            </mat-expansion-panel>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        Section 2. Employer Review and Verification
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <form [formGroup]="section2Form" class="form-sec">
                                    <fieldset class="border-0 py-0">
                                        <div class="mb-4">
                                            <h5>Section 2. Employer Review and Verification</h5>
                                            <p> Employers or their authorized representative
                                                must complete and sign
                                                Section 2
                                                within three
                                                business days after the employee's first day of employment, and must
                                                physically examine, or
                                                examine
                                                consistent
                                                with an alternative procedure
                                                authorized by the Secretary of DHS, documentation from List A OR a
                                                combination of
                                                documentation from
                                                List B and
                                                List C. Enter any additional
                                                documentation in the Additional Information box; see Instructions.
                                            </p>
                                        </div>

                                        <div>
                                            <!-- Radio Buttons for List A or List B and List C -->
                                            <div>
                                                <label class="mr-5">
                                                    <input type="radio" formControlName="listType" value="A" />
                                                    <label class="pl-2">List A</label>
                                                </label>
                                                <label>
                                                    <input type="radio" formControlName="listType" value="BC" />
                                                    <label class="pl-2">List B and C</label>
                                                </label>
                                            </div>

                                            <div class="listA-documents"
                                                *ngIf="section2Form.get('listType')?.value === 'A'">
                                                <h6>Enter document details for List A</h6>
                                                <div class="row marg15">
                                                    <div class="col-md-4">
                                                        <div class="form-group">
                                                            <label for="documentTitle1">Document Title 1</label>
                                                            <!-- <input id="documentTitle1"
                                                                formControlName="documentTitle1" /> -->
                                                            <p-dropdown [options]="listAdocumentTitles"
                                                                formControlName="documentTitle1"
                                                                placeholder="Select a document type"></p-dropdown>
                                                        </div>
                                                        <div class="form-group">
                                                            <label for="issuingAuthority1">Issuing
                                                                Authority</label>
                                                            <input id="issuingAuthority1"
                                                                formControlName="issuingAuthority1" />
                                                        </div>
                                                        <div class="form-group">
                                                            <label for="documentNumber1">Document Number (if
                                                                any)</label>
                                                            <input id="documentNumber1"
                                                                formControlName="documentNumber1" />
                                                        </div>
                                                        <div class="form-group calendar-sec">
                                                            <label for="expirationDate1">Expiration Date (if
                                                                any)</label>
                                                            <!-- <input id="expirationDate1"
                                                                formControlName="expirationDate1" type="date" /> -->
                                                            <p-calendar class="form-control"
                                                                formControlName="expirationDate1"
                                                                 placeholder="MM/DD/YYYY"
                                                                [yearNavigator]="true" [monthNavigator]="true"
                                                                [yearRange]="'1900:2050'" [showIcon]="true"
                                                                [readonlyInput]="true">
                                                            </p-calendar>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-4">
                                                        <div class="form-group">
                                                            <label for="documentTitle2">Document Title 2 (if
                                                                any)</label>
                                                            <!-- <input id="documentTitle2"
                                                                formControlName="documentTitle2" /> -->

                                                            <p-dropdown [options]="listAdocumentTitles"
                                                            formControlName="documentTitle2"
                                                            placeholder="Select a document type"></p-dropdown>
                                                        </div>
                                                        <div class="form-group">
                                                            <label for="issuingAuthority2">Issuing
                                                                Authority</label>
                                                            <input id="issuingAuthority2"
                                                                formControlName="issuingAuthority2" />
                                                        </div>
                                                        <div class="form-group">
                                                            <label for="documentNumber2">Document Number (if
                                                                any)</label>
                                                            <input id="documentNumber2"
                                                                formControlName="documentNumber2" />
                                                        </div>
                                                        <div class="form-group calendar-sec">
                                                            <label for="expirationDate2">Expiration Date (if
                                                                any)</label>
                                                            <!-- <input id="expirationDate2"
                                                                formControlName="expirationDate2" type="date" /> -->
                                                            <p-calendar class="form-control"
                                                                formControlName="expirationDate2"
                                                                 placeholder="MM/DD/YYYY"
                                                                [yearNavigator]="true" [monthNavigator]="true"
                                                                [yearRange]="'1900:2050'" [showIcon]="true"
                                                                [readonlyInput]="true">
                                                            </p-calendar>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-4">
                                                        <div class="form-group">
                                                            <label for="documentTitle3">Document Title 3 (if
                                                                any)</label>
                                                            <!-- <input id="documentTitle3"
                                                                formControlName="documentTitle3" /> -->

                                                            <p-dropdown [options]="listAdocumentTitles"
                                                            formControlName="documentTitle3"
                                                            placeholder="Select a document type"></p-dropdown>
                                                        </div>
                                                        <div class="form-group">
                                                            <label for="issuingAuthority3">Issuing
                                                                Authority</label>
                                                            <input id="issuingAuthority3"
                                                                formControlName="issuingAuthority3" />
                                                        </div>
                                                        <div class="form-group">
                                                            <label for="documentNumber3">Document Number (if
                                                                any)</label>
                                                            <input id="documentNumber3"
                                                                formControlName="documentNumber3" />
                                                        </div>
                                                        <div class="form-group calendar-sec">
                                                            <label for="expirationDate3">Expiration Date (if
                                                                any)</label>
                                                            <!-- <input id="expirationDate3"
                                                                formControlName="expirationDate3" type="date" /> -->
                                                            <p-calendar class="form-control"
                                                                formControlName="expirationDate3"
                                                                 placeholder="MM/DD/YYYY"
                                                                [yearNavigator]="true" [monthNavigator]="true"
                                                                [yearRange]="'1900:2050'" [showIcon]="true"
                                                                [readonlyInput]="true">
                                                            </p-calendar>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="listBC-documents row"
                                                *ngIf="section2Form.get('listType')?.value === 'BC'">
                                                <div class="col-md-6 pl-0">
                                                    <h6>Enter document details for List B</h6>
                                                    <div class="form-group">
                                                        <label for="documentTitle1">Document Title 1</label>
                                                        <!-- <input id="documentTitle1" formControlName="documentTitle1" /> -->

                                                        <p-dropdown [options]="listBdocumentTitles"
                                                        formControlName="documentTitle1"
                                                        placeholder="Select a document type"></p-dropdown>
                                                    </div>
                                                    <div class="form-group">
                                                        <label for="issuingAuthority1">Issuing Authority</label>
                                                        <input id="issuingAuthority1"
                                                            formControlName="issuingAuthority1" />
                                                    </div>
                                                    <div class="form-group">
                                                        <label for="documentNumber1">Document Number (if
                                                            any)</label>
                                                        <input id="documentNumber1" formControlName="documentNumber1" />
                                                    </div>
                                                    <div class="form-group calendar-sec">
                                                        <label for="expirationDate1">Expiration Date (if
                                                            any)</label>
                                                        <!-- <input id="expirationDate1" formControlName="expirationDate1"
                                                            type="date" /> -->
                                                        <p-calendar class="form-control"
                                                            formControlName="expirationDate1"
                                                            placeholder="MM/DD/YYYY" [yearNavigator]="true"
                                                            [monthNavigator]="true" [yearRange]="'1900:2050'"
                                                            [showIcon]="true" [readonlyInput]="true">
                                                        </p-calendar>
                                                    </div>
                                                </div>
                                                <div class="col-md-6 pr-0">
                                                    <h6>Enter document details for List C</h6>
                                                    <div class="form-group">
                                                        <label for="documentTitle2">Document Title 2 (if
                                                            any)</label>
                                                        <!-- <input id="documentTitle2" formControlName="documentTitle2" /> -->

                                                        <p-dropdown [options]="listCdocumentTitles"
                                                        formControlName="documentTitle2"
                                                        placeholder="Select a document type"></p-dropdown>
                                                    </div>
                                                    <div class="form-group">
                                                        <label for="issuingAuthority2">Issuing Authority</label>
                                                        <input id="issuingAuthority2"
                                                            formControlName="issuingAuthority2" />
                                                    </div>
                                                    <div class="form-group">
                                                        <label for="documentNumber2">Document Number (if
                                                            any)</label>
                                                        <input id="documentNumber2" formControlName="documentNumber2" />
                                                    </div>
                                                    <div class="form-group calendar-sec">
                                                        <label for="expirationDate2">Expiration Date (if
                                                            any)</label>
                                                        <!-- <input id="expirationDate2" formControlName="expirationDate2"
                                                            type="date" /> -->
                                                        <p-calendar class="form-control"
                                                            formControlName="expirationDate2"
                                                            placeholder="MM/DD/YYYY" [yearNavigator]="true"
                                                            [monthNavigator]="true" [yearRange]="'1900:2050'"
                                                            [showIcon]="true" [readonlyInput]="true">
                                                        </p-calendar>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group">
                                                <label for="additionalInfo">Additional Information</label>
                                                <textarea id="additionalInfo"
                                                    formControlName="additionalInfo"></textarea>
                                            </div>
                                            <div class="form-group">
                                                <input id="alternativeProcedure" type="checkbox"
                                                    formControlName="alternativeProcedure" />
                                                <label class="pl-2" for="alternativeProcedure">Check here if you used an
                                                    alternative procedure authorized
                                                    by DHS to
                                                    examine
                                                    documents.</label>
                                            </div>
                                        </div>


                                        <fieldset class="border-0 p-0">
                                            <div class="mb-4">
                                                <h6>Certification </h6>
                                                <p> I attest, under penalty of perjury, that
                                                    (1) I have examined the
                                                    documentation
                                                    presented by the
                                                    above-named
                                                    employee, (2) the above-listed documentation appears to be
                                                    genuine and to relate to the
                                                    employee
                                                    named, and (3) to the
                                                    best of my knowledge, the employee is authorized to work in the
                                                    United States. </p>
                                            </div>
                                            <div class="form-group calendar-sec">
                                                <label for="firstDayOfEmployment">First Day of Employment
                                                    (mm/dd/yyyy)</label>
                                                <!-- <input id="firstDayOfEmployment" formControlName="firstDayOfEmployment"
                                                    type="date" [dateFormat]="'mm/dd/yy'" /> -->
                                                <p-calendar class="form-control" formControlName="firstDayOfEmployment"
                                                     placeholder="MM/DD/YYYY"
                                                    [yearNavigator]="true" [monthNavigator]="true"
                                                    [yearRange]="'1900:2050'" [showIcon]="true" [readonlyInput]="true">
                                                </p-calendar>
                                            </div>

                                            <div class="form-group">
                                                <label for="employerName">Last Name, First Name and Title of
                                                    Employer or Authorized
                                                    Representative</label>
                                                <input id="employerName" formControlName="employerName" />
                                            </div>

                                            <div class="form-group">
                                                <label class="pr-2" for="employerSignature">Signature of Employer or
                                                    Authorized
                                                    Representative</label>
                                                <input id="employerSignature" type="checkbox"
                                                    formControlName="employerSignature" />
                                            </div>
                                            <div class="form-group calendar-sec">
                                                <label for="employerTodaysDate">Today's Date (mm/dd/yyyy)</label>
                                                <p-calendar class="form-control" formControlName="employerTodaysDate"
                                                     placeholder="MM/DD/YYYY"
                                                    [yearNavigator]="true" [monthNavigator]="true"
                                                    [yearRange]="'1900:2050'" [showIcon]="true" [readonlyInput]="true">
                                                </p-calendar>
                                            </div>
                                            <div class="form-group">
                                                <label for="businessName">Employer's Business or Organization
                                                    Name</label>
                                                <input id="businessName" formControlName="businessName" />
                                            </div>
                                            <div class="form-group">
                                                <label for="businessAddress">Employer's Business or Organization
                                                    Address, City or Town, State,
                                                    ZIP
                                                    Code</label>
                                                <input id="businessAddress" formControlName="businessAddress" />
                                            </div>
                                        </fieldset>
                                    </fieldset>
                                </form>
                            </mat-expansion-panel>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        Supplement B. Reverification and Rehires
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <form [formGroup]="supplementBForm">
                                    <fieldset class="border-0 py-0">
                                        <h5>Supplement B, Reverification and Rehire (formerly Section 3)</h5>

                                        <div class="row">
                                            <div class="col-md-4 pl-0">
                                                <label for="lastName">Last Name (Family Name) from Section
                                                    1</label>
                                                <input id="lastName" formControlName="lastName" class="m-0" />
                                            </div>
                                            <div class="col-md-4 pl-0">
                                                <label for="firstName">First Name (Given Name) from Section
                                                    1</label>
                                                <input id="firstName" formControlName="firstName" class="m-0" />
                                            </div>
                                            <div class="col-md-4 pl-0">
                                                <label for="middleInitial">Middle Initial (if any) from Section
                                                    1</label>
                                                <input id="middleInitial" formControlName="middleInitial" class="m-0" />
                                            </div>
                                        </div>
                                        <div class="mb-4">
                                            <fieldset class="border-0 px-0">
                                                <h6>Instructions</h6>
                                                <p class="info-text"> This supplement replaces Section 3 on the
                                                    previous version of Form
                                                    I-9. Only
                                                    use this page if your employee requires
                                                    reverification, is rehired within three years of the date the
                                                    original Form I-9 was
                                                    completed, or
                                                    provides proof of a legal name change. Enter
                                                    the employee's name in the fields above. Use a new section for
                                                    each reverification or
                                                    rehire. Review
                                                    the Form I-9 instructions before
                                                    completing this page. Keep this page as part of the employee's
                                                    Form I-9 record. Additional
                                                    guidance
                                                    can be found in the
                                                    <a href="https://www.uscis.gov/i-9-central/form-i-9-resources/handbook-for-employers-m-274"
                                                        target="_blank">Handbook for Employers: Guidance for
                                                        Completing Form I-9 (M-274)</a>
                                                </p>
                                            </fieldset>
                                        </div>

                                        <fieldset class="border-0 p-0">
                                            <div formArrayName="rehireRecords">
                                                <div class="mb-4 each-rehire-section"
                                                    *ngFor="let record of rehireRecords.controls; let i = index"
                                                    [formGroupName]="i">
                                                    <div class="row mb-4">
                                                        <div class="col-md-8 pl-0">
                                                            <h5 class="m-0">Rehire {{i+1}}</h5>
                                                        </div>
                                                        <div class="col-md-4 pr-0 text-right">
                                                            <button type="button"
                                                                (click)="deleteRehireRecord(i)">Delete</button>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-md-3 pl-0 calendar-sec">
                                                            <label for="rehireDate{{i}}">Date of Rehire (if
                                                                applicable):</label>
                                                            <!-- <input id="rehireDate{{i}}" formControlName="rehireDate"
                                                                type="date" /> -->
                                                            <p-calendar class="form-control"
                                                                formControlName="rehireDate"
                                                                placeholder="MM/DD/YYYY" [yearNavigator]="true"
                                                                [monthNavigator]="true" [yearRange]="'1900:2050'"
                                                                [showIcon]="true" [readonlyInput]="true"
                                                                id="rehireDate{{i}}">
                                                            </p-calendar>
                                                        </div>
                                                        <div class="col-md-3 pl-0">
                                                            <label for="newLastName{{i}}">New Last Name (if
                                                                applicable):</label>
                                                            <input id="newLastName{{i}}"
                                                                formControlName="newLastName" />
                                                        </div>
                                                        <div class="col-md-3 pl-0">
                                                            <label for="newFirstName{{i}}">New First Name (if
                                                                applicable):</label>
                                                            <input id="newFirstName{{i}}"
                                                                formControlName="newFirstName" />
                                                        </div>
                                                        <div class="col-md-3 pl-0">
                                                            <label for="newMiddleInitial{{i}}">New Middle Initial
                                                                (if applicable):</label>
                                                            <input id="newMiddleInitial{{i}}"
                                                                formControlName="newMiddleInitial" />
                                                        </div>
                                                    </div>


                                                    <div class="mb-4">
                                                        <h6>Reverification: </h6>
                                                        <span class="info-text"> If the employee requires
                                                            reverification, your employee can choose
                                                            to
                                                            present any acceptable List A or List C documentation to
                                                            show
                                                            continued employment authorization. Enter the document
                                                            information in the spaces
                                                            below.</span>
                                                    </div>

                                                    <div class="row">
                                                        <div class="col-md-4 p-1">
                                                            <label for="documentTitle{{i}}">Document Title:</label>
                                                            <!-- <input id="documentTitle{{i}}"
                                                                formControlName="documentTitle" /> -->

                                                            <p-dropdown [options]="documentTitles"
                                                            formControlName="documentTitle"
                                                            placeholder="Select a document type"></p-dropdown>
                                                        </div>
                                                        <div class="col-md-4 p-1">
                                                            <label for="documentNumber{{i}}">Document Number:</label>
                                                            <input id="documentNumber{{i}}"
                                                                formControlName="documentNumber" />
                                                        </div>
                                                        <div class="col-md-4 p-1 calendar-sec">
                                                            <label for="expirationDate{{i}}">Expiration Date:</label>
                                                            <!-- <input id="expirationDate{{i}}"
                                                                formControlName="expirationDate" type="date" /> -->
                                                            <p-calendar class="form-control"
                                                                formControlName="expirationDate"
                                                                 placeholder="MM/DD/YYYY"
                                                                [yearNavigator]="true" [monthNavigator]="true"
                                                                [yearRange]="'1900:2050'" [showIcon]="true"
                                                                [readonlyInput]="true" id="expirationDate{{i}}">
                                                            </p-calendar>
                                                        </div>
                                                    </div>

                                                    <div class="mb-4">
                                                        <span class="info-text">I attest, under penalty of perjury,
                                                            that to the best of my
                                                            knowledge, this
                                                            employee is authorized to work in the United States, and
                                                            if the
                                                            employee presented documentation, the documentation I
                                                            examined appears to be genuine and
                                                            to
                                                            relate to the individual who presented it</span>
                                                    </div>

                                                    <div class="row">
                                                        <div class="col-md-4 pl-0">
                                                            <label for="employerName{{i}}">Name of Employer or
                                                                Authorized Representative:</label>
                                                            <input id="employerName{{i}}"
                                                                formControlName="employerName" />
                                                        </div>
                                                        <div class="col-md-4 pl-0">
                                                            <label for="employerSignature{{i}}">Signature of
                                                                Employer or Authorized
                                                                Representative:</label>
                                                            <input id="employerSignature{{i}}" type="checkbox"
                                                                formControlName="employerSignature" />
                                                        </div>
                                                        <div class="col-md-4 pl-0 calendar-sec">
                                                            <label for="todayDate{{i}}">Today's Date
                                                                (mm/dd/yyyy):</label>
                                                            <p-calendar class="form-control" formControlName="todayDate"
                                                                 placeholder="MM/DD/YYYY"
                                                                [yearNavigator]="true" [monthNavigator]="true"
                                                                [yearRange]="'1900:2050'" [showIcon]="true"
                                                                [readonlyInput]="true" id="todayDate{{i}}">
                                                            </p-calendar>
                                                        </div>
                                                    </div>

                                                    <div class="row">
                                                        <div class="col-md-12 pl-0">
                                                            <label for="additionalInfo{{i}}">Additional Information
                                                                (Initial and date each
                                                                notation):</label>
                                                            <textarea id="additionalInfo{{i}}"
                                                                formControlName="additionalInfo"></textarea>
                                                        </div>
                                                        <div class="pl-0">
                                                            <input id="alternativeProcedure{{i}}" type="checkbox"
                                                                formControlName="alternativeProcedure" />
                                                            <label for="alternativeProcedure{{i}}">Check here if you
                                                                used an alternative procedure
                                                                authorized by
                                                                DHS to examine documents.</label>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </fieldset>

                                        <button type="button" (click)="addRehireRecord()"
                                            [disabled]="rehireRecords.length >= 3" class="mr-4 mt-4">Add
                                            Rehire</button>
                                    </fieldset>
                                </form>
                            </mat-expansion-panel>
                        </mat-accordion>
                        <div class="col-12 p-0">
                            <button type="button" class="m-0 mt-4 mb-4 btn btn-primary pull-right"
                                (click)="submitSections()">Save
                                All</button>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="row p-0">
            <div class="bottom-sec">
                <div class="doc-sect">
                    <mat-form-field>
                        <mat-select [(ngModel)]="selectedDocumentType"
                            (selectionChange)="onDocumentTypeChange($event.value)">
                            <mat-option *ngFor="let option of options" [value]="option.value">
                                {{ option.viewValue }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <button type="button" class="btn btn-primary btn-next" (click)="goToNextDocument()">
                        <mat-icon>keyboard_backspace</mat-icon>
                    </button>
                </div>
                <div class="pagination-buttons">
                    <button type="button" class="btn btn-primary" (click)="goToPrevEmp()"
                        [disabled]="currentIndex === 0">
                        <mat-icon>keyboard_backspace</mat-icon> Previous Employee
                    </button>
                    <span *ngIf="!isLastEmployee()">{{ currentIndex + 1 }}/{{ employees.length }}</span>
                    <button *ngIf="!isLastEmployee()" type="button" class="btn btn-primary next-btn"
                        (click)="goToNextEmp()" [disabled]="currentIndex === employees.length - 1">
                        Next Employee <mat-icon>keyboard_backspace</mat-icon>
                    </button>
                    <div *ngIf="isLastEmployee() && docError==0" class="completion-message">
                        <span>{{ employees.length }}/{{ employees.length }}</span> Employees {{
                        getSelectedDocumentViewValue() }}
                        Details Corrected
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>