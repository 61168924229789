import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProgressService {
  private progressSubject = new BehaviorSubject<number>(0);

  get progress$() {
    return this.progressSubject.asObservable();
  }

  setProgress(progress: number) {
    this.progressSubject.next(progress);
  }
}
