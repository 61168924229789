<div class="container-fluid">


    <div class="row my-4">
        <div class="col-md-9">
            <h3>Forms/Visas we support</h3>
        </div>
        <div class="col-md-3">
            <div class="form-group has-search-right">
                <a href="#" class="form-control-feedback"><span class="fa fa-search"></span></a>
                <input type="text" (keyup)="applyFilter($event)" class="form-control" placeholder="Search Forms/Visas">
              </div>             
        </div>
    </div>

    <ng-container *ngIf="totalRecordCount > 0">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
      
          <!-- Forms Column -->
          <ng-container matColumnDef="formName">
            <th mat-header-cell *matHeaderCellDef class="form-name-column"> Forms </th>
            <td mat-cell *matCellDef="let element" class="form-name-column">
                <h6 class="mt-3 mb-2">{{ element.formName }} | {{ element.formShortDesc }}</h6>
                <p>{{element.formLongDesc}}</p>
            </td>
          </ng-container>    
         
      
          <!-- Visas Column -->
          <ng-container matColumnDef="refCodes">
            <th mat-header-cell *matHeaderCellDef class="ref-codes-column"> Visas </th>
            <td mat-cell *matCellDef="let element" class="ref-codes-column">{{ addSpaceAfterComma(element.refCodes) }}</td>
          </ng-container>
      
          <!-- Table Row Definitions -->
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <div *ngIf="dataSource.filteredData.length === 0" class="no-data">
            <h6>No Data Found!</h6>
        </div>

      </ng-container>

    <div class="no-data" *ngIf="totalRecordCount === 0">
        <h6>No Data Found!</h6>
    </div>

</div>
