<div class="container-fluid" id="request-data-document">

  <!-- Request Data Title Start -->
  <div class="row col-md-12 p-0" [hidden]="isBeneficiaryQuestionnaireStatus || isWizard" *ngIf="!isCaseRequestSatus">
    <div class="col-sm-6 p-0">
      <div class="mat-title titleColor font-weight-bold">Request Data and Documents</div>
      <div class="mat-subheading-1 titleColor">Request data from beneficiary to upload in petition</div>
    </div>
    <div class="col-sm-6 px-0 py-2 text-right">
      <!-- <button class="btn btn-primary mr-2" (click)="viewEdit()" *ngIf="beneReqLinkDetails?.AccessToken && userType ==='Petitioner'"  [disabled]="isViewEditDisabled" [pTooltip]="viewEditTooltipText" tooltipPosition="top" [tooltipDisabled]="!isViewEditDisabled" tooltipStyleClass="view-edit-quest-tooltip" [escape]="false" >View and Edit</button> -->
      <button class="btn btn-primary mr-2" (click)="viewEdit()" *ngIf="userType !=='Beneficairy'">View and Edit</button>
      <button class="btn btn-primary" (click)="reqLink()">Beneficiary Request Link</button>
    </div>
  </div>
  <div class="row col-md-12 p-0" *ngIf="isCaseRequestSatus || isBeneficiaryQuestionnaireStatus">
    <div class="col-sm-6 p-0" [hidden]="isViewDataLogData">
      <div class="mat-title titleColor font-weight-bold" *ngIf="isCaseRequestSatus">Create New Questions</div>
      <div class="mat-title titleColor font-weight-bold" *ngIf="isBeneficiaryQuestionnaireStatus">Beneficiary questionnaire library creation</div>
      <div class="mat-subheading-1 titleColor">
        <a class="backClick" (click)="onClickBack()">
          <mat-icon>keyboard_arrow_left</mat-icon><span>Back</span>
        </a>
      </div>
      <!-- <a class="link back-button pl-0 pr-3 back-link back-btn-white-bg" routerLink="../companies">
              <i class="fa fa-chevron-circle-left " aria-hidden="true"></i> Back</a> -->
    </div>
  </div>
  <!-- Request Data Title End -->

  <!-- Request Data Body Start -->
  <div class="bgWhite bxShadow mHeight">

    <!-- Visa Type Start -->
    <div class="row type-portion" [hidden]="taskLevelStatus || isWizard">
      <div class="col-md-12 p-0" *ngIf="!isCaseRequestSatus">
          <h6 *ngIf="!isViewDataLogData">What is the visa type you are planning to file ?</h6>
          <h6 *ngIf="isViewDataLogData">Petition Details</h6>
      </div>
      <div class="col-md-12 p-0" *ngIf="isCaseRequestSatus">
        <h6>Select the petition details to add the question</h6>
      </div>
      <div class="col-md-12 p-0">
        <form #f="ngForm" class="visa-type-main">
          <div class="visa-type">
            <label class="required">Task Type</label>
            <p-dropdown class="d-block w-100" [options]="taskTypeList" [(ngModel)]="requestData.superTaskTypeId"
              optionLabel="name" required placeholder="Select" (ngModelChange)="onTaskTypeChange()" name="taskType"
              #taskType="ngModel" optionValue="code" [disabled]="(isCaseRequestSatus && isCaseRequestEditSatus) || (isBeneficiaryQuestionnaireStatus && isBeneficiaryQuestionnaireEditSatus) || taskLevelStatus">
            </p-dropdown>
            <span class="invalid-message" *ngIf="(taskType.dirty || taskType.touched) && taskType.errors?.required">
              {{ getGlobalErrorMessages("REQUIRED") }}
            </span>
          </div>
          <div class="visa-type" *ngIf="requestData.superTaskTypeId && requestData.superTaskTypeId === 'NONIMMPET'">
            <label class="required">Visa Type</label>
            <p-dropdown class="d-block w-100" [options]="visaTypeList" [(ngModel)]="requestData.visaType"
              optionLabel="name" required placeholder="Select" (ngModelChange)="getPetitionList()" name="visaType"
              #visaType="ngModel" optionValue="code" [disabled]="(isCaseRequestSatus && isCaseRequestEditSatus) || (isBeneficiaryQuestionnaireStatus && isBeneficiaryQuestionnaireEditSatus) || taskLevelStatus">
            </p-dropdown>
            <span class="invalid-message" *ngIf="(visaType.dirty || visaType.touched) && visaType.errors?.required">
              {{ getGlobalErrorMessages("REQUIRED") }}
            </span>
          </div>
          <div class="visa-type" *ngIf="requestData.superTaskTypeId && requestData.superTaskTypeId === 'IMMPET'">
            <label class="required">Immigration Category</label>
            <p-dropdown class="d-block w-100" [options]="immigrationTypeList" [(ngModel)]="requestData.immCategoryType"
              optionLabel="name" required placeholder="Select" (ngModelChange)="getImmigrationClassification()"
              name="immCategoryType" #immCategoryType="ngModel" optionValue="code" [disabled]="(isCaseRequestSatus && isCaseRequestEditSatus) || (isBeneficiaryQuestionnaireStatus && isBeneficiaryQuestionnaireEditSatus) || taskLevelStatus">
            </p-dropdown>
            <span class="invalid-message"
              *ngIf="(immCategoryType.dirty || immCategoryType.touched) && immCategoryType.errors?.required">
              {{ getGlobalErrorMessages("REQUIRED") }}
            </span>
          </div>
          <div class="visa-type" *ngIf="!(requestData.superTaskTypeId && requestData.superTaskTypeId === 'IMMPET')">
            <label class="required">Petition Type</label>
            <p-dropdown class="d-block w-100" [options]="petitionTypeList" [(ngModel)]="requestData.petitionType"
              optionLabel="name" required placeholder="Select" (ngModelChange)="onPetitionTypeChange()"
              name="petitionType" #petitionType="ngModel" optionValue="code" [disabled]="(isCaseRequestSatus && isCaseRequestEditSatus) || (isBeneficiaryQuestionnaireStatus && isBeneficiaryQuestionnaireEditSatus) || taskLevelStatus || !requestData.visaType">
            </p-dropdown>
            <span class="invalid-message"
              *ngIf="(petitionType.dirty || petitionType.touched) && petitionType.errors?.required">
              {{ getGlobalErrorMessages("REQUIRED") }}
            </span>
          </div>
          <div class="visa-type" *ngIf="requestData.superTaskTypeId == 'IMMPET'">
            <label class="required">Immigration Classification</label>
            <p-dropdown class="d-block w-100" [options]="immigrationClassificationList"
              [(ngModel)]="requestData.immClassificationType" optionLabel="name" required placeholder="Select"
              (ngModelChange)="onImmigrationClassificationChange()" name="immClassificationType"
              #immClassificationType="ngModel" optionValue="code" [disabled]="(isCaseRequestSatus && isCaseRequestEditSatus) || (isBeneficiaryQuestionnaireStatus && isBeneficiaryQuestionnaireEditSatus) || taskLevelStatus || !requestData.immCategoryType">
            </p-dropdown>
            <span class="invalid-message"
              *ngIf="(immClassificationType.dirty || immClassificationType.touched) && immClassificationType.errors?.required">
              {{ getGlobalErrorMessages("REQUIRED") }}
            </span>
          </div>
          <div class="visa-type">
            <label class="required">Sub-Task Type</label>
            <p-dropdown class="d-block w-100" [options]="subTaskTypeList" [(ngModel)]="requestData.taskTypeRefId"
              optionLabel="name" required placeholder="Select" name="subTaskType"
              #subTaskType="ngModel" optionValue="code" (ngModelChange)="getVisaTemplates()" [disabled]="(isCaseRequestSatus && isCaseRequestEditSatus) || (isBeneficiaryQuestionnaireStatus && isBeneficiaryQuestionnaireEditSatus) || taskLevelStatus || !(requestData.petitionType || requestData.immClassificationType)">
            </p-dropdown>
            <span class="invalid-message"
              *ngIf="(subTaskType.dirty || subTaskType.touched) && subTaskType.errors?.required">
              {{ getGlobalErrorMessages("REQUIRED") }}
            </span>
          </div>
          <div class="visa-type">
            <label>Visa Template</label>
            <p-dropdown class="d-block w-100" [options]="visaTemplates" [(ngModel)]="requestData.visaId"
              optionLabel="visaName"  placeholder="Select" name="visaTemplate" #visaTemplate="ngModel"
              optionValue="visaId" (ngModelChange)="getVisaTemplateJson()" [disabled]="(!requestData.taskTypeRefId) || (isCaseRequestSatus && isCaseRequestEditSatus) || (isBeneficiaryQuestionnaireStatus && isBeneficiaryQuestionnaireEditSatus) || taskLevelStatus">
            </p-dropdown>
            <span class="invalid-message"
              *ngIf="(visaTemplate.dirty || visaTemplate.touched) && visaTemplate.errors?.required">
              {{ getGlobalErrorMessages("REQUIRED") }}
            </span>
          </div>
          <div class="display-sections">
            <button type="submit" class="im-btn im-btn-primary im-btn-md ml-2 mr-2" (click)="generateDynamicGroup()"
              [disabled]="(f.invalid) || (isCaseRequestSatus && isCaseRequestEditSatus) || (isBeneficiaryQuestionnaireStatus && isBeneficiaryQuestionnaireEditSatus) || taskLevelStatus">
              {{isCaseRequestSatus ? 'VIEW INFORMATION' : 'DISPLAY SECTIONS'}}
            </button>
          </div>
        </form>
      </div>
    </div>
    <!-- Visa Type End -->

    <!-- Display Result Start -->
    <div class="row display-portion" *ngIf="displayResult">
      <div class="col-md-12 p-0 beneficiary-head" [hidden]="isBeneficiaryQuestionnaireStatus" *ngIf="!isCaseRequestSatus">
          <h6 *ngIf="!taskLevelStatus" class="pb-0">Select the appropriate options to request for details from - <span><b>Beneficiary {{beneficiaryDetails?.beneficiaryEmployeeFName+" "+beneficiaryDetails?.beneficiaryEmployeeLName}}</b></span></h6>
          <h6 *ngIf="taskLevelStatus" class="pb-0">Select the appropriate options to request for details from - <span><b>Beneficiary {{taskLevelStatusData.beneficiaryName}}</b></span></h6>

          <p class="m-0 infoClass"><i>Note - Some sections are dependent on other sections and may get selected by default.</i></p>

      </div>
      
      <div class="col-md-12 p-0">
        <div class="row">
        <div class="col-md-4 p-0">
          <div class="showAllOrSelected" *ngIf="(!!beneficiaryData || !!taskLevelStatusData) && !isBeneficiaryQuestionnaire">
            <label for="showAllOrSelected">Settings Filter</label>
            <p-dropdown [options]="showAllOrSelectedArr"
                        inputId="showAllOrSelected"
                        styleClass="redBorder"
                        name="showAllOrSelected"
                        placeholder="Select"
                        [formControl]="showAllOrSelectedFormControl"
                        (onChange)="onChangeShowAllOrSelected($event)"
                        optionLabel="label"
                        optionValue="value">
            </p-dropdown>
          </div>
        </div>
      </div>
      </div>

      <div class="col-md-12 p-0 beneficiary-head" *ngIf="isCaseRequestSatus">
        <h6 class="pb-0">Select the sections you want to add in the questionnaire for petitioner to request for a petition</h6>
        <p class="m-0 infoClass"><i>Note - Some sections are dependent on other sections and may get selected by default.</i></p>
      </div>
      <div class="col-md-12 p-0 beneficiary-head" *ngIf="isBeneficiaryQuestionnaireStatus">
        <h6 [hidden]="isViewDataLogData" class="pb-0">Select petition details to add questions to petition steps</h6>
        <h6 [hidden]="!isViewDataLogData">Selected Sections</h6>
        <p [hidden]="isViewDataLogData" class="m-0 infoClass"><i>Note - Some sections are dependent on other sections and may get selected by default.</i></p>
        <div class="row">
          <div class="col-md-4 p-0">
            <div class="showAllOrSelected" *ngIf="!isPlatformAdmin">
              <label for="showAllOrSelected">Settings Filter</label>
              <p-dropdown [options]="showAllOrSelectedArr"
                          inputId="showAllOrSelected"
                          styleClass="redBorder"
                          name="showAllOrSelected"
                          placeholder="Select"
                          [formControl]="showAllOrSelectedFormControl"
                          (onChange)="onChangeShowAllOrSelected($event)"
                          optionLabel="label"
                          optionValue="value">
              </p-dropdown>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12" *ngIf="isQuestionnaireEmpty">
        <p class="questionnaire-empty-txt">No Questionnaires Present, please select 'Show All' to select Questionnaires.</p>
      </div>
      <div class="col-md-12 p-0" [ngClass]="isViewDataLogData ? 'disableViewDataLog': ''">
        <form [formGroup]="form" (ngSubmit)="mapUIDataToAPIData()">
          <!-- Display form Start -->
          <div [hidden]="data?.customHide" class="row display-main" *ngFor="let data of uiData;let i=index;" [ngClass]="data?.customDisabled ? 'disabledSection': ''">
            <div class="col-md-12 p-0">
              <div class="row">
                <div class="col-md-6 p-0 title-main">
                  <span class="number-circle">{{(i+1)}}</span>
                  <span class="title-portion"><b>{{data?.title}}</b></span>
                  <small class="selected-items ml-3 text-success" *ngIf="data?.options?.length && data?.options?.length==1">{{getNoOfSelected(data, 0)}} selected</small>
                </div>
                <!-- <div class="col-md-6 mb-0 p-0 text-right title-main" *ngIf="data.accordion && isTasktypeId">
                  <p class="text-danger">No task found! <button type="button" class="btn btn-primary ml-3" (click)="addNewTask()">create new task</button></p>
                </div> -->
                <div class="col-md-6 mb-0 p-0 text-right title-main" *ngIf="data?.infoMessage">
                  <p class="text-danger selectInfo">{{data?.infoMessage}}</p>
                </div>
                <div class="col-md-6 mb-0 p-0 text-right title-main" *ngIf="(data.accordion && isTasktypeId) || (data.additionalInfo && isTasktypeId)">
                  <p class="text-danger">No task found! <button type="button" class="btn btn-primary ml-3" (click)="addNewTask()">create new task</button></p>
                </div>
              </div>
            </div>

            <ng-container *ngIf="!data.accordion">
              <div *ngFor="let option of data.options; let i = index;" [ngClass]="{'col-md-12 option-section': option.isShown}">

                <ng-container *ngIf="option.isShown">
                  <div class="col-md-12 option-title p-0" *ngIf="option.title">
                    <b>{{option.title}}</b>
                    <small class="selected-items ml-3 text-success" *ngIf="data?.options?.length && data.options.length>1"> {{getNoOfSelected(data, i)}} selected</small>
                  </div>
                  <div class="col-md-12 p-0 pb-3" [ngClass]="{'not-visible': (data.additionalInfo === true && isTasktypeId === true), '' : (data.additionalInfo && isTasktypeId === false) }">
                    <mat-checkbox [checked]="option.allSelected" (change)="selectAllCheckboxes($event, option)">
                      Select All
                    </mat-checkbox>
                  </div>
                  <!-- <div [ngClass]="data.class" class="col-md-12 p-0 content-portion"> -->
                  <div class="row">
                    <div class="customItemMain" *ngFor="let subOption of option.subOptions; let i = index" [ngClass]="{'not-visible': (data.additionalInfo === true && isTasktypeId === true), '' : (data.additionalInfo && isTasktypeId === false), 'col-4': subOption.isShown }">
                      <ng-container *ngIf="subOption.isShown">
                        <label>
                          <mat-checkbox class="each-checkbox" (change)="setSubOptionSelected($event, subOption, option)" [checked]="subOption.selected" >
                            {{subOption.displayLabel}}
                          </mat-checkbox>
                        </label>
                        <div class="defaultSelected" *ngIf="subOption?.isDefaultSelected">
                          <i>Default Selected</i>
                        </div>
                      </ng-container>
                    </div>
                    <div *ngFor="let cusOption of option.cusSubOptions; let i = index" [ngClass]="{'not-visible': (data.additionalInfo === true && isTasktypeId === true), '' : (data.additionalInfo && isTasktypeId === false), 'col-4': cusOption.isShown }" [hidden]="isCaseRequestSatus ? ((cusOption?.data) && (cusOption?.data?.isPetitionerAction == 0)) : ((cusOption?.data) && (cusOption?.data?.isBeneficiaryAction == 0))" >
                      <ng-container *ngIf="cusOption.isShown">
                        <label [hidden]="(cusOption?.data) && ((cusOption?.data?.isBeneficiaryEditable == 0 && cusOption?.data?.isBeneficiaryVisible == 0) || (cusOption?.data?.isBeneficiaryEditable == 1 && cusOption?.data?.isBeneficiaryVisible == 0))">
                          <mat-checkbox class="each-checkbox" (change)="setSubOptionSelected($event, cusOption, option)" [checked]="cusOption.selected" >
                            {{cusOption.displayLabel}}<span *ngIf="cusOption.type=='section'">(Custom)</span>
                          </mat-checkbox>
                        </label>
                      </ng-container>
                    </div>
                  </div>
                </ng-container>

              </div>
            </ng-container>

            <ng-container *ngIf="data.accordion">
              <div class="" *ngFor="let option of data.options; let i = index;"
              [ngClass]="{'col-md-12 option-section': option.isShown}">

                  <div class="row" *ngIf="option.isShown">
                    <div class="col-md-12 accordian-title pb-3 pl-0" [ngClass]="{'not-visible': isTasktypeId === true, '' : isTasktypeId === false }">
                      <mat-icon *ngIf="option.openAccordian" (click)="option.openAccordian=false">keyboard_arrow_down</mat-icon>
                      <mat-icon *ngIf="!option.openAccordian" (click)="option.openAccordian=true">keyboard_arrow_up</mat-icon>
                      <mat-checkbox [checked]="option.allSelected" (change)="selectAllCheckboxes($event, option)">
                        <b>{{option.title}}</b>
                      </mat-checkbox><small class="selected-items ml-3 text-success">{{getNoOfSelected(data, i)}} selected</small>
                    </div>
                    <!-- <div class="col-md-6 p-0">
                      <p>No task Id found. Please create a task <button type="button" class="btn" (click)="addNewTask()">click</button></p>
                    </div> -->
                  </div>



                <ng-container *ngIf="option.openAccordian">
                  <div [ngClass]="data.class" class="row p-0 content-portion accordian-sub-title" >
                    <div class="col-12">
                      <div class="row p-0">
                        <p-confirmPopup></p-confirmPopup>
                        <div class="doc-portion" *ngFor="let subOption of option.subOptions; let i = index" [ngClass]="{'col-4': subOption.isShown}">
                            <ng-container *ngIf="subOption.isShown">

                            <label [ngClass]="{'not-visible': isTasktypeId === true, '' : isTasktypeId === false, 'not-clickable': subOption.isDisabled === true }">
                              <mat-checkbox class="each-checkbox" (change)="setSubOptionSelected($event, subOption, option)" [checked]="subOption.selected">
                                {{subOption.displayLabel}}
                              </mat-checkbox>
                            </label>
                          </ng-container>
                          <button type="button" [ngClass]="{'not-visible': isTasktypeId === true, '' : isTasktypeId === false }" *ngIf="subOption.isDisabled === true" (click)="confirm($event)" pButton icon="pi pi-info" matTooltip="Document Information"></button>

                          <!-- <mat-icon class="ml-2" [ngClass]="{'not-visible': isTasktypeId === true, '' : isTasktypeId === false }" *ngIf="subOption.isDisabled === true" (click)="confirm($event)">info</mat-icon> -->

                        </div>
                      </div>
                    </div>


                  </div>
                </ng-container>
              </div>
            </ng-container>

            <!-- <ng-container *ngIf="!data.accordion && data.access">

                <div class="col-md-12">
                  <div class="col-md-12 p-0 title-main">
                    <span class="number-circle">4</span>
                    <span class="title-portion">Access Rights</span>
                  </div>
                </div>
                <div class="col-md-3">
                  <label class="d-block required">Beneficiary will have access rights till?</label>
                    <p-calendar [disabled]="disableFields" showButtonBar="true"
                        [monthNavigator]="true" [yearNavigator]="true" name="establishedDate"
                        class="w-100" yearRange="1910:2050" dateFormat="mm-dd-yy" [showIcon]="true">
                    </p-calendar>
                </div>
                <div class="col-md-3 custom-dropdown">
                  <label for="importance">Set Importance</label>
                  <p-dropdown [options]="importanceList" inputId="importance" styleClass="redBorder" name="importance"
                    optionLabel="name" optionValue="code"></p-dropdown>
                </div>

            </ng-container> -->

          </div>

          <div class="row display-main" *ngIf="!isCaseRequestSatus && !isBeneficiaryQuestionnaireStatus">
            <div class="col-md-12 p-0">
              <div class="col-md-12 p-0 title-main">
                <span class="number-circle">{{uiData.length+1}}</span>
                <span class="title-portion">Access Rights</span>
              </div>
            </div>
            <div class="col-md-4 access-rights">
              <label class="d-block required">Beneficiary will have access rights till?</label>
                <p-calendar formControlName="accessRights" showButtonBar="true"
                    [monthNavigator]="true" [yearNavigator]="true" name="establishedDate" [minDate]="minimumDate"
                    class="w-100" yearRange="1910:2050" dateFormat="mm-dd-yy" [showIcon]="true">
                </p-calendar>

                <span class="invalid-message"
                  *ngIf="form.get('accessRights').touched && form.get('accessRights').errors?.required">
                  {{ getGlobalErrorMessages("REQUIRED") }}
                </span>
              </div>
              <div class="col-md-4 visa-type">
                <label class="required" for="importance">Set Importance</label>
              <p-dropdown [options]="importanceList" inputId="importance" styleClass="redBorder" name="importance" placeholder="Select"
                formControlName="importance" optionLabel="name" optionValue="id"></p-dropdown>
                <span class="invalid-message"
                  *ngIf="form.get('importance').touched && form.get('importance').errors?.required">
                  {{ getGlobalErrorMessages("REQUIRED") }}
                </span>
            </div>
          </div>
          <!-- Display form End -->

          <!-- Submit Start -->
          <div class="row" *ngIf="isWizard">
            <div class="col-md-12 p-15">
              <button pButton type="submit" class="mr-15" [disabled]="form.invalid">
                SEND EMAIL REQUEST
              </button>
              <button pButton type="button" (click)="onClickCancel()" label="CANCEL" class="p-button-secondary"></button>
            </div>
          </div>
          <div class="row" [hidden]="isBeneficiaryQuestionnaireStatus" *ngIf="!isCaseRequestSatus && !isWizard">
            <div class="col-md-12 p-15">
              <button pButton type="submit" class="mr-15" [disabled]="form.invalid">
                SUBMIT
              </button>
              <button pButton type="button" (click)="onClickCancel()" label="CANCEL" class="p-button-secondary"></button>
            </div>
          </div>
          <div class="row" *ngIf="isCaseRequestSatus">
            <div class="col-md-12 p-15">
              <button *ngIf="!isCaseRequestEditSatus" pButton type="submit" class="mr-15" [disabled]="form.invalid">
                SUBMIT
              </button>
              <button *ngIf="isCaseRequestEditSatus" pButton type="submit" class="mr-15" [disabled]="form.invalid">
                SAVE
              </button>
              <button pButton type="button" (click)="onClickBack()" label="CANCEL" class="p-button-secondary"></button>
            </div>
          </div>
          <div class="row" *ngIf="isBeneficiaryQuestionnaireStatus">
            <div [hidden]="isViewDataLogData" class="col-md-12 p-15">
              <button *ngIf="!isBeneficiaryQuestionnaireEditSatus" pButton type="submit" class="mr-15" [disabled]="form.invalid">
                SUBMIT
              </button>
              <button *ngIf="isBeneficiaryQuestionnaireEditSatus" pButton type="submit" class="mr-15" [disabled]="form.invalid">
                SAVE
              </button>
              <button pButton type="button" (click)="onClickBack()" label="CANCEL" class="p-button-secondary"></button>
            </div>
          </div>
          <!-- Submit End -->
        </form>
      </div>
    </div>
    <div class="no-data" *ngIf="!displayResult">
      <h6>No Data Found!</h6>
    </div>

    <!-- Display Result End -->

  </div>
  <!-- Request Data Body End -->
</div>

<p-dialog header="Beneficiary Request Link" [(visible)]="showreqLink" [position]="'top'" [modal]="true" [style]="{width: '50vw'}"
    [draggable]="false" [resizable]="false">
    <div class="px-4 py-3">
      <input type="text" readonly [value]="beneReqLinkDetails?.link">
      <div class="pt-3 d-flex align-items-center">
        Access Code : <h4 class="m-0"> {{beneReqLinkDetails?.AccessToken}}</h4>
      </div>
    </div>
</p-dialog>

<p-confirmDialog key="confirm-drop-database" header="Confirm" acceptLabel="Yes" rejectLabel="No" [(visible)]="confirmDropDatabaseDialogVisible"></p-confirmDialog>
