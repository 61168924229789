const urls = {
  dev5: 'http://18.215.237.95',
  qa9: 'https://qa9.imagility.co',
  dev9: 'http://50.19.45.219',
  qa7: 'http://34.235.141.147',
  uat4: 'http://44.210.134.224',
  qa5: 'http://3.81.207.168',
  dev6: 'http://54.198.212.131',
  dev3: 'http://34.228.186.101',
  dev3v2: 'http://3.235.178.82',
  prod: 'https://pl.imagility.co',
  qa3: 'http://34.202.145.234',
  uat2: 'http://52.204.59.234',
  dev2: 'http://52.54.81.36',
  qa4: 'http://34.230.88.68',
  qa6: 'http://34.202.145.234',
  qa6_https: 'https://qa6.imagility.co',
  qa6_5600: 'http://18.208.188.1',
  staging: 'https://staging.mytestsdummy.co.in',
  dev1: 'http://3.88.240.163',
  dev7: 'http://18.215.173.111',
  host: 'window.location.host',
  origin: 'window.location.origin',
  alternativeSatging: 'https://qa9.mytestsdummy.co.in',
  qa8: 'http://34.195.44.137',
  qa1: 'http://35.172.253.220',
  qa2: 'http://34.235.33.17',
  uat1: 'http://35.171.5.20',
  staging_fb: 'http://3.238.244.166',
  dev11: 'http://3.239.125.215',
  dev4: 'http://52.87.172.153',
  tristateurl: 'http://34.195.44.137',
  code_merge: 'http://54.84.60.114',
  QA6_new: 'http://18.208.188.1'
  // Add more URLs as needed
};

// const selectedUrl = 'dev7'; // Change this to select the desired URL
const selectedUrl = 'dev1' // ange this to select the desired URL
// const selectedUrl = 'prod'; // Change this to select the desired URL

const port = '5651';

export const environment = {
  production: false,
  version: '1.0.1',
  baseUrl: `${urls[selectedUrl]}:${port}/api/v1`,
  docs: `${urls[selectedUrl]}:${port}/docs/`,
  appUrl: urls[selectedUrl],
  affiniPayUrl: 'https://secure.affinipay.com/oauth/authorize',
  affiniPayClientId: 'f8cae1757af41b4b99f3a0c7619daa8080359ad322227d90df3f2961b4e7a58b',
  secretKey: "7e5da343be9b93e0e31fe670ec1638c1bbc9b648baab484fbfb338562ce5a1fa",
  zoomClientId: 'hwVOSDKNSlexKhBmCz5OEg',
  zoomRedirectUrl: 'http://54.198.212.131/compliance/callback',
  imagilityZoomLink: 'https://us04web.zoom.us/j/2296933038?pwd=OWdXeldud2RNQlFOS3BUSzdEY1VDdz09',
  gmailCompany: [239, 240]
};