import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { environment } from 'environment-configurations';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { I9RemediationService } from '../../i9-remediation.service';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { PetitionerService } from 'src/app/services/petitioner.service';
import { forkJoin } from 'rxjs';

export interface Option {
    includes(documentTitle: string): unknown;
    value: string;
    viewValue: string;
}

@Component({
    selector: 'app-parsing-errors',
    templateUrl: './parsing-errors.component.html',
    styleUrls: ['./parsing-errors.component.scss']
})
export class ParsingErrorsComponent implements OnInit {

    options: Option[] = [];

    selectedDocumentType: any;

    pdfSrc: string;
    isPdf: boolean = false;

    documentForm: FormGroup;
    continue: boolean = true;


    city: string;
    employees = [];
    docErrorCount: number;
    currentIndex = 0;
    allDataSaved: boolean = false;
    companyId: number;
    docError: number = 0;
    filepath: any;
    empName: any;
    documentTitle: any;
    remediationIterationId: any;
    remediationRunId: any;

    selectedList: string;
    selectedABList: string;
    i9RecordAndEmployeeIdsListDTO: any;
    selectedSignature: string;
    signatureOptions = [
        { label: 'Present', value: 'Present' },
        { label: 'NotPresent', value: 'NotPresent' }
    ];

    recordId;
    employeedId;

    // for section 2 qand Supplement B 
    section2Form: FormGroup;
    supplementBForm: FormGroup;
    primaryI9: string = 'no';
    documentTitles = [];
    listAdocumentTitles = [];
    listBdocumentTitles = [];
    listCdocumentTitles = [];

    // Document types
    List_A = [];
    List_B = [];
    List_C = [];
    List_B1 = [];

    transformedData: { label: string; value: string }[] = [];
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private i9RemediationService: I9RemediationService,
        private toastrService: ToastrService,
        private datePipe: DatePipe,
        private petitinerService: PetitionerService,
    ) {


    }

    ngOnInit() {
        this.loadAllDocumentLists();
        this.companyId = parseInt(sessionStorage.getItem('companyId'));
        this.remediationIterationId = sessionStorage.getItem('remediationIterationId');
        this.remediationRunId = sessionStorage.getItem('remediationRunId');
        // this.remediationIterationId=1483;
        // this.remediationRunId = 1459;
        this.i9RecordAndEmployeeIdsListDTO = JSON.parse(localStorage.getItem('i9RecordAndEmployeeIdsListDTO'));
        const queryParams = this.route.snapshot.queryParamMap;
        this.documentTitle = queryParams.get('documentTitle');
        //this.documentTitle = 'I9';

        this.documentForm = this.fb.group({
            documentName: ['', Validators.required],
            documentNumber: ['', Validators.required],
            issuingAuthority: ['', Validators.required],
            expiryDate: [''],
            lastName: [''],
            firstName: [''],
            middleName: [''],
            otherLastName: [''],
            address1: [''],
            address2: [''],
            city: [''],
            state: [''],
            zipCode: [''],
            dob: [''],
            ssn: [''],
            email: [''],
            telNumber: [''],
            selectedList: [''],
            selectedABList: [''],
            selectedSignature: [''],
            todayDate: [''],
            uscisNumber: [''],
            issuance: [''],
            forgnPassNum: [''],
            uscisi94AlienNumber: [''],
            workAuthExpiryDate: ['']
        });

        // for section 2 qand Supplement B 
        this.section2Form = this.fb.group({
            listType: ['', Validators.required], // default value
            documentTitle1: [''],
            issuingAuthority1: [''],
            documentNumber1: [''],
            expirationDate1: [''],

            documentTitle2: [''],
            issuingAuthority2: [''],
            documentNumber2: [''],
            expirationDate2: [''],

            documentTitle3: [''],
            issuingAuthority3: [''],
            documentNumber3: [''],
            expirationDate3: [''],

            additionalInfo: [''],
            alternativeProcedure: [false],

            firstDayOfEmployment: ['', Validators.required],
            employerName: ['', Validators.required],
            employerSignature: [false],
            employerTodaysDate: ['', Validators.required],
            businessName: ['', Validators.required],
            businessAddress: ['', Validators.required]
        });


        this.supplementBForm = this.fb.group({
            lastName: [''],
            firstName: [''],
            middleInitial: [''],
            rehireRecords: this.fb.array([this.createRehireRecord()])
        });


        if (this.documentTitle == 'I9' || this.documentTitle == 'i9' || this.documentTitle == 'I-9') {
            this.i9RecordAndEmployeeIdsListDTO.forEach((item, index) => {
                if (index == 0) {
                    this.getParsedI9Doctype(this.documentTitle)
                    return;
                }
            });
        } else {
            this.getParsedEmpDoctype(this.documentTitle);
        }
    }
    onListChange(event) {
        this.selectedList = event.value;
        this.selectedABList = '';
    }

    onListABChange(event) {
        this.selectedABList = event.value;
    }

    backToDashboard() {
        this.router.navigate(['petitioner-landing/dashboard/petitioner-dashboard']);
    }

    checkIfLastEmployee() {
        this.continue = !this.isLastEmployee();
    }

    continueAnalysis() {
        const queryParamsObj = {}
        this.router.navigate(['../parsing-report-list'], { queryParams: queryParamsObj, relativeTo: this.route });
    }
    isContinueButtonActive() {
        if (this.isLastEmployee() && this.docError == 0) {
            return false;
        }
        else {
            return true;
        }

    }

    getCurrentEmployee() {
        return this.employees[this.currentIndex];
    }

    goToPrevEmp() {
        if (this.currentIndex > 0) {
            this.currentIndex--;
            if (this.selectedDocumentType == 'I9' || this.selectedDocumentType == 'i9' || this.selectedDocumentType == 'I-9') {
                this.i9RecordAndEmployeeIdsListDTO.forEach((item, index) => {
                    if (this.currentIndex == index) {
                        this.getParsedI9Doctype(this.selectedDocumentType)
                        return;
                    }
                });
            } else {
                this.getParsedEmpDoctype(this.selectedDocumentType);
            }
        }
    }

    goToNextEmp() {
        if (this.currentIndex < this.employees.length - 1) {
            this.currentIndex++;
            this.checkAllDataSaved();
            if (this.selectedDocumentType == 'I9' || this.selectedDocumentType == 'i9' || this.selectedDocumentType == 'I-9') {
                this.i9RecordAndEmployeeIdsListDTO.forEach((item, index) => {
                    if (this.currentIndex == index) {
                        this.getParsedI9Doctype(this.selectedDocumentType)
                        return;
                    }
                });
            } else {
                this.getParsedEmpDoctype(this.selectedDocumentType);
            }

        }
    }

    isLastEmployee() {
        return this.currentIndex === this.employees.length - 1;
    }

    getSelectedDocumentViewValue() {
        const selectedOption = this.options.length > 0 ? this.options.find(docType => docType.value === this.selectedDocumentType).viewValue : '';
        return selectedOption ? selectedOption : '';
    }

    goToNextDocument() {
        if (this.options.length === 0) return;
        const currentIndex = this.options.findIndex(option => option.value === this.selectedDocumentType);
        const nextIndex = (currentIndex + 1) % this.options.length;
        this.selectedDocumentType = this.options[nextIndex].value;
        if (this.selectedDocumentType == 'I9' || this.selectedDocumentType == 'i9' || this.selectedDocumentType == 'I-9') {
            this.i9RecordAndEmployeeIdsListDTO.forEach((item, index) => {
                if (this.currentIndex == index) {
                    this.getParsedI9Doctype(this.selectedDocumentType)
                    return;
                }
            });
        } else {
            this.getParsedEmpDoctype(this.selectedDocumentType);
        }
    }

    onDocumentTypeChange(value: string) {
        this.selectedDocumentType = this.options.length > 0 ? this.options.find(docType => docType.value === value).viewValue : '';
        this.updateFormControls();
    }


    updateFormControls(): void {
        if (this.selectedDocumentType == 'I9' || this.selectedDocumentType == 'i9' || this.selectedDocumentType == 'I-9') {
            this.i9RecordAndEmployeeIdsListDTO.forEach((item, index) => {
                if (this.currentIndex == index) {
                    this.getParsedI9Doctype(this.selectedDocumentType)
                    return;
                }
            });
        } else {
            this.documentForm.reset();
            this.documentForm.get('documentName').clearValidators();
            this.documentForm.get('documentNumber').clearValidators();
            this.documentForm.get('issuingAuthority').clearValidators();
            this.documentForm.get('expiryDate').clearValidators();
            this.getParsedEmpDoctype(this.selectedDocumentType);
            this.documentForm.updateValueAndValidity();
        }

    }

    onSave(): void {
        if (this.selectedDocumentType != 'SSN' && this.selectedDocumentType != 'I-94') {
            this.documentForm.controls['expiryDate'].setValidators([Validators.required]);
        } else {
            this.documentForm.controls['expiryDate'].clearValidators();
        }
        this.documentForm.controls['expiryDate'].updateValueAndValidity();
        if (this.documentForm.valid) {
            const expiryDate = this.documentForm.controls['expiryDate'].value;
            const isoDate = this.formatDateToISO(expiryDate);
            const payload = {
                id: this.employees[this.currentIndex].id,
                filename: this.documentForm.get('documentName').value,
                issuingAuthority: this.documentForm.get('issuingAuthority').value,
                documentNumber: this.documentForm.get('documentNumber').value,
                expiryDate: this.selectedDocumentType === 'SSN' ? null : this.isValidDate(isoDate) ? isoDate : null
            };
            this.i9RemediationService.saveDocTypeData(this.selectedDocumentType, this.companyId, payload).subscribe(
                (data: any) => {
                    this.toastrService.success('Data saved successfully');
                    this.getParsedEmpDoctype(this.selectedDocumentType);
                },
                (error) => {
                    this.toastrService.error('Backend error');
                }
            );
            // Mark current employee data as saved
            this.checkAllDataSaved();
        } else {
            this.markFormGroupTouched(this.documentForm);
        }
    }

    markFormGroupTouched(formGroup: FormGroup): void {
        Object.keys(formGroup.controls).forEach(key => {
            const control = formGroup.get(key);
            control.markAsTouched();
        });
    }

    checkAllDataSaved(): void {
        // Here, you can implement the logic to check if all employee data is saved.
        // For now, we assume that data for the last employee is saved when `goToNextEmp` is called.
        if (this.isLastEmployee()) {
            this.allDataSaved = true;
        }
    }

    onSkip() {
        if (this.options.length === 0) return; // Prevent skipping if there are no options

        // Find the index of the current selected document type
        const currentIndex = this.options.findIndex(option => option.value === this.selectedDocumentType);

        // Calculate the next index. Use modulus to wrap around to the start if at the end.
        const nextIndex = (currentIndex + 1) % this.options.length;

        // Update selectedDocumentType to the next document type
        this.selectedDocumentType = this.options[nextIndex].value;

        // Update the form controls based on the new document type
        this.updateFormControls();

    }
    getParsedEmpDoctype(documentTitle: string) {
        this.i9RemediationService.getParseEmpDocType(documentTitle, this.companyId, this.remediationRunId, this.remediationIterationId).subscribe(data => {
            if (data && data.length > 0) {
                this.employees = data;
                const docTypes = data[this.currentIndex].docTypes;
                this.options = docTypes.map(type => ({ value: type, viewValue: type }));
                this.selectedDocumentType = this.options.length > 0 ? this.options.find(docType => docType.value.trim() === documentTitle).viewValue : '';
                this.updateForm(data[this.currentIndex]);
                this.docError = data[this.currentIndex].errorCount;
                this.empName = data[this.currentIndex].empName;
                this.filepath = data[this.currentIndex].filepath;
            } else {
                this.options = [];
                this.selectedDocumentType = '';
            }
            const basePath = `${environment.docs}`;
            const relativePath = this.extractRelativePath(this.filepath);
            this.pdfSrc = `${basePath}${relativePath}`;
            this.isPdf = this.pdfSrc.toLowerCase().endsWith('.pdf');

        });
    }

    extractRelativePath(fullPath: string): string {
        const uploadIndex = fullPath.indexOf('i9remediation');
        if (uploadIndex !== -1) {
            return fullPath.substring(uploadIndex);
        }
        return fullPath; // fallback to return the original path if not found
    }

    private updateForm(data: any): void {
        //YYYY-MM-DDTHH:mm:ss.sssZ
        //const formattedDate = this.formatDateToMMDDYYYY(data.expiryDate);
        const formattedDate = this.selectedDocumentType === 'SSN' ? null : this.formatDateToMMDDYYYY(data.expiryDate);
        //const date = this.selectedDocumentType === 'SSN' ? null : this.datePipe.transform(new Date(data.expiryDate), 'yyyy-MM-ddThh:mm:ss.SSSZ')

        this.documentForm.controls['expiryDate'].patchValue(formattedDate ? new Date(formattedDate) : '');
        this.documentForm.patchValue({
            documentName: data.documentTitle || '',
            documentNumber: data.documentNumber || '',
            issuingAuthority: data.issuingAuthority || '',
            //expiryDate: data.expiryDate ? new Date(data.expiryDate).toISOString().split('T')[0] : ''
        });
        if (!data.documentNumber) {
            this.documentForm.get('documentNumber').setErrors({ required: true });
        }
        if (!data.issuingAuthority) {
            this.documentForm.get('issuingAuthority').setErrors({ required: true });
        }
        if (this.selectedDocumentType !== 'I-94' && !data.expiryDate) {
            this.documentForm.get('expiryDate').setErrors({ required: true });
        }
    }

    initiateNewRemediation() {
        const queryParamsObj = {}
        this.router.navigate(['../initiate-i9-remediation'], { queryParams: queryParamsObj, relativeTo: this.route });
    }

    formatDateToMMDDYYYY(dateString: string): any {
        //return this.datePipe.transform(new Date(dateString), 'yyyy-MM-ddThh:mm:ss.SSSZ')
        if (dateString) {
            return moment(dateString).utcOffset(0, false).toLocaleString();
        }
        return null;
    }

    formatDateToISO(dateString: Date): string {
        return moment(dateString).format('YYYY-MM-DD');
    }


    // for section 2 qand Supplement B 
    get rehireRecords(): FormArray {
        return this.supplementBForm.get('rehireRecords') as FormArray;
    }

    createRehireRecord(): FormGroup {
        return this.fb.group({
            id: [''],
            rehireDate: [''],
            newLastName: [''],
            newFirstName: [''],
            newMiddleInitial: [''],
            documentTitle: [''],
            documentNumber: [''],
            expirationDate: [''],
            alternativeProcedure: [false],
            employerName: [''],
            employerSignature: [false],
            todayDate: [''],
            additionalInfo: ['']
        });
    }

    addRehireRecord(): void {
        this.rehireRecords.push(this.createRehireRecord());
    }

    deleteRehireRecord(index: number): void {
        this.rehireRecords.removeAt(index);
    }

    submitSections() {
        const payload = this.transformObjects(this.documentForm.value, this.section2Form.value, this.supplementBForm.value);
        this.saveI9Data(payload);
    }

    transformObjects(
        obj1,
        obj2,
        obj3
    ) {
        return {
            dob: this.datePipe.transform(obj1.dob, 'yyyy-MM-dd'),
            i9RecordId: this.recordId,
            i9RemediationEmployeeId: this.employeedId,
            i9SequenceNo: this.primaryI9,
            i9RemediationSection1And2DTO: {
                additionalInfo: obj2.additionalInfo,
                alienRegNumber: obj1.uscisi94AlienNumber || '',
                alternativeProcedure: obj2.alternativeProcedure,
                aptNo: obj1.address2,
                city: obj1.city,
                citizenType: { 'code': obj1.selectedABList },
                currentAddress: obj1.address1,
                dateofbirth: this.datePipe.transform(obj1.dob, 'yyyy-MM-dd'),
                dateOfExit: null,
                dateOfI9Completion: null,
                dateOfSigningSection1: this.datePipe.transform(obj1.todayDate, 'yyyy-MM-dd'),
                documentNumber1: obj2.documentNumber1,
                documentNumber2: obj2.documentNumber2,
                documentNumber3: obj2.documentNumber3,
                documentTitle1: obj2.documentTitle1,
                documentTitle2: obj2.documentTitle2,
                documentTitle3: obj2.documentTitle3,
                email: obj1.email,
                employerTodaysDate: this.datePipe.transform(obj2.employerTodaysDate, 'yyyy-MM-dd'),
                employeeid: this.employeedId,
                employerName: obj2.employerName,
                expirationDate1: this.datePipe.transform(obj2.expirationDate1, 'yyyy-MM-dd'),
                expirationDate2: this.datePipe.transform(obj2.expirationDate2, 'yyyy-MM-dd'),
                expirationDate3: this.datePipe.transform(obj2.expirationDate3, 'yyyy-MM-dd'),
                firstDayOfEmployment: this.datePipe.transform(obj2.firstDayOfEmployment, 'yyyy-MM-dd'),
                firstname: obj1.firstName,
                lastname: obj1.lastName,
                middlename: obj1.middleName,
                othername: obj1.otherLastName,
                foreignpassportissuedcountry: obj1.issuance,
                forgnPassNo: obj1.forgnPassNum,
                formI94Number: obj1.uscisi94AlienNumber,
                i9filepath: null,
                i9SequenceNo: this.primaryI9,
                immigrationStatus: null,
                isEmployeeSign: obj1.selectedSignature == 'Present' ? true : false,
                isEmployerSignature: obj2.employerSignature,
                isI9Current: null,
                isI9DoneInPast: null,
                issuingAuthority1: obj2.issuingAuthority1,
                issuingAuthority2: obj2.issuingAuthority2,
                issuingAuthority3: obj2.issuingAuthority3,
                listType: obj1.selectedList,
                local_remote_Employment: null,
                organisationAddres: obj2.businessAddress,
                organisationName: obj2.businessName,
                phoneNo: parseInt(obj1.telNumber, 10),
                recordId: this.recordId,
                ssnNo: obj1.ssn,
                state: obj1.state,
                streetNo: obj1.address1,
                todayDate: this.datePipe.transform(obj1.todayDate, 'yyyy-MM-dd'),
                uscisNumber: obj1.uscisNumber,
                workExpiryDate: this.datePipe.transform(obj1.workAuthExpiryDate, 'yyyy-MM-dd'),
                workLocation: null,
                zipCode: obj1.zipCode
            },
            i9RemediationSection3DTO: obj3.rehireRecords.map(record => ({
                id: record.id || 0,
                additionalInfo: record.additionalInfo,
                dateOfRehire: this.datePipe.transform(record.rehireDate, 'yyyy-MM-dd'),
                documentNo: record.documentNumber || '',
                documentTitle: record.documentTitle || '',
                employerSignDate: this.datePipe.transform(record.todayDate, 'yyyy-MM-dd') || '',
                expirationDate: this.datePipe.transform(record.expirationDate, 'yyyy-MM-dd') || '',
                firstName: record.newFirstName,
                i9remediationrunid: 0,
                isDhs: record.alternativeProcedure ? 1 : 0,
                isEmployerSign: record.employerSignature ? 1 : 0,
                lastName: record.newLastName,
                middleName: record.newMiddleInitial,
                nameOfEmployer: record.employerName
            })),
            section1FirstName: obj1.firstName,
            section1LastName: obj1.lastName,
            section1MiddleName: obj1.middleName,
            section1OtherLastName: obj1.otherLastName
        };
    }

    getParsedI9Doctype(docTitle) {
        this.i9RemediationService.getI9formDocData(this.companyId, this.remediationRunId, this.remediationIterationId).subscribe(data => {
            this.patchDocumentForm(data[this.currentIndex]);
            this.patchSection2Form(data[this.currentIndex]);
            this.patchSupplementBForm(data[this.currentIndex]);
            this.employees = data;

            this.recordId = data[this.currentIndex].i9RecordId;
            this.employeedId = data[this.currentIndex].i9RemediationEmployeeId;
            const docTypes = data[this.currentIndex].docTypes;
            this.options = docTypes.map(type => ({ value: type, viewValue: type }));
            this.selectedDocumentType = this.options.length > 0 ? this.options.find(docType => docType.value.includes(docTitle)).viewValue : '';
            // this.docError = data.errorCount;
            this.empName = data[this.currentIndex].empName;
            this.filepath = data[this.currentIndex].filepath;
            const basePath = `${environment.docs}`;
            const relativePath = this.extractRelativePath(this.filepath);
            this.pdfSrc = `${basePath}${relativePath}`;
            this.isPdf = this.pdfSrc.toLowerCase().endsWith('.pdf');
        })
    }
    saveI9Data(payload) {
        this.i9RemediationService.saveI9Data(payload).subscribe(data => {
            if (data && data.status) {
                this.toastrService.success(data.message);
                this.getParsedI9Doctype(this.selectedDocumentType);
            }
        })
    }

    patchDocumentForm(transformedObject: any) {
        this.primaryI9 = transformedObject.i9RemediationSection1And2DTO.i9SequenceNo == '1' ? 'yes' : 'no';
        this.documentForm.patchValue({
            documentName: transformedObject.i9RemediationSection1And2DTO.documentTitle1,
            documentNumber: transformedObject.i9RemediationSection1And2DTO.documentNumber1,
            issuingAuthority: transformedObject.i9RemediationSection1And2DTO.issuingAuthority1,
            expiryDate: this.isValidDate(transformedObject.i9RemediationSection1And2DTO.expirationDate1) ? new Date(this.formatDateToMMDDYYYY(transformedObject.i9RemediationSection1And2DTO.expirationDate1)) : '',
            lastName: transformedObject.section1LastName,
            firstName: transformedObject.section1FirstName,
            middleName: transformedObject.section1MiddleName,
            otherLastName: transformedObject.section1OtherLastName,
            address1: transformedObject.i9RemediationSection1And2DTO.streetNo,
            address2: transformedObject.i9RemediationSection1And2DTO.aptNo,
            city: transformedObject.i9RemediationSection1And2DTO.city,
            state: transformedObject.i9RemediationSection1And2DTO.state,
            zipCode: transformedObject.i9RemediationSection1And2DTO.zipCode,
            dob: this.isValidDate(transformedObject.i9RemediationSection1And2DTO.dateofbirth) ? new Date(this.formatDateToMMDDYYYY(transformedObject.i9RemediationSection1And2DTO.dateofbirth)) : '',
            ssn: transformedObject.i9RemediationSection1And2DTO.ssnNo,
            email: transformedObject.i9RemediationSection1And2DTO.email,
            telNumber: transformedObject.i9RemediationSection1And2DTO.phoneNo,
            selectedList: transformedObject.i9RemediationSection1And2DTO.listType,
            selectedABList: transformedObject.i9RemediationSection1And2DTO.citizenType?.code, // You might need to map this from your data
            selectedSignature: transformedObject.i9RemediationSection1And2DTO.isEmployeeSign ? 'Present' : 'NotPresent',
            todayDate: this.isValidDate(transformedObject.i9RemediationSection1And2DTO.todayDate) ? new Date(this.formatDateToMMDDYYYY(transformedObject.i9RemediationSection1And2DTO.todayDate)) : '',
            workAuthExpiryDate: this.isValidDate(transformedObject.i9RemediationSection1And2DTO.workExpiryDate) ? new Date(this.formatDateToMMDDYYYY(transformedObject.i9RemediationSection1And2DTO.workExpiryDate)) : '',
            uscisNumber: transformedObject.i9RemediationSection1And2DTO.uscisNumber,
            issuance: transformedObject.i9RemediationSection1And2DTO.foreignpassportissuedcountry,
            forgnPassNum: transformedObject.i9RemediationSection1And2DTO.forgnPassNo,
            uscisi94AlienNumber: transformedObject.i9RemediationSection1And2DTO.formI94Number,
        });
    }

    patchSection2Form(transformedObject: any) {
        this.section2Form.patchValue({
            listType: transformedObject.i9RemediationSection1And2DTO.listType,
            documentTitle1: transformedObject.i9RemediationSection1And2DTO.documentTitle1,
            issuingAuthority1: transformedObject.i9RemediationSection1And2DTO.issuingAuthority1,
            documentNumber1: transformedObject.i9RemediationSection1And2DTO.documentNumber1,
            expirationDate1: this.isValidDate(transformedObject.i9RemediationSection1And2DTO.expirationDate1) ? new Date(this.formatDateToMMDDYYYY(transformedObject.i9RemediationSection1And2DTO.expirationDate1)) : '',

            documentTitle2: transformedObject.i9RemediationSection1And2DTO.documentTitle2,
            issuingAuthority2: transformedObject.i9RemediationSection1And2DTO.issuingAuthority2,
            documentNumber2: transformedObject.i9RemediationSection1And2DTO.documentNumber2,
            expirationDate2: this.isValidDate(transformedObject.i9RemediationSection1And2DTO.expirationDate2) ? new Date(this.formatDateToMMDDYYYY(transformedObject.i9RemediationSection1And2DTO.expirationDate2)) : '',

            documentTitle3: transformedObject.i9RemediationSection1And2DTO.documentTitle3,
            issuingAuthority3: transformedObject.i9RemediationSection1And2DTO.issuingAuthority3,
            documentNumber3: transformedObject.i9RemediationSection1And2DTO.documentNumber3,
            expirationDate3: this.isValidDate(transformedObject.i9RemediationSection1And2DTO.expirationDate3) ? new Date(this.formatDateToMMDDYYYY(transformedObject.i9RemediationSection1And2DTO.expirationDate3)) : '',

            additionalInfo: transformedObject.i9RemediationSection1And2DTO.additionalInfo,
            alternativeProcedure: transformedObject.i9RemediationSection1And2DTO.alternativeProcedure,

            firstDayOfEmployment: this.isValidDate(transformedObject.i9RemediationSection1And2DTO.firstDayOfEmployment) ? new Date(this.formatDateToMMDDYYYY(transformedObject.i9RemediationSection1And2DTO.firstDayOfEmployment)) : '',

            employerName: transformedObject.i9RemediationSection1And2DTO.employerName,
            employerSignature: transformedObject.i9RemediationSection1And2DTO.isEmployerSignature,
            employerTodaysDate: this.isValidDate(transformedObject.i9RemediationSection1And2DTO.employerTodaysDate) ? new Date(this.formatDateToMMDDYYYY(transformedObject.i9RemediationSection1And2DTO.employerTodaysDate)) : '',

            businessName: transformedObject.i9RemediationSection1And2DTO.organisationName,
            businessAddress: transformedObject.i9RemediationSection1And2DTO.organisationAddres,
        });
    }

    patchSupplementBForm(transformedObject: any) {
        this.supplementBForm.patchValue({
            lastName: transformedObject.i9RemediationSection1And2DTO.lastname,
            firstName: transformedObject.i9RemediationSection1And2DTO.firstname,
            middleInitial: transformedObject.i9RemediationSection1And2DTO.middlename,
        });

        const rehireRecordsArray = this.supplementBForm.get('rehireRecords') as FormArray;
        rehireRecordsArray.clear(); // Clear any existing records

        transformedObject.i9RemediationSection3DTO.forEach((record: any) => {
            rehireRecordsArray.push(this.fb.group({
                id: record.id,
                newLastName: record.lastName,
                newFirstName: record.firstName,
                newMiddleInitial: record.middleName,
                documentTitle: record.documentTitle,
                rehireDate: this.isValidDate(record.dateOfRehire) ? new Date(this.formatDateToMMDDYYYY(record.dateOfRehire)) : '',
                documentNumber: record.documentNo,
                employerSignDate: this.isValidDate(record.employerSignDate) ? new Date(this.formatDateToMMDDYYYY(record.employerSignDate)) : '',
                expirationDate: this.isValidDate(record.expirationDate) ? new Date(this.formatDateToMMDDYYYY(record.expirationDate)) : '',
                employerName: record.nameOfEmployer,
                additionalInfo: record.additionalInfo,
                alternativeProcedure: record.isDhs,
                todayDate: this.isValidDate(record.employerSignDate) ? new Date(this.formatDateToMMDDYYYY(record.employerSignDate)) : '',
                employerSignature: record.isEmployerSign,
            }));
        });
    }

    isValidDate(dateString: string): boolean {
        if (dateString) {
            const date = new Date(dateString);
            return !isNaN(date.getTime());
        }
        return false;
    }


    loadAllDocumentLists() {
        let response1 = this.petitinerService.getdocumentList('I9IDEMPLAUTHDOC');
        let response2 = this.petitinerService.getdocumentList('I9IDDOC');
        let response3 = this.petitinerService.getdocumentList('I9EMPLAUTHDOC');
        let response4 = this.petitinerService.getdocumentList('I9IDDOCBLW18');
    
        forkJoin([response1, response2, response3, response4]).subscribe(([response1, response2, response3, response4]) => {
            // Set the lists
            this.List_A = response1;
            this.List_B = response2;
            this.List_C = response3;
            this.List_B1 = response4;
    
            // Concatenate the lists after they are set
            let finalDocArray = this.List_A.concat(this.List_B, this.List_B1, this.List_C);
    
            // Transform the data
            this.documentTitles = this.transformData(finalDocArray);
            this.listAdocumentTitles = this.transformData(this.List_A);
            this.listBdocumentTitles = this.transformData(this.List_B.concat(this.List_B1));
            this.listCdocumentTitles = this.transformData(this.List_C);
        });
    }
    
    transformData(data: any[]): { label: string; value: string }[] {
        return data.map(item => ({
          label: item.shortDescription ? item.shortDescription.trim() : '',
          value: item.shortDescription ? item.shortDescription.trim() : ''
        }));
    }
}
