import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthenticationService } from "src/app/services/authentication.service";
import { PetitionerService } from "src/app/services/petitioner.service";
import moment from "moment";
import { OwlOptions } from "ngx-owl-carousel-o";
import { DashboardWidgetsService } from 'src/app/services/dashboard-widgets.service';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { UserPrivacyModalComponent } from 'src/app/modules/shared/modal/user-privacy-modal/user-privacy-modal.component';
import { UserRoleService } from 'src/app/services/user-role.service';
import { TourModalComponent } from 'src/app/modules/shared/modal/tour-modal/tour-modal.component';
 

@Component({
  selector: "dashboard-petitioner",
  templateUrl: "./dashboard-petitioner.component.html",
  styleUrls: ["./dashboard-petitioner.component.scss"],
})
export class DashboardPetitionerComponent implements OnInit {
  public userName: string;
  public userTimeNow: string;
  public teamMemberCount: number;
  public companyId = "";
  public userId = "";
  alertsList = [];
  recordsPerPage = 10;
  pageNo = 1;
  dialogContent;
  lcaAssign: any;
  publicAccess: any;
  public alertWidgetPagination = { currentIndex: 0, maxIndex: 0 };

  images = [
    "../assets/images/help.png",
    "../assets/images/help.png",
    "../assets/images/help.png",
    "../assets/images/help.png",
    "../assets/images/help.png",
    "../assets/images/help.png",
  ];
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 100,
    autoplay: true,
    autoplayTimeout: 3000,
    navText: ["", ""],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 2,
      },
      740: {
        items: 3,
      },
      940: {
        items: 4,
      },
    },
    nav: true,
  };
  //CarouselOptions = { items: 3, dots: true, nav: true,center:true };
  public employees: any;
  color = [];
  showMiniAds = false;
  policyId;
  isI9Admin=false;
  constructor(
    private router: Router,
    private userRoleService: UserRoleService,
    public dialog: MatDialog,
    private authenticationService: AuthenticationService,
    private petitionerService: PetitionerService,
    private route: ActivatedRoute,
    private dashboardWidgetsService: DashboardWidgetsService

  ) {
    this.userName = "";
    this.userTimeNow = moment(new Date()).format("dddd, MMMM Do YYYY");
    this.alertWidgetPagination['currentIndex'] = 0;
    this.alertWidgetPagination['maxIndex'] = 0;

  }

  ngOnInit() {
    let getUser = sessionStorage.getItem('userTypeRole');
    if(getUser==='I9Admin'){
      this.isI9Admin=true;
    }

    this.companyId = sessionStorage.getItem("companyId");
    this.userId = sessionStorage.getItem("userId");
    this.getuserName();
    this.getNotifications();
    this.getAllAlerts();
    this.getLcaCount();
    this.getPublicAccessCount();
    //this.getNotifications();
    //this.getAllAlerts();
    let userType;
    if (localStorage.getItem('policyAcceptanceRequired') == "true") {
      switch (sessionStorage.getItem('userTypeRole')) {
        case 'Petitioner': userType = 'PETNR'; break;
        case 'Beneficiary': userType = 'BEN'; break;
        case 'Attorney': userType = 'ATRNY'; break;
        case 'I9Admin': userType = 'I9ADM'; break;
        default: userType = 'BEN';
      }
      this.userRoleService.getPolicyContent(userType, 'PRVCYPLCY').subscribe((data) => {
        if (data) {
          this.dialogContent = data.content;
          this.policyId = data.policyId;
          this.displayPrivacyPolicy();
        }
      });
    }
    // if(localStorage.getItem('isFirstLogin') == "true")
    //   this.displayIntroDialog();

    // this.joyride.startTour(
    //     { steps: ['firstStep','secondStep']}
    //   )
  }

  goToCreateBene(): void {
    this.router.navigate(['i9-admin-landing/beneficiaries/list'], { queryParams: { action: 'create' } });
  }

  goToBeneList() {
    this.router.navigate(['i9-admin-landing/beneficiaries/list'], { queryParams: { tab: 'not-initiated' } });
  }
  getAllAlerts() {
    let payload = {
      pageNo: this.pageNo,
      recordsPerPage: this.recordsPerPage
    }
    this.dashboardWidgetsService.getAlertsListNew(payload).subscribe((response: any) => {
      if (response) {
        this.alertsList = response.data?.alerts;
        if (this.alertsList.length > 0) {
          this.alertWidgetPagination.maxIndex = this.alertsList.length > 6 ? 5 : this.alertsList.length - 1;
        }
      }
      else {
        this.alertsList = [];
      }
    });
  }
  setContentIndex(action) {
    if (action == "inc") {
      this.alertWidgetPagination.currentIndex == this.alertWidgetPagination.maxIndex ?
        '' : this.alertWidgetPagination.currentIndex = this.alertWidgetPagination.currentIndex + 1;
    }
    if (action == "dec") {
      this.alertWidgetPagination.currentIndex == 0 ?
        '' : this.alertWidgetPagination.currentIndex = this.alertWidgetPagination.currentIndex - 1;
    }
  }

  displayIntroDialog() {
    this.dialog.open(PetitionerWelcomeDialog,
      {
        height: '740px',
        width: '800px',
      }).afterClosed().subscribe(result => {
        localStorage.setItem('isFirstLogin', "false");
      });
  }

  displayPrivacyPolicy() {
    let type = 'privacy';
    const dialogRef = this.dialog.open(UserPrivacyModalComponent, {
      width: '800px',
      height: 'auto',
      data: {
        update: {
          policyId: this.policyId,
          dialogContent: this.dialogContent,
          type: type
        }
      }
    }).afterClosed().subscribe(result => {
      if (result == 'acceptPolicy') {
        if (localStorage.getItem('isFirstLogin') == "true")
          this.displayIntroDialog();
      }
    });
  }

  addNewTask() {
    //this.router.navigate(['petitioner-landing/dashboard/task',  { id: 5 }]);
    sessionStorage.removeItem("taskId");
    this.router.navigate(["petitioner-landing/dashboard/task/task-details", 0]);
  }
  getuserName() {
    this.authenticationService.currentUser.subscribe((data) => {
      if (data) {
        this.userName = data.firstname;
        if (data["permissions"]) {
          data["permissions"].find((el) => {
            if (el === "ISADMIN") {
              this.showMiniAds = true;
            }
          });
        }

        //this.userName=  data.firstname+data.lastName;
      }
    });
  }

  getLcaCount(){
    this.dashboardWidgetsService.getLcaAssign(this.companyId).subscribe(res => {
      this.lcaAssign = res.data;
    });
  }

  getPublicAccessCount(){
    this.dashboardWidgetsService.getPublicAccess(this.companyId).subscribe(res => {
      this.publicAccess = res.data;
    });
  }

  getNotifications() {
    let payload = {
      isActionable: null,
      isNotifyGroup: null,
      isRead: null,
      isStarred: null,
      pageNumber: 0,
      recordsPerPage: 0
    }
    this.dashboardWidgetsService.getNotifications(payload).subscribe((response) => {
      if (response) {
        this.dashboardWidgetsService.setBellNotifyCount(response['unReadCount']||0);
      } else {
        this.dashboardWidgetsService.setBellNotifyCount(0);
      }
    });
  }
  getTotalCount(dataArray) {
    if (dataArray) {
    const sum = Object.values(dataArray).reduce((acc, value) => (acc as number) + (value as number), 0);
    return sum;
    } else {
      return 0;
    }
  }
}

@Component({
  selector: 'petitioner-welcome-dialog',
  templateUrl: 'petitioner-welcome-dialog.html',
})
export class PetitionerWelcomeDialog {constructor(public dialogRef: MatDialogRef<PetitionerWelcomeDialog>,
  public dialog: MatDialog){

    }

  displayStartTourDialog() {
    this.dialogRef.close();
    this.dialog.open(TourModalComponent,
      {
        width: '650px',
        data: {
          update: {
            userType: sessionStorage.getItem('userTypeRole')
          }
        }
      }).afterClosed().subscribe(result => {

      });
  }
}
