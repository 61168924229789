import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import * as RolesRouteMap from '../../../data/constants/roles';
import { ReferenceLibrary } from 'src/app/data/models/referenceLibrary.model';
import { ReferenceLibraryService } from 'src/app/services/referenceLibrary.service';
import { debounceTime, distinctUntilChanged, filter, map, take, takeUntil } from 'rxjs/operators';
import { CaseStatusService } from '../services/case-status.service';
import { combineLatest, fromEvent, Subject, Subscription } from 'rxjs';
import {ConfirmationService} from 'primeng/api';
import { ToastrService } from 'ngx-toastr';
import moment from 'moment';
import { CustomErrorToastComponent } from 'custom-error-toast';

@Component({
  selector: 'app-case-status-list',
  templateUrl: './case-status-list.component.html',
  styleUrls: ['./case-status-list.component.scss']
})
export class CaseStatusListComponent implements OnInit, OnDestroy {

  caseStatusList = [];
  companyList = [];
  companyIds = [];
  visaType = [];
  petitionType = [];
  serviceCenterNameIds = [];
  totalElementCount = 0;
  pageSizeOptions = [25, 50, 100];
  pageIndex = 1; // default
  pageSize = 25; // default
  searchText = '';
  showFilter = false;
  checkedStatus: boolean = false;
  filter = {
    superTaskType: '',
    immCategoryTypes:[],
    immClassificationTypes:[],
    visaTypes:[],
    petitionType: [],
    subTaskTypes: [],
    serviceCenterNameIds: [],
    processType: ""
  };
  payload = {
    companyId: 0,
    attorneyId:0,
    pageNumber: this.pageIndex,
    pageSize: this.pageSize,
    petitionTypes: this.petitionType,
    subTaskTypes: this.filter.subTaskTypes,
    superTaskType: '',
    serviceCenterNameIds: this.serviceCenterNameIds,
    processType: '',
    visaTypes: this.visaType,
    immCategoryTypes: this.filter.immCategoryTypes,
    immClassificationTypes: this.filter.immClassificationTypes,
    searchText:'',
    companyIds: this.companyIds,
    missingCaseStatus: this.checkedStatus
  };
  userType: string;
  companyId: number;
  taskType: ReferenceLibrary[];
  immCategoryTypes: ReferenceLibrary[];
  immClassificationTypes: ReferenceLibrary[];
  visaTypes: ReferenceLibrary[];
  petitionTypes: ReferenceLibrary[];
  subTaskTypes: ReferenceLibrary[];
  processTypes: any[] = [{name:'Regular',code:'REGULAR'}, {name:'Premium',code:'PREMIUM'}];
  serviceCenters: any[];
  searchSubscription: Subscription;
  observableSubscription$ = new Subject();
  requestsLoaded$ = new Subject();
  isOnInit:boolean = true;
  receiptNumber;
  cols: any[];
  @ViewChild('searchCaseStatusListTable', { static: true }) searchCaseStatusListTable: ElementRef;
  caseStatusListCols: { field: string; header: string; width?: string; cssClass?: string; }[];
  navUrl;
  editNavUrl;
  display: boolean = false;
  receiptNumberContent:string = '';
  caseUpdatedTime:string = '';
  currentCaseItem: any;
  userId: any;
  shortCaseData: any;
  selectedCompanies: any;
  selectedCompaniesText: any;
  
  constructor(
    private router: Router,
    private referenceLibraryService: ReferenceLibraryService,
    private APIService : CaseStatusService,
    private confirmationService: ConfirmationService,
    public toastr: ToastrService) { }

  ngOnInit(): void {

    this.userType = sessionStorage.getItem('userTypeRole');
    this.companyId = parseInt(sessionStorage.getItem('companyId'));
    this.userId = sessionStorage.getItem("userId");
    this.payload.companyId = this.companyId;
    this.caseStatusListCols = [
      { field: 'receiptNumber', header: 'Receipt Number', width: '10%', cssClass:'uscis-status-column' },
      { field: 'companyName', header: 'Company Name', width: '10%', cssClass:'uscis-status-column' },
      { field: 'beneficiaryName', header: 'Beneficiary Name', width: '13%', cssClass:'uscis-status-column' },
      { field: 'visaOrImmCategory', header: 'Visa Type / Imm-Category', width: '10%', cssClass:'uscis-status-column' },
      { field: 'petitionTypeOrImmClassification', header: 'Petition Type / Imm-Classification', width: '10%', cssClass:'uscis-status-column' },
      { field: 'serviceCenter', header: 'Service Centre', width: '15%', cssClass:'uscis-status-column' },
      { field: 'processType', header: 'Process Type', width: '8%', cssClass:'uscis-status-column' },
      { field: 'shortStatusUpdatedOn', header: 'USCIS Case Status', width: '17%', cssClass:'uscis-status-column' }
    ];
    this.cols = this.caseStatusListCols;
    //this.getTaskType();
    this.getCompanyList();
    //this.getServiceCenters(this.filter.processType);
    this.searchSetUp();
    // this.requestsLoaded$.pipe(takeUntil(this.observableSubscription$)).subscribe(()=>{
    //   this.isOnInit = false;
      
    // });
    this.applyFilters();
  }
  getTaskType() {
    this.referenceLibraryService.getReferenceData('SUPTSKTYP')
      .pipe(take(1),takeUntil(this.observableSubscription$))
      .subscribe((response: ReferenceLibrary[]) => {
        this.taskType = response;
        this.onTaskTypeChange();
      });
  }
  onTaskTypeChange(){
    if(this.filter.superTaskType ==='NONIMMPET'){
      this.getVisaTypes();
    }else{
      this.getIMMCategory();
      this.getIMMPetitionType();
    }
  }
  onVisaTypeChange(){
    this.handleFilterChange();
  }
  getVisaTypes(){
    if(!this.visaTypes){
      this.referenceLibraryService.getReferenceData('VISATYP')
      .pipe(take(1),takeUntil(this.observableSubscription$))
      .subscribe((response: ReferenceLibrary[]) => {
        this.visaTypes = [...new Map(response.map(item =>
          [item['code'], item])).values()];
        this.filter.visaTypes = this.visaTypes.map(x => x.code);
        //this.handleFilterChange();
        this.getPetitionType();
      });
    }else {
      this.filter.visaTypes = this.visaTypes.map(x => x.code);
      this.getPetitionType();
    }
  }
  getIMMClassification() {
    if (!this.immClassificationTypes && this.immCategoryTypes) {
      const req = [];
      this.immClassificationTypes = [];
      this.immCategoryTypes.forEach(immCategory => {
        req.push(this.referenceLibraryService.getReferenceData(immCategory.code));
      });
      combineLatest(req)
        .pipe(take(1))
        .subscribe((response: any[]) => {
          response.forEach((res,k) => {
            this.immClassificationTypes = [...this.immClassificationTypes , ...res];
          });
          this.filter.immClassificationTypes = this.immClassificationTypes.map(x => x.code);
          this.handleFilterChange();
        });
    } else {
      this.filter.immClassificationTypes = this.immClassificationTypes.map(x => x.code);
      this.handleFilterChange();
    }
  }
  getIMMCategory() {
    if (!this.immCategoryTypes) {
      this.referenceLibraryService.getReferenceData('GCIMMCATG')
        .pipe(take(1))
        .subscribe((response: ReferenceLibrary[]) => {
          this.immCategoryTypes = response;
          this.filter.immCategoryTypes = this.immCategoryTypes.map(x => x.code);
          this.getIMMClassification();
        }); 
    } else {
      this.filter.immCategoryTypes = this.immCategoryTypes.map(x => x.code);
      this.getIMMClassification();
    }
  }
  getIMMPetitionType(){
    this.APIService.getSubTasksType(this.filter.superTaskType)
      .pipe(take(1),takeUntil(this.observableSubscription$))
      .subscribe((response: ReferenceLibrary[]) => {
        this.subTaskTypes = [...new Map(response.map(item =>
          [item['code'], item])).values()];
        this.subTaskTypes.push({
          code: "PETITION",
          desc: "Petition",
          group: "TASKTYP",
          id: 494,
          name: "Petition"
        })
        this.filter.subTaskTypes = this.subTaskTypes.map(x => x.code);
        this.handleFilterChange();
      });
  }
  getPetitionType() {
    this.referenceLibraryService.getReferenceData('PETTYP')
      .pipe(take(1),takeUntil(this.observableSubscription$))
      .subscribe((response: ReferenceLibrary[]) => {
        this.petitionTypes = response;
        this.filter.petitionType = this.petitionTypes.map(x => x.code);
        this.handleFilterChange();
        if(this.isOnInit){
          this.requestsLoaded$.next();
        }
    });
  }

  getServiceCenters(processType){
    let flag = processType == 'PREMIUM' ? true : false;
    this.APIService.getServiceCenters(flag).pipe(take(1),takeUntil(this.observableSubscription$)).subscribe((response)=>{
      this.serviceCenters = response;
      this.filter.serviceCenterNameIds = this.serviceCenters.map(x => x.id);
      this.handleFilterChange();
    });
  }

  handleFilterChange() {
    if(this.filter.superTaskType ==='NONIMMPET'){
      this.payload.immClassificationTypes = [];
      this.payload.immCategoryTypes = [];
      this.payload.subTaskTypes = [];
      this.payload.visaTypes = this.filter.visaTypes;
      this.payload.petitionTypes = this.filter.petitionType;
    }else{
      this.payload.immClassificationTypes = this.filter.immClassificationTypes;
      this.payload.immCategoryTypes = this.filter.immCategoryTypes;
      this.payload.petitionTypes = [];
      this.payload.visaTypes = [];
      this.payload.subTaskTypes = this.filter.subTaskTypes;
    }
    this.payload.superTaskType = this.filter.superTaskType;
    this.payload.processType = this.filter.processType;
    this.payload.serviceCenterNameIds = this.filter.serviceCenterNameIds;
  }

  applyFilters(){
    this.searchText = '';
    this.payload.pageNumber = 1;
    this.payload.pageSize = 25;
    this.cols = this.caseStatusListCols;
    this.handleFilterChange();
    this.getCaseStatuses();
  }

  searchSetUp() {
    this.searchSubscription = fromEvent(this.searchCaseStatusListTable.nativeElement, 'input').pipe(
      map((event: any) => { return event.target.value; })
      , filter(res => res.length > 2 || !res)
      , debounceTime(1000)
      , distinctUntilChanged()).subscribe((text: string) => {
        this.searchText = text.trim();
        this.payload.searchText = this.searchText;
        this.getCaseStatuses();
      });
  }

  onCompanySelectionChange() {
    console.log('Selected Companies:', this.selectedCompanies);
    
    if(this.selectedCompanies.length===this.companyList.length)
    {
      this.companyIds = [];
     // this.selectedCompanies= 'All';
      this.selectedCompaniesText='All';
    }
    else
    { 
      if(this.selectedCompanies.includes('All') || this.selectedCompanies.length==0 )
      {
        this.selectedCompaniesText='All';
      }
      else
      {
        this.selectedCompaniesText = this.selectedCompanies.map(this.trimData.bind(this)).join(', ');
      }
    this.companyIds=this.selectedCompanies.length>0 ? this.selectedCompanies.map(caseStatus => caseStatus.companyId) : [];
    }
    this.getCaseStatuses();
  }
  trimData(item) {
    if(this.selectedCompanies.length==1 ){
      return item.companyName;
    }
    else {
      return item.companyName.substring(0,5)+ '..';
    }
  }

  getCheckedData(){
    this.payload.missingCaseStatus = this.checkedStatus;
    this.getCaseStatuses();
  }

  getCaseStatuses(){
    this.payload.companyIds=this.companyIds;
    this.APIService.getCaseStatusList(this.payload)
    .pipe(takeUntil(this.observableSubscription$))
    .subscribe((data) => {
      if (data !== undefined) {
      if(data['caseStatuses']){
        this.caseStatusList =data['caseStatuses'].map((el)=>{
          el['updatedDate'] = el['modifiedDate'] ? moment(el['modifiedDate']).format('L') : '';
          el['updatedTime'] = el['modifiedDate'] ? moment(el['modifiedDate']).format('LT') : '';
          return el;
        });
        
      }else{
        this.caseStatusList = [];
      }
      this.caseStatusList = data['caseStatuses'] ? data['caseStatuses'] : [];
      this.totalElementCount = data['totalElementCount'] ? data['totalElementCount'] : 0;
    }
    else
    {
      this.caseStatusList = [];
    }
    if(this.caseStatusList && this.caseStatusList.length>0)
      {
      this.caseStatusList = this.caseStatusList.map(item => ({
        ...item,
        visaOrImmCategory: item.visaType ? item.visaType : item.immCategory,
        petitionTypeOrImmClassification : item.petitionType ? item.petitionType : item.immClassification
      }));
    }
    });
   
  
  }
  getCompanyList() {
    this.APIService.getAllCompanies().subscribe((response: any) => {
      if (response.status === 200) {
        // Add "All Companies" option at the top
        this.companyList = response.data;
        this.selectedCompanies=this.companyList;
        this.selectedCompaniesText='All';
      }
    });
  }
  paginationEvent(event) {
    this.payload.pageNumber = event.page + 1;
    this.payload.pageSize = event.rows;
    this.getCaseStatuses();
  }

  editCase(_data, id) {
    this.router.navigate(['/platform-admin-landing/case-status-update', id]);
  }

  ngOnDestroy() {
    this.observableSubscription$.next();
    this.observableSubscription$.complete();
  }

  copyRecieptNo() {
    navigator.clipboard.writeText(this.receiptNumber);
    this.toastr.success('Reciept Number Copied', 'Success')
  }

  cancelDialog(){
    this.display = false;
  }

  showUSCISDialog(caseItem:any) {
    this.currentCaseItem = caseItem;
    this.receiptNumber = caseItem['receiptNumber'];
    this.shortCaseData = caseItem.shortStatus;
    this.receiptNumberContent = caseItem.uscisStatus;
    this.caseUpdatedTime = caseItem.updatedOn;
    this.display = true;
  }

  updateCaseStatus() {
    this.APIService.updateCaseStatusTracker()
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe({
        next: (response) => {
          if (response && response.status === 200) {
            this.toastr.success(response.message);
            this.getCaseStatuses(); // Refresh the list after successful update
          }
        },
        error: (error) => {
          this.toastr.error('Failed to update case status');
        }
      });
  }

}
