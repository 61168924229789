import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'app-i9-form-details',
    templateUrl: './i9-form-details.component.html',
    styleUrls: ['./i9-form-details.component.scss']
})
export class I9FormDetailsComponent implements OnInit {
    section2Form: FormGroup;

    supplementBForm: FormGroup;
    constructor(private fb: FormBuilder) { }

    ngOnInit(): void {
        this.section2Form = this.fb.group({
            listType: ['', Validators.required], // default value
            documentTitle1: [''],
            issuingAuthority1: [''],
            documentNumber1: [''],
            expirationDate1: [''],

            documentTitle2: [''],
            issuingAuthority2: [''],
            documentNumber2: [''],
            expirationDate2: [''],

            documentTitle3: [''],
            issuingAuthority3: [''],
            documentNumber3: [''],
            expirationDate3: [''],

            additionalInfo: [''],
            alternativeProcedure: [false],

            firstDayOfEmployment: ['', Validators.required],
            employerName: ['', Validators.required],
            employerSignature: [false],
            todayDate: ['', Validators.required],
            businessName: ['', Validators.required],
            businessAddress: ['', Validators.required]
        });


        this.supplementBForm = this.fb.group({
            lastName: [''],
            firstName: [''],
            middleInitial: [''],
            rehireRecords: this.fb.array([this.createRehireRecord()])
        });
    }

    get rehireRecords(): FormArray {
        return this.supplementBForm.get('rehireRecords') as FormArray;
    }

    createRehireRecord(): FormGroup {
        return this.fb.group({
            rehireDate: [''],
            newLastName: [''],
            newFirstName: [''],
            newMiddleInitial: [''],
            documentTitle: [''],
            documentNumber: [''],
            expirationDate: [''],
            alternativeProcedure: [false],
            employerName: [''],
            employerSignature: [false],
            todayDate: [''],
            additionalInfo: ['']
        });
    }

    addRehireRecord(): void {
        this.rehireRecords.push(this.createRehireRecord());
    }

    deleteRehireRecord(index: number): void {
        this.rehireRecords.removeAt(index);
    }

    submitSections() {
        console.log('Form Submitted', this.section2Form.value);
        console.log('Form Submitted', this.supplementBForm.value);
    }
}
