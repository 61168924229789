<div class="container-fuild">
  <div class="row pt-4 d-flex align-items-center">
    <div class="col-md-8 step-title">
      <h5 class="mb-0">Enter Proposed Job Description</h5>
      <p class="mb-0">Please provide Work Location, Position Details & List of Duties</p>
    </div>
    
    <div class="col-md-4 editBtn headerSubPortion px-0"> 
      <ul>
        <li class="col-auto px-0 firstPortion"> 
          <gc-status-update [stepDetails]="stepDetails" [visaType]="inputData.visatype" type="EDIT"></gc-status-update>
        </li>
        <li class="col-auto pr-0 pl-2 secondPortion">
          <lib-maximize-dialog-library [configData]="{'layoutCode':inputData?.layoutCode}"></lib-maximize-dialog-library>
          <i (click)="handleClose();" class="close-icon pi pi-times pull-right"></i>
        </li>
      </ul>
    </div>
  </div>
  <div class="row"> 
    <div class="col-md-12 p-0">
        <lib-assigning-task-library [visaType]="inputData.visatype" [stepDetails]="stepDetails" (updateStatus)="updateStepStatus($event)"
        [isViewMode]="inputData['isViewMode']" [isCaseRequest]="inputData['isCaseRequest']">
        </lib-assigning-task-library >
    </div>
  </div>
  <p-divider></p-divider>
  <form (ngSubmit)="prePostJobDescription()" [formGroup]="jobDescriptionForm">
    <div class="main-body">
      <div class="row col-md-12 mb-3">
        <h5 class="sub-title">Identify The Work Location</h5>
      </div>
      <div class="row mb-3" formArrayName="address">
        <div class="row" *ngFor="let item of getAddressFormArray(); let addressIndex= index">
          <div class="row w-100" [formGroupName]="addressIndex">
            <ng-template [ngIf]="fields.showWorkLocationTypeRadioBtn && addressIndex === 0">
              <div class="row col-md-12 mb-3">
                <div class="col-4 pl-0">
                  <p-radioButton [disabled]="disabledControls" formControlName="workLocationTypeCode" value="INHSE"
                    inputId="INHSE" name="workLocationTypeCode" label="In-House"
                    (click)="handleWorkLocationTypeChange('INHSE')">
                  </p-radioButton>
                </div>
                <div class="col-4 pl-0" *ngIf="fields.showThirdPartyRadioBtn">
                  <p-radioButton [disabled]="disabledControls" formControlName="workLocationTypeCode" value="TPARTY"
                    inputId="TPARTY" name="workLocationTypeCode" label="Third Party Site"
                    (click)="handleWorkLocationTypeChange('TPARTY')">
                  </p-radioButton>
                </div>
              </div>
            </ng-template>
            <div class="row w-100 pr-0 d-flex justify-content-end" *ngIf="fields.showDeleteAddressIcon">
              <button type="button" [disabled]="disabledControls || (jobDescriptionForm
                .get('address')['controls'].length === 1)" pButton class="mt-1 remove-work-location-btn"
                (click)="handleRemoveWorkClose(addressIndex)"><i class="remove-work-location pi pi-trash"></i></button>
            </div>
            <div class="row w-100 mb-3" *ngIf="dynamicDialogConfig.data.visatype !== 'L1A' && jobDescriptionForm
              .get('address')['controls']['0'].get('workLocationTypeCode').value === 'TPARTY'">
              <div class="col-md-6 form-group input-disabled">
                <label class="label-cls"> Client Name </label>
                <input class="im-input mw-100" id="clientName" formControlName="clientName" type="text" pInputText
                  [readonly]="disabledControls" placeholder="Enter" />
                <div *ngIf="item.get('clientName').touched && item.get('clientName').invalid">
                  <span class="invalid-message" *ngIf="item.get('clientName').errors?.required">
                    {{getGlobalErrorMessages('REQUIRED')}}
                  </span>
                </div>
              </div>
              <div class="col-md-3 input-disabled">
                <label class="label-cls"> Phone Number </label>
                <input class="im-input mw-100" id="clientPhoneNo" formControlName="clientPhoneNo" pInputText type="text"
                  [readonly]="disabledControls" maxlength="15" placeholder="Enter" />
                <div *ngIf="item.get('clientPhoneNo').touched && item.get('clientPhoneNo').invalid">
                  <span class="invalid-message" *ngIf="item.get('clientPhoneNo').errors?.required">
                    {{getGlobalErrorMessages('REQUIRED')}}
                  </span>
                  <span class="invalid-message" *ngIf="item.get('clientPhoneNo').errors?.pattern">
                    {{getGlobalErrorMessages('INVALID_FORMAT')}}
                  </span>
                </div>
              </div>
              <div class="col-md-3 input-disabled">
                <label class="label-cls"> Email ID </label>
                <input class="im-input mw-100" id="clientEmail" formControlName="clientEmail" pInputText type="email"
                  [readonly]="disabledControls" placeholder="Enter" />
                <div *ngIf="item.get('clientEmail').touched && item.get('clientEmail').invalid">
                  <span class="invalid-message" *ngIf="item.get('clientEmail').errors?.required">
                    {{getGlobalErrorMessages('REQUIRED')}}
                  </span>
                  <span class="invalid-message" *ngIf="item.get('clientEmail').errors?.pattern">
                    {{getGlobalErrorMessages('INVALID_FORMAT')}}
                  </span>
                </div>
              </div>
            </div>
            <div class="row w-100 mb-3">
              <div class="col-md-6 form-group input-disabled">
                <label class="label-cls required"> Address Line 1 </label>
                <input class="im-input mw-100" id="addressLine1" formControlName="addressLine1" type="text" pInputText
                  [readonly]="disabledControls" placeholder="Enter" />
                <div *ngIf="item.get('addressLine1').touched && item.get('addressLine1').invalid">
                  <span class="invalid-message" *ngIf="item.get('addressLine1').errors?.required">
                    {{getGlobalErrorMessages('REQUIRED')}}
                  </span>
                </div>
              </div>
              <div class="col-md-6 input-disabled">
                <label class="label-cls"> Address Line 2 </label>
                <input class="im-input mw-100" id="addressLine2" formControlName="addressLine2" pInputText type="text"
                  [readonly]="disabledControls" placeholder="Enter" />
                <div *ngIf="item.get('addressLine2').touched && item.get('addressLine2').invalid">
                  <span class="invalid-message" *ngIf="item.get('addressLine2').errors?.required">
                    {{getGlobalErrorMessages('REQUIRED')}}
                  </span>
                </div>
              </div>
            </div>
            <div class="row w-100 mb-3">
              <div class="col-md-3 form-group processing-dropdown">
                <label class="label-cls  required"> Country </label>
                <p-dropdown appendTo="body" [options]="countryList" id="countryCode" formControlName="countryCode"
                  [disabled]="disabledControls || fields.disableCountryDropdown" name="country"
                  optionLabel="countryName" optionValue="countryCode" placeholder="select"
                  [readonly]="fields.disableCountryDropdown"
                  (onChange)="handleCountryChange($event.value, addressIndex, 'workAddress')"> </p-dropdown>
                <div *ngIf="item.get('countryCode').touched && item.get('countryCode').invalid">
                  <span class="invalid-message" *ngIf="item.get('countryCode').errors?.required">
                    {{getGlobalErrorMessages('REQUIRED')}}
                  </span>
                </div>
              </div>
              <div class="col-md-3 form-group processing-dropdown"
                *ngIf="statelistArrObj.workAddress.length > 0 && statelistArrObj.workAddress[addressIndex] && statelistArrObj.workAddress[addressIndex].stateList && statelistArrObj.workAddress[addressIndex].stateList.length > 0">
                <label class="label-cls required"> State </label>
                <p-dropdown appendTo="body" [options]="statelistArrObj.workAddress[addressIndex].stateList"
                  id="workAddressStateProvinceCode" formControlName="stateProvinceCode" [disabled]="disabledControls"
                  name="state" optionLabel="stateProvinceName" optionValue="stateProvinceCode" placeholder="select">
                </p-dropdown>
                <div *ngIf="item.get('stateProvinceCode').touched && item.get('stateProvinceCode').invalid">
                  <span class="invalid-message" *ngIf="item.get('stateProvinceCode').errors?.required">
                    {{getGlobalErrorMessages('REQUIRED')}}
                  </span>
                </div>
              </div>
              <div class="col-md-3 input-disabled"
                *ngIf="statelistArrObj.workAddress.length > 0 && statelistArrObj.workAddress[addressIndex] && (!statelistArrObj.workAddress[addressIndex].stateList || (statelistArrObj.workAddress[addressIndex].stateList && statelistArrObj.workAddress[addressIndex].stateList.length === 0))">
                <label class="label-cls required"> State </label>
                <input class="im-input mw-100" id="workAddressStateProvinceCodeText" formControlName="stateProvinceName"
                  pattern="[a-zA-Z ]*" type="text" [readonly]="disabledControls" pInputText placeholder="Enter" />
                <div *ngIf="item.get('stateProvinceName').touched && item.get('stateProvinceName').invalid">
                  <span class="invalid-message" *ngIf="item.get('stateProvinceName').errors?.required">
                    {{getGlobalErrorMessages('REQUIRED')}}
                  </span>
                </div>
              </div>
              <div class="col-md-3 input-disabled">
                <label class="label-cls required">City </label>
                <input class="im-input mw-100" id="city" formControlName="city" pattern="[a-zA-Z ]*" type="text"
                  [readonly]="disabledControls" placeholder="Enter" />
                <div *ngIf="item.get('city').touched && item.get('city').invalid">
                  <span class="invalid-message" *ngIf="item.get('city').errors?.required">
                    {{getGlobalErrorMessages('REQUIRED')}}
                  </span>
                  <span class="invalid-message" *ngIf="item.get('city').touched && item.get('city').errors?.pattern">
                    {{getGlobalErrorMessages('INVALID_FORMAT')}}
                  </span>
                </div>
              </div>
              <div class="col-md-3 input-disabled">
                <label class="label-cls required"> Postal Code </label>
                <input class="im-input" id="postCode" formControlName="postCode" type="text" pInputText
                  [readonly]="disabledControls" placeholder="Enter" />
                <div *ngIf="item.get('postCode').touched && item.get('postCode').invalid">
                  <span class="invalid-message" *ngIf="item.get('postCode').errors?.required">
                    {{getGlobalErrorMessages('REQUIRED')}}
                  </span>
                  <span class="invalid-message"
                    *ngIf="item.get('postCode').touched && item.get('postCode').errors?.pattern">
                    {{getGlobalErrorMessages('INVALID_FORMAT')}}
                  </span>
                </div>
              </div>
            </div>
            <p-divider class="mb-3 w-100" *ngIf="addressIndex < (getAddressFormArray().length - 1)"></p-divider>
          </div>
        </div>
      </div>
      <p-divider *ngIf="fields.showDividerBeforeAddMoreLocation"></p-divider>
      <div class="row w-100 save-location" *ngIf="fields.showAddMoreLocation">
        <button type="button" [disabled]="disabledControls" pButton class="mt-1 p-button-outlined"
          (click)="handleAddLocationClick()">Add more location</button>
      </div>
      <p-divider></p-divider>
      <div class="row col-md-12">
        <h5 class="sub-title">Position Details</h5>
      </div>
      <div class="row mb-3" *ngIf="!fields.showExtraFieldsPositionDetails">
        <div class="col-md-4 form-group input-disabled">
          <label class="label-cls  required"> Position Title </label>
          <input class="im-input mw-100" [readonly]="disabledControls" formControlName="jobTitle" type="text" pInputText
            placeholder="Enter" />
          <div
            *ngIf="jobDescriptionForm.controls['jobTitle'].touched && jobDescriptionForm.controls['jobTitle'].invalid">
            <span class="invalid-message" *ngIf="jobDescriptionForm.controls['jobTitle'].errors?.required">
              {{getGlobalErrorMessages('REQUIRED')}}
            </span>
            <span class="invalid-message" *ngIf="jobDescriptionForm.controls['jobTitle'].errors?.pattern">
              {{getGlobalErrorMessages('INVALID_FORMAT')}}
            </span>
          </div>
        </div>
      </div>
      <div *ngIf="fields.showExtraFieldsPositionDetails">
        <div class="row">
          <div class="col-md-6 form-group processing-dropdown">
            <label class="label-cls required"> Position Title </label>
            <p-dropdown [options]="positionTitleList" id="positiontitle" formControlName="positionTitileId"
              [disabled]="disabledControls" name="positionTitileId" optionLabel="postitionName" optionValue="id"
              placeholder="select" (onChange)="handlePositionChange($event.value)"> </p-dropdown>
            <div
              *ngIf="jobDescriptionForm.controls['positionTitileId'].touched && jobDescriptionForm.controls['positionTitileId'].invalid">
              <span class="invalid-message" *ngIf="jobDescriptionForm.controls['positionTitileId'].errors?.required">
                {{getGlobalErrorMessages('REQUIRED')}}
              </span>
            </div>
          </div>
          <div class="col-md-4 form-group input-disabled">
            <label class="label-cls"> Job Role </label>
            <input class="im-input mw-100" [readonly]="disabledControls" formControlName="jobRole" type="text"
              pInputText placeholder="Enter" />
            <div
              *ngIf="jobDescriptionForm.controls['jobRole'].touched && jobDescriptionForm.controls['jobRole'].invalid">
              <span class="invalid-message" *ngIf="jobDescriptionForm.controls['jobRole'].errors?.required">
                {{getGlobalErrorMessages('REQUIRED')}}
              </span>
              <span class="invalid-message" *ngIf="jobDescriptionForm.controls['jobRole'].errors?.pattern">
                {{getGlobalErrorMessages('INVALID_FORMAT')}}
              </span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 form-group processing-dropdown">
            <label class="label-cls  required"> Education Required </label>
            <p-dropdown [options]="degreeList" id="educationId" formControlName="educationRequiredId"
              [disabled]="disabledControls" name="educationRequiredId" optionLabel="qualfName" optionValue="id"
              placeholder="select"> </p-dropdown>
            <div
              *ngIf="jobDescriptionForm.controls['educationRequiredId'].touched && jobDescriptionForm.controls['educationRequiredId'].invalid">
              <span class="invalid-message" *ngIf="jobDescriptionForm.controls['educationRequiredId'].errors?.required">
                {{getGlobalErrorMessages('REQUIRED')}}
              </span>

            </div>
          </div>
        </div>
        <div class="row">
          <div class="row">
            <div class="form-group col-12">
              <label class="label-cls">What will be the required field of study for this position ? <small
                  class="p-form-note">(Please enter comma separated values)</small></label>
              <p-chips class="p-form-control" [disabled]="disabledControls" placeholder="Enter" class="p-form-chips"
                formControlName="fieldOfStudy" separator=",">
              </p-chips>
            </div>
          </div>
        </div>
        <div class="row">

          <div class="col-md-4 form-group input-disabled">
            <label class="label-cls  required"> Experience Required (Years)</label>
            <input class="im-input mw-100" [readonly]="disabledControls" formControlName="experienceRequired"
              type="text" pInputText placeholder="Enter" />
            <div
              *ngIf="jobDescriptionForm.controls['experienceRequired'].touched && jobDescriptionForm.controls['experienceRequired'].invalid">
              <span class="invalid-message" *ngIf="jobDescriptionForm.controls['experienceRequired'].errors?.required">
                {{getGlobalErrorMessages('REQUIRED')}}
              </span>
              <span class="invalid-message" *ngIf="jobDescriptionForm.controls['experienceRequired'].errors?.pattern">
                {{getGlobalErrorMessages('INVALID_FORMAT')}}
              </span>
              <span class="invalid-message" *ngIf="jobDescriptionForm.controls['experienceRequired'].errors?.min">
                Minimum {{jobDescriptionForm.controls['experienceRequired'].errors?.min?.min}} years of Experience
                Required
              </span>
              <span class="invalid-message" *ngIf="jobDescriptionForm.controls['experienceRequired'].errors?.max">
                Please enter the valid Experience
              </span>
            </div>
          </div>

        </div>

      </div>
      <div class="row mb-3">
        <div class="col-md-4 form-group">
          <label class="label-cls  required"> Start Date </label>
          <p-calendar styleClass="p-form-datepicker" appendTo="body" dateFormat="mm/dd/yy" placeholder="MM/DD/YYYY"
            [showIcon]="true" [maxDate]="jobDescriptionForm.get('endDate').value" [disabled]="disabledControls" formControlName="startDate" [monthNavigator]="true" [yearNavigator]="true"
            yearRange="1910:2050">
          </p-calendar>
          <div
            *ngIf="jobDescriptionForm.controls['startDate'].touched && jobDescriptionForm.controls['startDate'].invalid">
            <span class="invalid-message" *ngIf="jobDescriptionForm.controls['startDate'].errors?.required">
              {{getGlobalErrorMessages('REQUIRED')}}
            </span>
          </div>
        </div>
        <div class="col-md-4 form-group input-disabled">
          <label class="label-cls  required"> End Date </label>
          <p-calendar class="mw-100" appendTo="body" styleClass="p-form-datepicker" dateFormat="mm/dd/yy"
            placeholder="MM/DD/YYYY" [showIcon]="true" [minDate]="jobDescriptionForm.get('startDate').value" [disabled]="disabledControls || !jobDescriptionForm.get('startDate').value"
            formControlName="endDate" [monthNavigator]="true" [yearNavigator]="true" yearRange="1910:2050">
          </p-calendar>
          <div *ngIf="jobDescriptionForm.controls['endDate'].touched && jobDescriptionForm.controls['endDate'].invalid">
            <span class="invalid-message" *ngIf="jobDescriptionForm.controls['endDate'].errors?.required">
              {{getGlobalErrorMessages('REQUIRED')}}
            </span>
          </div>
        </div>
      </div>
      <div class="row mb-3">
      </div>
      <div class="row mb-3">
      </div>
      <div class="form-group">
        <div class="row col-md-6">
          <div class="col-md-11 pl-0 bold-label" name="question">
            <label class="label-cls">Is this a full time position?</label>
          </div>
          <div class="col-md-1">
            <p-inputSwitch id="thisFulltime" [disabled]="disabledControls" formControlName="thisFulltime"
              (onChange)="handleDataChange($event)">
            </p-inputSwitch>
          </div>
        </div>
        <div class="row" *ngIf="jobDescriptionForm.get('thisFulltime').value === false">
          <div class="col-md-4 form-group input-disabled">
            <label class="label-cls required"> Working Hours per week </label>
            <input [readonly]="disabledControls" type="text" class="im-input mw-100" id="workingHoursPerWeek"
              formControlName="workingHoursPerWeek" placeholder="Enter" pattern="\d{1,5}" pInputText />
            <div
              *ngIf="jobDescriptionForm.get('workingHoursPerWeek').touched && jobDescriptionForm.get('workingHoursPerWeek').invalid">
              <span class="invalid-message" *ngIf="jobDescriptionForm.get('workingHoursPerWeek').errors?.required">
                {{getGlobalErrorMessages('REQUIRED')}}
              </span>
              <span class="invalid-message" *ngIf="jobDescriptionForm.get('workingHoursPerWeek').errors?.pattern">
                {{getGlobalErrorMessages('NUMBER_ONLY')}}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="row col-md-12">
        <h5 class="sub-title">Wage offer Information</h5>
      </div>
      <div class="row mb-3">
        <div class="col-md-4 form-group input-disabled">
          <label class="label-cls required"> Wage </label>
          <span class="p-input-icon-left mask-input mw-100"> <i class="pi pi-dollar"></i>
            <input class="im-input mask-in mw-100" id="wagesFrom" formControlName="wagesFrom" type="text"
              [readonly]="disabledControls" pInputText placeholder="" /> </span>
          <div
            *ngIf="jobDescriptionForm.controls['wagesFrom'].touched && jobDescriptionForm.controls['wagesFrom'].invalid">
            <span class="invalid-message" *ngIf="jobDescriptionForm.controls['wagesFrom'].errors?.required">
              {{getGlobalErrorMessages('REQUIRED')}}
            </span>
            <span class="invalid-message" *ngIf="jobDescriptionForm.controls['wagesFrom'].errors?.pattern">
              {{getGlobalErrorMessages('NUMBER_ONLY')}}
            </span>
          </div>
        </div>
        <div class="col-md-4 processing-dropdown">
          <label class="label-cls  required"> Wage Unit </label>
          <p-dropdown appendTo="body" [options]="wageUnitType" [filter]="false" id="wageTypeCode"
            formControlName="wageTypeCode" [disabled]="disabledControls" optionLabel="name" optionValue="code"
            placeholder="select">
          </p-dropdown>
          <div
            *ngIf="jobDescriptionForm.controls['wageTypeCode'].touched && jobDescriptionForm.controls['wageTypeCode'].invalid">
            <span class="invalid-message" *ngIf="jobDescriptionForm.controls['wageTypeCode'].errors?.required">
              {{getGlobalErrorMessages('REQUIRED')}}
            </span>
          </div>
        </div>
      </div>
      <p-divider></p-divider>
      <div class="row col-md-12" *ngIf="fields.showItinarySection">
        <h5 class="sub-title">Itinerary Information</h5>
      </div>
      <div class="row mb-3" *ngIf="fields.showItinarySection">
        <!-- <div class="row col-md-12 mb-2" [formGroupName]="0"> -->
        <div class="row" formArrayName="itinenaryInfoList"
          *ngFor="let item of jobDescriptionForm.get('itinenaryInfoList')['controls']; let itineraryIndex= index">
          <div class="row w-100" [formGroupName]="itineraryIndex">
            <div class="row w-100 pr-0 d-flex justify-content-end">
              <button type="button" [disabled]="disabledControls || (jobDescriptionForm
              .get('itinenaryInfoList')['controls'].length === 1)" pButton class="mt-1 remove-itinerary-btn" title="Remove"
                (click)="handleRemoveItineraryClose(itineraryIndex)"><i
                  class="remove-itinerary pi pi-times"></i></button>
            </div>
            <div class="row mb-3">
              <div class="col-md-4 form-group input-disabled">
                <label class="label-cls  required"> Type of Work </label>
                <input class="im-input mw-100" [readonly]="disabledControls" formControlName="typeOfwork" type="text"
                  pInputText placeholder="Enter" />
                <!-- {{jobDescriptionForm.get('itinenaryInfoList')['controls']['0'].get('typeOfwork').value}} -->
                <div *ngIf="item.get('typeOfwork').touched && item.get('typeOfwork').invalid">
                  <span class="invalid-message" *ngIf="item.get('typeOfwork').errors?.required">
                    {{getGlobalErrorMessages('REQUIRED')}}
                  </span>
                </div>
              </div>
              <div class="col-md-4 form-group">
                <label class="label-cls  required"> Start Date </label>
                <p-calendar styleClass="p-form-datepicker" appendTo="body" dateFormat="mm/dd/yy"
                  placeholder="MM/DD/YYYY" [showIcon]="true" [maxDate]="item.get('endDate').value"
                  [disabled]="disabledControls" formControlName="startDate"
                  [monthNavigator]="true" [yearNavigator]="true" yearRange="1910:2050">
                </p-calendar>
                <div *ngIf="item.get('startDate').touched && item.get('startDate').invalid">
                  <span class="invalid-message" *ngIf="item.get('startDate').errors?.required">
                    {{getGlobalErrorMessages('REQUIRED')}}
                  </span>
                </div>
              </div>
              <div class="col-md-4 form-group input-disabled">
                <label class="label-cls  required"> End Date </label>
                <p-calendar class=" mw-100" appendTo="body" styleClass="p-form-datepicker" dateFormat="mm/dd/yy"
                  placeholder="MM/DD/YYYY" [showIcon]="true" [minDate]="item.get('startDate').value" [disabled]="disabledControls || !item.get('startDate').value"
                  formControlName="endDate" [monthNavigator]="true" [yearNavigator]="true" yearRange="1910:2050">
                </p-calendar>
                <div *ngIf="item.get('endDate').touched && item.get('endDate').invalid">
                  <span class="invalid-message" *ngIf="item.get('endDate').errors?.required">
                    {{getGlobalErrorMessages('REQUIRED')}}
                  </span>
                </div>
              </div>
            </div>
            <div class="row w-100 mb-3">
              <div class="col-md-6 form-group input-disabled">
                <label class="label-cls required"> Address Line 1 </label>
                <input class="im-input mw-100" id="addressLine1" formControlName="addressLine1" type="text" pInputText
                  [readonly]="disabledControls" placeholder="Enter" />
                <div *ngIf="item.get('addressLine1').touched && item.get('addressLine1').invalid">
                  <span class="invalid-message" *ngIf="item.get('addressLine1').errors?.required">
                    {{getGlobalErrorMessages('REQUIRED')}}
                  </span>
                </div>
              </div>
              <div class="col-md-6 input-disabled">
                <label class="label-cls"> Address Line 2 </label>
                <input class="im-input mw-100" id="addressLine2" formControlName="addressLine2" pInputText type="text"
                  [readonly]="disabledControls" placeholder="Enter" />
                <!-- <div *ngIf="item.get('addressLine2').touched && item.get('addressLine2').invalid">
                <span class="invalid-message" *ngIf="item.get('addressLine2').errors?.required">
                  {{getGlobalErrorMessages('REQUIRED')}}
                </span>
              </div> -->
              </div>
            </div>
            <div class="row w-100 mb-3">
              <div class="col-md-3 form-group processing-dropdown">
                <label class="label-cls  required"> Country </label>
                <p-dropdown [options]="countryList" id="countryCode" formControlName="countryCode"
                  [disabled]="disabledControls" name="country" optionLabel="countryName" optionValue="countryCode"
                  placeholder="select"
                  (onChange)="handleCountryChange($event.value, itineraryIndex, 'itenanoryAddress')"> </p-dropdown>
                <div *ngIf="item.get('countryCode').touched && item.get('countryCode').invalid">
                  <span class="invalid-message" *ngIf="item.get('countryCode').errors?.required">
                    {{getGlobalErrorMessages('REQUIRED')}}
                  </span>
                </div>
              </div>
              <div class="col-md-3 form-group processing-dropdown"
                *ngIf="statelistArrObj.itenanoryAddress.length > 0 && statelistArrObj.itenanoryAddress[itineraryIndex] && statelistArrObj.itenanoryAddress[itineraryIndex].stateList && statelistArrObj.itenanoryAddress[itineraryIndex].stateList.length > 0">
                <label class="label-cls required"> State </label>
                <p-dropdown [options]="statelistArrObj.itenanoryAddress[itineraryIndex].stateList"
                  id="itenanoryAddressStateProvinceCode" formControlName="stateProvinceCode"
                  [disabled]="disabledControls" name="state" optionLabel="stateProvinceName"
                  optionValue="stateProvinceCode" required placeholder="select"> </p-dropdown>
                <div *ngIf="item.get('stateProvinceCode').touched && item.get('stateProvinceCode').invalid">
                  <span class="invalid-message" *ngIf="item.get('stateProvinceCode').errors?.required">
                    {{getGlobalErrorMessages('REQUIRED')}}
                  </span>
                </div>
              </div>
              <div class="col-md-3 input-disabled"
                *ngIf="statelistArrObj.itenanoryAddress.length > 0 && statelistArrObj.itenanoryAddress[itineraryIndex] && (!statelistArrObj.itenanoryAddress[itineraryIndex].stateList || (statelistArrObj.itenanoryAddress[itineraryIndex].stateList && statelistArrObj.itenanoryAddress[itineraryIndex].stateList.length === 0))">
                <label class="label-cls"> State </label>
                <input class="im-input mw-100" id="itenanoryAddressStateProvinceCodeText"
                  formControlName="stateProvinceName" pattern="[a-zA-Z ]*" type="text" [readonly]="disabledControls"
                  pInputText placeholder="Enter" />
                <div *ngIf="item.get('stateProvinceName').touched && item.get('stateProvinceName').invalid">
                  <span class="invalid-message" *ngIf="item.get('stateProvinceName').errors?.required">
                    {{getGlobalErrorMessages('REQUIRED')}}
                  </span>
                </div>
              </div>
              <div class="col-md-3 input-disabled">
                <label class="label-cls  required"> City </label>
                <input class="im-input mw-100" id="city" formControlName="city" pInputText type="text"
                  pattern="[a-zA-Z ]*" [readonly]="disabledControls" placeholder="Enter" />
                <div *ngIf="item.get('city').touched && item.get('city').invalid">
                  <span class="invalid-message" *ngIf="item.get('city').errors?.required">
                    {{getGlobalErrorMessages('REQUIRED')}}
                  </span>
                  <span class="invalid-message" *ngIf="item.get('city').touched && item.get('city').errors?.pattern">
                    {{getGlobalErrorMessages('INVALID_FORMAT')}}
                  </span>
                </div>
              </div>
              <div class="col-md-3 input-disabled">
                <label class="label-cls required"> Postal Code </label>
                <input class="im-input" id="postCode" formControlName="postCode" type="text" pInputText
                  [readonly]="disabledControls" placeholder="Enter" />
                <div *ngIf="item.get('postCode').touched && item.get('postCode').invalid">
                  <span class="invalid-message" *ngIf="item.get('postCode').errors?.required">
                    {{getGlobalErrorMessages('REQUIRED')}}
                  </span>
                  <span class="invalid-message"
                    *ngIf="item.get('postCode').touched && item.get('postCode').errors?.pattern">
                    {{getGlobalErrorMessages('INVALID_FORMAT')}}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- </div> -->
      </div>
      <p-divider *ngIf="fields.showItinarySection"></p-divider>
      <div class="row w-100 save-itinenary" *ngIf="fields.showItinarySection">
        <button type="button" [disabled]="disabledControls" pButton class="mt-1 p-button-outlined"
          (click)="handleAddItinenaryClick()">Add more itinenary</button>
      </div>
      <p-divider *ngIf="fields.showItinarySection"></p-divider>
      <div class="row col-md-12">
        <h5 class="sub-title">Upload Offer Letter</h5>
      </div>
      <div class="row">
        <i140-file-upload style="width: 100%;" [fileUploadConfigurations]="fileUploadConfigurations">
        </i140-file-upload>
      </div>

      <!-- <div class="row col-md-6 pb-2">
          <div class="col-md-11  form-group pl-0 bold-label">
            <label class="label-cls">Is this a permanent position?</label>
          </div>
          <div class="col-md-1  form-group">
            <p-inputSwitch [disabled]="disabledControls" id="thisPermanentPos" formControlName="thisPermanentPos">
            </p-inputSwitch>
          </div>
        </div>
        <div class="row col-md-6 pb-2">
          <div class="col-md-11  form-group pl-0 bold-label">
            <label class="label-cls">Is this a new position?</label>
          </div>
          <div class="col-md-1  form-group">
            <p-inputSwitch [disabled]="disabledControls" id="thisNewPos" formControlName="thisNewPos">
            </p-inputSwitch>
          </div>
        </div> -->

      <!-- <div class="row col-md-12 pl-0 pr-0 profile-title pt-3" *ngIf="fields.showItinarySection">
          <h6>Explain in detail about Extraordinary ability field - {{ this.fieldExtrAbltyName }}</h6>
          <div class="col-md-12 pl-0 pr-0 beneficiary-experience-froala-editor">
            <textarea [(froalaModel)]="template.body" name="text" [froalaEditor]="options">
            </textarea>

          </div>
        </div> -->

      <!-- <div class="row col-md-12 pl-0 pr-0 profile-title pt-3"  // IM-11552 fix 
        *ngIf="inputData.visatype === 'P1A' || inputData.visatype === 'P1A_extn'">
        <h6>Explain in detail about Extraordinary ability field {{ this.fieldExtrAbltyName }}</h6>
        <div class="col-md-12 pl-0 pr-0 beneficiary-experience-froala-editor">
          <textarea [(froalaModel)]="template.body" name="text" [froalaEditor]="options">
          </textarea>

        </div>
      </div> -->
      <p-divider></p-divider>
      <job-duties *ngIf="inputData && jobDescriptionForm" [disabledControls]="disabledControls"
        (deleteAllDutie)="handleDeleteAllDuties()" [inputData]="inputData" (addUpdateDuty)="handleAddUpdateDuty($event)"
        [parentForm]="jobDescriptionForm" (deleteDuty)="handleDutyDelete($event)"
        (deleteSubDuty)="handleSubDutyDelete($event)" (deleteDutiesOnPopulateDuties)="alldutiesDelete()"></job-duties>
    </div>

    <p-divider></p-divider>
    <!-- pending UI verification done -->
   <div class="row pt-3 mt-n3">
    <lib-custom-questionnaire [stepId]="inputData.stepId" [taskId]="inputData.taskId"
    [viewQuestions]="false" [showSaveBtn]="false" [loadedIn]="stepDetails.stepName"
    [isSection]="false"></lib-custom-questionnaire>
   </div>
    <p-divider></p-divider>
    
    <div class="row pt-3">
      <div class="col-md-2">
        <button pButton type="submit" label="Save" title="Save" icon="pi pi-save"
          [ngClass]="jobDescriptionForm.invalid  || disabledControls ? 'btn-success button-width-icon-left mr-3 btn-success_disabled':'btn-success button-width-icon-left mr-3'"
          [disabled]="jobDescriptionForm.invalid  || disabledControls"></button>
      </div>
      <div class="col-md-2">
        <button pButton type="button" label="Cancel" icon="pi pi-times-circle" class="p-button-secondary cancel-btn button-width-icon-left
                  d-flex alogn-items-center" [disabled]="disabledControls" (click)="handleCancel()">
        </button>
      </div>
      <div class="col-md-2"></div>
      <div class="col-md-6">
        <gc-status-update [stepDetails]="stepDetails" [visaType]="inputData.visatype" [type]="typeOfButton" [showPreviousStepBtn]="showPrev" [showNextStepBtn]="showNext" class="d-flex col-12 justify-content-end">
        </gc-status-update>
      </div>
    </div>
  </form>
</div>
