<div *ngIf="isSelectCategory || emailData">

  <div class="row mt-3 mb-2">

    <div class="col-md-8 float-left float-start">
      <h1 class="heading-compose">{{currentSelected}} Email  <span *ngIf="isemailActivated" class="subjectHeading">  (Subject: {{replyForm.controls['subject'].value}})</span></h1>
    </div>
 
    <div class="col-md-3 float-right" >
      <!-- <select class="form-control" name="tmplate"  *ngIf="!isemailActivated && !emailData" placeholder="Select" [style]="{width: '300px'}" [(ngModel)]="templateListModel" 
       (ngModelChange)="templateOnChange($event)">
       <option [value]="key?.id" *ngFor="let key of templateList; let i = index" [attr.selected]="key?.id">{{key?.templateName}}</option>
      </select>   -->
      
      
      <p-dropdown class="d-block w-300" *ngIf="!isemailActivated && !emailData"  [style]="{'width':'300px'}" required 
      [options]="templateList" [(ngModel)]="templateListModel" (onChange)="templateOnChange($event)"
      optionLabel="templateName" optionValue="id">
    </p-dropdown>



    </div>


    <div class="col-md-1 float-right float-end" style="text-align: right;" (click)="onCancel()">
      <button type="button" class="close" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

  </div>

  <div class="clearfix"></div>

  
    <form [formGroup]="replyForm">    
    <div class="my-1 mb-1 bg-light">

          <div class="float-left" *ngIf="isemailActivated">
            <p class="label-cls font12" [ngClass]="{'mb-2 mt-3': !isemailActivated }">From: <b>{{fromEmail}}</b></p>
            <p class="label-cls float-left font12" [ngClass]="{'mb-2 mt-3': !isemailActivated }">To: <b>{{replyForm.controls['to'].value}}</b></p>
            <p class="label-cls  float-left font12" [ngClass]="{'mb-2 mt-3': !isemailActivated }"> &nbsp;CC:<b> {{replyForm.controls['cc'].value}}</b></p>
          </div>

          <div class="float-right">

           
            <div class="float-right method" (click)="onEditSend()" [ngClass]="{'disabled': replyForm.invalid}" *ngIf="isemailActivated">
                <i class="bi bi-reply"></i>&nbsp;
                <span class="grey-text">REPLY</span>
            </div>
            
            <div class="float-right method" (click)="onSend('SENT')" [ngClass]="{'disabled': replyForm.invalid}" *ngIf="!isemailActivated">
              <i class="fa fa-paper-plane-o" aria-hidden="true" ></i>
              <span class="grey-text" *ngIf="!isReply || this.emailData.folder == 'DRAFT'">SEND</span>
            </div>

            <div class="email-date" *ngIf="isemailActivated">{{emailData?.dateTime | date: 'MM/dd/yyyy hh:mm'}}</div>
          </div>
        </div>
  
    <div class="clearfix"></div>

    <div class="container">
      <div class="row" *ngIf="!isemailActivated">
        <div class="col-12 p-0 mt-2"> 
          <p class="label-cls">From</p>
          <input class="no-margin" style="background-color: #f1f1f1;" type="text" name="fromEmail2" #fromEmail2 [value]="fromEmail" disabled />
        
        </div>

        <div class="col-md-12 p-0 mt-2">
          <p class="label-cls required">To</p>
          <p-chips class="w-100" formControlName="to" separator="," [allowDuplicate]="false" (onAdd)="validateEmailAddress($event,'to')"
                  required [disabled]="isemailActivated" ></p-chips>
        
            <span >
                <small *ngIf="(replyForm.get('to').touched || submitted) && replyForm.get('to').errors" class="p-error">
                <span *ngIf="replyForm.get('to').errors['required']">To is required.</span>
                <span *ngIf="replyForm.get('to').hasError('pattern') && !replyForm.get('to').errors['duplicateEmail']">To
                  email(s) Invalid.</span>
                <span *ngIf="replyForm.get('to').errors['duplicateEmail']">Duplicate email match in cc. </span>
                </small> 
            </span>
        </div>
        <div class="col-md-12 p-0 mt-2">
          <p class="label-cls">CC</p>
          <p-chips class="w-100" formControlName="cc" separator="," [allowDuplicate]="false" (onAdd)="validateEmailAddress($event,'cc')"></p-chips>
          
          <span >
            <small *ngIf="(replyForm.get('cc').touched || submitted) && replyForm.get('cc').errors" class="p-error">
              <span *ngIf="replyForm.get('cc').hasError('pattern') && !replyForm.get('cc').errors['duplicateEmail']">CC
                email(s) Invalid.</span>
              <span *ngIf="replyForm.get('cc').errors['duplicateEmail']">Duplicate email match in To. </span>
            </small>
          </span>
        </div>

        <div class="col-12 p-0 mt-2">
          <p class="label-cls required">Subject</p>
          <input type="text" class="no-margin" #subject name="subject" formControlName="subject"  />
        
          <span >
            <small *ngIf="(replyForm.get('subject').touched || submitted) && replyForm.get('subject').errors"
              class="p-error">
              <span *ngIf="replyForm.get('subject').errors['required']">Subject is required.</span>
            </small>
          </span>
        </div>
      </div>
      <div class="d-flex flex-column mt-2"  >
        <div class="d-flex align-items-center" *ngIf="!isemailActivated">
          <p-fileUpload 
          [disabled]="isemailActivated"
            #createUploader 
            name="demo[]" 
            mode="basic" 
            chooseLabel="Attach File" 
            chooseIcon="pi pi-paperclip"
            [auto]="true" customUpload="true"
            (uploadHandler)="Upload($event, createUploader)" 
            accept="{{acceptedFiles}}" 
            multiple="multiple"
            showUploadButton="false" 
            showCancelButton="true" 
            [outlined]="true"
            maxFileSize="10485760">
          </p-fileUpload>
          <!-- <p class="label-cls ml-2 mb-2 mt-3"><span><i class="pi pi-exclamation-circle"
                pTooltip="Allowed File Types: pdf, jpg, png" tooltipPosition="top"></i></span></p> -->
        </div>
        <div class="colp-0">

          <ng-container>
            <ul *ngIf="(uploadedFiles$ | async)?.length" class="list-group list-group-horizontal mt-1 flex-wrap">
              <i class="bi bi-paperclip"></i>
              <li *ngFor="let file of (uploadedFiles$ | async); let i=index" class="attachment-item">
                <span>{{file?.name}} -</span>
                <span id="deleteCls" *ngIf="file?.fileLocation" (click)="openFile(file)" class="bi bi-eye"></span>
                <span id="deleteClsCross" *ngIf="!file?.fileLocation" (click)="deleteFile(i)" class="fa fa-times"></span>
              </li>
            </ul>
          </ng-container>
        </div>
      </div>
      <div class="row mt-3">
        <div class="customInputContainer" [ngClass]="{'isemailActivated': isemailActivated == true, 'notemailActivated': isemailActivated == false}" >
          <kendo-editor #commonkendoeditor [disabled]="isemailActivated"  name="editor" formControlName="editor" style=" height: 500px; 
    min-width: 1110px;">
            <kendo-toolbar>
              <kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorStrikethroughButton></kendo-toolbar-button>
              </kendo-toolbar-buttongroup>
              <kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorSubscriptButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorSuperscriptButton></kendo-toolbar-button>
              </kendo-toolbar-buttongroup>
              <kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorAlignLeftButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorAlignCenterButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorAlignRightButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorAlignJustifyButton></kendo-toolbar-button>
              </kendo-toolbar-buttongroup>
              <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>
              <kendo-toolbar-dropdownlist kendoEditorFontSize></kendo-toolbar-dropdownlist>
              <kendo-toolbar-dropdownlist kendoEditorFontFamily></kendo-toolbar-dropdownlist>
              <kendo-toolbar-colorpicker kendoEditorForeColor></kendo-toolbar-colorpicker>
              <kendo-toolbar-colorpicker kendoEditorBackColor view="gradient"></kendo-toolbar-colorpicker>
              <kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorInsertUnorderedListButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorInsertOrderedListButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorIndentButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorOutdentButton></kendo-toolbar-button>
              </kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorBlockquoteButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorSelectAllButton></kendo-toolbar-button>
              <kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorUndoButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorRedoButton></kendo-toolbar-button>
              </kendo-toolbar-buttongroup>
              <kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorCreateLinkButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorUnlinkButton></kendo-toolbar-button>
              </kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorInsertFileButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorViewSourceButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorCleanFormattingButton></kendo-toolbar-button>
              <kendo-editor-insert-table-button></kendo-editor-insert-table-button>
              <kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorAddColumnBeforeButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorAddColumnAfterButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorAddRowBeforeButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorAddRowAfterButton></kendo-toolbar-button>
              </kendo-toolbar-buttongroup>
              <kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorDeleteColumnButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorDeleteRowButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorDeleteTableButton></kendo-toolbar-button>
              </kendo-toolbar-buttongroup>
              <kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorMergeCellsButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorSplitCellButton></kendo-toolbar-button>
              </kendo-toolbar-buttongroup>
              <!-- <kendo-toolbar-button kendoEditorPrintButton></kendo-toolbar-button> -->
              <!-- <kendo-toolbar-buttongroup> -->
                <!-- <kendo-toolbar-button kendoEditorInsertImageButton></kendo-toolbar-button> -->
                <!-- <kendo-toolbar-button title="Upload’s Image at cursor point" text="Upload Image"
                  (click)="commonkendoeditorimageuploaddialog()"></kendo-toolbar-button> -->
              <!-- </kendo-toolbar-buttongroup> -->
            </kendo-toolbar>
          </kendo-editor>
          <!-- <app-upload-image-dialog #commonkendoeditorupload [editor]="commonkendoeditor"></app-upload-image-dialog> -->
        </div>
      
      </div>
   </div>      

    </form>


<p-dialog header="Confirm" [(visible)]="discardDialog" [modal]="true" [style]="{ width: '25vw' }" [draggable]="false"
  [resizable]="false">
  <div class="row mt-2">
   
    <p *ngIf="isemailActivated && !unifiedTaskId"><b>Do you want to discard?</b></p>
    <p *ngIf="!isemailActivated && !unifiedTaskId"><b>Do you want to save your changes?</b></p>
    <p *ngIf="unifiedTaskId"><b>Do you want to discard your changes?</b></p>
  </div>

  <div class="divider mt-3 mb-3"></div>

  <div class="row d-flex">


    <div *ngIf="!isemailActivated && !unifiedTaskId">
      <button pButton pRipple type="button" label="Yes" class="confirmBoxBtnWid"  (click)="onSend('DRAFT')"></button>
      <button pButton pRipple type="button" label="No" class="p-button-outlined ml-3 p-button-secondary confirmBoxBtnWid"  (click)="discard()"></button>
      <button pButton pRipple type="button" label="Cancel" class="p-button-secondary ml-3 confirmBoxBtnWid" (click)="discardDialogBox()"></button>
    </div>

    <div *ngIf="isemailActivated && !unifiedTaskId">
      <button pButton pRipple type="button" label="Yes" class="p-button-outlined ml-3 p-button-secondary confirmBoxBtnWid"  (click)="discard()"></button>
      <button pButton pRipple type="button" label="Cancel" class="p-button-secondary ml-3 confirmBoxBtnWid" (click)="discardDialogBox()"></button>
    </div>

    <div *ngIf="unifiedTaskId">
      <button pButton pRipple type="button" label="Yes" class="p-button-outlined ml-3 p-button-secondary confirmBoxBtnWid"  (click)="discard()"></button>
      <button pButton pRipple type="button" label="Cancel" class="p-button-secondary ml-3 confirmBoxBtnWid" (click)="discardDialogBox()"></button>
    </div>

  </div>

</p-dialog>
</div>




<!-- Placeholder error -->

<p-dialog  [(visible)]="placeholderErrorDialog" [modal]="true"  [style]="{ width: '30vw', margin:'0px 0px 0px 0px' }" [draggable]="false"
  [resizable]="false">

  <p-header>
    Alert
  </p-header>

  <hr>

  <p-content  [style]="{'padding':'10px 25px 0px 25px'}">
  <div class="row mt-2">
    <div class="w-100"><p>Your email contains unpopulated placeholder. </p></div>
    <div class="clearfix"></div>

    <div class="w-100"><p>It is highlighted in Red.</p></div>
    <p><b>Please replace or remove before sending.</b></p>
  </div>

  </p-content>

  <hr>

  <p-footer>
    <button class="btn btn-secondary" (click)="placeholderErrorDialog = false">Ok</button>
  </p-footer>


</p-dialog>



<p-dialog *ngIf="!isSelectCategory && !emailData" 
[(visible)]="categoryDialog" [modal]="true"  [style]="{ width: '40vw' }" [draggable]="false"
(visibleChange)="handleCloseCategory()"
[resizable]="false">

  <p-header>
    Email Composition Reference
  </p-header>

  <hr>

  <p-content  [style]="{'padding':'10px 25px 0px 25px'}">
  <div class="row mt-2">
    <p>Please choose a refrence for this email to ensure proper tracking and context. Select from the option below.</p>
  </div>

  <div class="row d-flex">
    <div class="form-group">
      <label class="required">Select Category</label>
        <p-dropdown class="d-block w-300" [style]="{'position':'absolute', 'width':'320px'}" required 
          [options]="categories" [(ngModel)]="categoryPlaceholder" (onChange)="onChangeCategory($event)"
          optionLabel="name">
        </p-dropdown>
    </div>

    <div class="form-group mt-3" *ngIf="isbenActive" >
      <label class="required">{{currentUserRole == 'Attorney' ? 'Individual List' : 'Beneficiary List'}}</label>
        <p-dropdown class="d-block w-300" [style]="{'position':'absolute', 'width':'320px'}" required 
          [options]="beneficiaryListData" [(ngModel)]="benPlaceholder" (onChange)="onChangeBeneficiary($event)"
          optionLabel="beneficiaryEmployeeFName" optionValue="beneficiaryEmployeeId">
        </p-dropdown>
    </div>


    <div class="form-group mt-3" *ngIf="ispetActive && userRole == 'Petitioner'" >
      <label class="required">Petitioner List</label>

        <p-dropdown class="d-block w-300" [style]="{'position':'absolute', 'width':'320px'}" required 
          [options]="petetionerListData" [(ngModel)]="petPlaceholder" (onChange)="onChangeAttorney($event)"
          optionLabel="companyName" optionValue="id">
        </p-dropdown>
    </div>


    <div class="form-group mt-3" *ngIf="ispetActive && userRole == 'Attorney'" >
      <label class="required">Petitioner List</label>
        <p-dropdown class="d-block w-300" [style]="{'position':'absolute', 'width':'320px'}" required 
          [options]="petetionerListData" [(ngModel)]="petPlaceholder" (onChange)="onChangeAttorney($event)"
          optionLabel="petitionerName" optionValue="petitionerId">
        </p-dropdown>
    </div>
  </div>

  </p-content>

  <hr>

  <p-footer>
    <button class="btn btn-primary float-start" (click)="confirmCategorySelection()">Confirm Selection</button>
    <button class="btn btn-secondary" (click)="handleCloseCategory()">Cancel</button>
  </p-footer>


</p-dialog>






