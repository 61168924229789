import {
  Component,
  OnInit,
  ViewChild,
  Output,
  Input,
  EventEmitter,
  ElementRef
} from "@angular/core";
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { environment } from "../../../../environments/environment";
import { AuthenticationService } from "src/app/services/authentication.service";
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ConfirmationModalComponent } from "src/app/modules/shared/modal/confirmation-modal/confirmation-modal.component";
import { UploadDocumentService } from "src/app/services/upload-document.service";
import * as Roles from "../../../data/constants/roles";
import { PetitionerService } from "src/app/services/petitioner.service";
import { LCA } from "src/app/data/models/lca.model";
import { PageEvent } from '@angular/material/paginator';
import { debounceTime, map, filter, take } from 'rxjs/operators';
import { fromEvent,Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { Subject } from 'rxjs/internal/Subject';
import { ImagilityBaseResponse } from 'app-models';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: "app-lca",
  templateUrl: "./lca.component.html",
  styleUrls: ["./lca.component.scss"],
  animations: [
    trigger("detailExpand", [
      state("collapsed", style({ height: "0px", minHeight: "0" })),
      state("expanded", style({ height: "*" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      ),
    ]),
  ],
})
export class LcaComponent implements OnInit {
  lcaList: LCA[] = [];
  displayedColumns: string[] = [];
  dataSource: MatTableDataSource<any>;
  pageEvent: PageEvent;
  pageSizeOptions = [10,25,50,100];
  pageSize = 25; //default
  pageIndex = 0; //default
  searchText:string = '';
  searchSubscription: Subscription;
  totalRecordCount: number = 0;
  validityEndDate;
  validityStartDate;

  updateBenProfile: Boolean = true;
  educationDetails: any = [];
  beneficiaryId: any;
  expandedElement: any | null;
  envPath: any;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild("searchAccessListTable", { static: true }) searchAccessListTable: ElementRef;
  companyId: string;
  categoryName: any;
  apiUrl:any;
  entityId: number;
  fileCategory: string;
  selectedOption: string = '';
  selectedRowData: any;
  selectedElement:any;
  isFileCategory: boolean = false;

  option: any;
  i: number;
  selectedFile='';
  observableSubscription$ = new Subject();

  columns = [
    { columnDef: "toggle", header: "", columnType: "icon", customClass: "" },
    {
      columnDef: "beneficiaryFullname",
      header: "Name",
      columnType: "text",
      customClass: "",
    },
    {
      columnDef: "visaType",
      header: "Visa Type",
      columnType: "text",
      customClass: "",
    },
    {
      columnDef: "petitionType",
      header: "Petition Type",
      columnType: "text",
      customClass: "",
    },
    {
      columnDef: "companyName",
      header: "Company",
      columnType: "text",
      customClass: "",
    },
  ];

  docType = {
    documents: [
      { code: '1', name: 'I-129 Forms', fileCat:'I129' },
      { code: '2', name: 'Support Letters', fileCat:'SUPLTR' },
      { code: '3', name: 'LCA (Labor Condition Application)', fileCat:'LCA' },
      { code: '4', name: 'Passport Copies', fileCat:'PASSCOPY' },
      { code: '5', name: 'Approval Notices', fileCat:'APPROVAL' },
      { code: '6', name: 'Supporting Documents', fileCat:'OTHER' },
    ]
  };

  constructor(
    private authenticationService: AuthenticationService,
    private petitionerService: PetitionerService,
    public dialog: MatDialog,
    private toastr: ToastrService
  ) {
    this.categoryName = [
    'I129',
    'DOCTYP',
    'LCADOC',
    'BENPASSDOC',
    'BENIMMDOC',
    'BENOTHDOC'
  ];
}

  ngOnInit() {
    this.companyId = sessionStorage.getItem("companyId");
    this.searchSetUp();
    this.loadLcaCodes();
    this.fileCategory = '';
  }

  handleFileCategorySelection(): void {
    switch (this.fileCategory) {
      case 'I129':
        this.categoryName = 'I129';
        break;
      case 'SUPLTR':
        this.categoryName = 'DOCTYP';
        break;
        case 'LCA':
        this.categoryName = 'LCADOC';
        break;
        case 'PASSCOPY':
          this.categoryName = 'BENPASSDOC';
          break;
          case 'APPROVAL':
            this.categoryName = 'BENIMMDOC';
            break;
        case 'OTHER':
          this.categoryName = 'BENOTHDOC';
          break;
      default:
        // Default logic if no match is found
    }
    this.isFileCategory = this.fileCategory !== '';
  }

  searchSetUp() {
    this.searchSubscription = fromEvent(this.searchAccessListTable.nativeElement, 'keyup').pipe(
      map((event: any) => { return event.target.value; })
      , filter(res => res.length > 2)
      , debounceTime(1000)).subscribe((text: string) => {
        if (this.dataSource.paginator) {
          this.dataSource.paginator.firstPage();
        }
        this.searchText = text.trim();
        this.loadLcaCodes();
      });
  }

  ngAfterContentInit() {
    this.displayedColumns = this.columns.map((c) => c.columnDef);
  }

  loadLcaCodes() {
    let payload = {
      caseNumber: "",
      socCode: "",
      pageNumber: this.pageIndex+1,
      pageSize: this.pageSize,
      searchText: this.searchText,
      status: ["LCAUNASSI", "LCAASSIGN", "LCAWTHDR"],
    };
    this.petitionerService
      .getPrivateAccessFiles(this.companyId, payload)
      .subscribe((data) => {
        this.lcaList = data["privateAccessFilesResponseDtoList"];
        this.totalRecordCount = data["totalCount"]||0;
        this.dataSource = new MatTableDataSource(this.lcaList);
        this.dataSource.sort = this.sort;
      });
  }

  getRecord(row, element) {
    this.selectedRowData = row;
    this.selectedElement=element;

      if(element)
      {
      this.petitionerService.getLcaDetailsPrivateById(element.companyId, element.taskId).subscribe((data) => {
        debugger;
        row.documents = data;
      });
    }
    
  }
  displayDocument(item) {
    // console.log(item);
  //  window.open(`${item.fileLocation}`, "_blank");
  const docPath = item.substring(23);
  window.open(`${environment.docs}${docPath}`, "_blank");
  }

  paginationEvent(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.loadLcaCodes();
  }


  resetSearch(){
      this.searchText =''; //reset search
      this.pageIndex = 0;//reset search
      this.loadLcaCodes();
  }

  ngOnDestroy() {
    this.searchSubscription.unsubscribe();
  }

  uploadDocument(_data: any){
    debugger;
    let row : any;
    let files = _data[0];   
    this.selectedFile = files; 
    const formData = new FormData();
    formData.append('file', this.selectedFile);
    this.getApiURLbasedOnFileCategory();
    this.petitionerService.uploadPrivateDocument(formData, this.apiUrl)
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe((response: ImagilityBaseResponse) => {
        debugger;
        if (response.status == 200) {
          this.toastr.success(response.message, "File Uploaded Successfully");
          this.getRecord(this.selectedRowData,this.selectedElement);
        }
        else{
          this.toastr.warning(response.message);
        }
      })
  }

  getApiURLbasedOnFileCategory(): void {
    switch (this.fileCategory) {
      case 'I129':
        this.apiUrl = `/H1-B/PETITION/${this.companyId}/form/${this.fileCategory}/upload?superTaskId=17&taskId=140&familyId=`;
        break;
      case 'SUPLTR':
        this.apiUrl = `/H1-B/PETITION/${this.companyId}/letter/${this.fileCategory}/upload`;
        break;
      case 'LCA':
        this.apiUrl = `/document/company/${this.companyId}/category/${this.categoryName}/entity/${this.entityId}/fileCategory/${this.fileCategory}`;
        break;
      case 'PASSCOPY':
        this.apiUrl = `/document/beneficiary/${this.beneficiaryId}/category/${this.categoryName}/entity/${this.entityId}/fileCategory/${this.fileCategory}`;
        break;
      case 'APPROVAL':
        this.apiUrl = `/document/beneficiary/${this.selectedRowData.beneficiaryId}/category/${this.categoryName}/entity/${this.selectedRowData.id}/fileCategory/${this.fileCategory}`;
        break;
      case 'OTHER':
        this.apiUrl = `/H1-B/PETITION/${this.companyId}/document/category/${this.categoryName}/fileCategory/${this.fileCategory}?otherName=Passport%20copy`;
        break;
      default:
    }
  }

  deleteDoc(item: any) {
    debugger;
    this.petitionerService.deleteUploadDocument(this.companyId, item.fileCategory.group, item.fileCategory.code, item.id)
      .pipe(take(1))
      .subscribe((response: boolean) => {
        if (response) {
          debugger;
          this.getRecord(this.selectedRowData, 'updatedDocument')
        }
      });
  }
}
