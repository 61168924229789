import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { I9RemediationService } from '../../i9-remediation.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-send-report-mail',
  templateUrl: './send-report-mail.component.html',
  styleUrls: ['./send-report-mail.component.scss']
})
export class SendReportMailComponent implements OnInit {
  listOfEmails = '';
  constructor(
    public dialogRef: MatDialogRef<SendReportMailComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private i9RemediationService: I9RemediationService,
    private toastrService: ToastrService,
  ) { }

  ngOnInit(): void {
  }
  sendReportToEmail() {
    this.i9RemediationService.sendReportAsEmail(this.listOfEmails, this.data.reportPath).subscribe((data: any) => {
      this.toastrService.success(data.message);
    }, (error)=> {
      this.toastrService.error('Backend error');
    });

    this.dialogRef.close(true);
  }
  onDismiss() {
    this.dialogRef.close(false);
  }

}
