<div class="outer-container" [ngClass]="{'displayNone': this.redirectPrompt }">
  <div class="row">
    <div class="caption-felx-row">
      <div class=" p-2">
        <h4 class="caption common-bene-class-for-translation">{{ this.caption }}</h4>
      </div>
      <div class="close">
        <span *ngIf="!this.expiredCred" matTooltip="Close" class="material-icons cancel" (click)="onNoClick()">
          cancel
        </span>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12 col-sm-12  col-md-12">
      <form [formGroup]="changePasswordForm">
        <div class="change-password-overflow">
          <div class="form-group mt-4">
            <div class="vertical-box">
              <div>
                <label class="common-bene-class-for-translation">Current Password</label>
              </div>
              <div class="field-top-minus-margin">
                <mat-form-field class="passwordChange" appearance="outline">
                  <input type="password" matInput name="old_password" id="oldPassword" placeholder="Current Password"
                    [type]="current_password_hide ? 'text':'password'" #oldpwd formControlName="old_password" />
                  <mat-icon class="pt-2 mr-2" matSuffix (click)="current_password_hide  = !current_password_hide ">
                    {{current_password_hide ? 'visibility':'visibility_off'}}
                  </mat-icon>
                  <mat-error
                    *ngIf="changePasswordForm.controls['old_password'].hasError('required') && changePasswordForm.controls['old_password'].touched"
                    class="text-danger mt-3 common-bene-class-for-translation">Current Password Required</mat-error>
                </mat-form-field>
              </div>
            </div>
            <!---vertical-box--->
          </div>
          <div class="form-group mt-4">
            <div class="vertical-box">
              <div>
                <label class="common-bene-class-for-translation">New Password</label><small class="common-bene-class-for-translation">( 8 minimum characters )</small>
              </div>
              <div class="field-top-minus-margin">
                <mat-form-field class="passwordChange  mb-1" appearance="outline">
                  <input type="password" #newpwd matInput name="new_password" id="newPassword"
                    placeholder="New Password" [type]="new_password_hide?'text':'password'"
                    formControlName="new_password" (keyup)="setScore(newpwd.value)"
                    pattern="(?=.*\W)(?!.*\s)(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" />
                  <!---IM-3589  IM-3588 -->
                  <mat-icon class="pt-2 mr-2" matTooltip="Show/Hide" matSuffix
                    (click)="new_password_hide = !new_password_hide">
                    {{new_password_hide ?'visibility':'visibility_off'}}</mat-icon>
                  <mat-error *ngIf="oldpwd.value === newpwd.value" class="text-danger-fix mt-1 common-bene-class-for-translation">
                    Current Password and new Password should not be same
                  </mat-error>
                  <!---IM-3589  IM-3588 -->
                </mat-form-field>
                <div class="mt-1 password-strength flex-row" *ngIf="getStrengthMeterDetails('strength')">
                  <div class="password-strength-label">
                    <strong class="common-bene-class-for-translation">Password Strength :</strong>
                  </div>
                  <div class="password-strength-pbar-wrap">
                    <div class="pwd-bar"
                      [ngStyle]="{'background-color':getStrengthMeterDetails('color'),'width':getStrengthMeterDetails('width')}">
                    </div>
                  </div>
                  <div class="password-strength-value">
                    <strong>{{ getStrengthMeterDetails('strength')}}</strong>
                  </div>
                </div>
                <div class="newpassword-validity-status" *ngIf="changePasswordForm.controls['new_password'].dirty">
                  <ul>
                    <li class="flex-row">
                      <span class="material-icons valid-icon" *ngIf="validateInput('totalCount',newpwd)">
                        check_circle
                      </span>
                      <span class="material-icons not-valid-icon common-bene-class-for-translation" *ngIf="!validateInput('totalCount',newpwd)">
                        cancel
                      </span>
                      <span class="pl-1 common-bene-class-for-translation">
                        Must be 8 characters at minimum
                      </span>
                    </li>
                    <li class="flex-row">
                      <span class="material-icons valid-icon" *ngIf="validateInput('aA3',newpwd)">
                        check_circle
                      </span>
                      <span class="material-icons not-valid-icon common-bene-class-for-translation" *ngIf="!validateInput('aA3',newpwd)">
                        cancel
                      </span>
                      <span class="pl-1 common-bene-class-for-translation">
                        Must contain atleast 3 alpha (A-z) character(s)
                      </span>
                    </li>
                    <li class="flex-row">
                      <span class="material-icons valid-icon" *ngIf="validateInput('number',newpwd)">
                        check_circle
                      </span>
                      <span class="material-icons not-valid-icon common-bene-class-for-translation" *ngIf="!validateInput('number',newpwd)">
                        cancel
                      </span>
                      <span class="pl-1 common-bene-class-for-translation">
                        Must contain atleast 1 number(0-9) characters(s)
                      </span>
                    </li>
                    <li class="flex-row">
                      <span class="material-icons valid-icon" *ngIf="validateInput('specChar',newpwd)">
                        check_circle
                      </span>
                      <span class="material-icons not-valid-icon common-bene-class-for-translation" *ngIf="!validateInput('specChar',newpwd)">
                        cancel
                      </span>
                      <span class="pl-1 common-bene-class-for-translation">
                        Must contain atleast 1 special character(s)
                        <small>(~`!@#$%^&*()*-_+ {{ '{' }} {{ '}' }}[];:,.?<>/|\)</small>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <!---vertical-box--->
          </div>
          <div class="form-group mt-4">
            <div class="vertical-box">
              <div>
                <label class="common-bene-class-for-translation">Confirm Password</label><small class="common-bene-class-for-translation">( 8 minimum characters )</small>
              </div>
              <div class="field-top-minus-margin">
                <mat-form-field class="passwordChange" appearance="outline">
                  <input type="password" matInput name="confirm_new_password" id="cofirmNewPassword"
                    placeholder="Confirm New Password" [type]="confirm_new_password_hide ?'text': 'password'"
                    formControlName="confirm_new_password" />
                  <mat-icon class="pt-2 mr-2" matTooltip="Show/Hide" matSuffix
                    (click)="confirm_new_password_hide = !confirm_new_password_hide">
                    {{confirm_new_password_hide ? 'visibility':'visibility_off'}}</mat-icon>
                </mat-form-field>
                <ng-container *ngIf="changePasswordForm.controls['confirm_new_password'].touched">
                  <mat-error
                    *ngIf="changePasswordForm.hasError('notMatching')||changePasswordForm.controls['confirm_new_password'].hasError('required')"
                    class="text-danger-fix ml-2 mt-1">
                    <span class="common-bene-class-for-translation"
                      *ngIf="changePasswordForm.hasError('notMatching') && ! changePasswordForm.controls['confirm_new_password'].hasError('required')">
                      Confirm Password mismatched</span>
                    <span class="common-bene-class-for-translation" *ngIf="changePasswordForm.controls['confirm_new_password'].hasError('required')">Confirm
                      Password Required</span>
                  </mat-error>
                </ng-container>
              </div>
            </div>
            <!---vertical-box--->
          </div>
        </div>
        <div class="form-group text-left mt-4 mb-3">
          <button type="submit" (click)="changePassword()" class="im-btn im-btn-primary im-btn-md mb-4"
            [disabled]="!changePasswordForm.valid || (oldpwd.value === newpwd.value )">
            <!---IM-3589  IM-3588 -->
            <div class="btn-content"> <span class="material-icons">lock</span><span>&nbsp;</span>
              <span class="common-bene-class-for-translation"> CHANGE PASSWORD</span>
            </div>
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
<!----outer container --->
<div [ngClass]="{'displayNone': !this.redirectPrompt}" class="redirectDialogue">
  <div class="font-600 font-16 text-center pt-4 caption-color common-bene-class-for-translation">
    Your password is successfully updated
  </div>
  <div class="text-center pt-4">
    <button type="submit" (click)="handleLogout()" class="im-btn im-btn-primary im-btn-md text-center">
      <div class="btn-content btn-content-align">
        <span class="material-icons">check_circle_outline</span>
        <span>&nbsp;</span> <span class="common-bene-class-for-translation">Login</span>
      </div>
    </button>
  </div>
</div>
