import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ImagilityBaseResponse } from '../data/models/response.model';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class PetitionBuilderService {
  encriptedData: string;
  selectedPACategory: any;
  constructor(private http: HttpClient) {

  }

  getChecklistDocuments(petitionId) {
    return this.http.get('/petitionBuilder/documents/' + petitionId).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        }
      })
    );
  } 

  getReviews(petitionId) {
    return this.http.get('/petitionBuilder/' + petitionId + '/review/').pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        }
      })
    );
  }

  saveReviews(payLoad, petitionId) {
    return this.http.post('/petitionBuilder/' + petitionId + '/review/', payLoad).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }

  getDuties(positionId: number) {
    return this.http.get(`/petitionBuilder/jobduty/${positionId}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        }
      })
    );
  }

  saveDuties(companyid, selectedJobPosition, payload) {
    return this.http.post('/petitionBuilder/company/' + companyid + '/petition/' + payload.petitionId + '/position/' + payload.positionId + '/title/' + selectedJobPosition, payload).pipe(
      map((response: ImagilityBaseResponse) => response)
    );
  }

  saveCustomJobDuties(payload) {
    return this.http.post(`/petitionBuilder/jobduty`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }

  deleteDuties(jobDutyId, isForceDelete) {
    return this.http.delete(`/petitionBuilder/position/jobduty/${jobDutyId}?forceDelete=${isForceDelete}`).pipe(
      map((response: ImagilityBaseResponse) => {
        return response;
      })
    );
  }

  // IM-3941
  deleteAllDuties(companyid, petitionId, positionId, isForceDelete) {
    return this.http.delete(`/petitionBuilder/company/${companyid}/petition/${petitionId}/position/${positionId}/jobDuty?forceDelete=${isForceDelete}`).pipe(
      map((response: ImagilityBaseResponse) => {
        return response;
      })
    );
  }


  saveOccupationDetails(companyId: number, petitionId: number, payload) {
    return this.http.post('/petitionBuilder/speciality/occupationDetails/company/' + companyId + '/petition/' + petitionId, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }

  getLCACodes() {
    return this.http.get('/petitionBuilder/speciality/lcaCodes').pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        }
      })
    );
  }

  getLCADuties(lcaCodeId) {
    return this.http.get('/petitionBuilder/speciality/lcaDuties/lca/' + lcaCodeId).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        }
      })
    );
  }

  saveOccupationDetermination(companyId, petitionId, payload) {
    return this.http.post('/petitionBuilder/speciality/Determination/company/' + companyId + '/petition/' + petitionId, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }

  // IM-3067
  assignLCA(companyId, petitionId, payload) {
    return this.http.post('/petitionBuilder/speciality/Determination/company/' + companyId + '/petition/' + petitionId, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        }
      })
    );
  }

  getOccupationDetermination(companyId, petitionId) {
    return this.http.get('/petitionBuilder/speciality/Determination/company/' + companyId + '/petition/' + petitionId).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        }
      })
    );
  }

  getEmployeeWorkLocation(companyId, petitionId) {
    return this.http.get(`/petitionBuilder/employerrelationship/locationSupervisor/company/${companyId}/petition/${petitionId}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        }
      })
    );
  }

  saveEmployeeWorkLocation(payload, companyId, petitionId, actionType) {
    return this.http.post(`/petitionBuilder/employerrelationship/locationSupervisor/company/${companyId}/petition/${petitionId}?actionType=${actionType}`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }

  getOccupationDetails(companyId, petitionId) {
    return this.http.get('/petitionBuilder/speciality/occupationDetails/company/' + companyId + '/petition/' + petitionId).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        }
      })
    );
  }

  saveItinearyDetails(petitionId, payload) {
    return this.http.post(`/petitionBuilder/itineraryOfServices/petition/${petitionId}`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        return response;
      })
    );
  }

  getItinearyDetails(petitionId: number) {
    return this.http.get(`/petitionBuilder/itineraryOfServices/petition/${petitionId}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        }
      })
    );
  }

  getWorkDetails(petitionId: number) {
    return this.http.get(`/workDetails/petition/${petitionId}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        }
      })
    );
  }
  getBeneficiaryQualifyWorkExpSummary(petitionId: number) {
    const query = `/petitionBuilder/additionalInfo/getContent/petition/${petitionId}/infoType/WORKEXSUM`;
    return this.http.get(query).pipe(
      map((response: any) => {
        return response.status === 200 ? response.data : { data: ' ' };
      })
    );
  }

  setBeneficiaryQualifyWorkExpSummary(petitionId: number, summary: string) {
    const query = `/petitionBuilder/additionalInfo/saveOrUpdateContent/petition/${petitionId}`;
    const payload = {
      additionalText: summary,
      infoType: 'WORKEXSUM',
      isDel: 0
    };
    return this.http.post(query, payload).pipe(
      map((response: any) => {
        return response.status === 200 ? true : false;
      })
    );
  }

  getEducationsDetails(petitionId: number) {
    return this.http.get(`/educations/petition/${petitionId}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        }
      })
    );
  }
  getProject(companyId, petitionId) {
    // petitionBuilder/employerrelationsip/locationSupervisor/company/6/petition/25
    return this.http.get(`/petitionBuilder/employerrelationship/project/company/${companyId}/petition/${petitionId}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        }
      })
    );
  }

  savePositionDetails(payload, companyId, petitionId) {
    return this.http.post(`/petitionBuilder/job/positionDetails/company/${companyId}/petition/${petitionId}/`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }

  saveProject(companyId, petitionId, payload) {
    return this.http.post(`/petitionBuilder/employerrelationship/project/company/${companyId}/petition/${petitionId}`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }

  createPetition(companyId, beneficiaryId, petitionType, payload) {
    return this.http.post(`/petitionBuilder/company/${companyId}/beneficiary/${beneficiaryId}/petition/${petitionType}`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        }
      })
    );
  }

  getTechnicalSkilss() {
    return this.http.get(`/petitionBuilder/technicalSkills/getInfo`).pipe(
      map((response: ImagilityBaseResponse) => {
        return response.data;
      })
    );
  }

  uploadDocument(formData, docType, companyId, petitionId) {
    let api = '';
    if (docType.includes('OTHERTYPE-')) {
      api = `/petitionBuilder/employerrelationship/upload/company/${companyId}
        /petition/${petitionId}/filecategory/OTHER?otherName=${docType.split('-')[1]}`;
    }
    else {
      api = `/petitionBuilder/employerrelationship/upload/company/${companyId}/petition/${petitionId}/filecategory/${docType}`;
    }
    return this.http.post(api, formData).pipe(
      map((response: ImagilityBaseResponse) => {
        return response;
      })
    );
  }

  getEducationDocument(petitionId, categoryName) {
    return this.http.get(`/document/petitionBuilder/${petitionId}/category/${categoryName}`).pipe(
      map((response: ImagilityBaseResponse) => {
        return response.data;
      })
    );
  }

  deleteEducationDocument(petitionId, categoryName, fileCategory, fileId) {
    return this.http.delete(`/document/petitionBuilder/${petitionId}/category/${categoryName}/fileCategory/${fileCategory}/${fileId}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }

  getPositionDetails(companyId, petitionId) {
    return this.http.get(`/petitionBuilder/job/positionDetails/company/${companyId}/petition/${petitionId}/`).pipe(
      map((response: ImagilityBaseResponse) => {
        return response.data;
      })
    );
  }

  getDocuments(companyId, petitionId) {
    return this.http.get(`/petitionBuilder/petition/${petitionId}/employerrelationship/category/PETDOCTYP`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        }
      })
    );
  }

  getProjectDocuments(petitionId) {
    return this.http.get(`/petitionBuilder/petition/${petitionId}/employerrelationship/category/PETPROJDOC`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        }
      })
    );
  }

  deleteDocument(companyId, petitionId, docType, filename) {
    return this.http.delete(`/petitionBuilder/employerrelationship/upload/company/${companyId}/petition/${petitionId}/filecategory/${docType}/filename/${filename}/`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return true;
        }
      })
    );
  }
  generateSupportLetter(companyId, petitionId) {
    return this.http.get(`/petitionBuilder/employerrelationsip/supportletter/company/${companyId}/petition/${petitionId}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        }
      })
    );
  }

  saveEnclosedDoc(petitionId, infoType, payload) {
    return this.http.post(`/petitionBuilder/additionalInfo/saveOrUpdateContent/petition/${petitionId}`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }


  getLcaSocDetails(payload, isFullList) {
    const url = isFullList ? `/soc/search?full=true` : `/soc/search`;
    return this.http.post(url, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        return response;
      })
    );
  }

  getMajorGroupCode(positionTitle) {
    var posTitle = positionTitle == null ? 'NULL' : this.toEncrypt(positionTitle);
    return this.http.get(`/soc/majorGroup/position/title/${posTitle}`).pipe(
      map((response: ImagilityBaseResponse) => {
        return response.data;
      })
    );
  }

  getLCACodesByCompanyId(companyId, payload) {
    return this.http.post(`/dashboard/petitioner/lca/company/${companyId}/list/`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        return response.data;
      })
    );
  }

  getLCASocCodes(petitionId, socCode) {
    return this.http.get(`/petition/${petitionId}/soc/${socCode}/detail`).pipe(
      map((response: ImagilityBaseResponse) => {
        return response.data;
      })
    );
  }

  getLCACodesByPetitionId(petitionId, majorSocCode) {
    return this.http.get(`/petitionBuilder/speciality/petition/${petitionId}/soc/${majorSocCode}/lcaCodes`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        }
      })
    );
  }

  getEnclosedDoc(petitionId, infoType) {
    return this.http.get(`/petitionBuilder/additionalInfo/getContent/petition/${petitionId}/infoType/${infoType}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        }
      })
    );
  }

  getDocPreview(companyId, documentTemplateId, petitionId, isSample) {
    const payload = {
      companyId,
      isSample,
      petitionId,
      templateId: documentTemplateId
    };
    return this.http.post(`/company/${companyId}/documentTemplate/${documentTemplateId}/generate`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        }
      })
    );
  }
  saveDocument(companyId, petitionId, payload) {
    return this.http.post(`/company/${companyId}/petition/${petitionId}/document/save`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        }
      })
    );
  }

  getEducationInformation(petitionId) {
    return this.http.get(`/petitionBuilder/qualification/profile/education/petition/${petitionId}`).pipe(
      map((response: ImagilityBaseResponse) => {
        return response.data;
      })
    );
  }

  deleteEducationData(petitionId, educationId, courseId, forceDelete) {
    return this.http.delete(`/petitionBuilder/petition/${petitionId}/education/${educationId}/${courseId}?forceDelete=${forceDelete}`).pipe(
      map((response: ImagilityBaseResponse) => {
        return response;
      })
    );
  }

  getTrainingLicenseInformation(petitionId, type) {
    return this.http.get(`/petitionBuilder/qualification/profile/${petitionId}/professional/${type}`).pipe(
      map((response: ImagilityBaseResponse) => {
        return response.data;
      })
    );
  }

  deleteTrainingLicenseData(petitionId, professionalId, type) {
    return this.http.delete(`/petitionBuilder/qualification/profile/${petitionId}/professional/${professionalId}/${type}`).pipe(
      map((response: ImagilityBaseResponse) => {
        return response.data;
      })
    );
  }

  deleteDocumentsBeneficiaryQulification(petitionId, categoryName, fileCategory, documentId) {
    return this.http.delete(`document/petitionBuilder/${petitionId}/category/${categoryName}/fileCategory/${fileCategory}/${documentId}`).pipe(
      map((response: ImagilityBaseResponse) => {
        return response.data;
      })
    );
  }

  getExperienceInformation(petitionId) {
    return this.http.get(`/petitionBuilder/workexperience/petition/${petitionId}`).pipe(
      map((response: ImagilityBaseResponse) => {
        return response.data;
      })
    );
  }

  deleteExperienceData(petitionId, experienceId, isForceDelete) {
    return this.http.delete(`/petitionBuilder/petition/${petitionId}/experience/${experienceId}?forceDelete=${isForceDelete}`).pipe(
      map((response: ImagilityBaseResponse) => {
        return response;
      })
    );
  }

  deleteJobDutyWorkExp(petitionId, jobDutyId) {
    return this.http.delete(`/petitionBuilder/petition/${petitionId}/jobDuty/${jobDutyId}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }

  deleteToolsWorkExp(petitionId, toolsId) {
    return this.http.delete(`/petitionBuilder/petition/${petitionId}/tools/${toolsId}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }

  generateLetter(companyId, documentTemplateId, petitionId) {
    const payload = {
      companyId: companyId,
      isSample: false,
      petitionId: petitionId,
      templateId: documentTemplateId
    };
    return this.http.post(`/company/${companyId}/documentTemplate/${documentTemplateId}/generate/pdf`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        }
      })
    );
  }

  // Generate pdf for own template created by petitioner / attorney
  generatePDFForOwnTemplate(companyId, petitionId, letterType) {
    return this.http.post(`/company/${companyId}/petition/${petitionId}/letter/${letterType}/generate/pdf`, {}).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        }
      })
    );
  }

  getLatestLetterInfo(companyId, petitionId, type) {
    return this.http.get(`/company/${companyId}/petition/${petitionId}/document/${type}`).pipe(
      map((response: ImagilityBaseResponse) => {
        return response.data;
      })
    );
  }

  getlastPreparedDoc(companyId, petitionId, type) {
    return this.http.get(`/company/${companyId}/petition/${petitionId}/form/${type}`).pipe(
      map((response: ImagilityBaseResponse) => {
        return response.data;
      })
    );
  }

  getlastPreparedDocGC(taskId, caseId, formType) {
    return this.http.get(`/task/${taskId}/case/${caseId}/form/${formType}`).pipe(
      map((response: ImagilityBaseResponse) => {
        return response.data;
      })
    );
  }

  getEnotificationAndCCDetails(visaType: string, caseType, caseId: number) {
    return this.http.get(`/${visaType}/${caseType}/${caseId}/enotificationAndCCDetails`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200 || response.status === 204) {
          return response;
        }
      })
    )
  }

  preparedAndsendDoc(companyId, petitionId, type, payload) {
    return this.http.post(`/company/${companyId}/petition/${petitionId}/form/${type}`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        return response; // send  full data
      })
    );
  }

  /*
  getG28(companyId, petitionId) {
      return this.http.get(`/company/${companyId}/petition/${petitionId}/g28`).pipe(
          map((response: ImagilityBaseResponse) => {
              return response.data;
          })
      );
  }

  getI129(companyId, petitionId) {
      return this.http.get(`/company/${companyId}/petition/${petitionId}/i129`).pipe(
          map((response: ImagilityBaseResponse) => {
              return response.data;
          })
      );
  }

  */
  getMailAddresses(petitionId) {
    return this.http.get(`/petitionBuilder/serviceCenter/${petitionId}`).pipe(
      map((response: ImagilityBaseResponse) => {
        return response.data;
      })
    );
  }

  setMailAddress(petitionId, payload) {
    return this.http.post(`/petitionBuilder/serviceCenter/${petitionId}`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }

  getFeesStructure(petitionId) {
    return this.http.get(`/petitionBuilder/fees/${petitionId}`).pipe(
      map((response: any) => {
        return response.data;
      })
    );
  }

  getMasterFeesStructure(petitionId) {
    return this.http.get(`/petition/${petitionId}/getFee`).pipe(
      map((response: any) => {
        return response.data;
      })
    );
  }


  setFeesStructure(petitionId, payload) {
    return this.http.post(`/petitionBuilder/fees/${petitionId}`, payload).pipe(
      map((response: any) => {
        if (response.status === 200) {
          return true;
        }

      })
    );
  }


  getConsulates(petitionId, country) {
    let query = `/petitionBuilder/consulate/${petitionId}`;
    if (country) {
      query = `/petitionBuilder/consulate/${petitionId}/country/${country}`;
    }
    return this.http.get(query).pipe(
      map((response: any) => {
        return response.data;
      })
    );

  }

  setSelectedConsulateAddress(petitionId, payload) {
    return this.http.post(`/petitionBuilder/consulate/${petitionId}`, payload).pipe(
      map((response: any) => {
        if (response.status === 200) {
          return true;
        }
      })
    );
  }



  syncBeneficiaryProfile(companyId: number, petitionId: number, useCase: string) {
    return this.http.post(`/petitionBuilder/company/${companyId}/petition/${petitionId}/${useCase}/sync`, {}).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200 || response.status === 204) {
          return response;
        }
      })
    );
  }

  syncBeneficiarySection(companyId: number, petitionId: number, useCase: string, section: string) {
    return this.http.post(`/petitionBuilder/company/${companyId}/petition/${petitionId}/${useCase}/${section}/sync`, {}).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200 || response.status === 204) {
          return response;
        }
      })
    );
  }

  syncBeneficiaryIndividual(companyId: number, petitionId: number, useCase: string, id: number, section?: string,) {
    const url = section ? `/petitionBuilder/company/${companyId}/petition/${petitionId}/${useCase}/${section}/${id}/sync` : `/petitionBuilder/company/${companyId}/petition/${petitionId}/${useCase}/${id}/sync`;
    return this.http.post(url, {}).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200 || response.status === 204) {
          return response;
        }
      })
    );
  }


  getPositionDuties(companyId, posiitonTitle) {
    return this.http.get(`/petitionBuilder/company/${companyId}/position/${posiitonTitle}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        }
      })
    );
  }
  getPetionReviewerDetails(petiotinId) {
    return this.http.get(`/petitionBuilder/petition/${petiotinId}/reviewer`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        }
      })
    );
  }


  savePetionReviewerDetails(petiotinId, reviewerId) {
    return this.http.post(`/petitionBuilder/petition/${petiotinId}/reviewer/${reviewerId}`, {}).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }

  getPetitionDocs(petitionId) {
    return this.http.get(`/petitionBuilder/documents/${petitionId}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }
  generatePetition(petitionId, payload) {
    return this.http.post(`/petitionBuilder/documents/${petitionId}`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }

  generatePetitionDraft(petitionId, payload) {
    return this.http.post(`/petitionBuilder/documents/${petitionId}/draft`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }

  getJobRole(companyId, petitionId) {
    return this.http.get(`/petitionBuilder/petition/${petitionId}/company/${companyId}/jobRole`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        }
      })
    );
  }
  postJobRole(companyId, petitionId, jobRole) {
    const payloadObj = {
      jobRole
    };
    return this.http.post(`/petitionBuilder/petition/${petitionId}/company/${companyId}/jobRole/${jobRole}`, payloadObj).pipe(
      map((response: ImagilityBaseResponse) => response)
    );
  }
  saveReviewer(petitionId, actionCode, payload) {
    return this.http.post(`/petitionBuilder/${petitionId}/actionCode/${actionCode}`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }

  getReviewResponse(petitionId, payload) {
    return this.http.post(`/petitionBuilder/${petitionId}/steps`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }

  getNotificationDocListBeneQua(payload) {
    return this.http.post(`/notification/save`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }


  // IM-3067
  getLcaDetailsByLcaId(lcaId) {
    return this.http.get(`/dashboard/petitioner/lca/${lcaId}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        }
      })
    );
  }

  // IM-3067
  unassignLca(companyId, petitionId, lcaId) {
    return this.http.delete(`/petitionBuilder/speciality/Determination/company/${companyId}/petition/${petitionId}/lcaId/${lcaId}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }

  getSycFeesStructure(petitionId) {
    return this.http.get(`/petitionBuilder/refreshfees/${petitionId}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200 || response.status === 204) {
          return response;
        }
      })
    );
  }


  // GC - I-140 Services
  postProcessingInformation(payload, superTaskId, subTaskId) {
    return this.http.post(`/superTask/${superTaskId}/subTask/${subTaskId}/gc/i140Case/processingInfo`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }

  getProcessingInformation(superTaskId, subTaskId) {
    return this.http.get(`/superTask/${superTaskId}/subTask/${subTaskId}/gc/i140Case/processingInfo`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200 || response.status === 204) {
          return response.data;
        }
      })
    );
  }

  postJobDescription(payload, superTaskId, subTaskId, i140CaseId) {
    return this.http.post(`/superTask/${superTaskId}/subTask/${subTaskId}/gc/i140Case/${i140CaseId}/jobDescription`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        return response;
      })
    );
  }

  getJobDescription(superTaskId, subTaskId, i140CaseId) {
    return this.http.get(`/superTask/${superTaskId}/subTask/${subTaskId}/gc/i140Case/${i140CaseId}/jobDescription`).pipe(
      map((response: ImagilityBaseResponse) => {
        return response.data;
      })
    );
  }

  ongetSOCCode(soccode) {
    return this.http.get(`/soc/${soccode}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        } else {
          return null;
        }
      })
    );
  }

  deleteJobDescDuties(superTaskId, subTaskId, dutyId) {
    return this.http.delete(`/superTask/${superTaskId}/subTask/${subTaskId}/gc/i140Case/duty/${dutyId}`).pipe(
      map((response: ImagilityBaseResponse) => {
        return response;
      })
    );
  }

  getBeneProfileInformation(superTaskId, subTaskId) {
    return this.http.get(`/superTask/${superTaskId}/subTask/${subTaskId}/gc/i140Case/beneficiaryPersonalDetails`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response && (response.status === 200 || response.status === 204)) {
          return response;
        }
      })
    );
  }

  getBeneEducationInformation(superTaskId, subTaskId, isUsedForBenEdit: boolean=false) {
    return this.http.get(`/superTask/${superTaskId}/subTask/${subTaskId}/gc/i140Case/educationDetails?isUsedForBenEdit=${isUsedForBenEdit}`).pipe(
      map((response: ImagilityBaseResponse) => {
        return response.data;
      })
    );
  }

  syncProfileData(superTaskId, subTaskId) {
    return this.http.post(`/superTask/${superTaskId}/subTask/${subTaskId}/gc/i140Case/educationDetails/sync`, {}).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }

  syncAllBeneEducation(superTaskId, subTaskId) {
    return this.http.post(`/superTask/${superTaskId}/subTask/${subTaskId}/gc/i140Case/educationDetails/education/sync`, {}).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }

  syncBeneEducationId(superTaskId, subTaskId, educationId) {
    return this.http.post(`/superTask/${superTaskId}/subTask/${subTaskId}/gc/i140Case/educationDetails/${educationId}/sync`, {}).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }

  deleteEducationInformation(superTaskId, subTaskId, educationId) {
    return this.http.delete(`/superTask/${superTaskId}/subTask/${subTaskId}/gc/i140Case/education/${educationId}/delete`).pipe(
      map((response: ImagilityBaseResponse) => {
        return response;
      })
    );
  }

  deleteCourseDetails(superTaskId, subTaskId, educationId, courseId) {
    return this.http.delete(`/superTask/${superTaskId}/subTask/${subTaskId}/gc/i140Case/education/${educationId}/course/${courseId}/delete`).pipe(
      map((response: ImagilityBaseResponse) => {
        return response;
      })
    );
  }

  deleteEducationDocuments(superTaskId, subTaskId, educationId, documentId) {
    return this.http.delete(`/superTask/${superTaskId}/subTask/${subTaskId}/gc/i140Case/education/${educationId}/document/${documentId}/delete`).pipe(
      map((response: ImagilityBaseResponse) => {
        return response;
      })
    );
  }

  getLicensesInformation(superTaskId, subTaskId, isUsedForBenEdit: boolean=false) {
    return this.http.get(`/superTask/${superTaskId}/subTask/${subTaskId}/gc/i140Case/education/license?isUsedForBenEdit=${isUsedForBenEdit}`).pipe(
      map((response: ImagilityBaseResponse) => {
        return response.data;
      })
    );
  }

  syncAllLicenses(superTaskId, subTaskId) {
    return this.http.post(`/superTask/${superTaskId}/subTask/${subTaskId}/gc/i140Case/education/license/sync`, {}).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }

  syncLicenses(superTaskId, subTaskId, licenseId) {
    return this.http.post(`/superTask/${superTaskId}/subTask/${subTaskId}/gc/i140Case/education/license/${licenseId}/sync`, {}).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }



  deleteLicensesInformation(superTaskId, subTaskId, licenseId) {
    return this.http.delete(`/superTask/${superTaskId}/subTask/${subTaskId}/gc/i140Case/education/license/${licenseId}/delete`).pipe(
      map((response: ImagilityBaseResponse) => {
        return response;
      })
    );
  }

  deleteLicensesDocuments(superTaskId, subTaskId, licenseId, documentId) {
    return this.http.delete(`/superTask/${superTaskId}/subTask/${subTaskId}/gc/i140Case/education/license/${licenseId}/document/${documentId}/delete`).pipe(
      map((response: ImagilityBaseResponse) => {
        return response;
      })
    );
  }

  getTrainingInformation(superTaskId, subTaskId, isUsedForBenEdit: boolean=false) {
    return this.http.get(`/superTask/${superTaskId}/subTask/${subTaskId}/gc/i140Case/education/training?isUsedForBenEdit=${isUsedForBenEdit}`).pipe(
      map((response: ImagilityBaseResponse) => {
        return response.data;
      })
    );
  }

  syncAllTraining(superTaskId, subTaskId) {
    return this.http.post(`/superTask/${superTaskId}/subTask/${subTaskId}/gc/i140Case/education/training/sync`, {}).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }

  syncTraining(superTaskId, subTaskId, trainingId) {
    return this.http.post(`/superTask/${superTaskId}/subTask/${subTaskId}/gc/i140Case/education/training/${trainingId}/sync`, {}).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }

  deleteTrainingInformation(superTaskId, subTaskId, trainingId) {
    return this.http.delete(`/superTask/${superTaskId}/subTask/${subTaskId}/gc/i140Case/education/training/${trainingId}/delete`).pipe(
      map((response: ImagilityBaseResponse) => {
        return response;
      })
    );
  }

  deleteTrainingDocuments(superTaskId, subTaskId, trainingId, documentId) {
    return this.http.delete(`/superTask/${superTaskId}/subTask/${subTaskId}/gc/i140Case/education/training/${trainingId}/document/${documentId}/delete`).pipe(
      map((response: ImagilityBaseResponse) => {
        return response;
      })
    );
  }

  postBeneExperienceInformation(payload, superTaskId, subTaskId) {
    return this.http.post(`/superTask/${superTaskId}/subTask/${subTaskId}/gc/i140Case/workExperienceDetails`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }

  getBeneExperienceInformation(superTaskId, subTaskId, isUsedForBenEdit = null) {
    return this.http.get(`/superTask/${superTaskId}/subTask/${subTaskId}/gc/i140Case/workExperienceDetails${isUsedForBenEdit != null ? '?isUsedForBenEdit='+isUsedForBenEdit: ''}`).pipe(
      map((response: ImagilityBaseResponse) => {
        return response.data;
      })
    );
  }

  syncBeneWorkExperience(superTaskId, subTaskId, experienceId) {
    return this.http.post(`/superTask/${superTaskId}/subTask/${subTaskId}/gc/i140Case/workExperienceDetails/${experienceId}/sync`, {}).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    )
  };

  getDependentDetail(superTaskId, subTaskId, i140CaseId) {
    return this.http.get(`/superTask/${superTaskId}/subTask/${subTaskId}/gc/i140Case/${i140CaseId}/family`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response && (response.status === 200 || response.status === 204))
          return response;
      })
    );
  }

  syncDependentDetails(superTaskId, subTaskId, i140CaseId) {
    return this.http.post(`/superTask/${superTaskId}/subTask/${subTaskId}/gc/i140Case/${i140CaseId}/family/sync`, {}).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    )
  }

  syncBeneWorkExperienceAll(superTaskId, subTaskId) {
    return this.http.post(`/superTask/${superTaskId}/subTask/${subTaskId}/gc/i140Case/workExperienceDetails/sync`, {}).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    )
  }

  postDependentDetails(payload, superTaskId, subTaskId, i140CaseId) {
    return this.http.post(`/superTask/${superTaskId}/subTask/${subTaskId}/gc/i140Case/${i140CaseId}/family`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }

  deletBeneWorkExperience(superTaskId, subTaskId, experienceId) {
    return this.http.delete(`/superTask/${superTaskId}/subTask/${subTaskId}/gc/i140Case/workExperienceDetails/${experienceId}/delete`, {}).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    )
  }

  deletBeneWorkExperienceTools(superTaskId, subTaskId, toolId) {
    return this.http.delete(`/superTask/${superTaskId}/subTask/${subTaskId}/gc/i140Case/workExperienceDetails/tools/${toolId}`, {}).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    )
  }

  deleteDependentDetails(superTaskId, subTaskId, i140CaseId, familyId) {
    return this.http.delete(`/superTask/${superTaskId}/subTask/${subTaskId}/gc/i140Case/${i140CaseId}/family/${familyId}`).pipe(
      map((response: ImagilityBaseResponse) => {
        return response;
      })
    );
  }

  deletBeneWorkExperienceDuties(superTaskId, subTaskId, jobDutyId) {
    return this.http.delete(`/superTask/${superTaskId}/subTask/${subTaskId}/gc/i140Case/workExperienceDetails/jobDuty/${jobDutyId}`, {}).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }

  deleteBeneWorkExperienceDocuments(superTaskId, subTaskId, experienceId, documentId) {
    return this.http.delete(`/superTask/${superTaskId}/subTask/${subTaskId}/gc/i140Case/workExperienceDetails/${experienceId}/document/${documentId}/delete`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response && response.status === 200) {
          return response;
        }
      })
    )
  };

  getPetitionerDetails(companyId) {
    return this.http.get(`/company/${companyId}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response && (response.status === 200 || response.status === 204))
          return response;
      })
    );
  }

  saveUpdateSubTask(payload) {
    return this.http.post(`/greenCard/update/task`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }

  getFeeCalculation(taskId, caseId) {
    return this.http.get(`/task/${taskId}/case/${caseId}/fee`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );

  }

  postFeeCalculation(taskId, caseId, payload) {
    return this.http.post(`/task/${taskId}/case/${caseId}/fee`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }

  resetFeeCalculation(taskId, caseId) {
    return this.http.get(`/task/${taskId}/case/${caseId}/fee/reset`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }

  getServiceCenter(taskId, caseId) {
    return this.http.get(`/task/${taskId}/case/${caseId}/serviceCenter`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }else{
          return  [];
        }
      })
    );
  }

  postServiceCenter(taskId, caseId, serviceCenterId, serviceCenterNameId?: number) {
    return this.http.post(`/task/${taskId}/case/${caseId}/serviceCenter/${serviceCenterId}?serviceCenterNameId=${serviceCenterNameId}`, {}).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }

  postFormType(taskId, caseId, formType, payload) {
    return this.http.post(`/task/${taskId}/case/${caseId}/form/${formType}`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }

  getOrganizeDocuments(taskId: number, caseId: number) {
    return this.http.get(`/task/${taskId}/case/${caseId}/organizeDocuments`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }

  resetOrganizeDocuments(taskId: number, caseId: number) {
    return this.http.post(`/task/${taskId}/case/${caseId}/organizeDocuments/reset`, {}).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }

  postOrganizedDocument(taskId: number, caseId: number, payload: {}) {
    return this.http.post(`/task/${taskId}/case/${caseId}/organizeDocuments`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }


  uploadGreenCardPDF(taskId, caseId, formType, payload) {
    return this.http.post(`/task/${taskId}/case/${caseId}/form/${formType}/upload`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }

  deleteGreenCardPDF(documentId) {
    return this.http.delete(`/green/card/document/${documentId}`).pipe(
      map((response: ImagilityBaseResponse) => {
        return response;
      })
    );
  }

  toEncrypt(_data) {
    var pharase = 'c63a423527b24f65'
    var data = _data;
    var key  = CryptoJS.enc.Latin1.parse(pharase);
    var iv   = CryptoJS.enc.Latin1.parse(pharase);  
    var encrypted = CryptoJS.AES.encrypt(
      data,
      key,
      {iv:iv,mode:CryptoJS.mode.CBC,
        padding:CryptoJS.pad.ZeroPadding
    }).toString();

    var e64 = CryptoJS.enc.Base64.parse(encrypted);
    var eHex = e64.toString(CryptoJS.enc.Hex);

   return this.encriptedData = eHex
  }

  
  syncToProfile(caseType, caseId) {
    const encryptedText = this.toEncrypt('education');
    return this.http.post(`/gcCase/updateProfile/${caseType}/${caseId}/${encryptedText}`, {});
  }

  mergeEducation(superTaskId, subTaskId) {
    return this.http.post(`/superTask/${superTaskId}/subTask/${subTaskId}/gc/i140Case/educationDetails/education/sync?syncTypeGC=Merge`, {});
  }

  mergeEducationPerm(caseId) {
    return this.http.post(`/gc/perm/${caseId}/educationDetails/education/sync?syncTypeGC=Merge`, {});
  }

  resetEducation(superTaskId, subTaskId) {
    return this.http.post(`/superTask/${superTaskId}/subTask/${subTaskId}/gc/i140Case/educationDetails/education/sync?syncTypeGC=Reset`, {});
  }

  resetEducationPerm(caseId) {
    return this.http.post(`/gc/perm/${caseId}/educationDetails/education/sync?syncTypeGC=Reset`, {});
  }

  mergeTraining(superTaskId, subTaskId, caseType) {
    let _caseType = caseType == 'I140' ? 'i140Case': "Perm";
    return this.http.post(`/superTask/${superTaskId}/subTask/${subTaskId}/gc/${_caseType}/education/training/sync?syncTypeGC=Merge`, {});
  }

  mergeTrainingPerm(caseId) {
    return this.http.post(`/gc/perm/${caseId}/educationDetails/education/training/sync?syncTypeGC=Merge`, {});
  }

  resetTraining(superTaskId, subTaskId, caseType) {
    let _caseType = caseType == 'I140' ? 'i140Case': "Perm";
    return this.http.post(`/superTask/${superTaskId}/subTask/${subTaskId}/gc/${_caseType}/education/training/sync?syncTypeGC=Reset`, {});
  }

  resetTrainingPerm(caseId) {
    return this.http.post(`/gc/perm/${caseId}/educationDetails/education/training/sync?syncTypeGC=Reset`, {});
  }

  mergeLicense(superTaskId, subTaskId, caseType) {
    let _caseType = caseType == 'I140' ? 'i140Case': "Perm";
    return this.http.post(`/superTask/${superTaskId}/subTask/${subTaskId}/gc/${_caseType}/education/license/sync?syncTypeGC=Merge`, {});
  }

  mergeLicensePerm(caseId) {
    return this.http.post(`/gc/perm/${caseId}/educationDetails/education/license/sync?syncTypeGC=Merge`, {});
  }

  resetLicense(superTaskId, subTaskId, caseType) {
    let _caseType = caseType == 'I140' ? 'i140Case': "Perm";
    return this.http.post(`/superTask/${superTaskId}/subTask/${subTaskId}/gc/${_caseType}/education/license/sync?syncTypeGC=Reset`, {});
  }

  resetLicensePerm(caseId) {
    return this.http.post(`/gc/perm/${caseId}/educationDetails/education/license/sync?syncTypeGC=Reset`, {});
  }

  syncI140(caseId) {
    const encriptedData = this.toEncrypt('experience');
    return this.http.post(`/gcCase/updateProfile/I140/${caseId}/${encriptedData}`, {});
  }
  mergeI140(superTaskId: number, subTaskId: number|string) {
    return this.http.post(`/superTask/${superTaskId}/subTask/${subTaskId}/gc/i140Case/workExperienceDetails/sync?syncTypeGC=Merge`, {});
  }
  resetI140(superTaskId: number, subTaskId: number|string) {
    return this.http.post(`/superTask/${superTaskId}/subTask/${subTaskId}/gc/i140Case/workExperienceDetails/sync?syncTypeGC=Reset`, {});
  }

  getServiceCentersImmigrant(isPremium) {
    return this.http.get(`/I140/serviceCenter/${isPremium}`).pipe(
      map((response: ImagilityBaseResponse) => response.data)
    );
  }

  getAddressFromCenter(visatype: string, caseType: string, petitionId: number, taskTypeId: number, serviceCenterNameId: number){
    return this.http.get(`/${visatype}/${caseType}/${petitionId}/${taskTypeId}/${serviceCenterNameId}/serviceCenters`, {}).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }

  getImmiServiceCenters(isPremium,taskTypeId) {
      return this.http.get(`/taskType/${taskTypeId}/serviceCenter/${isPremium}`).pipe(
        map((response: ImagilityBaseResponse) => response.data)
      );
  }

}
