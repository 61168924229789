import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { PrimaryData, TaskStep, labelNotNeededArray } from 'app-models';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable, Subject } from 'rxjs';
import { first, map, take, takeUntil } from 'rxjs/operators';
import { getStepDetails, loadSubTaskDetails, updateStepStatus, updateStepStatusToInitialState, getFormCreationType, SetDataSourceCountryList, getDataSourceCountryList } from 'visa-store';
import { CustomStepsService } from './custom-steps.service';
import { DirtyFormGuard, MarkCleanFormAction, MarkDirtyFormAction } from 'dirty-check-store';
import { ChangeDetectorRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { StepStatusUpdatePayloadService } from 'step-status-update-payload-service';
import { ReferenceLibraryService } from 'reference-library';
import { AppServicesLibraryService } from 'app-services-library';
import moment from 'moment';

@Component({
  selector: 'app-custom-steps-form',
  templateUrl: './custom-steps-form.component.html',
  styleUrls: ['./custom-steps-form.component.scss']

})
export class CustomStepsFormComponent implements OnInit, AfterViewInit, OnDestroy {

  inputData: PrimaryData;
  stepFormData = [];
  stepsForm: FormGroup = new FormGroup({});
  stepsFormArray = new FormArray([]);
  stepDetails: TaskStep;
  typeOfButton: string;
  isBeneficiaryNotify: boolean;
  responseCode: string;
  receiptNumber: string;
  responseNote: string;
  observableSubscription$ = new Subject();
  guardSubscription: any;
  fileUploadConfigurations: any;
  external = false;
  public stepsFormData$: Observable<any>;
  disabledControls: boolean;

  @ViewChild('child') child: ElementRef;
  flag = false;
  sta: boolean;
  applicantsList = [];
  inputJson;
  contHeight;
  activeIndex = 0;
  prePDFToWorkflow: boolean;
  labelNotNeededArray = ["CustomCalenderComponent", "AddressDetailsComponent", "VdSwitchGroupComponent", "CustomQuestionsGroupComponent", "VdLabelComponent", "VdDividerComponent", "VdTextAreaComponent", "CustomGridEditor", "CustomTextFieldComponent", "VdEadLibraryComponent", "VdAddGenerateFormComponent", "DOC_UPLOAD", "PERSON_DTL", "CommonEditorComponent", "VdCountryStateComponent", "CustomButtonComponent", "EfilingVersionStatusComponent"];
  beneQuestIsCaseRequest: string;
  // labelNotNeededArray: string[];
  isFromBenQuestionairre: boolean = false;
  isShowSyncWithProfile: boolean = true;
  stepsToShowSyncButton: any[] = ["About Petitioner", "About Beneficiary", "Employment", "H Classification Supplement"];
  showPrev = false;
  showNext = false;
  loadedChildren = 0;

  constructor(
    public dynamicDialogConfig: DynamicDialogConfig,
    public dynamicDialogRef: DynamicDialogRef,
    public customStepsService: CustomStepsService,
    private guard: DirtyFormGuard,
    private store: Store<any>,
    private cRef: ChangeDetectorRef,
    private toastr: ToastrService,
    private stepStatusUpdatePayloadCostruction: StepStatusUpdatePayloadService,
    private referenceLibraryService: ReferenceLibraryService,
    private appServicesLibraryService: AppServicesLibraryService
  ) {
    this.labelNotNeededArray = labelNotNeededArray;
    this.inputData = this.dynamicDialogConfig.data;
    // console.log("CustomStepsFormComponent this.dynamicDialogConfig.data ::", this.dynamicDialogConfig.data);
    // this.prePDFToWorkflow = this.inputData.immigrationClassification === 'WVRINADMI601';
    this.disabledControls = false;

    this.store.pipe(select(getFormCreationType))
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe((data: string) => {
        this.prePDFToWorkflow = data === null ? true : false;
      });
  }

  ngOnInit(): void {
    this.isFromBenQuestionairre = !!(sessionStorage.getItem('isFromBenQuestionairre'));
    this.inputJson = this.inputData && this.inputData.jsonInput ? this.inputData.jsonInput : null;
    this.loadStepForm();
    this.stepsFormData$ = this.customStepsService.customStepsObservable;
    this.showNext = this.inputData?.showNext;
    this.showPrev = this.inputData?.showPrev;
    this.store.pipe(select(getStepDetails, { id: this.dynamicDialogConfig.data.stepId }))
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe((data: TaskStep) => {
        this.typeOfButton = data.isPetitionerAction === 1 || data.isAttorneyAction === 1 || data.isBeneficiaryAction === 1 ? 'MARK_COMPLETE' : 'MARK_SUBMIT';
        this.stepDetails = data;
        this.inputData.stepStatusCode = this.stepDetails.stepStatusDetails.stepStatusCode;
        this.isBeneficiaryNotify = this.stepDetails.isBeneficiaryNotify === 1 ? true : false;
        this.responseCode =
          this.stepDetails.stepResponseDetails &&
            this.stepDetails.stepResponseDetails.stepResponseCode ?
            this.stepDetails.stepResponseDetails.stepResponseCode : null;
        this.responseNote = this.stepDetails.stepResponseNote ? this.stepDetails.stepResponseNote : null;
        this.onStatusToggle(this.stepDetails.stepStatusDetails.stepStatusCode);
        this.beneQuestIsCaseRequest = 'beneQuestIsCaseRequest' in sessionStorage ? sessionStorage.getItem('beneQuestIsCaseRequest') : 'false';
        this.disabledControls = this.appServicesLibraryService.formToBeDisabled(this.inputData.stepStatusCode);
        // if(this.inputData['isCaseRequest']){
        // this.disabledControls = false;
        // } else {
        // this.disabledControls = (this.inputData.stepStatusCode === 'COMPLETE' || this.inputData.stepStatusCode === 'NEW') ? true : false;
        // }
        this.stepsForm.markAsPristine();
        if (this.inputData.stepStatusCode === 'COMPLETE') {
          this.store.dispatch(new MarkCleanFormAction({
            dirty: false
          }));
        }
        this.isShowSyncWithProfile = (this.stepsToShowSyncButton.includes(this.stepDetails?.stepName));
      });


    this.stepsForm.valueChanges.pipe(
      map(() => this.stepsForm.dirty),
    ).pipe(takeUntil(this.observableSubscription$)).subscribe(dirty => {
      if (dirty) {
        this.store.dispatch(new MarkDirtyFormAction({
          dirty: true
        }));
      } else {
        this.store.dispatch(new MarkCleanFormAction({
          dirty: false
        }));
      }
    });
  }

  translet() {
    setTimeout(() => {
      this.referenceLibraryService.transletConversion();
    }, 600);
  }

  loadStepForm(patchValues: boolean = false) {
    this.customStepsService.getStepDetails(this.inputData.visatype, this.inputData.petitionId, this.inputData.stepId)
      .subscribe(response => {
        if (response.data) {
          const stepFormDataResponse = (response.data.formElement) ? JSON.parse(JSON.stringify(response.data.formElement)) : JSON.parse(response.data.jsonInput);
          if (this.applicantsList.length === 0 && this.prePDFToWorkflow === false && Array.isArray(stepFormDataResponse) && stepFormDataResponse?.length > 0) {
            stepFormDataResponse.forEach(element => {
              if (!element.layoutCode && !element.emptyCol) {
                element.emptyCol = true;
              }
              if (element.numberOfColumn && !isNaN(element.numberOfColumn)) {
                element.numberOfColumn = Number(element.numberOfColumn);
              }
              if (element.numberOfRow && !isNaN(element.numberOfRow)) {
                element.numberOfRow = Number(element.numberOfRow);
              }
            });
          }
          this.stepFormData = JSON.parse(JSON.stringify(stepFormDataResponse));
          if (this.prePDFToWorkflow && this.stepFormData && Array.isArray(this.stepFormData) && this.stepFormData.length > 0) {
            this.stepFormData.forEach(data => {
              data.customMinWidth = data.style && data.style.width &&
                data.style.width.trim().endsWith('px') &&
                Number(data.style.width.trim().replace('px', '')) < 226 ? 'fit-content' : '226px';
            });
          }
          if (Array.isArray(this.stepFormData) && this.stepFormData.length) {
            if (this.stepFormData.filter(x => ['VdCountryStateComponent', 'VdAddressComponent'].includes(x.controlName)).length > 0) {
              this.store.pipe(select(getDataSourceCountryList))
                .pipe(take(1))
                .subscribe((data: any[]) => {
                  if (!data || (data && data.length === 0)) {
                    this.referenceLibraryService.getDataSource('COUNTRY', '')
                      .pipe(take(1))
                      .subscribe((response: any[]) => {
                        this.store.dispatch(new SetDataSourceCountryList(response || null));
                        this.postLoadStepForm();
                      });
                  } else {
                    this.postLoadStepForm();
                  }
                });
            } else {
              // this.loadApplicantsForm();
              // this.cRef.detectChanges();
              this.postLoadStepForm();
            }
          } else {
            // this.loadApplicantsForm();
            // this.cRef.detectChanges();
            this.postLoadStepForm();
          }

          if (patchValues === true) {
            const keys = Object.keys(this.stepsForm.value);
            keys.forEach((key) => {
              const index = this.stepFormData.findIndex(x => x.name === key);
              if (this.stepsForm.controls[key] && index > -1) {
                this.stepsForm.controls[key].patchValue(this.stepFormData[index].value);
              }
            });
          }
        }
      });
  }

  postLoadStepForm() {
    const obj: any[] = Array.isArray(this.stepFormData) ? this.stepFormData.filter(item => item.layoutCode === 'VdCustomFileUploadComponent') : [];
    obj.forEach(x => {
      const uniqueName = x.name;
      const documentTypeDropdownCode =
        (x && x.properties && x.properties.refercenceCodeForApiCall != '') ?
          x.properties.refercenceCodeForApiCall : null;
      if (documentTypeDropdownCode) {
        this.setFileUploadConfigurationValue(documentTypeDropdownCode, uniqueName);
      }
    });

    if (Array.isArray(this.stepFormData)) {
      let tempArr = this.stepFormData.map(el => { return el.coordinateY = el.coordinateY ? el.coordinateY : 0 });
      let highestYcordinate = Math.max(...tempArr)
      this.contHeight = highestYcordinate + 400
    }
    else {
      for (let key in (this.stepFormData as any)) {
        if (Array.isArray(this.stepFormData[key])) {
          let tempArr = (this.stepFormData[key] as any).map(el => { return el.coordinateY = el.coordinateY ? el.coordinateY : 0 });
          let highestYcordinate = Math.max(...tempArr)
          this.contHeight = highestYcordinate + 400
        }
      }
    }
    this.loadApplicantsForm();
    this.cRef.detectChanges();
  }

  loadApplicantsForm() {
    this.applicantsList = [];
    if (this.inputJson?.applicantsList?.length > 0) {
      this.inputJson.applicantsList.forEach(applicant => {
        if (this.prePDFToWorkflow && applicant.stepForm && applicant.stepForm.length > 0) {
          (applicant.stepForm as any[]).forEach(data => {
            data.customMinWidth = data.style && data.style.width &&
              data.style.width.trim().endsWith('px') &&
              Number(data.style.width.trim().replace('px', '')) < 226 ? 'fit-content' : '226px';
          });
        }
        if (this.stepDetails.stepApplTo === 'SECAPPLCNT') {
          if (applicant.isPrimaryApplicant === false) {
            this.applicantsList.push(applicant);
          }
        } else if (this.stepDetails.stepApplTo === 'PRIMAPPLCNT') {
          if (applicant.isPrimaryApplicant === true) {
            this.applicantsList.push(applicant);
          } else if (this.inputData.immigrationClassification === 'AFFDVTSUPT864' && this.inputJson.primaryApplicant && this.applicantsList.length === 0) {
            this.applicantsList.push(this.inputJson.primaryApplicant);
          }
        } else if (this.stepDetails.stepApplTo === 'SPOUSE') {
          if (applicant.isPrimaryApplicant === false && applicant.relationshipCode === 'SPOUSE') {
            this.applicantsList.push(applicant);
          }
        } else if (this.stepDetails.stepApplTo === 'CHILD') {
          if (applicant.isPrimaryApplicant === false && applicant.relationshipCode === 'CHILD') {
            this.applicantsList.push(applicant);
          }
        }
      });
      this.mapApplicantsToForm();
    } else if (this.stepDetails.stepApplTo === 'PRIMAPPLCNT' && this.inputData.immigrationClassification === 'AFFDVTSUPT864' && this.inputJson.primaryApplicant) {
      this.applicantsList.push(this.inputJson.primaryApplicant);
      this.mapApplicantsToForm();
    } else {
      this.applicantsList = [];
      this.checkFLTranslation();
    }
  }

  mapApplicantsToForm() {

    this.stepsFormArray = new FormArray([]);
    //let formData = this.stepFormData;

    this.applicantsList.forEach((applicant, i) => {
      this.stepsFormArray.push(new FormGroup({
        familyId: new FormControl(applicant.id)
      }));
      applicant.formGroup = new FormGroup({
        familyId: new FormControl(applicant.id)
      });
      applicant[`family_${applicant.id}`] = new FormGroup({
        familyId: new FormControl(applicant.id)
      });
      applicant.formGroup.valueChanges.pipe(
        map(() => applicant.formGroup.dirty),
      ).pipe(takeUntil(this.observableSubscription$)).subscribe(dirty => {
        if (dirty) {
          this.store.dispatch(new MarkDirtyFormAction({
            dirty: true
          }));
        } else {
          this.store.dispatch(new MarkCleanFormAction({
            dirty: false
          }));
        }
      });
      //applicant[`stepForm_${applicant.id}`] = [];
      applicant.stepForm = [];
      //applicant[`stepForm_${applicant.id}`] = [];
      // debugger;
      //  console.log(Array.isArray(this.stepFormData),typeof(this.stepFormData),Object.keys(this.stepFormData).length)
      // console.log(str.toLowerCase().includes(substr));
      //  if(Object.keys(this.stepFormData).length === this.applicantsList.length){
      //   if (this.stepFormData[`family_${applicant.id}`]) {
      //       this.stepFormData[`family_${applicant.id}`].forEach(elm => {
      //         applicant.stepForm.push(elm);
      //       })
      //   }
      //  }else{
      //   Object.keys(this.stepFormData).forEach((applicantForm, i) => {
      //      if(applicantForm.toLowerCase().includes(applicant.id)){
      //       if (this.stepFormData[`family_${applicant.id}`]) {
      //         this.stepFormData[`family_${applicant.id}`].forEach(elm => {
      //           applicant.stepForm.push(elm);
      //         })
      //     }else{
      //       this.stepFormData?.forEach(frmElm => {
      //             let tmpObj = Object.assign({}, frmElm);
      //             applicant.stepForm.push(tmpObj);
      //             tmpObj = null;
      //           })
      //     }
      //      }
      //   })
      //  }


      if (this.stepFormData[`family_${applicant.id}`]) {
        this.stepFormData[`family_${applicant.id}`].forEach(elm => {
          applicant.stepForm.push(elm);
        })

      } else {
        this.stepFormData?.forEach(frmElm => {
          let tmpObj = Object.assign({}, frmElm);
          applicant.stepForm.push(tmpObj);
          tmpObj = null;
        })
      }
      const obj: any[] = Array.isArray(applicant.stepForm) ? applicant.stepForm.filter(item => item.layoutCode === 'VdCustomFileUploadComponent') : [];
      obj.forEach(x => {
        const uniqueName = x.name;
        const documentTypeDropdownCode =
          (x && x.properties && x.properties.refercenceCodeForApiCall != '') ?
            x.properties.refercenceCodeForApiCall : null;
        if (documentTypeDropdownCode) {
          this.setFileUploadConfigurationValue(documentTypeDropdownCode, uniqueName, applicant.id);
        }
      });
    });
    //   const obj = applicant.stepForm.find(item => item.layoutCode === 'VdCustomFileUploadComponent');
    //   const documentTypeDropdownCode = (obj && obj.properties && obj.properties.refercenceCodeForApiCall != '') ? obj.properties.refercenceCodeForApiCall : null;
    //   if (documentTypeDropdownCode) {
    //     this.setFileUploadConfigurationValue(documentTypeDropdownCode, applicant.id);
    //   }
    // });
    this.checkFLTranslation();
  }

  checkFLTranslation() {
    this.loadedChildren = 0;
    let count = 0;
    const controlsImplementedFL = ['VdSwitchGroupComponent', 'CustomGridEditor','CustomDropdownComponent','VdAddGenerateFormComponent','CustomTextFieldComponent','VdLabelComponent', 'CustomButtonComponent', 'CustomButtonActionComponent', 'CustomCalenderComponent', 'CustomCheckboxComponent', 'CustomChipComponent', 'CustomDateTimePickerComponent', 'CustomNumberFieldComponent', 'CustomRadioButtonComponent', 'VdCountComponent', 'VdTextAreaComponent','VdEadLibraryComponent','VdCustomFileUploadComponent', 'VdAddressComponent', 'VdPhoneComponent', 'VdCountryStateComponent', 'CustomReadonlyLabelValueLibraryComponent', 'CustomQuestionsGroupComponent', 'CustomErrorToastComponent','EfilingVersionStatusComponent'];
    if (this.applicantsList.length) {
      count = this.applicantsList.reduce((total, step) => {
        return total + step.stepForm.filter(item => controlsImplementedFL.includes(item.layoutCode)).length;
      }, 0);
    } else {
      count = this.stepFormData.filter(x => controlsImplementedFL.includes(x.layoutCode)).length;
    }
    this.appServicesLibraryService.childLoaded$.subscribe(() => {
      this.loadedChildren += 1;
      console.log(this.loadedChildren, count);
      if (this.loadedChildren === count) {
        this.translet();
      }
    });
  }

  setFileUploadConfigurationValue(documentTypeDropdownCode, uniqueName, familyId?) {
    const fileUploadConfigurations = Object.assign({}, {
      disableControls: this.disabledControls,
      isDocumentTypeRequired: true,
      isDocumentUploadRequired: true,
      isViewEnabled: true,
      isDownloadEnabled: true,
      isDeleteEnabled: true,
      // getDocTypeApiUrl: this.inputData.caseType === 'I485' ? `/${this.dynamicDialogConfig.data.caseType}/task/${this.dynamicDialogConfig.data.subTaskId}/group/${documentTypeDropdownCode}/documentTypes` :
      // `/${this.dynamicDialogConfig.data.visatype}/petitionType/${this.dynamicDialogConfig.data.petitionType}/group/${documentTypeDropdownCode}/documentTypes`,
      getDocTypeApiUrl: `/${this.dynamicDialogConfig.data.caseType}/task/${this.dynamicDialogConfig.data.subTaskId}/group/${documentTypeDropdownCode}/documentTypes`,
      getApiUrl: `/${this.dynamicDialogConfig.data.visatype}/${this.dynamicDialogConfig.data.caseType}/${this.dynamicDialogConfig.data.caseId}/document/category/${documentTypeDropdownCode}/`,
      postApiUrl: `/${this.dynamicDialogConfig.data.visatype}/${this.dynamicDialogConfig.data.caseType}/${this.dynamicDialogConfig.data.caseId}/document/category/${documentTypeDropdownCode}/fileCategory/`,
      deleteApiUrl: `/${this.dynamicDialogConfig.data.visatype}/${this.dynamicDialogConfig.data.caseType}/${this.dynamicDialogConfig.data.caseId}/document/`,
      documentTypeDropdownCode: documentTypeDropdownCode,
      familyId: familyId ? familyId : null
    });
    if (!this.fileUploadConfigurations) {
      this.fileUploadConfigurations = {};
    }
    this.fileUploadConfigurations[uniqueName] = fileUploadConfigurations;
  }

  postStepsForm() {
    const keys = Object.keys(this.stepsForm.value);
    keys.forEach(key => {
      const stepFormDataIndex =
        this.stepFormData.findIndex(x =>
          ['VdEadLibraryComponent', 'VdCountryStateComponent'].includes(x.layoutCode) ?
            x.groupName === key : x.name === key);
      if (stepFormDataIndex >= 0) {
        if (this.stepFormData[stepFormDataIndex] && this.stepFormData[stepFormDataIndex].layoutCode === 'CustomGridEditor') {
          this.stepFormData[stepFormDataIndex].gridValues = this.stepsForm.value[key].gridValues;
        }
        else if (this.stepFormData[stepFormDataIndex] && this.stepFormData[stepFormDataIndex].layoutCode === 'VdSwitchGroupComponent') {
          const switchGridProps = this.stepFormData[stepFormDataIndex]?.groupElementProperty;
          let additionalInfo = '';
          if (switchGridProps?.whenTextAreaShown === 'off') {
            additionalInfo =
              (this.stepsForm.value[key].switch_field === true) ?
                '' : this.stepsForm.value[key].additional_info_for_switch_field;
          } else if (switchGridProps?.whenTextAreaShown === 'on') {
            additionalInfo =
              (this.stepsForm.value[key].switch_field === false) ?
                '' : this.stepsForm.value[key].additional_info_for_switch_field;
          } else {
            additionalInfo = this.stepsForm.value[key].additional_info_for_switch_field;
          }

          this.stepFormData[stepFormDataIndex].value = {
            switch_field: this.stepsForm.value[key].switch_field,
            additional_info_for_switch_field: additionalInfo
          };
          this.stepFormData[stepFormDataIndex].questionCheck = this.stepsForm.value[key].switch_field;
        } else if (this.stepFormData[stepFormDataIndex] &&
          this.stepFormData[stepFormDataIndex].layoutCode === 'CustomQuestionsGroupComponent') {
          this.stepFormData[stepFormDataIndex].questionCheck = this.stepsForm.value[key];
          this.stepFormData[stepFormDataIndex].value = this.stepsForm.value[key];
        } else if (this.stepFormData[stepFormDataIndex] && this.stepFormData[stepFormDataIndex].layoutCode === 'CustomCalenderComponent') {
          this.stepFormData[stepFormDataIndex].value = this.stepsForm.value[key] ? this.getFormattedDate(this.stepsForm.value[key]) : '';
        }
        else {
          this.stepFormData[stepFormDataIndex].value = this.stepsForm.value[key];
        }
      }

    });

    const payload: any = {};
    payload.formElement = null;
    payload.jsonInput = JSON.stringify(this.stepFormData);
    if (payload.jsonInput) {
      this.customStepsService.postStepDetails(this.inputData.visatype, this.inputData.petitionId,
        this.inputData.stepId, payload)
        .subscribe(response => {
          if (response?.status === 200) {
            this.toastr.success(response.message, 'Success');
            this.store.dispatch(new MarkCleanFormAction({
              dirty: false
            }));
            if (this.stepDetails.stepStatusDetails.stepStatusCode === 'NEW') {
              this.updateStepStatus('INPROGRESS');
            }
          }
        });
    }
  }

  postApplicantsStepsForm() {
    let jsonInput = [];
    this.applicantsList.forEach(applicant => {
      jsonInput[`family_${applicant.id}`] = [];
      //get the formgroup
      //let formGrop = applicant[`family_${applicant.id}`];
      let formGrop = applicant.formGroup;
      applicant.stepForm?.forEach(elm => {
        let tmpVar = elm;
        if (elm.layoutCode === 'CustomGridEditor') {
          elm.gridValues = (formGrop.value[elm.name]) ? formGrop.value[elm.name].gridValues : null;//form[key].gridValues;
        }
        else if (elm.layoutCode === 'VdSwitchGroupComponent') {
          let switchGridProps = elm?.groupElementProperty;
          let additionalInfo = '';

          if (switchGridProps?.whenTextAreaShown === 'off') {
            additionalInfo = (formGrop?.value[elm.name]?.switch_field === true) ? '' : formGrop.value[elm.name]?.additional_info_for_switch_field;//form[key].additional_info_for_switch_field;
          } else if (switchGridProps?.whenTextAreaShown === 'on') {
            additionalInfo = (formGrop?.value[elm.name]?.switch_field === false) ? '' : formGrop.value[elm.name]?.additional_info_for_switch_field;//form[key].additional_info_for_switch_field;
          } else {
            additionalInfo = formGrop?.value[elm.name]?.additional_info_for_switch_field;
          }


          elm.value = {
            switch_field: formGrop?.value[elm.name]?.switch_field,
            additional_info_for_switch_field: additionalInfo
          }
          elm.questionCheck = formGrop?.value[elm.name]?.switch_field;
        }
        else if (elm.layoutCode === 'CustomQuestionsGroupComponent') {
          elm.questionCheck = formGrop?.value[elm.name];
          elm.value = formGrop?.value[elm.name];
        } else if (elm.layoutCode === 'CustomCalenderComponent') {
          elm.value = formGrop?.value[elm.name] || '';
        } else if (elm.layoutCode === 'CustomCheckboxComponent') {
          if (formGrop?.value[elm.name]) {
            elm.value = (formGrop?.value[elm.name]) ? formGrop.value[elm.name] : null;
          }
        } else if (elm.layoutCode === 'VdEadLibraryComponent' || elm.layoutCode === 'VdCountryStateComponent') {
          elm.value = (formGrop?.value[elm.groupName]) ? formGrop.value[elm.groupName] : null;
        }
        else {
          tmpVar.value = (formGrop?.value[elm.name]) ? formGrop.value[elm.name] : null;
        }
        jsonInput[`family_${applicant.id}`].push(tmpVar);
      });
      //Mark the applicant form clean upon save
      applicant.formGroup.markAsPristine();
      applicant.formGroup.markAsUntouched();

      formGrop = [];
    });
    let payload: any = {};
    payload.formElement = null;
    payload.jsonInput = JSON.stringify(Object.assign({}, jsonInput));
    //payload.jsonInput = JSON.stringify(jsonInput);

    this.customStepsService.postStepDetails(this.inputData.visatype, this.inputData.petitionId, this.inputData.stepId, payload).subscribe(response => {
      if (response?.status === 200) {
        this.toastr.success(response.message, 'Success');
        this.store.dispatch(new MarkCleanFormAction({
          dirty: false
        }));
        if (this.stepDetails.stepStatusDetails.stepStatusCode === 'NEW') {
          this.updateStepStatus('INPROGRESS');
        }
      }
    });
  }

  updateStepStatus(status) {
    this.store.dispatch(updateStepStatus({
      payload: this.stepStatusUpdatePayloadCostruction.payloadConstruction(this.stepDetails, status),
      subTaskId: this.stepDetails.taskId, stepId: this.dynamicDialogConfig.data.stepId, visaType: this.dynamicDialogConfig.data.visatype
    }));
  }


  handleClose() {
    this.guardSubscription = this.guard.canDeactivate().pipe(first()).subscribe((response: boolean) => {
      if (response) {
        this.store.dispatch(new MarkCleanFormAction({
          dirty: false
        }));
        this.dynamicDialogRef.close();
      }
      else {
        return false;
      }
    });
  }


  handleCancel() {
    this.stepsForm.reset();

    this.loadStepForm(); // IM-10015 issue fix
    const keys = Object.keys(this.stepsForm.value);
    keys.forEach((key) => {
      const index = this.stepFormData.findIndex(x => x.name === key);
      this.stepsForm.controls[key].patchValue(this.stepFormData[index].value);
    });

    this.store.dispatch(loadSubTaskDetails({
      taskId: this.inputData.subTaskId,
      visaTypeCode: this.inputData.visatype
    }));
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.onStatusToggle(this.stepDetails.stepStatusDetails.stepStatusCode);
    }, 1000);
  }

  statusChangedHandler(e) {
    this.onStatusToggle(e);
  }

  onStatusToggle(status) {
    this.inputData.stepStatusCode = status;
    const user = sessionStorage.getItem('userTypeRole');
    const isCaseRequest = sessionStorage.getItem('beneQuestIsCaseRequest') === 'true';
    if (user === 'Beneficiary') {
      status == 'SUBMIT' ? this.stepsForm.disable() : this.stepsForm.enable();
      this.external = isCaseRequest ? false : status === 'SUBMIT';
    } else {
      status == 'COMPLETE' || status == 'NEW' ? this.stepsForm.disable() : this.stepsForm.enable();
      this.external = isCaseRequest ? false : status === 'COMPLETE' || status === 'NEW';
    }
    this.cRef.detectChanges();
    // const a = document.querySelectorAll<HTMLElement>('.mr-2.ng-star-inserted');
  }

  ngOnDestroy() {
    this.observableSubscription$.next();
    this.observableSubscription$.complete();
    this.store.dispatch(updateStepStatusToInitialState());
  }
  updateValues(e) {
  }
  updateFormValidity(e) {

  }


  handleIsFileListModified(e) {

  }
  handleChange(e) {
    this.cRef.detectChanges();
  }

  getFormattedDate(value: any) {
    const strType = Object.prototype.toString.call(value);
    if (['[object String]', '[object Date]'].includes(strType) && value) {
      return moment(value).format('MM/DD/YYYY');
    } else {
      return value;
    }
  }

  sycProfilePitition() {
    if (this.stepDetails?.id) {
      this.customStepsService.syncStep(this.inputData.visatype, this.inputData.petitionId, this.inputData.stepId)
        .subscribe(response => {
          if (response) {
            this.toastr.success(response.message, 'Success');
            this.loadStepForm(true);
          }
        });
    }
  }
}
