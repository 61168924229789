<!--<mat-toolbar class="mat-toolbar-row  header-container">
  <div class="pr-4 pl-4">
    <a routerLink="dashboard" routerLinkActive="header--menuItem-active" class="header--menu-item">
      Dashboard
    </a>
  </div>
  <div class="pr-4 pl-4">
    <a routerLink="my-petitions" routerLinkActive="header--menuItem-active" class="header--menu-item">
      My Petitions
    </a>
  </div>
  <div class="pr-4 pl-4">
    <a>
      Petitioners
    </a>
  </div>
  <div class="pr-4 pl-4">
    <a>
      Situations
    </a>
  </div>
</mat-toolbar> -->



<nav class="im-navbar navbar-expand-xl navbar-light mb-4">
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      <li class="im-nav-item dropdown" routerLinkActive="active">
        <!-- <a routerLink="dashboard"  class="im-nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
                  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i class="fa fa-home"></i> DASHBOARD
              </a> -->
        <a class="im-nav-link" routerLink="dashboard"><img src="/assets/images/menu-dashboard.svg" width="17px"
            class="mr-1" alt=""><span class="common-bene-class-for-translation"> DASHBOARD</span></a>
      </li>

      <li class="im-nav-item" routerLinkActive="active">
        <a class="im-nav-link" routerLink="petitions/manage-petitions-bene"><img src="/assets/images/menu-petitions.png"
            width="20px" class="mr-1" alt=""><span class="common-bene-class-for-translation">PETITIONS</span></a>
      </li>
      <!-- start - Hiding the MENU items temperarily for forreign language  -->
      <!-- <li class="im-nav-item" routerLinkActive="active">
        <a class="im-nav-link" routerLink="myforms"><img
            src="/assets/images/menu-petitions.png" width="20px" class="mr-1" alt=""><span class="common-bene-class-for-translation">FORMS</span></a>
      </li>
      <li class="im-nav-item" routerLinkActive="active">
        <a class="im-nav-link" routerLink="billing"><img
            src="../../../../assets/billing_icn.svg" width="20px" class="mr-1" alt=""><span class="common-bene-class-for-translation">BILLING</span></a>
      </li>
      <li class="im-nav-item" routerLinkActive="active">
        <a class="im-nav-link" routerLink="compliance/compliance/fdns-qa"><i
            class="fa fa-file-text"></i><span class="common-bene-class-for-translation">Compliance</span></a>
      </li>
      <li class="im-nav-item" routerLinkActive="active">
        <a class="im-nav-link" routerLink="resources"><img
            src="/assets/images/Resources_icon.png" width="20px" alt="">
            <span class="common-bene-class-for-translation">Resources</span></a>
      </li> -->
      <!-- end - Hiding the MENU items temperarily for forreign language  -->
    </ul>
    <!-- Hiding the MENU item as no route assigned to this  -->
    <!-- <li class="im-nav-item" routerLinkActive="active">
      <a class="im-nav-link"><img src="/assets/images/beneficiary-icon.png" width="20px" class="mr-1" alt="">
        PETITIONERS</a>
    </li> -->
    <!-- Hiding the MENU item as no route assigned to this  -->
    <!-- <li class="im-nav-item" routerLinkActive="active">
      <a class="im-nav-link"><img src="/assets/images/menu-situations.png" width="20px" class="mr-1" alt="">
        SITUATIONS</a>
    </li> -->
  </div>
  <!-- <div class="navbar-rgt-btn mr-2">

    <button class="btn btn-primary"  icon="pi pi-search" (click)="op.toggle($event)">FAQ</button>
    <p-overlayPanel #op [style]="{width: '450px',backgroundColor:'red'}" autoZIndex="true" class="customPanel">
      <ng-template pTemplate>

            <div class="col-6">
              <label>Response Title</label>

            </div>
      </ng-template>
    </p-overlayPanel>
  </div> -->
  <div class="navbar-rgt-btn mr-2">
    <button (click)="isOpen = !isOpen;loadFaq()" class="btn bg-transparent faqButton" type="button" cdkOverlayOrigin
      #trigger="cdkOverlayOrigin">
      <img src="/assets/images/faq/faq_icon.svg" alt="">
      <span class="common-bene-class-for-translation">FAQ</span> <i class="fa fa-angle-down" style="font-size:20px"></i>
    </button>

    <!-- This template displays the overlay content and is connected to the button -->
    <ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="trigger" [cdkConnectedOverlayOpen]="isOpen">

      <div class="faqMenuConainer">
        <div class="row">
          <div class="col-3 common-bene-class-for-translation">Most Viewed</div>
          <div class="col"><button class="btn btn-sm btn-outline-primary goToDetailButton"
              (click)="goToBeneficiaryFaqDetailsPage()"><span class="common-bene-class-for-translation">Go To FAQ
                Details Page</span></button></div>
        </div>
        <hr />
        <div class="scrollableContent">
          <mat-accordion class=" mt-3">
            <!-- #enddocregion multi -->
            <mat-expansion-panel [togglePosition]="'before'" *ngFor="let faq of faqList; let i=index">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{faq.question}}
                </mat-panel-title>

              </mat-expansion-panel-header>
              <p>{{faq.answer}}</p>



            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <div class="d-flex justify-content-center">

          <i class="material-icons expandMoreStyle" (click)="goToBeneficiaryFaqDetailsPage()">expand_more</i>

        </div>
      </div>

    </ng-template>

  </div>
  <!-- <a class="navbar-rgt-btn mr-2" href="#">
    <img src="/assets/images/menu-recomended.png" width="25%" alt="">
    <div>RECOMMENDED<br>TOOLS FOR YOU</div>
  </a> -->
  <div>
    <app-switch-app [appRoleData]="null"></app-switch-app>
  </div>
</nav>