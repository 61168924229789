<div class="multiUploadContainer">

    <div class="upload-btn-wrapper" *ngIf="isViewButton">
        <button class="btn-outline-primary btn custom-btn">Select Documents</button>
        <input #myfileSection1 type="file" name="files[]" multiple accept="pdf"
            (change)="selectDocuments($event.target.files)" />
    </div>

    <!-- <div class="upload-btn-wrapper float-right" *ngIf="isViewButton">
            <button class="btn-outline-primary btn custom-btn" (click)="saveDocument()">Upload Documents</button>
        </div> -->
    <h5 class="doc-heading">List of uploaded Documents</h5>    
    <table class="im-card-no-border-table table im-card-table-noborder-spacing doc-table-sec" mat-table
        [dataSource]="getDocumentArray" matSort style="width: 100%;">

        <ng-container *ngFor="let column of displayedColumns;" [matColumnDef]="column.columnDef">
            <th mat-header-cell *matHeaderCellDef> {{column.header}} </th>

            <td mat-cell *matCellDef="let emp;  let i = index">

                <!-- Normal Mode -->

                <span *ngIf="
                column.header === 'Document Name' &&
                column.header != 'Document Type' &&
                column.header != 'Issue Authority' && 
                column.header != 'Document Number' &&
                column.header != 'Expiry Date' &&
                column.header != 'Action'"> {{emp[column.columnDef]}}</span>

                <span *ngIf="column.header === 'Action' && emp.viewMode == false" style="width: 100px;">
                    <i class="fa fa-eye customTrash" (click)="openDoc(emp)" aria-hidden="true"></i>
                    <i class="fa fa-pencil customTrash" *ngIf="isViewButton" (click)="editRow(emp)"
                        aria-hidden="true"></i>
                    <i class="fa fa-trash customTrash" *ngIf="isViewButton" (click)="deleteI9PastDoc(emp)"
                        aria-hidden="true"></i>
                </span>

                <span
                    *ngIf="column.header === 'Document from List' && column.header != 'Document Name' && emp.viewMode == false">
                    {{ emp[column.columnDef] }} </span>
                <span
                    *ngIf="column.header === 'Document Type' && column.header != 'Document Name' && emp.viewMode == false">
                    {{ emp[column.columnDef] }} </span>
                <span
                    *ngIf="column.header === 'Issue Authority' && column.header != 'Document Name' && emp.viewMode == false">{{
                    emp[column.columnDef] }} </span>
                <span
                    *ngIf="column.header === 'Document Number' && column.header != 'Document Name' && emp.viewMode == false">{{
                    emp[column.columnDef] }} </span>
                    <span
                    *ngIf="column.header === 'Expiry Date' && column.header != 'Document Name' && emp.viewMode == false">
                    {{ emp[column.columnDef] ? (emp[column.columnDef] | date: 'MM/dd/yyyy') : 'NA' }}
                  </span>                  


                <!-- Edit Mode -->
                <span
                    *ngIf="column.header === 'Document from List' && emp.viewMode == true">
                    {{ emp.listType }}
                </span>
                <span *ngIf="column.header === 'Document Type' && emp.viewMode == true"
                    style="position: relative; width: 150px;">
                    <input type="text" class="inputEditBox1" [(ngModel)]="emp.docType"
                        (click)="openDocumentPopup(emp)" />
                    <div class="pi pi-angle-down arrowInput" (click)="openDocumentPopup(emp)"></div>
                </span>

                <span *ngIf="column.header === 'Issue Authority' && emp.viewMode == true">
                    <input type="text" class="inputEditBox" [(ngModel)]="emp.docIssuingAuthority" />
                </span>

                <span *ngIf="column.header === 'Document Number' && emp.viewMode == true">
                    <input type="text" class="inputEditBox" [(ngModel)]="emp.docNumber" />
                </span>

                <span *ngIf="column.header === 'Expiry Date' && emp.viewMode == true">
                    <input type="date" [disabled]="emp.noExpiry" [(ngModel)]="emp.docExpirationDate" class="inputEditBox" />
                
                    <!-- Checkbox with "No Expiry Date / NA" -->
                    <label class="no-expiry-label">
                        <input type="checkbox" [(ngModel)]="emp.noExpiry" (change)="handleNoExpiryChange(emp)" />
                        No Expiry Date
                    </label>
                
                    <!-- pi-circle Icon -->
                    <i pTooltip="This document either has no expiry date or, it's not applicable for this document type." class="pi pi-info-circle"></i>
                </span>
                

                <!-- Edit Mode Button -->
                <span *ngIf="emp.viewMode == true && emp.upload == false && isViewButton">
                    <span *ngIf="column.header === 'Action' && emp[column.columnDef] != 'docExpirationDate'">

                        <i class="fa fa-save customTrash" (click)="updateDocument(emp)" aria-hidden="true"></i>
                        <i class="fa fa-times customTrash" (click)="cancelRow(emp)" aria-hidden="true"></i>
                    </span>
                </span>

                <!-- Add New Mode Button -->
                <span *ngIf="column.header === 'Action' 
                        && emp.viewMode == true
                        && emp.upload == true">
                    <i class="fa fa-trash customTrash" (click)="deleteRow(i)" aria-hidden="true"></i>
                </span>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="getDisplayedColumns()"></tr>
        <tr mat-row *matRowDef="let row; columns: getDisplayedColumns();"></tr>
    </table>

    <ng-container *ngIf="getDocumentArray.length == 0">
        <div style="border: 1px solid #cecece; padding: 40px; text-align: center;">No Document Found</div>
    </ng-container>

</div>

<div class="float-right uploadDocumentsMain mt-4 mb-4" *ngIf="isViewButton">
    <button type="button" (click)="saveDocument()" class="btn btn-primary mr-2" label="Edit Employee">
        Save All Documents
    </button>
</div>

<p-confirmDialog header="Confirm Upload" key="uploaddocs-{{instanceId}}"
    styleClass="confirm-upload-documents"></p-confirmDialog>