import { Component, OnInit, ChangeDetectorRef, ViewChildren, QueryList, AfterViewInit } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { VisaPreferenceService } from 'src/app/services/visa-preference.service';
import { CustomErrorToastComponent } from "custom-error-toast";
import { ToastrService } from 'ngx-toastr';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

interface VisaTypeSelection {
  visaTypeId: string;
  visaId: number;
  isVisaTypeSelected: boolean;
}

interface FormData {
  formName: string;
  formShortDesc: string;
  formLongDesc: string;
  isFormSelected: boolean;
  formTypeId: string;
  visaTypeSelections: VisaTypeSelection[];
  isExpanded?: boolean;
}

@Component({
  selector: 'app-visa-preference-settings',
  templateUrl: './visa-preference-settings.component.html',
  styleUrls: ['./visa-preference-settings.component.scss']
})
export class VisaPreferenceSettingsComponent implements OnInit { 

  @ViewChildren(MatExpansionPanel) panels: QueryList<MatExpansionPanel>;

  employeeId: number;  
  visaForms: FormData[] = [];  // Holds the original dataset
  filteredForms: FormData[] = []; // Holds the filtered dataset
  searchControl = new FormControl('');  
  noDataFound: boolean = false;
  isAllSelected: boolean = false; 

  constructor(
    private visaPreferenceService: VisaPreferenceService,
    private toastService: ToastrService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.employeeId = parseInt(sessionStorage.getItem("employeeId"));            
    this.loadVisaForms(this.employeeId); 
    // Apply debounce to improve performance
    this.searchControl.valueChanges.pipe(debounceTime(300)).subscribe(value => {
      if (value.length >= 2 || value.length === 0) {  // Trigger only if 2+ characters or empty
        this.applyFilter(value);
      }
    });
  }  

  ngAfterViewInit(): void {}

  loadVisaForms(employeeId: number) {
    this.visaPreferenceService.getVisaForms(employeeId).subscribe((response: any) => { 
      if (!response || response.length === 0) {
        this.visaForms = [];
        this.filteredForms = [];
        this.noDataFound = true;  
      } else {
        this.visaForms = response;
        this.filteredForms = response;
        this.noDataFound = false; 
      }
    });
  }

  selectAllForms(isSelected: boolean) {
    this.isAllSelected = isSelected; // Update the checkbox state

    this.visaForms.forEach(form => {
      form.isFormSelected = isSelected;
      form.visaTypeSelections.forEach(visa => visa.isVisaTypeSelected = isSelected);
    });
}  

  toggleFormSelection(form: any) {
    // Select/deselect all child visa types when parent checkbox is clicked
    const newState = form.isFormSelected; 
    form.visaTypeSelections.forEach(visa => visa.isVisaTypeSelected = newState);

    // Manually trigger change detection to ensure UI updates immediately
    this.cdr.detectChanges();
  }

  toggleVisaSelection(form: any) {
    // If all child visa types are selected, set the parent checkbox to true, else false
    form.isFormSelected = form.visaTypeSelections.every(visa => visa.isVisaTypeSelected);
  } 

  clearSelection() {
    this.searchControl.setValue(''); // Clears the search box
    this.closeAllAccordions();

    this.visaForms.forEach(form => {
      form.isFormSelected = false;      
      form.visaTypeSelections.forEach(visa => visa.isVisaTypeSelected = false);
    });

    this.filteredForms = [...this.visaForms]; // Reset filtered list

    // Manually uncheck the "Select All" checkbox
    this.isAllSelected = false;

    this.cdr.detectChanges(); // Ensure UI updates
    
  }


  closeAllAccordions(): void {
    this.panels.toArray().forEach(panel => panel.close());
  }

  applyFilter(searchText: string) {
    if (!this.visaForms) return; // Prevent errors if API hasn't loaded
  
    if (!searchText || searchText.length < 2) {
      this.filteredForms = [...this.visaForms]; // Reset when search is cleared or too short
      this.noDataFound = false;
      return;
    }
  
    const search = searchText.toLowerCase();
  
    this.filteredForms = this.visaForms
      .map(form => {
        // Check if formName or formShortDesc matches
        const formMatch =
          form.formName.toLowerCase().includes(search) ||
          form.formShortDesc.toLowerCase().includes(search);
  
        // Check if any visaTypeId matches
        const matchingVisas = form.visaTypeSelections.filter(visa =>
          visa.visaTypeId.toLowerCase().includes(search)
        );
  
        return {
          ...form,
          visaTypeSelections: formMatch ? form.visaTypeSelections : matchingVisas, // Keep all visas if form matches
          isExpanded: formMatch || matchingVisas.length > 0 // Expand if form or visas match
        };
      })
      .filter(form => form.isExpanded); // Only keep relevant forms
  
    this.noDataFound = this.filteredForms.length === 0;
  }  

  isSaveDisabled(): boolean {
    return !this.filteredForms.some(form =>
      form.isFormSelected || form.visaTypeSelections.some(visa => visa.isVisaTypeSelected)
    );
  }

  saveFormPreference(){   
    const selectedData = {};
    //format the data to get the selected forms and visas  
    this.filteredForms.forEach(form => {
      if (form.isFormSelected || form.visaTypeSelections.some(visa => visa.isVisaTypeSelected)) {
        selectedData[form.formTypeId] = form.visaTypeSelections
          .filter(visa => visa.isVisaTypeSelected)
          .map(visa => visa.visaId);
      }
    });

    this.visaPreferenceService.savePreferenceSettings(this.employeeId, selectedData).subscribe({
      next: (response) => {
        if (response.status === 200) {
          this.toastService.success(response.message, 'Success');
          this.loadVisaForms(this.employeeId);
        } else {
          CustomErrorToastComponent.showErrorToast(this.toastService, response.message);
        }
      },
      error: (error) => {
        this.toastService.error('An error occurred while saving preferences. Please try again later.');
      }
    });
  }

  

}