import { NgModule } from '@angular/core';
import { CustomErrorToastComponent } from './custom-error-toast.component';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';



@NgModule({
  declarations: [CustomErrorToastComponent],
  imports: [
    CommonModule,
    ButtonModule,
  ],
  exports: [CustomErrorToastComponent]
})
export class CustomErrorToastModule { }
