<div *ngIf="!isHeaderIncluded" class="row d-flex flex-column flex-md-row align-items-center p-1 px-md-4"
  [ngClass]="{'header': isLoggedIn}">

  <!---IM-3564--->
  <div class="col-md-3">
    <a class="navbar-brand my-0 mr-md-auto pointer font-weight-normal pl-2" (click)="handleNavigationCLick(presentApp)">
      <img src="../../../../assets/weblogo.png" alt="Imagility Logo" *ngIf="whiiteLabelLogo == ''">
      <img src="{{whiiteLabelLogo}}" alt="Logo" *ngIf="whiiteLabelLogo !== ''">
    </a>
  </div>
  <div class="col-md-5">
        <div class="row rounded-pill border border-secondary p-0" *ngIf="userType === 'Attorney'">
          <div class="col-md-3 mr-0 pr-0 border border-0 ">
            <p-multiSelect #multiSelect [options]="users" 
              [filter]="false" 
              placeholder="All" 
              [showToggleAll]="true"
              [displaySelectedLabel]="true" 
              [(ngModel)]="selectedUsers" 
              (onChange)="onSelect($event)"
              optionLabel="name" 
              styleClass="custom-multiselect">
              <ng-template pTemplate="header">
                <div class="checkbox-all-text">All</div>
              </ng-template>
              <ng-template let-selectedItems pTemplate="selectedItems">
                <span *ngIf="selectedItems && (selectedItems.length === 3 || selectedItems.length === 0)">All</span>
                <span *ngIf="selectedItems && selectedItems.length > 0">
                    {{selectedRoles}}
                </span>
              </ng-template>
            </p-multiSelect>
          </div>
          <div class="col-md-8 pl-0 pr-0">
            <!-- <input style="width:280px !important" type="text" id="search-bar" placeholder="Search"> -->
            <input class="form-control py-2  mr-1 border border-0 shadow-none pr-0" (keyup.enter)="onEnter()" type="search" id="search-bar" [(ngModel)]="universalSearchbar" placeholder="Search">
          </div>
          <div class="col-md-1 pl-0 search-round" (click)="universalSearch()">
            <a href="#" class="search-icon">
              <i class="fa fa-search"></i>
          </a>
          </div>
        </div>
  </div>
  <div class="col-md-4">
    <ul class="nav justify-content-end align-items-center" *ngIf="isLoggedIn">
      <!-- <li class="nav-item hd-search">
        <a class="nav-link" href="#"><img src="../../../../assets/images/search-icon.svg" alt=""></a>
      </li>
      <li class="nav-item hd-bell">
        <a class="nav-link" href="#"><img src="../../../../assets/images/bell-icon.svg" alt=""></a>
      </li> -->
      <li (click)="handleNavigationCLick('notifications')" *ngIf="!isFBUser">
        <span *ngIf="!!this.notificationCount" title="Your notifications" matBadge="{{notificationCount}}"
          matBadgeColor="warn"><i class="fa fa-bell" aria-hidden="true"></i>
        </span>
        <span *ngIf="!this.notificationCount" title="No unread notifications"><i class="fa fa-bell"
            aria-hidden="true"></i>
        </span>
      </li>
      <li class="nav-item hd-user" joyrideStep="beneficiaryFirstStep" [stepContent]="beneficiaryFirstStepContent">
        <div class="hd-avatar-icon">
          <img [src]="avatarSrc ? avatarSrc : '../../../../assets/profile.png' " alt="profile picture">
          <!-- <span class="status-dot available"></span> -->
        </div>
        <div class="hd-user-details">
          <p class="user-name">{{userFN}} {{userLN}}</p>
          <ng-template [ngIf]="!(roleList?.length > 1)">
            <p class="user-designation" *ngIf="!isFBUser">{{userType === 'Attorney' ? 'Lawfirm' : userType}}<span *ngIf="isAdmin">Admin</span></p>
            <p class="user-designation" *ngIf="isFBUser">{{ designation }}</p>
          </ng-template>
          <ng-template [ngIf]="roleList?.length > 1">
            <p class="user-designation">{{ roleName }}</p>
          </ng-template>
          <!-- <p class="user-status">Available</p> -->
        </div>
      </li>
      <ng-template #beneficiaryFirstStepContent>
        <app-guided-tour-steps [content]="beneficiaryGuidedTourContent[0]"></app-guided-tour-steps>
      </ng-template>
      <li class="nav-item">
        <div class="position-relative">
          <a class="nav-link pr-0" href="javascript:void(0);" id="navbarDropdown" role="button" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            <img src="../../../../assets/images/dropdown-icon.svg" alt="">
          </a>
          <div class="dropdown-menu dropdown-menu-right imagility-dropdown-menu" aria-labelledby="navbarDropdown">
            <ng-template [ngIf]="showViewProfile">
              <span *ngFor="let item of profileMenu">
                <button class="dropdown-item" [disabled]="item.path=='' && !isFBUser" (click)="redirectTo(item.path)"
                  routerLinkActive="active">View Profile</button>
              </span>
            </ng-template>
            <!---IM 3549 --->
            <ng-template [ngIf]="!isFBUser">
              <a *ngIf="userType !== 'Platform'" class="dropdown-item" (click)="handleNavigationCLick('settings')"
                href="javascript:void(0);">Settings</a>
            </ng-template>
            <a class="dropdown-item" href="javascript:void(0);" (click)="ChangePwdDialogueOpen()">Change
              password</a>
            <ng-template [ngIf]="!isFBUser">
              <a class="dropdown-item" href="javascript:void(0);" (click)="handleNavigationCLick('help')">Help</a>
            </ng-template>
            <a class="dropdown-item" href="javascript:void(0);" [hidden]="!isLoggedIn" (click)="handleLogout()">Logout</a>
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>


<!-- <div class='row' style="height: 75px; padding: 5px; border: 1px solid black;">
  <div class='col-3'>
    <img src="../../../../assets/weblogo.png" />
  </div>
  <div class='col' style="text-align: right;">
    <button class="im-btn im-btn-primary im-btn-md" [hidden]="!isLoggedIn" (click)="handleLogout()">
      Logout
    </button>
  </div>
</div> -->
