import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AttorneyGuardService } from './attorney-guard.service';
import { AttorneyLandingComponent } from './attorney-landing/attorney-landing.component';
import { TermsConditionsComponent } from '../shared/footer/terms-conditions/terms-conditions.component';
import { PrivacyPolicyComponent } from '../shared/footer/privacy-policy/privacy-policy.component';
import { ContactUsComponent } from '../shared/footer/contact-us/contact-us.component';
import { VisaBulletinDetailsComponent } from './visa-bulletin-details/visa-bulletin-details.component';
import { FormsWeSupportComponent } from '../shared/forms-we-support/forms-we-support.component';
import { VisaPreferenceSettingsComponent } from '../visa-preference/visa-preference-settings/visa-preference-settings.component';

const routes: Routes = [
  {
    path: 'attorney-landing',
    component: AttorneyLandingComponent,
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      },
      {
        path: 'attorney',
        loadChildren: () => import('../attorney/attorney.module').then((mod) => mod.AttorneyModule),

      },
      {
        path: 'dashboard',
        loadChildren: () => import('../dashboard-attorney/dashboard-attorney.module').then((mod) => mod.AttorneyDashboardModule),

      },
      {
        path: 'clients',
        loadChildren: () => import('../clients/clients.module').then((mod) => mod.ClientsModule),
      },
      {
        path: 'client-companies',
        loadChildren: () => import('../petitioner/petitioner.module').then((mod) => mod.PetitionerModule),
      },
      {
        path: 'companies',
        loadChildren: () => import('../petitioner/petitioner.module').then((mod) => mod.PetitionerModule),
      },
      {
        path: 'users',
        loadChildren: () => import('../user/user.module').then((mod) => mod.UserModule),
      },
      {
        path: 'roles',
        loadChildren: () => import('../roles/roles.module').then((mod) => mod.RolesModule),
      },
      {
        path: 'policies',
        loadChildren: () => import('../policies/policies.module').then((mod) => mod.PoliciesModule),
      },
      {
        path: 'leads',
        loadChildren: () => import('../../modules/marketing/marketing.module').then((mod) => mod.MarketingModule),
      },
      {
        path: 'email-temp',
        loadChildren: () => import('../../modules/email-template/email-template.module').then((mod) => mod.EmailTemplateModule)
      },
      {
        path: 'reports',
        loadChildren: () => import('../manage-company-reports/manage-company-reports.module').then((mod) => mod.ManageCompanyReportsModule),
      },
      {
        path: 'policies',
        loadChildren: () => import('../policies/policies.module').then((mod) => mod.PoliciesModule),
      },
      {
        path: 'case-request',
        loadChildren: () => import('../case-request/case-request.module').then((mod) => mod.CaseRequestModule), 
      },
      {
        path: 'custom-questionaire',
        loadChildren: () => import('../custom-questionaire/custom-questionaire.module').then((mod) => mod.CustomQuestionaireModule),
      },
      {
        path: 'attorney-invoice',
        loadChildren: () => import('../attorney-invoice/attorney-invoice.module').then((mod) => mod.AttorneyInvoiceModule),
      },
      {
        path: 'lca',
        loadChildren: () => import('../lca/lca.module').then((mod) => mod.LCAModule),
      },
      {
        path: 'task-allocation',
        loadChildren: () => import('../task-allocation/task-allocation.module').then((mod) => mod.TaskAllocationModule),
      },
      {
        path: 'template',
        loadChildren: () => import('../template-builder/template-builder.module').then((mod) => mod.TemplateBuilderModule),
      },
      {
        path: 'rfe',
        loadChildren: () => import('../rfe-builder/rfe-builder.module').then((mod) => mod.RfeBuilderModule),
      },
      {
        path: 'receipt',
        loadChildren: () => import('../receipt-management/receipt-management.module').then((mod) => mod.ReceiptManagementModule),
      },
      {
        path: 'notifications',
        loadChildren: () => import('../../modules/notifications/notifications.module').then((mod) => mod.NotificationsModule)
      },
      {
        path: 'petition-analysis',
        loadChildren: () => import('../petition-analysis-container/petition-analysis.module').then((mod) => mod.PetitionAnalysisContainerModule)
      },
      {
        path: 'receipts-notices',
        loadChildren: () => import('../receipts-notices/receipts-notices.module').then((mod) => mod.ReceiptsNoticesModule),
      },
      {
        path: 'settings',
        loadChildren: () => import('../../modules/settings/settings.module').then(m => m.SettingsModule),
        data: { preload: true }
      },
      {
        path: 'help',
        loadChildren: () => import('../../modules/help/help.module').then(m => m.HelpModule),
        data: { preload: true }
      },
      {
        path: 'terms-and-conditions',
        component: TermsConditionsComponent,
      },
      {
        path: 'privacy',
        component: PrivacyPolicyComponent,
      },
      {
        path: 'contact-us',
        component: ContactUsComponent,
      },
      {
        path: 'resources',
        loadChildren: () => import('../../modules/resources/resources.module').then(m => m.ResourcesModule),
      },
      {
        path: 'bulk-upload',
        loadChildren: () => import('../../modules/bulk-upload/bulk-upload.module').then(m => m.BulkUploadModule),
      },
      {
        path: 'immigration-wizard',
        loadChildren: () => import('../../modules/immigration-wizard/immigration-wizard.module').then(m => m.ImmigrationWizardModule),
      },
      {
        path: 'master-data',
        loadChildren: () => import('../../modules/master-data/master-data.module').then((mod) => mod.MasterDataModule),
      },
      {
        path: 'faq',
        loadChildren: () => import('../../modules/faq/faq.module').then((mod) => mod.FaqModule),
      },
      {
        path: 'case-status',
        loadChildren: () => import('../case-status/case-status.module').then((mod) => mod.CaseStatusModule),
      },
      {
        path: 'leads',
        loadChildren: () => import('../../modules/marketing/marketing.module').then((mod) => mod.MarketingModule),
      },
      {
        path: 'e-consent',
        loadChildren: () => import('../../modules/e-consent-setup/e-consent-setup.module').then((mod) => mod.EConsentSetupModule)
      },
      {
        path: 'forms-we-support',
        component: FormsWeSupportComponent
      },  
      {
        path: 'visa-preference-settings',
        component: VisaPreferenceSettingsComponent
      },    
      {
        path: 'visaBulletin',
        component: VisaBulletinDetailsComponent
      },
    ],
    canActivate: [AttorneyGuardService],
    runGuardsAndResolvers: 'always'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class AttorneyLandingRoutingModule { }

