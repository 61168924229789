import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ImagilityBaseResponse, PrimaryData, ReferenceLibrary, TaskStep } from 'app-models';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs/internal/Subject';
import { StepStatusUpdatePayloadService } from 'step-status-update-payload-service';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { getStepDetails, updateStepStatus } from 'visa-store';
import { combineLatest } from 'rxjs';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as errorUtils from 'error-message-utility';
import { DeleteDialogLibraryComponent } from 'delete-dialog-library';
import { DialogService } from 'primeng/dynamicdialog';
import { MarkCleanFormAction, MarkDirtyFormAction } from 'dirty-check-store';
import { FamilyInformationService } from './family-information.service';
import { ReferenceLibraryService } from 'reference-library';
import { CacheCountryService } from 'src/app/services/cacheCountries.service';
import { ApiServicesService } from 'src/app/modules/beneficiary/services/api-services.service';
import * as moment from 'moment';

export const DeleteDialogConfigurations = {
  width: '45%',
  closeOnEscape: false,
  closable: false,
  showHeader: false,
  contentStyle: { 'z-index': '1001' }
};

@Component({
  selector: 'app-family-information',
  templateUrl: './family-information.component.html',
  styleUrls: ['./family-information.component.scss']
})
export class FamilyInformationComponent implements OnInit, OnChanges {
  @Input() primaryData: PrimaryData;
  @Input() userId: number;
  @Input() type: string;
  disabledControls: boolean;
  stepDetails: TaskStep;
  familyInformationForm: FormGroup;
  familyList = [];
  addEditButtonText;
  selectedCountryCode = '';
  selectedRelationShipType: any;
  observableSubscription$ = new Subject();
  formChangesSubscription: any;
  countryList: any[];
  familyTypeArrTemp: ReferenceLibrary[];
  familyTypeArr: ReferenceLibrary[];
  titleListArr: ReferenceLibrary[];
  genderListArr: ReferenceLibrary[];
  listBirthStates = [];
  listLivingStates = [];
  @Output() scrollTop = new EventEmitter();

  constructor(
    public store: Store<any>,
    public apiService: FamilyInformationService,
    public toastr: ToastrService,
    public stepStatusUpdatePayloadCostructionService: StepStatusUpdatePayloadService,
    private fb: FormBuilder,
    public dialogService: DialogService,
    private referenceLibraryService: ReferenceLibraryService,
    private cacheCountryService: CacheCountryService,
    private apiServiceState: ApiServicesService) {
    this.disabledControls = false;
  }

  ngOnInit(): void {
    // Get Specific step details by passing step code
    this.store.pipe(select(getStepDetails, { id: this.primaryData.stepId }))
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe((data: TaskStep) => {
        if (data && data.id) {
          this.stepDetails = data;
          this.toggleControlsStatus();
        }
      });
  }

  ngOnChanges() {
    if (this.primaryData) {
      this.familyInformationForm = new FormGroup({
        id: new FormControl(null),
        title: new FormControl(null, Validators.required),
        firstName: new FormControl(null, [Validators.required, Validators.pattern(/^[a-zA-Z ]*$/)]),
        lastName: new FormControl(null, [Validators.required, Validators.pattern(/^[a-zA-Z ]*$/)]),
        middleName: new FormControl(null, Validators.pattern(/^[a-zA-Z ]*$/)),
        dob: new FormControl(null),
        gender: new FormControl(null),
        birthCountryCode: new FormControl(null),
        birthStateCode: new FormControl(null),
        birthState: new FormControl(null),
        birthCity: new FormControl(null, Validators.pattern(/^[a-zA-Z ]*$/)),
        alienRegNo: new FormControl(null),
        relationshipCode: new FormControl(null, Validators.required),
        hasOtherName: new FormControl(null),
        otherNameDTO: new FormArray([
          new FormGroup({
            title: new FormControl(null),
            firstName: new FormControl(null),
            lastName: new FormControl(null),
            middleName: new FormControl(null)
          }, [
            this.otherNameDTOTitleFieldRequired.bind(this),
            this.otherNameDTOFirstNameFieldRequired.bind(this),
            this.otherNameDTOFirstNameFieldPattern.bind(this),
            this.otherNameDTOMiddleNameFieldPattern.bind(this),
            this.otherNameDTOLastNameFieldRequired.bind(this),
            this.otherNameDTOLastNameFieldPattern.bind(this)
          ])
        ]),
        addressLine1: new FormControl(null),
        addressLine2: new FormControl(null),
        countryOfResidence: new FormControl(null),
        cityOfResidence: new FormControl(null),
        childSpecificRelationship: new FormControl(null),
        livingStateCode: new FormControl(null),
        livingStateName: new FormControl(null),
        zipCode: new FormControl(null),
        county: new FormControl(null)
      });
      this.familyInformationForm.valueChanges.subscribe(() => {
        if (this.familyInformationForm.dirty) {
          this.store.dispatch(new MarkDirtyFormAction({
            dirty: true
          }));
        } else {
          this.store.dispatch(new MarkCleanFormAction({
            dirty: false
          }));
        }
      });
      this.getFamilyInformation();
    }
  }

  otherNameDTOTitleFieldRequired(group: FormGroup): { [s: string]: boolean } {
    if (this.familyInformationForm && this.familyInformationForm.get('hasOtherName').value &&
      !group.value.title) {
      return { otherNameDTOTitleFieldIsRequired: true };
    }
    return null;
  }

  otherNameDTOFirstNameFieldRequired(group: FormGroup): { [s: string]: boolean } {
    if (this.familyInformationForm && this.familyInformationForm.get('hasOtherName').value &&
      (!group.value.firstName || (group.value.firstName && group.value.firstName.trim() === ''))) {
      return { otherNameDTOFirstNameFieldIsRequired: true };
    }
    return null;
  }

  otherNameDTOFirstNameFieldPattern(group: FormGroup): { [s: string]: boolean } {
    const REGEXP = /^[a-zA-Z ]*$/;
    if (this.familyInformationForm && this.familyInformationForm.get('hasOtherName').value &&
      group.value.firstName && group.value.firstName.trim() !== '' && !(REGEXP.test(group.value.firstName))) {
      return { otherNameDTOFirstNameFieldIsPattern: true };
    }
    return null;
  }

  otherNameDTOMiddleNameFieldPattern(group: FormGroup): { [s: string]: boolean } {
    const REGEXP = /^[a-zA-Z ]*$/;
    if (this.familyInformationForm && this.familyInformationForm.get('hasOtherName').value &&
      group.value.middleName && group.value.middleName.trim() !== '' && !(REGEXP.test(group.value.middleName))) {
      return { otherNameDTOMiddleNameFieldIsPattern: true };
    }
    return null;
  }

  otherNameDTOLastNameFieldRequired(group: FormGroup): { [s: string]: boolean } {
    if (this.familyInformationForm && this.familyInformationForm.get('hasOtherName').value &&
      (!group.value.lastName || (group.value.lastName && group.value.lastName.trim() === ''))) {
      return { otherNameDTOLastNameFieldIsRequired: true };
    }
    return null;
  }

  otherNameDTOLastNameFieldPattern(group: FormGroup): { [s: string]: boolean } {
    const REGEXP = /^[a-zA-Z ]*$/;
    if (this.familyInformationForm && this.familyInformationForm.get('hasOtherName').value &&
      group.value.lastName && group.value.lastName.trim() !== '' && !(REGEXP.test(group.value.lastName))) {
      return { otherNameDTOLastNameFieldIsPattern: true };
    }
    return null;
  }


  getReferenceData() {
    combineLatest([
      this.cacheCountryService.getCountry(),
      this.referenceLibraryService.getReferenceData('FAMRELTYP'),
      this.referenceLibraryService.getReferenceData('TITL'),
      this.referenceLibraryService.getReferenceData('GENDER')
    ])
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe(data => {
        if (data[0].length > 0) {
          this.countryList = data[0];
        }
        this.familyTypeArrTemp = (this.type === 'family' || ['K1', 'K3'].includes(this.primaryData.visatype)) ? data[1].filter(item => item.code === 'FATHER' || item.code === 'MOTHER') : data[1];
        if(this.primaryData.getTaskType === 'I130'){
         this.familyTypeArrTemp = this.familyTypeArrTemp.filter(x=>x.code !== 'FIANCE');
        }
        this.titleListArr = data[2];
        this.genderListArr = data[3];
        this.checkFamilyPresent();
        //for old saved family entries -> IM-20224
        if(this.familyList?.length && this.titleListArr?.length){
          this.familyList = this.familyList.map((el:any)=>{
            if(el.title){
              //if title is previously saved as title.name not title.code then maping it to title.code as now this is being saved as code from the profile section
              const titleObj = this.titleListArr.find((x)=> x.name === el.title);
              el.title = titleObj ? titleObj.code : el.title;
            }
            return el;
          })
        }
      });
  }

  handleFamilyTypeChange(val) {
    this.selectedRelationShipType = this.familyTypeArr.find(item => item.code === val);
  }

  getFamilyInformation() {
    if (this.primaryData.beneficiaryId && !this.type && this.primaryData.beneficiaryId === this.userId) {
      this.getBenficiaryFamilyDetails();
    } else {
      const visaType = (this.type === 'family' || ['K1', 'K3'].includes(this.primaryData.visatype)) ? this.primaryData.visatype : this.primaryData.caseType;
      const familyId = (this.type === 'family' || ['K1', 'K3'].includes(this.primaryData.visatype)) ? this.userId : this.primaryData.familyId;
      this.apiService.getFamilyInformation(visaType,
        familyId, this.primaryData.superTaskId, this.primaryData.subTaskId)
        .pipe(takeUntil(this.observableSubscription$))
        .subscribe((response: ImagilityBaseResponse) => {
          this.familyList = response.data ? response.data : [];
          if (!this.countryList || !this.familyTypeArrTemp || !this.familyTypeArr || !this.titleListArr || !this.genderListArr) {
            this.getReferenceData();
          } else {
            this.checkFamilyPresent();
          }
        });
    }
  }

  checkFamilyPresent() {
    // this.familyTypeArr = this.familyTypeArrTemp;
    this.familyTypeArr = this.familyTypeArrTemp.map(x => {
      return {
        ...x,
        inActive:
          ['FATHER', 'MOTHER', 'SPOUSE'].includes(x.code) && this.familyList &&
            this.familyList.length > 0 ?
            this.familyList.some((family) => family.relationshipCode === x.code)
            : false
      };
    });
  }

  handleCountryChange(countryCode: string) {
    this.familyInformationForm.patchValue({
      birthStateCode: null,
      birthState: null
    });
    this.apiServiceState.getStates(countryCode).subscribe((states: []) => {
      this.listBirthStates = states;
    });
  }

  handleCountryChangeLiving(countryCode: string) {
    this.familyInformationForm.patchValue({
      livingStateCode: null,
      livingStateName: null
    });
    this.apiServiceState.getStates(countryCode).subscribe((states: []) => {
      this.listLivingStates = states;
    });
  }


  updateStepStatus(status) {
    this.store.dispatch(updateStepStatus({
      payload: this.stepStatusUpdatePayloadCostructionService.payloadConstruction(
        this.stepDetails, status),
      subTaskId: this.stepDetails.taskId,
      visaType: this.primaryData.caseType === 'I130' ?
        this.primaryData.caseType : this.primaryData.visatype,
      stepId: this.primaryData.stepId
    }));
  }

  // Toggle controls status based on step status
  toggleControlsStatus() {
    this.disabledControls =
      this.stepDetails.stepStatusDetails.stepStatusCode === "NEW" ||
      this.stepDetails.stepStatusDetails.stepStatusCode === 'COMPLETE' ||
      this.stepDetails.stepStatusDetails.stepStatusCode === 'SUBMIT';
    if (this.familyInformationForm) {
      if (this.disabledControls) {
        this.familyInformationForm.disable();
      } else {
        this.familyInformationForm.enable();
      }
    }
  }

  onDestroy() {
    this.observableSubscription$.next();
    this.observableSubscription$.complete();
  }

  getGlobalErrorMessages(error: string) {
    return errorUtils.errorMessages.get(error);
  }

  saveAddress() {
    const formValue = this.familyInformationForm.getRawValue();
    const payload = {
      ...formValue,
      dob: formValue.dob ? moment(formValue.dob).format('YYYY-MM-DD') : null,
      otherNameDTO: formValue.otherNameDTO && formValue.otherNameDTO.length > 0 && formValue.otherNameDTO[0].firstName != '' ? formValue.otherNameDTO : []
    };
    const visaType = (this.type === 'family' || ['K1', 'K3'].includes(this.primaryData.visatype)) ? this.primaryData.visatype : this.primaryData.caseType;
    if (this.primaryData.beneficiaryId && !this.type && this.primaryData.beneficiaryId === this.userId) {
      this.saveBenficiaryFamilyDetails(visaType, payload);
    } else {
      const familyId = (this.type === 'family' || ['K1', 'K3'].includes(this.primaryData.visatype)) ? this.userId : this.primaryData.familyId;
      this.apiService.postFamilyInformation(visaType, familyId, payload, this.primaryData.superTaskId, this.primaryData.subTaskId)
        .pipe(takeUntil(this.observableSubscription$))
        .subscribe((response: ImagilityBaseResponse) => {
          if (response) {
            this.toastr.success(response.message, 'Success');
            // if (this.stepDetails.stepStatusDetails.stepStatusCode === 'NEW') {
            this.updateStepStatus('INPROGRESS');
            // }
            this.resetForm();
            this.getFamilyInformation();
          }
        });
    }
  }

  getBenficiaryFamilyDetails() {
    this.apiService.getBenficiaryFamilyDetails(this.primaryData.beneficiaryId)
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe((response: ImagilityBaseResponse) => {
        if (response) {
          this.familyList = response.data ? response.data : [];
        } else {
          this.familyList = [];
        }
        if (!this.countryList || !this.familyTypeArrTemp || !this.familyTypeArr || !this.titleListArr || !this.genderListArr) {
          this.getReferenceData();
        } else {
          this.checkFamilyPresent();
        }
      });
  }

  saveBenficiaryFamilyDetails(visaType: string, payload: any) {
    this.apiService.postBenficiaryFamilyDetails(visaType, this.primaryData.beneficiaryId, payload)
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe((response: ImagilityBaseResponse) => {
        if (response) {
          this.toastr.success(response.message, 'Success');
          this.updateStepStatus('INPROGRESS');
          this.resetForm();
          this.getFamilyInformation();
        }
      });
  }

  resetForm() {
    this.familyInformationForm.reset();
  }

  deleteDocument(id) {
    const deleteDialogRef = this.dialogService.open(DeleteDialogLibraryComponent, DeleteDialogConfigurations);
    deleteDialogRef.onClose
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe((response: boolean) => {
        if (response) {
          this.apiService.deleteFamilyItem(this.primaryData.caseType, id, this.primaryData.superTaskId, this.primaryData.subTaskId)
            .pipe(takeUntil(this.observableSubscription$))
            .subscribe((deleteResponse: ImagilityBaseResponse) => {
              this.toastr.success(deleteResponse.message, 'Success');
              this.getFamilyInformation();
            });
        }
      });
  }

  editDocument(doc) {
    if (doc.birthCountryCode && doc.birthCountryCode.trim() !== '') {
      this.handleCountryChange(doc.birthCountryCode);
    }
    if (doc.countryOfResidence && doc.countryOfResidence.trim() !== '') {
      this.handleCountryChangeLiving(doc.countryOfResidence);
    }
    // if (doc.countryCode && doc.countryCode.trim() !== '') {
    //   this.handleCountryChangeLiving(doc.countryCode);
    // }
    if (doc.relationshipCode) {
      this.handleFamilyTypeChange(doc.relationshipCode);
    }
    const otherNameDTOBool = doc.otherNameDTO && doc.otherNameDTO.length > 0 &&
      doc.otherNameDTO[0].firstName && doc.otherNameDTO[0].firstName.trim() !== '';
    let formValue = {
      ...doc,
      dob: doc.dob ? new Date(doc.dob) : null,
      hasOtherName: otherNameDTOBool,
      otherNameDTO: otherNameDTOBool ? doc.otherNameDTO : []
    };

    if (this.primaryData.beneficiaryId && !this.type && this.primaryData.beneficiaryId === this.userId) {
      formValue = {
        ...formValue,
        gender: formValue.genderCode
      }
    }
    this.familyInformationForm.patchValue(formValue);
    if(this.primaryData?.immigrationClassification && this.primaryData.immigrationClassification !='ONLINEIMMVISAREG'){
      this.familyInformationForm.disable();
      const enableFieldsArr = ['county', 'childSpecificRelationship'];
      this.enableFields(enableFieldsArr);
    }
    
    
    this.scrollTop.emit();

  }

  cancelForm() {
    this.resetForm();
  }
  private enableFields(fields: string[]): void {
    fields.forEach(fieldName => {
      const control: AbstractControl = this.familyInformationForm.get(fieldName);
      if (control) {
        control.enable();
      }
    });
  }
}
