import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserRoleService } from 'src/app/services/user-role.service';
import { ReferenceLibraryService } from 'reference-library';

@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.scss']
})
export class ChangepasswordComponent implements OnInit {
  changePasswordForm: FormGroup;
  response: any;
  caption: string = '';
  expiredCred: boolean;
  confirm_new_password_hide: boolean = false;
  new_password_hide: boolean = false;
  current_password_hide: boolean = false;
  redirectPrompt: boolean;
  userName: string;
  strengthIndex: number;
  constructor(
    private userService: UserService,
    private userRoleService: UserRoleService,
    private authenticationService: AuthenticationService,
    private toastr: ToastrService,
    private referenceLibraryService: ReferenceLibraryService,
    private router: Router,
    public dialogRef: MatDialogRef<ChangepasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogInputdata: any) {
    this.caption = this.dialogInputdata.caption || 'Change Password';
    this.expiredCred = this.dialogInputdata.expiredCred || false;
    this.redirectPrompt = false;
    this.userName = '';
    this.strengthIndex = 0;
  }


  ngOnInit() {
    this.creatChangePasswordForm();
    this.getUserName();
  }

  ngAfterViewInit() {
    this.translet();
  }

  translet() {
    setTimeout(() => {
      this.referenceLibraryService.transletConversion();
    }, 600);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  creatChangePasswordForm() {
    this.changePasswordForm = new FormGroup({
      old_password: new FormControl(null, [Validators.required,]),
      new_password: new FormControl(null, [Validators.required, Validators.minLength(8)]),
      confirm_new_password: new FormControl(null, [Validators.required])
    });
    this.changePasswordForm.setValidators(this.passwordMatch);
  }


  changePassword() {

    if (this.changePasswordForm.valid) {
      const changePasswordReqObj = {
        oldpassword: this.changePasswordForm.value.old_password,
        newpassword: this.changePasswordForm.value.new_password,
        username: this.expiredCred ? sessionStorage.getItem("attemptedUserName") : this.userName
      };
      if (changePasswordReqObj.username) {
        //IM-3796
        this.expiredCred ? this.changePasswordOnExpiry(changePasswordReqObj) : this.changePasswordvoluntarily(changePasswordReqObj)
      }
    }
  }

  //IM-3796 
  changePasswordvoluntarily(changePasswordReqObj) {
    this.userService.changepassword(changePasswordReqObj).subscribe(result => {
      this.response = result;
      if (this.response.status === 200) {
        this.toastr.success(this.response.message, 'Success');
        this.redirectPrompt = true;

      } else {
        this.redirectPrompt = false;
      }
    });
  }

  //IM-3796 
  changePasswordOnExpiry(changePasswordReqObj) {
    let payload = {
      newpassword: changePasswordReqObj.newpassword,
      username: changePasswordReqObj.username
    }
    this.userService.resetpassword(payload).subscribe(result => {
      this.response = result;
      if (this.response.status === 200) {
        this.toastr.success(this.response.message, 'Success');
        this.redirectPrompt = true;
      } else {
        this.redirectPrompt = false;
      }
    });
  }


  passwordMatch(group: FormGroup): any {
    if (group) {
      if (group.get("new_password").value !== group.get("confirm_new_password").value) {
        return { notMatching: true };
      }
    }

    return null;
  }

  validateInput(type, newpwd): boolean {
    let regex: RegExp;
    if (type == 'totalCount') {
      regex = /.{8,}$/;
    }
    else if (type == 'aA3') {
      regex = /^(?=.*[a-z])(?=.*[A-Z])[a-zA-Z\S]{3,}$/g
    }
    else if (type == 'number') {
      regex = /^(?=.*\d)/g;
    }
    else if (type == 'specChar') {
      regex = /^(?=.*\W)(?!.*\s)/g;
    }

    this.setScore(newpwd.value);

    let str = newpwd.value;
    let res = str.match(regex);
    if (res) {
      return true;
    }
    else {
      return false;
    }
  }

  setScore(stri) {
    let str = stri;
    let score = 0;
    if (str.match(/.{8,}$/)) {
      score = score + 1;
    }
    if (str.match(/^(?=.*[a-z])(?=.*[A-Z])[a-zA-Z\S]{3,}$/g)) {
      score = score + 1;
    }
    if (str.match(/^(?=.*\d)/g)) {
      score = score + 1;
    }
    if (str.match(/^(?=.*\W)(?!.*\s)/g)) {
      score = score + 1;
    }
    this.strengthIndex = score;
  }

  getStrengthMeterDetails(type) {
    let _type = type;
    let _color = ['', 'rgb(251,132,68)', 'rgb(228,196,26)', 'rgb(26,210,228)', 'rgb(27,228,26)'];
    let _width = ['0%', '25%', '50%', '75%', '100%'];
    let _strength = ['', 'weak', 'fair', 'good', 'strong'];
    if (_type == 'color') {
      return _color[this.strengthIndex];
    }
    else if (_type == 'width') {
      return _width[this.strengthIndex];
    }
    else if (_type == 'strength') {
      return _strength[this.strengthIndex];
    }
  }

  handleLogout() {
    this.dialogRef.close();
    this.authenticationService.logout().subscribe(data => {
      if(data && data['status'] === 200){
        this.authenticationService.clearUserData();
        this.router.navigate(['/login']);
      }
   });
  }

  getUserName() {
    !this.expiredCred ? this.userRoleService.getUserInfo().subscribe(response => {
      this.userName = response['userName'];
    }) : this.userName = '';
  }


}
