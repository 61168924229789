import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { pluck, map, take } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { ImagilityBaseResponse } from 'app-models';
import { saveAs } from 'file-saver';
import { EncryptService } from '../core/http/http-encrypt.service'; 
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class I9EverificationService {
  encriptedData:any;

  constructor(private httpClient: HttpClient, private encryptService: EncryptService) { } 

// Initions screen E-VERIFY
getRemoteI9Verification(companyId, payload) {
  return this.httpClient.post(`/i9admin/i9EverifyList?i9AdminCompanyId=${companyId}`, payload).pipe(
    map((response: ImagilityBaseResponse) => {
      if (response.status === 200) {
        return response.data;
      }
      else {
        return null;
      }
    }));
}
// Initions screen E-VERIFY


// Upload Document
uploadDocument(companyId, beneficiaryId, formId, userId, caseNo, isVerfiedFrom, file) {
  var caseNo = caseNo.toString();
  return this.httpClient.post(`/i9admin/uploadEverifyDocument/i9Form/${companyId}/${beneficiaryId}/${formId}/${userId}/${caseNo}/${isVerfiedFrom}`, file).pipe(
    map((response: ImagilityBaseResponse) => {
      if (response.status === 200) {
        return response;
      }
      else {
        return null;
      }
    }));
}
// Upload Document

// GET Document
getDocument(companyId, beneficiaryId) {
  let payload={
    "pageNumber": 0,
    "pageSize": 0,
    "searchText": ""
  }
  return this.httpClient.post(`/i9admin/getI9EverifyDocumentList?i9AdminCompanyId=${companyId}&beneficiaryId=${beneficiaryId}`, payload).pipe(
    map((response: ImagilityBaseResponse) => {
      if (response.status === 200) {
        return response;
      }
      else {
        return null;
      }
    }));
}
// GET Document

// GET Document
deleteDocument(companyId, caseNumber, beneficiaryId, docId) {
  var caseNumber = caseNumber.toString();
  return this.httpClient.delete(`/i9admin/deleteEverifyDocument?caseNumber=${caseNumber}&i9AdminCompanyId=${companyId}&beneficiaryId=${beneficiaryId}&docId=${docId}`).pipe(
    map((response: ImagilityBaseResponse) => {
      if (response.status === 200) {
        return response;
      }
      else {
        return null;
      }
    }));
}
// GET Document



// Launch E-Verify
launchEverify(payload) {
  return this.httpClient.post(`/i9admin/everify/save`, payload).pipe(
    map((response: ImagilityBaseResponse) => {
      if (response.status === 200) {
        return response;
      }
      else {
        return null;
      }
    }));
}
// Launch E-Verify


// Save Status
saveStatus(companyId, beneficiaryId, formId, caseNo, everifiedStatus) {
  return this.httpClient.post(`/i9admin/EditEverifyDocumentStatus/${companyId}/${beneficiaryId}/${formId}/${caseNo}/${this.toEncrypt(everifiedStatus)}`, {}).pipe(
    map((response: ImagilityBaseResponse) => {
      if (response.status === 200) {
        return response;
      }
      else {
        return null;
      }
    }));
}
// GET Status


// GeT User Credential
userCredentials(beneficiaryEmployeeId: number  = null, companyId: number = null, i9AdminId: number = null, i9FormId: number = null) {
  let slug ='';
  if(beneficiaryEmployeeId && companyId && i9AdminId && i9FormId){
    slug = `?beneficiaryEmployeeId=${beneficiaryEmployeeId}&companyId=${companyId}&i9AdminId=${i9AdminId}&i9FormId=${i9FormId}`;
  }
  return this.httpClient.get(`/i9admin/getI9EverifyDetails${slug}`).pipe(
    map((response: ImagilityBaseResponse) => {
      if (response.status === 200) {
        return response;
      }
      else {
        return null;
      }
    }));
}
// GeT User Credential



// BULK UPLOAD
getListA() {
  return this.httpClient.get('/referenceLibrary/I9IDEMPLAUTHDOC').pipe(
    map((response: ImagilityBaseResponse) => {
      if (response.status === 200) {
        return response;
      }
      else {
        return null;
      }
    }));
}

getListB() {
  return this.httpClient.get('/referenceLibrary/I9IDDOC').pipe(
    map((response: ImagilityBaseResponse) => {
      if (response.status === 200) {
        return response;
      }
      else {
        return null;
      }
    }));
}

getListB18() {
  return this.httpClient.get('/referenceLibrary/I9IDDOCBLW18').pipe(
    map((response: ImagilityBaseResponse) => {
      if (response.status === 200) {
        return response;
      }
      else {
        return null;
      }
    }));
}

getListC() {
  return this.httpClient.get('/referenceLibrary/I9EMPLAUTHDOC').pipe(
    map((response: ImagilityBaseResponse) => {
      if (response.status === 200) {
        return response;
      }
      else {
        return null;
      }
    }));
}


getUploadedDataForBeneficiary(companyId, beneficiaryId){
     return this.httpClient.post(`/i9admin/getBulkUploadDocs?companyId=${companyId}&beneficiaryId=${Number(beneficiaryId)}`, {}).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
        else {
          return null;
        }
      }));
}
uploadI9BeneficiarySignature(companyId, beneficiaryId, i9FormId, evaluatorId, file) {
  return this.httpClient.post(`/i9admin/document/beneficiaries/company/${companyId}/${beneficiaryId}/${i9FormId}/${evaluatorId}/uploadSign`, file).pipe(
    map((response: ImagilityBaseResponse) => {
      if (response.status === 200) {
        return response;
      }
    })
  );
}
getI9BeneficiarySignature(evaluatorId) {
  return this.httpClient.get(`/i9admin/document/beneficiaries/${evaluatorId}/uploadSign`).pipe(
    map((response: ImagilityBaseResponse) => {
      if (response.status === 200) {
        return response;
      }
    })
  );
}
getFormDetails(companyId, beneficiaryId, sectionNo, seqNo){
  return this.httpClient.post(`/i9admin/document/beneficiaries/company/${companyId}/beneficiary/${Number(beneficiaryId)}/section/${this.toEncrypt(String(sectionNo))}/seqNo/${seqNo}`, {}).pipe(
   map((response: ImagilityBaseResponse) => {
     if (response.status === 200) {
       return response;
     }
     else {
       return null;
     }
   }));
}

saveInitiateDetails(companyId, beneficiaryId, payload) {
  return this.httpClient.post(`/i9admin/document/beneficiaries/company/${companyId}/beneficiary/${Number(beneficiaryId)}/saveSection2Initiation`, payload).pipe(
    map((response: ImagilityBaseResponse) => {
      if (response.status === 200) {
        return response;
      }
      else {
        return null;
      }
    }));
}

saveInitiateSectionThreeDetails(companyId, beneficiaryId, payload) {
  return this.httpClient.post(`/i9admin/document/beneficiaries/company/${companyId}/beneficiary/${Number(beneficiaryId)}/saveSection3Initiation`, payload).pipe(
    map((response: ImagilityBaseResponse) => {
      if (response.status === 200) {
        return response;
      }
      else {
        return null;
      }
    }));
}

getInitiateDetails(companyId, beneficiaryId, formId){
  return this.httpClient.get(`/i9admin/document/beneficiaries/company/${companyId}/beneficiary/${beneficiaryId}/I9Form/${formId}/getSection2InitiationData`).pipe(
    map((response: ImagilityBaseResponse) => {
      if (response.status === 200) {
        return response;
      }
      else {
        return null;
      }
    }));
}

getSec3InitiateDetails(companyId, beneficiaryId, formId){
  return this.httpClient.get(`/i9admin/document/beneficiaries/company/${companyId}/beneficiary/${beneficiaryId}/I9Form/${formId}/getSection3InitiationData`).pipe(
    map((response: ImagilityBaseResponse) => {
      if (response.status === 200) {
        return response;
      }
      else {
        return null;
      }
    }));
}


deleteSingleDocument(documentId) {
  return this.httpClient.delete(`/i9admin/${documentId}`).pipe(
    map((response: ImagilityBaseResponse) => {
      if (response.status === 200) {
        return response;
      }
      else {
        return null;
      }
    }));
}


updateDocumentBulkUpload(payload, companyId, benefiicaryId) {
  return this.httpClient.post(`/i9admin/updateSectionWiseDocs/${companyId}/${benefiicaryId}`, payload).pipe(
    map((response: ImagilityBaseResponse) => {
      if (response.status === 200) {
        return response;
      }
      else {
        return null;
      }
    }));
}



saveDocuments(type, payload, benefiicaryId, companyId, obj) {
  if(type == 'supportingDocument'){
    return this.httpClient.post(`/i9admin/document/beneficiaries/company/${companyId}/${benefiicaryId}/${obj.secName}/${obj.groupCode}/${obj.refCode}/${obj.issueAuth}/${obj.docNum}/${obj.expDate}/${obj.sequenceNo}/saveSection1`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
        else {
          return null;
        }
      }));
  }
  else{
    return this.httpClient.post(`/i9admin/document/beneficiaries/company/${companyId}/${benefiicaryId}/${obj.secName}/${obj.groupCode}/${obj.refCode}/${obj.sequenceNo}/${obj.empStartDt}/${obj.signDt}/saveSectionI9Form`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
        else {
          return null;
        }
      }));
  }
  
}


deleteSectionDocument(sequenceNumber, sectionName, beneficiaryId) {
  return this.httpClient.delete(`/i9admin/${sequenceNumber}/${this.toEncrypt(String(sectionName))}/${beneficiaryId}`).pipe(
    map((response: ImagilityBaseResponse) => {
      if (response.status === 200) {
        return response;
      }
      else {
        return null;
      }
    }));
}

generateDocI9(beneficiary_id, company_id) {
  return this.httpClient.get(`/document/beneficiaries/company/${beneficiary_id}/${company_id}/generateFile`).pipe(
    map((response: ImagilityBaseResponse) => {
      if (response.status === 200) {
        return response;
      }
      else {
        return null;
      }
    }));
}

generateinitiateForm(companyId, beneficiaryID, i9FormId, section, sequenceNo, isSection1Related) {
  return this.httpClient.post(`/company/${companyId}/beneficiary/${beneficiaryID}/i9form/${i9FormId}/${this.toEncrypt(String(section))}/${sequenceNo}/${isSection1Related}/generate`, {}).pipe(
    map((response: ImagilityBaseResponse) => {
      if (response.status === 200) {
        return response;
      }
      else {
        return null;
      }
    }));
}

// generateSectionThreeForm(companyId, beneficiaryID, i9FormId, section, sequenceNo) {
//   return this.httpClient.post(`/company/${companyId}/beneficiary/${beneficiaryID}/i9form/${i9FormId}/${section}/${sequenceNo}/section3Generate`, {}).pipe(
//     map((response: ImagilityBaseResponse) => {
//       if (response.status === 200) {
//         return response;
//       }
//       else {
//         return null;
//       }
//     }));
// }

generateSectionThreeForm(payload:any) {
  return this.httpClient.post(`/i9admin/Company/I9form/generate`, payload).pipe(
    map((response: ImagilityBaseResponse) => {
      if (response.status === 200) {
        return response;
      }
      else {
        return null;
      }
    }));
}




// SECTION INITIATIONS

getSectionTwoDocumentsInitiation(beneficiaryId, section, sequenceNo, isVideoVerification){
  return this.httpClient.get(`/i9admin/documents/beneficiary/${beneficiaryId}/${this.toEncrypt(String(section))}/${sequenceNo}/${isVideoVerification}`, {}).pipe(
   map((response: ImagilityBaseResponse) => {
     if (response.status === 200) {
       return response;
     }
     else {
       return null;
     }
   }));
}


saveDocumentsSectionTwoDocumentsInitiation(payload, benefiicaryId, companyId, obj) {
  return this.httpClient.post(`/i9admin/document/beneficiaries/company/${companyId}/${benefiicaryId}/${this.toEncrypt(String(obj.secName))}/${obj.groupCode}/${obj.refCode}/${this.toEncrypt(String(obj.issueAuth))}/${this.toEncrypt(String(obj.docNum))}/${obj.expDate}/${obj.sequenceNo}/saveSectionDocs`, payload).pipe(
    map((response: ImagilityBaseResponse) => {
      if (response.status === 200) {
        return response;
      }
      else {
        return null;
      }
    }));
}

deleteDocumentsSectionTwoDocument(docId) {
  return this.httpClient.delete(`/i9admin/${docId}`).pipe(
    map((response: ImagilityBaseResponse) => {
      if (response.status === 200) {
        return response;
      }
    }));
}

sendEmailToEvaluatorToVerification(companyId, payload) {
  return this.httpClient.post(`/i9admin/${companyId}/sendEmailToBulkUploadEvaluator`, payload).pipe(
    map((response: ImagilityBaseResponse) => {
      if (response.status === 200) {
        return response;
      }
      else if (response.status === 204) {
        return response;
      }
      else {
        return null;
      }
    }));
}

// GET EVALUATOR COMPANY --- INITIATE I-9 PAGE -- SECTION 2B COMPANY DROPDOWN
getEvaluatorCompaniesByBeneficiryId(beneficiaryId: number): Observable<any> {
  let getUserId = sessionStorage.getItem("userId");
  return this.httpClient.get('/company/listOfCompaniesByUserID/' + getUserId).pipe(
    map((response: ImagilityBaseResponse) => response.data)
  );
}

getBeneficiaryList(companyId, payload) {
  return this.httpClient.post(`/petitioner/dashboard/beneficiaries/company/${companyId}`, payload).pipe(
    map((response: ImagilityBaseResponse) => {
      if (response.status === 200) {
        return response.data;
      }
      else {
        return null;
      }
    }));
}

getEmployeeDetails(companyId, employeeId){
  return this.httpClient.get(`/i9admin/company/${companyId}/employee/${employeeId}`).pipe(
    pluck('data'), map((data: Array<any>) => {
      return data;
    })
)}

getCreatedEmployeeRecords(companyId, i9FormId){
  return this.httpClient.post(`/document/beneficiaries/company/${companyId}/i9Form/${i9FormId}/saveCreatedEmployeeDocuments`, {}).pipe(
    map((response: ImagilityBaseResponse) => {
      if (response.status === 200) {
        return response;
      }
      else {
        return null;
      }
    }));
}

downloadDocuments(path: string, fileName: string) {
  this.httpClient.get(path, { responseType: "arraybuffer" })
  .pipe(take(1))
  .subscribe(pdf => {
    const blob = new Blob([pdf], { type: 'application/pdf' });
    saveAs(blob, fileName);
  });
}

updateDocumentStatus(payload) {
  return this.httpClient.post(`/i9admin/updateDocStatus`, payload).pipe(
    map((response: ImagilityBaseResponse) => {
      if (response.status === 200) {
        return response;
      }
      else {
        return null;
      }
    }));
}

getZoomAccessToken(authCode) {
  return this.httpClient.get('/i9admin/getBearerToken/' + authCode).pipe(
    map((response: ImagilityBaseResponse) => response.data)
  );
}

toEncrypt(_data) {
  var pharase = 'c63a423527b24f65'
  var data = _data;
  var key  = CryptoJS.enc.Latin1.parse(pharase);
  var iv   = CryptoJS.enc.Latin1.parse(pharase);  
  var encrypted = CryptoJS.AES.encrypt(
    data,
    key,
    {iv:iv,mode:CryptoJS.mode.CBC,
      padding:CryptoJS.pad.ZeroPadding
  }).toString();

  var e64 = CryptoJS.enc.Base64.parse(encrypted);
  var eHex = e64.toString(CryptoJS.enc.Hex);

 return this.encriptedData = eHex
}
  
}
