import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CacheCountryService } from 'src/app/services/cacheCountries.service';

@Component({
  selector: 'app-petitioner-landing',
  templateUrl: './petitioner-landing.component.html',
  styleUrls: ['./petitioner-landing.component.scss']
})
export class PetitionerLandingComponent implements OnInit {
  isExternal;
  constructor(
    private cacheCountryService: CacheCountryService,
    private activeRoute: ActivatedRoute

  ) { }

  ngOnInit() {
    if (localStorage.getItem("UserToken") === null) {
      this.isExternal = true;
    }
    this.cacheCountryService.getCountry().subscribe();
    this.isExternal = localStorage.getItem("isExternal");
  }

}
