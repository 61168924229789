import { Injectable } from '@angular/core';
import { SessionRelatedServiceService } from 'app-services-library';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root' // This will make the service globally available
})
export class CacheService {

  constructor(
    private sessionRelatedServiceService: SessionRelatedServiceService
  ) { }

  // Function to force a page reload by appending a query parameter
  bustCacheAndReload() {
    if (!window.location.href.includes(`?version=${environment.version}`)) {
      window.location.href = window.location.href.split('?')[0] + `?version=${environment.version}`;
    }
    //window.location.href = window.location.href.split('?')[0] + '?' + new Date().getTime();
  }

  // Function to clear localStorage
  clearLocalStorage() {
    localStorage.clear();
  }

  // Function to clear sessionStorage
  clearSessionStorage() {
    this.sessionRelatedServiceService.clearSessionStorage();
  }

  // Function to clear cookies
  clearCookies() {
    const cookies = document.cookie.split(';');
    cookies.forEach(cookie => {
      const cookieName = cookie.split('=')[0].trim();
      document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
    });
  }

  // Function to clear cache in the service worker (if applicable) -- may be in the future
  // clearCacheUsingServiceWorker() {
  //   if ('caches' in window) {
  //     caches.keys().then(cacheNames => {
  //       cacheNames.forEach(cacheName => {
  //         caches.delete(cacheName).then(() => {
  //           console.log(`Cache ${cacheName} deleted`);
  //         });
  //       });
  //     });
  //   }
  // }
}

