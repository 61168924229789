import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { DynamicDialogConfig, DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { StepStatusUpdatePayload } from '../../utility/services/stepStatusUpdate.service';
import { TaskStep } from 'src/app/data/models/task-step.model';
import { Subject } from 'rxjs/internal/Subject';
import { getStepDetails } from '../../store/selectors/sub-task-details.selectors';
import { getStepStatusUpdate } from '../../store/selectors/step-status-update.selectors';
import { loadSubTaskDetails } from '../../store/actions/sub-task-details.actions';
import { ImagilityBaseResponse } from '../../../../data/models/response.model';
import { updatei140StepStatus, updateStepStatusToInitialState } from '../../store/actions/step-status-update.actions';
import { I140FileUploadConfiguration } from '../../../../data/models/i140UploadConfiguration';
import { NoteDialogComponent } from '../note-dialog/note-dialog.component';
import { DeleteDialogConfigurations, NoteDialogConfigurations } from '../../utility/configurations/dialog.configuration';
import { GCI140Service } from 'src/app/services/gci140.service';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';
import { environment } from '../../../../../environments/environment';
import { I140FileUploadService } from 'src/app/services/i140-file-upload.service';
import { Router } from '@angular/router';
import * as RolesRouteMap from 'app-models';
import { AppServicesLibraryService } from 'app-services-library';


@Component({
  selector: 'app-beneficiary-documents',
  templateUrl: './beneficiary-documents.component.html',
  styleUrls: ['./beneficiary-documents.component.scss']
})
export class BeneficiaryDocumentsComponent implements OnInit {
  otherDocumentsFileUploadConfigurations: I140FileUploadConfiguration;
  beneImmigrationFileUploadConfigurations: I140FileUploadConfiguration;

  disabledControls: boolean;
  typeOfButton: string; // This is required for status update button
  stepDetails: TaskStep;
  observableSubscription$ = new Subject();
  immigrationDocuments = [];
  userType: string;
  stepId: any;
  showNext: boolean = false;
  showPrev: boolean = false;

  constructor(
    private store: Store<any>,
    private dynamicDialogRef: DynamicDialogRef,
    private dynamicDialogConfig: DynamicDialogConfig,
    private toastr: ToastrService,
    public dialogService: DialogService,
    private stepStatusUpdatePayloadCostruction: StepStatusUpdatePayload,
    private gcI140Service: GCI140Service,
    private uploadService: I140FileUploadService,
    public router: Router,
    private appServicesLibraryService: AppServicesLibraryService
  ) { 
    this.showNext = this.dynamicDialogConfig.data?.showNext; 
    this.showPrev = this.dynamicDialogConfig.data?.showPrev;
  }

  ngOnInit(): void {
    this.userType = sessionStorage.getItem('userTypeRole');
    this.stepId = this.dynamicDialogConfig.data.stepId;
    // Selector for step status update
    this.store.pipe(select(getStepStatusUpdate)).pipe(takeUntil(this.observableSubscription$)).subscribe((response: ImagilityBaseResponse) => {
      if (response && response.status === 200) {
        this.toastr.success(response.message, 'Success');
        this.store.dispatch(loadSubTaskDetails({ taskId: this.stepDetails.taskId }));
      }
    });
    this.getImmigrationDocuments();
    // Get Specific step details by passing step code
    this.store.pipe(select(getStepDetails, { id: this.stepId })).pipe(takeUntil(this.observableSubscription$)).subscribe((data: TaskStep) => {
      this.typeOfButton = data.isPetitionerAction === 1 || data.isAttorneyAction === 1 ? "MARK_COMPLETE" : "MARK_SUBMIT";
      this.stepDetails = data;
      this.otherDocumentsFileUploadConfigurations = Object.assign({}, {
        disableControls: this.disabledControls,
        isDocumentTypeRequired: true,
        isDocumentUploadRequired: true,
        isViewEnabled: true,
        isDownloadEnabled: true,
        isDeleteEnabled: true,
        getApiUrl: `/superTask/${this.dynamicDialogConfig.data.superTaskId}/subTask/${this.dynamicDialogConfig.data.subTaskId}/gc/i140Case/${this.dynamicDialogConfig.data.i140CaseId}/document/category/BENOTHDOC`,
        postApiUrl: `/superTask/${this.dynamicDialogConfig.data.superTaskId}/subTask/${this.dynamicDialogConfig.data.subTaskId}/gc/i140Case/${this.dynamicDialogConfig.data.i140CaseId}/document/category/BENOTHDOC/fileCategory/`,
        deleteApiUrl: `/superTask/${this.dynamicDialogConfig.data.superTaskId}/subTask/${this.dynamicDialogConfig.data.subTaskId}/gc/i140Case/${this.dynamicDialogConfig.data.i140CaseId}/document/`,
        documentTypeDropdownCode: 'BENOTHDOC'
      });
      this.toggleControlsStatus();
      // if (this.stepDetails.stepStatusDetails.stepStatusCode === 'NEW') {
      //   this.updateStepStatus('INPROGRESS');
      // }
    });
  }

  getImmigrationDocuments() {
    this.gcI140Service.getImmigraionDoc(this.dynamicDialogConfig.data.superTaskId, this.dynamicDialogConfig.data.subTaskId,
      this.dynamicDialogConfig.data.i140CaseId, 'BENIMMDOC').subscribe((response: ImagilityBaseResponse) => {
        this.immigrationDocuments = response.data;
      });
  }

  syncBeneficiaryProfileData() {
    this.gcI140Service.syncDocument(this.dynamicDialogConfig.data.superTaskId, this.dynamicDialogConfig.data.subTaskId,
      this.dynamicDialogConfig.data.i140CaseId, 'BENIMMDOC').subscribe((response: ImagilityBaseResponse) => {
        if (response && response.data) {
          this.immigrationDocuments = response.data;
          this.toastr.success(response.message, 'Success');
          this.getImmigrationDocuments();
        }
      });
  }

  deleteImmigrationDocuments(item) {
    const deleteDialogRef = this.dialogService.open(DeleteDialogComponent, DeleteDialogConfigurations);
    deleteDialogRef.onClose.pipe(takeUntil(this.observableSubscription$)).pipe(takeUntil(this.observableSubscription$)).pipe(takeUntil(this.observableSubscription$)).pipe(takeUntil(this.observableSubscription$)).subscribe((response: boolean) => {
      if (response) {
        this.gcI140Service.deleteImmigraionDoc(this.dynamicDialogConfig.data.superTaskId, this.dynamicDialogConfig.data.subTaskId, this.dynamicDialogConfig.data.i140CaseId, item.id).pipe(takeUntil(this.observableSubscription$)).subscribe((response: ImagilityBaseResponse) => {
          this.toastr.success(response.message, 'Success');
          this.getImmigrationDocuments();
        });
      }
    });
  }



  handleSendBack(item) {
    NoteDialogConfigurations.data = {
      title: `Rejected Document - ${item.fileCategory.name} `,
      payload: {
        beneficiaryId: this.stepDetails.beneficiaryId ? this.stepDetails.beneficiaryId : null,
        companyId: this.stepDetails.companyId ? this.stepDetails.companyId : null,
        employeeId: null,
        notificationType: "DOCREJECT",
        text: null,
        title: null,
        data: JSON.stringify({
          petitionId: this.dynamicDialogConfig.data.i140CaseId,
          taskId: this.stepDetails.taskId || null,
          documentType: item.fileCategory.name,
          documentCategory: item.fileCategory.group
        }),
        channelType: "",
        notifyAllBeneficiaries: false,
        notifyAllEmployees: false,
        priority: "",
      }
    };
    this.dialogService.open(NoteDialogComponent, NoteDialogConfigurations);
  }

  handleNotification(item) {
    NoteDialogConfigurations.data = {
      title: `Missing Document - ${item.fileCategory.name} `,
      payload: {
        beneficiaryId: this.stepDetails.beneficiaryId ? this.stepDetails.beneficiaryId : null,
        companyId: this.stepDetails.companyId ? this.stepDetails.companyId : null,
        employeeId: null,
        notificationType: "DOCMISS",
        text: null,
        title: null,
        data: JSON.stringify({
          petitionId: this.dynamicDialogConfig.data.i140CaseId,
          taskId: this.stepDetails.taskId || null,
          documentType: item.fileCategory.name,
          documentCategory: item.fileCategory.group
        }),
        channelType: "",
        notifyAllBeneficiaries: false,
        notifyAllEmployees: false,
        priority: "",
      }
    };
    this.dialogService.open(NoteDialogComponent, NoteDialogConfigurations);
  }

  //Toggle controls status based on step status
  toggleControlsStatus() {
    this.disabledControls = this.appServicesLibraryService.formToBeDisabled(this.stepDetails.stepStatusDetails.stepStatusCode);
    // this.disabledControls = this.stepDetails.stepStatusDetails.stepStatusCode === "NEW" || this.stepDetails.stepStatusDetails.stepStatusCode === "COMPLETE" || this.stepDetails.stepStatusDetails.stepStatusCode === "SUBMIT" ? true : false;
    this.otherDocumentsFileUploadConfigurations = Object.assign({}, {
      disableControls: this.disabledControls,
      isDocumentTypeRequired: true,
      isDocumentUploadRequired: true,
      isViewEnabled: true,
      isDownloadEnabled: true,
      isDeleteEnabled: true,
      getApiUrl: `/superTask/${this.dynamicDialogConfig.data.superTaskId}/subTask/${this.dynamicDialogConfig.data.subTaskId}/gc/i140Case/${this.dynamicDialogConfig.data.i140CaseId}/document/category/BENOTHDOC`,
      postApiUrl: `/superTask/${this.dynamicDialogConfig.data.superTaskId}/subTask/${this.dynamicDialogConfig.data.subTaskId}/gc/i140Case/${this.dynamicDialogConfig.data.i140CaseId}/document/category/BENOTHDOC/fileCategory/`,
      deleteApiUrl: `/superTask/${this.dynamicDialogConfig.data.superTaskId}/subTask/${this.dynamicDialogConfig.data.subTaskId}/gc/i140Case/${this.dynamicDialogConfig.data.i140CaseId}/document/`,
      documentTypeDropdownCode: 'BENOTHDOC'
    });
  }

  updateStepStatus(status) {
    this.store.dispatch(updatei140StepStatus({ payload: this.stepStatusUpdatePayloadCostruction.payloadConstruction(this.stepDetails, status), subTaskId: this.stepDetails.taskId, actionCode: this.stepDetails.stepActionList[0].layoutCode }));
  }

  openDoc(item) {
    const docPath = item.fileLocation.substring(23);
    window.open(`${environment.docs}${docPath}`, '_blank');
  }

  download(file) {
    this.uploadService.downloadDocuments(environment.docs + file.fileLocation.substring(23), file.fileLocation.substring(23));
  }

  handleClose() {
    this.dynamicDialogRef.close();
  }

  ngOnDestroy() {
    this.observableSubscription$.next();
    this.observableSubscription$.complete();
    // Update the Step Status state to empty state
    this.store.dispatch(updateStepStatusToInitialState());
  }
  updateImmigration(){
    sessionStorage.setItem('bene-profile-viewMode', 'false');
    sessionStorage.setItem('listBeneficiaryId', sessionStorage.getItem('beneficiaryId'));
    let url;
      if (this.userType === RolesRouteMap.ATTORNEY) {
      url = [`${RolesRouteMap.userRoleRouteMapping[this.userType]}/attorney/beneficiary/profile/immigration`,{listview:true}];
    } else {
      url = [`${RolesRouteMap.userRoleRouteMapping[this.userType]}/beneficiaries/profile/immigration`,{listview:true}];
    }
    this.router.navigate(url, { state: { redirectUrl: this.router.url, redirectPage: 'Back to Petition Builder' } });
    sessionStorage.setItem('bene-update-experience', 'true');
    sessionStorage.removeItem('bene-update-profile');
  }
}
