import { AfterViewInit, Component, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ReferenceLibraryService } from '../../../../services/referenceLibrary.service';
import * as errorUtils from 'error-message-utility';
import { CacheCountryService } from 'src/app/services/cacheCountries.service';
import { ApiServicesService } from '../../../beneficiary/services/api-services.service';
import { select, Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';

import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { TaskStep } from 'src/app/data/models/task-step.model';
import { getStepDetails, getStepStatusUpdate, loadSubTaskDetails, updateStepStatus, updateStepStatusToInitialState, getVisaTypeCode } from 'visa-store';

import { Subject } from 'rxjs/internal/Subject';
import { take, takeUntil } from 'rxjs/operators';
import { first } from 'rxjs/internal/operators/first';
import { ImagilityBaseResponse, PrimaryData, ReferenceLibrary } from 'app-models';
import { JobDescriptionService } from './job-description.service';

import { I140FileUploadConfiguration } from 'app-models';
import { DirtyFormGuard, MarkDirtyFormAction, MarkCleanFormAction } from 'dirty-check-store';
import { StepStatusUpdatePayloadService } from 'step-status-update-payload-service';
import { combineLatest } from 'rxjs';
import { VisaExtendedState } from '../../utility/interfaces/visa-extended-state';
//import { FroalaEditorOptions } from '../../../../data/constants/froala-editor-options';
import { ConfirmationModalComponent } from 'src/app/modules/shared/modal/confirmation-modal/confirmation-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { UploadImageDialogComponent } from "../../../custom-kendo/upload-image-dialog/upload-image-dialog.component";
import { EditorComponent } from "@progress/kendo-angular-editor";
import { AppServicesLibraryService } from 'app-services-library';
import moment from 'moment';

@Component({
  selector: 'app-job-description',
  templateUrl: './job-description.component.html',
  styleUrls: ['./job-description.component.scss']
})
export class JobDescriptionComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("jobdescriptionimageupload") public jobdescriptionimageupload: UploadImageDialogComponent;
  @Output() @ViewChild("jobdescriptionkendoeditor") public jobdescriptionkendoeditor: EditorComponent;
  inputData: PrimaryData;
  jobDescriptionForm: FormGroup;
  jobDescription: any;
  listDuties: [{
    id: number,
    name: string,
    petitionId: number,
    positionId: number,
    sequenceNo: number
  }];
  subJobDuty: {
    id: number,
    duty: string,
    name: string,
  }[];
  disabledControls: boolean;
  wageUnitType: ReferenceLibrary[];
  countryList: [];
  stateList: [];
  typeOfButton: string; // This is required for status update button
  stepDetails: TaskStep;
  titleSoc: string;
  positionTitleList: any[] = [];
  degreeList: [] = [];

  observableSubscription$ = new Subject();
  formChangesSubscription: any;
  guardSubscription: any;

  addressList = [];
  address: FormArray;

  itinenaryList = [];
  itinenaryInfoList: FormArray;

  fileUploadConfigurations: I140FileUploadConfiguration;
  fields: {
    showWorkLocationTypeRadioBtn: boolean;
    showThirdPartyRadioBtn: boolean;
    disableCountryDropdown: boolean;
    showDividerBeforeAddMoreLocation: boolean;
    showAddMoreLocation: boolean;
    showExtraFieldsPositionDetails: boolean;  // IM-7341 user story
    showDeleteAddressIcon: boolean;
  } = {
      showWorkLocationTypeRadioBtn: true,
      showThirdPartyRadioBtn: true,
      disableCountryDropdown: false,
      showDividerBeforeAddMoreLocation: true,
      showAddMoreLocation: true,
      showExtraFieldsPositionDetails: false,
      showDeleteAddressIcon: true
    };

  // public options = FroalaEditorOptions.EditorOptions;
  template: {
    body: string,
  };
  statelistArrObj: {
    workAddress: {
      countryCode: string,
      stateList: any[]
    }[],
    itenanoryAddress: {
      countryCode: string,
      stateList: any[]
    }[];
  } = {
      workAddress: [],
      itenanoryAddress: []
    };
  deleteWorkLocReqArr: any[] = [];
  fieldExtrAbltyName: any;
  userType: string;
  showNext: boolean = false;
  showPrev: boolean = false;
  constructor(
    private jobDescriptionBuilder: FormBuilder,
    private store: Store<VisaExtendedState>,
    private jobDescriptionService: JobDescriptionService,
    public dynamicDialogRef: DynamicDialogRef,
    public dynamicDialogConfig: DynamicDialogConfig,
    private referenceLibraryService: ReferenceLibraryService,
    private cacheCountyService: CacheCountryService,
    private guard: DirtyFormGuard,
    private apiServiceState: ApiServicesService,
    private toastr: ToastrService,
    private stepStatusUpdatePayloadCostruction: StepStatusUpdatePayloadService,
    public dialog: MatDialog,
    private appServicesLibraryService: AppServicesLibraryService
  ) {
    this.countryList = [];
    this.stateList = [];
    this.disabledControls = false;
    this.inputData = this.dynamicDialogConfig.data;
    this.userType = sessionStorage.getItem("userTypeRole");
    this.template = {
      body: ''
    };
    
    this.showNext = this.inputData?.showNext; 
    this.showPrev = this.inputData?.showPrev;
    this.cacheCountyService.getCachedCountryList().pipe(takeUntil(this.observableSubscription$)).subscribe(data => {
      if (data.listCountries.length > 0) {
        this.countryList = data.listCountries;
      }
    });
  }

  ngOnInit() {
    console.log('this.inputData job description ',this.inputData);
    console.log('this.inputData isViewMode job description ',this.inputData['isViewMode']);
    console.log('this.inputData isCaseRequest job description ' ,this.inputData['isCaseRequest']);
    switch (this.inputData.visatype) {
      case 'L1A':
        this.fields = {
          showWorkLocationTypeRadioBtn: true,
          showThirdPartyRadioBtn: false,
          disableCountryDropdown: true,
          showDividerBeforeAddMoreLocation: true,
          showAddMoreLocation: true,
          showExtraFieldsPositionDetails: false,
          showDeleteAddressIcon: true
        };
        break;
      case 'L1B':
        this.fields = {
          showWorkLocationTypeRadioBtn: true,
          showThirdPartyRadioBtn: true,
          disableCountryDropdown: true,
          showDividerBeforeAddMoreLocation: true,
          showAddMoreLocation: true,
          showExtraFieldsPositionDetails: false,
          showDeleteAddressIcon: true
        };
        break;
      case 'E1':
      case 'E2':
      case 'E2C':
        this.fields = {
          showWorkLocationTypeRadioBtn: false,
          showThirdPartyRadioBtn: false,
          disableCountryDropdown: true,
          showDividerBeforeAddMoreLocation: false,
          showAddMoreLocation: false,
          showExtraFieldsPositionDetails: false,
          showDeleteAddressIcon: false
        };
        break;
      case 'O1A':
      case 'O1B':
        this.fields = {
          showWorkLocationTypeRadioBtn: true,
          showThirdPartyRadioBtn: true,
          disableCountryDropdown: false,
          showDividerBeforeAddMoreLocation: true,
          showAddMoreLocation: true,
          showExtraFieldsPositionDetails: false,
          showDeleteAddressIcon: true
        };
        break;
      case 'TN1':
      case 'TN2':
        this.fields = {
          showWorkLocationTypeRadioBtn: true,
          showThirdPartyRadioBtn: true,
          disableCountryDropdown: true,
          showDividerBeforeAddMoreLocation: false,
          showAddMoreLocation: false,
          showExtraFieldsPositionDetails: true,
          showDeleteAddressIcon: false
        };
        this.getPositionTitleList();
        break;
      default:
        this.fields = {
          showWorkLocationTypeRadioBtn: true,
          showThirdPartyRadioBtn: true,
          disableCountryDropdown: false,
          showDividerBeforeAddMoreLocation: true,
          showAddMoreLocation: true,
          showExtraFieldsPositionDetails: false,
          showDeleteAddressIcon: true
        };
        break;
    }

    // Selector for step status update
    // combineLatest([
    //   this.store.pipe(select(getStepStatusUpdate)),
    //   this.store.pipe(select(getVisaTypeCode))
    // ])
    //   .pipe(takeUntil(this.observableSubscription$))
    //   .subscribe((response: any[]) => {
    //     if (response[0] && response[0].status === 200 && response[1]) {
    //       //this.toastr.success(response[0].message);
    //       this.store.dispatch(loadSubTaskDetails({
    //         taskId: this.stepDetails.taskId,
    //         // visaTypeCode: response[1]
    //         visaTypeCode: this.inputData.getTaskType
    //       }));
    //     }
    //   });

    this.jobDescriptionForm = this.jobDescriptionBuilder.group({
      id: [0],
      jobTitle: ['', [Validators.required, Validators.pattern(/^[a-zA-Z ]+$/)]],
      socCode: [null],
      socTitle: [null],
      duties: this.jobDescriptionBuilder.array([]),
      address: this.jobDescriptionBuilder.array([]),
      wagesFrom: ['', [Validators.required, Validators.pattern(/^[1-9]\d*(\.\d+)?$/)]],
      wagesTo: [null],
      wageTypeCode: ['', Validators.required],
      wageTypeName: [''],
      thisFulltime: [true],
      workingHoursPerWeek: [''],
      thisPermanentPos: [],
      thisNewPos: [],
      startDate: [null, Validators.required],
      endDate: [null, Validators.required],
      workLocationId: [null],
      caseId: [],
      socCode1: [null],
      socCode2: [null],
      thisPastJob: [false],
      extraordinaryAbilityInfo: [''],
      jobRole: [null],
      otherJobPosition: [null],
      jobDutyForceDelete: [false],
      selectedCategory: [null],
      selectedRaiobtn: [0],
      jobRoleJD: [null]
    });

    // itinenaryInfoList: this.jobDescriptionBuilder.array([])
    if (['O1A', 'O1B'].includes(this.inputData.visatype)) {
      this.jobDescriptionForm.addControl('itinenaryInfoList', new FormArray([], Validators.required));
    } else if (this.jobDescriptionForm.get('itinenaryInfoList')) {
      this.jobDescriptionForm.removeControl('itinenaryInfoList');
    }

    if (['TN1', 'TN2'].includes(this.inputData.visatype)) {
      // this.jobDescriptionForm.addControl('jobRole',)
      this.jobDescriptionForm.addControl('positionTitileId', new FormControl('', Validators.required));
      this.jobDescriptionForm.addControl('jobRole', new FormControl('', Validators.pattern(/^[a-zA-Z ]+$/)));
      this.jobDescriptionForm.addControl('educationRequiredId', new FormControl('', Validators.required));
      this.jobDescriptionForm.addControl('experienceRequired',
        new FormControl('', [
          Validators.required,
          Validators.max(99), Validators.pattern(/^\d*\.?\d{0,2}$/)
        ]));
      this.jobDescriptionForm.addControl('fieldOfStudy', new FormControl(null));
      this.jobDescriptionForm.get('jobTitle').clearValidators();
      this.jobDescriptionForm.get('jobTitle').updateValueAndValidity();
    } else {
      const tnSpecificArr = ['positionTitileId', 'jobRole', 'educationRequiredId', 'experienceRequired', 'fieldOfStudy'];
      tnSpecificArr.forEach(tnSpecific => {
        if (this.jobDescriptionForm.contains(tnSpecific)) {
          this.jobDescriptionForm.removeControl(tnSpecific);
        }
      });
      this.jobDescriptionForm.get('jobTitle').setValidators(Validators.required);
      this.jobDescriptionForm.get('jobTitle').updateValueAndValidity();
    }
    // Get Specific step details by passing step code
    this.store.pipe(select(getStepDetails, { id: this.dynamicDialogConfig.data.stepId }))
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe((data: TaskStep) => {
        this.typeOfButton = data.isPetitionerAction === 1 || data.isAttorneyAction === 1 ? 'MARK_COMPLETE' : 'MARK_SUBMIT';
        this.stepDetails = data;
        this.toggleControlsStatus();
      });
    if (['O1A', 'O1B'].includes(this.inputData.visatype)) {
      // this.jobDescriptionForm.addControl('itinenaryInfoList', this.jobDescriptionBuilder.array([]));
      this.fileUploadConfigurations = Object.assign({}, {
        disableControls: this.disabledControls,
        isDocumentTypeRequired: true,
        isDocumentUploadRequired: true,
        isViewEnabled: true,
        isDownloadEnabled: true,
        isDeleteEnabled: true,
        getApiUrl: `/${this.dynamicDialogConfig.data.visatype}/${this.dynamicDialogConfig.data.caseType}/${this.dynamicDialogConfig.data.caseId}/document/category/PETDOC/`,
        postApiUrl: `/${this.dynamicDialogConfig.data.visatype}/${this.dynamicDialogConfig.data.caseType}/${this.dynamicDialogConfig.data.caseId}/document/category/PETDOC/fileCategory/`,
        deleteApiUrl: `/${this.dynamicDialogConfig.data.visatype}/${this.dynamicDialogConfig.data.caseType}/${this.dynamicDialogConfig.data.caseId}/document/`,
        documentTypeDropdownCode: 'PETDOC'
      });
    } else {
      this.fileUploadConfigurations = Object.assign({}, {
        disableControls: this.disabledControls,
        isDocumentTypeRequired: true,
        isDocumentUploadRequired: true,
        isViewEnabled: true,
        isDownloadEnabled: true,
        isDeleteEnabled: true,
        getApiUrl: `/${this.dynamicDialogConfig.data.visatype}/${this.dynamicDialogConfig.data.caseType}/${this.dynamicDialogConfig.data.caseId}/document/category/POSOFFRLTR/`,
        postApiUrl: `/${this.dynamicDialogConfig.data.visatype}/${this.dynamicDialogConfig.data.caseType}/${this.dynamicDialogConfig.data.caseId}/document/category/POSOFFRLTR/fileCategory/`,
        deleteApiUrl: `/${this.dynamicDialogConfig.data.visatype}/${this.dynamicDialogConfig.data.caseType}/${this.dynamicDialogConfig.data.caseId}/document/`,
        documentTypeDropdownCode: '',
        getDocTypeApiUrl: '/referenceLibrary/POSOFFRLTR'
      });
    }
    this.getCountries();
    this.getWageUnitType();
    this.getWageUnitTypeBenQues();
    this.updateFormControls();
    this.getJobDescription();
    // Get Specific step details by passing step code
    this.store.pipe(select(getStepDetails, { id: this.dynamicDialogConfig.data.stepId }))
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe((data: TaskStep) => {
        this.typeOfButton = data.isPetitionerAction === 1 || data.isAttorneyAction === 1 ? 'MARK_COMPLETE' : 'MARK_SUBMIT';
        this.stepDetails = data;
        this.toggleControlsStatus();
      });

      console.log('this.inputData',this.inputData);
    console.log('this.inputData isViewMode',this.inputData['isViewMode']);
    console.log('this.inputData isCaseRequest',this.inputData['isCaseRequest']);

      console.log("Job description Component :: this.inputData", this.inputData);
      console.log("Job description Component :: this.inputData", this.inputData['isViewMode']);


  }

  getAddressFormArray() {
    return (this.jobDescriptionForm.get('address') as FormArray).controls;
  }

  getItineraryFormArray() {
    return (this.jobDescriptionForm.get('itinenaryInfoList') as FormArray).controls;
  }

  ngAfterViewInit() {
    this.formChangesSubscription = this.jobDescriptionForm.valueChanges.subscribe(() => {
      if (this.jobDescriptionForm.dirty) {
        this.store.dispatch(new MarkDirtyFormAction({
          dirty: true
        }));
      } else {
        this.store.dispatch(new MarkCleanFormAction({
          dirty: false
        }));
      }
    });
  }
  openjobdescriptionimageuploaddialog() {
    this.jobdescriptionimageupload.open();
  }
  buildDutyList(item) {
    const subJobDuty = [];
    if (item.subJobDuty && item.subJobDuty.length > 0) {
      item.subJobDuty.forEach(element => {
        subJobDuty.push(this.buildSubDutyList(element));
      });
    } else if (item.subduty && item.subduty.length > 0) {
      item.subduty.forEach(element => {
        subJobDuty.push(this.buildSubDutyList(element));
      });
    }
    return this.jobDescriptionBuilder.group({
      name: item.name || item.duty,
      subJobDuty: this.jobDescriptionBuilder.array(subJobDuty)
    });
  }

  buildSubDutyList(item) {
    return this.jobDescriptionBuilder.group({
      name: item.name || item.duty
    });
  }

  handleAddUpdateDuty({ type, data, dutyIndex }) {
    if (type === 'ADD') {
      // If type is ADD then append the job duties to exisiting one
      (this.jobDescriptionForm.controls['duties'] as FormArray).push(this.buildDutyList(data));
    }
    if (type === 'UPDATE') {
      // If type is UPDATE then update the job duties
      (this.jobDescriptionForm.controls['duties'] as FormArray).setControl(dutyIndex, this.buildDutyList(data));
    }
  }

  getJobDescription() {
    this.deleteWorkLocReqArr = [];
    this.statelistArrObj = {
      workAddress: [],
      itenanoryAddress: []
    };
    this.jobDescriptionService.getJobDescription(
      this.dynamicDialogConfig.data.visatype,
      this.dynamicDialogConfig.data.caseType,
      this.dynamicDialogConfig.data.caseId).pipe(takeUntil(this.observableSubscription$)).subscribe((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          this.jobDescription = response.data;
          this.inputData.jsonInput = {jobCategory: response.data?.jobCategory,jobRole: response.data?.jobRole};
          if (['TN1', 'TN2'].includes(this.inputData.visatype)) {
            this.handlePositionChange(this.jobDescription.positionTitileId);
          }
          if (['O1A', 'O1B'].includes(this.inputData.visatype)) {
            this.fieldExtrAbltyName = this.jobDescription.fieldOfExtraordinaryAbility;
          }
          this.loadFormValues(response.data);
        } else {
          this.fileUploadConfigurations = Object.assign({}, this.fileUploadConfigurations);
          (this.jobDescriptionForm.controls['address'] as FormArray).push(this.createaddressItem());
          this.statelistArrObj.workAddress.push({
            countryCode: null,
            stateList: []
          });
          if (['O1A', 'O1B'].includes(this.inputData.visatype)) {
            (this.jobDescriptionForm.controls['itinenaryInfoList'] as FormArray).push(this.createitinenaryInfoItem());
          }
          this.statelistArrObj.itenanoryAddress.push({
            countryCode: null,
            stateList: []
          });
          if (['H1-B', 'L1A', 'L1B', 'E1', 'E2', 'E2C', 'TN1', 'TN2'].includes(this.inputData.visatype)) {
            this.handleCountryChange('USA', 0, 'workAddress');
          }
          this.statelistArrObj.itenanoryAddress.push({
            countryCode: null,
            stateList: []
          });
        }
      });
  }

  // Load job Desc form with values
  loadFormValues(response) {
    const itinenaryInfoList =
      ['O1A', 'O1B'].includes(this.inputData.visatype) &&
        response.itinenaryInfoList && response.itinenaryInfoList.length > 0 ?
        response.itinenaryInfoList.map(x => {
          return {
            ...x,
            startDate: x.startDate ?
              moment(x.startDate).utc().toDate() : null,
            endDate: x.endDate ?
            moment(x.endDate).utc().toDate() : null
          };
        }) : [];
    response = {
      ...response,
      startDate: response.startDate ? moment(response.startDate).utc().toDate() : null,
      endDate: response.endDate ? moment(response.endDate).utc().toDate() : null,
      itinenaryInfoList
    };
    // removing itinenaryInfoList from above is giving error - Swati if u can check pls
    this.jobDescriptionForm.reset({
      ...response,
      selectedRaiobtn: 0,
      jobRoleJD: null
    });
    this.loadDuties(response.duties);
    this.loadAddress(response.address);
    if (['O1A', 'O1B'].includes(this.inputData.visatype)) {
      this.loadItinenaryInfo(response.itinenaryInfoList);
    }
    this.template.body = response.extraordinaryAbilityInfo;

  }

  loadDuties(list) {
    (this.jobDescriptionForm.controls['duties'] as FormArray).clear();
    list.forEach(element => {
      (this.jobDescriptionForm.controls['duties'] as FormArray).push(this.buildDutyList(element));
    });
  }

  getCountries() {
    this.cacheCountyService.getCountry().pipe(takeUntil(this.observableSubscription$)).subscribe();
    this.cacheCountyService.getCachedCountryList().pipe(takeUntil(this.observableSubscription$)).subscribe(data => {
      if (data.listCountries.length > 0) {
        this.countryList = data.listCountries;
      }
    });
  }

  getWageUnitType() {
    this.referenceLibraryService.getReferenceData('PAYTYP')
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe((response: ReferenceLibrary[]) => {
        this.wageUnitType = response;
      });
  }

  getWageUnitTypeBenQues() {
    this.referenceLibraryService.getReferenceDataWithoutAuthentication('PAYTYP')
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe((response: ReferenceLibrary[]) => {
        this.wageUnitType = response;
      });
  }

  getPositionTitleList() {
    this.jobDescriptionService.getPositionTitleList(
      this.dynamicDialogConfig.data.visatype,
      this.dynamicDialogConfig.data.caseType,
      this.dynamicDialogConfig.data.caseId)
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe((response: ImagilityBaseResponse) => {
        if (response && response.status === 200) {
          this.positionTitleList = response.data;
        }
      });
  }

  handlePositionChange(positionId) {

    const selectedPosition = this.positionTitleList.find(x => x.id === positionId);
    const minYears = selectedPosition && selectedPosition.minYearsExpReqd ? selectedPosition.minYearsExpReqd : 0;
    this.setDynamicValidator('experienceRequired', minYears);

    this.jobDescriptionService.getDegreeListByPosition(
      this.dynamicDialogConfig.data.visatype,
      this.dynamicDialogConfig.data.caseType,
      this.dynamicDialogConfig.data.caseId, positionId)
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          this.degreeList = response.data;

        }
      });

  }

  setDynamicValidator(field, minYears) {
    this.jobDescriptionForm.get(field).setValidators([Validators.required, Validators.min(minYears), Validators.max(99), Validators.pattern(/^\d*\.?\d{0,2}$/)]);
    this.jobDescriptionForm.get(field).updateValueAndValidity();
  }

  handleDataChange(event: any) {
    if (!event.checked) {
      this.jobDescriptionForm.get('workingHoursPerWeek').reset();
    }
  }

  handleDutyDelete(i: number) {
    const title = 'Confirm Deletion!';
    const message = 'Do you wish to remove this item?';
    this.dialogueInitializer(title, message).afterClosed()
      .subscribe(result => {
        if (result) {
          this.dutiesDelete(i);
        }
      });
  }

  handleDeleteAllDuties() {
    const title = 'Confirm Deletion!';
    const message = 'Do you wish to remove this item?';
    this.dialogueInitializer(title, message).afterClosed().subscribe(result => {
      if (result) {
        this.alldutiesDelete();
      }
    });
  }

  handleRemoveWorkClose(ind) {
    const workLocationId = (this.jobDescriptionForm.controls['address'] as FormArray).controls[ind].get('workLocationId').value;
    if (workLocationId !== 0) {
      this.jobDescriptionService.deleteWorkLocationItem(
        this.dynamicDialogConfig.data.visatype,
        this.dynamicDialogConfig.data.caseType,
        this.dynamicDialogConfig.data.caseId,
        workLocationId
      ).pipe(takeUntil(this.observableSubscription$)).subscribe((response: ImagilityBaseResponse) => {
        if (response && response.status === 200) {
          this.toastr.success(response.message, 'Success');
          this.statelistArrObj.workAddress.splice(ind, 1);
          this.getJobDescription();
        }
      });
    } else {

      const title = 'Confirm Deletion!';
      const message = 'Do you wish to remove this item?';
      this.dialogueInitializer(title, message).afterClosed().subscribe(result => {
        if (result) {
          ((this.jobDescriptionForm.controls['address'] as FormArray).removeAt(ind));
          this.toastr.success('Deleted Successfully', 'Success')
          this.statelistArrObj.workAddress.splice(ind, 1);
        }
      });

    }
  }

  handleRemoveItineraryClose(ind) {
    const intinenaryId = (this.jobDescriptionForm.controls['itinenaryInfoList'] as FormArray).controls[ind].get('itinenaryId').value;
    if (intinenaryId !== 0) {
      this.jobDescriptionService.deleteItineraryItem(
        this.dynamicDialogConfig.data.visatype,
        this.dynamicDialogConfig.data.caseType,
        this.dynamicDialogConfig.data.caseId,
        intinenaryId
      ).pipe(takeUntil(this.observableSubscription$))
        .subscribe((response: ImagilityBaseResponse) => {
          if (response && response.status === 200) {
            this.toastr.success(response.message, 'Success');
            this.statelistArrObj.itenanoryAddress.splice(ind, 1);
            this.getJobDescription();
          }
        });
    } else {
      ((this.jobDescriptionForm.controls['itinenaryInfoList'] as FormArray).removeAt(ind));
      this.statelistArrObj.itenanoryAddress.splice(ind, 1);
    }
  }

  handleSubDutyDelete({ dutyIndex, subDutyIndex }) {
    const title = 'Confirm Deletion!';
    const message = 'Do you wish to remove this item?';
    this.dialogueInitializer(title, message).afterClosed()
      .subscribe(result => {
        if (result) {
          this.subdutiesDelete({ dutyIndex, subDutyIndex });
        }
      });
  }

  updateFormControls() {
    this.jobDescriptionForm.get('thisFulltime').valueChanges.pipe(takeUntil(this.observableSubscription$)).subscribe((value) => {
      if (!value) {
        this.jobDescriptionForm.get('workingHoursPerWeek').setValidators(Validators.required);
      } else {
        this.jobDescriptionForm.get('workingHoursPerWeek').clearValidators();
      }
      this.jobDescriptionForm.get('workingHoursPerWeek').reset();
      this.jobDescriptionForm.get('workingHoursPerWeek').updateValueAndValidity();
    });
  }

  prePostJobDescription() {
    if (this.deleteWorkLocReqArr.length > 0) {
      combineLatest(this.deleteWorkLocReqArr)
        .pipe(take(1))
        .subscribe((response: ImagilityBaseResponse[]) => {
          this.postJobDescription();
        });
    } else {
      this.postJobDescription();
    }
  }

  postJobDescription() {
    const { jobRoleJD, selectedRaiobtn, ...payload } = {
      ...this.jobDescriptionForm.value,
      startDate: this.jobDescriptionForm.value.startDate ? moment(this.jobDescriptionForm.value.startDate).utc(true).format('YYYY-MM-DD') : null,
      endDate: this.jobDescriptionForm.value.endDate ? moment(this.jobDescriptionForm.value.endDate).utc(true).format('YYYY-MM-DD') : null,
      thisPastJob: false,
      caseId: this.dynamicDialogConfig.data.caseId,
      selectedRaiobtn: this.jobDescriptionForm.value.selectedRaiobtn,
      jobRoleJD: this.jobDescriptionForm.value.jobRoleJD,
      workLocationTypeCode: this.jobDescriptionForm.value.address && this.jobDescriptionForm.value.address.length > 0 ? this.jobDescriptionForm.value.address[0].workLocationTypeCode : null,
      workLocationId: this.jobDescriptionForm.value.address && this.jobDescriptionForm.value.address.length > 0 ? this.jobDescriptionForm.value.address[0].workLocationId : null
    };
    payload['jobRole'] = jobRoleJD;
    
    if (['O1A', 'O1B'].includes(this.inputData.visatype)) {
      payload.extraordinaryAbilityInfo = this.template.body;
    }
    const APIs = [
      this.jobDescriptionService.postJobDescription(payload,
        this.dynamicDialogConfig.data.visatype,
        this.dynamicDialogConfig.data.caseType,
        this.dynamicDialogConfig.data.caseId)
    ];
    if (selectedRaiobtn === 1) {
      const selectJobRole = JSON.parse(sessionStorage.getItem('selectedJobRole'));
      const jobDuty = this.jobDescriptionForm.value.duties ?
        this.jobDescriptionForm.value.duties.map(x => {
          return {
            dutyId: 0,
            duty: x.name,
            subduty: x.subJobDuty && x.subJobDuty.length > 0 ?
              x.subJobDuty.map(y => {
                return {
                  dutyId: 0,
                  duty: y.name,
                };
              }) : []
          };
        }) : [];
      const jobRoleConst = {
        id: selectJobRole.id,
        jobDuty,
        jobRoleName: selectJobRole.jobTitle === 'Other' ? this.jobDescriptionForm.value.otherJobPosition : selectJobRole.jobTitle,
        requestFlag: 'updatemasterdata'
      };
      APIs.push(this.jobDescriptionService.saveJobRole(this.inputData.companyId,
        this.jobDescriptionForm.value.selectedCategory.id, jobRoleConst));
    }
    combineLatest(APIs)
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe((response: any[]) => {
        if (response[0] && response[0].status === 200) {
          this.getJobDescription();
          this.toastr.success(response[0].message, 'Success');
          if (this.stepDetails.stepStatusDetails.stepStatusCode === 'NEW') {
            this.updateStepStatus('INPROGRESS');
          }
        }
        if (selectedRaiobtn === 1 && response[1] && response[1].status === 200) {
          this.toastr.success(response[1].message, 'Success');
        }
      });
  }

  updateStepStatus(status) {
    this.store.dispatch(updateStepStatus({
      payload: this.stepStatusUpdatePayloadCostruction.payloadConstruction(
        this.stepDetails, status),
      subTaskId: this.stepDetails.taskId,
      stepId: this.dynamicDialogConfig.data.stepId,
      visaType: this.inputData.caseType === 'I130' ?
        this.inputData.caseType : this.inputData.visatype
    }));
  }

  handleCountryChange(countryCode: string, addressIndex: number, addressType: string) {
    if (addressType === 'workAddress') {
      (this.jobDescriptionForm.get('address') as FormArray).controls[addressIndex].patchValue({
        stateProvinceCode: null,
        stateProvinceName: null
      });
    } else if (addressType === 'itenanoryAddress') {
      (this.jobDescriptionForm.get('itinenaryInfoList') as FormArray).controls[addressIndex].patchValue({
        stateProvinceCode: null,
        stateProvinceName: null
      });
    }
    const workAddressCountryCodeArr = this.statelistArrObj.workAddress
      .filter(x => x.countryCode).map(x => x.countryCode);
    const itenanoryAddressCountryCodeArr = this.statelistArrObj.itenanoryAddress
      .filter(x => x.countryCode).map(x => x.countryCode);
    const countryCodeArr = Array.from(new Set([
      ...Array.from(new Set(workAddressCountryCodeArr)),
      ...Array.from(new Set(itenanoryAddressCountryCodeArr))
    ]));
    if (countryCodeArr && countryCodeArr.length > 0 && countryCodeArr.includes(countryCode)) {
      let stateList: any[];
      if (workAddressCountryCodeArr.includes(countryCode)) {
        stateList = this.statelistArrObj.workAddress.find(x => x.countryCode === countryCode).stateList;
      } else if (itenanoryAddressCountryCodeArr.includes(countryCode)) {
        stateList = this.statelistArrObj.itenanoryAddress.find(x => x.countryCode === countryCode).stateList;
      }
      this.statelistArrObj[addressType][addressIndex] = {
        countryCode,
        stateList
      };
    } else {
      this.apiServiceState.getStates(countryCode).pipe(takeUntil(this.observableSubscription$)).subscribe((states: []) => {
        this.statelistArrObj[addressType][addressIndex] = {
          countryCode,
          stateList: states
        };
        // this.stateList = states;
        // this.changeDetector.markForCheck();
      });
    }
  }
  // Toggle controls status based on step status
  toggleControlsStatus() {
    this.disabledControls = this.appServicesLibraryService.formToBeDisabled(this.stepDetails.stepStatusDetails.stepStatusCode);
    // this.disabledControls = this.stepDetails.stepStatusDetails.stepStatusCode === "NEW" || this.stepDetails.stepStatusDetails.stepStatusCode === 'COMPLETE' ||
    //   this.stepDetails.stepStatusDetails.stepStatusCode === 'SUBMIT';

    if (['O1A', 'O1B'].includes(this.inputData.visatype)) {
      this.fileUploadConfigurations = Object.assign({}, {
        disableControls: this.disabledControls,
        isDocumentTypeRequired: true,
        isDocumentUploadRequired: true,
        isViewEnabled: true,
        isDownloadEnabled: true,
        isDeleteEnabled: true,
        getApiUrl: `/${this.dynamicDialogConfig.data.visatype}/${this.dynamicDialogConfig.data.caseType}/${this.dynamicDialogConfig.data.caseId}/document/category/PETDOC/`,
        postApiUrl: `/${this.dynamicDialogConfig.data.visatype}/${this.dynamicDialogConfig.data.caseType}/${this.dynamicDialogConfig.data.caseId}/document/category/PETDOC/fileCategory/`,
        deleteApiUrl: `/${this.dynamicDialogConfig.data.visatype}/${this.dynamicDialogConfig.data.caseType}/${this.dynamicDialogConfig.data.caseId}/document/`,
        documentTypeDropdownCode: 'PETDOC'
      });
    } else {
      this.fileUploadConfigurations = Object.assign({}, {
        disableControls: this.disabledControls,
        isDocumentTypeRequired: true,
        isDocumentUploadRequired: true,
        isViewEnabled: true,
        isDownloadEnabled: true,
        isDeleteEnabled: true,
        getApiUrl: `/${this.dynamicDialogConfig.data.visatype}/${this.dynamicDialogConfig.data.caseType}/${this.dynamicDialogConfig.data.caseId}/document/category/POSOFFRLTR/`,
        postApiUrl: `/${this.dynamicDialogConfig.data.visatype}/${this.dynamicDialogConfig.data.caseType}/${this.dynamicDialogConfig.data.caseId}/document/category/POSOFFRLTR/fileCategory/`,
        deleteApiUrl: `/${this.dynamicDialogConfig.data.visatype}/${this.dynamicDialogConfig.data.caseType}/${this.dynamicDialogConfig.data.caseId}/document/`,
        documentTypeDropdownCode: '',
        getDocTypeApiUrl: '/referenceLibrary/POSOFFRLTR'
      });
    }
  }

  getGlobalErrorMessages(error: string) {
    return errorUtils.errorMessages.get(error);
  }

  handleClose() {
    this.guardSubscription = this.guard.canDeactivate().pipe(first()).subscribe((response: boolean) => {
      if (response) {
        this.dynamicDialogRef.close();
      }
      else {
        return false;
      }
    });
  }

  handleCancel() {
    this.getJobDescription();
  }

  handleWorkLocationTypeChange(workLocTypeVAl) {
    const address = this.jobDescriptionForm.controls['address'] as FormArray;
    address.value.forEach((element, i) => {
      const workLocationId = element.workLocationId;
      if (workLocationId !== 0) {
        this.deleteWorkLocReqArr.push(this.jobDescriptionService.deleteWorkLocationItem(
          this.dynamicDialogConfig.data.visatype,
          this.dynamicDialogConfig.data.caseType,
          this.dynamicDialogConfig.data.caseId,
          workLocationId
        ));
      } else {
        ((this.jobDescriptionForm.controls['address'] as FormArray).removeAt(i));
      }
    });
    this.statelistArrObj = {
      workAddress: [],
      itenanoryAddress: []
    };
    address.clear();
    address.push(this.createaddressItem(workLocTypeVAl));
    if (['L1A', 'L1B', 'TN1', 'TN2'].includes(this.inputData.visatype)) {
      this.handleCountryChange('USA', 0, 'workAddress');
    }
  }

  createaddressItem(onClickWorkLocationType?: string): FormGroup {
    const visaType = this.dynamicDialogConfig.data.visatype;
    const onClickWorkLocationTypeVal = onClickWorkLocationType ? onClickWorkLocationType : (this.jobDescriptionForm.value.address && this.jobDescriptionForm.value.address.length > 0 ? this.jobDescriptionForm.value.address[0].workLocationTypeCode:(visaType && ['L1A', 'E1', 'E2', 'E2C', 'TN1', 'TN2', 'O1A', 'O1B'].includes(visaType) ? 'INHSE' : null))
    return this.jobDescriptionBuilder.group({
      id: [0],
      addressLine1: ['', Validators.required],
      addressLine2: [''],
      addressLine2NoType: [null],
      addressTypeCode: 'WORK',
      beneficiaryId: [null],
      countryCode: [visaType && ['L1A', 'L1B', 'E1', 'E2', 'E2C', 'TN1', 'TN2'].includes(visaType) ? 'USA' : null],
      countryName: [null],
      doorNo: [null],
      locality: [null],
      state: [null],
      stateProvinceCode: [null],
      stateProvinceName: [null],
      streetNo: [null],
      city: ['', Validators.required],
      postCode: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9 ]+$/)]],
      workLocationTypeCode: [onClickWorkLocationTypeVal],
      clientName: [''],
      clientEmail: ['', [Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)]],
      clientPhoneNo: ['', Validators.pattern(/^-?(0|[0-9]\d*)?$/)],
      type: [null],
      familyId: [null],
      companyId: [null],
      employeeId: [null],
      immigrationId: [null],
      workLocationId: [0],
      clientPhoneCode: [visaType && ['L1A', 'L1B', 'TN1', 'TN2'].includes(visaType) ? 'USA' : null],
      sequenceNo: ['0']
    });
  }

  createitinenaryInfoItem(): FormGroup {
    const visaType = this.dynamicDialogConfig.data.visatype;
    return this.jobDescriptionBuilder.group({
      addressId: [0],
      addressLine1: ['', Validators.required],
      addressLine2: [''],
      addressLine2NoType: [null],
      addressTypeCode: ['CURR'],
      beneficiaryId: [null],
      city: ['', Validators.required],
      companyId: [null],
      countryCode: [visaType && ['L1A', 'L1B'].includes(visaType) ? 'USA' : null],
      countryName: [null],
      doorNo: [null],
      employeeId: [null],
      endDate: [null, Validators.required],
      familyId: [null],
      immigrationId: [null],
      itinenaryId: [0],
      postCode: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9 ]+$/)]],
      sequenceNo: [0],
      startDate: [null, Validators.required],
      state: [null],
      stateProvinceCode: [null],
      stateProvinceName: [null],
      streetNo: [null],
      type: [null],
      typeOfwork: ['', Validators.required],
    });
  }

  handleAddLocationClick() {
    this.address = this.jobDescriptionForm.get('address') as FormArray;
    this.address.push(this.createaddressItem());
    this.statelistArrObj.workAddress.push({
      countryCode: null,
      stateList: []
    });
    const addressIndex = this.statelistArrObj?.workAddress?.length-1;
    this.handleCountryChange('USA', addressIndex, 'workAddress');
  }

  handleAddItinenaryClick() {
    this.itinenaryInfoList = this.jobDescriptionForm.get('itinenaryInfoList') as FormArray;
    this.itinenaryInfoList.push(this.createitinenaryInfoItem());
    this.statelistArrObj.itenanoryAddress.push({
      countryCode: null,
      stateList: []
    });
  }

  loadAddress(list) {
    const address = this.jobDescriptionForm.controls['address'] as FormArray;
    address.clear();
    this.statelistArrObj.workAddress = [];
    if (!list || (list && list.length === 0)) {
      address.push(this.createaddressItem());
      this.statelistArrObj.workAddress.push({
        countryCode: null,
        stateList: []
      });
      if (['L1A', 'L1B', 'E1', 'E2', 'E2C', 'TN1', 'TN2'].includes(this.inputData.visatype)) {
        this.handleCountryChange('USA', 0, 'workAddress');
      }
    } else {
      list.forEach((element, i) => {
        this.statelistArrObj.workAddress.push({
          countryCode: null,
          stateList: []
        });
        address.push(this.createaddressItem());
        this.handleCountryChange(element.countryCode, i, 'workAddress');
      });
      this.jobDescriptionForm.patchValue({
        address: list
      });
    }
  }
  loadItinenaryInfo(list) {
    const itinenaryInfoList = this.jobDescriptionForm.controls['itinenaryInfoList'] as FormArray;

    itinenaryInfoList.clear();
    this.statelistArrObj.itenanoryAddress = [];
    if (!list || (list && list.length === 0)) {
      itinenaryInfoList.push(this.createitinenaryInfoItem());
      this.statelistArrObj.itenanoryAddress.push({
        countryCode: null,
        stateList: []
      });
    } else {
      if (['O1A', 'O1B'].includes(this.inputData.visatype)) {
        list.forEach((element, i) => {
          this.statelistArrObj.itenanoryAddress.push({
            countryCode: null,
            stateList: []
          });
          itinenaryInfoList.push(this.createitinenaryInfoItem());
          this.handleCountryChange(element.countryCode, i, 'itenanoryAddress');
        });
        this.jobDescriptionForm.patchValue({
          itinenaryInfoList: list
        });
      }
    }
  }

  dutiesDelete(itemno) {
    (this.jobDescriptionForm.controls['duties'] as FormArray).removeAt(itemno);
  }

  alldutiesDelete() {
    (this.jobDescriptionForm.controls['duties'] as FormArray).clear();
  }

  subdutiesDelete({ dutyIndex, subDutyIndex }) {
    ((this.jobDescriptionForm.controls['duties'] as FormArray).controls[dutyIndex] as FormArray).controls['subJobDuty'].removeAt(subDutyIndex);
  }

  dialogueInitializer(title: string, message: string) {
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      width: '400px',
      data: {
        update: { title, message },
        buttons: ['No', 'Yes']
      }
    });
    return dialogRef;
  }

  ngOnDestroy() {
    this.observableSubscription$.next();
    this.observableSubscription$.complete();
    // Update the Step Status state to empty state
    this.store.dispatch(updateStepStatusToInitialState());
  }
  public jobdescriptioneditorValueChange(value: string): void {
    this.template.body = value;
  }
  reloadJobDescription(){
    this.getJobDescription();
  }
}
