import { Component, OnInit, ViewChild, ElementRef, OnDestroy, Inject } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { PageEvent } from '@angular/material/paginator';
import { debounceTime, map, filter, takeUntil, take } from 'rxjs/operators';
import { fromEvent, Subscription } from 'rxjs';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { PetitionerService } from 'src/app/services/petitioner.service';
import { ApiServicesService } from 'src/app/modules/beneficiary/services/api-services.service';
import { I9Service } from 'src/app/services/i9-service';
import moment from 'moment';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, NgForm } from '@angular/forms';
import * as Roles from 'src/app/data/constants/roles';
import { AttorneyService } from 'src/app/services/attorney.service';


@Component({
  selector: 'bulk-upload-list',
  templateUrl: './bulk-upload-list.component.html',
  styleUrls: ['./bulk-upload-list.component.scss'],
  providers: [DialogService],
})
export class BulkUploadListComponent implements OnInit, OnDestroy {

  pastDocumentsList: [];
  displayedColumns: string[] = ['fname', 'email', 'modeOfCreation', 'empName', 'startDate', 'action'];
  dataSource: MatTableDataSource<any>;
  pageSize = 25; // default
  pageIndex = 0; // default
  searchText = '';
  totalElementCount = 0;
  searchSubscription: Subscription;
  observableSubscription$ = new Subject();
  ref: DynamicDialogRef;
  isTextCleared: boolean = false;
  userId;
  companyId;
  totalRecordCount: number;
  beneficiaryStatus = 'ACTIVE';
  totalActiveRecords: number;
  totalPastRecords: number;
  totalPendingRecords: number;
  i9form:any = {};
  I9Form:boolean = false;
  loggedInUser: any;
  beneficiaryProfile:any = {};
  beneficiary_id: any;
  userType: string;
  taskDetailsSubscription$ = new Subject();
  stateList:any=[];


  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('pastDocTable', { static: true }) pastDocTable: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<UpdateInfoDialogComponent>,
    private petitionerService: PetitionerService,
    private toastr: ToastrService,
    private confirmationService: ConfirmationService,
    private router: Router,
    private route: ActivatedRoute, private authenticationService: AuthenticationService,
    public dialogService: DialogService,
    private apiService: ApiServicesService,
    private i9Service: I9Service,
    public dialog: MatDialog,
    private attorneyService: AttorneyService,
  ) { }

  ngOnInit() {
    this.userType = sessionStorage.getItem('userTypeRole');
    this.userId =  this.authenticationService.currentUserValue['userId'];
    this.companyId = this.authenticationService.currentUserValue['companyId'];
    this.searchSetUp();
    //this.getI9PastDocument();
    this.getStates();
    this.getbeneficiaryListForPetitioner();
  }

  backToDashboard() {
    this.router.navigate(['petitioner-landing/dashboard/petitioner-dashboard']);
  }

  searchSetUp() {
    this.searchSubscription = fromEvent(this.pastDocTable.nativeElement, 'keyup').pipe(
      map((event: any) => event.target.value)
      , filter(res => res.length > 2)
      , debounceTime(1000)).subscribe((text: string) => {
        if (this.dataSource.paginator) {
          this.dataSource.paginator.firstPage();
        }
        this.searchText = text.trim();
       // this.getI9PastDocument();
       this.getbeneficiaryListForPetitioner();
      });
  }

  onSearchChange(event){
    if(!event){
      this.searchText = '';
      this.pageIndex = 0;
      this.isTextCleared = true;
      //this.getI9PastDocument();
      this.getbeneficiaryListForPetitioner();
    } else {
      this.isTextCleared = false;
    }
  }

  getI9PastDocument() {
    if(this.isTextCleared){
      this.searchText = '';
    }
    const payload = {
      pageNumber: this.pageIndex,
      pageSize: this.pageSize,
      searchText: this.searchText
    };

    this.petitionerService.getI9ManageList(this.companyId, this.userId, payload).subscribe((response: any) => {
      this.pastDocumentsList = response['data']['i9DashBoardList'];
      console.log('this.pastDocumentsList>>>', this.pastDocumentsList);
      this.dataSource = new MatTableDataSource(this.pastDocumentsList);
      this.totalElementCount = (response['data']['totalElementCount'] || 0);
      this.dataSource.sort = this.sort;
    });
  }


  editPastDoc(row) {
    const queryParamsObj = {
      companyId:  row.companyId || 0,
      beneficiaryEmployeeId: row.beneficiaryEmployeeId || 0, 
      beneficiaryEmployeeFName: row.beneficiaryEmployeeFName || 'Not Available',
      beneficiaryEmail: row.beneficiaryEmail || 'Not Available',
      employeeFname: row.fname  || 'Not Available',  
      employeeLname: row.lname ? row.lname : '',
      i9FormId: row.i9FormId ? row.i9FormId : null,
      empType: row.comments == 'BULKUPLOAD' ? 'BULKUPLOAD' : 'CREATED'
    }
    this.router.navigate(['/petitioner-landing/i9-evaluators/bulk-upload-view'], { queryParams: queryParamsObj, relativeTo: this.route });
  } 

  paginationEvent(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.getbeneficiaryListForPetitioner();
    this.getI9PastDocument();
  }

  deleteI9PastDoc(item){
    this.petitionerService.deleteBulkUploadDocumentList(item.beneficiaryEmployeeId, item.companyId)
    .pipe(takeUntil(this.observableSubscription$))
    .subscribe((response) => {
      this.getI9PastDocument();
      this.getbeneficiaryListForPetitioner();
      this.toastr.success(response.message);
    });
  }

  confirmPastDocDelete(item){
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete?',
      key:'deletePastDoc',
      accept: () => {
        this.deleteI9PastDoc(item);
      },
      reject: () => {
      }
    });
  }

  ngOnDestroy() {
    this.searchSubscription.unsubscribe();
    this.observableSubscription$.next();
    this.observableSubscription$.complete();
  }
  getbeneficiaryListForPetitioner() {
    const payload = {
      pageNumber: this.pageIndex,
      recordsPerPage: this.pageSize,
      searchCriteria: '',
      searchText: this.searchText,
      sortBy: 'beneficiaryName',
      sortOrder: 'asc',
      status: [this.beneficiaryStatus]
    };
    if(this.isTextCleared){
      this.searchText = '';
    }
   
    this.apiService.getBeneficiariesListForPetitioner(this.companyId, payload)
      .pipe(takeUntil(this.taskDetailsSubscription$))
      .subscribe(result => {
        if (result) {
          const beneList = result.beneficiaryList;
          // beneList.forEach(item => {
          //   item.name = `${item.beneficiaryEmployeeFName} ${item.beneficiaryEmployeeMName || ''} ${item.beneficiaryEmployeeLName}`;
          // });

          this.totalRecordCount = this.beneficiaryStatus === 'ACTIVE' ?
            result.totalActiveRecords :
            (this.beneficiaryStatus === 'PEND' ? result.totalPendingRecords : result.totalPastRecords);
          this.totalActiveRecords = result.totalActiveRecords ? result.totalActiveRecords : 0;
          this.totalPastRecords = result.totalPastRecords ? result.totalPastRecords : 0;
          this.totalPendingRecords = result.totalPendingRecords ? result.totalPendingRecords : 0;
          this.dataSource = new MatTableDataSource(beneList);
            this.totalElementCount = result.totalRecords;
           this.dataSource.sort = this.sort;

        } else {
          this.totalRecordCount = 0;
          this.dataSource = new MatTableDataSource([]);
        }
      });
  }
  openI9Profile(data){
    sessionStorage.setItem('beneficiaryId',data.beneficiaryEmployeeId);
    this.i9Service.getI9BeneficiaryProfile(sessionStorage.getItem('beneficiaryId'))
    .pipe().subscribe((_result: any) => { 
      this.i9form.fname = _result.data.firstName;
      this.i9form.mname = _result.data.middleName || '';
      this.i9form.lname = _result.data.lastName;
      var dob = moment(_result.data.dob).format('MM/DD/YYYY');
      this.i9form.dob = new Date(dob);
      this.i9form.email = _result.data.benEmail[0].email || '';
      this.i9form.ssn = _result.data.ssn || '';
      this.i9form.mobile = _result.data.benPhone[0].phoneNo || '';
      this.i9form.address = _result.data.benAddress[0].addressLine1 || '';
      this.i9form.apartment = _result.data.benAddress[0].addressLine2 || '';
      this.i9form.city = _result.data.benAddress[0].city || '';
      this.i9form.stateProvinceName = _result.data.benAddress[0].stateProvinceName || '';
      this.i9form.stateProvinceCode = _result.data.benAddress[0].stateProvinceCode || '';
      this.i9form.zipcode = _result.data.benAddress[0].postCode || '';
      this.i9form.empStartDate = _result.data.empStartDate || '';
      this.i9form.empEndDate = _result.data.empEndDate || '';
    })
    this.I9Form = true;
  }
  
  updateInfo(row, type) {
    const dialogRef = this.dialog.open(UpdateInfoDialogComponent, {
      width: '500px',
      maxHeight: '600px',
      hasBackdrop: false,
      data: {
        beneficiary: row,
        type
      }
    });
    dialogRef.afterClosed()
      .pipe(takeUntil(this.taskDetailsSubscription$))
      .subscribe(result => {
        this.loadBeneficiaryRecordsInit();
      });
  }
  loadBeneficiaryRecordsInit() {
    try {
      
      const payload = {
        pageNumber: this.pageIndex + 1,
        recordsPerPage: this.pageSize,
        searchCriteria: '',
        searchText: this.searchText,
        sortBy: 'beneficiaryName',
        sortOrder: 'asc',
        status: [this.beneficiaryStatus]
      };
      if (this.loggedInUser.userType === Roles.ATTORNEY) {
        this.getbeneficiaryListForAttorney(payload);
      }
      else if (this.loggedInUser.userType === Roles.I9ADMIN) {
        this.getbeneficiaryListForPetitioner();
      } else {
        this.getbeneficiaryListForPetitioner();
      }
    } catch (error) {
      console.log(error);
    }
  }
  getbeneficiaryListForAttorney(payload) {

    this.attorneyService.getBeneficiariesListForAttorney(parseInt(sessionStorage.getItem('companyId')), payload)
      .pipe(takeUntil(this.taskDetailsSubscription$))
      .subscribe((result: any) => {
        if (result) {
          const beneList = result.beneficiaryList;
          beneList.forEach((item: any) => {
            item.name = `${item.beneficiaryEmployeeFName} ${item.beneficiaryEmployeeMName} ${item.beneficiaryEmployeeLName}`;
            item.beneficiaryEmployeeId = item.id;
          });
          this.totalRecordCount = this.beneficiaryStatus === 'ACTIVE' ?
            result.totalActiveRecords :
            (this.beneficiaryStatus === 'PEND' ? result.totalPendingRecords : result.totalPastRecords);
          this.totalActiveRecords = result.totalActiveRecords ? result.totalActiveRecords : 0;
          this.totalPastRecords = result.totalPastRecords ? result.totalPastRecords : 0;
          this.totalPendingRecords = result.totalPendingRecords ? result.totalPendingRecords : 0;
          this.dataSource = new MatTableDataSource(beneList);
        }
        else {
          this.totalRecordCount = 0;
          this.dataSource = new MatTableDataSource([]);
        }
      });
  }
  openInitiateI9(row){
    const queryParamsObj = {
      companyId:  row.companyId || 0,
      beneficiaryEmployeeId: row.beneficiaryEmployeeId || 0, 
      beneficiaryEmployeeFName: row.beneficiaryEmployeeFName || 'Not Available',
      beneficiaryEmail: row.beneficiaryEmail || 'Not Available',
      companyName: row.companyName || 'Not Available',
      row: row,
      i9FormId:row.i9FormId,
      email: row.beneficiaryEmail,
      confirmationSent: row?.sendConfirmation,
      meetingStatus: null, 
      meetingId: null,
      lastConfirmationAdmin: row?.lastConfirmationAdmin,
      lastConfirmationDate: row?.lastConfirmationDate,
      lastConfirmationTime: row?.lastConfirmationTime

    }
    this.router.navigate(['/petitioner-landing/i9-evaluators/evaluation-request'], { queryParams: queryParamsObj, relativeTo: this.route });
  }
  
  openUpdateEmployment(row, updateEmploymentType) {
    const queryParamsObj = {
      beneficiaryEmployeeId: row.beneficiaryEmployeeId || 0,
      companyId: row.companyId || 0,
      taskId: 0,
      i9FormId: row.i9FormId || 0
    };
    if (updateEmploymentType === 'start') {
      if (row?.isEmploymentUpdatable) {
        this.router.navigate(['../beneficiaries/update-employment/on-board'], { queryParams: queryParamsObj, relativeTo: this.route });
      } else {
        this.toastr.error(row?.updateEmploymentError);
      }
    } else {
      if (row?.i9FormId) {
        this.router.navigate(['../beneficiaries/update-employment/I-9'], { queryParams: queryParamsObj, relativeTo: this.route });
      } else {
        this.toastr.error('I9 is yet to be intitiated. Please try again, after initiating the I9.');
      }

    }

  }
  i9FormSubmit(form:NgForm){
    if(!form.valid){ 
      return;
    }


    this.beneficiaryProfile.fName = this.i9form.fname;
    this.beneficiaryProfile.mName = this.i9form.mname || '';
    this.beneficiaryProfile.lName = this.i9form.lname;
    this.beneficiaryProfile.dob = this.i9form.dob;
    this.beneficiaryProfile.phoneContacts[0].phoneNo = this.i9form.mobile;
    this.beneficiaryProfile.emailContacts[0].email = this.i9form.email;
    this.beneficiaryProfile.address[0].addressLine1 = this.i9form.address;
    this.beneficiaryProfile.address[0].addressLine2 = this.i9form.apartment || '';
    this.beneficiaryProfile.address[0].city = this.i9form.city;
    
    this.beneficiaryProfile.address[0].stateProvinceCode = this.i9form.stateProvinceCode;    
    this.beneficiaryProfile.address[0].stateProvinceName = this.i9form.stateProvinceName;

    this.beneficiaryProfile.address[0].postCode = this.i9form.zipcode;
    this.beneficiaryProfile.ssn = this.i9form.ssn || '';
    this.beneficiaryProfile.empStartDate = this.i9form.empStartDate || '';
    this.beneficiaryProfile.empEndDate = this.i9form.empEndDate || '';
    this.beneficiary_id= sessionStorage.getItem('beneficiaryId');
    
    this.i9Service.i9FormFilling(this.beneficiary_id, this.beneficiaryProfile).pipe().subscribe((result: any) => {

      this.I9Form = false;
      this.toastr.success(result.message);
    })

  }
  profileSateChange(event, value){
    var stateName = event.originalEvent.srcElement.innerText;
    this.i9form.stateProvinceCode = event.value;
    this.i9form.stateProvinceName = stateName;
  }
  getStates(){
    this.petitionerService.getStates().pipe(takeUntil(this.observableSubscription$))
    .subscribe((response: any[]) => {
      this.stateList = response;
    })
  }
}
@Component({
  selector: 'update-info-dialog',
  templateUrl: 'update-info-dialog.html',
})
export class UpdateInfoDialogComponent implements OnInit {
  communicationForm: FormGroup;
  beneficiaryValue;
  header;
  email;
  userId;
  benName;
  taskDetailsSubscription$ = new Subject();

  constructor(
    public dialogRef: MatDialogRef<UpdateInfoDialogComponent>,
    private apiService: ApiServicesService,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.beneficiaryValue = {
      id: this.data['beneficiary'].beneficiaryEmployeeId,
      type: this.data['type'],
      email: this.data['beneficiary'].communicationEmailId
      
    };
 
    this.benName = this.data['beneficiary'].beneficiaryEmployeeFName +
      this.data['beneficiary'].beneficiaryEmployeeMName + this.data['beneficiary'].beneficiaryEmployeeLName;

    switch (this.beneficiaryValue.type) {
      case 'email':
        this.header = 'Update Communication Email Id';
        break;
      case 'userId':
        this.header = 'Edit User Id';
        break;
      case 'eer':
        this.header = 'Edit Employer Employee Relationship';
        break;
    }
  }

  updateData() {
    switch (this.beneficiaryValue.type) {
      case 'email':
        const payload = {
          beneficiaryId: this.beneficiaryValue.id,
          communicationEmailId: this.beneficiaryValue.email,
          companyId: sessionStorage.getItem('companyId')
          
        };
        this.apiService.updateCommunicationEmail(payload)
          .pipe(takeUntil(this.taskDetailsSubscription$))
          .subscribe(result => {
            if (result.status === 200) {
              this.toastr.success(result.message, 'Success');
              this.dialogRef.close(false);
            }
          });
        break;
      case 'userId':
        const payload1 = {
          beneficiaryId: this.beneficiaryValue.id,
          userId: this.userId,
          companyId: sessionStorage.getItem('companyId')
        };
        this.apiService.updateUserId(payload1).subscribe(result => {
          if (result.status === 200) {
            this.toastr.success(result.message, 'Success');
            this.dialogRef.close(false);
          }
        });
        break;

      case 'eer':
        const payload2 = {
          beneficiaryId: this.beneficiaryValue.id,
          internal: true,
          companyId: sessionStorage.getItem('companyId')
        };
        this.apiService.updateModeOfCreation(payload2)
          .pipe(takeUntil(this.taskDetailsSubscription$))
          .subscribe(result => {
            if (result) {
              this.toastr.success(result.message, 'Success');
              this.dialogRef.close(false);

            }
          });
        break;
    }
  }

  ngOnInit() {

  }

  

}