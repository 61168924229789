export const ATTORNEY = 'Attorney';
export const PETITIONER = 'Petitioner';
export const BENEFICIARY = 'Beneficiary';
export const PLATFORMADMIN = 'Platform';
export const I9ADMIN = 'I9Admin';

export enum userRoleRouteMapping {
  Petitioner = 'petitioner-landing',
  Attorney = 'attorney-landing',
  Beneficiary = 'beneficiary-landing',
  Platform = 'platform-admin-landing',
  I9Admin = 'i9-admin-landing',
}
