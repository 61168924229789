<div class="">
  <div class="row">
    <div class="col-md-9 pl-0 hd-clr-ovrde">
      <!-- <h2 class="mt-4">All LCA ({{ this.totalRecordCount }})</h2> -->
      <h5 class="mt-4 heading mb-3">List of LCA </h5>
    </div>
    <div class="col-md-3 pr-0 hd-clr-ovrde">
      <div class="input-group search-input-group mt-3 mb-3">
          <input class="form-control py-2 rounded-pill mr-1 pr-5" #searchAccessListTable 
          (search)="resetSearch()" type="search" placeholder="Search, Case no. / SOC code">
          <span class="input-group-append">
              <button class="btn rounded-pill border-0 ml-n4r" type="button">
                  <i class="fa fa-search"></i>
              </button>
          </span>
      </div>
    </div>
  </div>

  <div class="">
    <table class="im-card-no-border-table table table-striped-odd im-card-table-noborder-spacing  no-brick-rt-brdr" mat-table [dataSource]="dataSource" multiTemplateDataRows>
      <ng-container
        matColumnDef="{{ column.columnDef }}"
        *ngFor="let column of columns"
      >
        <th mat-header-cell *matHeaderCellDef class="aqua">
          {{ column.header }}
        </th>
        <td mat-cell *matCellDef="let element">
          <span *ngIf="column.columnDef == 'toggle'" class="toggle-col">
            <mat-icon class="mr-1 icn">expand_more</mat-icon>
          </span>
          <span *ngIf="column.columnDef == 'status'">
            {{ element?.status?.name }}
          </span>
          <span *ngIf="column.columnDef == 'visaType'">
            {{ element?.visaType?.code }}
          </span>
          <span
            *ngIf="
              column.columnDef == 'employmentStartDate' ||
              column.columnDef == 'statusChangeDate' ||
              column.columnDef == 'employmentEndDate'
            "
          >
            {{ element[column.columnDef] | ImgDateFormat }}
          </span>
          <span
            *ngIf="
              column.columnDef !== 'visaType' &&
              column.columnDef !== 'status' &&
              column.columnDef !== 'employmentStartDate' &&
              column.columnDef !== 'employmentEndDate' &&
              column.columnDef !== 'statusChangeDate' &&
              column.columnDef !== 'toggle' 
            "
          >
            {{ element[column.columnDef] }}
          </span>
        </td>
      </ng-container>

      <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" class="inner-table-with-nopadding" [attr.colspan]="columns.length">
          <div class="example-element-detail"
            [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
          <div class="employment-box documents-margin m-4">
          <div class="row">
              <div class="col-md-4">
                <p class="para-margin">New Employment</p>
                <label class="readonly-data boldHead">{{
                  element.newEmploymentCount
                }}</label>
              </div>
              <div class="col-md-4">
                <p class="para-margin">Continuation of Employment</p>
                <label class="readonly-data">{{
                  element.continueApprovedCount
                }}</label>
              </div>
              <div class="col-md-4">
                <p class="para-margin">Change In Employment</p>
                <label class="readonly-data">{{
                  element.changeApproveCount
                }}</label>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <p class="para-margin">New Concurrent Employment</p>
                <label class="readonly-data">{{
                  element.concurrentEmploymentCount
                }}</label>
              </div>
              <div class="col-md-4">
                <p class="para-margin">Change In Employer</p>
                <label class="readonly-data">{{
                  element.employerChangeCount
                }}</label>
              </div>
              <div class="col-md-4">
                <p class="para-margin">Amended Petition</p>
                <label class="readonly-data">{{
                  element.amendedPetitionCount
                }}</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 ml-2">
              
              <select class="form-control float-left doc-sec" [(ngModel)]="fileCategory" placeholder="Select Document Type"
                name="documentType" #documentType="ngModel" (ngModelChange)="handleFileCategorySelection()">
                <option value="" [selected]="fileCategory === ''">Select Document Type</option>
                <option *ngFor="let option of docType.documents" [value]="option.fileCat">{{option.name}}</option>
              </select>
              <div class="upload-btn-wrapper"  style="cursor:pointer">
                <button type="button" [disabled]="!isFileCategory" class="btn btn-primary mb-2 float-left ml-2">Upload Document</button>
                <input #uploadDoc type="file" accept="application/pdf" (change)="uploadDocument($event.target.files)" />
              </div>
            </div>
          </div>
            <div class="row documents-margin">
              <!-- <button
                *ngFor="let item of element.documents; let i = index"
                (click)="displayDocument(item)"
                class="rect-btn mb-2"
              > -->
              <div class="col-md-12">
                <div class="doc-container" *ngFor="let item of element.documents; let i = index">
                  <button
                    (click)="displayDocument(item)"
                    class="btn btn-primary mb-2 float-left ml-2 btn-xs delete-doc-btn"
                  >
                    {{ item?.fileCategory?.description }}
                  </button>
                  <i class="fa fa-close delete-doc" (click)="deleteDoc(item)"></i>
                </div>
              </div>
            </div>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let element; columns: displayedColumns"
        class="example-element-row"
        [class.example-expanded-row]="expandedElement === element"
        (click)="
          expandedElement = expandedElement === element ? null : element;
          getRecord(element, expandedElement)
        "
      ></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: ['expandedDetail']"
        class="example-detail-row"
      ></tr>
    </table>
    <mat-paginator [pageSizeOptions]=pageSizeOptions
    [pageSize]="pageSize"
    [length]="totalRecordCount"
    (page)="paginationEvent($event)"
    ></mat-paginator>
  </div>
</div>
