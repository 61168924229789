import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from "@angular/material/table";
import { ToastrService } from 'ngx-toastr';
import { VisaDesignerService } from 'src/app/services/visa-designer.service';

@Component({
  selector: 'app-forms-we-support',
  templateUrl: './forms-we-support.component.html',
  styleUrls: ['./forms-we-support.component.scss']
})
export class FormsWeSupportComponent implements OnInit {   

  displayedColumns: string[] = ['formName', 'refCodes'];
  dataSource: MatTableDataSource<any>;
  totalRecordCount: number = 0;

  constructor(
    private visaService: VisaDesignerService,
    private toastService: ToastrService
  ) { }

  ngOnInit(): void {
    this.getSupportedFormsList();    
  }
  
  getSupportedFormsList() {
    this.visaService.getSupportedForms().subscribe({
      next: (response: any) => { // Success handler      
        let result = response.data;         
        this.totalRecordCount = result.length;        
        this.dataSource = new MatTableDataSource(result);
        // Define a custom filterPredicate to restrict search to form name and short description
        this.dataSource.filterPredicate = (result, filter: string) => {
          const dataStr = (result.formName + ' ' + result.formShortDesc + ' ' + result.refCodes).toLowerCase();
          return dataStr.indexOf(filter) !== -1; // Returns true if the filter is found
        };
      },
      error: (err: any) => { // Error handler       
       console.log('Not able to load the list')      
      }
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase();
  
    if (filterValue.length >= 2) {
      this.dataSource.filter = filterValue;
    } else {
      this.dataSource.filter = '';  // If less than 2 characters, reset the filter
    }
  }

  addSpaceAfterComma(refCodes: any): string {    
    if (Array.isArray(refCodes)) {
      return refCodes.join(', ').replace(/,/g, ', ');
    }
    return refCodes ? String(refCodes) : '';
  }
  

}
