<div *ngIf="showEditBtn" class="row">
    <div class="col-12">
        <button class="im-btn im-btn-with-icon im-btn-light btn-pos-top-right" (click)="routeToProfile()">
            <img src="../../../../../assets/images/edit.png" alt="">
             <span class="common-bene-class-for-translation">Edit</span></button>
    </div>
</div>
<div class="mt-4">
    <div class="row">
        <div class="col-md-12 p-0">
            <div class="main-timeline pt-2 pb-4 app-project-timeline">
                <div class="triangle-box position-relative text-center">
                    <div class='arrow-icon'></div>
                </div>

                <div class="timeline" *ngFor="let item of experienceData">

                    <a class="timeline-content">
                        <div class="row">
                         <!-- <div class="col-2">
                             <h3><i class="timeline-custom-icon fa fa-briefcase"></i></h3>
                         </div> -->
                         <div class="col">
                            <h3 class="title">{{item?.designation}}</h3>
                            <p class="description">
                                {{item?.companyName}}
                            </p>
                         </div>
                        </div>
                        <div class="timeline-duration ed-duration position-absolute"><span class="d-block">{{item?.startDate}}</span><span class="d-block mt-1">{{(item.endDate == 'Present')? item.endDate: item.endDate}}</span></div>
                    </a>
                    <!-- <a class="timeline-content">
                        <div class="timeline-duration"><span>{{item?.startDate}} - {{item?.endDate}}</span></div>
                        <h3 class="title">{{item?.companyName}}</h3>
                        <p class="description">
                            {{item?.clientsList}}
                        </p>
                    </a> -->
                </div>

            </div>
            <div class="rounded-area">
                <div class="rounded-area-inner"></div>
            </div>
        </div>
    </div>
</div>
