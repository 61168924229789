import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CustomQuestionsService } from './custom-questions.service';

@Component({
  selector: 'app-custom-questions',
  templateUrl: './custom-questions.component.html',
  styleUrls: ['./custom-questions.component.scss']
})
export class CustomQuestionsComponent implements OnInit {
  
  currentBeneficiaryId: any;
  currentLoadedIn: any;
  listOfQuestions: any[];
  customQues: any = {};
  isViewQuestions: any;
  isNewPortionVisible: any;
  dataResponse: any;
  isSectionStatus: any;
  isFamilyId: any;
  questionStatus: boolean = false;

  @Input() loadedIn; // Each portions (example: personal-details, education-details)
  @Input() beneficiaryId; // Beneficiary Id
  @Input() viewQuestions; // View or Edit
  @Input() isNewPortion; // New portion or Existing portion
  // @Input() isCustomTriggered; // For identify the parent data saved
  @Input() isSection; // Is sections or steps
  @Input() familyId;
  @Input()  taskId;
  @Input()  stepId;
  @Output() refreshList: EventEmitter<any> = new EventEmitter();

  observableSubscription$ = new Subject();

  constructor(private customQuestionsService: CustomQuestionsService, private toastr: ToastrService
   , private dialogRef: MatDialogRef<CustomQuestionsComponent>) { } 

  ngOnChanges(event: SimpleChanges) {
    //console.log('this.isFamilyIdevent11', event);
    if (event.loadedIn && event.loadedIn.currentValue) {
      this.currentLoadedIn = event.loadedIn.currentValue;
    }
    if (event.beneficiaryId && event.beneficiaryId.currentValue) {
      this.currentBeneficiaryId = event.beneficiaryId.currentValue;
    }
    if (event.viewQuestions && event.viewQuestions.currentValue) {
      this.isViewQuestions = event.viewQuestions.currentValue;
    }
    if (event.isNewPortion && event.isNewPortion.currentValue) {
      this.isNewPortionVisible = event.isNewPortion.currentValue;
    }
    if (event.isSection && event.isSection.currentValue) {
      this.isSectionStatus = event.isSection.currentValue;
    }
    if ((event.familyId && event.familyId.currentValue) && (event.familyId.currentValue !== event.familyId.previousValue)) {
      //console.log('this.isFamilyId---', this.isFamilyId);
      this.isFamilyId = event.familyId.currentValue;
      this.onLoadPage();
    }
  
  }

  ngOnInit(): void {
    //this.onLoadPage();
  }

  onLoadPage(){
    // After the api call below response will get
    if(this.isSectionStatus){
      let currentCompanyId;
    if(sessionStorage.getItem('companyId')){
      currentCompanyId = parseInt(sessionStorage.getItem('companyId'));
    } else {
      let custCompanyId = JSON.parse(localStorage.getItem('custCompanyId'));
      let custBeneficiaryId = JSON.parse(localStorage.getItem('custBeneficiaryId'));
      currentCompanyId = custCompanyId;
      this.currentBeneficiaryId = custBeneficiaryId;
    }

    setTimeout(() => {
      this.customQuestionsService.getQuestionList(this.currentBeneficiaryId, currentCompanyId, this.isFamilyId).pipe(takeUntil(this.observableSubscription$)).subscribe((data) => {
        if(data){
          this.dataResponse = data;
          //console.log('fffffffffffffff', this.dataResponse);
          if(!this.isNewPortionVisible){
            this.conditionalShowItems(1);
          } else {
            this.conditionalShowItems(2);
          }
        }
      });
    }, 500);
    } else {
      if(parseInt(sessionStorage.getItem('companyId')) && parseInt(sessionStorage.getItem('taskTypeId'))){
        this.customQuestionsService.getQuestionListSteps(parseInt(sessionStorage.getItem('companyId')),parseInt(sessionStorage.getItem('taskTypeId')))
        .pipe(takeUntil(this.observableSubscription$))
        .subscribe((data) => {
            if(data){
              setTimeout(() => {
                this.dataResponse = data;
                if(!this.isNewPortionVisible){
                  this.conditionalShowItems(1);
                } else {
                  this.conditionalShowItems(2);
                }
              }, 100);
              
            }
          });
      }
      
    }
  }
   
  conditionalShowItems(data){
    this.listOfQuestions = [];
    if(data==1){
      if(this.isSectionStatus){
        if(this.dataResponse.sectionList.length !== 0){
          this.conditionalFilter(this.dataResponse.sectionList);
        }
      } else {
        if(this.dataResponse.stepList.length !== 0){
          this.conditionalFilter(this.dataResponse.stepList);
        }
      }
    } else if(data==2){
      if(this.isSectionStatus){
        if(this.dataResponse.customSectionList.length !== 0){
          this.conditionalFilter(this.dataResponse.customSectionList);
        }
      } else {
        if(this.dataResponse.customStepList.length !== 0){
          this.conditionalFilter(this.dataResponse.customStepList);
        }
      }
    }
  }

  conditionalFilter(resData){
    //console.log('this.currentLoadedIn11', resData);
    //console.log('this.listOfQuestions', this.listOfQuestions);
    if(this.isSectionStatus){
      this.listOfQuestions.push(resData.find(data => data.name == this.currentLoadedIn));
      if(this.listOfQuestions.length!==0){
        this.questionStatus = true;
        resData.find(data => data.name == this.currentLoadedIn)?.questionList.forEach((item: any, i) => {
          this.generateQuestions(item);
        });
      } else {
        this.questionStatus = false;
      }
    } else {
      this.listOfQuestions.push(resData.find(data => data.stepName == this.currentLoadedIn));
      if(this.listOfQuestions.length!==0){
        this.questionStatus = true;
        resData.find(data => data.stepName == this.currentLoadedIn)?.questionList.forEach((item: any, i) => {
          this.generateQuestions(item);
        });
      } else {
        this.questionStatus = false;
      }
    }
  }

  generateQuestions(item){
    //console.log('itemmmmmmmm', item);
    if(item.answerTypeId == 'MULTISELECT'){
      this.customQues[item.questionId] = [];
      item.layOutJsonDTO.options.forEach((data, k) => {
        this.customQues[item.questionId].push({
          checked: false
        })
      });
      if(item.multiSelectAnswers){
        item.layOutJsonDTO.options.forEach((data, j) => {
          item.multiSelectAnswers.forEach((res, n) => {
           if(data==res){
             this.customQues[item.questionId][j].checked = true;
           }
          });
         });
      }
    } else {
      this.customQues[item.questionId] = item.answers;
    }

    //console.log('this.customQues', this.customQues);
  }

  onClickSave(data) {
    if(this.mapQuestions()){
      if(this.isSectionStatus){
        let currentCompanyId;
        if(sessionStorage.getItem('companyId')){
          currentCompanyId = parseInt(sessionStorage.getItem('companyId'));
        } else {
          let custCompanyId = JSON.parse(localStorage.getItem('custCompanyId'));
          let custBeneficiaryId = JSON.parse(localStorage.getItem('custBeneficiaryId'));
          currentCompanyId = custCompanyId;
          this.currentBeneficiaryId = custBeneficiaryId;
        }
        
        setTimeout(() => {
          this.customQuestionsService.saveQuestionList(this.currentBeneficiaryId, currentCompanyId, data, this.dataResponse).pipe(takeUntil(this.observableSubscription$)).subscribe((res) => {
            if(res){
              this.toastr.success(res.message, 'Success');
              this.isFamilyId = data;
              this.refreshList.emit(true);
            } 
          });
        }, 100);
      }
    } else {
      // call step API
      if(this.mapQuestions()){
        // api call steps
        //console.log('this.dataResponse.customStepList[0]', this.dataResponse.customStepList[0]);

        this.customQuestionsService.saveQuestionStepList(this.stepId,this.taskId,parseInt(sessionStorage.getItem('companyId')), this.dataResponse.customStepList[0].questionList).pipe(takeUntil(this.observableSubscription$)).subscribe((data) => {
          if(data){
            this.toastr.success(data.message, 'Success');
          } 
        });
      }
    }
  }

  mapQuestions(){
    let mapQuesData: any;
    if(this.isSectionStatus){
      if(!this.isNewPortionVisible){
        mapQuesData = this.dataResponse && this.mapQuestionnaire(this.dataResponse.sectionList);
      } else {
        mapQuesData = this.dataResponse && this.mapQuestionnaire(this.dataResponse.customSectionList);
      }
    } else {
      if(!this.isNewPortionVisible){
        mapQuesData = this.dataResponse && this.mapQuestionnaire(this.dataResponse.stepList);
      } else {
        mapQuesData = this.dataResponse && this.mapQuestionnaire(this.dataResponse.customStepList);
      }
    }

    return mapQuesData;
  }

  mapQuestionnaire(resData){
    if(this.isSectionStatus){
      for (const [key, value] of Object.entries(this.customQues)) {
        let tempQuesVal: any = value;
        resData.find(data => data.name == this.currentLoadedIn)?.questionList.forEach((item: any, i) => {
          if(item.questionId==key){
            if(item.answerTypeId == "MULTISELECT"){
              let tempMultiAns = [];
              item.layOutJsonDTO.options.forEach((val, j)=> {
                tempQuesVal.forEach((res, k)=> {
                  if(res.checked && j==k){
                    tempMultiAns.push(val);
                  }
                });
              });
              item.multiSelectAnswers = tempMultiAns;
            } else {
              item.answers = value;
            }
          }
        });
      }
    } else {
      for (const [key, value] of Object.entries(this.customQues)) {
        let tempQuesVal: any = value;
        resData.find(data => data.stepName == this.currentLoadedIn)?.questionList.forEach((item: any, i) => {
          if(item.questionId==key){
            if(item.answerTypeId == "MULTISELECT"){
              let tempMultiAns = [];
              item.layOutJsonDTO.options.forEach((val, j)=> {
                tempQuesVal.forEach((res, k)=> {
                  if(res.checked && j==k){
                    tempMultiAns.push(val);
                  }
                });
              });
              item.multiSelectAnswers = tempMultiAns;
            } else {
              item.answers = value;
            }
          }
        });
      }
    }
    return this.dataResponse;
  }

  ngOnDestroy() {
    this.observableSubscription$.next();
    this.observableSubscription$.complete();
  }

  closeModal(){
    //console.log('asa')
    this.dialogRef.close()
  }
}
