<canvas
  #canvas
  (touchstart)="startDrawing($event)"
  (touchmove)="moved($event)"
  width="557"
  height="100"
></canvas>
<p class="error-message" *ngIf="signatureNeeded">Signature is required</p>

<div class="buttons flex justify-between">
  <button type="button" (click)="clearPad()"  class="btn-secondary btn">Clear</button>
  <button type="button" (click)="savePad()" class="btn-primary btn">Submit the signature</button>
</div>
