import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, take } from 'rxjs/operators';
import { ImagilityBaseResponse } from 'src/app/data/models/response.model';
import { saveAs } from 'file-saver';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { EncryptService } from '../modules/core/http/http-encrypt.service';  
import * as CryptoJS from 'crypto-js';
@Injectable({
  providedIn: 'root'
})
export class I9Service {
  encriptedData: any;

  constructor(private http: HttpClient) { }
  onGetUploadedFile(companyId, i9FormId){
    return this.http.get(`/document/i9/company/${companyId}/i9form/${i9FormId}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        }
        else {
          return null;
        }
      })
    );
  }

  onDownload(path: string, fileName: string) {
    this.http.get(path, { responseType: 'arraybuffer' })
      .pipe(take(1))
      .subscribe(data => {
        const blob = new Blob([data], { type: 'application/pdf' });
        saveAs(blob, fileName);
      });
  }

  getWorkAuthList() {
    return this.http.get('/i9admin/getvisaTypesForGuidedDocs/GUIDEDWORKAUTHTYPE')
  }

  getGuidedDocsByAuthList(val) {
    return this.http.get(`/i9admin/getguidedDocs/${val}`)
  }

  onDeleteUploadedI9Form(i9FormId: number, companyId: number) {
    return this.http.delete(`/i9/form/company/${companyId}/i9form/${i9FormId}`);
  }

  onFileUpload(companyId, beneficiaryEmployeeId, file) {
    let APIURI = `/document/i9/company/${companyId}/beneficiary/${beneficiaryEmployeeId}   `;    
    return this.http.post(APIURI, file);
  }

 
  getI9Section1Info(companyId, i9FormId){
    return this.http.get(`/i9admin/company/${companyId}/i9form/${i9FormId}/I9Section1Info`).pipe(map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        }
        else {
          return null;
        }
      })
    );
  }

  postI9Section1Info(companyId, i9FormId, payload){
    return this.http.post(`/i9/company/${companyId}/i9form/${i9FormId}/I9Section1Info`, payload).pipe(
        map((response: ImagilityBaseResponse) => {
          if (response.status === 200) {
            return response;
          }
          else {
            return null;
          }
        })
    );
  }
  updateI9Status(i9FormId, statusCode, payload: any = {}){
    return this.http.post(`/i9/${i9FormId}/status/${statusCode}`, payload).pipe(
        map((response: ImagilityBaseResponse) => {
          if (response.status === 200) {
            return response;
          }
          else {
            return null;
          }
        })
    );
  }
  getBeneficiarySignature(beneficiaryEmployeeId) {
    let url = `/beneficiary/${beneficiaryEmployeeId}/profile/self/signPic`;    
    return this.http.get(url).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        }
        else {
          return null;
        }
      })
    );
  }
  getPreparerSignature(companyId,i9FormId) {
    let url = `/i9admin/i9/company/${companyId}/i9form/${i9FormId}/preparer/signPic`;    
    return this.http.get(url).pipe(
      map((response: ImagilityBaseResponse) => {
                if (response.status === 200) {
          return response;
        }
      })
    );
  }
  uploadPreparerSignature(companyId,i9FormId,payload) {
    return this.http.post(`/i9/company/${companyId}/i9form/${i9FormId}/preparer/signPic`, payload).pipe(
      map((data: ImagilityBaseResponse) => {
        return data;
      })
    );
  }

  uploadBeneficiarySignature(beneficiaryId, payload){
    return this.http.post(`/beneficiary/${beneficiaryId}/profile/self/signPic`, payload).pipe(
      map((data: Array<any>) => {
        return data;
      })
    );    
  }

  beneficiaryDigitalSignature(beneficiaryId,payload){
    return this.http.post(`/i9admin/beneficiary/${beneficiaryId}/profile/digitalSsignPic`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );    
  }

  getBeneficiaryFormList(formType,beneficiaryId,payload){
    return this.http.post(`/i9/form/${formType}/beneficiary/${beneficiaryId}/i9FormList`,payload).pipe(
        map((response: ImagilityBaseResponse) => {
          if (response.status) {
            return response.data;
          }
        })
      );
    } 

  fetchUploadedDocument(i9FormId,categoryName,fileCategory){
    return this.http.get(`/document/i9/${i9FormId}/category/${categoryName}/fileCategory/${fileCategory}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        }
        else {
          return null;
        }
      })
    );
  }  

  uploadDocument(companyId,i9FormId,categoryName,fileCategory, payload){
    return this.http.post(`/document/i9/company/${companyId}/i9form/${i9FormId}/category/${categoryName}/fileCategory/${fileCategory}`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        return response; // send  full data
    })
    );
  }

  getI9DiscrimintaionNotice(){
    return this.http.get(`/i9admin/getI9AntiDiscr`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        }
        else {
          return null;
        }
      })
    );
  }


  deleteDoc(i9FormId,documentId){
    return this.http.delete(`/document/i9/${i9FormId}/document/${documentId}`).pipe(
      map((data) => {
        return data;
      })
    )
  }


  // evaluator verification form

  // /api/v1/document/i9/{i9FormId}/category/{categoryName}/fileCategory/{fileCategory}

  evaluatorFileCategory(i9FormId, categoryName, fileCategory) {
    return this.http.get(`/document/i9/${i9FormId}/category/${categoryName}/fileCategory/${fileCategory}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }  

   //evaluationType
  evaluatorDocumentTypes(type) {
      return this.http.get(`/i9admin/evaluatorDocumentTypes/docType/${type}`).pipe(
        map((response: ImagilityBaseResponse) => {
          if (response.status === 200) {
            return response;
          }
        })
      );
    } 

    //evaluationDocuments
    documentsEvaluation(formId) {
      return this.http.get(`/i9admin/evaluationDocuments/form/${formId}`).pipe(
        map((response: ImagilityBaseResponse) => { 
          if (response.status === 200) {
            return response;
          }
        })
      );
    } 

 
   // http://50.19.45.219:5600/api/v1/i9admin/company/343/i9form/19/I9Section1Info
    employerAndVerifyDocInfo(companyId, beneficiaryId) {
      return this.http.get(`/i9admin/company/${companyId}/i9form/${beneficiaryId}/I9Section1Info`).pipe(
        map((response: ImagilityBaseResponse) => {
          if (response.status === 200) {
            return response;
          }
        })
      );
    } 



   // BENEFICIARY FORM FILLING FOR INITIATION
   beneficiaryFormFilling(companyId, i9FormId, payload) {
    return this.http.post(`/i9admin/company/${companyId}/i9form/${i9FormId}/I9Section1Info`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }

  sendConfirmationEmailToAdmin(payload, formId) {
    return this.http.post(`/i9admin/sendMailToI9AdminOrBeneficiary?i9FormId=${formId}`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }


  // GET API CALL FOR BENEFICIARY FORM TO KNOW EITHER FORM IS SUBMITTED OR NOT -- NEW I9

  // /i9admin/company/${companyId}/beneficiary/${beneficiaryId}/I9Section1Info (replace by rahul)
  // api/v1/i9admin/company/{companyId}/i9form/{i9FormId}/I9Section1Info
  isBeneficiaryFormSubmitted(companyId, i9FormId) {
    return this.http.get(`/i9admin/company/${companyId}/i9form/${i9FormId}/I9Section1Info`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }


  uploadI9BeneficiarySignature(beneficiaryId, file) {
    return this.http.post(`/i9admin/beneficiary/${beneficiaryId}/profile/signPic`, file).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }
  uploadI9Signature(companyId, beneficiaryId, i9FormId, evaluatorId, file) {
    return this.http.post(`/document/beneficiaries/company/${companyId}/${beneficiaryId}/${i9FormId}/${evaluatorId}/uploadSign`, file).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }
  getI9BeneficiarySignature(beneficiaryId) {
    return this.http.get(`/i9admin/beneficiary/${beneficiaryId}/profile/self/signPic`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }


//eval  file upload method
  uploadI9BeneficiaryDocument(companyId, i9FormId, fileCategory, documentCategory, file) {
    return this.http.post(`/i9admin/document/i9/company/${companyId}/i9form/${i9FormId}/category/${fileCategory}/fileCategory/${documentCategory}`, file).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }



   // On Evalutor Form -- GET Beneficiary Documents
   GetBeneficiaryDocuments(i9FormId) {
    return this.http.get(`/i9Admin/evaluationDocuments/form/${i9FormId}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }
  


// Refresh library - to get the list the categoryName and fileCategory
  getStates() {
    return this.http.get(`/countries/states?countryCode=USA`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        }
      })
    );
  }  

  getCountry() {
    return this.http.get(`/countries`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        }
      })
    );
  }

// Verify Beneficiary Screen
  getSignPicBeneficiary(beneficiaryId) {
    return this.http.get(`/i9Admin/beneficiary/${beneficiaryId}/profile/self/signPic`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        }
      })
    );
  }  

  getI9BeneficiaryDocument(i9FormId){
            return this.http.get(`/i9admin/document/i9/${i9FormId}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }

  deleteI9BeneficiaryDocument(i9FormId, documentId){
    return this.http.delete(`/i9admin/document/i9/${i9FormId}/document/${documentId}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }

  uploadI9PreparerSignature(companyId, i9FormId, file) {
        return this.http.post(`/i9admin/i9/company/${companyId}/i9form/${i9FormId}/preparer/signPic`, file).pipe(
      map((response: ImagilityBaseResponse) => {
                if (response.status === 200) {
          return response;
        }
      })
    );
  }


  ////////////////////////////////////////////////
    // i9admin/company/{companyId}/i9form/{i9FormId}/I9Section1Info
    getI9Section1Info_b(companyId, i9FormId){
      return this.http.get(`/i9admin/company/${companyId}/i9form/${i9FormId}/I9Section1Info`).pipe(
        map((response: ImagilityBaseResponse) => {
          if (response.status === 200) {
            return response.data;
          }
          else {
            return null;
          }
        })
      );
    }

    // /i9/company/${companyId}/i9form/${i9FormId}/employerAndVerifyDocInfo (previous)

    employerAndVerifyDocInfoPost(companyId, i9FormId, payload) { 
      return this.http.post(`/i9admin/company/${companyId}/i9form/${i9FormId}/I9Section1Info`, payload).pipe(
        map((response: ImagilityBaseResponse) => {
          if (response.status === 200) {
            return response;
          }
        })
      );
    }


   // /i9admin/{i9formId}/updateEvalStatus

   updateEvalStatus(i9formId, payload) { 
      return this.http.post(`/i9admin/${i9formId}/updateEvalStatus`, payload).pipe(
        map((response: ImagilityBaseResponse) => {
          if (response.status === 200) {
            return response;
          }
        })
      );
    }


    getReferenceData(referenceCode: string): Observable<any> {
      
        return this.http.get(`/referenceLibrary/${referenceCode}`).pipe(
          map((response: ImagilityBaseResponse) => {
            if (response.status === 200) {
              return response.data;
            }
          })
        );
      
    }


    // LIST BC START
    uploadListBCDcoument(companyId, i9FormId, emplVerificationDocCode, refCode, file): Observable<any> {      
      return this.http.post(`/i9admin/document/i9/company/${companyId}/i9form/${i9FormId}/category/${emplVerificationDocCode}/fileCategory/${refCode}`, file).pipe(
        map((response: ImagilityBaseResponse) => {
          if (response.status === 200) {
            return response;
          }
          else {
            return null;
          }
        })
    );
    }

    refLibraryListB(): Observable<any>{
      return this.http.get('/referenceLibrary/I9IDDOC').pipe(
        map((response: ImagilityBaseResponse) => {
          if (response.status === 200) {
            return response;
          }
        })
      );  
    }

    refLibraryListC(): Observable<any>{
      return this.http.get('/referenceLibrary/I9EMPLAUTHDOC').pipe(
        map((response: ImagilityBaseResponse) => {
          if (response.status === 200) {
            return response;
          }
        })
      );  
    }


    deleteBcDocument(formId, docId){
      let i9formsId = Number(formId);
      return this.http.delete(`/i9admin/document/i9/${i9formsId}/document/${docId}`).pipe(
        map((response: ImagilityBaseResponse) => {
          if (response.status === 200) {
            return response;
          }
        })
      );  
    }

    // LIST BC END

  //I9 Form Submit
  i9FormFilling(beneficiary_id, payload) {
    return this.http.post(`/i9admin/beneficiary/${beneficiary_id}/i9profile/`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }

  //I9 Beneficiary Profile
  getI9BeneficiaryProfile(beneficiary_id){
    return this.http.get(`/i9admin/beneficiary/${beneficiary_id}/i9profile`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
        else {
          return null;
        }
      })
    );
  }

  // SEND EMAIL TO BENEFICIARY --- INITIATE I-9 PAGE - SECTION 1
sendEmailToBeneficiariesToGetDetails(companyId, payload) {
  return this.http.post(`/i9admin/${companyId}/sendEmailToBeneficiary`, payload).pipe(
    map((response: ImagilityBaseResponse) => {
      if (response.status === 200) {
        return response;
      }
      else {
        return null;
      }
    }));
}

getI9BeneSign(evaluatorId) {
  return this.http.get(`/document/beneficiaries/${evaluatorId}/uploadSign`).pipe(
    map((response: ImagilityBaseResponse) => {
      if (response.status === 200) {
        return response;
      }
    })
  );
}

getFormDetails(companyId, beneficiaryId, sectionNo, seqNo){
  return this.http.post(`/document/beneficiaries/company/${companyId}/beneficiary/${Number(beneficiaryId)}/section/${sectionNo}/seqNo/${seqNo}`, {}).pipe(
   map((response: ImagilityBaseResponse) => {
     if (response.status === 200) {
       return response;
     }
     else {
       return null;
     }
   }));
}

//Form GET
getFormData(companyId, beneficiaryId, formId, sectionNo, seqNo){
  return this.http.get(`/i9admin/getRemoteI9VerifyDetails?companyId=`+companyId+'&beneficiaryId='+beneficiaryId+'&i9FormId='+formId+'&sectionName='+sectionNo+'&seqNo='+seqNo).pipe(
    map((response: ImagilityBaseResponse) => {
      if (response.status === 200) {
        return response;
      }
      else {
        return null;
      }
    })
  );
}
evaluateDocuments(type, payload, benefiicaryId, companyId, obj) {
  if(type == 'supportingDocument'){
    
    return this.http.post(`i9admin/document/beneficiaries/company/${companyId}/${benefiicaryId}/${obj.secName}/${obj.groupCode}/${obj.refCode}/${obj.issueAuth}/${obj.docNum}/${obj.expDate}/${obj.sequenceNo}/${obj.evalStatus}/${obj.evalFeedBack}/${obj.evalComment}/saveRemoteSectionDocs`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
        else {
          return null;
        }
      }));
  }
  else{
    return this.http.post(`/document/beneficiaries/company/${companyId}/${benefiicaryId}/${obj.secName}/${obj.groupCode}/${obj.refCode}/${obj.sequenceNo}/${obj.empStartDt}/${obj.signDt}/saveSectionI9Form`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
        else {
          return null;
        }
      }));
  }
  
} 
saveDocuments(type, payload, benefiicaryId, companyId, obj) {
  if(type == 'supportingDocument'){
    
    return this.http.post(`/document/beneficiaries/company/${companyId}/${benefiicaryId}/${obj.secName}/${obj.groupCode}/${obj.refCode}/${obj.issueAuth}/${obj.docNum}/${obj.expDate}/${obj.sequenceNo}/saveSection1`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
        else {
          return null;
        }
      }));
  }
  else{
    return this.http.post(`/document/beneficiaries/company/${companyId}/${benefiicaryId}/${obj.secName}/${obj.groupCode}/${obj.refCode}/${obj.sequenceNo}/${obj.empStartDt}/${obj.signDt}/saveSectionI9Form`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
        else {
          return null;
        }
      }));
  }
  
}
getUploadedDataForBeneficiary(companyId, beneficiaryId){
  return this.http.post(`/i9admin/getBulkUploadDocs?companyId=${companyId}&beneficiaryId=${Number(beneficiaryId)}`, {}).pipe(
   map((response: ImagilityBaseResponse) => {
     if (response.status === 200) {
       return response;
     }
     else {
       return null;
     }
   }));
}
deleteSingleDocument(documentId) {
  return this.http.delete(`/i9admin/${documentId}`).pipe(
    map((response: ImagilityBaseResponse) => {
      if (response.status === 200) {
        return response;
      }
      else {
        return null;
      }
    }));
}

updateDocumentBulkUpload(payload, companyId, benefiicaryId) {
  return this.http.post(`/i9admin/updateSectionWiseDocs/${companyId}/${benefiicaryId}`, payload).pipe(
    map((response: ImagilityBaseResponse) => {
      if (response.status === 200) {
        return response;
      }
      else {
        return null;
      }
    }));
}
getDocument(sequenceNo, section, beneficiaryId) {
  return this.http.get(`/i9admin/documents/beneficiary/${beneficiaryId}/${this.toEncrypt(String(section))}/${sequenceNo}`).pipe(
    map((response: ImagilityBaseResponse) => {
      if (response.status === 200) {
        return response;
      }
      else {
        return null;
      }
    }));
}

saveEvaluatorData(companyId, i9FormId, payload) { 
  return this.http.post(`/i9admin/company/${companyId}/i9form/${i9FormId}/remoteI9SectionInfo`, payload).pipe(
    map((response: ImagilityBaseResponse) => {
      if (response.status === 200) {
        return response;
      }
    })
  );
}

toEncrypt(_data) {
  var pharase = 'c63a423527b24f65'
  var data = _data;
  var key  = CryptoJS.enc.Latin1.parse(pharase);
  var iv   = CryptoJS.enc.Latin1.parse(pharase);  
  var encrypted = CryptoJS.AES.encrypt(
    data,
    key,
    {iv:iv,mode:CryptoJS.mode.CBC,
      padding:CryptoJS.pad.ZeroPadding
  }).toString();

  var e64 = CryptoJS.enc.Base64.parse(encrypted);
  var eHex = e64.toString(CryptoJS.enc.Hex);

 return this.encriptedData = eHex
}

}
