import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { SelectionModel } from "@angular/cdk/collections";
import { ConfirmationService } from "primeng/api";
import { ToastrService } from "ngx-toastr";
import { take } from "rxjs/operators";
import * as moment from 'moment';
import { TaskNotesService } from "src/app/services/task-notes.service";
import { CustomErrorToastComponent } from "custom-error-toast";


@Component({
  selector: "app-add-notes",
  templateUrl: "./add-notes.component.html",
  styleUrls: ["./add-notes.component.scss"],
})
export class AddNotesComponent implements OnInit {
  @Input() inputData: any;

  displayedColumns: string[] = [   
    "select",     
    "noteText",
    "timelineSteps",        
    "lastUpdatedBy",
    "lastUpdatedTime",    
    "id",
  ];
  
  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<any>(true, []);
  showAddNotesModal: boolean = false;    
  totalRecordCount: number = 0;
  noteId: null;
  note: any;
  isEditMode: boolean = false; 
  userType: string;
  companyId: string;
  

  constructor(
    private confirmationService: ConfirmationService,
    private taskNoteService: TaskNotesService,
    private toastService: ToastrService
  ) {}

  ngOnInit(): void {
    this.userType = sessionStorage.getItem('userTypeRole');
    this.companyId = sessionStorage.getItem('noteCompanyId');
    this.getNotesList();
  }

  ngOnChanges(e: SimpleChanges) {
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  showDialog() {
    this.isEditMode = false;
    this.showAddNotesModal = true;
  }

  hideDialog(){
    this.showAddNotesModal = false;
  }
  
  expandAllNotes() {
    this.dataSource.data.forEach(row => row.show = true);
  }
  
  collapseAllNotes() {
    this.dataSource.data.forEach(row => row.show = false);
  }

  getNotesList() {        
      this.taskNoteService.getAllNotes(this.userType, (this.inputData.id || this.inputData[0].taskId)).subscribe((response:any) => {             
        let result = response.data[this.userType];         
        result = result.map((note: any) => ({
          ...note,
          lastUpdatedTime: this.formatDate(note.lastUpdatedTime)
        }));   
        this.totalRecordCount = result.length;    
        this.dataSource = new MatTableDataSource(result);
        this.selection.clear();
      });    
  }

  formatDate(dateStr: string): string {    
    const cleanDateStr = dateStr.split(' (')[0];    
    return moment(cleanDateStr, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm:ss');
  }

  onSubmit(form: any) {
    if (!form.valid) return;

    const payload = {
      noteDetails: form.controls["note"].value,
      noteTitle: "",
      ...(this.isEditMode
        ? {}
        : {
            companyId: this.companyId,
            noteDetails: form.controls["note"].value,
            noteTitle: "",
            taskStepId: null,
            taskId: this.inputData.id || this.inputData[0].taskId,
          }),
    };

    const request$ = this.isEditMode
      ? this.taskNoteService.updateNotes(this.noteId, payload)
      : this.taskNoteService.savenotes((this.inputData.id || this.inputData[0].taskId), payload);

    request$.pipe(take(1)).subscribe({
      next: (result: any) => {
        if (result.status === 200) {
          this.toastService.success(result.message, 'Success');
            form.resetForm();
            this.selection.clear();
            this.hideDialog();
            this.getNotesList();        
        }
      },
      error: (err) => {
        CustomErrorToastComponent.showErrorToast(this.toastService, err);
      },
    });
  }

  editNotes(data) {
    this.showDialog();
    this.isEditMode = true;
    this.note = data.noteText;
    this.noteId = data.id;
  }

  deleteNotes(data) {
    this.confirmationService.confirm({
      header: "Delete Note",
      icon: "pi pi-info-circle",
      rejectLabel: "Cancel",
      acceptLabel: "Yes, Delete it",
      acceptButtonStyleClass: "btn-accept-delete",
      rejectButtonStyleClass: "btn-cancel-delete",
      message: `<div class="mb-4"> Are you sure. you want to delete this note? </div>
                <div class="mb-4">This action is irreversible, and the note can not be recovered</div>
                <div class="mb-4">Are you sure you want to <span class="delete-text">delete?</span></div>`,
      accept: () => {
        this.taskNoteService.deletenotes((this.inputData.id || this.inputData[0].taskId), data.id, data.timelineSteps, data.customStep).pipe(take(1))
        .subscribe({
          next: (result: any) => {
            if (result.status === 200) {
              this.toastService.success(result.message, 'Success');
            }     
            this.selection.clear();                   
            this.getNotesList();
          },
          error: (err) => {            
            CustomErrorToastComponent.showErrorToast(this.toastService, err);
          },
        });
      },
      reject: () => {},
    });
  }

  printValidation(){
    this.confirmationService.confirm({
      header: "Please select a Note",
      icon: "pi pi-info-circle",
      rejectLabel: "Cancel",
      acceptLabel: "Select Note",
      acceptButtonStyleClass: "btn-accept-note",
      rejectButtonStyleClass: "btn-cancel-note",
      message: `<div class="mb-4"> To proceed with printing, please select the notes you wish to print. </div>`,
      accept: () => {},
      reject: () => {},
    });
  }

  printSection(sectionId: string): void {    
    if(this.selection.selected.length == 0){
       this.printValidation();
    }else{    
      const section = document.getElementById(sectionId);
      const printWindow = window.open('', '', 'height=600,width=800');
      printWindow?.document.write(`
        <html>
          <head>
            <title>Print Note</title>
            <style>
              /* Add any print-specific styles here */
              @media print {
                /* Example: Hide elements you don't want to print */
                .no-print {
                  display: none;
                }
              }
            </style>
          </head>
          <body>
            ${section.innerHTML}
          </body>
        </html>
      `);
      printWindow?.document.close(); // Close the document for printing
      printWindow?.focus(); // Focus on the new window
      printWindow?.print(); // Trigger the print dialog
    }
    
  }

}
