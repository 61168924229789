// import { Component, OnInit, AfterContentInit, OnChanges, ViewChild, SimpleChanges, AfterViewInit } from '@angular/core';
// import { ActivatedRoute, Router } from '@angular/router';
// import { AuthenticationService } from 'src/app/services/authentication.service';
// import { DashboardWidgetsService } from 'src/app/services/dashboard-widgets.service';
// import { ToastrService } from 'ngx-toastr';
// import { MatTableDataSource } from '@angular/material/table';
// import { MatPaginator } from '@angular/material/paginator';
// import { MatSort } from '@angular/material/sort';
// import { NotifyCodeAction, NotifyCodeNoAction } from 'src/app/data/constants/notification-type-code';
// import { HelpVideoOverlayComponent } from '../../../../modules/shared/help-video-overlay/help-video-overlay.component';
// import * as RolesRouteMap from '../../../../data/constants/roles';
// import { take } from 'rxjs/operators';

// @Component({
//   selector: 'notification-list',
//   templateUrl: './notification-list.component.html',
//   styleUrls: ['./notification-list.component.scss']
// })
// export class NotificationListComponent implements OnInit, AfterViewInit {
//   notifications: any;
//   userType;
//   notificationCount;
//   noDataFoundMsg;
//   isEditLink: boolean = false;
//   routingPathVal: any;

//   @ViewChild(HelpVideoOverlayComponent, { static: false }) helpVideoComponent: HelpVideoOverlayComponent;


//   displayedColumns: string[] = ['notifications'];
//   dataSource: MatTableDataSource<any>;

//   @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
//   @ViewChild(MatSort, { static: true }) sort: MatSort;
//   linkreq = NotifyCodeAction.LINKREQ;
//   notificationsElements: any[] = [];

//   constructor(
//     private router: Router,
//     private route: ActivatedRoute,
//     private authenticationService: AuthenticationService,
//     private dashBoardWidgetsServices: DashboardWidgetsService,
//     private toastr: ToastrService) { }

//   ngOnInit() {
//     // Connect now button will be shown for user with permission of EDIT_LINK
//     this.authenticationService.currentUser.subscribe((data) => {
//       if (data) {
//         this.isEditLink = data['permissions'].indexOf('EDIT_LINK') !== -1;
//       }
//     });
//     this.userType = sessionStorage.getItem('userTypeRole');
//     // IM-3579 - removed check of companytype and companyid
//     this.notificationsList();

//   }

//   ngAfterViewInit() {
//     if (this.userType != 'Beneficiary') {
//       this.helpVideoComponent.setValues('right', 'How to use Notifications to collabrate?', 'How to use Notifications to collabrate?');
//     } else {
//       this.helpVideoComponent.setValues('right', 'Using Timelines and Notifications for Beneficiary', 'Using Timelines and Notifications for Beneficiary');
//     }

//   }


//   notificationsList() {
//     const payload = {};
//     this.dashBoardWidgetsServices.getNotificationListUpdated(payload)
//       .pipe(take(1))
//       .subscribe(response => {
//         if (response['status'] === 200) {
//           this.notifications = response['data'];
//           if (this.notifications.elements.length <= 0) {
//             this.notificationCount = 0;
//             this.noDataFoundMsg = "No Notifications at this time";
//           }
//           //  this.dataSource = new MatTableDataSource(this.notifications.elements);
//           this.modifyData(this.notifications.elements);
//           // this.notificationsElements = this.notifications.elements;
//           // this.dataSource.paginator = this.paginator;
//           // this.dataSource.sort = this.sort;
//           this.notificationCount = this.notifications.elements.length;
//         }
//       });
//   }

//   modifyData(notificationArr) {
//     if (notificationArr.length > 0) {
//       for (const item of notificationArr) {
//         switch (item.notificationType.code) {
//           case 'LINKREQ':
//             item.notificationLink = 'CONNECT_NOW';
//             break;
//           case 'ETA_NOTICE':
//             item.notificationLink = 'ETA_NOTICE';
//             break;
//           case 'LCA_ACKNOWLEDGEMENT_REQ':
//             item.notificationLink = 'GOTO_LCA_ACKNOWLEDGMENT';
//             break;
//           case 'LCA_POST_DECLARATION_RES':
//           case 'LCA_ACKNOWLEDGEMENT_RES':
//             item.notificationLink = 'LCA_ACK_RES';
//             break;
//           case 'ATTR_BEN_LINK':
//             item.notificationLink = 'ATTR_BEN_LINK';
//             break;
//           default:
//             if (item.notificationType.code.indexOf('I9') >= 0) {
//               item.notificationLink = 'GOTO_I9';
//             }
//             else {
//               item.notificationLink = 'GOTO_TASK';
//             }
//             break;
//         }
//       }
//       this.notificationsElements = notificationArr;
//       this.dataSource = new MatTableDataSource(this.notificationsElements);
//       this.dataSource.paginator = this.paginator;
//       this.dataSource.sort = this.sort;
//     }

//   }

//   connectNow(item) {
//     const payload = {
//       linkRequestId: item['data']['linkReqId'],
//       statusCode: 'ACTV'
//     };
//     this.dashBoardWidgetsServices.postUpdateNotificationRequest(payload).subscribe(data => {
//       (data['status'] === 200) ? this.toastr.success('', data['message']) :
//         this.toastr.error('', data['message']);
//       this.notifications = [];
//       this.notificationsList();
//     });
//   }
//   showFullNotifications() {
//     this.toastr.info('Not yet implemented');
//   }

//   // isGoTo(type,code,actioned){
//   //   if(type == 'task' && code != NotifyCodeAction.LINKREQ ){
//   //       return true;
//   //   }
//   //   else if(type == 'connect'&& code === NotifyCodeAction.LINKREQ &&
//   //           this.isEditLink && actioned === false
//   //          ){
//   //     return true;
//   //   }
//   //   else {
//   //     return false;
//   //   }
//   // }

//   // isGoTo(type,code,actioned,data?){

//   //   if(type == 'task' && code != NotifyCodeAction.LINKREQ && code != NotifyCodeAction.RFEDOCMISSRFERESPONSEREQ && code != NotifyCodeAction.RFEDOCMISS && code != NotifyCodeAction.RFERESPONSEREQ){
//   //       this.routingPathVal ='../../dashboard/task/task-details/'+ data ?.taskId;
//   //       return true;
//   //   }
//   //   else if(type == 'connect'&& code === NotifyCodeAction.LINKREQ &&
//   //           this.isEditLink && actioned === false
//   //          ){
//   //     return true;
//   //   }else if(code === NotifyCodeAction.RFEDOCMISSRFERESPONSEREQ || code === NotifyCodeAction.RFEDOCMISS || code === NotifyCodeAction.RFERESPONSEREQ){
//   //     this.routingPathVal ='../../dashboard/rfe/task-details/'+ data ?.taskId;
//   //     return true;
//   //   }
//   //   else {
//   //     return false;
//   //   }


//   // }


//   isGoToConnect(code, actioned) {
//     if (code === NotifyCodeAction.LINKREQ && this.isEditLink && actioned === false) {
//       return true;
//     } else {
//       return false;
//     }
//   }


//   isGoToTask(code, data) {
//     if (code.startsWith("RFE_") && data && data.taskId) {
//       this.routingPathVal = '../../dashboard/rfe/task-details/' + data['taskId'];
//       return true;
//     } else if (code != NotifyCodeAction.LINKREQ && data && data.taskId) {
//       this.routingPathVal = '../../dashboard/task/task-details/' + data['taskId'];
//       return true;
//     } else {
//       return false;
//     }
//   }

//   changeStatus(linkRequestId, statusCode, notificationId) {
//     this.dashBoardWidgetsServices.updateRequest(linkRequestId, statusCode, notificationId).subscribe(data => {
//       (data['status'] === 200) ? this.toastr.success('', data['message']) :
//         this.toastr.error('', data['message']);
//       this.notifications = [];
//       this.notificationsList();
//     });
//   }

//   acceptNotice(id) {
//     this.dashBoardWidgetsServices.acceptNotice(id).subscribe((response: any) => {
//       if (response.status == 200) {
//         this.toastr.success(response.message);
//         this.notificationsList();
//       }
//     });
//   }

//   ignoreNotice(id) {
//     this.dashBoardWidgetsServices.ignoreNotice(id).subscribe((response: any) => {
//       if (response.status == 200) {
//         this.toastr.success(response.message);
//         this.notificationsList();
//       }
//     });
//   }
//   navigateTask(code, row: any) {
//     if (row.data && row.data.superTaskId) {
//       this.router.navigate([`../${RolesRouteMap.userRoleRouteMapping[sessionStorage.getItem('userTypeRole')]}/dashboard/superTask/task-details/${row.data.superTaskId}/summary`]);

//     } else {
//       if (row.data.taskId) {
//         this.dashBoardWidgetsServices.getSuperTaskId(row.data.taskId).subscribe((response: any) => {
//           if (response.status == 200 && response.data) {
//             if (response.data.superTaskId) {
//               const superTaskId = response.data.superTaskId;
//               this.router.navigate([`../${RolesRouteMap.userRoleRouteMapping[sessionStorage.getItem('userTypeRole')]}/dashboard/superTask/task-details/${superTaskId}/summary`]);
//             } else {
//               this.toastr.success('', response.message);
//             }

//           }
//         });
//       }
//     }
//   }

//   viewDetail(item) {

//     sessionStorage.setItem('lcaCompanyId', (item.data.companyId));
//     sessionStorage.setItem('lcaPetitionId', (item.data.petitionId));
//     sessionStorage.setItem('selectedLcaId', (item.data.LcaId));
//     sessionStorage.setItem('lcaTaskId', (item.data.taskId));
//     this.router.navigate([`${RolesRouteMap.userRoleRouteMapping[sessionStorage.getItem('userTypeRole')]}/myforms/manage-lca-acknowledge`]);

//   }
//   navigateToForms() {
//     this.router.navigate([`../${RolesRouteMap.userRoleRouteMapping[sessionStorage.getItem('userTypeRole')]}/myforms`]);
//   }
//   navigateToI9(row) {
//     if (row.data && row.data.i9FormId) {
//       const queryParamsObj = {
//         beneficiaryEmployeeId: row.data.beneficiaryId || 0,
//         companyId: row.data.companyId || 0,
//         taskId: row.data.taskId || 0,
//         i9FormId: row.data.i9FormId || 0
//       };
//       this.router.navigate([`../${RolesRouteMap.userRoleRouteMapping[sessionStorage.getItem('userTypeRole')]}/beneficiaries/update-employment/I-9`], { queryParams: queryParamsObj });

//     }
//   }


// }


import { Component, OnInit, AfterContentInit, OnChanges, ViewChild, SimpleChanges, AfterViewInit, EventEmitter,Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DashboardWidgetsService } from 'src/app/services/dashboard-widgets.service';
import { ToastrService } from 'ngx-toastr';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { NotifyCodeAction, NotifyCodeNoAction } from 'src/app/data/constants/notification-type-code';
import { HelpVideoOverlayComponent } from '../../../../modules/shared/help-video-overlay/help-video-overlay.component';
import * as RolesRouteMap from '../../../../data/constants/roles';
import { take } from 'rxjs/operators';
import { BillingInvoiceService } from "src/app/services/billing-invoice.service";
import { environment } from "environment-configurations";
import { MatDialog } from '@angular/material/dialog';
import { ViewNotificationComponent } from 'src/app/modules/notifications/components/view-notification/view-notification.component';
import { Store } from '@ngrx/store';
import * as Task from 'src/app/modules/superTask/task-state/actions/task.actions';
import { ActionAlertModalComponent } from 'src/app/modules/lca/action-alert-modal/action-alert-modal.component';
import { PetitionerService } from 'src/app/services/petitioner.service';
import { CustomErrorToastComponent } from 'custom-error-toast';

@Component({
  selector: 'notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss']
})
export class NotificationListComponent implements OnInit, AfterViewInit {
  @Output() clientNotificationClicked = new EventEmitter();
  notifications: any;
  userType;
  notificationCount;
  noDataFoundMsg: string = "No Notifications at this time";
  isEditLink: boolean = false;
  routingPathVal: any;
  maxShownCharacters: number = 50;
  InvoiceDialog: boolean = false;
  InvoiceId: number;
  proofofPaymentImg: any;

  @ViewChild(HelpVideoOverlayComponent, { static: false }) helpVideoComponent: HelpVideoOverlayComponent;


  displayedColumns: string[] = ['notifications'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  linkreq = NotifyCodeAction.LINKREQ;
  notificationsElements: any[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private dashBoardWidgetsServices: DashboardWidgetsService,
    private toastr: ToastrService,
    public dialog: MatDialog,
    private invoiceservice: BillingInvoiceService,
    protected store: Store<any>,
    private petitionerService: PetitionerService,
    private toastService: ToastrService
    ) { }

  ngOnInit() {
    //  this.InvoiceDialog = true;
    // Connect now button will be shown for user with permission of EDIT_LINK
    this.authenticationService.currentUser.subscribe((data) => {
      if (data) {
        this.isEditLink = data['permissions'].indexOf('EDIT_LINK') !== -1;      
      }
    });
    this.userType = this.authenticationService.currentUserValue ? this.authenticationService.currentUserValue['userType'] : sessionStorage.getItem('userTypeRole');
    // sessionStorage.getItem('userTypeRole');  
    // IM-3579 - removed check of companytype and companyid
    this.notificationsList();

  }

  ngAfterViewInit() {
    if (this.userType != 'Beneficiary') {
      this.helpVideoComponent.setValues('right', 'How to use Notifications to collabrate?', 'How to use Notifications to collabrate?');
    } else {
      this.helpVideoComponent.setValues('right', 'Using Timelines and Notifications for Beneficiary', 'Using Timelines and Notifications for Beneficiary');
    }

  }


  notificationsList() {
    const payload = {};
    this.dashBoardWidgetsServices.getNotificationListUpdated(payload)
      .pipe(take(1))
      .subscribe(response => {
       // console.log('notifications>>>>>>>>>>>>', response);
        if (response['status'] === 200) {
          this.notifications = response['data'];
          if (this.notifications.elements.length <= 0) {
            this.notificationCount = 0;
          }
          //  this.dataSource = new MatTableDataSource(this.notifications.elements);
          this.modifyData(this.notifications.elements);
          // this.notificationsElements = this.notifications.elements;
          // this.dataSource.paginator = this.paginator;
          // this.dataSource.sort = this.sort;
          this.notificationCount = this.notifications.elements.length;
        }
      });
  }

  modifyData(notificationArr) {
    if (notificationArr.length > 0) {
      for (const item of notificationArr) {
        switch (item.notificationType.code) {
          case 'CLIENT_UPDATED_PAYMENT_DTL':
            item.notificationLink = 'CLIENT_UPDATED_PAYMENT_DTL';
            break;
          case 'INVOICE_PAYMENT_DUE':
            item.notificationLink = 'INVOICE_PAYMENT_DUE';
            break;
        case 'INVOICE_RECEIVED':
            item.notificationLink = 'INVOICE_RECEIVED';
            break;
          case 'LINKREQ':
            item.notificationLink = 'CONNECT_NOW';
            break;
          case 'ETA_NOTICE':
            item.notificationLink = 'ETA_NOTICE';
            break;
          case 'LCA_ACKNOWLEDGEMENT_REQ':
            item.notificationLink = 'GOTO_LCA_ACKNOWLEDGMENT';
            break;
          case 'LCA_POST_DECLARATION_RES':
          case 'LCA_ACKNOWLEDGEMENT_RES':
            item.notificationLink = 'LCA_ACK_RES';
            break;
          case 'ATTR_BEN_LINK':
            item.notificationLink = 'ATTR_BEN_LINK';
            break;
          case 'PET_DOC_MISS':
            item.notificationLink = 'PET_DOC_MISS';
            break; 
          case 'PETNR_CREATE_BENEFICIARY_NOTIF':
              item.notificationLink = 'PETNR_CREATE_BENEFICIARY_NOTIF';
            break; 
            case 'LCA_TAKE_DOWN_REMINDER_NOTF':
            item.notificationLink = 'LCA_TAKE_DOWN_REMINDER_NOTF';
            break;             
          default:
            if (item.notificationType.code.indexOf('I9') >= 0) {
              item.notificationLink = 'GOTO_I9';
            }else if(item.notificationType.code.indexOf('BEN_QUEST_SUBMIT_NOTF') >= 0 && item?.data?.superTaskId){
              item.notificationLink = 'GOTO_WIZARD_TASK';
            }else {
              item.notificationLink = 'GOTO_TASK';
            }
            break;
        }
        item['shortText'] =  this.modifyText(item);        
       // console.log('notificationLink...', item.notificationLink)
       // console.log('sorttext...', this.modifyText(item))
      }
      //console.log("$$$$ Notifications on dashboard", notificationArr);
      this.notificationsElements = notificationArr;
      this.dataSource = new MatTableDataSource(this.notificationsElements);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }

  }

  modifyText(item){
    //Three dot implemetations for Notification List as per requested by the Product Team.
    let shortText;
    if(item.notificationLink == 'ETA_NOTICE'){
      let textArr = item['text'].split('|');
      if(textArr.length > 2){
        let dateTime = `${textArr[1]} | ${textArr[2]}`;
        shortText = `${textArr[0]}\n${dateTime}`;
      }else{
        shortText = item['text'];
      }
    }else{
      let textArr = item['text'].split('\n');
      if(textArr.length > 1){
        let nameDateText = textArr[textArr.length-1];
        let note = (item['data'] && item['data']['comments']) ? item['data']['comments'] : '';
        let noteText = note ? (note.length > this.maxShownCharacters ? `\nNote: ${note.substring(0, this.maxShownCharacters).concat(`...`)}`: `\nNote: ${note}`) : '';
        textArr.pop();
        let otherThanNameDate = textArr.join('\n').replace(/\n{2,}/g, '\n');
        shortText = otherThanNameDate.length > this.maxShownCharacters ? otherThanNameDate.substring(0, this.maxShownCharacters).concat(`... ${noteText} \n${nameDateText}`) : otherThanNameDate.concat(`${noteText}\n${nameDateText}`);
      }else{
        shortText = item['text'];
      }
    }
    return shortText;
  }

  connectNow(item) {
    const payload = {
      linkRequestId: item['data']['linkReqId'],
      statusCode: 'ACTV',
      notificationInfoId: item?.id
    };
    this.dashBoardWidgetsServices.postUpdateNotificationRequest(payload).subscribe(data => {
      (data['status'] === 200) ? this.toastr.success(data['message'], 'Success') :
      CustomErrorToastComponent.showErrorToast(this.toastr, data['message']);
        
      this.notifications = [];
      this.notificationsList();
    });
  }
  showFullNotifications() {
    this.toastr.info('Not yet implemented');
  }

  // isGoTo(type,code,actioned){
  //   if(type == 'task' && code != NotifyCodeAction.LINKREQ ){
  //       return true;
  //   }
  //   else if(type == 'connect'&& code === NotifyCodeAction.LINKREQ &&
  //           this.isEditLink && actioned === false
  //          ){
  //     return true;
  //   }
  //   else {
  //     return false;
  //   }
  // }

  // isGoTo(type,code,actioned,data?){

  //   if(type == 'task' && code != NotifyCodeAction.LINKREQ && code != NotifyCodeAction.RFEDOCMISSRFERESPONSEREQ && code != NotifyCodeAction.RFEDOCMISS && code != NotifyCodeAction.RFERESPONSEREQ){
  //       this.routingPathVal ='../../dashboard/task/task-details/'+ data ?.taskId;
  //       return true;
  //   }
  //   else if(type == 'connect'&& code === NotifyCodeAction.LINKREQ &&
  //           this.isEditLink && actioned === false
  //          ){
  //     return true;
  //   }else if(code === NotifyCodeAction.RFEDOCMISSRFERESPONSEREQ || code === NotifyCodeAction.RFEDOCMISS || code === NotifyCodeAction.RFERESPONSEREQ){
  //     this.routingPathVal ='../../dashboard/rfe/task-details/'+ data ?.taskId;
  //     return true;
  //   }
  //   else {
  //     return false;
  //   }


  // }


  isGoToConnect(code, actioned) {
    if (code === NotifyCodeAction.LINKREQ && this.isEditLink && actioned === false) {
      return true;
    } else {
      return false;
    }
  }


  isGoToTask(code, data) {
    if (code.startsWith("RFE_") && data && data.taskId) {
      this.routingPathVal = '../../dashboard/rfe/task-details/' + data['taskId'];
      return true;
    } else if (code != NotifyCodeAction.LINKREQ && data && data.taskId) {
      this.routingPathVal = '../../dashboard/task/task-details/' + data['taskId'];
      return true;
    } else {
      return false;
    }
  }

  changeStatus(linkRequestId, statusCode, notificationId) {
    this.dashBoardWidgetsServices.updateRequest(linkRequestId, statusCode, notificationId).subscribe(data => {
      (data['status'] === 200) ? this.toastr.success(data['message'], 'Success') :
      CustomErrorToastComponent.showErrorToast(this.toastr, data['message']);
      this.notifications = [];
      this.notificationsList();
    });
  }

  acceptNotice(id) {
    this.dashBoardWidgetsServices.acceptNotice(id).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.success(response.message, 'Success');
        this.notificationsList();
      }
    });
  }

  ignoreNotice(id) {
    this.dashBoardWidgetsServices.ignoreNotice(id).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.success(response.message, 'Success');
        this.notificationsList();
      }
    });
  }

  navigateToCaseRequest(row:any){
    console.log('navigateToCaseRequest changed:: ', row);
    console.log('navigateToCaseRequest :: row.data', row.data);
    console.log('navigateToCaseRequest :: row.data.superTaskId', row.data.superTaskId);
    if (row.data && row.data.superTaskId) {
      // this.store.dispatch(new Task.SetTaskRouteId(row.data.superTaskId));
      // this.router.navigate([`attorney-landing/case-request`]);
      // this.router.navigate([`attorney-landing/case-request/create-request-initiate`]);
      // this.router.navigate([`attorney-landing/case-request/create-request-initiate`],queryParams: {
      //   id: item.notificationId,
      //   superTaskTypeId:  this.superTaskTypeDD,
      // } );
      // queryParams: {
      //   id: item.notificationId,
      //   superTaskTypeId:  this.superTaskTypeDD,
      // }
      this.router.navigate([`${RolesRouteMap.userRoleRouteMapping[sessionStorage.getItem('userTypeRole')]}/case-request/create-request-initiate`],
      {
        queryParams: {
          beneficiaryId: row.data.beneficiaryId,
          superTaskId:  row.data.superTaskId,
          caseRequestId: row.data.caseRequestId,
          taskTypeId: row.data.taskTypeId,
          petitionerCompanyName: row.data.petitionerCompanyName,
          petitionerName: row.data.petitionerName,
          lawFirmUserId: row.data.attorneyEmployeeId
        }
      }
    );
    }
    //   this.router.navigate([`attorney-landing/case-request/create-request-initiate`],
    //   {
    //     queryParams: {
    //       beneficiaryId: row.data.beneficiaryId,
    //       superTaskId:  row.data.superTaskId,
    //     }
    //   }
    // );
    }
 // }

  navigateTask(code, row: any) {
    if (row.data && row.data.superTaskId && code !== 'BEN_QUEST_SUBMIT_NOTF') {
      this.store.dispatch(new Task.SetTaskRouteId(row.data.superTaskId));
      this.router.navigate([`../${RolesRouteMap.userRoleRouteMapping[sessionStorage.getItem('userTypeRole')]}/dashboard/superTask/task-details/summary`]);

    }else if(row?.data?.superTaskId && row?.data?.taskId && code === 'BEN_QUEST_SUBMIT_NOTF'){
      sessionStorage.setItem('wizardTID', row.data.taskId);
      this.router.navigate([`${RolesRouteMap.userRoleRouteMapping[sessionStorage.getItem('userTypeRole')]}/immigration-wizard/upload/individual-details`]);
    }else {
      if (row.data.taskId) {
        this.dashBoardWidgetsServices.getSuperTaskId(row.data.taskId).subscribe((response: any) => {
          if (response.status == 200 && response.data) {
            if (response.data.superTaskId) {
              const superTaskId = response.data.superTaskId;
              this.store.dispatch(new Task.SetTaskRouteId(superTaskId));
              this.router.navigate([`../${RolesRouteMap.userRoleRouteMapping[sessionStorage.getItem('userTypeRole')]}/dashboard/superTask/task-details/summary`]);
            } else {
              this.toastr.success(response.message, 'Success');
            }

          }
        });
      }
    }
  }

  viewDetail(item) {

    sessionStorage.setItem('lcaCompanyId', (item.data.companyId));
    sessionStorage.setItem('lcaPetitionId', (item.data.petitionId));
    sessionStorage.setItem('selectedLcaId', (item.data.LcaId));
    sessionStorage.setItem('lcaTaskId', (item.data.taskId));
    this.router.navigate([`${RolesRouteMap.userRoleRouteMapping[sessionStorage.getItem('userTypeRole')]}/myforms/manage-lca-acknowledge`]);

  }
  navigateToForms() {
    this.router.navigate([`../${RolesRouteMap.userRoleRouteMapping[sessionStorage.getItem('userTypeRole')]}/myforms`]);
  }
  navigateToI9(row) {
    if (row.data && row.data.i9FormId) {
      const queryParamsObj = {
        beneficiaryEmployeeId: row.data.beneficiaryId || 0,
        companyId: row.data.companyId || 0,
        taskId: row.data.taskId || 0,
        i9FormId: row.data.i9FormId || 0
      };
      this.router.navigate([`../${RolesRouteMap.userRoleRouteMapping[sessionStorage.getItem('userTypeRole')]}/beneficiaries/update-employment/I-9`], { queryParams: queryParamsObj });

    }
  }
  navigateToRoute(row) {
    let path;
    if (this.userType == 'Beneficiary') {
      path = 'beneficiary-landing/petitions/requested-documents/'+row.data.superTaskId;
    }else if(this.userType == 'Petitioner'){
      path = 'petitioner-landing/petitioner/requested-documents/'+row.data.superTaskId;
    }
    if(path){
      this.router.navigate([path]);
    }
  }
  navigateToInvoicingPage(invoiceId,id){
    // this.router.navigate(['/attorney-landing/attorney-invoice/billing-invoices']);
    this.router.navigateByUrl('/attorney-landing/attorney-invoice/billing-invoices', {
      state: {
        isnaviGated: true,
        invoiceId: invoiceId,
        id: id
      },
    });
   
  }
  clientUpdatingPaymentStatus(row){
    this.router.navigateByUrl("/petitioner-landing/dashboard/invoice-received", {
      state: {
        invoiceId: row.data.invoiceId,
        id: row.id
      },
    });
   
  //   this.router.navigateByUrl(['/petitioner-landing/dashboard/invoice-received'])
  //   this.InvoiceDialog = true;
  //  console.log('inv',invoiceId);
  //  this.clientNotificationClicked.emit({
  //   invoiceDialog: true
  //  })
  }

  cancel(){
  this.InvoiceDialog = false;
  }

  sendUpdate(){
    console.log('hi');
  }

  navigateToCompanyInfo(rowData) {
    this.router.navigate(['/attorney-landing/clients/edit-client/', rowData.data.companyId])
  }

  showproofofPaymentDialog(invoiceId){

    //this.InvoiceDialog = true;
    this.InvoiceId = invoiceId;
    this.invoiceservice.getProofofPayment(this.InvoiceId).subscribe(res =>{
      console.log('proofof',res)
      this.proofofPaymentImg = res;
      this.openDoc( this.proofofPaymentImg)
    })

    console.log('paymentProof')
  }

  openDoc(fileLocation) {
    const docPath = fileLocation.substring(23);
    window.open(`${environment.docs}${docPath}`, "_blank");
  }

  navigateToBilling(){
    this.router.navigate(['/attorney-landing/attorney-invoice/billing-invoices'])
  }


  navigateToProfile(row) {
    sessionStorage.setItem('listBeneficiaryId', row['data']['beneficiaryId']);
    sessionStorage.setItem('bene-profile-viewMode', row['data']['modeOfCreationCode'] === 'CREATED' ? 'false' : 'true');
    if(this.userType=='Petitioner'){
      this.router.navigate([`${RolesRouteMap.userRoleRouteMapping[this.userType]}/beneficiaries/profile/education`]);
    } else {
      this.router.navigate([`${RolesRouteMap.userRoleRouteMapping[this.userType]}/attorney/beneficiary/profile`]);
    }
  }

  showMessage(row){
    console.log('rowwww', row);
    const dialogRef = this.dialog.open(ViewNotificationComponent, {
      data: row,
      panelClass: 'custom-dialog',
      width: '520px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
      }
    });
  } 

  openActionAlertModal(lcaData, action: string) {
    const dialogRef = this.dialog.open(ActionAlertModalComponent, {
      width: '580px',
      data: {
        lcaId: lcaData.data.lcaId,
        action: action,
        buttons: ['Cancel', action]
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.petitionerService.takeDownLCA(lcaData.data.lcaId).subscribe(data => {
          this.toastService.success("LCA Taken Down successfully!", 'Success');
        });
      }
    });
  }

  navigateToCustomProfile(row) {
    sessionStorage.setItem('listBeneficiaryId', row['data']['beneficiaryId']);
    sessionStorage.setItem('bene-profile-viewMode', row['data']['modeOfCreationCode'] === 'CREATED' ? 'false' : 'true');
    if(row['data']['requesterLoginId']){
      sessionStorage.setItem('requesterDetails', JSON.stringify(row['data']));
    }
    if(this.userType=='Petitioner'){
      this.router.navigate([`${RolesRouteMap.userRoleRouteMapping[this.userType]}/companies/profile`]);
    } else if(this.userType=='Beneficiary'){
      this.router.navigate([`/beneficiary-landing/beneficiaries/beneficiary-profile-data/bene-personal-details`]);
    } else {
      this.router.navigate([`${RolesRouteMap.userRoleRouteMapping[this.userType]}/attorney/attorneys/profile`]);
    }
  }

}