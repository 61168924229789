import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CaseStatusService } from '../services/case-status.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-case-status-update',
  templateUrl: './case-status-update.component.html',
  styleUrls: ['./case-status-update.component.scss']
})
export class CaseStatusUpdateComponent implements OnInit, OnDestroy  {

  receiptNumber;
  updatecaseForm: FormGroup;
  currentCaseId:any;
  observableSubscription$ = new Subject();
  editCaseData:any;
  shortStatus: any;
  caseDetail: any;
  constructor(
    private fb: FormBuilder,
    private toastr: ToastrService,
    private APIService : CaseStatusService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.currentCaseId = this.activatedRoute.snapshot.paramMap.get('id');
    this.updatecaseForm = this.fb.group({
      shortStatus: ['', Validators.required],
      caseDetail: ['']
    });
    if(this.currentCaseId !== null){
      this.getEditData(this.currentCaseId);
    }  
  }

  copyRecieptNo() {
    if (navigator.clipboard) {
      // If Clipboard API is supported, use it
      navigator.clipboard.writeText(this.editCaseData.receiptNumber)
        .then(() => {
          this.toastr.success('Receipt Number Copied', 'Success');
        })
        .catch(err => {
          console.error('Error copying text: ', err);
          this.toastr.error('Failed to copy receipt number', 'Error');
        });
    } else {
      // Fallback for older browsers (execCommand)
      const textArea = document.createElement('textarea');
      textArea.value = this.editCaseData.receiptNumber;
      document.body.appendChild(textArea);
      textArea.select();
      try {
        // Use the document.execCommand method to copy text
        const successful = document.execCommand('copy');
        if (successful) {
          this.toastr.success('Receipt Number Copied', 'Success');
        } else {
          this.toastr.error('Failed to copy receipt number', 'Error');
        }
      } catch (err) {
        console.error('Fallback failed: ', err);
        this.toastr.error('Failed to copy receipt number', 'Error');
      }
      document.body.removeChild(textArea);
    }
    
  }

  getEditData(caseId){
    this.APIService.caseEdit(caseId).pipe(takeUntil(this.observableSubscription$)).subscribe((response) => {
      this.editCaseData= response;
      this.receiptNumber = response.receiptNumber;
      this.updatecaseForm.patchValue({
        shortStatus: response.shortStatus,
        caseDetail: response.uscisStatus
      });
    });
  }

  saveCase() {
    if (this.updatecaseForm.invalid) {
      this.updatecaseForm.markAllAsTouched(); // Highlight errors
      this.toastr.error('Please fill in all required fields', 'Error');
      return;
    }
  
    const payload = {
      id: this.currentCaseId ? parseInt(this.currentCaseId) : 0,
      shortStatus: this.updatecaseForm.get('shortStatus').value,
      uscisCaseStatus: this.updatecaseForm.get('caseDetail').value
    };
  
    this.APIService.updateCases(payload).subscribe(res => {
      this.toastr.success(res.message, 'Success');
      this.router.navigate(['/platform-admin-landing/case-status-list']);
    });
  }  

    cancelHandler(){
      this.router.navigate(['/platform-admin-landing/case-status-list']);
    }

    ngOnDestroy(): void {
      this.observableSubscription$.next();
      this.observableSubscription$.complete();
    }

}
