<div class="container-fluid padding-top-bottom-15 new-dashboard">
    <div class="row mb-3">
        <div class="col-12">
            <div class="card">
                <p-carousel [value]="beneficiaryData" [numVisible]="6" [numScroll]="3" [circular]="false">
                    <ng-template let-product pTemplate="item">
                        <div class="slider-main" (click)="getAllData(product)">
                            <div class="text-center py-5 px-3 slider-cont">
                                <div class="mb-3">
                                    <div class="name-sec">{{product?.beneficiaryEmployeeFName+"
                                        "+product.beneficiaryEmployeeLName}}</div>
                                    <div class="company-sec">{{product?.companyName}}</div>
                                    <div class="dob-sec"><b>DOB - </b> {{product?.dob | date:'MM/dd/yyyy'}}</div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </p-carousel>
            </div>
            <div class="float-right">
                <button type="button" (click)="gridView()" class="btn view-btn">View All</button>
            </div>
            <div class="clearfix"></div>
        </div>
    </div>
    <div class="row pb-3">
        <div class="col-12">
            <p-card class="quick-card-pro">
                <div class="row">
                    <div class="col-md-3 p-0 profile-header text-center">
                        <div class="row">
                            <span class="header-icon border rounded-circle p-3">
                                <img src="http://18.208.188.1/compliance/assets/profile_grid.png" alt="">
                            </span>
                        </div>
                        <label>{{beneficiaryFName}} {{beneficiaryLName}}</label>
                    </div>
                    <p-divider layout="vertical"></p-divider>
                    <div class="col-md-8 p-0 mt-4" *ngIf="employeeDetails.taskId">
                        <div class="title-profile">
                            <label>Task ID - {{employeeDetails.taskId ? employeeDetails.taskId : '--'}}</label>
                        </div>
                        <div class="task-header-summary">
                            <div class="border-right pr-2 border-dark float-left">
                                <label class="float-left mb-0">Case opened : <span> {{employeeDetails?.createdDate  | date:'MM/dd/yyyy'}}</span></label>
                            </div>
                            <div class="border-right px-2 border-dark float-left">
                                <label class="float-left mb-0">Visa Type : <span>{{employeeDetails?.visaType ?
                                        employeeDetails.visaType : '--'}}</span></label>
                            </div>
                            <div class="border-right px-2 border-dark float-left">
                                <label class="float-left mb-0">Petition Type : <span>{{employeeDetails?.petitionType ?
                                        employeeDetails?.petitionType : '--'}}</span></label>
                            </div>
                            <div class=" px-2 border-dark float-left">
                                <label class="float-left mb-0">Action Needed : <span> {{employeeDetails?.taskStatus ? employeeDetails?.taskStatus :
                                        '--'}}</span></label>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-8 p-0 mt-5" *ngIf="employeeDetails.taskId == null">
                        <div class="title-profile">
                            <label>Status : No Case Opened</label>
                        </div>
                    </div>
                </div>
            </p-card>
        </div>
    </div>


    <!-- LCA  & I-9Forms -->
    <div class="row">
        <div class="col-6 pt-3">
            <!-- LCA -->
            <p-card class="quick-card">
                <ng-template pTemplate="header">
                    <h6 class="m-0 pb-2 pl-3 c"> LCA </h6>
                </ng-template>

                <div class="row">
                    <div class="col-md-5 p-0">
                        <div class="lac-total">
                            <label>New Employment <span class="counts"> :
                                    {{LcaTotalList?.availableNewEmploymentCount ?
                                    LcaTotalList?.availableNewEmploymentCount : 0}}</span></label>
                        </div>
                        <div class="lac-total">
                            <label>Continuation of Employment <span class="counts"> :
                                    {{LcaTotalList?.availableContinueApprovedCount ?
                                    LcaTotalList?.availableContinueApprovedCount : 0}} </span></label>
                        </div>
                        <div class="lac-total">
                            <label>Change in Employment <span class="counts"> :
                                    {{LcaTotalList?.availableEmployerChangeCount ?
                                    LcaTotalList?.availableEmployerChangeCount : 0}} </span></label>
                        </div>
                    </div>
                    <p-divider layout="vertical"></p-divider>
                    <div class="col-md-5 p-0">
                        <div class="lac-total">
                            <label>Concurrent in Employment <span class="counts"> :
                                    {{LcaTotalList?.availableConcurrentEmploymentCount ?
                                    LcaTotalList?.availableConcurrentEmploymentCount : 0}} </span></label>
                        </div>
                        <div class="lac-total">
                            <label>Amended Petition <span class="counts"> : {{LcaTotalList?.amendedPetitionCount ?
                                    LcaTotalList?.amendedPetitionCount : 0}}
                                </span></label>
                        </div>
                        <div class="lac-total">
                            <label>Available Count <span class="counts"> : {{LcaTotalList?.availableCount ?
                                    LcaTotalList?.availableCount : 0}}
                                </span></label>
                        </div>
                    </div>
                </div>
                <p-divider></p-divider>

                <!-- LCA Details -->
                <div class="row">
                    <div class="col-md-5 p-0">
                        <h5 class="table-card">Available Documents</h5>
                        <div class="header-border"></div>
                        <p-table [columns]="cols" class="i9-table" [value]="LcaDocuments">
                            <ng-template pTemplate="body" let-row let-columns="columns">
                                <tr>
                                    <td *ngFor="let col of columns">
                                        <span>
                                            {{row.fileCategory[col.field]}}
                                        </span>
                                    </td>
                                    <td>
                                        <div class="row">
                                            <button pButton type="button" icon="pi pi-download" pTooltip="Download"
                                                (click)="downloadLcaDoc(row)"
                                                class="p-button-text p-button-rounded"></button>
                                            <button pButton type="button" icon="pi pi-eye" pTooltip="View"
                                                (click)="openLcaDoc(row)"
                                                class="p-button-text p-button-rounded"></button>
                                        </div>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                        <div class="text-center" *ngIf="!employeeDetails.lcaId">
                            <h5 class="noData">No Data</h5>
                        </div>

                    </div>
                    <p-divider layout="vertical"></p-divider>
                    <div class="col-md-6 p-0">
                        <h5 class="table-card">Required Documents</h5>
                        <div class="header-border"></div>
                        <p-table [columns]="cols" class="i9-table" [value]="reqDocLCAPublicNew">
                            <ng-template pTemplate="body" let-row let-columns="columns">
                                <tr>
                                    <td *ngFor="let col of columns">
                                        <span>
                                            {{row}}
                                        </span>
                                    </td>
                                    <td>
                                        <span class="redColor d-flex align-items-center">
                                            Document Missing
                                        </span>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                        <div class="text-center" *ngIf="!employeeDetails.lcaId">
                            <h5 class="noData">No Data</h5>
                        </div>

                    </div>
                </div>
            </p-card>
        </div>
        <!-- I-9 Form Details -->
        <div class="col-6 pt-3">
            <p-card class="quick-card">
                <ng-template pTemplate="header">
                    <h6 class="m-0 pb-2 pl-3 c"> I-9 Form Details </h6>
                </ng-template>

                <div class="row">
                    <div class="col-md-6">
                        <h5 class="table-card">Available Documents</h5>
                        <div class="header-border"></div>
                    </div>
                    <div class="col-md-6">
                        <button type="button" label="Upload" class="btn p-button-primary"></button>
                    </div>
                </div>

                <div class="row i9-binding">
                    <div class="col-md-4 p-0">
                        <p class="sec">Section 1</p>
                        <p-table *ngIf="documentArraySec1.length > 0" [columns]="i9cols" class="i9-table" [value]="documentArraySec1">
                            <ng-template pTemplate="body" let-row let-columns="columns">
                                <tr>
                                    <td *ngFor="let col of columns">
                                        <span>
                                            {{(row[col.field]) ? row[col.field] : '--'}}
                                        </span>
                                    </td>
                                    <td>
                                        <div class="row">
                                            <button pButton type="button" icon="pi pi-download" pTooltip="Download"
                                                (click)="downloadI9FormDoc(row)"
                                                class="p-button-text p-button-rounded"></button>
                                            <button pButton type="button" icon="pi pi-eye" pTooltip="View"
                                                (click)="openI9FormDoc(row)"
                                                class="p-button-text p-button-rounded"></button>
                                            <button pButton type="button" icon="pi pi-trash" pTooltip="Delete"
                                                (click)="deleteI9aDoc(row)"
                                                class="p-button-text p-button-rounded"></button>
                                        </div>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                        <div class="text-center" *ngIf="documentArraySec1.length <= 0">
                            <h5 class="noData">No Data</h5>
                        </div>

                    </div>
                    <!-- <p-divider layout="vertical"></p-divider> -->
                    <div class="col-md-4 p-0">
                        <p class="sec">Section 2</p>
                        <p-table *ngIf="documentArraySec2.length > 0" [columns]="i9cols" class="i9-table" [value]="documentArraySec2">
                            <ng-template pTemplate="body" let-row let-columns="columns">
                                <tr>
                                    <td *ngFor="let col of columns">
                                        <span>
                                            {{(row[col.field]) ? row[col.field] : '--'}}
                                        </span>
                                    </td>
                                    <td>
                                        <div class="row">
                                            <button pButton type="button" icon="pi pi-download" pTooltip="Download"
                                                (click)="downloadI9FormDoc(row)"
                                                class="p-button-text p-button-rounded"></button>
                                            <button pButton type="button" icon="pi pi-eye" pTooltip="View"
                                                (click)="openI9FormDoc(row)"
                                                class="p-button-text p-button-rounded"></button>
                                            <button pButton type="button" icon="pi pi-trash" pTooltip="Delete"
                                                (click)="deleteI9aDoc(row)"
                                                class="p-button-text p-button-rounded"></button>
                                        </div>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                        <div class="text-center" *ngIf="documentArraySec2.length <= 0">
                            <h5 class="noData">No Data</h5>
                        </div>
                    </div>
                    <!-- <p-divider layout="vertical"></p-divider> -->
                    <div class="col-md-4 p-0">
                        <p class="sec">Section 3</p>
                        <p-table *ngIf="documentArraySec3.length > 0" [columns]="i9cols" class="i9-table" [value]="documentArraySec3">
                            <ng-template pTemplate="body" let-row let-columns="columns">
                                <tr>
                                    <td *ngFor="let col of columns">
                                        <span>
                                            {{(row[col.field]) ? row[col.field] : '--'}}
                                        </span>
                                    </td>
                                    <td>
                                        <div class="row">
                                            <button pButton type="button" icon="pi pi-download" pTooltip="Download"
                                                (click)="downloadI9FormDoc(row)"
                                                class="p-button-text p-button-rounded"></button>
                                            <button pButton type="button" icon="pi pi-eye" pTooltip="View"
                                                (click)="openI9FormDoc(row)"
                                                class="p-button-text p-button-rounded"></button>
                                            <button pButton type="button" icon="pi pi-trash" pTooltip="Delete"
                                                (click)="deleteI9aDoc(row)"
                                                class="p-button-text p-button-rounded"></button>
                                        </div>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                        <div class="text-center" *ngIf="documentArraySec3.length <= 0">
                            <h5 class="noData">No Data</h5>
                        </div>
                    </div>
                </div>
            </p-card>
        </div>
    </div>

    <!-- Private/Public Access -->
    <div class="row pt-4">
        <!-- Public Access -->
        <div class="col-6">
            <p-card class="quick-card">
                <ng-template pTemplate="header">
                    <h6 class="m-0 pb-2 pl-3 c"> Public Access </h6>
                </ng-template>

                <div class="row">
                    <div class="col-md-5 p-0">
                        <h5 class="table-card">Available Documents</h5>
                        <div class="header-border"></div>
                        <p-table [columns]="cols" class="i9-table" [value]="LcaDocuments">
                            <ng-template pTemplate="body" let-row let-columns="columns">
                                <tr>
                                    <td *ngFor="let col of columns">
                                        <span>
                                            {{row.fileCategory[col.field]}}
                                        </span>
                                    </td>
                                    <td>
                                        <div class="row">
                                            <button pButton type="button" icon="pi pi-download" pTooltip="Download"
                                                (click)="downloadLcaDoc(row)"
                                                class="p-button-text p-button-rounded"></button>
                                            <button pButton type="button" icon="pi pi-eye" pTooltip="View"
                                                (click)="openLcaDoc(row)"
                                                class="p-button-text p-button-rounded"></button>
                                        </div>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                        <div class="text-center" *ngIf="!employeeDetails.lcaId">
                            <h5 class="noData">No Data</h5>
                        </div>
                    </div>
                    <p-divider layout="vertical"></p-divider>
                    <div class="col-md-6 p-0">
                        <h5 class="table-card">Required Documents</h5>
                        <div class="header-border"></div>
                        <p-table [columns]="cols" class="i9-table" [value]="reqDocLCAPublicNew">
                            <ng-template pTemplate="body" let-row let-columns="columns">
                                <tr>
                                    <td *ngFor="let col of columns">
                                        <span>
                                            {{row}}
                                        </span>
                                    </td>
                                    <td>
                                        <span class="redColor d-flex align-items-center">
                                            Document Missing
                                        </span>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                        <div class="text-center" *ngIf="!employeeDetails.lcaId">
                            <h5 class="noData">No Data</h5>
                        </div>

                    </div>
                </div>
            </p-card>

        </div>
        <!-- Private Access -->
        <div class="col-6">
            <p-card class="quick-card">
                <ng-template pTemplate="header">
                    <h6 class="m-0 pb-2 pl-3 c"> Private Access </h6>
                </ng-template>

                <div class="row">
                    <div class="col-md-5 p-0">
                        <h5 class="table-card">Available Documents</h5>
                        <div class="header-border"></div>
                        <p-table *ngIf="employeeDetails.taskId" [columns]="cols" class="i9-table" [value]="privateAccessList">
                            <ng-template pTemplate="body" let-row let-columns="columns">
                                <tr>
                                    <td *ngFor="let col of columns">
                                        <span>
                                            {{row.fileCategory[col.field]}}
                                        </span>
                                    </td>
                                    <td>
                                        <div class="row">
                                            <button pButton type="button" icon="pi pi-download" pTooltip="Download"
                                                (click)="downloadLcaDoc(row)"
                                                class="p-button-text p-button-rounded"></button>
                                            <button pButton type="button" icon="pi pi-eye" pTooltip="View"
                                                (click)="openLcaDoc(row)"
                                                class="p-button-text p-button-rounded"></button>
                                        </div>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                        <div class="text-center" *ngIf="!employeeDetails.taskId">
                            <h5 class="noData">No Data</h5>
                        </div>

                    </div>
                    <p-divider layout="vertical"></p-divider>
                    <div class="col-md-6 p-0">
                        <h5 class="table-card">Required Documents</h5>
                        <div class="header-border"></div>
                        <p-table *ngIf="employeeDetails.taskId" [columns]="cols" class="i9-table" [value]="reqDocLCAPrivateNew">
                            <ng-template pTemplate="body" let-row let-columns="columns">
                                <tr>
                                    <td *ngFor="let col of columns">
                                        <span>
                                            {{row}}
                                        </span>
                                    </td>
                                    <td>
                                        <span class="redColor d-flex align-items-center">
                                            Document Missing
                                        </span>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                        <div class="text-center" *ngIf="!employeeDetails.taskId">
                            <h5 class="noData">No Data</h5>
                        </div>
                    </div>
                </div>
            </p-card>

        </div>

    </div>

    <!-- FDNS Q & A -->
    <div class="row pt-4">
        <div class="col-6">
            <p-card class="quick-card">
                <ng-template pTemplate="header">
                    <h6 class="m-0 pb-2 pl-3 c"> FDNS Q & A </h6>
                </ng-template>
                <p-table class="fdnsQAlist" [columns]="fdnsQaCols" [value]="fdnsQAList"
                    tableStyleClass="fdnsQAListingTable">
                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <th *ngFor="let col of columns">

                                <span *ngIf="col.header === 'Answers'">
                                    <h6> {{col?.header}} </h6>
                                    <small>(Answers are taken from current active petition)</small>

                                </span>
                                <span *ngIf="col.header != 'Answers'">{{col?.header}}</span>
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-columns="columns">
                        <tr>
                            <td *ngFor="let col of columns">
                                <span *ngIf="col.header === 'Questions'">{{rowData[col.field]}}</span>
                                <span *ngIf="!rowData.required && col.header === 'Answers'"><span class="inner-html"
                                        [innerHtml]="rowData[col.field] | keepHtml"></span></span>
                                <span *ngIf="rowData.required && col.header === 'Answers'"><button class="link-button"
                                        (click)="activeIndex = 2">{{rowData[col.field]}}</button></span>

                            </td>
                        </tr>
                    </ng-template>
                </p-table>
                <div class="text-center" *ngIf="fdnsQAList.length <= 0">
                    <h5 class="noData">No Data</h5>
                </div>
            </p-card>
        </div>

        <!-- FDNS Visit -->
        <div class="col-6">
            <p-card class="quick-card">
                <ng-template pTemplate="header">
                    <h6 class="m-0 pb-2 pl-3 c">FDNS Visits </h6>
                </ng-template>
                <div class="row">
                    <div class="col-md-12 p-0">
                        <p-table [columns]="fdnsVisistcols" class="i9-table" [value]="fdnsVisitList">
                            <ng-template pTemplate="header" let-columns>
                                <tr class="text-uppercase">
                                    <th *ngFor="let col of columns">
                                        {{col.header}}
                                    </th>
                                    <!-- <th>Action </th> -->
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-row let-columns="columns">
                                <tr>
                                    <td *ngFor="let col of columns">
                                        <span>
                                            {{(row[col.field]) ? row[col.field] : '--'}}
                                        </span>
                                    </td>
                                    <!-- <td>
                                <div class="row">
                                    <button pButton type="button" icon="pi pi-download" pTooltip="Download"
                                        (click)="downloadLcaDoc(row)"
                                        class="p-button-text p-button-rounded"></button>
                                    <button pButton type="button" icon="pi pi-eye" pTooltip="View"
                                        (click)="openLcaDoc(row)"
                                        class="p-button-text p-button-rounded"></button>
                                </div>
                            </td> -->
                                </tr>
                            </ng-template>
                        </p-table>

                        <div class="text-center" *ngIf="fdnsVisitList?.length > 0">
                            <p class="show-more"><a
                                    [routerLink]="'../../companies/compliance/compliance/fdns-visit'">Show More</a></p>
                        </div>
                        <div class="mt-2" *ngIf="fdnsVisitList.length === 0">
                            <p class="text-center">Currently FDNS Visit data not available</p>
                        </div>

                    </div>

                </div>
                <!-- <app-fdns-visit></app-fdns-visit> -->
            </p-card>

        </div>

    </div>


</div>