import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ApiServicesService } from '../services/api-services.service';
import { ToastrService } from 'ngx-toastr';
import { MarkCleanFormAction } from 'src/app/app-state/actions/dirty-form.actions';
import { Store } from '@ngrx/store';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { ReferenceLibrary } from 'src/app/data/models/referenceLibrary.model';
import { ReferenceLibraryService } from 'src/app/services/referenceLibrary.service';
import { catchError, debounceTime, delay, switchMap, take } from 'rxjs/operators';
import * as moment from 'moment';
import { AttorneyService } from 'src/app/services/attorney.service';
import { Subject, combineLatest, from, of } from 'rxjs';
import { Router } from '@angular/router';
import { LcaService } from 'src/app/services/lca.service';
import { PetitionerService } from 'src/app/services/petitioner.service';
import { CustomErrorToastComponent } from 'custom-error-toast';

@Component({
    selector: 'app-create-new-beneficiary',
    templateUrl: './create-new-beneficiary.component.html',
    styleUrls: ['./create-new-beneficiary.component.scss']
})
export class CreateNewBeneficiaryComponent implements OnInit {
    emailValidExist: boolean = false;
    createNewEnable: boolean = false;
    formDirty = false;
    benLivingUS: any;
    isSubmitted = false;
    residingUs = false;
    visaTypes: ReferenceLibrary[];
    createNewBene: FormGroup;
    taskType: any[];
    familyType: any[];
    currentDate = new Date();
    toYear: string;
    fromYear: string;
    listOfClientCompanies: any[];
    userType: string;
    selectedCompanyId = 0;
    listGender: ReferenceLibrary[];
    isBeneUndocumented: boolean = false;
    loading: boolean = false;
    private emailExistSubject = new Subject<string>();

    constructor(
        public router: Router,
        private toastr: ToastrService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private formBuilder: FormBuilder,
        private apiService: ApiServicesService,
        private referenceLibraryService: ReferenceLibraryService,
        protected store: Store<any>,
        private attorneyService: AttorneyService,
        private petitionerService: PetitionerService,
        private cdr: ChangeDetectorRef,
        private lcaApis: LcaService) {
        this.toYear = moment().format('YYYY');
        this.fromYear = moment().subtract(100, 'years').format('YYYY');
        this.userType = sessionStorage.getItem('userTypeRole');

        // Handle the debounced API call using RxJS operators
        this.emailExistSubject.pipe(
            debounceTime(1000), // 1 seconds debounce
            switchMap((email: string) =>
                from(this.isEmailExist(email)).pipe(  // Convert Promise to Observable using 'from'
                    catchError((error) => {
                        console.error('Error during email check:', error);
                        return of(false); // Default to false in case of error
                    })
                )
            )
        ).subscribe((emailExists: boolean) => {
            this.emailValidExist = emailExists;
            if (this.emailValidExist) {
                this.toastr.error("Email already Exists");
            } else {
                this.toastr.success("Email is available");
            }
            this.isFormValid(); // Re-validate the form after checking email existence
        });
    }

    ngOnInit(): void {
        console.log(this.config.data);
        this.userType = sessionStorage.getItem('userTypeRole');
    
        this.createNewBene = this.formBuilder.group({
            firstName: [null, [Validators.required]],
            lastName: [null, [Validators.required]],
            middleName: [null],
            genderType: [null, [Validators.required]],
            date: [null, [Validators.required]],
            emailId: [null, [Validators.required, Validators.email]],
            immType: [null, [Validators.required]],
            benType: [null, [Validators.required]],
            benLivingUS: [null, [this.selectedBenLiveUSRequired.bind(this)]],
            selectedBenType: [null, [this.selectedBenTypeRequired.bind(this)]],
            hasImmigration: [null, [this.hasImmigrationRequired.bind(this)]],
            petitionerCompany: [null, [this.petitionerCompanyRequired.bind(this)]],
            selectedCompanyId: [null]
        });
    
        if (this.userType === "Petitioner") {
            this.createNewBene.patchValue({
                immType: 'GCEB',
                benType: 'INDVDL',
                petitionerCompany: null
            });
        }
    
        // Create an array of observables
        const requests = [
            this.referenceLibraryService.getReferenceData('IMMSTAT'),
            this.referenceLibraryService.getReferenceData('BENTYP'),
            this.referenceLibraryService.getReferenceData('GCIMMCATG'),
            this.referenceLibraryService.getReferenceData('GENDER')
        ];
    
        // Conditionally add the attorney service request
        if (this.userType === "Petitioner") {
            requests.push(
                this.attorneyService.getClientsList(parseInt(sessionStorage.getItem('companyId')), {
                    searchText: '',
                    searchCriteria: '',
                    sortBy: '',
                    sortOrder: '',
                    pageNumber: 0,
                    recordsPerPage: 0
                })
            );
        }
    
        // Execute all requests
        combineLatest(requests)
            .pipe(take(1))
            .subscribe((response: any[]) => {
                this.visaTypes = [...response[0]];
                this.visaTypes.push({
                    code: 'NONE',
                    desc: 'None',
                    group: '',
                    id: 0,
                    name: 'None'
                });
                this.taskType = (response[1] as ReferenceLibrary[]).filter(x => x.code !== 'GUARDIAN');
                this.familyType = [...response[2]];
                this.listGender = [...response[3]];
    
                if (this.userType === "Petitioner") {
                    this.getCompanyList();
                    this.listOfClientCompanies = [...response[4]['petetionerList']];
                }
            });
    
        if (this.config?.data) {
            this.getEmployeeDetails();
            if (this.config.data.i9StatusCode) {
                this.createNewBene.disable();
            }
        }
    
        this.getCompanyList();
    }    

    isFormValid() {
        if (!this.emailValidExist && this.createNewBene.controls.firstName.value && this.createNewBene.controls.lastName.value
            && this.createNewBene.controls.genderType.value && this.createNewBene.controls.date.value) {
            this.createNewEnable = true;
        }
        else {
            this.createNewEnable = false;
        }
    }

    async getCompanyList() {
        const companyId = sessionStorage.getItem('companyId');
        if (!companyId) return;
        this.loading = true;
        try {
            // Convert the observable to a promise using toPromise() or firstValueFrom()
            const data = await this.lcaApis.companyHierarchy(parseInt(companyId)).pipe(take(1),delay(500)).toPromise();
            
            // Map the response data
            this.listOfClientCompanies = data.map((el) => ({
                'petitionerId': el['id'],
                'petitionerName': el['companyName']
            }));
    
            if (this.listOfClientCompanies.length) {
                this.selectedCompanyId = this.listOfClientCompanies[0].petitionerId;
                this.createNewBene.patchValue({ selectedCompanyId: this.selectedCompanyId });
            }
            this.loading = false;
            this.cdr.detectChanges();
        } catch (error) {
            console.error('Error fetching company list:', error);
            this.loading = false;
        }
    }
    

    selectedBenLiveUSRequired(control: FormControl): { [s: string]: boolean } {
        if (this.createNewBene && this.isBeneUndocumented && control.value === null) {
            return { benLiveInUSIsRequired: true };
        }
        return null;
    }

    hasImmigrationRequired(control: FormControl): { [s: string]: boolean } {
        if (this.createNewBene && this.createNewBene.value.benLivingUS &&
            this.createNewBene.value.benLivingUS === '1' && control.value === null && this.isBeneUndocumented) {
            return { hasImmigrationIsRequired: true };
        }
        return null;
    }

    selectedBenTypeRequired(control: FormControl): { [s: string]: boolean } {
        if (this.createNewBene && this.createNewBene.value.benLivingUS &&
            this.createNewBene.value.benLivingUS === '1' && control.value === null && this.isBeneUndocumented) {
            return { selectedBenTypeIsRequired: true };
        }
        return null;
    }

    petitionerCompanyRequired(control: FormControl): { [s: string]: boolean } {
        if (this.createNewBene && this.createNewBene.value.immType &&
            this.createNewBene.value.immType === 'GCEB' && !control.value) {
            return { petitionerCompanyIsRequired: true };
        }
        return null;
    }

    // convenience getter for easy access to form fields
    get f() { return this.createNewBene.controls; }

    onImmTypeSelected() {
        this.createNewBene.patchValue({
            benType: null,
            petitionerCompany: null
        });
        this.isBeneUndocumented = false;
        this.f['benType'].markAsPristine();
        this.f['petitionerCompany'].markAsPristine();
        this.f['benType'].markAsUntouched();
        this.f['petitionerCompany'].markAsUntouched();
        this.updateValidationOfOptionalFields();
    }

    createBeneFormSub() {
        if (this.config.data) {
            this.updateEmployee();
        } else {
            const formControls = this.createNewBene.controls;
            if (this.userType === "Petitioner") {
                const payload = {
                    undocumentedUserInfo: {
                        entryVisaCode: formControls.selectedBenType.value ?
                            (formControls.selectedBenType.value === 'NONE' ? null : formControls.selectedBenType.value) : null,
                        immTypeToApplyCode: formControls.immType.value,
                        currentlyInUS: parseInt(formControls.benLivingUS.value),
                        isImmStatusValid: formControls.hasImmigration.value ?
                            parseInt(formControls.hasImmigration.value) : null
                    },
                    firstName: formControls.firstName.value,
                    lastName: formControls.lastName.value,
                    middleName: formControls.middleName.value,
                    email: formControls.emailId.value,
                    beneficiaryTypeCode: formControls.benType.value,
                    gender: formControls.genderType.value,
                    dob: formControls.date.value ?
                        moment(formControls.date.value).format('YYYY-MM-DD') : null,
                    companyId: formControls.immType.value === 'GCEB' ?
                        formControls.petitionerCompany.value : parseInt(sessionStorage.getItem('companyId')),
                    beneficiaryId: 0
                };
                this.apiService.petitionerCreateBeneficiary(payload)
                    .pipe(take(1))
                    .subscribe(result => {
                        if (result.status === 200) {
                            this.store.dispatch(new MarkCleanFormAction({
                                dirty: false
                            }));
                            this.toastr.success(result.message);
                            this.ref.close(true);
                            this.router.navigateByUrl('petitioner-landing/beneficiaries/list');
                        }
                        else {
                            CustomErrorToastComponent.showErrorToast(this.toastr, result.message);
                            this.ref.close();
                        }
                    });

            } else {
                const payload = {
                    undocumentedUserInfo: {
                        entryVisaCode: formControls.selectedBenType.value ?
                            (formControls.selectedBenType.value === 'NONE' ? null : formControls.selectedBenType.value) : null,
                        immTypeToApplyCode: formControls.immType.value,
                        currentlyInUS: parseInt(formControls.benLivingUS.value),
                        isImmStatusValid: formControls.hasImmigration.value ?
                            parseInt(formControls.hasImmigration.value) : null
                    },
                    firstName: formControls.firstName.value,
                    lastName: formControls.lastName.value,
                    middleName: formControls.middleName.value,
                    email: formControls.emailId.value,
                    beneficiaryTypeCode: formControls.benType.value,
                    gender: formControls.genderType.value,
                    dob: formControls.date.value ?
                        moment(formControls.date.value).format('YYYY-MM-DD') : null,
                    companyId: formControls.selectedCompanyId.value ?
                        formControls.selectedCompanyId.value : parseInt(sessionStorage.getItem('companyId'))
                };
                this.apiService.attorneyCreateBeneficiary(payload)
                    .pipe(take(1))
                    .subscribe(result => {
                        if (result.status === 200) {
                            this.store.dispatch(new MarkCleanFormAction({
                                dirty: false
                            }));
                            if (this.userType === "I9Admin") {
                                this.toastr.success('Employee data saved successfully');
                                this.ref.close(true);
                            }
                            else{
                                this.toastr.success(result.message);
                                this.ref.close(true);
                            }
                        }
                        else {
                            if (this.userType === "I9Admin") {
                                CustomErrorToastComponent.showErrorToast(this.toastr, 'Employee creation is failed');
                                this.ref.close();
                            }
                            else{
                                CustomErrorToastComponent.showErrorToast(this.toastr, result.message);
                                this.ref.close();
                            }
                        }
                    });
            }
        }
    }




    benLivingUSCheck(event) {
        if (event === 1) {
            this.residingUs = true;
        } else {
            this.residingUs = false;
            this.createNewBene.patchValue({
                selectedBenType: null,
                hasImmigration: null
            });
        }
        this.updateValidationOfOptionalFields();
    }

    onChangeBeneType(event) {
        this.isBeneUndocumented = (event.value === 'UNDOCUMENTED');
        if (this.isBeneUndocumented) {
            this.createNewBene.patchValue({
                selectedBenType: null,
                hasImmigration: null,
                benLivingUS: null
            });
            this.residingUs = false;
        }
        this.updateValidationOfOptionalFields();
    }

    updateValidationOfOptionalFields() {
        this.createNewBene.get('selectedBenType').updateValueAndValidity();
        this.createNewBene.get('hasImmigration').updateValueAndValidity();
        this.createNewBene.get('benLivingUS').updateValueAndValidity();
        Object.keys(this.f).forEach(control => {
            if (['selectedBenType', 'hasImmigration', 'benLivingUS'].includes(control)) {
                this.f[control].markAsPristine();
                this.f[control].markAsUntouched();
            }
        });
    }
    getEmployeeDetails() {
        this.petitionerService.getEmployeeDetails(this.config.data.companyId, this.config.data.beneficiaryId).subscribe(data => {
            const dateObject = new Date(data.dob);
            this.createNewBene.patchValue({
                firstName: data.fname,
                lastName: data.lname,
                middleName: data.middleName,
                genderType: data.gender,
                date: dateObject,
                emailId: data.email
            });
        })
    }
    updateEmployee() {
        const formControls = this.createNewBene.controls;
        const payload = {
            beneficiaryId: this.config.data.beneficiaryId,
            companyId: this.config.data.companyId,
            fname: formControls.firstName.value,
            lname: formControls.lastName.value,
            middleName: formControls.middleName.value,
            email: formControls.emailId.value,
            gender: formControls.genderType.value,
            dob: formControls.date.value ?
                moment(formControls.date.value).format('YYYY-MM-DD') : null,
        }
        this.petitionerService.updateEmployee(payload).subscribe(data => {
            this.toastr.success(data.message);
            this.ref.close(payload);
        })
    }
    async isEmailExist(email: string): Promise<boolean> {
        const companyId = sessionStorage.getItem('companyId');
        try {
            const res = await this.apiService.isEmailExist(companyId, email).toPromise();
            return res; // Assuming the response is a boolean
        } catch (error) {
            console.error('API error', error);
            return false; // In case of error, return false
        }
    }

    checkEmail() {
        const regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        const email = this.createNewBene.controls.emailId.value;

        if (email && email.match(regexp)) {
            // Trigger the emailExistSubject to debounce the API call
            this.emailExistSubject.next(email);
        } else {
            this.emailValidExist = false;
            this.isFormValid(); // Immediately validate the form if the email is invalid
        }
    }

}
