import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ImagilityBaseResponse } from 'src/app/data/models/response.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VisaPreferenceService {

  constructor(private http:HttpClient) { }

  getVisaForms(employeeId: number) {
    return this.http.get(`/petitioner/attorney/userform/empId/${employeeId}`).pipe(
      map((response: ImagilityBaseResponse) => response.data)
    );
  }

  savePreferenceSettings(employeeId: number, payload: any) {
    return this.http.post(`/petitioner/attorney/userform/empId/${employeeId}`, payload).pipe(
      map((response: ImagilityBaseResponse) => response)
    );
  } 

}
