import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ImagilityBaseResponse } from 'app-models';
import { map, take } from 'rxjs/operators';
import * as fromLogin from './login-state/reducers/login.reducer';
import * as Login from './login-state/actions/login.actions';
import { EncryptService } from '../core/http/http-encrypt.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(
    private store: Store<fromLogin.State>,
    private router: Router,
    private httpClient: HttpClient,
    private encryptService: EncryptService
  ) { }

  backToLogin() {
    this.store.select(fromLogin.getLoginState)
      .pipe(take(1))
      .subscribe((response: fromLogin.LoginState) => {
        if (response.initiated && response.initiated === 'Attorney') {
          this.store.dispatch(new Login.SetIsBackToLogin(true));
          this.router.navigate(['/login/registerImmigrantType'], {
            state: {
              linkrequestId: response.linkRequestId,
              initiated: response.initiated,
              type: response.type
            }
          });
        } else {
          this.router.navigate(['/login/signin']);
        }
      });
  }

  getGroupReferenceData(groupReferenceCode: string) {
    this.httpClient = this.httpClient.disableJwt();
    return this.httpClient.get(`/referenceLibrary/${groupReferenceCode}`)
      .pipe(map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        } else {
          return null;
        }
      })
      );
  }

  linkRegisteredBeneficiaryToAttorney(beneficiaryId, linkCode) {
    this.httpClient = this.httpClient.enableJwt();
    return this.httpClient.post(`/beneficiary/${beneficiaryId}/linkRegisteredBeneficiaryToAttorney/${linkCode}`, {});
  }

  getCountry() {
    this.httpClient = this.httpClient.disableJwt();
    return this.httpClient.get('/countries').pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.data) {
          return response.data;
        }
      })
    );
  }

  checkUserIdAvailability(userId: string) {
    this.httpClient = this.httpClient.disableJwt();
    var usrId = this.encryptService.toEncrypt(userId);
    return this.httpClient.post(`/validateUserId/${usrId}`, {}).pipe(
      map((response: ImagilityBaseResponse) => {
          return response;
      })
    );
  }

}
