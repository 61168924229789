import { Component, OnDestroy, OnInit } from '@angular/core';
import * as RolesRouteMap from '../../../../data/constants/roles';
import { Router } from '@angular/router';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AddClientCompanyComponent, AddClientCompanyService } from 'add-client-company';
import { AddBeneficiaryOrMemberComponent, AddBeneficiaryOrMemberService } from 'add-beneficiary-or-member';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-quick-access',
  templateUrl: './quick-access.component.html',
  styleUrls: ['./quick-access.component.scss']
})
export class QuickAccessComponent implements OnInit, OnDestroy {
  userType: any = '';
  dynamicDialogRef: DynamicDialogRef;
  destroy$ = new Subject<void>();
  dynamicModalData: any = {};
  primaryCompanyId: number | null;
  constructor(
    private router: Router,
    public dialogService: DialogService,
    private addClientCompanyService: AddClientCompanyService,
    private addBenMemberService: AddBeneficiaryOrMemberService
    ) { 
      this.primaryCompanyId = parseInt(sessionStorage.getItem('companyId'));
      this.userType = sessionStorage.getItem('userTypeRole');
  }

  ngOnInit(): void {
    this.addClientCompanyService.saveClientCompanySubject.next({});
    this.addBenMemberService.saveBenMemberSubject.next({});
    this.addClientCompanyService.saveClientCompanySubject.pipe(takeUntil(this.destroy$)).subscribe((res)=>{
      if(res && res.saved){
        //Do anything if required after the creation of client
      }
    });
    
    this.addBenMemberService.saveBenMemberSubject.pipe(takeUntil(this.destroy$)).subscribe((res)=>{
      if(res && res.saved){
        //Do anything if required after the creation of beneficiary
      }
    });
  }

  addNewTask() {
    sessionStorage.removeItem('taskId');
    sessionStorage.removeItem('requestDataDocument');
    this.router.navigate([`${RolesRouteMap.userRoleRouteMapping[this.userType]}/dashboard/superTask/new`]);
  }

  viewTasks(){
    this.router.navigate([`${this.userType === RolesRouteMap.ATTORNEY ? 'attorney' : 'petitioner'}-landing/${this.userType === RolesRouteMap.ATTORNEY ? 'attorney' : 'petitioner'}/task-list`]);
  }

  viewBeneficiaries() {
    let url;
    if (this.userType === RolesRouteMap.PETITIONER) {
      url = `${RolesRouteMap.userRoleRouteMapping[this.userType]}/petitioner-landing/beneficiaries`;
    }
    if (this.userType === RolesRouteMap.ATTORNEY) {
      url = `${RolesRouteMap.userRoleRouteMapping[this.userType]}/attorney/beneficiaries`;
    }
    this.router.navigate([url]);
  }

  viewClients(){
    let url;
    // if (this.userType === RolesRouteMap.PETITIONER) {
    //   url = `${RolesRouteMap.userRoleRouteMapping[this.userType]}/petitioner-landing/beneficiaries`;
    // }
    if (this.userType === RolesRouteMap.ATTORNEY) {
      url = `${RolesRouteMap.userRoleRouteMapping[this.userType]}/clients/clients`;
    }
    this.router.navigate([url]);
  }

  getAddComponent(componentName: string): any{
    if(componentName){
      switch (componentName) {
        case 'addClientCompany':
          this.dynamicModalData = {attorneyId: this.primaryCompanyId};
          return AddClientCompanyComponent;
        case 'addBeneficiary':
          this.dynamicModalData = { companyId: this.primaryCompanyId, isAddingMember: false,isAddingSponsor:false };
          return AddBeneficiaryOrMemberComponent;
        default:
          return '';
      }
    }
  }

  handleAddClick(addFlag: string = '') {
    if(this.userType === RolesRouteMap.ATTORNEY && addFlag=='addClientCompany'){
      sessionStorage.setItem('clientId', null);
      let url = `${RolesRouteMap.userRoleRouteMapping[this.userType]}/clients/edit-client/null`;
      this.router.navigate([url], { queryParams: { modeOfCreation: null }});
    } else {
      this.dynamicDialogRef = this.dialogService.open(this.getAddComponent(addFlag), {
        width: '60%',
        contentStyle: { 'z-index': '1000', 'padding-bottom': '15px', 'overflow-y': 'hidden !important' },
        styleClass: 'create-task-new-dialog',
        closable: false,
        showHeader: false,
        closeOnEscape: false,
        modal: true,
        dismissableMask: true,
        data: this.dynamicModalData
      });
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
