import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PetitionerGuardService } from './petitioner-guard.service';
import { PetitionerLandingComponent } from './petitioner-landing/petitioner-landing.component';
import { TermsConditionsComponent } from '../shared/footer/terms-conditions/terms-conditions.component';
import { PrivacyPolicyComponent } from '../shared/footer/privacy-policy/privacy-policy.component';
import { ContactUsComponent } from '../shared/footer/contact-us/contact-us.component';
import { PublicAccessComponent } from '../compliance/public-access/public-access.component';
import { LcaComponent } from '../compliance/lca/lca.component';
import { FdnsQaComponent } from '../compliance/fdns-qa/fdns-qa.component';
import { FdnsVisitComponent } from '../compliance/fdns-visit/fdns-visit.component';
import { PastDocumentsMenuComponent } from '../compliance/past-documents-menu/past-documents-menu.component';
import { BulkUploadListComponent } from '../i9-evaluators/bulk-upload/bulk-upload-list/bulk-upload-list.component';
import { BulkUploadDocumentComponent } from '../i9-evaluators/bulk-upload/bulk-upload-document/bulk-upload-document.component';
import { I9EmployeeDetailsComponent } from '../beneficiary/i9-employee-details/i9-employee-details.component';
import { ManageRemediationsComponent } from '../i9-evaluators/i9-remediation/manage-remediations/manage-remediations.component';
import { InitiateRemediationComponent } from '../i9-evaluators/i9-remediation/initiate-remediation/initiate-remediation.component';
import { ParsingReportListComponent } from '../i9-evaluators/i9-remediation/parsing-report-list/parsing-report-list.component';
import { ParsingErrorsComponent } from '../i9-evaluators/i9-remediation/parsing-errors/parsing-errors.component';
import { I9FormDetailsComponent } from '../i9-evaluators/i9-form-details/i9-form-details.component';
import { VisaBulletinDetailsComponent } from './visa-bulletin-details/visa-bulletin-details.component';
import { FormsWeSupportComponent } from '../shared/forms-we-support/forms-we-support.component';
import { VisaPreferenceSettingsComponent } from '../visa-preference/visa-preference-settings/visa-preference-settings.component';

const routes: Routes = [
  {
    path: 'petitioner-landing',
    component: PetitionerLandingComponent,
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('../dashboard-petitioner/dashboard-petitioner.module').then((mod) => mod.PetitionerDashboardModule),
        data: { preload: true }
      },
      {
        path: 'petitions',
        loadChildren: () => import('../manage-petitions/manage-petitions.module').then((mod) => mod.ManagePetitionsModule),
      },
      {
        path: 'beneficiaries',
        loadChildren: () => import('../beneficiary/beneficiary.module').then((mod) => mod.BeneficiaryModule),
      },
      {
        path: 'beneficiaries/undocumented',
        loadChildren: () => import('../beneficiary/beneficiary.module').then((mod) => mod.BeneficiaryModule),
      },
      {
        path: 'attorneys',
        loadChildren: () => import('../attorney/attorney.module').then((mod) => mod.AttorneyModule),
      },
      {
        path: 'lca',
        loadChildren: () => import('../lca/lca.module').then((mod) => mod.LCAModule),
      },
      {
        path: 'task-allocation',
        loadChildren: () => import('../task-allocation/task-allocation.module').then((mod) => mod.TaskAllocationModule),
      },
      {
        path: 'users',
        loadChildren: () => import('../user/user.module').then((mod) => mod.UserModule),
      },
      {
        path: 'roles',
        loadChildren: () => import('../roles/roles.module').then((mod) => mod.RolesModule),
      },
      {
        path: 'policies',
        loadChildren: () => import('../policies/policies.module').then((mod) => mod.PoliciesModule),
      },
      {
        path: 'case-request',
        loadChildren: () => import('../case-request/case-request.module').then((mod) => mod.CaseRequestModule), 
      },
      {
        path: 'custom-questionaire',
        loadChildren: () => import('../custom-questionaire/custom-questionaire.module').then((mod) => mod.CustomQuestionaireModule),
      },
      {
        path: 'companies',
        loadChildren: () => import('../petitioner/petitioner.module').then((mod) => mod.PetitionerModule),
      },
      {
        path: 'template',
        loadChildren: () => import('../template-builder/template-builder.module').then((mod) => mod.TemplateBuilderModule),
      },
      {
        path: 'rfe',
        loadChildren: () => import('../rfe-builder/rfe-builder.module').then((mod) => mod.RfeBuilderModule),
      },
      {
        path: 'receipts-notices',
        loadChildren: () => import('../receipts-notices/receipts-notices.module').then((mod) => mod.ReceiptsNoticesModule),
      },
      {
        path: 'receipt',
        loadChildren: () => import('../receipt-management/receipt-management.module').then((mod) => mod.ReceiptManagementModule),
      },
      {
        path: 'petitioner',
        loadChildren: () => import('../petitioner/petitioner.module').then((mod) => mod.PetitionerModule),
      },
      {
        path: 'reports',
        loadChildren: () => import('../manage-company-reports/manage-company-reports.module').then((mod) => mod.ManageCompanyReportsModule),
      },
      {
        path: 'notifications',
        loadChildren: () => import('../../modules/notifications/notifications.module').then((mod) => mod.NotificationsModule)
      },
      {
        path: 'help',
        loadChildren: () => import('../../modules/help/help.module').then(m => m.HelpModule),
        data: { preload: true }
      },
      {
        path: 'billing',
        loadChildren: () => import('../../modules/billing-payment/billing-payment.module').then(m => m.BillingPaymentModule)
        
      },
      {
        path: 'settings',
        loadChildren: () => import('../../modules/settings/settings.module').then(m => m.SettingsModule),
        data: { preload: true }
      },
      {
        path: 'petition-analysis',
        loadChildren: () => import('../petition-analysis-container/petition-analysis.module').then((mod) => mod.PetitionAnalysisContainerModule)
      },
      {
        path: 'terms-and-conditions',
        component: TermsConditionsComponent,
      },
      {
        path: 'privacy',
        component: PrivacyPolicyComponent,
      },
      {
        path: 'contact-us',
        component: ContactUsComponent,
      },
      {
        path: 'resources',
        loadChildren: () => import('../../modules/resources/resources.module').then(m => m.ResourcesModule)
      },
      {
        path: 'faq',
        loadChildren: () => import('../../modules/faq/faq.module').then((mod) => mod.FaqModule),
      },

      {
        path: 'email-temp',
        loadChildren: () => import('../../modules/email-template/email-template.module').then((mod) => mod.EmailTemplateModule)
      },
      {
        path: 'master-data',
        loadChildren: () => import('../../modules/master-data/master-data.module').then((mod) => mod.MasterDataModule),
      },
      {
        path: 'case-status',
        loadChildren: () => import('../case-status/case-status.module').then((mod) => mod.CaseStatusModule),
      },
      {
        path: 'bulk-upload',
        loadChildren: () => import('../../modules/bulk-upload/bulk-upload.module').then(m => m.BulkUploadModule),
      },
      {
        path: 'immigration-wizard',
        loadChildren: () => import('../immigration-wizard/immigration-wizard.module').then(m => m.ImmigrationWizardModule),
      },
      {
        path: 'visaBulletin',
        component: VisaBulletinDetailsComponent
      },
      {
        path: 'forms-we-support',
        component: FormsWeSupportComponent
      },
      {
        path: 'visa-preference-settings',
        component: VisaPreferenceSettingsComponent
      },
      {
        path: 'manage-i9-remediations',
        component: ManageRemediationsComponent,
      }, 
      {
        path: 'initiate-i9-remediation',
        component: InitiateRemediationComponent,
      }, 
      {
        path: 'i9-evaluators',
        loadChildren: () => import('../i9-evaluators/i9-everify.module').then((mod) => mod.I9EverifyModule),
      },
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      }
    ],
    canActivate: [PetitionerGuardService],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'i9-admin-landing',
    component: PetitionerLandingComponent,
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('../dashboard-petitioner/dashboard-petitioner.module').then((mod) => mod.PetitionerDashboardModule),
        data: { preload: true }
      },
      {
        path: 'petitions',
        loadChildren: () => import('../manage-petitions/manage-petitions.module').then((mod) => mod.ManagePetitionsModule),
      },
      {
        path: 'beneficiaries',
        loadChildren: () => import('../beneficiary/beneficiary.module').then((mod) => mod.BeneficiaryModule),
      },
      {
        path: 'beneficiaries/undocumented',
        loadChildren: () => import('../beneficiary/beneficiary.module').then((mod) => mod.BeneficiaryModule),
      },
      {
        path: 'attorneys',
        loadChildren: () => import('../attorney/attorney.module').then((mod) => mod.AttorneyModule),
      },
      {
        path: 'lca',
        loadChildren: () => import('../lca/lca.module').then((mod) => mod.LCAModule),
      },
      {
        path: 'task-allocation',
        loadChildren: () => import('../task-allocation/task-allocation.module').then((mod) => mod.TaskAllocationModule),
      },
      {
        path: 'users',
        loadChildren: () => import('../user/user.module').then((mod) => mod.UserModule),
      },
      {
        path: 'roles',
        loadChildren: () => import('../roles/roles.module').then((mod) => mod.RolesModule),
      },
      {
        path: 'policies',
        loadChildren: () => import('../policies/policies.module').then((mod) => mod.PoliciesModule),
      },
      {
        path: 'case-request',
        loadChildren: () => import('../case-request/case-request.module').then((mod) => mod.CaseRequestModule), 
      },
      {
        path: 'custom-questionaire',
        loadChildren: () => import('../custom-questionaire/custom-questionaire.module').then((mod) => mod.CustomQuestionaireModule),
      },
      {
        path: 'companies',
        loadChildren: () => import('../petitioner/petitioner.module').then((mod) => mod.PetitionerModule),
      },
      {
        path: 'template',
        loadChildren: () => import('../template-builder/template-builder.module').then((mod) => mod.TemplateBuilderModule),
      },
      {
        path: 'rfe',
        loadChildren: () => import('../rfe-builder/rfe-builder.module').then((mod) => mod.RfeBuilderModule),
      },
      {
        path: 'receipts-notices',
        loadChildren: () => import('../receipts-notices/receipts-notices.module').then((mod) => mod.ReceiptsNoticesModule),
      },
      {
        path: 'receipt',
        loadChildren: () => import('../receipt-management/receipt-management.module').then((mod) => mod.ReceiptManagementModule),
      },
      {
        path: 'petitioner',
        loadChildren: () => import('../petitioner/petitioner.module').then((mod) => mod.PetitionerModule),
      },
      {
        path: 'reports',
        loadChildren: () => import('../manage-company-reports/manage-company-reports.module').then((mod) => mod.ManageCompanyReportsModule),
      },
      {
        path: 'notifications',
        loadChildren: () => import('../../modules/notifications/notifications.module').then((mod) => mod.NotificationsModule)
      },
      {
        path: 'help',
        loadChildren: () => import('../../modules/help/help.module').then(m => m.HelpModule),
        data: { preload: true }
      },
      {
        path: 'billing',
        loadChildren: () => import('../../modules/billing-payment/billing-payment.module').then(m => m.BillingPaymentModule)
        
      },
      {
        path: 'settings',
        loadChildren: () => import('../../modules/settings/settings.module').then(m => m.SettingsModule),
        data: { preload: true }
      },
      {
        path: 'petition-analysis',
        loadChildren: () => import('../petition-analysis-container/petition-analysis.module').then((mod) => mod.PetitionAnalysisContainerModule)
      },
      {
        path: 'terms-and-conditions',
        component: TermsConditionsComponent,
      },
      {
        path: 'privacy',
        component: PrivacyPolicyComponent,
      },
      {
        path: 'contact-us',
        component: ContactUsComponent,
      },
      {
        path: 'resources',
        loadChildren: () => import('../../modules/resources/resources.module').then(m => m.ResourcesModule)
      },
      {
        path: 'faq',
        loadChildren: () => import('../../modules/faq/faq.module').then((mod) => mod.FaqModule),
      },

      {
        path: 'email-temp',
        loadChildren: () => import('../../modules/email-template/email-template.module').then((mod) => mod.EmailTemplateModule)
      },
      {
        path: 'master-data',
        loadChildren: () => import('../../modules/master-data/master-data.module').then((mod) => mod.MasterDataModule),
      },
      {
        path: 'case-status',
        loadChildren: () => import('../case-status/case-status.module').then((mod) => mod.CaseStatusModule),
      },
      {
        path: 'i9-evaluators',
        loadChildren: () => import('../i9-evaluators/i9-everify.module').then((mod) => mod.I9EverifyModule),
      },
      {
        path: 'public-access',
        component: PublicAccessComponent,
      },
      {
        path: 'private-access',
        component: LcaComponent,
      },
      {
        path: 'past-documents-menu',
        component: PastDocumentsMenuComponent,
      },
      {
        path: 'fdns-qa',
        component: FdnsQaComponent,
      },
      {
        path: 'fdns-visit',
        component: FdnsVisitComponent,
      },
      {
        path: 'bulk-upload-list',
        component: BulkUploadListComponent,
      }, 
      {
        path: 'bulk-upload-document',
        component: BulkUploadDocumentComponent,
      }, 
      {
        path: 'employeedetails',
        component: I9EmployeeDetailsComponent,
      },
      {
        path: 'manage-i9-remediations',
        component: ManageRemediationsComponent,
      }, 
      {
        path: 'initiate-i9-remediation',
        component: InitiateRemediationComponent,
      }, 
      {
        path: 'parsing-report-list',
        component: ParsingReportListComponent,
      },
      {
        path: 'parsing-errors',
        component: ParsingErrorsComponent,
      },
      {
        path: 'i9-form-details-correction',
        component: I9FormDetailsComponent,
      },
      {
        path: '',
        redirectTo: 'dashboard/i9-admin-dashboard',
        pathMatch: 'full'
      }
    ],
    canActivate: [PetitionerGuardService],
    runGuardsAndResolvers: 'always'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class PetitionerLandingRoutingModule { }

