<p-card styleClass="tasklist-card">

    <div class="d-flex align-items-center justify-content-between header-section">
        <div class="d-flex align-items-center">
            <h5 class="font-weight-bold mb-0">Checklist</h5>
            <select class="custom-select ml-2 filter-dropdown" (change)="onStatusChange($event)">
                <option value="" selected>All</option>
                <option value="UPCOMING">Upcoming</option>
                <option value="COMPLETED">Completed</option>
            </select>
        </div>
        <button class="btn btn-outline-primary btn-add-new" (click)="openModal()">
            <i class="fa fa-plus-circle mr-1"></i> Add New
        </button>
    </div>

    <ng-container *ngIf="todoList?.length > 0">
        <ng-container *ngFor="let item of todoList | slice:0:5">
            <div class="card list-item" [ngStyle]="{'backgroundColor': item.categoryColor }">
                <div class="row no-gutters align-items-center p-2">
                    <!-- Date Section -->
                    <div class="col-auto date-section text-center">
                        <div class="date-day">{{item.toDoDate | date: 'dd' }}</div>
                        <div class="date-month">{{item.toDoDate | date: 'MMM' }}</div>
                        <div class="date-time">{{item.time}}</div>
                    </div>

                    <!-- Content Section -->
                    <div class="col ml-2">
                        <div class="d-flex justify-content-between align-items-center">
                            <!-- Task Title -->
                            <div class="title-text" (click)="editTodoItem(item)">
                                {{item.heading}}
                            </div>
                            <!-- Three Dot Menu -->
                            <div class="menu-container">
                                <mat-icon matTooltip="settings" class="mx-2" [matMenuTriggerFor]="menu">
                                    <mat-icon>more_vert</mat-icon>
                                </mat-icon>
                                <mat-menu #menu="matMenu">
                                    <button mat-menu-item [disabled]="item.statusDto?.code === 'COMPLETED'" (click)="markCompleteToDo(item)">Mark Complete</button>
                                    <button mat-menu-item (click)="deleteNote(item)">Delete Note</button>
                                </mat-menu>
                            </div>
                        </div>
                        <!-- Category and Status -->
                        <div class="text-muted small">
                            {{item.categoryName}}
                            <span class="badge status-badge" [ngClass]="getStatusClass(item.statusDto?.code)">{{item.statusDto?.name}}</span>
                        </div>
                    </div>
                    <!-- Priority Dropdown -->
                    <div class="col-auto">
                        <select class="form-control">
                            <option selected>{{item.priorityDto.name}}</option>                       
                        </select>
                    </div>
                </div>
            </div>
        </ng-container>
    </ng-container>

    <div class="text-center" *ngIf="todoList?.length > 0">
        <a href="javascript:void(0)" (click)="openListModal()" class="show-more">Show More</a>
    </div>

    <div class="no-data" *ngIf="todoList?.length === 0">
        <h6>No Data Found!</h6>
    </div>

</p-card>


<!-- Confirm Delete Modal -->
<p-confirmDialog id="delete-todo-modal" [style]="{width: '25vw'}"></p-confirmDialog>