<div class="fluid-container">
  <div class="row pt-4 d-flex align-items-center">
    <div class="col-md-11 step-title pl-3">
      <h5 class="mb-0 common-bene-class-for-translation">Provide Immigration & Other documents</h5>
      <p class="mb-0 common-bene-class-for-translation">Sync & Upload Supporting Documents</p>
    </div>


  </div>
  <p-divider class="header-divider"></p-divider>
  <div class="main-body">
    <div *ngIf="type === 'family'">
      <div class="row col-md-12 pt-2">
        <div class="p-0 col-md-8">
          <h5 class="sub-title common-bene-class-for-translation">Immigration Documents</h5>
        </div>
        <div class="p-0 col-md-4">
          <button pButton type="button" class="p-button-outlined  pull-right" [disabled]="disabledControls"
            pTooltip="SYNC FROM PROFILE" (click)="syncDocuments()"><i
              class="pi pi-check"></i>&nbsp;&nbsp;&nbsp;
            </button>
        </div>
      </div>
      <div class="row pt-3">
        <div class="courseList col-md-12 p-0">
          <p-card class="courses-card">
            <div class="row document-header">
              <div class="col-md-5">
                <h6 class="common-bene-class-for-translation">Document Type</h6>
              </div>
              <div class="col-md-5">
                <h6 class="common-bene-class-for-translation">Document Name</h6>
              </div>
              <div class="col-md-2">
                <h6 classs="common-bene-class-for-translation">Action</h6>
              </div>
            </div>
            <div class="row document  courses-title d-flex align-items-center"
              *ngFor="let item of availableDocList; let i = index">
              <div class="col-md-5">
                <span>
                  {{ item?.fileCategory.name }}
                </span>
              </div>
              <div class="col-md-5">
                <span *ngIf="item.isPresent">
                  {{ item.fileName}}
                </span>
                <span *ngIf="!item.isPresent" class="redColor d-flex align-items-center">
                  <i class="pi pi-exclamation-triangle pr-2"></i> Document Missing
                </span>
              </div>
              <div class="col-md-2 pull-right d-flex align-items-center" *ngIf="item?.isPresent">
                <button pButton type="button" icon="pi pi-eye" pTooltip="View" [disabled]="!item.fileLocation"
                  (click)="openDoc(item)" class="p-button-text p-button-rounded"></button>
                <lib-send-back-component *ngIf="userType !== 'Beneficiary'" [disabledControls]="disabledControls"
                  [stepDetails]="stepDetails" [primaryData]="primaryData" [item]="item">
                </lib-send-back-component>
                <button pButton pRipple type="button" icon="pi pi-trash" pTooltip="Delete" [disabled]="disabledControls"
                  (click)="deleteDocumentTypeLis(item)" class="p-button-text p-button-rounded btnTrash"></button>
              </div>
              <div class="col-md-2" *ngIf="!item?.isPresent">
                <notification-component-library *ngIf="userType !== 'Beneficiary'" [destination]="notifyDestinationUser"
                  [disabledControls]="disabledControls" [stepDetails]="stepDetails" [primaryData]="primaryData"
                  [item]="item">
                </notification-component-library>
              </div>
            </div>
          </p-card>
        </div>
        <div class="row col-md-12 ">
          <p style="color: #626262; font-size: 13px;" class="common-bene-class-for-translation"> Please upload immigration documents in the Profile section. If
            you delete any document here, it will be removed ONLY from this screen, NOT in the Profile section.</p>
        </div>
      </div>
      <div class="col-md-12">
        <p-divider></p-divider>
      </div>
    </div>
    <div class="row col-md-12 pt-4">
      <div class="p-0 col-md-12">
        <h5 class="sub-title common-bene-class-for-translation" *ngIf="type === 'family'">Other Documents</h5>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 p-0  my-3">
        <file-upload-library style="width: 100%;" [fileUploadConfigurations]="requiredDocFileUploadConfigurations"
          [primaryData]="primaryData">
        </file-upload-library>
      </div>
    </div>
  </div>

</div>
