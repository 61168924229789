import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ImagilityBaseResponse, PrimaryData, TaskStep } from 'app-models';
import { DirtyFormGuard, MarkCleanFormAction } from 'dirty-check-store';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { StepStatusUpdatePayloadService } from 'step-status-update-payload-service';
//import { updateStepStatus } from 'src/app/modules/green-card-i140/store/actions/step-status-update.actions';
//import { StepStatusUpdatePayload } from 'src/app/modules/green-card-i140/utility/services/stepStatusUpdate.service';
import { getStepDetails, updateStepStatus, getStepStatusUpdate, updateStepStatusToInitialState } from 'visa-store';

@Component({
  selector: 'app-provide-information-container',
  templateUrl: './provide-information-container.component.html',
  styleUrls: ['./provide-information-container.component.scss']
})
export class ProvideInformationContainerComponent implements OnInit, OnDestroy {
  selectedItem: string;
  isLastTab = false;
  primaryData: PrimaryData;
  sectionCode: string;
  observableSubscription$ = new Subject();
  stepDetails: TaskStep;
  typeOfButton: string;
  tabList: {
    name: string;
    code: string;
    isLastTab: boolean;
  }[];
  userId: number;
  lastTabObj: { name: string; code: string; isLastTab: boolean; };
  type: string;
  @ViewChild('mainBody', { static: true }) mainBody: ElementRef;
  title: string;
  subTitle: string;


  constructor(
    private dynamicDialogRef: DynamicDialogRef,
    public dynamicDialogConfig: DynamicDialogConfig,
    private stepStatusUpdatePayloadCostruction: StepStatusUpdatePayloadService,
    private store: Store<any>,
    private guard: DirtyFormGuard) {
    this.primaryData = this.dynamicDialogConfig.data;
    this.sectionCode = this.primaryData.layoutCode;
  }

  ngOnInit(): void {
    this.store.dispatch(new MarkCleanFormAction({
      dirty: false
    }));
    this.store.pipe(select(getStepStatusUpdate))
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe((response: ImagilityBaseResponse) => {
        if (response && response.status === 200) {
          this.sectionCode = null;
          setTimeout(() => {
            this.sectionCode = this.primaryData.layoutCode;
          }, 0);
        }
      });
     this.store.pipe(select(getStepDetails, { id: this.primaryData.stepId }))
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe((data: TaskStep) => {
        this.stepDetails = data;
        console.log(this.stepDetails);
        this.typeOfButton = this.stepDetails.isPetitionerAction === 1 || this.stepDetails.isAttorneyAction === 1 || this.stepDetails.isBeneficiaryAction === 1 ? 'MARK_COMPLETE' : 'MARK_SUBMIT';
        if (!this.tabList || (this.tabList && this.tabList.length === 0)) {
          this.title = this.stepDetails?.stepName ? this.stepDetails?.stepName : 'Provide Beneficiary Information';
          
          this.subTitle = 'Provide all required Beneficiary data';
         // this.buildTabsForOldVisas();
        }
      });
      let additionalConfig = (this.stepDetails.additionalConfig) ? JSON.parse(this.stepDetails.additionalConfig) : null
      if(additionalConfig?.applicantTabs){
        this.tabList = additionalConfig.applicantTabs.filter(x => x.showTab == true);
        this.primaryData.reqDocType = additionalConfig?.docType ? additionalConfig?.docType : null;
        this.lastTabObj = this.tabList.find(item => item.isLastTab === true);
        if(!this.lastTabObj){
          this.lastTabObj = this.tabList[this.tabList.length - 1];
        }
        this.type = (additionalConfig?.isFamily)? 'family' : null;
        if(this.stepDetails.stepName == 'Provide Sponsor Details') {
          this.type = 'self';
        } else if(this.stepDetails.stepName == 'Provide Beneficiary Details') {
          this.type = !(additionalConfig?.isFamily) && this.stepDetails.stepApplTo === "BEN" ? 'self':'family';
        }
        this.userId = this.primaryData.familyId ? this.primaryData.familyId : this.primaryData.beneficiaryId;
        if(this.tabList.length > 0){
          this.handleLeftPanelSelection(this.tabList[0].code, this.tabList[0].isLastTab);
        }
      }else{
        this.buildTabsForOldVisas();
      }

  }

  buildTabsForOldVisas(){
    console.log(this.primaryData);
    const i360FormTypeClassification = ['EB4IMMRELGWRKR', 'EB4IMMJUVNL', 'EB4IMMOTH', 'AMERASIANI360', 'WIDOWERUSI360', 'VAWASPOUSEI360', 'VAWACHILDI360', 'VAWAPARENTI360'];
    if (['BENEFICIARY_DETAIL', 'BEN_BENEFICIARY_DETAIL'].includes(this.primaryData.layoutCode)) {
      if (['K1', 'K3'].includes(this.primaryData.visatype) && this.stepDetails.stepApplTo === 'PRIMAPPLCNT') {
        //  this.userId = this.primaryData.beneficiaryId;
        this.tabList = [{
          name: 'Personal Details',
          code: 'PERSONAL_DETAILS',
          isLastTab: false
        }, {
          name: 'Address History',
          code: 'ADDR_HISTORY',
          isLastTab: false
        }, {
          name: 'Marital History',
          code: 'MARITAL_HISTORY',
          isLastTab: false
        }, {
          name: 'Parent Information',
          code: 'FAMILY_INFO',
          isLastTab: false
        }, {
          name: 'Employment History',
          code: 'EMP_INFO',
          isLastTab: false
        }, {
          name: 'Immigration History',
          code: 'IMM_INFO',
          isLastTab: true
        }
        ];
        this.type = "family";
      } else if (this.primaryData.visatype === 'ASYLUM') {
        this.userId = this.primaryData.beneficiaryId;
        if (['ASYLRELATIVE'].includes(this.primaryData.immigrationClassification)) {
          this.tabList = [{
            name: 'Personal Details',
            code: 'PERSONAL_DETAILS',
            isLastTab: false
          }, {
            name: 'Immigration History',
            code: 'IMM_INFO',
            isLastTab: false
          },
          {
            name: 'Marital History',
            code: 'MARITAL_HISTORY',
            isLastTab: false
          },
          {
            name: 'Required Documents',
            code: 'DOC_INFO',
            isLastTab: true
          }
          ];
        } else {
          this.tabList = [{
            name: 'Personal Details',
            code: 'PERSONAL_DETAILS',
            isLastTab: false
          }, {
            name: 'Immigration History',
            code: 'IMM_INFO',
            isLastTab: false
          },
          {
            name: 'Required Documents',
            code: 'DOC_INFO',
            isLastTab: true
          }
          ];
        }
      } else if (this.primaryData.getTaskType === 'I485') {
        //  this.userId = this.primaryData.beneficiaryId;
        this.tabList = [{
          name: 'Personal Details',
          code: 'PERSONAL_DETAILS',
          isLastTab: false
        }, {
          name: 'Address History',
          code: 'ADDR_HISTORY',
          isLastTab: false
        }, {
          name: 'Employment History',
          code: 'EMP_INFO',
          isLastTab: false
        }, {
          name: 'Immigration History',
          code: 'IMM_INFO',
          isLastTab: false
        }, {
          name: 'Parent Information',
          code: 'PARENT_INFO',
          isLastTab: false
        }, {
          name: 'Marital History',
          code: 'MARITAL_HISTORY',
          isLastTab: false
        },
        {
          name: 'Required Documents',
          code: 'DOC_INFO',
          isLastTab: true
        }];
        this.subTitle = 'Provide & verify all required Beneficiary data';
      } else if (['TRVLDOCI131', 'REMVCONDTRES'].includes(this.primaryData.immigrationClassification)) {
        this.tabList = [{
          name: 'Personal Details',
          code: 'PERSONAL_DETAILS',
          isLastTab: true
        }];
        this.subTitle = 'Provide & verify all required Beneficiary data';
      } else if (['WVRINADMI601', 'AFFDVTSUPT864', 'AFFDVTSUPTEXMPT864W'].includes(this.primaryData.immigrationClassification)) {
        this.userId = this.primaryData.beneficiaryId;
        this.tabList = [{
          name: 'Personal Details',
          code: 'PERSONAL_DETAILS',
          isLastTab: false
        },
        {
          name: 'Required Documents',
          code: 'DOC_INFO',
          isLastTab: true
        }
        ];
      } else if (['WVRUNLAWPRSNCI601A'].includes(this.primaryData.immigrationClassification)) {
        this.userId = this.primaryData.beneficiaryId;
        this.tabList = [{
          name: 'Personal Details',
          code: 'PERSONAL_DETAILS',
          isLastTab: false
        },
        {
          name: 'Parent Information',
          code: 'PARENT_INFO',
          isLastTab: false
        },
        {
          name: 'Required Documents',
          code: 'DOC_INFO',
          isLastTab: true
        }
        ];
      } else if (['DACAI821D'].includes(this.primaryData.immigrationClassification)) {
        this.tabList = [{
          name: 'Personal Details',
          code: 'PERSONAL_DETAILS',
          isLastTab: false
        },
        {
          name: 'Immigration History',
          code: 'IMM_INFO',
          isLastTab: false
        },
        {
          name: 'Required Documents',
          code: 'DOC_INFO',
          isLastTab: true
        }
        ];
      }else if (['SUSPDPRT881'].includes(this.primaryData.immigrationClassification)) {
        this.tabList = [{
          name: 'Personal Details',
          code: 'PERSONAL_DETAILS',
          isLastTab: false
        }, {
          name: 'Address History',
          code: 'ADDR_HISTORY',
          isLastTab: false
        },
        {
          name: 'Immigration History',
          code: 'IMM_INFO',
          isLastTab: false
        },
        {
          name: 'Required Documents',
          code: 'DOC_INFO',
          isLastTab: true
        }
        ];
      } else if (['TEMPPROTSTATI821'].includes(this.primaryData.immigrationClassification)) {
        this.tabList = [{
          name: 'Personal Details',
          code: 'PERSONAL_DETAILS',
          isLastTab: false
        }, {
          name: 'Immigration History',
          code: 'IMM_INFO',
          isLastTab: false
        },
        {
          name: 'Family Information',
          code: 'PARENT_INFO',
          isLastTab: false
        }, {
          name: 'Required Documents',
          code: 'DOC_INFO',
          isLastTab: true
        }];
      } else if (['RELFFORMERSEC191'].includes(this.primaryData.immigrationClassification)) {
        this.tabList = [{
          name: 'Personal Details',
          code: 'PERSONAL_DETAILS',
          isLastTab: false
        },
        {
          name: 'Address History',
          code: 'ADDR_HISTORY',
          isLastTab: false
        },

        {
          name: 'Required Documents',
          code: 'DOC_INFO',
          isLastTab: true
        }];

      } else if (i360FormTypeClassification.includes(this.primaryData.immigrationClassification)) {
        this.tabList = [{
          name: 'Personal Details',
          code: 'PERSONAL_DETAILS',
          isLastTab: false
        }, {
          name: 'Immigration History',
          code: 'IMM_INFO',
          isLastTab: false
        },
        {
          name: 'Required Documents',
          code: 'DOC_INFO',
          isLastTab: true
        }
        ];

      } else if(['I130'].includes(this.primaryData.caseType)){
        this.tabList = [{
          name: 'Personal Details',
          code: 'PERSONAL_DETAILS',
          isLastTab: false
        }, {
          name: 'Address History',
          code: 'ADDR_HISTORY',
          isLastTab: false
        }, {
          name: 'Marital History',
          code: 'MARITAL_HISTORY',
          isLastTab: false
        }, {
          name: 'Family Information',
          code: 'FAMILY_INFO',
          isLastTab: false
        }, {
          name: 'Employment History',
          code: 'EMP_INFO',
          isLastTab: false
        }, {
          name: 'Immigration History',
          code: 'IMM_INFO',
          isLastTab: false
        }, {
          name: 'Additional Information',
          code: 'ADD_INFO',
          isLastTab: false
        }, {
          name: 'Required Documents',
          code: 'DOC_INFO',
          isLastTab: false
        },{
          name: 'Other Information',
          code: 'OTHER_INFO',
          isLastTab: true
        }
        ];
        this.title = 'Provide Beneficiary Information';
        if(this.stepDetails?.stepActionList?.length){
          this.type = ['BENEFICIARY_DETAIL'].includes(this.stepDetails?.stepActionList[0]?.layoutCode) ? 'family' : 'self';
        }
      }
      else {
        this.tabList = [{
          name: 'Personal Details',
          code: 'PERSONAL_DETAILS',
          isLastTab: false
        }, {
          name: 'Address History',
          code: 'ADDR_HISTORY',
          isLastTab: false
        }, {
          name: 'Marital History',
          code: 'MARITAL_HISTORY',
          isLastTab: false
        }, {
          name: 'Family Information',
          code: 'FAMILY_INFO',
          isLastTab: false
        }, {
          name: 'Employment History',
          code: 'EMP_INFO',
          isLastTab: false
        }, {
          name: 'Immigration History',
          code: 'IMM_INFO',
          isLastTab: false
        }, {
          name: 'Additional Information',
          code: 'ADD_INFO',
          isLastTab: false
        }, {
          name: 'Required Documents',
          code: 'DOC_INFO',
          isLastTab: true
        }
        ];
        this.title = 'Provide Beneficiary Information';
        this.type = ['DS160', 'DS260'].includes(this.primaryData.caseType) ? null : 'family';
      }
      this.userId = this.primaryData.familyId ? this.primaryData.familyId : this.primaryData.beneficiaryId;

    } else if (['PERSONAL_DETAIL', 'BEN_PERSONAL_DETAIL'].includes(this.primaryData.layoutCode)) {
      if (['K1', 'K3'].includes(this.primaryData.visatype) && this.stepDetails.stepApplTo === 'BEN') {
        this.tabList = [{
          name: 'Personal Details',
          code: 'PERSONAL_DETAILS',
          isLastTab: false
        }, {
          name: 'Address History',
          code: 'ADDR_HISTORY',
          isLastTab: false
        }, {
          name: 'Marital History',
          code: 'MARITAL_HISTORY',
          isLastTab: false
        }, {
          name: 'Parent Information',
          code: 'PARENT_INFO',
          isLastTab: false
        }, {
          name: 'Employment History',
          code: 'EMP_INFO',
          isLastTab: true
        }];
        // this.userId = this.primaryData.beneficiaryId;
        //  this.type = null;
        this.subTitle = 'Provide & verify all required Sponsor data';
      } else if (this.primaryData.visatype === 'ASYLUM') {
        this.tabList = [{
          name: 'Personal Details',
          code: 'PERSONAL_DETAILS',
          isLastTab: false
        }, {
          name: 'Immigration History',
          code: 'IMM_INFO',
          isLastTab: false
        },
        {
          name: 'Required Documents',
          code: 'IMM_DOC_INFO',
          isLastTab: true
        }
        ];
      } else if (this.primaryData.getTaskType === 'I485') {
        this.userId = this.primaryData.familyId;
        this.tabList = [{
          name: 'Personal Details 123',
          code: 'PERSONAL_DETAILS',
          isLastTab: false
        }, {
          name: 'Address History',
          code: 'ADDR_HISTORY',
          isLastTab: false
        }, {
          name: 'Employment History',
          code: 'EMP_INFO',
          isLastTab: false
        }, {
          name: 'Immigration History',
          code: 'IMM_INFO',
          isLastTab: false
        }, {
          name: 'Parent Information',
          code: 'PARENT_INFO',
          isLastTab: false
        }, {
          name: 'Marital History',
          code: 'MARITAL_HISTORY',
          isLastTab: false
        },
        {
          name: 'Required Documents',
          code: 'DOC_INFO',
          isLastTab: true
        }];
        this.subTitle = 'Provide & verify all required Beneficiary data';
      }
      else if (['TRVLDOCI131', 'REMVCONDTRES'].includes(this.primaryData.immigrationClassification)) {
        this.tabList = [{
          name: 'Personal Details',
          code: 'PERSONAL_DETAILS',
          isLastTab: true
        }];
        this.subTitle = 'Provide & verify all required Beneficiary data';
      } else {
        this.tabList = [{
          name: 'Personal Details',
          code: 'PERSONAL_DETAILS',
          isLastTab: false
        }, {
          name: 'Address History',
          code: 'ADDR_HISTORY',
          isLastTab: false
        }, {
          name: 'Marital History',
          code: 'MARITAL_HISTORY',
          isLastTab: false
        }, {
          name: 'Parent Information',
          code: 'PARENT_INFO',
          isLastTab: false
        }, {
          name: 'Employment History',
          code: 'EMP_INFO',
          isLastTab: false
        }, {
          name: 'Required Documents',
          code: 'DOC_INFO',
          isLastTab: true
        }];
        this.title = 'Provide Sponsor Information';
        this.subTitle = 'Provide & verify all required Sponsor data';
        // this.type = null;
      }
      this.userId = this.primaryData.sponsorId;
      this.type = null;

    }
    this.lastTabObj = this.tabList.find(item => item.isLastTab === true);
    if (this.tabList.length === 1) {
      this.handleLeftPanelSelection('PERSONAL_DETAILS', true);
    } else {
      this.handleLeftPanelSelection('PERSONAL_DETAILS', false);
    }
  }

  handleClose() {
    this.guard.canDeactivate().pipe(first()).subscribe((response: boolean) => {
      if (response) {
        this.store.dispatch(new MarkCleanFormAction({
          dirty: false
        }));
        this.dynamicDialogRef.close();
      }
      else {
        return false;
      }
    });
  }

  updateStepStatus(status) {
    this.store.dispatch(updateStepStatus({
      payload: this.stepStatusUpdatePayloadCostruction.payloadConstruction(this.stepDetails, status),
      subTaskId: this.stepDetails.taskId,
      visaType: this.primaryData.caseType === 'I130' ?
        this.primaryData.caseType : this.primaryData.visatype,
      stepId: this.primaryData.stepId
    }));
    //this.store.dispatch(updateStepStatus({ payload: this.stepStatusUpdatePayloadCostruction.payloadConstruction(this.stepDetails, status), subTaskId: this.stepDetails.taskId, actionCode: this.stepDetails.stepActionList[0].layoutCode }));
  }

  handleLeftPanelSelection(selectedTab, isLastTab) {
    this.guard.canDeactivate().pipe(first()).subscribe((response: boolean) => {
      if (response) {
        this.store.dispatch(new MarkCleanFormAction({
          dirty: false
        }));
        this.isLastTab = isLastTab;
        this.selectedItem = selectedTab;
      } else {
        return false;
      }
    });
  }

  onScrollTop() {
    this.mainBody.nativeElement.scroll(0, 0);
  }

  ngOnDestroy() {
    this.observableSubscription$.next();
    this.observableSubscription$.complete();
    // Update the Step Status state to empty state
    this.store.dispatch(updateStepStatusToInitialState());
  }

}
