import { Injectable } from '@angular/core';
import { Route, Router, UrlSegment } from '@angular/router';
import { AuthenticationService } from './services/authentication.service';
import * as utils from 'src/app/modules/utility/global-utils';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {
  jwt;
  constructor(
    public router: Router,
    private authService: AuthenticationService
  ) { }
  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
    this.jwt = utils.getCookie('UserToken');

    // if (this.location.path() == '/login/login/signin') {

    //   this.router.events.subscribe(event => {
    //     if (event instanceof NavigationEnd) {
    //       console.log(event)
    //       if (this.location.path() == '/login/login/signin') {

    //         this.router.navigateByUrl('/platform-admin/free')


    //       }


    //     }
    //   })

    //   console.log(this.router.url)

    // }

    if (this.jwt && this.jwt !== 'null') {
      return this.authService.validateUser(this.jwt)
      .pipe(
        take(1),
        map((user) => {
          if (user && user.status === 200) {
            debugger
            this.authService.mapUserData(user);
            const userType = sessionStorage.getItem('userTypeRole');
            if (userType == 'Petitioner') {
              this.router.navigate(['/petitioner-landing']);
            } else if (userType == 'Beneficiary') {
              this.router.navigate(['/beneficiary-landing']);
            } else if (userType == 'Attorney') {
              this.router.navigate(['/attorney-landing']);
            } else if (userType == 'Platform') {
              this.router.navigate(['/platform-admin-landing']);
            }
            else if (userType == 'I9Admin') {
              this.router.navigate(['/i9-admin-landing']);
            } else {
              this.router.navigate(['/access-denied']);
            }
            return false;
          } else {
            this.authService.logout();
            return true;
          }
          return true;
        })
      );
    }


    return of(true);

  }
}
