<div class="pb-3" *ngIf="!showFdnsQADetailSection">
    <h5 class="mt-3 heading mb-3 common-bene-class-for-translation">All Beneficiaries</h5>
    <p-table [columns]="cols" [value]="beneficiaryList" tableStyleClass="fdnsBeneListingTable table-striped">
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th *ngFor="let col of columns" class="common-bene-class-for-translation">
                    {{col.header}}
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns" let-expanded="expanded">
            <tr>
                <td *ngFor="let col of columns">
                    <div class="row" *ngIf="col.header === 'Name'">
                        <div class="col-md-2" style="text-align: left;padding:0px;">
                            <button type="button" pButton pRipple (click)="showFdnsQADetail(rowData)"
                                class="p-button-text p-button-rounded p-button-plain"
                                [icon]="expanded ? 'pi pi-chevron-up' : 'pi pi-chevron-down'"></button>
                        </div>
                        <div class="col-md-10 name-div">
                            <span>{{rowData[col.field]}}</span>
                        </div>
                    </div>

                    <span *ngIf="col.header != 'Name'">{{rowData[col.field]}}</span>

                </td>
            </tr>
        </ng-template>
    </p-table>
</div>
<div id="fdnsqaContainer" class="mt-4" *ngIf="showFdnsQADetailSection">
    <!-- <small class="ml-3" (click)="backToList()" class="icn" *ngIf="userType === 'Petitioner'">
        << Back to list</small> -->
    <button class="back-button pr-3 pl-0 back-btn-white-bg" *ngIf="userType === 'Petitioner'" (click)="backToList()"><i
            class="fa fa-chevron-circle-left" aria-hidden="true"></i><span
            class="common-bene-class-for-translation">Back</span></button>

    <p-tabView styleClass="fdnsqa" [(activeIndex)]="activeIndex" (onChange)="handleChange($event)">
        <p-tabPanel header="Overview">
            <div class="row">
                <h6><b class="common-bene-class-for-translation">Overview</b></h6>
                <p class="common-bene-class-for-translation">To safeguard the integrity of the US immigration laws and
                    homeland, and protect American
                    workers, the USCIS created the Fraud Detection and National Security Directorate (FDNS) in
                    2004. FDNS employs numerous measures to detect and deter immigration benefit and has
                    collaborated with the U.S. Immigration and Customs Enforcement (ICE) in a strategic
                    partnership to combat immigration fraud.</p>

            </div>
            <div class="row">
                <h6><b class="common-bene-class-for-translation">Administrative Investigations</b></h6>
                <p class="common-bene-class-for-translation">FDNS can conduct administrative investigations like
                    compliance reviews, interviews, site
                    visits, requests for evidence, and may also result in a referral to ICE for consideration of
                    a criminal investigation. Immigration and Customs Enforcement (ICE) can do surprise
                    workplace visits to assess a company’s compliance with H-1B regulations after the filing of
                    an H-1B visa petition. </p>
            </div>
            <div class="row">
                <h6><b class="common-bene-class-for-translation">How this Q&A will help</b></h6>
                <p class="common-bene-class-for-translation">Developing a policy and practice for being prepared to
                    respond to such visits will help in
                    effectively responding to such visits instead of panic. Also, a comprehensive list of
                    questions to expect during such visits will keep the petitioner and beneficiary stay
                    prepared in advance. Forewarned is forearmed and this FAQ will help you do exactly this.</p>
            </div>
        </p-tabPanel>
        <p-tabPanel header="FDNS Questions & Answers">
            <p-table [columns]="fdnsQaCols" [value]="fdnsQAList" tableStyleClass="fdnsQAListingTable">
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th *ngFor="let col of columns">
                            <span *ngIf="col.header === 'Answers'">
                                <h6 class="common-bene-class-for-translation"> {{col?.header}} </h6>
                                <small class="common-bene-class-for-translation">(Answers are taken from current active
                                    petition)</small>
                            </span>
                            <span *ngIf="col.header != 'Answers'"><span
                                    class="common-bene-class-for-translation">{{col?.header}}</span></span>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="columns">
                    <tr>
                        <td *ngFor="let col of columns">
                            <span *ngIf="col.header === 'Questions'"
                                class="common-bene-class-for-translation">{{rowData[col.field]}}</span>
                            <span *ngIf="!rowData.required && col.header === 'Answers'"><span class="inner-html"
                                    [innerHtml]="rowData[col.field] | keepHtml"></span></span>
                            <span *ngIf="rowData.required && col.header === 'Answers'"><button class="link-button"
                                    (click)="activeIndex = 2">{{rowData[col.field]}}</button></span>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </p-tabPanel>
        <p-tabPanel header="Documents">
            <p-accordion class="fdnsDocAccordion" (onOpen)="onDocumentOpen($event)" (onClose)="onDocumentOpen($event)"
                [activeIndex]="accordianActivedIndex">
                <p-accordionTab *ngFor="let singleDocSection of fdnsDocumentsList;let docIndex=index">
                    <ng-template pTemplate="header" class="common-bene-class-for-translation">
                        {{singleDocSection?.fileRefName}}
                        <span class="pi pi-fw ml-auto"
                            [ngClass]="{'pi-chevron-right': docIndex === selectedDocIndex, 'pi-chevron-down': docIndex != selectedDocIndex}"></span>
                    </ng-template>
                    <ng-template pTemplate="content">
                        <div class="row justify-content-end mt-3" *ngIf="singleDocSection.fileRefName === 'Pay Stubs'">
                            <div class="col-3">
                                <span class="mr-2 common-bene-class-for-translation">Upload New Pay Stubs</span>
                                <button pButton type="button" label="upload" (click)="uploadDocument()"
                                    class="p-button-outlined">

                                </button>
                            </div>
                        </div>
                        <div class="row innerContent mt-3 p-3" *ngIf="singleDocSection?.filesArray?.length > 0">
                            <div class="col-4 common-bene-class-for-translation">Type</div>
                            <div class="col-4 common-bene-class-for-translation">Name</div>
                            <div class="col-4 common-bene-class-for-translation">Action</div>
                        </div>
                        <div *ngFor="let item of singleDocSection.filesArray">
                            <div class="row p-3" *ngIf="item.fileName && item.filePath">
                                <div class="col-4">{{item?.fileRefName}}</div>
                                <div class="col-4">{{item?.fileName}}</div>
                                <div class="col-4">

                                    <span>
                                        <button pButton type="button" (click)="viewDocument(item.filePath)"
                                            class="p-button-icon-only bg-white border-0 text-secondary d-inline">
                                            <mat-icon class="mr-1 icn" matTooltip="View Document"
                                                class="material-icons">visibility</mat-icon>
                                            <!-- <i class="pi pi-eye" matTooltip="View Document"></i> -->
                                        </button>
                                    </span>
                                    <span *ngIf="singleDocSection.fileRefName === 'Pay Stubs'">
                                        <button pButton type="button" (click)="confirmDelete(item.id)"
                                            class="p-button-icon-only bg-white border-0 text-secondary d-inline">
                                            <mat-icon class="mr-1 icn" matTooltip="Delete Document"
                                                class="material-icons">delete</mat-icon>
                                        </button>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </p-accordionTab>
            </p-accordion>

        </p-tabPanel>
    </p-tabView>
</div>
<p-dialog [(visible)]="showFileuploadSection" [modal]="true" [responsive]="true"
    [style]="{width: '500px', minWidth: '200px', height:'400px'}" styleClass="fdns-paystub-upload" [minY]="70"
    [maximizable]="false" [baseZIndex]="60000">

    <ng-template pTemplate="header">
        <div class="row">
            <div class="col-12 p-0 m-0">
                <h6 class="text-primary common-bene-class-for-translation">Upload Pay Stubs</h6>
            </div>
        </div>
    </ng-template>

    <div>
        <form class="p-0" #payStubForm="ngForm">
            <div class="p-fluid p-formgrid p-grid p-2">
                <div class="p-col-12">
                    <label for="frequencyOfPay" class="required common-bene-class-for-translation">Frequency of
                        Pay</label>
                    <p-dropdown class="" inputId="frequencyOfPay" name="frequency" #frequency="ngModel"
                        [(ngModel)]="payStubObj.frequency" [options]="frequencyPayTypeList"
                        placeholder="Select Pay Type" optionLabel="name" optionValue="code" required></p-dropdown>
                    <span class="invalid-message common-bene-class-for-translation"
                        *ngIf="(frequency.touched || frequency.dirty) && frequency.errors?.required">
                        {{ getGlobalErrorMessages("REQUIRED") }}
                    </span>

                </div>
                <div class="p-col-12 mt-2">
                    <label for="payPeriodEndDate" id="payPeriodEndDateBox"
                        class="required common-bene-class-for-translation">Pay period end date</label>
                    <p-calendar name="payPeriodEndDate" #payPeriodEndDate="ngModel" styleClass="fdnsQa-upload-calender"
                        [(ngModel)]="payStubObj.payPeriodEndDate" [showIcon]="true" dateFormat="mm-dd-yy"
                        inputId="dateofvisit" required></p-calendar>
                    <span class="invalid-message common-bene-class-for-translation"
                        *ngIf="(payPeriodEndDate.touched || payPeriodEndDate.dirty) && payPeriodEndDate.errors?.required">
                        {{ getGlobalErrorMessages("REQUIRED") }}</span>
                </div>

                <div class="p-col-12 mt-2">
                    <label class="common-bene-class-for-translation">Please browse to upload paystub</label>
                    <div class="col-12 fileUploadSection ml-2 mt-2 p-2">
                        <p-fileUpload #createUploader name="demo[]" mode="basic" chooseLabel="Browse" [auto]="true"
                            customUpload="true" (uploadHandler)="onUpload($event, createUploader)"
                            [accept]="acceptedTypes" showUploadButton="false" showCancelButton="false"
                            maxFileSize="1000000" [disabled]="payStubForm.invalid">
                        </p-fileUpload>
                    </div>
                </div>
            </div>
        </form>

        <!-- <div class="row">
            <label class="col-12">Please browse file to upload</label>
            <p-fileUpload #createUploader name="demo[]" mode="basic" chooseLabel="Browse" [auto]="true"
                customUpload="true" (uploadHandler)="onUpload($event, createUploader)" accept="application/pdf"
                showUploadButton="false" showCancelButton="false" maxFileSize="1000000">
            </p-fileUpload>
        </div> -->

    </div>

</p-dialog>
<p-confirmDialog header="Delete"></p-confirmDialog>