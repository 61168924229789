import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import * as RolesRouteMap from '../../../data/constants/roles';
import { ReferenceLibrary } from 'src/app/data/models/referenceLibrary.model';
import { ReferenceLibraryService } from 'src/app/services/referenceLibrary.service';
import { debounceTime, distinctUntilChanged, filter, map, take, takeUntil } from 'rxjs/operators';
import { CaseStatusService } from '../services/case-status.service';
import { combineLatest, fromEvent, Subject, Subscription } from 'rxjs';
import {ConfirmationService} from 'primeng/api';
import { ToastrService } from 'ngx-toastr';
import moment from 'moment';
import { CustomErrorToastComponent } from 'custom-error-toast';

@Component({
  selector: 'app-manage-case-status',
  templateUrl: './manage-case-status.component.html',
  styleUrls: ['./manage-case-status.component.scss']
})
export class ManageCaseStatusComponent implements OnInit, OnDestroy {
  caseStatusList = [];
  totalElementCount = 0;
  pageSizeOptions = [10, 20, 50, 100];
  pageIndex = 1; // default
  pageSize = 10; // default
  searchText = '';
  showFilter = false;
  filter = {
    superTaskType: 'NONIMMPET',
    immCategoryTypes:[],
    immClassificationTypes:[],
    visaTypes:[],
    petitionType: [],
    subTaskTypes: [],
    serviceCenterNameIds: [],
    processType: "REGULAR"
  };
  payload = {
    companyId: 0,
    attorneyId:0,
    pageNumber: this.pageIndex,
    pageSize: this.pageSize,
    petitionTypes: this.filter.petitionType,
    subTaskTypes: this.filter.subTaskTypes,
    superTaskType: this.filter.superTaskType,
    serviceCenterNameIds: this.filter.serviceCenterNameIds,
    processType: this.filter.processType,
    visaTypes: this.filter.visaTypes,
    immCategoryTypes: this.filter.immCategoryTypes,
    immClassificationTypes: this.filter.immClassificationTypes,
    searchText:''
  };
  userType: string;
  companyId: number;
  taskType: ReferenceLibrary[];
  immCategoryTypes: ReferenceLibrary[];
  immClassificationTypes: ReferenceLibrary[];
  visaTypes: ReferenceLibrary[];
  petitionTypes: ReferenceLibrary[];
  subTaskTypes: ReferenceLibrary[];
  processTypes: any[] = [{name:'Regular',code:'REGULAR'}, {name:'Premium',code:'PREMIUM'}];
  serviceCenters: any[];
  searchSubscription: Subscription;
  observableSubscription$ = new Subject();
  requestsLoaded$ = new Subject();
  isOnInit:boolean = true;
  receiptNumber;
  cols: any[];
  @ViewChild('searchCaseStatusListTable', { static: true }) searchCaseStatusListTable: ElementRef;
  immigrantTypeCols: { field: string; header: string; width?: string; cssClass?: string;}[];
  nonImmigrantTypeCols: { field: string; header: string; width?: string; cssClass?: string; }[];
  navUrl;
  editNavUrl;
  display: boolean = false;
  receiptNumberContent:string = '';
  caseUpdatedTime:string = '';
  currentCaseItem: any;
  userId: any;
  constructor(
    private router: Router,
    private referenceLibraryService: ReferenceLibraryService,
    private APIService : CaseStatusService,
    private confirmationService: ConfirmationService,
    public toastr: ToastrService) { }

  ngOnInit(): void {
    this.userType = sessionStorage.getItem('userTypeRole');
    this.companyId = parseInt(sessionStorage.getItem('companyId'));
    this.userId = sessionStorage.getItem("userId");
    this.payload.companyId = this.companyId;

    if (this.userType == "Petitioner") {
      this.navUrl = "petitioner-landing/case-status/add-new-case";
      this.editNavUrl = "petitioner-landing/case-status/edit/case/";
      this.payload.companyId = this.companyId;
      this.payload.attorneyId = 0;
    } else if (this.userType == "Attorney") {
      this.navUrl = "attorney-landing/case-status/add-new-case";
      this.editNavUrl = "attorney-landing/case-status/edit/case/";
      this.payload.attorneyId = this.companyId;
      this.payload.companyId =0;
    }
    this.immigrantTypeCols = [
      { field: 'receiptNumber', header: 'Receipt Number', width: '12%' },
      { field: 'beneficiaryName', header: 'Beneficiary Name', width: '13%' },
      { field: 'immCategory', header: 'Immigration Category', width: '13%' },
      { field: 'immClassification', header: 'Immigration Classification', width: '16%' },
      { field: 'serviceCenter', header: 'Service Centre', width: '14%' },
      { field: 'processType', header: 'Process Type', width: '9%' },
      { field: 'shortStatus', header: 'USCIS Case Status', width: '13%', cssClass:'uscis-status-column' }
    ];
    this.nonImmigrantTypeCols = [
      { field: 'receiptNumber', header: 'Receipt Number', width: '12%' },
      { field: 'beneficiaryName', header: 'Beneficiary Name', width: '13%' },
      { field: 'visaType', header: 'Visa Type', width: '17%' },
      { field: 'petitionType', header: 'Petition Type', width: '10%' },
      { field: 'serviceCenter', header: 'Service Centre', width: '15%' },
      { field: 'processType', header: 'Process Type', width: '10%' },
      { field: 'shortStatus', header: 'USCIS Case Status', width: '13%', cssClass:'uscis-status-column' }
    ];
    this.cols = this.nonImmigrantTypeCols;
    this.getTaskType();
    this.getServiceCenters(this.filter.processType);
    this.searchSetUp();
    this.requestsLoaded$.pipe(takeUntil(this.observableSubscription$)).subscribe(()=>{
      this.isOnInit = false;
      this.applyFilters();
    });
  }
  getTaskType() {
    this.referenceLibraryService.getReferenceData('SUPTSKTYP')
      .pipe(take(1),takeUntil(this.observableSubscription$))
      .subscribe((response: ReferenceLibrary[]) => {
        this.taskType = response;
        this.onTaskTypeChange();
      });
  }
  onTaskTypeChange(){
    if(this.filter.superTaskType ==='NONIMMPET'){
      this.getVisaTypes();
    }else{
      this.getIMMCategory();
      this.getIMMPetitionType();
    }
    this.applyFilters();
  }
  onVisaTypeChange(){
    this.handleFilterChange();
  }
  getVisaTypes(){
    if(!this.visaTypes){
      this.referenceLibraryService.getReferenceData('VISATYP')
      .pipe(take(1),takeUntil(this.observableSubscription$))
      .subscribe((response: ReferenceLibrary[]) => {
        this.visaTypes = [...new Map(response.map(item =>
          [item['code'], item])).values()];
        this.filter.visaTypes = this.visaTypes.map(x => x.code);
        //this.handleFilterChange();
        this.getPetitionType();
      });
    }else {
      this.filter.visaTypes = this.visaTypes.map(x => x.code);
      this.getPetitionType();
    }
  }
  getIMMClassification() {
    if (!this.immClassificationTypes && this.immCategoryTypes) {
      const req = [];
      this.immClassificationTypes = [];
      this.immCategoryTypes.forEach(immCategory => {
        req.push(this.referenceLibraryService.getReferenceData(immCategory.code));
      });
      combineLatest(req)
        .pipe(take(1))
        .subscribe((response: any[]) => {
          response.forEach((res,k) => {
            this.immClassificationTypes = [...this.immClassificationTypes , ...res];
          });
          this.filter.immClassificationTypes = this.immClassificationTypes.map(x => x.code);
          this.handleFilterChange();
        });
    } else {
      this.filter.immClassificationTypes = this.immClassificationTypes.map(x => x.code);
      this.handleFilterChange();
    }
  }
  getIMMCategory() {
    if (!this.immCategoryTypes) {
      this.referenceLibraryService.getReferenceData('GCIMMCATG')
        .pipe(take(1))
        .subscribe((response: ReferenceLibrary[]) => {
          this.immCategoryTypes = response;
          this.filter.immCategoryTypes = this.immCategoryTypes.map(x => x.code);
          this.getIMMClassification();
        }); 
    } else {
      this.filter.immCategoryTypes = this.immCategoryTypes.map(x => x.code);
      this.getIMMClassification();
    }
  }
  getIMMPetitionType(){
    this.APIService.getSubTasksType(this.filter.superTaskType)
      .pipe(take(1),takeUntil(this.observableSubscription$))
      .subscribe((response: ReferenceLibrary[]) => {
        this.subTaskTypes = [...new Map(response.map(item =>
          [item['code'], item])).values()];
        this.subTaskTypes.push({
          code: "PETITION",
          desc: "Petition",
          group: "TASKTYP",
          id: 494,
          name: "Petition"
        })
        this.filter.subTaskTypes = this.subTaskTypes.map(x => x.code);
        this.handleFilterChange();
      });
  }
  getPetitionType() {
    this.referenceLibraryService.getReferenceData('PETTYP')
      .pipe(take(1),takeUntil(this.observableSubscription$))
      .subscribe((response: ReferenceLibrary[]) => {
        this.petitionTypes = response;
        this.filter.petitionType = this.petitionTypes.map(x => x.code);
        this.handleFilterChange();
        if(this.isOnInit){
          this.requestsLoaded$.next();
        }
    });
  }

  getServiceCenters(processType){
    let flag = processType == 'PREMIUM' ? true : false;
    this.APIService.getServiceCenters(flag).pipe(take(1),takeUntil(this.observableSubscription$)).subscribe((response)=>{
      this.serviceCenters = response;
      this.filter.serviceCenterNameIds = this.serviceCenters.map(x => x.id);
      this.handleFilterChange();
    });
  }

  handleFilterChange() {
    if(this.filter.superTaskType ==='NONIMMPET'){
      this.payload.immClassificationTypes = [];
      this.payload.immCategoryTypes = [];
      this.payload.subTaskTypes = [];
      this.payload.visaTypes = this.filter.visaTypes;
      this.payload.petitionTypes = this.filter.petitionType;
    }else{
      this.payload.immClassificationTypes = this.filter.immClassificationTypes;
      this.payload.immCategoryTypes = this.filter.immCategoryTypes;
      this.payload.petitionTypes = [];
      this.payload.visaTypes = [];
      this.payload.subTaskTypes = this.filter.subTaskTypes;
    }
    this.payload.superTaskType = this.filter.superTaskType;
    this.payload.processType = this.filter.processType;
    this.payload.serviceCenterNameIds = this.filter.serviceCenterNameIds;
  }

  applyFilters(){
    this.searchText = '';
    this.payload.pageNumber = 1;
    this.payload.pageSize = 10;
    this.cols = this.filter.superTaskType ==='NONIMMPET' ? this.nonImmigrantTypeCols : this.immigrantTypeCols;
    this.handleFilterChange();
    this.getCaseStatuses();
  }

  searchSetUp() {
    this.searchSubscription = fromEvent(this.searchCaseStatusListTable.nativeElement, 'input').pipe(
      map((event: any) => { return event.target.value; })
      , filter(res => res.length > 2 || !res)
      , debounceTime(1000)
      , distinctUntilChanged()).subscribe((text: string) => {
        this.searchText = text.trim();
        this.payload.searchText = this.searchText;
        this.getCaseStatuses();
      });
  }


  getCaseStatuses(){
    this.APIService.getCaseStatuses(this.payload,(this.userType == "Attorney" ? this.companyId : null))
    .pipe(takeUntil(this.observableSubscription$))
    .subscribe((data) => {
      if(data['caseStatuses']){
        this.caseStatusList =data['caseStatuses'].map((el)=>{
          el['updatedDate'] = el['modifiedDate'] ? moment(el['modifiedDate']).format('L') : '';
          el['updatedTime'] = el['modifiedDate'] ? moment(el['modifiedDate']).format('LT') : '';
          return el;
        });
      }else{
        this.caseStatusList = [];
      }
      this.caseStatusList = data['caseStatuses'] ? data['caseStatuses'] : [];
      this.totalElementCount = data['totalElementCount'] ? data['totalElementCount'] : 0;
    });
  }

  paginationEvent(event) {
    this.payload.pageNumber = event.page + 1;
    this.payload.pageSize = event.rows;
    this.getCaseStatuses();
  }

  breadCrumbNavigation() {
    this.router.navigate([`../../${RolesRouteMap.userRoleRouteMapping[sessionStorage.getItem('userTypeRole')]}`]);
  }

  addnew(){
    this.router.navigate([this.navUrl])
  }

  editCase(_data, id){
    this.router.navigate([this.editNavUrl + id]);
  }

  deleteCase(caseId){
    this.APIService.deleteCase(caseId)
    .pipe(takeUntil(this.observableSubscription$))
    .subscribe((response) => {
      this.getCaseStatuses();
      this.toastr.success(response.message, 'Success');
    });
  }

  confirmDelete(item){
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete?',
      key:'deletecasestatus',
      accept: () => {
        this.deleteCase(item.id);
      },
      reject: () => {
      }
    });
  }
  
  refreshAllCases(){
    this.APIService.refereshCases(this.payload)
    .pipe(takeUntil(this.observableSubscription$))
    .subscribe((response) => {
      if(response){
        this.getCaseStatuses();
        this.toastr.success(response.message, 'Success');
      }
    });
  }

  ngOnDestroy() {
    this.observableSubscription$.next();
    this.observableSubscription$.complete();
  }

  showUSCISDialog(caseItem:any) {
    this.currentCaseItem = caseItem;
    this.receiptNumber = caseItem['receiptNumber']
    this.receiptNumberContent = caseItem.uscisStatus;
    this.caseUpdatedTime = this.currentCaseItem['updatedDate'] && this.currentCaseItem['updatedTime'] ? this.currentCaseItem['updatedDate'] +' '+ this.currentCaseItem['updatedTime'] : '';
    this.display = true;

    console.log('caseItem', caseItem);
  }

  copyRecieptNo() {
    navigator.clipboard.writeText(this.receiptNumber);
    this.toastr.success('Reciept Number Copied', 'Success')
  }

  cancelDialog(){
    this.display = false;
  }

  refreshReceipt(){
    this.APIService.fetchContentWithreceiptNumber(this.currentCaseItem.receiptNumber)
    .pipe(takeUntil(this.observableSubscription$))
    .subscribe((response) => {
      if(response && response.message){
        const isError = response.message.search('Error:');
        if(isError === -1){
          this.currentCaseItem.uscisStatus = response.message;
          let timeDateArr = response.date_time.split(' ');
          this.currentCaseItem['updatedTime'] = timeDateArr[0];
          timeDateArr.shift();
          this.currentCaseItem['updatedDate'] = timeDateArr.join(' ');
          this.receiptNumberContent = response.message;
          this.caseUpdatedTime =  this.currentCaseItem['updatedDate']+ ' ' +this.currentCaseItem['updatedTime'];
          this.toastr.success('Case Status updated successfuly', 'Success');
        }else{
          CustomErrorToastComponent.showErrorToast(this.toastr, response.message);
        }
      }else{
        this.receiptNumberContent = this.currentCaseItem.uscisStatus;
        this.caseUpdatedTime = '';
        CustomErrorToastComponent.showErrorToast(this.toastr, 'Unable to update the Case Status');
      }
    });
  }
}
