import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, pluck } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ImagilityBaseResponse } from '../data/models/response.model';


@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(private httpClient: HttpClient) { }

  getCompanies() {
    return this.httpClient.get('/company').pipe(
      pluck('data'),
      map((companies: Array<any>) => {
     //   console.log('companies :-' + JSON.stringify(companies));

        companies.forEach(item => {
          if (item.address.length > 0) {
            item.address = item.address[0].addressLine1;
            item.email = item.emailContacts && item.emailContacts.find(x => x.type.code === 'PRIM') ?
              item.emailContacts.find(x => x.type.code === 'PRIM').email : '';
            const phone = item.phoneContacts.find(x => x.type.code === 'OFFC');
            item.phone = phone ? phone.phoneNo : null;
            item.plandetails = item.ftCompanyPlan && item.ftCompanyPlan.find(x => x.ftMasterFreeTrialPlan.planName === 'Attorney Plan') ?
            item.ftCompanyPlan.find(x => x.ftMasterFreeTrialPlan.planName === 'Attorney Plan').planName : '-';
          }
        });
        return companies;
      })
    )
  }

  getCompaniesPlatformAdmin(type){
    return this.httpClient.get(`/company/${type}/list`).pipe(
      map((response: ImagilityBaseResponse) => {
        return response.data;
      })
    )
  }

  getCompanyHierarchyById(companyId: number) {
    return this.httpClient.get(`/company/${companyId}/hierarchy`).pipe(
      pluck('data'),
      map((companies: Array<any>) => {
        companies.forEach(item => {
          if (item.address.length > 0) {
            item.address = item.address[0].addressLine1;
            item.email = item.emailContacts && item.emailContacts.find(x => x.type.code === 'PRIM') ?
              item.emailContacts.find(x => x.type.code === 'PRIM').email : '';
            item.phone = item.phoneContacts.length ? item.phoneContacts[0].phoneNo : '';
            item.plandetails = item.ftCompanyPlan.planName;
          }
        });
        return companies;
      }));
  }

  getCompaniesById(companyId: number): Observable<any> {
    return this.httpClient.get('/company/' + companyId).pipe(
      map((response: ImagilityBaseResponse) => response.data)
    );
  }

  getParentCompanyList(payload): Observable<any> {
    return this.httpClient.post('/company/parent', payload).pipe(
      pluck('data'),
      map((dta) => {
        let elements: Array<any> = dta['elements'];
        elements.forEach(item => {
        //  console.log(' item.ftCompanyPlan.planName:-' + JSON.stringify(item.ftCompanyPlan.ftMasterFreeTrialPlan.planName));
          if (item.address.length > 0) {
            item.address = item.address[0].addressLine1;
            item.email = item.emailContacts && item.emailContacts.find(x => x.type.code === 'PRIM') ?
              item.emailContacts.find(x => x.type.code === 'PRIM').email : '';
            const phone = item.phoneContacts.find(x => x.type.code === 'OFFC');
            item.phone = phone ? phone.phoneNo : null;

            // item.plandetails = item.ftCompanyPlan && item.ftCompanyPlan.find(x => x.ftMasterFreeTrialPlan.planName === 'Petitioner Plan') ?
            // item.ftCompanyPlan.find(x => x.ftMasterFreeTrialPlan.planName === 'Petitioner Plan').planName : '-';

            // Use optional chaining and nullish coalescing to fix above commented code
            item.plandetails = item?.ftCompanyPlan?.find(x => x?.ftMasterFreeTrialPlan?.planName === 'Petitioner Plan')?.planName ?? '-';
          }
        });
        return dta;
      })
    )
  }

  getAssocCompanies(companyId) {
    return this.httpClient.get('/company/' + companyId + '/associates').pipe(
      pluck('data'),
      map((companies: Array<any>) => {
        companies.forEach(item => {
          item.address = item.address.length > 0 ? this.formatAddress(item?.address[0]?.addressLine1,item?.address[0]?.addressLine2,item?.address[0]?.city
            ,item?.address[0]?.countryCode?.countryName, item?.address[0]?.postCode ) : "";
          item.email = item.emailContacts.length > 0 ? item.emailContacts[0].email : "";
          const phone = item.phoneContacts.find(x => x.type.code === 'OFFC');
          item.phone = phone ? phone.phoneNo : null;
          item.plandetails = item.ftCompanyPlan.planName;
        });
        return companies;
      })

    );
  }
  formatAddress(...args){
    let fulladress = ''; 
    args.forEach((x,i) => {
        if (x) {
        //  console.log(i)
          if(i !== (args.length-1)){
          fulladress += (x + ', ');
          }else{
            fulladress += (x);
          }
        }
    });
               return fulladress;
            }

  saveCompany(payload) {
    return this.httpClient.post(`/company`, payload).pipe(
      map((response: ImagilityBaseResponse) => response)
    );
  }

  saveCompanyInfo(payload) {
    return this.httpClient.post(`/attor/updatecompany`, payload).pipe(
      map((response: ImagilityBaseResponse) => response)
    );
  }

  saveCompanyDescription(payload) {
    return this.httpClient.post(`/company/${payload.companyId}/description`, payload).pipe(
      map((response: ImagilityBaseResponse) => response)
    );
  }

  getCompanyDescription(companyId: number) {
    return this.httpClient.get(`/company/${companyId}/description`).pipe(
      map((response: ImagilityBaseResponse) => {
        return response.data;
      })
    )
  }

  saveCompanyFeesInfo(payload) {
    return this.httpClient.post(`/question/response`, payload).pipe(
      map((response: ImagilityBaseResponse) => response)
    );
  }

  getQuestions(groupCode: string, companyId: number) {
    return this.httpClient.get(`/question/category/${groupCode}/COMPANY/${companyId}`).pipe(
      map((response: ImagilityBaseResponse) => {
        return response.data;
      })
    )
  }

  deleteCompany(companyId) {
    console.log('companyId ::-' + companyId);
    return this.httpClient.delete('/company/' + companyId).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    )
  }

  deActivateCompany(companyId) {        
    return this.httpClient.put(`/company/deactivate/${companyId}`, {}).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    )
  }

  updateProfilePic(companyId, payload) {
    return this.httpClient.post(`/company/${companyId}/companyLogo`, payload).pipe(
      map((response) => response)
    );
  }

  getCompanyNaics(companyId) {
    return this.httpClient.get(`/companyNaics/${companyId}`).pipe(
      map((response: ImagilityBaseResponse) => {
        return response.data;
      })
    )
  }

  setCompanyNaics(companyId, payload) {
    return this.httpClient.post(`/companyNaics/${companyId}`, payload).pipe(
      map((response: ImagilityBaseResponse) => response)
    );
  }

  onSearchNaics(text) {
    return this.httpClient.get(`/naicsMaster?searchText=${text}`).pipe(
      map((response: ImagilityBaseResponse) => {
        return response.data;
      })
    )
  }
  
  getCompanyHierarchyUserInfo(companyId: number): Observable<any> {
    return this.httpClient.get(`/company/${companyId}/createnoofusers/validation`).pipe(
      map((response: ImagilityBaseResponse) => response)
    );
  }

  uploadAuthorisedSignatory(companyId, signatoryId, payload) {
    return this.httpClient.post(`/company/${companyId}/signatory/${signatoryId}/signature`, payload).pipe(
      map((response) => response)
    );
  }

  deleteAuthorisedSignatory(companyId, signatoryId) {    
    return this.httpClient.delete(`/company/${companyId}/signatory/${signatoryId}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    )
  }

  deleteSignature(companyId, signatoryId) {
    return this.httpClient.delete(`/company/${companyId}/signatory/${signatoryId}/deleteSignature`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    )
  }

}
