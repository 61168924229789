import { Component, Input, OnInit, ViewChild, EventEmitter, ChangeDetectorRef, Output, Inject  } from '@angular/core';
import { forkJoin } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import moment from 'moment';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { I9MultiUploadDocumentsServiceService } from '../i9-multi-upload-documents/i9-multi-upload-documents-service.service';
declare var $: any;

@Component({
  selector: 'i9-select-upload-document-list',
  templateUrl: './i9-select-upload-document-list.component.html',
  styleUrls: ['./i9-select-upload-document-list.component.scss']
})
export class I9SelectUploadDocumentListComponent implements OnInit {

  getDocumentData: any;
  @Output() sendDocumentData: any;
  listBC:string = '';
  i9Section={name:'', code:'', group:'', isCheked: false};
  @Input() List_A;
  @Input() List_B;
  @Input() List_C; 
  @Input() List_B1;
  @Input() listOnly = false;
  currentSection = ''; 
  isFilePresent = false;
  fileType;
  fileB;
  fileC;
  fileA = [];
  fileCategoryB;
  fileCategoryC;
  lockBandC;
  lockA;

  arry = [];
  currentSelectedRadio:any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private docService: I9MultiUploadDocumentsServiceService,
    private router: Router,
    private cRef: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<I9SelectUploadDocumentListComponent>
  ) {
    this.getDocumentData = data;
  }

  ngOnInit(): void {
    this.currentSection = this.data.sectionName ? this.data.sectionName : null;
  }

  uploadFileA(value, fileCategory, code, group) {
    console.log(value, fileCategory);
    this.fileA.push({type: fileCategory, value: value[0], code: code, group: group, listType: 'A'});
    this.List_A.forEach((item) => {if(item.name == fileCategory) {item.selected = true}});
    this.lockBandC = true;
  }

  uploadFileC(value, fileCategory, code, group) {
    this.fileC = {type: fileCategory, value: value[0], code: code, group: group, listType: 'BC'};
    this.fileCategoryC = fileCategory;
    this.lockA = true;
  }

  uploadFileB(value, fileCategory, code, group) {
    this.fileB = {type: fileCategory, value: value[0], code: code, group: group, listType: 'BC'};
    this.fileCategoryB = fileCategory;
    this.lockA = true;
  }

  removeFileA(i, file) {
    this.fileA = this.fileA.filter((item, ind) => ind != i);
    this.List_A.forEach((item) => {if(item.name == file.type) {item.selected = false}});
    console.log(document.getElementById(file.type));
    (<HTMLInputElement>document.getElementById(file.type)).value = '';
    if(!this.fileA.length) {
      this.lockA = false;
      this.lockBandC = false;
    }
  }

  removeFileB() {
    this.fileB = null;
    this.fileCategoryB = null;
    this.lockBandC = false;
    if(!this.fileC) {
      this.lockA = false;
    }
  }

  removeFileC() {
    this.fileC = null;
    this.fileCategoryC = null;
    this.lockBandC = false;
    if(!this.fileB) {
      this.lockA = false;
    }
  }

  addDocTolist() {
     let addedFiles = this.fileA?.length ? this.fileA : [this.fileB, this.fileC];
     this.docService.addedDoc.next(addedFiles)
  }

  handleUpload() {

  }

  section_Change(item) {
    if(this.getDocumentData.sectionName == 'Section1' || this.getDocumentData.sectionName == 'Section-1'){
      this.i9Section = {name: 'Section-1 I9 Form', code:'UPLOADI9FORMSEC1', group:'I9FORMSECTION',  isCheked: item.isCheked};
    }
    else if(this.getDocumentData.sectionName == 'Section2' || this.getDocumentData.sectionName == 'Section-2'){
      this.i9Section = {name: 'Section-2 I9 Form', code:'UPLOADI9FORMSEC2', group:'I9FORMSECTION',  isCheked: item.isCheked};
    }
    else if(this.getDocumentData.sectionName == 'Section3' || this.getDocumentData.sectionName == 'Section-3'){
      this.i9Section = {name: 'Section-3 I9 Form', code:'UPLOADI9FORMSEC3', group:'I9FORMSECTION',  isCheked: item.isCheked};
    }

    this.currentSelectedRadio = this.i9Section;
  }

  list_A_Change(item) {
    this.currentSelectedRadio = item;
  }
 
  list_B_Change(item) {
    this.currentSelectedRadio = item;
  }

  list_C_Change(item) {
    this.currentSelectedRadio = item;
  } 

  addDoc(){
    this.dialogRef.close({ data: this.currentSelectedRadio })

  }

  handleCancel(){
    this.dialogRef.close({ data: null })
  }
 
}
