<div class="row">
  <div class="col-md-12 add-task-btn py-3 px-0">
    <!-- <button type="button" (click)="addNewVisit()"
            class="im-btn add-task-list-btn button_whitebg im-btn-with-icon im-btn-outline-primary">
            <i class="fa fa-plus im-drop-icon"></i>&nbsp; Add New Visit
        </button> -->
    <!-- <p-button label="Add New Visit" icon="pi pi-plus" (click)="addNewVisit()"></p-button>  -->
    <button  type="submit" class="btn btn-primary mt-0 float-right btn-add" label="Add New Visit" 
    (click)="addNewVisit()"><i class="fa fa-plus-circle" aria-hidden="true"></i>Add Visit</button>
  </div>
</div>
<div class="row">
  <div class="col-2 pl-0">
    <div class="lbl-text">
      <!-- <h5>All Visits ({{ totalElementCount }})</h5> -->
      <h5 class="mt-2 heading">All visits</h5>
    </div>
  </div>
  <div class="col-4"></div>
  <div class="col-3">
    <p-calendar [(ngModel)]="payload.visitDate" [showIcon]="true" dateFormat="mm-dd-yy" inputId="dateofvisit"
      (onSelect)="getFilteredData()" placeholder="Search by Date" styleClass="float-right"></p-calendar>
    <span *ngIf="payload.visitDate" (click)="resetSearch('calender')" class="calenderCloseIcon icn"><i
        class="pi pi-times" matTooltip="Clear Search"></i></span>

  </div>

  <div class="col-3 pr-0">
    <!--class not using mr-search-->
    <div class="input-group search-text search-input-group w-100">
      <input class="form-control rounded-pill im-pt-3 pr-5" type="search" #searchFdnsVisitsTable
        (search)="resetSearch('location')" placeholder="Search By Location">
      <span class="input-group-append">
        <button class="btn rounded-pill ml-n4r p-0" type="button">
          <i class="fa fa-search border-0"></i>
        </button>
      </span>
    </div>
  </div>
</div>
<div class="mt-3" *ngIf="fdnsVisitList.length > 0">
  <p-table [columns]="cols" class="pa-table im-card-no-border-table im-card-table-noborder-spacing"
    styleClass="p-datatable-gridlines fdnsVisitListingTable" [value]="fdnsVisitList" dataKey="id">
    <ng-template pTemplate="header" let-columns>
      <tr class="text-uppercase">
        <th style="width: 3rem"> </th>
        <!-- <th *ngFor="let col of columns" pSortableColumn="col">
                {{col.header}}
            </th> -->

        <th pSortableColumn="visitDate">Date of Visit <p-sortIcon field="visitDate"></p-sortIcon>
        </th>
        <th pSortableColumn="companyName">Company Name <p-sortIcon field="companyName"></p-sortIcon>
        </th>
        <th pSortableColumn="visitLocationName">Visit Location <p-sortIcon field="visitLocationName"></p-sortIcon>
        </th>
        <th pSortableColumn="visitOfficerName">Visit Officer <p-sortIcon field="visitOfficerName"></p-sortIcon>
        </th>
        <th pSortableColumn="modeOfVistName">Mode Of Visit <p-sortIcon field="modeOfVistName"></p-sortIcon>
        </th>
        <th pSortableColumn="visitOutcomeStatusName">Status <p-sortIcon field="visitOutcomeStatusName"></p-sortIcon>
        </th>

        <th>Action </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-fdnsVisit let-expanded="expanded" let-columns="columns">
      <tr>
        <td>
          <button type="button" pButton pRipple [pRowToggler]="fdnsVisit"
            class="p-button-text p-button-rounded p-button-plain"
            [icon]="expanded ? 'pi pi-chevron-up' : 'pi pi-chevron-down'"></button>
        </td>
        <td *ngFor="let col of columns">

          <span *ngIf="col.field === 'visitDate'">
            {{fdnsVisit[col.field] | ImgDateFormat}}
          </span>
          <span *ngIf="col.field != 'visitDate'">
            {{fdnsVisit[col.field]}}
          </span>
        </td>
        <td>
          <!-- <button pButton type="button" icon="pi pi-pencil" (click)="handleEditFdns(fdnsVisit)"
            matTooltip="Edit this FDNS" class="p-button-text"></button>
          <button pButton type="button" icon="pi pi-trash" (click)="handleDeleteFdns(fdnsVisit)"
            matTooltip="Delete this FDNS" class="p-button-text"></button> -->

            <mat-icon  (click)="handleEditFdns(fdnsVisit)" class="material-icons icn mr-1">create</mat-icon>
            <mat-icon (click)="handleDeleteFdns(fdnsVisit)" class="material-icons icn ml-1">delete</mat-icon>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="rowexpansion" let-fdnsVisit>
      <tr>
        <td colspan="8" *ngIf="fdnsVisit?.beneficiaryId">
          <div class="p-p-3 innerTableBeneInfo">
            <div class="row font-weight-bold">
              <div class="col-3">Beneficiary Name</div>
              <div class="col-3">Visa Type</div>
              <div class="col-3">Petition Type</div>
              <div class="col-3">Job Title</div>
            </div>
            <div class="row mt-2">
              <div class="col-3">{{fdnsVisit?.beneficiaryFirstName}} {{fdnsVisit?.beneficiaryLastName}}</div>
              <div class="col-3">{{fdnsVisit?.visaTypeName}}</div>
              <div class="col-3">{{fdnsVisit?.petitionTypeName}}</div>
              <div class="col-3">{{fdnsVisit?.jobTitle}}</div>
            </div>

          </div>
        </td>
        <td colspan="8" *ngIf="!(fdnsVisit?.beneficiaryId)">
          <div class="p-p-3 innerTableBeneInfo">
            <p class="text-center">This visit is related to petitioner. So beneficiary details not available</p>
          </div>
        </td>
      </tr>
      <tr *ngIf="fdnsVisit?.fdnsDocumentDTO.length">
        <td colspan="8">
          <div class="row documents-margin">
            <div class="col-md-12 pl-0">
              <button *ngFor="let item of fdnsVisit.fdnsDocumentDTO; let i = index"
              (click)="displayDocument(item?.filePath)" class="btn btn-primary mb-2 float-left ml-0 btn-xs"> {{ item?.fileName }} </button>
            </div>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>

  <div class="row justify-content-md-center">

    <p-paginator [rows]="pageSize" (onPageChange)="paginationEvent($event)" *ngIf="fdnsVisitList?.length > 0"
      [totalRecords]="totalElementCount" [rowsPerPageOptions]="pageSizeOptions" length="totalElementCount">
    </p-paginator>

  </div>
</div>
<div class="mt-2" *ngIf="fdnsVisitList.length === 0">
  <p class="text-center">Currently FDNS Visit data not available</p>
</div>
<p-confirmDialog header="Delete"></p-confirmDialog>
