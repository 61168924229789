import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import SignaturePad from 'signature_pad';
import {I9Service} from '../services/i9-service';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { Subject } from 'rxjs/internal/Subject';
import { ImagilityBaseResponse } from 'app-models';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { base64StringToBlob } from 'blob-util';


@Component({
  selector: 'app-signature-pad',
  templateUrl: './signature-pad.component.html',
  styleUrls: ['./signature-pad.component.scss']
})
export class SignaturePadComponent implements OnInit {
  signatureNeeded!: boolean;
  signaturePad!: SignaturePad;
  @ViewChild('canvas') canvasEl!: ElementRef;
  signatureImg!: string;
  observableSubscription$ = new Subject();
  @Input() forPreparer;
  @Output() digiSign = new EventEmitter<any>();
  @Output() digiSignForPreparer = new EventEmitter<any>();
  
  beneficiaryId:any;

  constructor(
    private apiService: I9Service,
    private toastr: ToastrService,
    private activeRoute: ActivatedRoute
  ) { 
    
  }

  ngOnInit(): void {
    this.activeRoute.queryParams
    .subscribe(params => {
       this.beneficiaryId = params.beneficiaryId;
    });
  }
  ngAfterViewInit() {
    this.signaturePad = new SignaturePad(this.canvasEl.nativeElement);
  }

  startDrawing(event: Event) {
    // works in device not in browser
  }

  moved(event: Event) {
    // works in device not in browser
  }

  clearPad() {
    this.signaturePad.clear();
  }

  savePad() {
    const base64Data = this.signaturePad.toDataURL();
    this.signatureImg = base64Data;

    const commaSeperator = base64Data.split(',');

    const contentType = 'image/png';
    const b64Data = commaSeperator[1];

    const blob = base64StringToBlob(b64Data, contentType);
    var file = new File([blob], "signature.png");

    const formData = new FormData();
    formData.append('file', file);
    if(this.forPreparer) {
      this.digiSignForPreparer.emit(file);
    } else {
      this.apiService.uploadI9BeneficiarySignature(this.beneficiaryId, formData)
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe((response: ImagilityBaseResponse) => {
        if (response.status == 200) {
          this.toastr.success(response.message, "Success");
          this.digiSign.emit(response.data.DigitalSignature);
        }
      });
    }

    


    // this.apiService.beneficiaryDigitalSignature(this.beneficiaryId,this.signatureImg)
    //   .pipe(takeUntil(this.observableSubscription$))
    //   .subscribe((response: ImagilityBaseResponse) => {
    //     if (response.status == 200) {
         
    //       this.toastr.success(response.message, "Success");
    //       this.digiSign.emit(response.data.DigitalSignature);
    //     }
    //   })
    // this.signatureNeeded = this.signaturePad.isEmpty();
    // if (!this.signatureNeeded) {
    //   this.signatureNeeded = false;
    // }
  }
}