<div class="tab-container border rounded shadow-sm col-12 bg-white">
    <div class="row">
        <div class="col-md-12 p-0">
            <div class="tab">
                <p-tabView [(activeIndex)]="index" class="emailNotification-tab">
                    <router-outlet></router-outlet>
                    <p-tabPanel>
                        <ng-template pTemplate="header">
                            <label>Profile</label>
                        </ng-template>
                        <h2>Coming Soon</h2>
                    </p-tabPanel>
                    <!-- <p-tabPanel [selected]="selectTaskAccessTab">
                        <ng-template pTemplate="header">
                            <label>I-9 Summry</label>
                        </ng-template>
                        <app-past-documents></app-past-documents>
                    </p-tabPanel> -->
                    <p-tabPanel  [selected]="selectNotificationTab">
                        <ng-template pTemplate="header">
                            <label>Compliance</label>
                        </ng-template>
                        <h2>Coming Soon</h2>
                    </p-tabPanel>
                    <p-tabPanel  [selected]="selectNotificationTab">
                        <ng-template pTemplate="header">
                            <label>Request Data and Document</label>
                        </ng-template>
                        <h2>Coming Soon</h2>
                    </p-tabPanel>
                </p-tabView>
        </div>
    </div>
</div>