<div class="container-fluid padding-top-bottom-15 new-dashboard">

  <div class="row margin-bottom-15">
    <div class="col-12">
      <div class="row m-0">
        <div class="col-8">
          <span class="user-name">Welcome {{ userName }}!</span>
        </div>



        <div *ngIf="!isI9Admin" class="col-4 align-self-center">
          <span class="user-time pull-right">
            <span class="material-icons pull-left">calendar_today</span><span> {{ userTimeNow }}</span>
            <!-- <a class="im-nav-link" routerLink="scheduling"><img src="/assets/images/Resources_icon.png" width="20px"
                alt="">
              Calendar</a> -->
          </span>
        </div>


        <!-- <div class="col-4 d-flex justify-content-end align-items-center">
          <div class="align-self-center pr-0 ml-auto">
            <div>
              <div style="border: 1px solid #19A0DA" class="pull-left rounded p-1 mail-align">
                <span title="Go to My Mail" class="user-time  icn" routerLink="email-client">
                  <mat-icon> mail_outline</mat-icon>
                  <span class="pull-right calendar pl-1 pr-1">
                    Mail</span>
                </span>
              </div>

              <div style="border: 1px solid #19A0DA" class="pull-right rounded p-1 ml-2">
                <span title="Go to My Calendar" class="user-time  icn" routerLink="scheduling"
                  style="padding-left:10px !important;">
                  <span class="material-icons pull-left">calendar_today</span>
                  <span class="pull-right calendar">
                    Calendar</span>
                </span>
              </div>
            </div>
          </div>
        </div> -->


      </div>
    </div>
  </div>
  <div class="row margin-top-bottom-15">
    <div class="col-8">
      <task-list [isI9Admin]="isI9Admin" class="dashboard-task-list"></task-list>
      <div class="row col-md-12 p-0 mt-4" *ngIf="isI9Admin">
        <div class="col-md-6 pt-0 pl-0 pb-0">
          <div style="position: relative" class="dashboard-i9-card h-100">
            <mat-card class="mat-card text-center rounded h-100">
              <mat-card-header class="border-bottom">
                <mat-card-title>Public Access </mat-card-title>
              </mat-card-header>
              <mat-card-content class="pb-2 pt-2">
                <div class="row pt-2 pb-2">
                  <div class="col-md-2">
                    <div class="pull-left rounded p-1 mail-align public-acc">{{publicAccess?.LCA ? publicAccess?.LCA : 0}}</div>
                  </div>
                  <div class="col-md-10">
                    <label class="pub-label">LCA (Labour Condition Application) </label>
                  </div>
                </div>
                <div class="row pt-2 pb-2">
                  <div class="col-md-2">
                    <div class="pull-left rounded p-1 mail-align public-acc">{{publicAccess?.WageDetermination ? publicAccess?.WageDetermination : 0
                      }}</div>
                  </div>
                  <div class="col-md-10">
                    <label class="pub-label">Wage Determination</label>
                  </div>
                </div>
                <div class="row pb-2">
                  <div class="col-md-2">
                    <div class="pull-left rounded p-1 mail-align public-acc">{{publicAccess?.WorkforceNotification ? publicAccess?.WorkforceNotification : 0
                      }}</div>
                  </div>
                  <div class="col-md-10">
                    <label class="pub-label">Workforce Notification</label>
                  </div>
                </div>
                <div class="row pb-2">
                  <div class="col-md-2">
                    <div class="pull-left rounded p-1 mail-align public-acc">{{publicAccess?.LCAAcknowledgment ? publicAccess?.LCAAcknowledgment : 0}}</div>
                  </div>
                  <div class="col-md-10">
                    <label class="pub-label">LCA Acknowledgment</label>
                  </div>
                </div>
                <!-- <div class="row pb-2">
                  <div class="col-md-2">
                    <div class="pull-left rounded p-1 mail-align public-acc">{{publicAccess?.LCA}}</div>
                  </div>
                  <div class="col-md-10">
                    <label class="pub-label">LCA Posting</label>
                  </div>
                </div> -->

              </mat-card-content>
              <mat-card-actions>
                <p class="show-more"><a routerLink="../../companies/compliance/compliance/public-access">Show More</a>
                </p>
              </mat-card-actions>
            </mat-card>
          </div>
        </div>
        <div class="col-md-6 p-0">
          <div style="position: relative" class="dashboard-i9-card h-100">
            <mat-card class="mat-card text-center rounded h-100">
              <mat-card-header class="border-bottom">
                <mat-card-title>LCA </mat-card-title>
              </mat-card-header>
              <mat-card-content class="pb-2 pt-2">
                <div class="row pt-2 pb-2">
                  <div class="col-md-2">
                    <div class="pull-left rounded p-1 mail-align public-acc">{{getTotalCount(lcaAssign)}}</div>
                  </div>
                  <div class="col-md-10">
                    <label class="pub-label">Total Number of LCA</label>
                  </div>
                </div>
                <div class="row pb-2">
                  <div class="col-md-2">
                    <div class="pull-left rounded p-1 mail-align public-acc">{{lcaAssign?.Unassigned ? lcaAssign?.Unassigned : 0}}</div>
                  </div>
                  <div class="col-md-10">
                    <label class="pub-label">Unassigned LCA</label>
                  </div>
                </div>
                <div class="row pb-2">
                  <div class="col-md-2">
                    <div class="pull-left rounded p-1 mail-align public-acc">{{lcaAssign?.Assign ? lcaAssign?.Assign : 0}}</div>
                  </div>
                  <div class="col-md-10">
                    <label class="pub-label">Assign</label>
                  </div>
                </div>
                <div class="row pb-2">
                  <div class="col-md-2">
                    <div class="pull-left rounded p-1 mail-align public-acc">{{lcaAssign?.Withdrawn ? lcaAssign?.Withdrawn : 0}}</div>
                  </div>
                  <div class="col-md-10">
                    <label class="pub-label">Withdrawn</label>
                  </div>
                </div>
                <div class="row pb-2">
                  <div class="col-md-2">
                    <div class="pull-left rounded p-1 mail-align public-acc">{{lcaAssign?.Certified ? lcaAssign?.Certified : 0}}</div>
                  </div>
                  <div class="col-md-10">
                    <label class="pub-label">Certified</label>
                  </div>
                </div>
                <div class="row pb-2">
                  <div class="col-md-2">
                    <div class="pull-left rounded p-1 mail-align public-acc">{{lcaAssign?.NotCertified ? lcaAssign?.NotCertified : 0}}</div>
                  </div>
                  <div class="col-md-10">
                    <label class="pub-label">NotCertified</label>
                  </div>
                </div>

              </mat-card-content>
              <mat-card-actions>
                <p class="show-more"><a routerLink="../../lca/lca">Show More</a>
                </p>
              </mat-card-actions>
            </mat-card>
          </div>
        </div>
      </div>
    </div>
    <div class="col-4">
      <p-card class="quick-card" *ngIf="isI9Admin">
        <h6 class="m-0 pb-2 pl-3"> Quick Access </h6>
        <div class="row row-cols-5">
          <div class="col-md-4">
            <div class="petition-grid light-blue-petition-grid">
              <div class="white-box">
                <div class="row">
                  <div class="col-5 quick-accsess">
                    <mat-icon> list_alt</mat-icon>
                  </div>
                </div>
                <p class="petiotion-label">I-9 Initiation New Employee</p>
              </div>
              <div class="petiotion-view"> <a class="link back-button pl-0 pr-3 back-link back-btn-white-bg"
                (click)="goToCreateBene()">
                  View All</a>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="petition-grid light-blue-petition-grid">
              <div class="white-box">
                <div class="row">
                  <div class="col-5 quick-accsess">
                    <mat-icon> list_alt</mat-icon>
                  </div>
                </div>
                <p class="petiotion-label">I-9 Initiation Existing Employee</p>
              </div>
              <div class="petiotion-view"> <a class="link back-button pl-0 pr-3 back-link back-btn-white-bg"
                   (click)="goToBeneList()">
                  View All</a>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="petition-grid light-blue-petition-grid">
              <div class="white-box">
                <div class="row">
                  <div class="col-5 quick-accsess">
                    <mat-icon>description</mat-icon>
                  </div>
                </div>
                <p *ngIf="!isI9Admin" class="petiotion-label">Create LCA</p>
                <p *ngIf="isI9Admin" class="petiotion-label">LCA's</p>
              </div>
              <div class="petiotion-view"> <a class="link back-button pl-0 pr-3 back-link back-btn-white-bg"
                  routerLink="../../lca/lca">
                  View All</a>
              </div>
            </div>
          </div>
        </div>
      </p-card>
      <div class="w-100 d-flex mb-3">
        <div class="mt-2 w-100 bg-white">
          <div class="action-box">
            <div class="flex-row message-box px-3 btm-brder">
              <div>
                <ng-container *ngIf="alertsList.length > 0 ; else noalert">
                  <span class="badge p-2 btn-orange-alert">Alerts</span>
                </ng-container>
                <ng-template #noalert>
                    <span  class="badge p-2 badge-light">Alerts</span>                    
                 </ng-template>
              </div>
              <div class="flex-col font-10 pl-4 mr-auto">
                  <ng-container *ngIf="alertsList.length > 0 ; else noAlertData" >
                    <span class=" text-left alert-text-header">
                      <strong>{{alertsList[alertWidgetPagination.currentIndex]['title']}}</strong>
                    </span>
                    <br>
                    <span class="text-left alert-text-para">
                      {{alertsList[alertWidgetPagination.currentIndex]['text']}}
                    </span>
                  </ng-container>
                  <ng-template #noAlertData>
                    <!-- <span class="badge p-2 btn-orange-alert"><a [routerLink]="['./alerts']">Show Alerts</a></span> -->
                    <span>No alerts for you</span>
                  </ng-template>
                  <!-- <ng-template #noAlertData>
                    <span class="badge p-2 btn-orange-alert"><a [routerLink]="['./alerts']">Show Alerts</a></span>
                  </ng-template> -->
              </div>
              <div class="pt-1 ml-4">
                <ul class="pagination">
      
                  <li [ngClass]="{'disabled-click':alertWidgetPagination.currentIndex == 0 }">
                    <a (click)="setContentIndex('dec')">
                      <span class="material-icons"> keyboard_arrow_left </span>
                    </a>
                  </li>
      
                  <li [ngClass]="{'disabled-click': alertWidgetPagination.currentIndex == alertWidgetPagination.maxIndex}">
                    <a (click)="setContentIndex('inc')">
                      <span class="material-icons">keyboard_arrow_right</span>
                    </a>
                  </li>
      
                </ul>
                <div class="index-track" *ngIf="alertsList.length > 0">
                  {{ alertWidgetPagination.currentIndex+1}}/{{ alertWidgetPagination.maxIndex+1}}
                </div>
              </div>
            </div>
            <div class="text-center pb-2">              
              <p class="show-more"><a [routerLink]="['./alerts']">Show More</a></p>
            </div>
          </div>
        </div>
      </div>
      <notification-list></notification-list>

    </div>
  </div>
  <div class="row margin-top-bottom-15" *ngIf="!isI9Admin">
    <div class="col-8">
      <app-visa-bulletin></app-visa-bulletin>
    </div>
    <div class="col-4">
      <app-individuals-date-tracker class="w-100"></app-individuals-date-tracker>
    </div>    
  </div>
  <div class="row margin-top-15" *ngIf="!isI9Admin">
    <!-- <div class="col-4">
      <information-graph></information-graph>
    </div>
    <div class="col-4">
      <dashboard-team-members-list-preview></dashboard-team-members-list-preview>
    </div>
    <div class="col-4">
      <platform-statistics></platform-statistics>
    </div> -->
    <div class="col-4">
      <calendar-widget></calendar-widget>
    </div>
    <div class="col-4">
      <app-task-deadline></app-task-deadline>
    </div>  
    <div class="col-4">
      <app-todo-checklist></app-todo-checklist>
    </div>   
  </div>
</div>