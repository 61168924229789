import { Component, OnInit, OnDestroy, Input, SimpleChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PetitionerService } from 'src/app/services/petitioner.service';
import { ConfirmationService } from 'primeng/api';
import { take } from 'rxjs/operators';

@Component({
  selector: "app-data-log",
  templateUrl: "./data-log.component.html",
  styleUrls: ["./data-log.component.scss"],
})

export class DataLogComponent implements OnInit, OnDestroy {
  viewDataLogData: any;
  totalRecordCount: number = 0;
  dataSource: MatTableDataSource<any>;
  viewRequestModal: boolean = false;
  dataLogs: any;
  companyId: number;
  taskTypeId: number;
  beneficiaryId: number;
  displayedColumns: string[] = ['beneficiaryName', 'createDt', 'createdBy', 'visaType', 'petitionType', 'status', 'action'];
  @Input() dataLogChanges:  any; 
  

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toastService: ToastrService,
    private confirmationService: ConfirmationService,
    private petitionerService: PetitionerService
  ) {
    this.route.queryParams.subscribe((params) => {      
      this.beneficiaryId = params.beneficiaryEmployeeId;
    });
  } 

  ngOnChanges(event: SimpleChanges) {
    if (event.dataLogChanges && event.dataLogChanges.currentValue) {
      if(sessionStorage.getItem('beneficiaryId')){
        this.beneficiaryId = JSON.parse(sessionStorage.getItem('beneficiaryId'));
        if(sessionStorage.getItem('taskDetailsList')){
          const taskData = JSON.parse(sessionStorage.getItem('taskDetailsList'));
          sessionStorage.setItem("taskTypeId", taskData[0].taskTypeId);
        }
        this.getDataLogList();
      }
    }
  }

  ngOnInit(): void {   
    this.getDataLogList();
  }

  getDataLogList() {
    this.companyId = parseInt(sessionStorage.getItem("companyId"));
    this.taskTypeId = parseInt(sessionStorage.getItem("taskTypeId"));
    if (this.taskTypeId) {
      this.petitionerService.getDataLogs(this.taskTypeId, this.companyId, this.beneficiaryId).subscribe((data) => {
        this.totalRecordCount = data.length;
        this.dataSource = new MatTableDataSource(data);
      });
    }
  }
  
  viewRequest(element){    
    this.viewDataLogData = {
      "qtId": element.qtId,
      "taskTypeId": element.taskTypeId,
      "companyId": this.companyId,
      "taskType": element.taskType,
      "taskTypeCd": element.taskTypeCd,
      "visaType": element.visaType,
      "visaTypeCd": element.visaTypeCd,
      "petitionType": element.petitionType,
      "petitionTypeCd": element.petitionTypeCd,
      "subTaskType": element.subTaskType,
      "subTaskTypeCd": element.subTaskTypeCd,
      "visaTemplateCode": element.visaTemplateCode,
      "id": element.id, // beneficiary id
      "totalCount": "1",
      "isbeneQuestionnaireEdit": "true",
    };   
    this.viewRequestModal = true;   
  }


  openDialog(data, type): void {   
    switch (type) {
      case "delete":
        this.confirmationService.confirm({
          header: "Delete - Sent Request",
          icon: "pi pi-info-circle",
          rejectLabel: "Cancel",
          acceptLabel: "Yes, Delete it",
          acceptButtonStyleClass: "btn-accept-delete",
          rejectButtonStyleClass: "btn-cancel-delete",
          message: `<div> You are about to delete the request sent to the ${data.beneficiaryName} </div>
              <strong>On deletion, beneficiary will no longer be able to see the request.
                All the data entered by beneficiary will be deleted. </strong>
              <div class="my-4">Are you sure you want to <span class="delete-text">delete?</span></div>`,
          accept: () => {
            this.petitionerService.deleteDataLogs(data.id)
            .pipe(take(1))
            .subscribe(result => {              
              this.toastService.success(result.message, 'Success');
              this.getDataLogList();
            });
          },
          reject: () => { },
        });
        break;
      case "resend":
        this.confirmationService.confirm({
          header: "Resend request for data and documents",          
          rejectLabel: "I will do it later",
          acceptLabel: "Yes, send",
          acceptButtonStyleClass: "btn-accept-resend",
          rejectButtonStyleClass: "btn-cancel-resend",
          message: `<h5>You are about to resend the request for data and documents.
          Do you wish to proceed?</h5>`,
          accept: () => {
            this.petitionerService.resendDataLogs(data.id)
              .pipe(take(1))
              .subscribe(result => {                
                this.toastService.success(result.message, 'Success');
                this.getDataLogList();
              });
          },
          reject: () => { },
        });
        break;
      default:
        break;
    }
  }

  ngOnDestroy() {
   
  }

}


