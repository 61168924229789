import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ReferenceLibrary, TaskStep } from 'app-models';
import * as errorUtils from 'error-message-utility';
import { ReferenceLibraryService } from 'reference-library';
import { combineLatest, Subject } from 'rxjs';
import { take, takeUntil, filter } from 'rxjs/operators';
import { ApiServicesService } from 'src/app/modules/beneficiary/services/api-services.service';
import { CacheCountryService } from 'src/app/services/cacheCountries.service';
import { select, Store } from '@ngrx/store';
import { ImagilityBaseResponse, PrimaryData } from 'app-models';
import { ToastrService } from 'ngx-toastr';
import { StepStatusUpdatePayloadService } from 'step-status-update-payload-service';
import { DeleteDialogLibraryComponent } from 'delete-dialog-library';
import { DialogService } from 'primeng/dynamicdialog';
import { MaritalHistoryService } from './marital-history.service';
import { MarkCleanFormAction, MarkDirtyFormAction } from 'dirty-check-store';
import { getStepDetails, updateStepStatus } from 'visa-store';

export const DeleteDialogConfigurations = {
  width: '30%',
  closeOnEscape: false,
  closable: false,
  showHeader: false,
  contentStyle: { 'z-index': '1001' }
};


@Component({
  selector: 'app-marital-history',
  templateUrl: './marital-history.component.html',
  styleUrls: ['./marital-history.component.scss']
})
export class MaritalHistoryComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input()
  primaryData: PrimaryData;
  @Input() type: string;

  beneficiaryPersonalDetails: any;
  maritalHistoryHeaderForm: FormGroup;
  maritalHistoryForm: FormGroup;
  maritalHistoryList = [];
  addEditButtonText;
  disabledControls = false;
  maritalStatusArr: ReferenceLibrary[];
  observableSubscription$ = new Subject();
  numberOfTimeMarriedListOption = [];
  titleListArr: ReferenceLibrary[];
  genderListArr: ReferenceLibrary[];
  listMarriageStates: any = [];
  listBirthStates: any = [];
  countryList: any;
  stepDetails: TaskStep;
  currentMaritalStatus: any;
  terminationReasons:any = [];
  @Input() userId: number;
  @Output() scrollTop = new EventEmitter();

  constructor(
    private referenceLibraryService: ReferenceLibraryService,
    private cacheCountryService: CacheCountryService,
    private apiServiceState: ApiServicesService,
    public store: Store<any>,
    public apiService: MaritalHistoryService,
    public toastr: ToastrService,
    public stepStatusUpdatePayloadCostructionService: StepStatusUpdatePayloadService,
    public dialogService: DialogService
  ) { }

  ngOnInit(): void {
    combineLatest([
      this.cacheCountryService.getCountry(),
      // this.referenceLibraryService.getReferenceData('MARSTAT'),
      this.referenceLibraryService.getReferenceData('TITL'),
      this.referenceLibraryService.getReferenceData('GENDER')
    ])
      .pipe(take(1))
      .subscribe(data => {
        if (data[0].length > 0) {
          this.countryList = data[0];
        }
        // this.maritalStatusArr = data[1];
        this.titleListArr = data[1];
        this.genderListArr = data[2];
      });

      this.terminationReasons = ['Divorce','Death','Annulment','Other']
  }

  // Toggle controls status based on step status
  toggleControlsStatus() {
    if (this.stepDetails.stepStatusDetails.stepStatusCode === "NEW" || this.stepDetails.stepStatusDetails.stepStatusCode === 'COMPLETE' ||
      this.stepDetails.stepStatusDetails.stepStatusCode === 'SUBMIT') {
      this.maritalHistoryForm.disable();
      this.maritalHistoryHeaderForm.disable();
      this.disabledControls = true;
    } else {
      this.maritalHistoryForm.enable();
      this.maritalHistoryHeaderForm.enable();
      this.disabledControls = false;
    }
  }

  ngOnChanges() {
    if (this.primaryData && !this.maritalHistoryForm && !this.maritalHistoryHeaderForm) {
      this.maritalHistoryHeaderForm = new FormGroup({
        isSpouseInArmedSrvc: new FormControl(0),
        numOfMarriage: new FormControl(null, Validators.required)
      });
      this.maritalHistoryForm = new FormGroup({
        aliasName: new FormArray([
          new FormGroup({
            fName: new FormControl(null),
            title: new FormControl(null),
            id: new FormControl(null),
            mName: new FormControl(null),
            lName: new FormControl(null)
          }, [
            this.aliasNameTitleFieldRequired.bind(this),
            this.aliasNameFNameFieldRequired.bind(this),
            this.aliasNameFNameFieldPattern.bind(this),
            this.aliasNameMNameFieldPattern.bind(this),
            this.aliasNameLNameFieldRequired.bind(this),
            this.aliasNameLNameFieldPattern.bind(this)
          ])
        ]),
        alienRegNo: new FormControl(null),
        birthCity: new FormControl(null, Validators.pattern(/^[a-zA-Z ]*$/)),
        birthCountryCode: new FormGroup({
          countryCode: new FormControl(null)
        }),
        birthStateProvinceCode: new FormControl(null),
        birthStateProvinceName: new FormControl(null),
        dob: new FormControl(null, Validators.required),
        firstName: new FormControl(null, [Validators.required, Validators.pattern(/^[a-zA-Z ]*$/)]),
        gender: new FormGroup({
          id: new FormControl(null, Validators.required),
          name: new FormControl(null)
        }),
        id: new FormControl(null),
        lastName: new FormControl(null, [Validators.required, Validators.pattern(/^[a-zA-Z ]*$/)]),
        marriageCountryCode: new FormGroup({
          countryCode: new FormControl(null)
        }),
        marriageCity: new FormControl(null, Validators.pattern(/^[a-zA-Z ]*$/)),
        marriageDate: new FormControl(null),
        marriageStateProvinceCode: new FormControl(null),
        marriageStateProvinceName: new FormControl(null),
        middleName: new FormControl(null, Validators.pattern(/^[a-zA-Z ]*$/)),
        sequenceNo: new FormControl(null),
        title: new FormControl(null, Validators.required),
        hasAliasName: new FormControl(false),
        marriageEndPlace:new FormControl(null),
        reasonOfMrgTermination:new FormControl(null),
        otherReasonOfMrgTermination:new FormControl(null),
        marriageEndDate:new FormControl(null)
      });
      // Get Specific step details by passing step code
      this.store.pipe(select(getStepDetails, { id: this.primaryData.stepId }))
        .pipe(takeUntil(this.observableSubscription$))
        .subscribe((data: TaskStep) => {
          if (data && data.id) {
            this.stepDetails = data;
            this.toggleControlsStatus();
          }
        });
      this.maritalHistoryForm.valueChanges.subscribe(() => {
        this.setFormDirtyOrNot();
      });
      this.maritalHistoryHeaderForm.valueChanges.subscribe(() => {
        this.setFormDirtyOrNot();
      });
      this.getMaritalHistoryInformation();
    }
  }

  get aliasNameForm() {
    return (this.maritalHistoryForm.get('aliasName') as FormArray).controls;
  }

  aliasNameTitleFieldRequired(group: FormGroup): { [s: string]: boolean } {
    if (this.maritalHistoryForm && this.maritalHistoryForm.get('hasAliasName').value &&
      !group.value.title) {
      return { aliasNameTitleFieldIsRequired: true };
    }
    return null;
  }

  aliasNameFNameFieldRequired(group: FormGroup): { [s: string]: boolean } {
    if (this.maritalHistoryForm && this.maritalHistoryForm.get('hasAliasName').value &&
      (!group.value.fName || (group.value.fName && group.value.fName.trim() === ''))) {
      return { aliasNameFNameFieldIsRequired: true };
    }
    return null;
  }

  aliasNameFNameFieldPattern(group: FormGroup): { [s: string]: boolean } {
    const REGEXP = /^[a-zA-Z ]*$/;
    if (this.maritalHistoryForm && this.maritalHistoryForm.get('hasAliasName').value &&
      group.value.fName && group.value.fName.trim() !== '' && !(REGEXP.test(group.value.fName))) {
      return { aliasNameFNameFieldIsPattern: true };
    }
    return null;
  }

  aliasNameMNameFieldPattern(group: FormGroup): { [s: string]: boolean } {
    const REGEXP = /^[a-zA-Z ]*$/;
    if (this.maritalHistoryForm && this.maritalHistoryForm.get('hasAliasName').value &&
      group.value.mName && group.value.mName.trim() !== '' && !(REGEXP.test(group.value.mName))) {
      return { aliasNameMNameFieldIsPattern: true };
    }
    return null;
  }

  aliasNameLNameFieldRequired(group: FormGroup): { [s: string]: boolean } {
    if (this.maritalHistoryForm && this.maritalHistoryForm.get('hasAliasName').value &&
      (!group.value.lName || (group.value.lName && group.value.lName.trim() === ''))) {
      return { aliasNameLNameFieldIsRequired: true };
    }
    return null;
  }

  aliasNameLNameFieldPattern(group: FormGroup): { [s: string]: boolean } {
    const REGEXP = /^[a-zA-Z ]*$/;
    if (this.maritalHistoryForm && this.maritalHistoryForm.get('hasAliasName').value &&
      group.value.lName && group.value.lName.trim() !== '' && !(REGEXP.test(group.value.lName))) {
      return { aliasNameLNameFieldIsPattern: true };
    }
    return null;
  }

  setFormDirtyOrNot() {
    if (this.maritalHistoryForm.dirty || this.maritalHistoryHeaderForm.dirty) {
      this.store.dispatch(new MarkDirtyFormAction({
        dirty: true
      }));
    } else {
      this.store.dispatch(new MarkCleanFormAction({
        dirty: false
      }));
    }
  }

  getMaritalHistoryInformation() {
    this.apiService.getMaritalHistoryInformation(
      this.primaryData.sponsorId ? this.primaryData.sponsorId : this.primaryData.beneficiaryId,
      this.primaryData.familyId === this.userId ? this.primaryData.familyId : this.type === 'family' ? this.userId : null)
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe((response: ImagilityBaseResponse) => {
        let maritalHistoryList = response.data ? response.data : [];
        if ((maritalHistoryList as any[]).length > 0) {
          if (maritalHistoryList.some(x => x.currentMaritalStatus)) {
            maritalHistoryList = maritalHistoryList.map(x => {
              if (x.currentMaritalStatus) {
                x = {
                  ...x,
                  currentMaritalStatus: {
                    maritalStatus: {
                      code: x.currentMaritalStatus.maritalStatus.code,
                      name: x.currentMaritalStatus.maritalStatus.name
                    },
                    numOfMarriage: x.currentMaritalStatus.numOfMarriage ? x.currentMaritalStatus.numOfMarriage : 0,
                    isSpouseInArmedSrvc: x.currentMaritalStatus.isSpouseInArmedSrvc ? x.currentMaritalStatus.isSpouseInArmedSrvc : 0
                  },
                  fullName: `${x.firstName}${x.middleName ? ' ' + x.middleName : '' } ${x.lastName}`,
                  isShow: !!x.firstName,
                  isActionable: false
                };
              } else {
                x = {
                  ...x,
                  fullName: `${x.firstName}${x.middleName ? ' ' + x.middleName : '' } ${x.lastName}`,isShow: !!x.firstName,
                  isActionable: true
                };
              }
              return x;
            });
          } else {
            maritalHistoryList = maritalHistoryList.map(x => {
              return {
                ...x,
                fullName: `${x.firstName}${x.middleName ? ' ' + x.middleName : '' } ${x.lastName}`,
                isShow: !!x.firstName,
                isActionable: !x.currentMaritalStatus
              };
            });
          }
          this.currentMaritalStatus = (maritalHistoryList as any[]).find(x => x.currentMaritalStatus);
          if (this.currentMaritalStatus) {
            // if (this.currentMaritalStatus.currentMaritalStatus.maritalStatus.code === 'SNGL') {
            //   if (this.stepDetails.stepStatusDetails.stepStatusCode === 'NEW') {
            //     this.updateStepStatus('INPROGRESS');
            //   }
            // } else {
            if (this.currentMaritalStatus.currentMaritalStatus.maritalStatus.code !== 'SNGL') {
              this.maritalHistoryHeaderForm.patchValue({
                isSpouseInArmedSrvc:
                  this.currentMaritalStatus.currentMaritalStatus.isSpouseInArmedSrvc ?
                    this.currentMaritalStatus.currentMaritalStatus.isSpouseInArmedSrvc : 0,
                numOfMarriage: this.currentMaritalStatus.currentMaritalStatus.numOfMarriage
              });
              maritalHistoryList = (maritalHistoryList as any[])
                .filter(x => x.isShow);
              this.maritalHistoryList = [
                ...(maritalHistoryList as any[])
                  .filter(x => !x.isActionable),
                ...(maritalHistoryList as any[])
                  .filter(x => x.isActionable)
              ];
            }
          }
        }
        this.resetForm();
      });
  }

  saveMaritalStatus() {
    const payload = {
      ...this.maritalHistoryHeaderForm.value,
      maritalHistory: null
    };
    this.onSave(payload);
  }

  saveMaritalHistory() {
    const { hasAliasName, ...maritalHistory } = {
      ...this.maritalHistoryForm.value,
      hasAliasName: this.maritalHistoryForm.value.hasAliasName,
      aliasName: this.maritalHistoryForm.value.hasAliasName ? [
        ...this.maritalHistoryForm.value.aliasName
      ] : []
    };
    const payload = {
      isSpouseInArmedSrvc: null,
      numOfMarriage: null,
      maritalHistory
    };
    this.onSave(payload);
  }

  onSave(payload: any) {
    this.apiService.postMaritalHistoryInformation(
      this.primaryData.sponsorId ? this.primaryData.sponsorId : this.primaryData.beneficiaryId,
      this.primaryData.familyId === this.userId ? this.primaryData.familyId : this.type === 'family' ? this.userId : null,
      payload)
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe((response: ImagilityBaseResponse) => {
        if (response) {
          this.toastr.success(response.message, 'Success');
          if (this.stepDetails.stepStatusDetails.stepStatusCode === 'NEW') {
            this.updateStepStatus('INPROGRESS');
          }
          this.getMaritalHistoryInformation();
        }
      });
  }

  updateStepStatus(status) {
    this.store.dispatch(updateStepStatus({
      payload: this.stepStatusUpdatePayloadCostructionService.payloadConstruction(this.stepDetails, status),
      subTaskId: this.stepDetails.taskId,
      visaType: this.primaryData.caseType,
      stepId: this.primaryData.stepId
    }));
  }

  resetForm() {
    this.maritalHistoryHeaderForm.reset(this.maritalHistoryHeaderForm.value);
    this.maritalHistoryForm.reset();
  }

  deleteDocument(addressHistoryId) {
    const deleteDialogRef = this.dialogService.open(DeleteDialogLibraryComponent, DeleteDialogConfigurations);
    deleteDialogRef.onClose.pipe(takeUntil(this.observableSubscription$)).subscribe((response: boolean) => {
      if (response) {
        this.apiService.deleteMaritalHistoryItem(addressHistoryId)
          .pipe(takeUntil(this.observableSubscription$))
          .subscribe((deleteResponse: ImagilityBaseResponse) => {
            this.toastr.success(deleteResponse.message, 'Success');
            this.getMaritalHistoryInformation();
          });
      }
    });

  }

  editDocument(doc) {
    if (doc.birthCountryCode && doc.birthCountryCode.countryCode) {
      this.handleCountryChange(doc.birthCountryCode.countryCode, 'Birth');
    }
    if (doc.marriageCountryCode && doc.marriageCountryCode.countryCode) {
      this.handleCountryChange(doc.marriageCountryCode.countryCode, 'Marriage');
    }
    const formValue = {
      ...doc,
      marriageDate: doc.marriageDate ? new Date(doc.marriageDate) : null,
      dob: doc.dob ? new Date(doc.dob) : null,
      hasAliasName: doc.aliasName && doc.aliasName.length > 0 ? true : false,
      marriageEndDate: doc.marriageEndDate ? new Date(doc.marriageEndDate) : null,
    };
    this.maritalHistoryForm.patchValue(formValue);
    this.scrollTop.emit();
  }

  cancelForm() {
    this.maritalHistoryForm.reset();
  }

  getGlobalErrorMessages(error: string) {
    return errorUtils.errorMessages.get(error);
  }

  handleCountryChange(countryCode: string, type) {
    if (type === 'Birth') {
    } else {
    }
    this.apiServiceState.getStates(countryCode).subscribe((states: []) => {
      if (type === 'Birth') {
        this.listBirthStates = states;
      } else {
        this.listMarriageStates = states;
      }
    });
  }

  handleCancel() {

  }

  ngAfterViewInit(){
    this.translet();    
  }

  translet(){
    setTimeout(() => {
      this.referenceLibraryService.transletConversion();
    }, 600);  
  }

  ngOnDestroy() {
    this.observableSubscription$.next();
    this.observableSubscription$.complete();
  }

}
