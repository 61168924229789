<div class="container-fluid">
    <div class="row">
        <div class="col-md-12">
            <div class="mat-title titleColor font-weight-bold">I-9 Bulk Data Upload</div>
            <div class="mat-subheading-1 titleColor">Seamless Bulk Data Upload and data validation</div>
        </div>
        <div class="col-md-12">
            <div class="bgWhite noMargin w100">
                <div class="mat-elevation-z8 mt-2">
                    <div class="title">3 Easy steps to upload I-9 Records</div>
                    <mat-divider role="separator" class="mat-divider mat-divider-horizontal"
                        aria-orientation="horizontal"></mat-divider>
                    <div class="mb-2">
                        <div class="download-container">
                            <span class="circle" [class.completed]="downloadCompleted">
                                <span *ngIf="!downloadCompleted">1</span>
                                <span class="download-complete" *ngIf="downloadCompleted">&#10003;</span>
                            </span>Download and Run Script
                            <button type="button" (click)="downloadFile()"
                                mat-flat-button color="default"
                                class="text-uppercase _btn"><mat-icon>save_alt</mat-icon> Download</button>
                            <!-- <a href="#" class="link">Guidance to maintain folder structure</a> -->
                            <button type="button" (click)="showGuidance()" class="link">Guidance to maintain folder
                                structure</button>
                        </div>
                        <div class="col-md-6 pl-0">
                            <div class="download-cont">
                                <ul>
                                    <li>Download the script and place it in the folder that you need to upload the files
                                        from.</li>
                                    <li>Run the script and an excel document will be generated with all the file
                                        details.</li>
                                    <li>Check the excel and update the non-captured data.</li>
                                </ul>
                            </div>
                        </div>
                        <mat-divider role="separator" class="mat-divider mat-divider-dashed mat-divider-horizontal"
                            aria-orientation="horizontal"></mat-divider>
                    </div>
                    <div class="mb-2">
                        <div class="download-container">
                            <span class="circle" [class.completed]="uploadCompleted">
                                <span *ngIf="!uploadCompleted">2</span>
                                <span class="upload-complete" *ngIf="uploadCompleted">&#10003;</span>
                            </span>Upload File
                            <span>
                                <input type="file" #fileInput (change)="handleFileInput($event)" style="display:none" />
                                <button (click)="fileInput.click()" mat-flat-button
                                    color="default" class="text-uppercase _btn">
                                    <mat-icon class="_add">file_upload</mat-icon> Upload
                                </button>
                            </span>
                            <span *ngIf="uploadedFileName">{{uploadedFileName}}</span>
                        </div>
                        <div class="col-md-6 pl-0">
                            <div class="download-cont">
                                <ul class="list-none">
                                    <li>Once the file is uploaded the results will automatically be displayed</li>
                                </ul>
                            </div>
                        </div>
                        <mat-divider role="separator" class="mat-divider mat-divider-dashed mat-divider-horizontal"
                            aria-orientation="horizontal"></mat-divider>
                    </div>
                    <ng-container *ngIf="isResult">
                        <div class="download-container marg-bot-1">
                            <span class="circle" [class.completed]="uploadCompleted">
                                <span *ngIf="!uploadCompleted">3</span>
                                <span class="upload-complete" *ngIf="uploadCompleted">&#10003;</span>
                            </span>View Results
                            <!-- <button type="button" (click)="generateReport()" mat-flat-button color="default" class="text-uppercase _btn">Generate Report</button>
                            <button type="button" (click)="downloadReport()" mat-flat-button color="default" class="text-uppercase _btn btn-border"><mat-icon>save_alt</mat-icon> Download Report</button> -->
                        </div>
                        <div class="sub-title">Upload Summary</div>
                        <div class="sub-text">You have uploaded '{{uploadedFileName}}'</div>
                        <div class="record-main">
                            <div class="col-md-2 p-0 pr-4">
                                <div class="total-cont">
                                    <span class="count">
                                        {{totalCount}}
                                    </span>Total<br> Uploaded
                                </div>
                            </div>
                            <div class="col-md-2 p-0 pr-4">
                                <div class="success-cont">
                                    <span class="count">
                                        {{successCount}}
                                    </span>Successfully<br> Uploaded
                                </div>
                            </div>
                            <div class="col-md-2 p-0 pr-4">
                                <div class="fail-cont">
                                    <span class="count">
                                        {{failedCount}}
                                    </span>Upload<br> Failed
                                </div>
                            </div>
                        </div>
                        <div class="errors-cont mb-2"><span class="error-text">{{failedCount}} errors</span> found while
                            upload.</div>

                        <div class="">
                            <table *ngIf="failedCount > 0" class="im-card-no-border-table table im-card-table-noborder-spacing" mat-table
                                [dataSource]="dataSource.data" matSort>
                                <ng-container matColumnDef="beneName">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Beneficiary Name </th>
                                    <td mat-cell *matCellDef="let row"> {{row.beneName}} </td>
                                </ng-container>
                                <ng-container matColumnDef="birthDate">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Date of Birth </th>
                                    <td mat-cell *matCellDef="let row"> {{row.birthDate}} </td>
                                </ng-container>
                                <ng-container matColumnDef="emailId">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Email Id </th>
                                    <td mat-cell *matCellDef="let row"> {{row.emailId}} </td>
                                </ng-container>
                                <ng-container matColumnDef="ssnNumber">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Document Number
                                    </th>
                                    <td mat-cell *matCellDef="let row"> {{row.ssnNumber}} </td>
                                </ng-container>
                                <ng-container matColumnDef="errorMessage">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Error Message </th>
                                    <td mat-cell *matCellDef="let row"> {{row.errorMessage}} </td>
                                </ng-container>

                                <!-- Define header and row templates -->
                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                            </table>
                            <ng-template *ngIf="failedCount== 0">
                                <div class="mt-4 text-center">No Error</div>
                            </ng-template>
                            <div class="paginator">
                                <mat-paginator [pageSizeOptions]=pageSizeOptions [pageSize]="pageSize"
                                    [length]="totalRecordCount" (page)="paginationEvent($event)"></mat-paginator>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
        <p-dialog header="Guidance to maintain folder structure" [(visible)]="displayGuidance" [modal]="true"
            [responsive]="true" [style]="{width: '840px', minWidth: '200px', height:'520px', background:'#fff'}"
            [minY]="70" [maximizable]="false" [baseZIndex]="60000">
            <div>
                <div>
                    <p><strong>Recommended Directory Structure for Bulk Upload</strong></p>
                    <ol>
                        <li>Create a root folder with the following naming convention &lt;firstname_lastname_mailid&gt;. (For example, if you create this folder <b>John_doe_johndeo@abcorp.com</b> under <b>D drive</b>, it will be: <b>D:\John_doe_johndeo@abcorp.com</b>).
                        </li>
                        <li>Inside the root folder create folders named <b>Folder 1</b>, <b>Folder 2</b>, <b>Folder 3</b> etc.</li>
                        <li>In each folder create subfolders named <b>section1</b>, <b>section2</b> and <b>section3</b>.</li>
                        <li>In each section folder, place pdf files of the employee.</li>
                    </ol>
                </div>
                <div>
                    <p><strong>Steps to Extract and Run .exe File</strong></p>
                    <ol>
                        <li>Download the zip file and then extract it.</li>
                        <li>Once extracted, you will get one <b>.exe</b> file and <b>remoteI9</b> folder.</li>
                        <li>Copy the <b>.exe</b> file and <b>remoteI9</b> folder and paste them where the root folder is created. (As explained in the above example, <b>D:\John_doe_johndeo@abcorp.com</b>.)</li>
                        <li>Run the <b>.exe</b> software.</li>
                        <li>Once <b>.exe</b> software is run, an excel file is generated. This Excel file has read through all the pdf files inside the folder structure and is now populated and ready for upload.</li>
                    </ol>
                </div>
            </div>
        </p-dialog>
    </div>
</div>