import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject  } from 'rxjs';
import { ProgressService } from './progress.service';
import { ImagilityBaseResponse } from 'app-models';
import { map } from 'rxjs/operators';


export interface RemediationRecord {
  id: number;
  noOfIterations: number;
  dateTime: string;
  noOfEmployees: number;
  compliant: number;
  nonCompliant: number;
  action: string;
}

export const MOCK_DATA: RemediationRecord[] = [
  {
    id: 1,
    noOfIterations: 1,
    dateTime: '2024-07-12T10:00:00',
    noOfEmployees: 25,
    compliant: 80,
    nonCompliant: 20,
    action: ''
  },
  {
    id: 2,
    noOfIterations: 2,
    dateTime: '2024-07-13T14:30:00',
    noOfEmployees: 30,
    compliant: 80,
    nonCompliant: 20,
    action: ''
  },
  {
    id: 3,
    noOfIterations: 3,
    dateTime: '2024-07-14T09:15:00',
    noOfEmployees: 20,
    compliant: 80,
    nonCompliant: 20,
    action: ''
  },
  {
    id: 4,
    noOfIterations: 4,
    dateTime: '2024-07-15T11:45:00',
    noOfEmployees: 50,
    compliant: 80,
    nonCompliant: 20,
    action: ''
  },
  {
    id: 5,
    noOfIterations: 5,
    dateTime: '2024-07-16T16:00:00',
    noOfEmployees: 40,
    compliant: 80,
    nonCompliant: 20,
    action: ''
  }
];


@Injectable({
  providedIn: 'root'
})
export class I9RemediationService {

  constructor(
    private httpClient: HttpClient,
    private progressService: ProgressService
  ) { }

  getRemediationData(): Observable<RemediationRecord[]> {
    return of(MOCK_DATA);
  }

  saveDocumentAndSheetDetails(payload) {
    return this.httpClient.post(`/i9admin/company/handleFileUpload`, payload).pipe(
      map((response: ImagilityBaseResponse) => response)
    );
  }

  getParsingStatus(companyId){
    return this.httpClient.get('/i9/remediation/parsing-status/' + companyId).pipe(
      map((response: ImagilityBaseResponse) => response.data)
    );
  }

  getRemediationStatus(companyId: number): any {
    return this.httpClient.get(`/i9admin/company/${companyId}/getstatusDetails`).pipe(
      map((response: ImagilityBaseResponse) => response.data)
    );
  }
  initiateRemediation(companyId, iterationNumber) {
    return this.httpClient.get(`/i9/remediation/initiateParsing/${companyId}/${iterationNumber}/INITIATE`).pipe(
      map((response: ImagilityBaseResponse) => response)
    );
  }

  saveCompliantData(companyId, i9remediationrunid, remediationIterationId) {
    const payload = {
      companyId: companyId,
      i9remediationrunid: i9remediationrunid,
      remediationIterationId: remediationIterationId,
    };
    return this.httpClient.post(`/i9/save/remediation/compliantData`, payload).pipe(
      map((response: ImagilityBaseResponse) => response)
    );
  }

  //parsing-report-list related
  getParseEmpStatus(companyId,i9remediationRunId,iterationId){
    return this.httpClient.get(`/i9/remediation/parsedEmpstatus/${companyId}/${i9remediationRunId}/${iterationId}`).pipe(
      map((response: ImagilityBaseResponse) => response.data)
    );
  }

  getParseEmpDocType(documentType, companyId,i9remediationRunId,iterationId){
    return this.httpClient.get(`/i9/remediation/EmpDocType/${documentType}/${companyId}/${i9remediationRunId}/${iterationId}`).pipe(
      map((response: ImagilityBaseResponse) => response.data)
    );
  }

  getParseEmpDocTypes(companyId,i9remediationRunId,iterationId){
    return this.httpClient.get(`/i9/remediation/parsedEmpDocTypes/${companyId}/${i9remediationRunId}/${iterationId}`).pipe(
      map((response: ImagilityBaseResponse) => response.data)
    );
  }

  saveDocTypeData(documentType: string, companyId: number, payload: any): Observable<any> {
    return this.httpClient.post(`/i9/remediation/EmpDocType/${documentType}/${companyId}`, payload).pipe(
      map((response: any) => response.data)
    );
  }

  analyzeGapReport(companyId, remediationIterationID, remediationRunID) {
    return this.httpClient.get(`/i9/remediation/analyzegapreport?companyId=${companyId}&remediationIterationID=${remediationIterationID}&remediationRunID=${remediationRunID}`).pipe(
      map((response: ImagilityBaseResponse) => response)
    );
  }
  sendReportAsEmail(emailsList, reportPath) {
    const payload = {
      i9GapReportPath: reportPath,
      toEmailList: emailsList
    }
    return this.httpClient.post(`/i9/send/remediation/email`, payload).pipe(
      map((response: ImagilityBaseResponse) => response)
    );
  }

  endRemediation(companyId) {
    return this.httpClient.get(`/i9admin/company/endRemediation/${companyId}`).pipe(
      map((response: ImagilityBaseResponse) => response)
    );
  }

  getI9formDocData(companyId, runId, iterationId){
    return this.httpClient.get(`/i9/remediation/getI9formData/${companyId}/${runId}/${iterationId}`).pipe(
      map((response: ImagilityBaseResponse) => response.data)
    );
  }

  saveI9Data(payload: any): Observable<any> {
    return this.httpClient.post(`/i9/save/data`, payload).pipe(
      map((response: any) => response)
    );
  }
}
