import { Component, OnInit, ViewChild, AfterViewInit, Input, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { DashboardWidgetsService } from 'src/app/services/dashboard-widgets.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TaskUtilityService } from '../../../../utility/task-utility.service';
import * as RolesRouteMap from '../../../../../data/constants/roles';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationModalComponent } from 'src/app/modules/shared/modal/confirmation-modal/confirmation-modal.component';
import { ImagilityBaseResponse } from 'src/app/data/models/response.model';
import { ReferenceLibraryService } from 'src/app/services/referenceLibrary.service';
import { ReferenceLibrary } from 'src/app/data/models/referenceLibrary.model';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { take, takeUntil } from 'rxjs/operators';
import { HelpVideoOverlayComponent } from 'src/app/modules/shared/help-video-overlay/help-video-overlay.component';
import { Subject, combineLatest } from 'rxjs';
import { CompletedPetitionStatusArr, InprogressPetitionStatusArr, NewPetitionStatusArr } from 'projects/app-models/src/constants/petition-status';
import moment from 'moment';
import { Store } from '@ngrx/store';
import * as Task from 'src/app/modules/superTask/task-state/actions/task.actions';
import { I9EverifyService } from 'src/app/modules/i9-evaluators/i9-everify.service';
import { environment } from 'src/environments/environment';

import * as viewByOptions from 'src/app/data/constants/tasklist-duration-options.json';
import { RecentlyVisitedFilterModel } from 'src/app/data/models/dashboard-widgets.model';
import { DialogService } from 'primeng/dynamicdialog';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';
import { CreateNewBeneficiaryComponent } from 'src/app/modules/beneficiary/create-new-beneficiary/create-new-beneficiary.component';
import { SortEvent } from 'primeng/api';


import { AuthenticationService } from 'authentication';
import { eVerifyStatuses } from 'src/app/data/constants/e-verify-statuses';
import { PetitionerService } from 'src/app/services/petitioner.service';
import { PetitionProcessTypeSelectionComponent } from 'src/app/modules/superTask/petition-process-type-selection/petition-process-type-selection.component';
import { CreateTaskWithWizardComponent } from 'src/app/modules/superTask/components/create-task-with-wizard/create-task-with-wizard.component';
@Component({
  selector: 'task-list',
  templateUrl: './task-list.component.html',
  styleUrls: ['./task-list.component.scss'],
  providers: [DialogService, MessageService],
  animations: [
    trigger('rowExpansionTrigger', [
      state('void', style({
        transform: 'translateX(-10%)',
        opacity: 0
      })),
      state('active', style({
        transform: 'translateX(0)',
        opacity: 1
      })),
      transition('* <=> *', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ])
  ]
})
export class TaskListComponent implements OnInit, OnDestroy, AfterViewInit {


  taskList = [];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  newTaskCount = 0;
  inProgressTaskCount = 0;
  completedTaskCount = 0;

  // i9Admin count
  i9InitiationsCount = 0;
  i9EvaluationsCount = 0;
  i9EverificationsCount = 0;

  currentYearIndex: number;
  currentMonthIndex: number;
  selectedIndex = 0;
  years: Array<number>;
  months: Array<string>;
  monthsData: Array<{
    monthName: string,
    monthYear: number,
    isInRange: boolean,
    isLowerEdge: boolean,
    isUpperEdge: boolean
  }>;
  rangeIndexes: Array<number>;
  monthViewSlicesIndexes: Array<number>;
  monthDataSlice: Array<{
    monthName: string,
    monthYear: number,
    isInRange: boolean,
    isLowerEdge: boolean,
    isUpperEdge: boolean
  }>;
  statusObj: string[] = [];
  globalIndexOffset: number;
  monthIndex = 0;
  noDataMsg = false;
  taskListObj: Array<any>;
  toggleStatus = 'I-9_Initiations';
  userType: string;
  isOutsideImagility = false;
  taskType: ReferenceLibrary[];
  subTaskType: any[];
  visaTypes: ReferenceLibrary[];
  petitionTypes: ReferenceLibrary[];
  immClassificationTypes: ReferenceLibrary[];
  immCategoryTypes: ReferenceLibrary[];
  cols: any[];
  nestedCols: any[];
  immigrantTypeCols: any[];
  nonImmigrantTypeCols: any[];
  i9TypeCols: any[];
  immigrantTypeNestedCols: any[];
  nonImmigrantTypeNestedCols: any[];
  awaitingNonImmigrantTypeCols: any[];
  awaitingImmigrantTypeCols: any[];
  completeNonImmigrantTypeCols: any[];
  completeImmigrantTypeCols: any[];
  observableSubscription$ = new Subject();
  filter = {
    superTaskType: 'NONIMMPET',
    subTaskType: [],
    visaType: [],
    petitionType: [],
    immClassificationType: [],
    immCategoryType: [],
    duration: 'halfYearly'
  };
  startDate: any;
  endDate: any;
  roleName: boolean = false;
  i9AdminCompanyId: number = 0

  payload = {
    companyId: parseInt(sessionStorage.getItem('companyId')),
    endDate: null,
    pageNumber: 1,
    pageSize: 10,
    searchText: '',
    startDate: null,
    visaTypes: this.filter.visaType,
    petitionTypes: this.filter.petitionType,
    subTaskTypes: this.filter.subTaskType,
    superTaskType: this.filter.superTaskType,
    immClassificationTypes: this.filter.immClassificationType,
    immCategoryTypes: this.filter.immCategoryType,
    taskStatus: [],
    filterTasksFromDate: null,
    filterTasksToDate: null,
    employeeId: null
  };

  @ViewChild(HelpVideoOverlayComponent, { static: false }) helpVideoComponent: HelpVideoOverlayComponent;
  immigrantSubTask: ReferenceLibrary[];
  nonImmigrantSubTask: ReferenceLibrary[];
  @Input() isI9Admin: any;
  // isLocal=environment.appUrl;
  isLocal = '';
  message: any;

  viewOptions: RecentlyVisitedFilterModel[];  
  viewOptionNew: any = null;
  viewOptionPending: any = null;
  viewOptionCompleted: any = null;
  activeTasksTab: string = 'mine';
  activeEmployeeId: any = parseInt(sessionStorage.getItem('loginEmployeeId'));
  listOfI9Status: any[] = [];
  statusModal: boolean = false;
  selectedStatus: string = '';
  caseNumberUpdate;
  empName: string;
  employeeActions: any[];
  selectedAction: string;
  pdfSrc: string;
  enrollPopup: boolean;
  refs: DynamicDialogRef;

  constructor(
    private dashboardWidgetsService: DashboardWidgetsService,
    public dialog: MatDialog,
    private toastService: ToastrService,
    private referenceLibraryService: ReferenceLibraryService,
    private router: Router,
    private route: ActivatedRoute,
    private utilityService: TaskUtilityService,
    protected store: Store<any>,
    private i9EverifyService: I9EverifyService,
    private toastr: ToastrService,
    private ref: ChangeDetectorRef,
    public dialogService: DialogService,
    public authenticationService: AuthenticationService,
    private petitinerService: PetitionerService
  ) {
    this.listOfI9Status = eVerifyStatuses.map(x => x.name);
  }

  ngOnInit() {
    //let url = document.location.href;
    this.isLocal = '';
    // if (url.includes('localhost')) {
    //   this.isLocal = '';
    // } else if (url.includes('https://pl.imagility.co')) {
    //   this.isLocal = ''
    // } else {
    //   this.isLocal = '';
    // }

    this.i9AdminCompanyId = parseInt(sessionStorage.getItem('companyId'));
    // this.i9AdminCompanyId = parseInt(sessionStorage.getItem('companyId')); 
    this.viewOptions = (viewByOptions as any).default;
    this.userType = sessionStorage.getItem('userTypeRole');
    this.roleName = sessionStorage.getItem('roleName') === 'External Attorney' ? true : false;
    // this.getTasks('NEW');
    this.utilityService.setTaskId(null);

    this.immigrantTypeCols = [
      { field: 'taskId', header: 'Task ID' },
      { field: 'beneficiaryName', header: 'Beneficiary Name' },
      { field: 'companyName', header: 'Company' },
      { field: 'taskDueDate', header: 'Task Due Date' },
      { field: 'immCategory', header: 'Immigration Category' },
      { field: 'immClassification', header: 'Immigration Classification' },
      { field: 'status', header: 'Status' },
    ];
    this.nonImmigrantTypeCols = [
      { field: 'taskId', header: 'Task ID' },
      { field: 'beneficiaryName', header: 'Beneficiary Name' },
      { field: 'companyName', header: 'Company' },
      { field: 'taskDueDate', header: 'Task Due Date' },
      { field: 'visaType', header: 'Visa Type/Immigration-category' },
      { field: 'petitionType', header: 'Petition Type/Immigration-classification' },
      { field: 'status', header: 'Status' },
    ];
    this.immigrantTypeNestedCols = [
      { field: 'subTaskType', header: 'Petition Sub-Type' },
      { field: 'preparerName', header: 'Preparer' },
      { field: 'startDate', header: 'Start Date' },
      { field: 'enddate', header: 'End Date' },
      { field: 'taskDueDate', header: 'Task Due Date' },
      { field: 'status', header: 'Status' }
    ];
    this.nonImmigrantTypeNestedCols = [
      { field: 'subTaskType', header: 'Petition Sub-Type' },
      { field: 'preparerName', header: 'Preparer' },
      { field: 'startDate', header: 'Start Date' },
      { field: 'enddate', header: 'End Date' },
      { field: 'taskDueDate', header: 'Task Due Date' },
      { field: 'status', header: 'Status' }
    ];

    this.awaitingImmigrantTypeCols = [
      { field: 'receiptNumber', header: 'Receipt Number' },
      { field: 'beneficiaryName', header: 'Beneficiary Name' },
      { field: 'immCategory', header: 'Immigration Category' },
      { field: 'immClassification', header: 'Immigration Classification' },
      { field: 'serviceCenter', header: 'Service Center' }
    ];
    this.awaitingNonImmigrantTypeCols = [
      { field: 'receiptNumber', header: 'Receipt Number' },
      { field: 'beneficiaryName', header: 'Beneficiary Name' },
      { field: 'visaType', header: 'Visa Type/Immigration-category' },
      { field: 'petitionType', header: 'Petition Type/Immigration-classification' },
      { field: 'serviceCenter', header: 'Service Center' },
    ];
    this.completeImmigrantTypeCols = [
      { field: 'taskId', header: 'Task ID' },
      { field: 'beneficiaryName', header: 'Beneficiary Name' },
      { field: 'companyName', header: 'Company' },
      { field: 'filedDate', header: 'Filed Date' },
      { field: 'immCategory', header: 'Immigration Category' },
      { field: 'immClassification', header: 'Immigration Classification' },
      { field: 'serviceCenter', header: 'Service Center' },
      { field: 'status', header: 'USCIS Decision' },
    ];
    this.completeNonImmigrantTypeCols = [
      { field: 'receiptNumber', header: 'Receipt Number' },
      { field: 'beneficiaryName', header: 'Beneficiary Name' },
      { field: 'filedDate', header: 'Filed Date' },
      { field: 'visaType', header: 'Visa Type/Immigration-category' },
      { field: 'petitionType', header: 'Petition Type/Immigration-classification' },
      { field: 'serviceCenter', header: 'Service Center' },
      { field: 'status', header: 'USCIS Decision' },
    ];

    this.i9TypeCols = [
      { field: 'fname', header: 'Employee Name' },
      { field: 'startDate', header: 'Employment Start Date' },
      { field: 'endDate', header: 'Employment End Date' },
      { field: 'i9StatusCode', header: 'Status' },
      { field: 'action', header: 'Action' }
    ];


    this.activeEmployeeId = !isNaN(this.activeEmployeeId) ? this.activeEmployeeId : (this.authenticationService?.currentUserValue ? this.authenticationService?.currentUserValue['employeeId'] : null);
    this.payload.employeeId = this.isI9Admin ? this.activeEmployeeId : null;
    if(!this.isI9Admin){ //IMGLT-27619 - checking if it's i9Admin then we don't need to call getTaskType as we are not loading filters and other option for i9Admin
      //this.getTaskType();
      //Make Active In-progress tab IMGLT-26632
      //this.toogleTaskTab('NEW');
      this.onSubTaskSelected(); //this line to get default columns for table and to avoid the unneccesory API calls
      this.toggleStatus = 'NEW';
    }else{
      this.toggleStatus = 'I-9_Initiations';
    }
    this.getTasks();
    

    this.employeeActions = [
      { label: 'Initiate I9', value: 'initiate' },
      { label: 'Upload I9', value: 'upload' }
    ];
  }

  onActionSelect(event: any) {
    if (event.value === 'initiate') {
      this.openInitiateI9();
    } else if (event.value === 'upload') {
      this.openUploadI9();
    }
  }

  openInitiateI9() {
    this.router.navigate(['i9-admin-landing/beneficiaries/list'], { queryParams: { action: 'create' } });
  }

  openUploadI9() {
    this.router.navigate(['i9-admin-landing/initiate-i9-remediation']);
    
  }

  // openEnrollPopup(val, row){
  //   this.enrollPopup = true;
  //   this.carryURLenroll = {
  //     companyId:  row.i9FormDetails.company.id || 0,
  //     beneficiaryEmployeeId: row.i9FormDetails.beneficiary.id || 0,
  //     beneficiaryEmployeeFName: row.i9FormDetails.beneficiary.fName || 'Not Available',
  //     beneficiaryEmail: row.i9FormDetails.beneficiary.emailContacts[0].email || 'Not Available',
  //     companyName: row.i9FormDetails.company.companyName || 'Not Available',
  //     i9FormId:row.i9FormDetails.id
  //   }  
  // }

  // agreeEnrollPopup(){
  //   this.enrollPopup = false;
  //   this.router.navigate([ './petitioner-landing/i9-evaluators/evaluation-request' ], { queryParams: { 
  //     companyId: this.carryURLenroll.companyId,
  //     beneficiaryEmployeeId: this.carryURLenroll.beneficiaryEmployeeId,
  //     beneficiaryEmployeeFName: this.carryURLenroll.beneficiaryEmployeeFName,
  //     beneficiaryEmail: this.carryURLenroll.beneficiaryEmail,
  //     companyName: this.carryURLenroll.companyName,
  //     i9FormId: this.carryURLenroll.i9FormId
  //    } })

  // }

  cancelEnrollPopup() {
    this.enrollPopup = false;
  }


  getText(val) {

    if (val == 'INITIATED') return "Initiated";
    else if (val == 'COMPLTD') return "Completed";
    else if (val == 'SEC1COMPLTD') return "Section 1 Completed";
    else if (val == 'SEC1VERFD') return "Section 1 Verified";
    else if (val == 'SENTBACK') return "Query on document";
    else if (val == 'REJECTED') return "Rejected";
    else if (val == 'VERIFIED') return "Section 1 Verified";
    else if (val == 'RSDOC') return "Request Supporting Documents";
    else if (val == 'QDOC') return "Query on document";
    else if (val == 'OTHER') return "Other";
    else { val }
  }



  getAction(val) {

    if (val == 'INITIATED') return "Initiate I-9";
    else if (val == 'SEC1COMPLTD') return "Initiate I-9";
    else if (val == 'SENTBACK') return "Sent Back";
    else if (val == 'SEC1VERFD') return "Generate I-9";
    else if (val == 'COMPLTD') return "I-9 Download";
    else if (val == 'REJECTED') return "Re-Initiate";
    else if (val == 'VERIFIED') return "Initiate I-9";
    else if (val == 'RSDOC') return "Sent Back";
    else if (val == 'QDOC') return "Sent Back";
    else if (val == 'OTHER') return "Sent Back";

    else return "NA";
  }


  viewInfo(val, row) {

    const q = {
      companyId: row?.i9FormDetails?.company?.id || 0,
      beneficiaryEmployeeId: row?.i9FormDetails?.beneficiary?.id || 0,
      beneficiaryEmployeeFName: row?.i9FormDetails?.beneficiary?.fName || 'Not Available',
      beneficiaryEmail: (row?.i9FormDetails?.beneficiary?.emailContacts?.[0]?.email) || 'Not Available',
      companyName: row?.i9FormDetails?.company?.companyName || 'Not Available',
      i9FormId: row?.i9FormDetails?.id || 0,
      UserId: row?.i9FormDetails?.createdBy || 0
    };
    return `${this.isLocal}/beneficiary-forms?companyId=${q.companyId}&beneficiaryId=${q.beneficiaryEmployeeId}&I9FormId=${q.i9FormId}&I9UserId=${q.UserId}&locked=true&view=true`;
  }

  // GenerateI9(val, row) {

  //   ;
  //   const q = {
  //     companyId: row.i9FormDetails.company.id || 0,
  //     beneficiaryId: row.i9FormDetails.beneficiary.id || 0,
  //     i9FormId: row.i9FormDetails.id,
  //     UserId: row.i9FormDetails.createdBy || 'Not Available',
  //   }
  //   this.i9EverifyService.getPDF(q.companyId, q.i9FormId).subscribe(res => {
  //     this.i9EverifyService.getPDFFile(q.companyId, q.i9FormId).subscribe(res => {
  //       // console.log('final response>>', res); 
  //       let path = res?.fileDetails?.path ? res?.fileDetails?.path : "";
  //       // console.log('path>>', path);   
  //       this.toastr.success("Form generated successfully!");
  //       let downloadLink = `${environment.docs}` + path;;
  //       console.log('downloadLink>>>>>', downloadLink);
  //       setTimeout(() => {
  //         window.open(downloadLink);
  //       }, 1000);
  //     })
  //   })
  // }

  GenerateI9(val, row) {
      const q = {
        companyId: row.i9FormDetails.company.id || 0,
        beneficiaryId: row.i9FormDetails.beneficiary.id || 0,
        i9FormId: row.i9FormDetails.id,
        UserId: row.i9FormDetails.createdBy || 'Not Available',
      }
      this.petitinerService.generateFullI9Form(q.i9FormId).subscribe(res => {
        if (res) {
          let path=res.fileLocation;
          this.pdfSrc = `${environment.docs}${path}`;
          this.toastService.success('I9Form generated successfully');
          window.open(this.pdfSrc);
        }
      })
    }

  getLink(val, row) {
    // ; // OTHER, QDOC, REJECTED, RSDOC, VERIFIED
    if (val == 'INITIATED' || val == 'OTHER' || val == 'QDOC' || val == 'RSDOC') {
      const q = {
        companyId: row.i9FormDetails.company.id || 0,
        beneficiaryEmployeeId: row.i9FormDetails.beneficiary.id || 0,
        beneficiaryEmployeeFName: row.i9FormDetails.beneficiary.fName || 'Not Available',
        beneficiaryEmail: row?.i9FormDetails?.beneficiary?.emailContacts?.[0]?.email || 'Not Available',
        companyName: row.i9FormDetails.company.companyName || 'Not Available',
        i9FormId: row.i9FormDetails.id,
        confirmationSent: row.i9FormDetails?.sendConfirmation,
        lastConfirmationAdmin: row?.lastConfirmationAdmin,
        lastConfirmationDate: row?.lastConfirmationDate,
        lastConfirmationTime: row?.lastConfirmationTime
      }
      return `${this.isLocal}/petitioner-landing/i9-evaluators/evaluation-request?companyId=${q.companyId}&beneficiaryEmployeeId=${q.beneficiaryEmployeeId}&beneficiaryEmployeeFName=${q.beneficiaryEmployeeFName}&beneficiaryEmail=${q.beneficiaryEmail}&companyName=${q.companyName}&i9FormId=${q.i9FormId}&confirmationSent=${q.confirmationSent}&lastConfirmationAdmin=${q.lastConfirmationAdmin}&lastConfirmationDate=${q.lastConfirmationDate}&lastConfirmationTime=${q.lastConfirmationTime}`;

      // let link =  `${this.isLocal}/petitioner-landing/i9-evaluators/evaluation-request?companyId=${q.companyId}&beneficiaryEmployeeId=${q.beneficiaryEmployeeId}&beneficiaryEmployeeFName=${q.beneficiaryEmployeeFName}&beneficiaryEmail=${q.beneficiaryEmail}&companyName=${q.companyName}&i9FormId=${q.i9FormId}`;  
      // window.open(link); 
    }
    else if (val == 'VERIFIED') {
      const q = {
        companyId: row.i9FormDetails.company.id || 0,
        beneficiaryEmployeeId: row.i9FormDetails.beneficiary.id || 0,
        beneficiaryEmployeeFName: row.i9FormDetails.beneficiary.fName || 'Not Available',
        beneficiaryEmail: row?.i9FormDetails?.beneficiary?.emailContacts[0]?.email || 'Not Available',
        companyName: row.i9FormDetails.company.companyName || 'Not Available',
        i9FormId: row.i9FormDetails.id,
        confirmationSent: row.i9FormDetails?.sendConfirmation,
        lastConfirmationAdmin: row?.lastConfirmationAdmin,
        lastConfirmationDate: row?.lastConfirmationDate,
        lastConfirmationTime: row?.lastConfirmationTime
      }
      //let link =  `${this.isLocal}/petitioner-landing/i9-evaluators/verify-beneficiary?companyId=${q.companyId}&beneficiaryEmployeeId=${q.beneficiaryEmployeeId}&i9formId=${q.i9FormId}`; 
      let link = `${this.isLocal}/petitioner-landing/beneficiaries/update-employment/I-9?companyId=${q.companyId}&beneficiaryEmployeeId=${q.beneficiaryEmployeeId}&i9formId=${q.i9FormId}&confirmationSent=${q.confirmationSent}&lastConfirmationAdmin=${q.lastConfirmationAdmin}&lastConfirmationDate=${q.lastConfirmationDate}&lastConfirmationTime=${q.lastConfirmationTime}`;
      return link;
    }
    else if (val == 'SEC1COMPLTD') {
      const q = {
        companyId: row.i9FormDetails.company.id || 0,
        beneficiaryEmployeeId: row.i9FormDetails.beneficiary.id || 0,
        beneficiaryEmployeeFName: row.i9FormDetails.beneficiary.fName || 'Not Available',
        beneficiaryEmail: row?.i9FormDetails?.beneficiary?.emailContacts[0]?.email || 'Not Available',
        companyName: row.i9FormDetails.company.companyName || 'Not Available',
        i9FormId: row.i9FormDetails.id,
        confirmationSent: row.i9FormDetails?.sendConfirmation,
        i9StatusCode: row?.i9StatusCode,
        lastConfirmationAdmin: row?.lastConfirmationAdmin,
        lastConfirmationDate: row?.lastConfirmationDate,
        lastConfirmationTime: row?.lastConfirmationTime
      }
      let link = `${this.isLocal}/petitioner-landing/i9-evaluators/evaluation-request?companyId=${q.companyId}&beneficiaryEmployeeId=${q.beneficiaryEmployeeId}&beneficiaryEmployeeFName=${q.beneficiaryEmployeeFName}&beneficiaryEmail=${q.beneficiaryEmail}&companyName=${q.companyName}&i9FormId=${q.i9FormId}&confirmationSent=${q.confirmationSent}&i9StatusCode=${q.i9StatusCode}&lastConfirmationAdmin=${q.lastConfirmationAdmin}&lastConfirmationDate=${q.lastConfirmationDate}&lastConfirmationTime=${q.lastConfirmationTime}`;
      return link;
      // window.open(link);

    }

    else if (val == 'SEC1VERFD') {
      const q = {
        companyId: row.i9FormDetails.company.id || 0,
        beneficiaryEmployeeId: row.i9FormDetails.beneficiary.id || 0,
        beneficiaryEmployeeFName: row.i9FormDetails.beneficiary.fName || 'Not Available',
        beneficiaryEmail: row?.i9FormDetails?.beneficiary?.emailContacts[0]?.email || 'Not Available',
        companyName: row.i9FormDetails.company.companyName || 'Not Available',
        i9FormId: row.i9FormDetails.id,
        confirmationSent: row.i9FormDetails?.sendConfirmation,
        lastConfirmationAdmin: row?.lastConfirmationAdmin,
        lastConfirmationDate: row?.lastConfirmationDate,
        lastConfirmationTime: row?.lastConfirmationTime
      }
      //  let link =  `${this.isLocal}/petitioner-landing/i9-evaluators/evaluation-request?companyId=${q.companyId}&beneficiaryEmployeeId=${q.beneficiaryEmployeeId}&beneficiaryEmployeeFName=${q.beneficiaryEmployeeFName}&beneficiaryEmail=${q.beneficiaryEmail}&companyName=${q.companyName}&i9FormId=${q.i9FormId}`; 
      let link = `${this.isLocal}/petitioner-landing/beneficiaries/update-employment/I-9?companyId=${q.companyId}&beneficiaryEmployeeId=${q.beneficiaryEmployeeId}&i9formId=${q.i9FormId}&confirmationSent=${q.confirmationSent}&lastConfirmationAdmin=${q.lastConfirmationAdmin}&lastConfirmationDate=${q.lastConfirmationDate}&lastConfirmationTime=${q.lastConfirmationTime}`;
      return link;
      //  return link;

      // const q = {
      //   companyId:  row.i9FormDetails.company.id || 0,
      //   beneficiaryId: row.i9FormDetails.beneficiary.id || 0,
      //   i9FormId:row.i9FormDetails.id,
      //   UserId: row.i9FormDetails.createdBy || 'Not Available', 
      // } 

      //  this.i9EverifyService.getPDF(q.companyId, q.i9FormId).subscribe(res => {   
      //       this.i9EverifyService.getPDFFile(q.companyId, q.i9FormId).subscribe(res => {  
      //         console.log('final response>>', res); 
      //         let path= res?.fileDetails?.path ? res?.fileDetails?.path : "";  
      //         console.log('path>>', path);   
      //         this.toastr.success("Form generated successfully!");  
      //         let downloadLink = `${environment.docs}`+path; ; 
      //         console.log('downloadLink>>>>>', downloadLink);
      //         setTimeout(() => { 
      //         //  window.open(downloadLink);
      //         }, 1000);
      //     }) 
      // })  

    }
    else if (val == 'COMPLTD') {
      const q = {
        companyId: row.i9FormDetails.company.id || 0,
        beneficiaryEmployeeId: row.i9FormDetails.beneficiary.id || 0,
        beneficiaryEmployeeFName: row.i9FormDetails.beneficiary.fName || 'Not Available',
        beneficiaryEmail: row?.i9FormDetails?.beneficiary?.emailContacts[0]?.email || 'Not Available',
        companyName: row.i9FormDetails.company.companyName || 'Not Available',
        i9FormId: row.i9FormDetails.id,
        confirmationSent: row.i9FormDetails?.sendConfirmation,
        lastConfirmationAdmin: row?.lastConfirmationAdmin,
        lastConfirmationDate: row?.lastConfirmationDate,
        lastConfirmationTime: row?.lastConfirmationTime
      }
      let link = `${this.isLocal}/petitioner-landing/i9-evaluators/verify-beneficiary?companyId=${q.companyId}&beneficiaryEmployeeId=${q.beneficiaryEmployeeId}&i9formId=${q.i9FormId}&confirmationSent=${q.confirmationSent}&lastConfirmationAdmin=${q.lastConfirmationAdmin}&lastConfirmationDate=${q.lastConfirmationDate}&lastConfirmationTime=${q.lastConfirmationTime}`;
      return link;
    }

    else if (val == 'SENTBACK') {
      const q = {
        companyId: row.i9FormDetails?.company?.id || 0,
        beneficiaryEmployeeId: row.i9FormDetails?.beneficiary?.id || 0,
        beneficiaryEmployeeFName: row.i9FormDetails?.beneficiary?.fName || 'Not Available',
        beneficiaryEmail: row?.i9FormDetails?.beneficiary?.emailContacts?.[0]?.email || 'Not Available',
        companyName: row.i9FormDetails?.company?.companyName || 'Not Available',
        i9FormId: row.i9FormDetails?.id,
        confirmationSent: row.i9FormDetails?.sendConfirmation,
        lastConfirmationAdmin: row?.lastConfirmationAdmin,
        lastConfirmationDate: row?.lastConfirmationDate,
        lastConfirmationTime: row?.lastConfirmationTime
      };
      
      let link = `${this.isLocal}/petitioner-landing/i9-evaluators/evaluation-request?companyId=${q.companyId}&beneficiaryEmployeeId=${q.beneficiaryEmployeeId}&beneficiaryEmployeeFName=${q.beneficiaryEmployeeFName}&beneficiaryEmail=${q.beneficiaryEmail}&companyName=${q.companyName}&i9FormId=${q.i9FormId}&confirmationSent=${q.confirmationSent}`;
      return link;
      // window.open(link);
    }    

    else if (val == 'REJECTED') {
      const q = {
        companyId: row.i9FormDetails.company.id || 0,
        beneficiaryEmployeeId: row.i9FormDetails.beneficiary.id || 0,
        beneficiaryEmployeeFName: row.i9FormDetails.beneficiary.fName || 'Not Available',
        beneficiaryEmail: row?.i9FormDetails?.beneficiary?.emailContacts[0]?.email || 'Not Available',
        companyName: row.i9FormDetails.company.companyName || 'Not Available',
        i9FormId: row.i9FormDetails.id,
        confirmationSent: row.i9FormDetails?.sendConfirmation,
      }
      let link = `${this.isLocal}/petitioner-landing/beneficiaries/list`;
      //let link =  `${this.isLocal}/petitioner-landing/i9-evaluators/evaluation-request?companyId=${q.companyId}&beneficiaryEmployeeId=${q.beneficiaryEmployeeId}&beneficiaryEmployeeFName=${q.beneficiaryEmployeeFName}&beneficiaryEmail=${q.beneficiaryEmail}&companyName=${q.companyName}`; 
      return link;
      // window.open(link);
    }

  }

  deleteExistI9Form(row) {
    const companyId = sessionStorage.getItem('companyId');
    this.i9EverifyService.deleteI9Form(companyId, row.i9FormDetails.id)
      .pipe()
      .subscribe((response: ImagilityBaseResponse) => {
        if (response.status == 200) {
          this.toastr.success(response.message, "I9Form deleted sucessfully");
        }
      })
  }


  ngAfterViewInit() {
    if (this.helpVideoComponent) {
      this.helpVideoComponent.setValues('right', 'How to create Tasks', 'How to create Tasks');
    }
  }

  createEmployee() {

    this.router.navigate(['/petitioner-landing/beneficiaries/beneficiary-invite']);
  }

  openFormModalCreate() {
    this.refs = this.dialogService.open(CreateNewBeneficiaryComponent, {
      header: this.userType == 'Beneficiary' ? 'Create Beneficiary' : 'Create Employee',
      width: '70%',
      contentStyle: { 'max-height': '800px', overflow: 'auto' }
    });
  }

  // addNewTask() {
  //   // this.router.navigate(['petitioner-landing/dashboard/task',  { id: 5 }]);
  //   // this.router.navigate(['petitioner-landing/dashboard/task/task-details', 0]);
  //   sessionStorage.removeItem('taskId');
  //   sessionStorage.removeItem('requestDataDocument');
  //   //this.router.navigate([`${RolesRouteMap.userRoleRouteMapping[sessionStorage.getItem('userTypeRole')]}/dashboard/task-list/new`]);
  //   // const slug = this.userType === 'Attorney' ? 'new-attorney': 'new';
  //   this.router.navigate([`${RolesRouteMap.userRoleRouteMapping[sessionStorage.getItem('userTypeRole')]}/dashboard/superTask/new`]);
  // }

  addNewTask() {
    const dialogConfig = {
      width: '60%',
      closeOnEscape: false,
      closable: false,
      showHeader: false,
      contentStyle: { "z-index": "1001" }
    };
    const seletionDialogRef = this.dialogService.open(PetitionProcessTypeSelectionComponent, {
      ...dialogConfig,
    });
    seletionDialogRef.onClose
                .pipe(takeUntil(this.observableSubscription$)).subscribe((response: number) => {
                  if (response) {
                      if(response == 1) {
                        sessionStorage.removeItem('taskId');
                        sessionStorage.removeItem('requestDataDocument');
                        this.router.navigate([`${RolesRouteMap.userRoleRouteMapping[sessionStorage.getItem('userTypeRole')]}/dashboard/superTask/new`]);
                      } else {
                        this.openQuestionnaireForWizard();
                      }
                    }
                    else {
                    }
                  })
                }

openQuestionnaireForWizard() {
  const dialogConfig = {
    width: '60%',
    closeOnEscape: false,
    closable: false,
    showHeader: false,
    contentStyle: { "z-index": "1001" }
  };
  const wizardRef = this.dialogService.open(CreateTaskWithWizardComponent, {
    ...dialogConfig,
  });
}

  // Filter

  getTaskType() {

    this.referenceLibraryService.getReferenceData('SUPTSKTYP')
      .pipe(take(1))
      .subscribe((response: ReferenceLibrary[]) => {
        this.taskType = response;
        this.getSubTaskType(this.filter.superTaskType);
      });
  }

  handleFilterChange() {    
    this.payload.subTaskTypes = this.filter.subTaskType;
    this.payload.superTaskType = this.filter.superTaskType;
    this.payload.filterTasksFromDate = this.startDate === undefined ? null : moment(this.startDate).format('YYYY-MM-DD');
    this.payload.filterTasksToDate = this.endDate === undefined ? null : moment(this.endDate).format('YYYY-MM-DD');
    if (this.payload.superTaskType === 'NONIMMPET') {
      this.payload.visaTypes = this.filter.visaType;
      this.payload.petitionTypes = this.filter.petitionType;
      this.payload.immClassificationTypes = null;
      this.payload.immCategoryTypes = null;
    } else {
      this.payload.visaTypes = null;
      this.payload.petitionTypes = null;
      this.payload.immClassificationTypes = this.filter.immClassificationType;
      this.payload.immCategoryTypes = this.filter.immCategoryType;
    }
    this.getTasks();
  }

  // filterCancel() {
  //   this.filter.superTaskType = this.payload.superTaskType;
  //   this.filter.subTaskType = this.payload.subTaskTypes;
  //   this.filter.visaType = this.payload.visaTypes;
  //   this.filter.petitionType = this.payload.petitionTypes;
  //   this.filter.immClassificationType = this.payload.immClassificationTypes;
  //   this.filter.immCategoryType = this.payload.immCategoryTypes;
  // }

  getSubTaskType(superTaskType: string) {

    this.filter.superTaskType = superTaskType;
    if (superTaskType === 'IMMPET' ? this.immigrantSubTask : this.nonImmigrantSubTask) {
      if (superTaskType === 'IMMPET') {
        this.subTaskType = [...this.immigrantSubTask];
      } else {
        this.subTaskType = [...this.nonImmigrantSubTask];
      }
      this.filter.subTaskType = this.subTaskType.map(x => x.code);
      this.onSubTaskSelected();
    } else {
      this.dashboardWidgetsService.getSubTasksType(superTaskType)
        .pipe(take(1))
        .subscribe((response: ReferenceLibrary[]) => {
          if (response) {
            this.subTaskType = [];
            new Set(response.map(x => x.id)).forEach(id => {
              this.subTaskType.push(response.find(x => x.id === id));
            });
            if (superTaskType === 'IMMPET') {
              this.subTaskType.push({
                code: "PETITION",
                createdBy: 1,
                description: "Petition",
                group: "TASKTYP",
                id: 494,
                modifiedBy: null,
                name: "Petition",
                refGroupdesc: "Task Type",
              })
            }
            this.filter.subTaskType = this.subTaskType.map(x => x.code);
            if (superTaskType === 'IMMPET') {
              this.immigrantSubTask = [...this.subTaskType];
            } else if (superTaskType === 'NONIMMPET') {
              this.nonImmigrantSubTask = [...this.subTaskType];
            }
          } else {
            this.subTaskType = [];
            this.filter.subTaskType = [];
          }
          this.onSubTaskSelected();
        });
    }
  }

  onSubTaskSelected() {;
    if (this.filter.superTaskType === 'IMMPET') {
      switch (this.toggleStatus) {
        case 'NEW':
          this.cols = this.immigrantTypeCols;
          break;
        case 'FILED':
          this.cols = this.awaitingImmigrantTypeCols;
          break;
        case 'COMPLETE':
          this.cols = this.completeImmigrantTypeCols;
          break;
        default:
          break;
      }
      //this.cols = this.immigrantTypeCols;
      this.nestedCols = this.immigrantTypeNestedCols;
      //this.getIMMCategory();
    } else if (this.filter.superTaskType === 'NONIMMPET') {
      // this.cols = this.nonImmigrantTypeCols;
      this.cols = this.isI9Admin ? this.i9TypeCols : this.nonImmigrantTypeCols ;
      switch (this.toggleStatus) {
        case 'NEW':
          this.cols = this.nonImmigrantTypeCols;
          break;
        case 'FILED':
          this.cols = this.awaitingNonImmigrantTypeCols;
          break;
        case 'COMPLETE':
          this.cols = this.completeNonImmigrantTypeCols;
          break;
        default:
          break;
      }
      //this.cols = this.nonImmigrantTypeCols;
      this.nestedCols = this.nonImmigrantTypeNestedCols;
      //this.getVisaType();
    }

    // this.cols = this.i9TypeCols; 

  }

  getVisaType() {

    if (!this.visaTypes) {
      this.referenceLibraryService.getReferenceData('VISATYP')
        .pipe(take(1))
        .subscribe((response: ReferenceLibrary[]) => {
          this.visaTypes = response;
          this.filter.visaType = this.visaTypes.map(x => x.code);
          this.getPetitionType();
        });
    } else {
      this.filter.visaType = this.visaTypes.map(x => x.code);
      this.getPetitionType();
    }
  }

  getPetitionType() {

    if (!this.petitionTypes) {
      this.referenceLibraryService.getReferenceData('PETTYP')
        .pipe(take(1))
        .subscribe((response: ReferenceLibrary[]) => {
          this.petitionTypes = response;
          this.filter.petitionType = this.petitionTypes.map(x => x.code);
          this.handleFilterChange();
        });
    } else {
      this.filter.petitionType = this.petitionTypes.map(x => x.code);
      this.handleFilterChange();
    }
  }

  getIMMClassification() {

    if (this.immCategoryTypes) {
      if (!this.immClassificationTypes) {
        this.immClassificationTypes = [];
        const req = [];
        this.immCategoryTypes.forEach(immCategory => {
          req.push(this.referenceLibraryService.getReferenceData(immCategory.code));
        });
        combineLatest(req)
          .pipe(take(1))
          .subscribe((response: any[]) => {
            response.forEach((res, k) => {
              this.immClassificationTypes = [...this.immClassificationTypes, ...res];
            });
            // this.immClassificationTypes = [...response[0], ...response[1]];
            this.filter.immClassificationType = this.immClassificationTypes.map(x => x.code);
            this.handleFilterChange();
          });
      } else {
        this.filter.immClassificationType = this.immClassificationTypes.map(x => x.code);
        this.handleFilterChange();
      }
    }
  }

  getIMMCategory() {

    if (!this.immCategoryTypes) {
      this.referenceLibraryService.getReferenceData('GCIMMCATG')
        .pipe(take(1))
        .subscribe((response: ReferenceLibrary[]) => {
          this.immCategoryTypes = response;
          this.filter.immCategoryType = this.immCategoryTypes.map(x => x.code);
          this.getIMMClassification();
        });
    } else {
      this.filter.immCategoryType = this.immCategoryTypes.map(x => x.code);
      this.getIMMClassification();
    }
  }

  getTasks() {
    if (this.toggleStatus === 'NEW') {
      this.statusObj = NewPetitionStatusArr;
    } else if (this.toggleStatus === 'COMPLETE') {
      this.statusObj = CompletedPetitionStatusArr;
    } else {
      this.statusObj = InprogressPetitionStatusArr;
    }

    this.payload.filterTasksFromDate = this.filter.duration ? this.getDate('from') : null;
    this.payload.filterTasksToDate = this.filter.duration ? this.getDate('to') : null;
    if (this.userType === 'Attorney') {
      const payload = {
        taskStatus: this.statusObj
      };

      this.payload.taskStatus = this.statusObj;

      // Create a new object without the few key to load Immigrant and non-Immigrant Visas IMGLT-26349
      const { visaTypes, petitionTypes, subTaskTypes, superTaskType, immClassificationTypes, immCategoryTypes,    ...newPayload } = this.payload;      

      this.dashboardWidgetsService.getTaskListForAttorney(parseInt(sessionStorage.getItem('companyId')), newPayload)
        .pipe(take(1))
        .subscribe((data: any) => {
          this.taskList = (data && data['superTasks']) ? data['superTasks'].map((item) => { if (item?.filedDate) { item.filedDate = moment(item.filedDate).format('YYYY-MM-DD') } return item; }) : [];
          this.newTaskCount = data && data.newSuperTaskCount;
          this.inProgressTaskCount = data && data.pendingSuperTaskCount;
          this.completedTaskCount = data && data.completedSuperTaskCount;
          sessionStorage.setItem('beneficiaryId', (this.taskList.length ? data['superTasks'][0].beneficiaryId : null));
          this.setDataSource();
        });
    } else if (this.userType != 'Attorney' && this.userType != 'I9Admin') {
      this.payload.taskStatus = this.statusObj;
      const { visaTypes, petitionTypes, subTaskTypes, superTaskType, immClassificationTypes, immCategoryTypes,    ...newPayload } = this.payload; 
      this.dashboardWidgetsService.getSuperTaskList(newPayload)
        .pipe(take(1))
        .subscribe((data: any) => {
          this.taskListObj = data['data']['superTasks'];
          if (data['status'] === 200) {
            this.taskList = (data['data']['superTasks']) ? data['data']['superTasks'].map((item) => { if (item?.filedDate) { item.filedDate = moment(item.filedDate).format('YYYY-MM-DD') } return item; }) : [];
            this.newTaskCount = data['data'].newSuperTaskCount;
            this.inProgressTaskCount = data['data'].pendingSuperTaskCount;
            this.completedTaskCount = data['data'].completedSuperTaskCount;
            sessionStorage.setItem('beneficiaryId', (this.taskList.length ? data['data']['superTasks'][0].beneficiaryId : null));
            this.setDataSource();
          } else {

            this.taskList = [];
            this.newTaskCount = 0;
            this.inProgressTaskCount = 0;
            this.completedTaskCount = 0;
            // this.dataSource = new MatTableDataSource(this.taskList);
            this.noDataMsg = true;
          }
        });
    }
    else if(this.userType === 'I9Admin'){
      this.getI9AdminDashboardList();
    }
    
  }

  tab1(isCount: boolean = false) {
    const payload = {
      "pageNumber": 0,
      "pageSize": 50,
      "searchText": ""
    };
    this.taskList = [];
    this.i9EverifyService.evalautionRequest(this.i9AdminCompanyId, payload)
      .pipe(take(1))
      .subscribe((data: any) => {
        if (isCount && data) {
          this.taskList = (data['i9DashBoardList']) ? data['i9DashBoardList'] : [];
          this.sortTaskList();
        }
        this.i9InitiationsCount = data && data.totalElementCount ? data.totalElementCount : 0;
      });
  }
  tab2(isCount: boolean = false) {
    const payload = {
      "pageNumber": 0,
      "pageSize": 50,
      "searchText": ""
    };

    this.i9EverifyService.evalautionFormList(this.i9AdminCompanyId, payload)
      .pipe(take(1))
      .subscribe((data: any) => {
        if (isCount && data) {
          this.taskList = (data['i9DashBoardList']) ? data['i9DashBoardList'] : [];
          this.sortTaskList();
        }
        this.i9EvaluationsCount = data && data.totalElementCount ? data.totalElementCount : 0;
      });
  }

  tab3(isCount: boolean = false) {
    const payload = {
      "pageNumber": 0,
      "pageSize": 50,
      "searchText": ""
    };

    this.i9EverifyService.getRemoteI9Verification(this.i9AdminCompanyId, payload)
      .pipe(take(1))
      .subscribe((data: any) => {
        if (isCount && data) {
          this.taskList = (data['i9DashBoardList']) ? data['i9DashBoardList'] : [];
          this.sortTaskList();
        }
        this.i9EverificationsCount = data && data.totalElementCount ? data.totalElementCount : 0;
      });
  }

  onRowExpand(event) {
    if (!event.data.subTaskList) {
      this.dashboardWidgetsService.getSubTaskList(event.data.id)
        .pipe(take(1))
        .subscribe((data: ImagilityBaseResponse) => {
          if (data.status === 200) {
            event.data.subTaskList = data.data;
          }
        });
    }
  }

  setDataSource() {
    if (this.taskList && this.taskList.length > 0) {
      this.taskList = this.taskList.slice(0, 10);
      // this.taskList.map((item) => {
      //   item.petitionType = item.taskTypeDetails ? item.taskTypeDetails.visaType + ' ' + item.taskTypeDetails.petitionName : null;
      // });
    } else {
      this.taskList = [];
    }
    // this.dataSource = new MatTableDataSource(this.taskList);
  }

  handleTask(task) {
    if(task?.taskSource === 'WIZARD'){
      sessionStorage.setItem('wizardTID', task?.subTaskId);
      this.router.navigate([`${RolesRouteMap.userRoleRouteMapping[sessionStorage.getItem('userTypeRole')]}/immigration-wizard/upload/individual-details`]);
    }else{
      sessionStorage.setItem('tempBeneficiaryEmployeeId', task.beneficiaryId);
      sessionStorage.setItem('beneficiaryId', task.beneficiaryId);
      sessionStorage.removeItem('selectedSocCode'); 
      sessionStorage.removeItem('majorgroupValue');
      this.store.dispatch(new Task.SetTaskRouteId(task.id));
      this.router.navigate([`${RolesRouteMap.userRoleRouteMapping[sessionStorage.getItem('userTypeRole')]}/dashboard/superTask/task-details`]);
    }
  }

  resetFilters() {
    //this.filter = Object.assign({},this.initialFilterState);
    this.getSubTaskType('NONIMMPET');
  }

  toogleTaskTab(toggleText: string = '', event: any = null, duration: string = 'today') {    
    if (event && event.target && event.target.classList && (event.target.className.indexOf('p-dropdown-label') > -1 || event.target.className.indexOf('p-inputtext') > -1 || event.target.className.indexOf('p-dropdown-trigger') > -1 || event.target.className.indexOf('p-dropdown-trigger-icon') > -1 || event.target.className.indexOf('pi-chevron-down') > -1)) {
      //Opening the dropdown
    } else {
      if (this.toggleStatus !== toggleText) {
        //reset the filters for new tab
        this.toggleStatus = toggleText;
        //this.filter.duration = this.getDuration(toggleText);
        //this.resetFilters();
        this.onSubTaskSelected();
        this.getTasks(); //Now as we not showing filters so don't need to call resetFilters function here directly calling getTasks. 
      } else {
        this.toggleStatus = toggleText;
        //this.filter.duration = duration;
        this.getTasks();
      }

    }
  }

  getDuration(statusText: string) {
    switch (statusText) {
      case 'NEW':
        return this.viewOptionNew;
      case 'FILED':
        return this.viewOptionPending;
      case 'COMPLETE':
        return this.viewOptionCompleted;
    }
  }

  getDate(flag: string) {
    if (flag === 'to') {
      return moment().format('YYYY-MM-DD');
    } else {
      switch (this.filter.duration) {
        case 'today':
          return moment().format('YYYY-MM-DD');
        case 'weekly':
          return moment().subtract(1, 'w').format('YYYY-MM-DD');
        case 'monthly':
          return moment().subtract(1, 'M').format('YYYY-MM-DD');
        case 'quarterly':
          return moment().subtract(3, 'M').format('YYYY-MM-DD');
        case 'halfYearly':
          return moment().subtract(6, 'M').format('YYYY-MM-DD');
        case 'yearly':
          return moment().subtract(1, 'y').format('YYYY-MM-DD');
      }
    }
  }

  handleDelete(input) {

    const title = 'Confirm';
    const message = 'Do you wish to delete the Task?';
    this.dialogueInitializer(title, message).afterClosed().subscribe(result => {
      if (result) {
        this.dashboardWidgetsService.deleteTaskId(input.id)
          .pipe(take(1))
          .subscribe(data => {
            if (data) {
              this.toastService.success('Task deleted successfully', 'Success');
              this.getTasks();
            }
          });
      }
    });
  }

  showMore() {
    if (this.isI9Admin) {
      this.router.navigate([`i9-admin-landing/beneficiaries/list`]);
    } else {
      this.router.navigate([`${sessionStorage.getItem('userTypeRole') === RolesRouteMap.ATTORNEY ? 'attorney' : 'petitioner'}-landing/${sessionStorage.getItem('userTypeRole') === RolesRouteMap.ATTORNEY ? 'attorney' : 'petitioner'}/task-list`]);
    }
  }

  dialogueInitializer(title: string, message: string) {

    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      width: '400px',
      data: {
        update: { title, message },
        buttons: ['Cancel', 'Delete']
      }
    });
    return dialogRef;
  }

  onActiveTaskTab(activeTab: string) {
    this.activeTasksTab = activeTab;
    switch (activeTab) {
      case 'mine':
        this.payload.employeeId = this.activeEmployeeId;
        break;
      case 'all':
        this.payload.employeeId = null;
    }
    this.getTasks();
  }

  getI9AdminDashboardList() {
    if (this.toggleStatus === 'I-9_Initiations') {
      this.cols = [
        { field: 'fname', header: 'Employee Name' },
        { field: 'startDate', header: 'Employment Start Date' },
        { field: 'endDate', header: 'Employment End Date' },
        { field: 'i9StatusCode', header: 'Status' },
        { field: 'action', header: 'Action' }
      ];
      this.tab1(true);
    } else if (this.toggleStatus === 'I9-Evaluations') {
      this.cols = [
        { field: 'fname', header: 'Employee Name' },
        { field: 'startDate', header: 'Employment Start Date' },
        { field: 'endDate', header: 'Employment End Date' },
        { field: 'i9StatusCode', header: 'Status' },
        { field: 'action', header: 'Action' },
        { field: 'evalComment', header: 'Message' }
      ];
      this.tab2(true);
    } else if (this.toggleStatus === 'I-9 E-Verifications') {
      this.cols = [
        { field: 'fname', header: 'Employee Name' },
        { field: 'startDate', header: 'Employment Start Date' },
        { field: 'endDate', header: 'Employment End Date' },
        { field: 'everifyStatus', header: 'Status' },
        { field: 'caseNumber', header: 'Case Number' }
      ];
      this.tab3(true);
    }
  }
  sortTaskList() {
    this.taskList.sort(function (a, b) {
      if (a.beneficiaryEmployeeId !== null) {
        return b.beneficiaryEmployeeId - a.beneficiaryEmployeeId;
      } else {
        return b.i9FormDetails.beneficiary.id - a.i9FormDetails.beneficiary.id;
      }
    });
  }
  onSort(event: SortEvent) {
    // Implement sorting logic here
    // You can access event.field (the field to sort) and event.order (the sort order: 1 for ascending, -1 for descending)
    // Sort your taskList array based on the provided field and order
    const field = event.field;
    const order = event.order;

    if (order === 1) {
      this.taskList = this.taskList.sort((a, b) => (a[field] > b[field] ? 1 : -1));
    } else {
      this.taskList = this.taskList.sort((a, b) => (a[field] < b[field] ? 1 : -1));
    }
  }

  viewEverifyStatus(task) {
    this.statusModal = true;
    this.caseNumberUpdate = task.caseNumber || '--'
    this.selectedStatus = task.everifyStatus;
    this.empName = `${task.fname} ${task.lname}`;
  }
  ngOnDestroy() {
    this.taskList = [];
    this.observableSubscription$.next();
  }


gotoVerify() {
  this.router.navigate(['/petitioner-landing/i9-evaluators/initiations/3']);
}
}// class end
