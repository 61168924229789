import { Component, OnInit, Output, ChangeDetectorRef, Input } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { I9Service } from '../services/i9-service'
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs/internal/Subject';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { ImagilityBaseResponse } from 'app-models';
import * as moment from 'moment';
import { environment } from '../../environments/environment';
import { NgForm } from '@angular/forms';
import { formatDate } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { StringifyOptions } from 'querystring';
import * as Roles from 'src/app/data/constants/roles';
@Component({
    selector: 'app-beneficiary-forms',
    templateUrl: './beneficiary-forms.component.html',
    styleUrls: ['./beneficiary-forms.component.scss']
})
export class BeneficiaryFormsComponent implements OnInit {
    @Input() isEdit = false;
    signaturePic: any;
    benSignImage: any;

    documentArray: any = [];
    //forgnPassportNum94: any;
    selectedListGroup: string = 'A';
    listB: any = [];
    listC: any = [];
    listBCurrentSelection: string = '';
    listCCurrentSelection: string = '';

    //categoryNumber: string = '';
    currentCategoryFlag: string = '';
    radioSelected: boolean;
    selectedForeignPassport: any = 'Select';
    selectedlawfulResident: any = 'Select';
    selectedAlianModel: any = 'Select';
    nonCzSelectedValue: any = 'Select';
    citiSelectedValue: any;

    companyId: any;
    beneficiaryId: any;
    i9FormId: any;
    selectedFile: any;
    observableSubscription$ = new Subject();
    documentCategory: string = 'CITZN';
    fileCategory: string = 'USPASSPORT';
    foreignDocumentType: string = '--'
    isSec1Completed: string = '';
    isFormCompleted: boolean = false;
    beneficiaryPayload: any;

    stateProvinceCodePBeneficiary;
    stateProvinceCodePreparer;
    isExpireUsPassort: boolean = false;
    isExpireForeignPassport: boolean = false;
    isExpirelawfulResident: boolean = false;

    isPreparer: any = false;
    I9UserId: any;
    isLocked: any = false;
    dtoFlag: string = '';
    preparerTodayDate: any;
    todayDate: any;
    i94Num: string = '';
    alienRegNum: string = '';
    //forgnPassportNum: string = '';
    uscisNum: string = '';
    selectedValue: string;
    countryCode: string = 'USA';
    selectedCountry: any;
    notApplicable: boolean = false;
    lawfulSelectedValue: any;
    usPassportSelectedValue: any;
    displayNotice = false;
    antiDiscriminatoryData = '';
    agreeCheckboxSelection: boolean = false;
    formDocumentsOptions: string = '';
    selectedLawfulCountry: string = 'USA';
    viewEnable: boolean = false;


    formDocuments: any = [
        { name: 'A citizen of the United States', checked: false, category: 'CITZN', fileCategory: 'USPASSPORT', sortName: 'Citizen', desc: 'U.S Passport or PR Card' },
        { name: 'A noncitizen national of the United States', checked: false, category: 'NONCITZNNATIONAL', fileCategory: 'FORGNPASSPORT', sortName: 'Noncitizen national of the United States', desc: 'An individual born in American Samoa, certain former citizens of the former Trust Territory of the Pacific Islands, and certain children of noncitizen nationals born abroad' },
        { name: 'A lawful permanent resident (Enter USCIS or A-Number.) ', checked: false, category: 'PERMRESIDENT', fileCategory: null, sortName: 'Lawful permanent resident', desc: 'Refers to an individual who is a LPR of the US, known as a green card holder. This term includes conditional residents. Employees choosing this status should enter their seven- to nine-digit Alien Registration Number or USCIS Number. Asylees and refugees should not choose this option; instead, they should select "A noncitizen authorized to work.' },
        { name: 'A noncitizen (other than Item Numbers 2. and 3. above) authorized to work until (exp. date, if any)', checked: false, category: 'WORKAUTHALIEN', fileCategory: null, sortName: 'A noncitizen authorized to work', desc: 'Individual authorized to work in the US, who is not a U.S. citizen or national, nor a lawful permanent resident. This includes asylees, refugees, and certain citizens of the Federated States of Micronesia, the Republic of the Marshall Islands, or Palau. Employees must also provide the expiration date of their employment authorization. If no expiration date exists, enter N/A. If employment authorization has been automatically extended, provide the expiration date of the extension. Additionally, employees must enter one of the following: Alien Registration Number (A-Number)/USCIS Number, Form I-94 Admission Number, or Foreign Passport Number and Country of Issuance. Example H-1B, STEM, OPT, EAD, L-1’s.' },
    ]

    beneficiaryDocumentData: any = {
        I9document: {
            us_Passport: {
                passportFile: '',
                fileName: '',
                url: '',
                id: ''
            },
            nonUs_Passport: {
                foreginPassportFile: '',
                fileName: '',
                url: '',
                id: ''
            },
            lawful_document: {
                lawfulFile: '',
                fileName: '',
                url: '',
                id: ''
            },
            alien_document: {
                alienFile: '',
                fileName: '',
                url: '',
                id: ''
            }
        }
    };


    beneficiaryData: any = {};
    idNumber: any;

    usPassportOptions: any = ['US Passport', 'US Passport Card'];
    foreignPassportOptions: any = ['Foreign Passport', 'Form I-766'];
    lawfulResidentOption: any = ['A Number', 'USCIS Number'];
    expirationOption: any = ['Form I-94 Admission Number', 'Alien Registration Number', 'USCIS Number', 'Foreign Passport Number'];
    stateList: any = [];

    countryList: any = [];
    translatorArray: any = [
        { key: 'I did not use a preparer or translator', value: 'Preparer' },
        { key: 'A preparer/translator assisted me in completing Section-1 for filling the I-9 Form', value: 'Translator' }
    ];

    getAuthorizationType: string = 'CITZN';
    workAuthorizationProof;
    workAuthorizationIDNumber;
    expirationDate: any;
    categoryList: any = [
        { categoryName: 'USCIS Number', categoryCode: 'USCISNUM' },
        { categoryName: 'I94 Number', categoryCode: 'I94NUM' },
        { categoryName: 'Alien Number', categoryCode: 'ALIENREGNUM' },
        { categoryName: 'Foreign Passport', categoryCode: 'FORGNPASSPORTNUM' },
    ];
    // selectedCategory:string = null;
    // iscategoryNumber:boolean = false;
    // isselectedCategory:boolean = false;
    isLockedAction: any = false;

    evaluationData: any = [];
    evaluatioTypeData: any;
    documentsEvaluation: any = [];
    fileName: any;
    url: any;
    id: any;
    type: string;
    statusEval: string;
    isEvalReport: boolean = false;
    isAdminViewing: boolean = false;
    isAdminEditing: boolean = false;
    isAdmin: boolean = false;
    constructor(
        private activeRoute: ActivatedRoute,
        private router: Router,
        private apiService: I9Service,
        private toastr: ToastrService,
        private cRef: ChangeDetectorRef,
        private domSanitizer: DomSanitizer
    ) { }

    ngOnInit(): void {
        if (sessionStorage.getItem('userTypeRole') === Roles.I9ADMIN) {
            this.isAdmin = true;
        }
        this.getDropdownListB();
        this.getDropdownListC();

        this.getQueryParams();
        this.getStatesList();
        // this.getBeneficiaryDocument();
        this.getCountryList();
        this.getAntiDiscriminatoryData();
        this.todayDate = new Date();
        this.preparerTodayDate = new Date();
        this.getDocumentsEvaluation();
    }


    getDropdownListB() {
        this.apiService.refLibraryListB().pipe(takeUntil(this.observableSubscription$)).subscribe((response: ImagilityBaseResponse) => {
            this.listB = response.data;
        })
    }

    getDropdownListC() {
        this.apiService.refLibraryListC().pipe(takeUntil(this.observableSubscription$)).subscribe((response: ImagilityBaseResponse) => {
            this.listC = response.data;
        })
    }

    getQueryParams() {
        this.activeRoute.queryParams
            .subscribe(params => {
                this.companyId = params.companyId;
                this.beneficiaryId = params.beneficiaryId ? params.beneficiaryId : params.beneficiaryEmployeeId;
                this.i9FormId = params.i9FormId || params.I9FormId;
                this.I9UserId = params.I9UserId;
                this.viewEnable = params.view;
                this.isAdminViewing = !!(params?.isAdminViewing);
                this.isAdminEditing = params?.canAdminEdit;
                // if locked then I am hiding all
                this.isLocked = params.locked;
                if (params.locked == 'true') {
                    this.isFormCompleted = true;
                    this.isLockedAction = true;
                } else {
                    this.isLockedAction = false;
                }
                // if locked then I am hiding all

                this.beneficiaryData = {
                    "companyId": Number(this.companyId),
                    "beneficiaryId": Number(this.beneficiaryId),
                    "i9FormDtlId": Number(this.i9FormId),
                    "beneficiaryDetails": {
                        "firstName": '',
                        "middleName": '',
                        "lastName": '',
                        // "aliasName": null, 
                        "aliasName": [
                            {
                                "createdBy": Number(this.I9UserId),
                                "modifiedBy": null,
                                "id": 0,
                                "title": "",
                                "beneficiary": {
                                    "id": Number(this.beneficiaryId)
                                },
                                "family": null,
                                "fname": "",
                                "lname": null,
                                "mname": null
                            }
                        ],
                        "benAddress": [
                            {
                                "createdBy": Number(this.I9UserId),
                                "modifiedBy": null,
                                "id": 0,
                                "addressLine1": '',
                                "addressLine2": '',
                                "postCode": '',
                                "doorNo": "",
                                "streetNo": "",
                                "countryCode": {
                                    "createdBy": 1,
                                    "modifiedBy": null,
                                    "countryName": "United States of America",
                                    "countryCode": "USA",
                                    "shortCountryCode": "US",
                                    "phoneCode": "1"
                                },
                                "stateProvinceCode": null,
                                "stateProvinceName": null,
                                "city": "",
                                "type": {
                                    "createdBy": 1,
                                    "modifiedBy": null,
                                    "id": 0,
                                    "group": "ADDR",
                                    "refGroupdesc": "Address Type",
                                    "code": "CURR",
                                    "name": "Current",
                                    "description": "Current Address",
                                    "isGenerated": true,
                                    "isUploaded": false
                                },
                                "beneficiary": {
                                    "id": Number(this.beneficiaryId)
                                },
                                "family": null,
                                "company": null,
                                "employee": null,
                                "immigration": null,
                                "addressLine2NoType": null,
                                "addressTypeCode": null,
                                "stateName": null,
                                "locality": '',
                                "lcaApplication": null,
                                "county": null,
                                "countryName": null
                            },
                            {
                                "createdBy": Number(this.I9UserId),
                                "modifiedBy": null,
                                "id": 0,
                                "addressLine1": '',
                                "addressLine2": '',
                                "postCode": '',
                                "doorNo": "",
                                "streetNo": "",
                                "countryCode": {
                                    "createdBy": 1,
                                    "modifiedBy": null,
                                    "countryName": "United Stated Of America",
                                    "countryCode": "USA",
                                    "shortCountryCode": "US",
                                    "phoneCode": "1"
                                },
                                "stateProvinceCode": "California",
                                "stateProvinceName": "CA",
                                "city": "AKCD",
                                "type": {
                                    "createdBy": 1,
                                    "modifiedBy": null,
                                    "id": 0,
                                    "group": "ADDR",
                                    "refGroupdesc": "Address Type",
                                    "code": "PERM",
                                    "name": "Permanent",
                                    "description": "Permanent Address",
                                    "isGenerated": true,
                                    "isUploaded": false
                                },
                                "beneficiary": {
                                    "id": Number(this.beneficiaryId)
                                },
                                "family": null,
                                "company": null,
                                "employee": null,
                                "immigration": null,
                                "addressLine2NoType": null,
                                "addressTypeCode": null,
                                "stateName": null,
                                "locality": "AB",
                                "lcaApplication": null,
                                "county": null,
                                "countryName": null
                            },
                            {
                                "createdBy": Number(this.I9UserId),
                                "modifiedBy": null,
                                "id": 0,
                                "addressLine1": "ABCD",
                                "addressLine2": "ABCD",
                                "postCode": "22222",
                                "doorNo": "",
                                "streetNo": "",
                                "countryCode": {
                                    "createdBy": 1,
                                    "modifiedBy": null,
                                    "countryName": "Unite States Of America",
                                    "countryCode": "USA",
                                    "shortCountryCode": "US",
                                    "phoneCode": "1"
                                },
                                "stateProvinceCode": "California",
                                "stateProvinceName": "CA",
                                "city": "Canada",
                                "type": {
                                    "createdBy": 1,
                                    "modifiedBy": null,
                                    "id": 0,
                                    "group": "ADDR",
                                    "refGroupdesc": "Address Type",
                                    "code": "FORN",
                                    "name": "FOREIGN",
                                    "description": "foreign  address",
                                    "isGenerated": true,
                                    "isUploaded": false
                                },
                                "beneficiary": {
                                    "id": Number(this.beneficiaryId)
                                },
                                "family": null,
                                "company": null,
                                "employee": null,
                                "immigration": null,
                                "addressLine2NoType": null,
                                "addressTypeCode": null,
                                "stateName": null,
                                "locality": null,
                                "lcaApplication": null,
                                "county": null,
                                "countryName": null
                            }
                        ],
                        "benEmail": [
                            {
                                "createdBy": Number(this.I9UserId),
                                "modifiedBy": null,
                                "id": 0,
                                "email": '',
                                "beneficiary": {
                                    "id": Number(this.beneficiaryId)
                                },
                                "family": null,
                                "type": {
                                    "createdBy": 1,
                                    "modifiedBy": null,
                                    "id": 0,
                                    "group": "EMLTYP",
                                    "refGroupdesc": "Email Type",
                                    "code": "PRIM",
                                    "name": "Primary",
                                    "description": "Primary",
                                    "isGenerated": true,
                                    "isUploaded": false
                                },
                                "company": null,
                                "employee": null,
                                "emailTypeCode": null
                            }
                        ],
                        "benPhone": [
                            {
                                "createdBy": Number(this.I9UserId),
                                "modifiedBy": null,
                                "id": 0,
                                "phoneNo": null,
                                "countryCode": {
                                    "createdBy": 1,
                                    "modifiedBy": null,
                                    "countryName": "United States of America",
                                    "countryCode": "USA",
                                    "shortCountryCode": "US",
                                    "phoneCode": "1"
                                },
                                "beneficiary": {
                                    "id": Number(this.beneficiaryId)
                                },
                                "family": null,
                                "company": null,
                                "employee": null,
                                "type": {
                                    "createdBy": 1,
                                    "modifiedBy": null,
                                    "id": 0,
                                    "group": "PHON",
                                    "refGroupdesc": "Phone Type",
                                    "code": "MOBL",
                                    "name": "Mobile",
                                    "description": "Mobile",
                                    "isGenerated": true,
                                    "isUploaded": false
                                },
                                "extension": null,
                                "phoneTypeCode": null
                            }
                        ],
                        "dob": '',
                        "ssn": '',
                        "benSignImage": '',
                        "benSmallSignImage": null,
                        "alienRegNo": null,
                        "beneficiarySignDate": '',
                        "passport": [
                            {
                                "createdBy": Number(this.I9UserId),
                                "modifiedBy": null,
                                "id": 0,
                                "passportNo": "",
                                "issueDate": "",
                                "expiryDate": "",
                                "issueCountryCode": {
                                    "createdBy": 1,
                                    "modifiedBy": 0,
                                    "countryName": "",
                                    "countryCode": "",
                                    "shortCountryCode": "",
                                    "phoneCode": "1",
                                    "sequenceNumber": 0
                                }
                            }
                        ],
                        "ssn2": null,
                        "ssn4": null,
                        "ssn3": null,
                        "primaryEmail": null,
                        "formattedDob": null,
                        "phoneWithCountryCode": null,
                        "i94Number": null
                    },
                    "i9WorkAuthPreparerInfo": {
                        "workAuthorizationType": this.documentCategory,
                        "workAuthorizationProof": this.workAuthorizationProof,
                        "isExpirationDtNa": null,
                        "expirationDate": null,
                        "idNumber": null,
                        "countryCode": 'USA',
                        "forgnPassportIdNumber": null,
                        "newVal": "",
                        "newCountryVal": "",
                        "preparerInfo": {
                            "firstName": '',
                            "lastName": '',
                            "middleName": '',
                            "prepAddress": [
                                {
                                    "addressLine1": '',
                                    "addressLine2": '',
                                    "countryCode": {
                                        "countryCode": "USA"
                                    },
                                    "city": '',
                                    "stateProvinceCode": null,
                                    "stateProvinceName": null,
                                    "type": {
                                        "createdBy": 1,
                                        "modifiedBy": null,
                                        "id": 1,
                                        "group": "ADDR",
                                        "refGroupdesc": "Address Type",
                                        "code": "CURR",
                                        "name": "Current",
                                        "description": "Current Address",
                                        "isGenerated": true,
                                        "isUploaded": false
                                    },
                                    "locality": '',
                                    "postCode": '',
                                }
                            ],
                            "prepSignImage": "",
                            "prepSmallSignImage": "",
                            "preparerSignDate": ''
                        }
                    },
                    "verificationDetails": null,
                    "isAntiDiscrAccpt": false,
                    "statusCode": "SEC1COMPLTD",
                    "flagUSIC": this.dtoFlag,
                    "uscisNo": null,
                    "formI94No": null,
                    "alienNumber": null,
                    "categoryNumber": '',
                    "forgnPassportNum": '',
                    "forgnPassportNum94": ''
                }

                this.getIsSubmitted();

            }
            );
        //  this.getBeneficiaryDocument();

    }


    radioButton(category, fileCategory, index) {
        this.beneficiaryData.categoryNumber = null;
        this.beneficiaryData.forgnPassportNum = null;
        this.selectedValue = null;

        if (category == 'CITZN' || category == 'NONCITZNNATIONAL') {
            this.countryCode = 'USA';
        }
        this.documentCategory = category;
        this.fileCategory = fileCategory;
        this.getAuthorizationType = category;

        this.beneficiaryDocumentData.I9document.us_Passport.passportFile = '';
        this.beneficiaryDocumentData.I9document.us_Passport.fileName = '';

        this.beneficiaryDocumentData.I9document.nonUs_Passport.foreginPassportFile = '';
        this.beneficiaryDocumentData.I9document.nonUs_Passport.fileName = '';

        this.beneficiaryDocumentData.I9document.lawful_document.lawfulFile = '';
        this.beneficiaryDocumentData.I9document.lawful_document.fileName = '';

        this.beneficiaryDocumentData.I9document.alien_document.alienFile = '';
        this.beneficiaryDocumentData.I9document.alien_document.fileName = '';

        for (let i = 0; i < this.formDocuments.length; i++) {
            if (index == i) {
                this.formDocuments[i].checked = true;
                this.getAuthorizationType = category;
            }
            else {
                this.formDocuments[i].checked = false;
            }
        }

        // this.getBeneficiaryDocument();

    }


    imgSrc: string = '';
    imgSrcP: string = '';
    bName: string = '';
    bEmil: string = '';
    bTilldate: string = '';
    defaultDate: Date = new Date("January 31 1980 12:30");
    expiredDate: string;
    isDateExpired: boolean = false;
    isFormDisabled: boolean = false;

    // prepSignImage
    getIsSubmitted() {
        this.apiService.isBeneficiaryFormSubmitted(this.companyId, this.i9FormId).pipe().subscribe((result: any) => {
            this.selectedValue = result.data.i9WorkAuthPreparerInfo.workAuthorizationType;

            const initiationDate = new Date(result.data.createdDate);
            initiationDate.setDate(initiationDate.getDate() + 1);
            const month = (initiationDate.getMonth() + 1).toString().padStart(2, '0');
            const day = initiationDate.getDate().toString().padStart(2, '0');
            const year = initiationDate.getFullYear().toString();
            const hr = initiationDate.getHours() > 12 ? (initiationDate.getHours() - 12).toString() : initiationDate.getHours().toString();
            const min = initiationDate.getMinutes().toString().padStart(2, '0');
            const sec = initiationDate.getSeconds().toString();
            const ampm = initiationDate.getHours() >= 12 ? 'PM' : 'AM';

            this.expiredDate = `${month}-${day}-${year} ${hr}:${min}:${sec} ${ampm}`;
            const expiredDateObj = new Date(this.expiredDate);

            //isDateExpired
            //if (this.todayDate > expiredDateObj && !this.isEdit) {
            if (!this.isEdit) {
                this.isDateExpired = false;
                // this.isFormCompleted = true;
            } else {
                this.isDateExpired = false;
                this.isFormCompleted = false;
            }

            if (result.data.statusCode == 'INITIATED') {
                this.bName = result.data.beneficiaryDetails.firstName + ' ' + result.data.beneficiaryDetails.lastName;
                this.bEmil = result.data.beneficiaryDetails.benEmail[0].email ? result.data.beneficiaryDetails.benEmail[0].email : "NA";
                return
            }

            if (this.viewEnable && !this.isEdit) {
                this.isFormDisabled = true;
                this.isFormCompleted = true;
            } else {
                this.isFormDisabled = false;
                this.isFormCompleted = false;
            }
            this.getAuthorizationType = result.data.i9WorkAuthPreparerInfo.workAuthorizationType;
            this.formDocumentsOptions = result.data.i9WorkAuthPreparerInfo.workAuthorizationType;

            // List BC START
            // this.beneficiaryData.verificationDetails = result.data.verificationDetails;
            // if(result.data.verificationDetails == undefined || result.data.verificationDetails.length == 0){
            // this.beneficiaryData.verificationDetails = [
            //   {
            //     "createdBy":null,
            //     "modifiedBy":null,
            //     "id":null,
            //     "emplVerificationDoc": null,
            //     "documentTitle":null,
            //     "issuingAuthority":null,
            //     "documentNumber":null,
            //     "countryCode":null,
            //     "expirationDate":null,
            //     "expirationDateForList":null,
            //     "emplVerificationDocCode":"I9IDDOC",
            //     "stateProvince":null,
            //     "refCode":"DRVLIC"
            //     },
            //     {
            //       "createdBy":null,
            //       "modifiedBy":null,
            //       "id":null,
            //       "emplVerificationDoc": null,
            //       "documentTitle":null,
            //       "issuingAuthority":null,
            //       "documentNumber":null,
            //       "countryCode":null,
            //       "expirationDate":null,
            //       "expirationDateForList":null,
            //       "emplVerificationDocCode":"I9EMPLAUTHDOC",
            //       "stateProvince":null,
            //       "refCode":"SSAN"
            // }]
            // }
            // else{
            //   this.listBCurrentSelection  = result.data.verificationDetails[0]?.refCode;
            //   this.listCCurrentSelection  = result.data.verificationDetails[1]?.refCode;
            // }

            this.selectedListGroup = result.data.listType;

            if (result.data.uscisNo != null) {

                this.beneficiaryData.categoryNumber = result.data.uscisNo;
                this.selectedValue = this.categoryList[0].categoryCode;
            }
            if (result.data.formI94No != null) {

                this.beneficiaryData.categoryNumber = result.data.formI94No;
                this.selectedValue = this.categoryList[1].categoryCode;
            }
            if (result.data.alienNumber != null) {

                this.beneficiaryData.categoryNumber = result.data.alienNumber;
                this.selectedValue = this.categoryList[2].categoryCode;
            }
            if (result.data.alienNumber == null && result.data.formI94No == null && result.data.uscisNo == null) {

                this.beneficiaryData.categoryNumber = null;
                this.selectedValue = null;
                this.beneficiaryData.i9WorkAuthPreparerInfo.idNumber = result.data.i9WorkAuthPreparerInfo.idNumber;
                this.beneficiaryData.categoryNumber = result.data.i9WorkAuthPreparerInfo.idNumber;
            }



            if (this.getAuthorizationType == 'CITZN') {
                this.formDocuments[0].checked = true

            }
            else if (this.getAuthorizationType == 'NONCITZNNATIONAL') {
                this.formDocuments[1].checked = true
            }
            else if (this.getAuthorizationType == 'PERMRESIDENT') {
                this.formDocuments[2].checked = true;

            }

            else if (this.getAuthorizationType == 'WORKAUTHALIEN') {
                this.formDocuments[3].checked = true
            }
            else {
                this.formDocuments[0].checked = true
                this.getAuthorizationType = 'CITZN';
            }
            this.bName = result.data.beneficiaryDetails.firstName + ' ' + result.data.beneficiaryDetails.lastName;
            this.bEmil = result.data.beneficiaryDetails.benEmail[0].email ? result.data.beneficiaryDetails.benEmail[0].email : "NA";
            //this.bTilldate = 'NA'; 
            // Initialize Expired date

            // new code------------------------
            this.beneficiaryData.beneficiaryDetails.firstName = result.data.beneficiaryDetails.firstName;
            this.beneficiaryData.beneficiaryDetails.lastName = result.data.beneficiaryDetails.lastName;
            this.beneficiaryData.beneficiaryDetails.middleName = result.data.beneficiaryDetails.middleName;
            this.beneficiaryData.beneficiaryDetails.passport[0].issueCountryCode = result.data.beneficiaryDetails.passport && result.data.beneficiaryDetails.passport[0]?.issueCountryCode ? result.data.beneficiaryDetails.passport[0]?.issueCountryCode : {};
            this.beneficiaryData.i9WorkAuthPreparerInfo.expirationDate = result.data.i9WorkAuthPreparerInfo.expirationDate;
            this.beneficiaryData.beneficiaryDetails.dob = new Date(formatDate(result.data.beneficiaryDetails.dob, 'MM/dd/YYYY', 'en_US'));
            this.beneficiaryData.beneficiaryDetails.ssn = result.data.beneficiaryDetails.ssn || '';
            this.beneficiaryData.beneficiaryDetails.benPhone[0].phoneNo = result.data.beneficiaryDetails.benPhone && result.data.beneficiaryDetails.benPhone[0]?.phoneNo ? result.data.beneficiaryDetails.benPhone[0]?.phoneNo : null
            this.beneficiaryData.beneficiaryDetails.benEmail[0].email = result.data.beneficiaryDetails.benEmail && result.data.beneficiaryDetails.benEmail[0]?.email ? result.data.beneficiaryDetails.benEmail[0]?.email : ''

            if (result.data.beneficiaryDetails.benAddress) {
                for (const address of result.data.beneficiaryDetails.benAddress) {
                    if (address.type.code == "CURR") {
                        this.beneficiaryData.beneficiaryDetails.benAddress[0].addressLine1 = address.addressLine1;

                        this.beneficiaryData.beneficiaryDetails.benAddress[0].addressLine2 = address.addressLine2;

                        this.beneficiaryData.beneficiaryDetails.benAddress[0].stateProvinceName = address.stateProvinceName;

                        this.beneficiaryData.beneficiaryDetails.benAddress[0].city = address.city;

                        this.beneficiaryData.beneficiaryDetails.benAddress[0].locality = address.locality;

                        this.beneficiaryData.beneficiaryDetails.benAddress[0].postCode = address.postCode;
                        this.beneficiaryData.beneficiaryDetails.benAddress[0].id = address.id ? address.id : 0;
                    }
                }
            }

            this.signaturePic = result.data.beneficiaryDetails.benSmallSignImage;
            this.benSignImage = result.data.beneficiaryDetails.benSignImage;
            this.beneficiaryData.beneficiaryDetails.beneficiarySignDate = result?.data?.beneficiaryDetails?.beneficiarySignDate ? moment(result.data.beneficiaryDetails.beneficiarySignDate).format('MM/DD/YYYY') : null;
            this.todayDate = result?.data?.beneficiaryDetails?.beneficiarySignDate ? moment(result.data.beneficiaryDetails.beneficiarySignDate).format('MM/DD/YYYY') : null;
            if (result.data.i9WorkAuthPreparerInfo.workAuthorizationType) {
                this.documentCategory = result.data.i9WorkAuthPreparerInfo.workAuthorizationType;
            }

            if (result.data.i9WorkAuthPreparerInfo.preparerInfo && result.data.i9WorkAuthPreparerInfo.preparerInfo.firstName && result.data.i9WorkAuthPreparerInfo.preparerInfo.lastName) {
                this.isPreparer = true;
            }
            else {
                this.isPreparer = false;
            }

            this.beneficiaryData.i9WorkAuthPreparerInfo.workAuthorizationType = result.data.i9WorkAuthPreparerInfo.workAuthorizationType;
            this.beneficiaryData.i9WorkAuthPreparerInfo.workAuthorizationProof = result.data.i9WorkAuthPreparerInfo.workAuthorizationProof;

            if (result.data.i9WorkAuthPreparerInfo.workAuthorizationProof == 'USPASSPORT' && result.data.i9WorkAuthPreparerInfo.workAuthorizationType == 'CITZN') {
                this.citiSelectedValue = 'US Passport';
                this.fileCategory = 'USPASSPORT';
                this.getAuthorizationType = 'CITZN';
                this.usPassportSelectedValue = 'US Passport';
                this.dtoFlag = "USPASSPORT";
                this.documentCategory = 'CITZN';
                this.workAuthorizationProof = 'USPASSPORT';

            }
            else if (result.data.i9WorkAuthPreparerInfo.workAuthorizationProof == 'USPASSPORTCARD' && result.data.i9WorkAuthPreparerInfo.workAuthorizationType == 'CITZN') {
                this.citiSelectedValue = 'US Passport Card';
                this.workAuthorizationProof = 'USPASSPORTCARD';
                this.getAuthorizationType = 'CITZN';
                this.usPassportSelectedValue = 'US Passport Card';
                this.dtoFlag = "USPASSPORTCARD";
                this.fileCategory = 'USPASSPORTCARD';
                this.documentCategory = 'CITZN';

            }



            if (result.data.i9WorkAuthPreparerInfo.workAuthorizationProof == 'FORGNPASSPORT' && result.data.i9WorkAuthPreparerInfo.workAuthorizationType == 'NONCITZNNATIONAL') {
                this.nonCzSelectedValue = 'Foreign Passport';
                this.selectedForeignPassport = this.nonCzSelectedValue;
                this.fileCategory = 'FORGNPASSPORT';
                this.documentCategory = 'NONCITZNNATIONAL';
                this.getAuthorizationType = 'NONCITZNNATIONAL';
                this.workAuthorizationProof = "FORGNPASSPORT";
                // this.beneficiaryData.i9WorkAuthPreparerInfo.idNumber=result.data.i9WorkAuthPreparerInfo.idNumber;
            }
            else if (result.data.i9WorkAuthPreparerInfo.workAuthorizationProof == 'FORMI766' && result.data.i9WorkAuthPreparerInfo.workAuthorizationType == 'NONCITZNNATIONAL') {
                this.nonCzSelectedValue = 'Form I-766';
                this.selectedForeignPassport = this.nonCzSelectedValue;
                this.fileCategory = 'FORMI766';
                this.documentCategory = 'NONCITZNNATIONAL';
                this.getAuthorizationType = 'NONCITZNNATIONAL';
                this.workAuthorizationProof = "FORMI766";
                //this.beneficiaryData.i9WorkAuthPreparerInfo.idNumber=result.data.i9WorkAuthPreparerInfo.idNumber;
            }

            if (result.data.i9WorkAuthPreparerInfo.workAuthorizationType == 'PERMRESIDENT') {
                this.selectedCountry = result.data.i9WorkAuthPreparerInfo.countryCode;
            }

            if (result.data.i9WorkAuthPreparerInfo.workAuthorizationProof == 'USCISNUM' && result.data.i9WorkAuthPreparerInfo.workAuthorizationType == 'PERMRESIDENT') {
                this.lawfulSelectedValue = 'USCIS Number';
                this.beneficiaryData.categoryNumber = result.data.i9WorkAuthPreparerInfo.idNumber;
                this.selectedlawfulResident == 'USCIS Number'
                this.selectedlawfulResident = this.lawfulSelectedValue;
                this.beneficiaryData.i9WorkAuthPreparerInfo.idNumber = result.data.i9WorkAuthPreparerInfo.idNumber;
                this.fileCategory = 'USCISNUM';
                this.workAuthorizationProof = 'USCISNUM';
                this.getAuthorizationType = 'PERMRESIDENT';
                this.dtoFlag = "USCIS";
                this.documentCategory = 'PERMRESIDENT';
                this.selectedCountry = result.data.i9WorkAuthPreparerInfo.countryCode;
            }
            else if (result.data.i9WorkAuthPreparerInfo.workAuthorizationProof == 'ALIENREGNUM' && result.data.i9WorkAuthPreparerInfo.workAuthorizationType == 'PERMRESIDENT') {

                this.lawfulSelectedValue = 'A Number';
                this.selectedlawfulResident = 'A Number';
                this.beneficiaryData.categoryNumber = result.data.i9WorkAuthPreparerInfo.idNumber;
                this.beneficiaryData.i9WorkAuthPreparerInfo.idNumber = result.data.i9WorkAuthPreparerInfo.idNumber;
                this.fileCategory = 'ALIENREGNUM';
                this.workAuthorizationProof = 'ALIENREGNUM';
                this.getAuthorizationType = 'PERMRESIDENT';
                this.lawfulSelectedValue = 'A Number';
                this.dtoFlag = "ALIENREGNUM";
                this.documentCategory = 'PERMRESIDENT';
                this.selectedCountry = result.data.i9WorkAuthPreparerInfo.countryCode;
            }



            if (this.signaturePic) {
                this.isSignaturePic = true;
            }
            if (result.data.i9WorkAuthPreparerInfo.workAuthorizationProof == 'I94NUM' && result.data.i9WorkAuthPreparerInfo.workAuthorizationType == 'WORKAUTHALIEN') {

                this.selectedValue = 'I94NUM';
                this.i94Num = result.data.i9WorkAuthPreparerInfo.idNumber;
                this.fileCategory = 'I94NUM';
                this.getAuthorizationType = 'WORKAUTHALIEN';
                this.workAuthorizationProof = 'I94NUM';
                this.documentCategory = 'WORKAUTHALIEN';
                this.beneficiaryData.forgnPassportNum94 = result.data.i9WorkAuthPreparerInfo.forgnPassportIdNumber;
                this.selectedCountry = result.data.i9WorkAuthPreparerInfo.countryCode
            }
            else if (result.data.i9WorkAuthPreparerInfo.workAuthorizationProof == 'ALIENREGNUM' && result.data.i9WorkAuthPreparerInfo.workAuthorizationType == 'WORKAUTHALIEN') {

                this.selectedValue = 'ALIENREGNUM';
                this.alienRegNum = result.data.i9WorkAuthPreparerInfo.idNumber;
                this.fileCategory = 'ALIENREGNUM';
                this.getAuthorizationType = 'WORKAUTHALIEN';
                this.workAuthorizationProof = 'ALIENREGNUM';
                this.documentCategory = 'WORKAUTHALIEN';
            }
            else if (result.data.i9WorkAuthPreparerInfo.workAuthorizationProof == 'FORGNPASSPORTNUM' && result.data.i9WorkAuthPreparerInfo.workAuthorizationType == 'WORKAUTHALIEN') {

                this.selectedValue = 'FORGNPASSPORTNUM';
                this.beneficiaryData.forgnPassportNum = result.data.i9WorkAuthPreparerInfo.idNumber;
                this.selectedCountry = result.data.i9WorkAuthPreparerInfo.countryCode;
                this.fileCategory = 'FORGNPASSPORTNUM';
                this.getAuthorizationType = 'WORKAUTHALIEN';
                this.workAuthorizationProof = 'FORGNPASSPORTNUM';
                this.documentCategory = 'WORKAUTHALIEN';

            }
            else if (result.data.i9WorkAuthPreparerInfo.workAuthorizationProof == 'USCISNUM' && result.data.i9WorkAuthPreparerInfo.workAuthorizationType == 'WORKAUTHALIEN') {

                this.selectedValue = 'USCISNUM';
                this.uscisNum = result.data.i9WorkAuthPreparerInfo.idNumber;
                this.fileCategory = 'USCISNUM';
                this.getAuthorizationType = 'WORKAUTHALIEN';
                this.workAuthorizationProof = 'USCISNUM';
                this.documentCategory = 'WORKAUTHALIEN';
            }
            else if (result.data.i9WorkAuthPreparerInfo.workAuthorizationType == 'WORKAUTHALIEN') {

                this.fileCategory = 'ALIENREGNUM';
                this.getAuthorizationType = 'WORKAUTHALIEN';
                this.workAuthorizationProof = 'ALIENREGNUM';
                this.documentCategory = 'WORKAUTHALIEN';
                this.selectedValue = 'ALIENREGNUM';
                if (result.data.beneficiaryDetails.passport[0]?.passportNo && result.data.beneficiaryDetails.passport[0]?.issueCountryCode) {

                    this.selectedValue = 'FORGNPASSPORTNUM';
                    this.selectedCountry = result.data.beneficiaryDetails.passport[0]?.issueCountryCode;
                    this.beneficiaryData.forgnPassportNum = result.data.beneficiaryDetails.passport[0]?.passportNo;
                    this.fileCategory = 'FORGNPASSPORTNUM';
                    this.getAuthorizationType = 'WORKAUTHALIEN';
                    this.workAuthorizationProof = 'FORGNPASSPORTNUM';
                    this.documentCategory = 'WORKAUTHALIEN';

                }

            }
            // new code------------------------


            //result.data[0]
            this.isSec1Completed = result.data.statusCode;
            //this.beneficiaryData = result.data;
            let _data = result.data; //  _data.beneficiaryDetails;
            if (this.isSec1Completed == 'SEC1COMPLTD' && !this.isEdit && !this.isAdminEditing) {
                this.isFormCompleted = true;
                this.agreeCheckboxSelection = result.data?.isAntiDiscrAccpt;
            }

            if (_data.i9WorkAuthPreparerInfo.preparerInfo) {
                this.beneficiaryData.i9WorkAuthPreparerInfo.preparerInfo.prepSmallSignImage = _data.i9WorkAuthPreparerInfo.preparerInfo.prepSmallSignImage;
                this.beneficiaryData.i9WorkAuthPreparerInfo.preparerInfo.prepSignImage = _data.i9WorkAuthPreparerInfo.preparerInfo.prepSignImage;
                this.beneficiaryData.i9WorkAuthPreparerInfo.preparerInfo.preparerSignDate = new Date(formatDate(_data.i9WorkAuthPreparerInfo.preparerInfo.preparerSignDate, 'MM/dd/YYYY', 'en_US'));
                if (_data.i9WorkAuthPreparerInfo.preparerInfo.prepSmallSignImage) {
                    this.isPrepSignaturePic = true;
                    this.prepSignImage = _data.i9WorkAuthPreparerInfo.preparerInfo.prepSignImage;
                    this.prepSmallSignImage = _data.i9WorkAuthPreparerInfo.preparerInfo.prepSmallSignImage;
                }
                this.preparerTodayDate = new Date(formatDate(_data.i9WorkAuthPreparerInfo.preparerInfo.preparerSignDate, 'MM/dd/YYYY', 'en_US'));
                this.beneficiaryData.i9WorkAuthPreparerInfo.preparerInfo.firstName = _data.i9WorkAuthPreparerInfo.preparerInfo.firstName;
                this.beneficiaryData.i9WorkAuthPreparerInfo.preparerInfo.lastName = _data.i9WorkAuthPreparerInfo.preparerInfo.lastName;
                this.beneficiaryData.i9WorkAuthPreparerInfo.preparerInfo.middleName = _data.i9WorkAuthPreparerInfo.preparerInfo.middleName;
                // this.beneficiaryData.i9WorkAuthPreparerInfo.preparerInfo.preparerSignDate = _data.i9WorkAuthPreparerInfo.preparerInfo.preparerSignDate;
                this.beneficiaryData.i9WorkAuthPreparerInfo.preparerInfo.prepAddress[0].addressLine1 = _data.i9WorkAuthPreparerInfo.preparerInfo.prepAddress[0].addressLine1;
                this.beneficiaryData.i9WorkAuthPreparerInfo.preparerInfo.prepAddress[0].addressLine2 = _data.i9WorkAuthPreparerInfo.preparerInfo.prepAddress[0].addressLine2
                this.beneficiaryData.i9WorkAuthPreparerInfo.preparerInfo.prepAddress[0].stateProvinceCode = _data.i9WorkAuthPreparerInfo.preparerInfo.prepAddress[0].stateProvinceCode ? _data.i9WorkAuthPreparerInfo.preparerInfo.prepAddress[0].stateProvinceCode : _data.i9WorkAuthPreparerInfo.preparerInfo.prepAddress[0].stateProvinceName;
                this.beneficiaryData.i9WorkAuthPreparerInfo.preparerInfo.prepAddress[0].city = _data.i9WorkAuthPreparerInfo.preparerInfo.prepAddress[0].city;
                this.beneficiaryData.i9WorkAuthPreparerInfo.preparerInfo.prepAddress[0].locality = _data.i9WorkAuthPreparerInfo.preparerInfo.prepAddress[0].locality;
                this.beneficiaryData.i9WorkAuthPreparerInfo.preparerInfo.prepAddress[0].postCode = _data.i9WorkAuthPreparerInfo.preparerInfo.prepAddress[0].postCode;
            }
        })
        if (this.isEdit) {
            this.isFormCompleted = false;
        }

        if (this.isAdminEditing) {
            this.isFormCompleted = false;
        }
    }

    getStatesList() {
        this.apiService.getStates().pipe().subscribe((result) => {
            this.stateList = result;
            this.stateList.unshift({
                createdBy: "",
                createdDate: "",
                modifiedBy: "",
                sequenceNumber: 0,
                stateProvinceCode: null,
                stateProvinceName: null
            });

        })
    }

    getCountryList() {
        this.apiService.getCountry().pipe().subscribe((result) => {
            this.countryList = result;
        })
    }

    getStateValue(event) {
        this.beneficiaryData.beneficiaryDetails.benAddress[0].stateProvinceCode = event.value;
        this.stateProvinceCodePBeneficiary = event.value;
    }

    preparerStateOnChange(event) {
        this.stateProvinceCodePreparer = event.value;
    }
    countryOnChange(event) {
        this.selectedCountry = event.value;
    }


    onChangeForeignPassport(event) {
        this.selectedForeignPassport = event.value;

        this.foreignDocumentType = event.value;
        if (event.value == 'Form I-766') {
            this.fileCategory = 'FORMI766';
            this.documentCategory = 'NONCITZNNATIONAL';
            this.getAuthorizationType = 'NONCITZNNATIONAL';
            this.workAuthorizationProof = "FORMI766";
        }

        if (event.value == 'Foreign Passport') {
            this.fileCategory = 'FORGNPASSPORT';
            this.documentCategory = 'NONCITZNNATIONAL';
            this.getAuthorizationType = 'NONCITZNNATIONAL';
            this.workAuthorizationProof = "FORGNPASSPORT";
        }
        if (event.value) {
            this.isExpireForeignPassport = true;
            this.beneficiaryData.i9WorkAuthPreparerInfo.expirationDate = null;
        }
        else {
            this.isExpireForeignPassport = false;
        }
    }

    lawfulResidentOptionChange(event) {
        this.beneficiaryData.categoryNumber=null;
        this.selectedlawfulResident = event.value;
        this.foreignDocumentType = event.value;
        var x = this.selectedValue;
        if (event.value == 'A Number') {
            this.selectedValue = 'ALIENREGNUM';
            this.workAuthorizationProof = 'ALIENREGNUM';
            this.getAuthorizationType = 'PERMRESIDENT';
            this.lawfulSelectedValue = 'A Number';
            this.dtoFlag = "ALIENREGNUM";
            this.fileCategory = 'ALIENREGNUM';
            this.documentCategory = 'PERMRESIDENT';
        }
        if (event.value == 'USCIS Number') {
            this.selectedValue = 'USCISNUM';
            this.workAuthorizationProof = 'USCISNUM';
            this.getAuthorizationType = 'PERMRESIDENT';
            this.lawfulSelectedValue = 'USCIS Number';
            this.dtoFlag = "USCIS";
            this.fileCategory = 'USCISNUM';
            this.documentCategory = 'PERMRESIDENT';
        }
        if (event.value) {
            this.isExpirelawfulResident = true;
            this.beneficiaryData.i9WorkAuthPreparerInfo.expirationDate = null;
        }
        else {
            this.isExpirelawfulResident = false;
        }
    }
    usPassportOptionsChange(event) {
        //    '',''
        this.usPassportSelectedValue = event.value;
        this.foreignDocumentType = event.value;
        if (event.value == 'US Passport') {
            this.workAuthorizationProof = 'USPASSPORT';
            this.getAuthorizationType = 'CITZN';
            this.usPassportSelectedValue = 'US Passport';
            this.dtoFlag = "USPASSPORT";
            this.fileCategory = 'USPASSPORT';
            this.documentCategory = 'CITZN';
        }
        if (event.value == 'US Passport Card') {
            this.workAuthorizationProof = 'USPASSPORTCARD';
            this.getAuthorizationType = 'CITZN';
            this.usPassportSelectedValue = 'US Passport Card';
            this.dtoFlag = "USPASSPORTCARD";
            this.fileCategory = 'USPASSPORTCARD';
            this.documentCategory = 'CITZN';
        }
        if (event.value) {
            this.isExpireUsPassort = true;
            this.beneficiaryData.i9WorkAuthPreparerInfo.expirationDate = null;
        }
        else {
            this.isExpireUsPassort = false;
        }
    }
    alianExpiratioOptionChange(event) {
        this.selectedAlianModel = event.value;
        this.foreignDocumentType = event.value;
        if (event.value == 'Alien Registration Number') {
            this.workAuthorizationProof = 'ALIENREGNUM';
            this.getAuthorizationType = 'ALIENREGNUM';

            this.dtoFlag = "ALIAN";
        }
        if (event.value == 'Foreign Passport Number') {
            this.workAuthorizationProof = 'FORGNPASSPORTNUM';
            this.getAuthorizationType = 'ALIENREGNUM';
            this.dtoFlag = "FORGNPASSPORT";
        }
        if (event.value == 'Form I-94 Admission Number') {
            this.workAuthorizationProof = 'I94NUM';
            this.getAuthorizationType = 'ALIENREGNUM';
            this.dtoFlag = "I94";
        }
        if (event.value == 'USCIS Number') {
            this.workAuthorizationProof = 'USCISNUM';
            this.getAuthorizationType = 'ALIENREGNUM';
            this.dtoFlag = "USCIS";
        }

    }

    // TRANSLATOR
    translatorRadio(value) {
        if (value == 'Preparer') {
            this.isPreparer = true;
        }
        if (value == 'Translator') {
            this.isPreparer = false;
        }
    }


    // UPLOAD BENEFICIARY SIGN
    uploadBeneficiarySignature(_data: any) {
        let files = _data[0];
        this.selectedFile = files;
        const formData = new FormData();
        formData.append('file', this.selectedFile);
        var abc = this.beneficiaryData.beneficiaryDetails.beneficiarySignDate
            ;
        this.apiService.uploadI9BeneficiarySignature(this.beneficiaryId, formData)
            .pipe(takeUntil(this.observableSubscription$))
            .subscribe((response: ImagilityBaseResponse) => {
                if (response.status == 200) {
                    var currDate = new Date()
                    this.todayDate = moment(currDate).format('YYYY-MM-DD');
                    ;
                    this.beneficiaryData.beneficiaryDetails.beneficiarySignDate = this.todayDate;
                    this.beneficiaryData.beneficiaryDetails.benSignImage = response.data.signImage
                    this.toastr.success(response.message, "Success");
                    this.getBeneficiarySignature();
                }
            })
    }
    getDigiSign(data: any) {
        this.getBeneficiarySignature();
    }
    isSignaturePic = false;
    prepConvertedImage: any;
    getBeneficiarySignature() {
        this.apiService.getI9BeneficiarySignature(this.beneficiaryId)
            .pipe()
            .subscribe((response: ImagilityBaseResponse) => {
                if (response.status == 200) {
                    this.isSignaturePic = true;
                    this.signaturePic = response.data.signImageSmall;
                }
            })
        return false;
    }
    toBase64(file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            this.prepSmallSignImage = reader.result.toString().split(',')[1];
        };
    }
    uploadSignatureTranlator(_data: any) {
        let files = _data[0];
        this.selectedFile = files;
        const formData = new FormData();
        formData.append('file', this.selectedFile);
        var currDate = new Date()
        this.preparerTodayDate = moment(currDate).format('MM/DD/YYYY');
        this.toBase64(this.selectedFile);
        this.beneficiaryData.i9WorkAuthPreparerInfo.preparerInfo.preparerSignDate = this.preparerTodayDate;
        this.beneficiaryData.i9WorkAuthPreparerInfo.preparerInfo.prepSmallSignImage = this.prepSmallSignImage;
        this.beneficiaryData.i9WorkAuthPreparerInfo.preparerInfo.prepSignImage = this.prepSmallSignImage;
        this.isPrepSignaturePic = true;
        this.prepSignImage = this.selectedFile;
        // }
    }
    prepSignImage = '';
    prepSmallSignImage = '';
    isPrepSignaturePic = false;
    base64Image: any;

    getPrepSignature() {
        this.apiService.getPreparerSignature(this.companyId, this.i9FormId)
            .pipe()
            .subscribe((response: ImagilityBaseResponse) => {
                if (response.status == 200) {
                    this.isPrepSignaturePic = true;
                    this.prepSmallSignImage = response.data.signImageSmall;
                    this.prepSignImage = response.data.signImage;
                }
            })
    }


    // DOCUMENt UPLOAD
    uploadBeneficiaryDocument(event: any) {

        this.selectedFile = event.target.files;
        const formData = new FormData();

        for (var i = 0; i < this.selectedFile.length; i++) {
            let formData = new FormData();
            formData.append('file', this.selectedFile[i]);
            this.apiService.getI9BeneficiaryDocument(this.i9FormId)
                .pipe()
                .subscribe((response: ImagilityBaseResponse) => {
                    this.workAuthorizationProof = this.fileCategory;
                    this.apiService.uploadI9BeneficiaryDocument(this.companyId, this.i9FormId, this.documentCategory, this.fileCategory, formData)
                        .pipe(takeUntil(this.observableSubscription$))
                        .subscribe((response: ImagilityBaseResponse) => {
                            if (response.status == 200) {
                                this.toastr.success(response.message, "Success");
                                this.getBeneficiaryDocument();
                            }
                        })
                })
        }
    }


    getBeneficiaryDocument() {

        this.apiService.getI9BeneficiaryDocument(this.i9FormId)
            .pipe()
            .subscribe((response: ImagilityBaseResponse) => {
                if (response && response.data) {

                    this.documentArray = response.data;
                    this.cRef.detectChanges();
                    console.log('documentArray', this.documentArray);
                    if (this.getAuthorizationType == 'CITZN' && response.data && response.data.fileCategory && response.data.fileCategory.code == 'USPASSPORT' || response.data.fileCategory.code == 'USPASSPORTCARD') {
                        this.beneficiaryDocumentData.I9document.us_Passport.fileName = response.data.fileName + "." + response.data.fileType;
                        this.beneficiaryDocumentData.I9document.us_Passport.url = response.data.fileLocation;
                        this.beneficiaryDocumentData.I9document.us_Passport.id = response.data.id;


                        if (response.data.fileCategory.code == 'USPASSPORT') {
                            this.selectedForeignPassport = 'US Passport';
                            this.foreignDocumentType = 'US Passport';
                        }
                        if (response.data.fileCategory.code == 'USPASSPORTCARD') {
                            this.selectedForeignPassport = 'US Passport Card';
                            this.foreignDocumentType = 'US Passport Card';
                        }
                    }
                    else if (this.getAuthorizationType == 'NONCITZNNATIONAL' && response.data && response.data.fileCategory && response.data.fileCategory.code == 'FORGNPASSPORT' || response.data && response.data.fileCategory && response.data.fileCategory.code == 'FORMI766') {
                        this.beneficiaryDocumentData.I9document.nonUs_Passport.fileName = response.data.fileName + "." + response.data.fileType;
                        this.beneficiaryDocumentData.I9document.nonUs_Passport.url = response.data.fileLocation;
                        this.beneficiaryDocumentData.I9document.nonUs_Passport.id = response.data.id;


                        if (response.data.fileCategory.code == 'FORGNPASSPORT') {
                            this.selectedForeignPassport = 'Foreign Passport';
                            this.foreignDocumentType = 'PASSPORT';
                        }
                        if (response.data.fileCategory.code == 'FORMI766') {
                            this.selectedForeignPassport = 'Form I-766';
                            this.foreignDocumentType = 'FORM-I 766';
                        }
                    }

                    else if (this.getAuthorizationType == 'PERMRESIDENT') {

                        this.beneficiaryDocumentData.I9document.lawful_document.fileName = response.data.fileName + "." + response.data.fileType;
                        this.beneficiaryDocumentData.I9document.lawful_document.url = response.data.fileLocation;
                        this.beneficiaryDocumentData.I9document.lawful_document.id = response.data.id;

                        //selectedlawfulResident
                        if (response.data.fileCategory.code == 'USCISNUM') {
                            this.selectedlawfulResident = 'USCIS Number';
                            this.foreignDocumentType = 'USCIS Number';
                        }
                        if (response.data.fileCategory.code == 'ALIENREGNUM') {
                            this.selectedlawfulResident = 'A Number';
                            this.foreignDocumentType = 'Registration Number';
                        }
                    }
                    else if (this.getAuthorizationType == 'WORKAUTHALIEN' && (response.data && response.data.fileCategory && response.data.fileCategory.code == 'USCISNUM'
                        || response.data && response.data.fileCategory && response.data.fileCategory.code == 'ALIENREGNUM'
                        || response.data && response.data.fileCategory && response.data.fileCategory.code == 'I94NUM'
                        || response.data && response.data.fileCategory && response.data.fileCategory.code == 'FORGNPASSPORTNUM')) {
                        this.beneficiaryDocumentData.I9document.alien_document.fileName = response.data.fileName + "." + response.data.fileType;
                        this.beneficiaryDocumentData.I9document.alien_document.url = response.data.fileLocation;
                        this.beneficiaryDocumentData.I9document.alien_document.id = response.data.id;

                        //selectedlawfulResident
                        if (response.data.fileCategory.code == 'USCISNUM') {

                            // this.selectedValue = 'USCISNUM';
                            this.foreignDocumentType = 'USCISNUM';
                        }
                        if (response.data.fileCategory.code == 'ALIENREGNUM') {

                            // this.selectedValue = 'ALIENREGNUM';
                            this.foreignDocumentType = 'ALIENREGNUM';
                        }
                        if (response.data.fileCategory.code == 'I94NUM') {

                            // this.selectedValue = 'I94NUM';
                            this.foreignDocumentType = 'I94NUM';
                        }
                        if (response.data.fileCategory.code == 'FORGNPASSPORTNUM') {

                            // this.selectedValue = 'FORGNPASSPORTNUM';
                            this.foreignDocumentType = 'FORGNPASSPORTNUM';
                        }
                    }
                    else {
                        this.beneficiaryDocumentData.I9document.nonUs_Passport.fileName = '';
                        this.beneficiaryDocumentData.I9document.nonUs_Passport.url = '';
                        this.beneficiaryDocumentData.I9document.nonUs_Passport.id = '';
                        this.beneficiaryDocumentData.I9document.us_Passport.fileName = '';
                        this.beneficiaryDocumentData.I9document.us_Passport.url = '';
                        this.beneficiaryDocumentData.I9document.us_Passport.id = '';
                        this.beneficiaryDocumentData.I9document.alien_document.fileName = '';
                        this.beneficiaryDocumentData.I9document.alien_document.url = '';
                        this.beneficiaryDocumentData.I9document.alien_document.id = '';
                        this.beneficiaryDocumentData.I9document.lawful_document.fileName = '';
                        this.beneficiaryDocumentData.I9document.lawful_document.url = '';
                        this.beneficiaryDocumentData.I9document.lawful_document.id = '';
                    }
                }
            })
    }

    viewDocument(item) {
        const docPath = item.substring(23);
        window.open(`${environment.docs}${docPath}`, '_blank');
    }

    deleteDocument(docId, docType) {
        this.apiService.deleteI9BeneficiaryDocument(this.i9FormId, docId)
            .pipe()
            .subscribe((response: ImagilityBaseResponse) => {
                this.getBeneficiaryDocument();
                this.selectedFile = null;
                this.toastr.success(response.message, "Success");
                if (docType == 'USPASSPORT') {
                    this.beneficiaryDocumentData.I9document.us_Passport.fileName = '';
                    this.beneficiaryDocumentData.I9document.us_Passport.url = '';
                    this.beneficiaryDocumentData.I9document.us_Passport.id = '';
                }
                else if (docType == 'FORGNPASSPORT') {
                    this.beneficiaryDocumentData.I9document.nonUs_Passport.fileName = '';
                    this.beneficiaryDocumentData.I9document.nonUs_Passport.url = '';
                    this.beneficiaryDocumentData.I9document.nonUs_Passport.id = '';
                }
                else if (docType == 'LAWFUL') {
                    this.beneficiaryDocumentData.I9document.lawful_document.fileName = '';
                    this.beneficiaryDocumentData.I9document.lawful_document.url = '';
                    this.beneficiaryDocumentData.I9document.lawful_document.id = '';
                    this.foreignDocumentType = '';
                }
                else if (docType == 'I94NUM') {
                    this.beneficiaryDocumentData.I9document.alien_document.fileName = '';
                    this.beneficiaryDocumentData.I9document.alien_document.url = '';
                    this.beneficiaryDocumentData.I9document.alien_document.id = '';
                    this.foreignDocumentType = '';
                }
            })
    }

    _setemail = '';

    // FORM SUBMIT
    beneficiaryForm(form: NgForm) {
        if (!form.valid) {
            return;
        }



        this.beneficiaryData.i9WorkAuthPreparerInfo.workAuthorizationType = this.getAuthorizationType;
        this.beneficiaryData.i9WorkAuthPreparerInfo.workAuthorizationProof = this.workAuthorizationProof ? this.workAuthorizationProof : null;

        this.beneficiaryData.flagUSIC = this.dtoFlag;
        this.beneficiaryData.beneficiaryDetails.benSmallSignImage = this.signaturePic;
        this.beneficiaryData.beneficiaryDetails.benSignImage = this.benSignImage;
        var preparerCurrDate = moment(this.preparerTodayDate).format('YYYY-MM-DD');
        if (this.beneficiaryData?.i9WorkAuthPreparerInfo?.preparerInfo) {
            this.beneficiaryData.i9WorkAuthPreparerInfo.preparerInfo.preparerSignDate = preparerCurrDate;
        }
        this.beneficiaryData.isAntiDiscrAccpt = this.agreeCheckboxSelection;
        var benCurrSignDate = this.beneficiaryData.beneficiaryDetails.beneficiarySignDate ? moment(this.beneficiaryData.beneficiaryDetails.beneficiarySignDate).format('YYYY-MM-DD') : null;
        this.beneficiaryData.beneficiaryDetails.beneficiarySignDate = benCurrSignDate;
        this.beneficiaryData.beneficiaryDetails.dob = moment(this.beneficiaryData.beneficiaryDetails.dob).format('YYYY-MM-DD');

        this.beneficiaryData.listType = this.selectedListGroup;

        if (this.selectedValue == 'I94NUM') {
            this.beneficiaryData.i9WorkAuthPreparerInfo.idNumber = this.beneficiaryData.categoryNumber;
            this.beneficiaryData.i9WorkAuthPreparerInfo.forgnPassportIdNumber = this.beneficiaryData.forgnPassportNum94;
            this.beneficiaryData.i9WorkAuthPreparerInfo.countryCode = this.selectedCountry;
        }

        else if (this.selectedValue == 'ALIENREGNUM') {
            this.beneficiaryData.i9WorkAuthPreparerInfo.idNumber = this.beneficiaryData.categoryNumber;

            if (this.selectedlawfulResident == 'A Number') {
                this.beneficiaryData.i9WorkAuthPreparerInfo.countryCode = this.selectedLawfulCountry;
            }
        }

        else if (this.selectedValue == 'FORGNPASSPORTNUM') {

            this.beneficiaryData.i9WorkAuthPreparerInfo.countryCode = this.selectedCountry;
            this.beneficiaryData.i9WorkAuthPreparerInfo.idNumber = this.beneficiaryData.forgnPassportNum;
        }

        else if (this.selectedValue == 'USCISNUM') {

            if (this.selectedlawfulResident == 'USCIS Number') {
                this.beneficiaryData.i9WorkAuthPreparerInfo.countryCode = this.selectedLawfulCountry;
            }
            this.beneficiaryData.i9WorkAuthPreparerInfo.idNumber = this.beneficiaryData.categoryNumber;
        }

        if (this.documentCategory == 'PERMRESIDENT') {

            this.beneficiaryData.i9WorkAuthPreparerInfo.countryCode = this.selectedCountry;

            if (this.selectedlawfulResident == 'USCIS Number' || this.selectedlawfulResident == 'A Number') {
                this.beneficiaryData.i9WorkAuthPreparerInfo.countryCode = this.selectedLawfulCountry;
            }
        }

        // var resultssss = this.beneficiaryData;
        // 
        // }



        if (!this.isPreparer) {
            this.beneficiaryData.i9WorkAuthPreparerInfo.preparerInfo = null;
        }

        this.apiService.beneficiaryFormFilling(this.companyId, this.i9FormId, this.beneficiaryData).pipe().subscribe((result: any) => {
            this.toastr.success(result.message);
            if (result.status == 200) {
                if (!this.isAdminEditing) {
                    this.isLockedAction = true;
                }


                let payload = { i9FormId: Number(this.i9FormId) }

                this.apiService.sendConfirmationEmailToAdmin(payload, this.i9FormId).pipe().subscribe((result: any) => {
                    this.toastr.success(result.message);
                })


            }

            if (!this.isFormCompleted && this.isPreparer) {
                const formData = new FormData();
                formData.append('file', this.prepSignImage);
                this.apiService.uploadI9PreparerSignature(this.companyId, this.i9FormId, formData).pipe().subscribe((result: any) => {
                    this.getIsSubmitted();
                });
            } else {
                this.getIsSubmitted();
            }

        })
    }



    alienAuthWorkClick(event) {

        if (this.selectedValue == 'I94NUM') {
            this.beneficiaryData.categoryNumber = null;
            this.documentCategory = 'WORKAUTHALIEN';
            this.getAuthorizationType = 'WORKAUTHALIEN';
            this.workAuthorizationProof = 'I94NUM';
            this.alienRegNum = '';
            this.beneficiaryData.forgnPassportNum = '';
            this.uscisNum = '';
            this.selectedCountry = '';
            this.fileCategory = 'I94NUM';

        }
        else if (this.selectedValue == 'ALIENREGNUM') {
            this.beneficiaryData.categoryNumber = null;
            this.documentCategory = 'WORKAUTHALIEN';
            this.getAuthorizationType = 'WORKAUTHALIEN';
            this.workAuthorizationProof = 'ALIENREGNUM';
            this.i94Num = '';
            this.beneficiaryData.forgnPassportNum = '';
            this.uscisNum = '';
            this.selectedCountry = '';
            this.fileCategory = 'ALIENREGNUM';
            this.beneficiaryData.forgnPassportNum94 = null;
        }
        else if (this.selectedValue == 'FORGNPASSPORTNUM') {
            this.documentCategory = 'WORKAUTHALIEN';
            this.getAuthorizationType = 'WORKAUTHALIEN';
            this.workAuthorizationProof = 'FORGNPASSPORTNUM';
            this.i94Num = '';
            this.alienRegNum = '';
            this.uscisNum = '';
            this.fileCategory = 'FORGNPASSPORTNUM';
            this.beneficiaryData.forgnPassportNum94 = null;
        }
        else if (this.selectedValue == 'USCISNUM') {
            this.beneficiaryData.categoryNumber = null;
            this.documentCategory = 'WORKAUTHALIEN';
            this.getAuthorizationType = 'WORKAUTHALIEN';
            this.workAuthorizationProof = 'USCISNUM';
            this.i94Num = '';
            this.alienRegNum = '';
            this.beneficiaryData.forgnPassportNum = '';
            this.selectedCountry = '';
            this.fileCategory = 'USCISNUM';
            this.beneficiaryData.forgnPassportNum94 = null;
        }

    }
    isApplicable(event) {
        if (event.checked) {
            this.notApplicable = true;
            this.beneficiaryData.i9WorkAuthPreparerInfo.expirationDate = null;
        }
        else {
            this.notApplicable = false;
        }
    }

    getAntiDiscriminatoryData() {
        this.apiService.getI9DiscrimintaionNotice().subscribe(data => {
            this.antiDiscriminatoryData = data['content'] || '';
        });
    }

    showNotice() {
        this.displayNotice = true;
    }

    // categoryChanged(event){
    //   this.currentCategoryFlag = event.value;
    //   this.isselectedCategory = true;
    // }

    categoryOnChangeInput(event) {
        var value = event.target.value;
        // this.iscategoryNumber = true;



        if (this.selectedValue == 'USCISNUM') {

            this.beneficiaryData.uscisNo = value;
            this.beneficiaryData.formI94No = null;
            this.beneficiaryData.alienNumber = null;
        }
        else if (this.selectedValue == 'I94NUM') {

            this.beneficiaryData.formI94No = value;
            this.beneficiaryData.alienNumber = null;
            this.beneficiaryData.uscisNo = null;
        }
        else if (this.selectedValue == 'ALIENREGNUM') {

            this.beneficiaryData.alienNumber = value;
            this.beneficiaryData.formI94No = null;
            this.beneficiaryData.uscisNo = null;
        }
        else if (this.selectedValue == 'FORGNPASSPORTNUM') {

            this.beneficiaryData.alienNumber = null;
            this.beneficiaryData.formI94No = null;
            this.beneficiaryData.uscisNo = null;
            this.beneficiaryData.i9WorkAuthPreparerInfo.idNumber = value;
        }
    }


    listSelected(value) {


        //this.beneficiaryData.forgnPassportNum = null;
        //this.selectedValue = null;
        //this.selectedCountry = null;
      //  this.beneficiaryData.categoryNumber = null;
        if (value == 'A') {
            this.beneficiaryData.i9WorkAuthPreparerInfo.workAuthorizationType = 'CITZN'
            this.documentCategory = 'CITZN';
            this.selectedListGroup = 'A';
            // this.beneficiaryData.verificationDetails = [
            //   {
            //     "createdBy":null,
            //     "modifiedBy":null,
            //     "id":null,
            //     "emplVerificationDoc": null,
            //     "documentTitle":null,
            //     "issuingAuthority":null,
            //     "documentNumber":null,
            //     "countryCode":null,
            //     "expirationDate":null,
            //     "expirationDateForList":null,
            //     "emplVerificationDocCode":"I9IDDOC",
            //     "stateProvince":null,
            //     "refCode":"DRVLIC"
            //     },
            //     {
            //       "createdBy":null,
            //       "modifiedBy":null,
            //       "id":null,
            //       "emplVerificationDoc": null,
            //       "documentTitle":null,
            //       "issuingAuthority":null,
            //       "documentNumber":null,
            //       "countryCode":null,
            //       "expirationDate":null,
            //       "expirationDateForList":null,
            //       "emplVerificationDocCode":"I9EMPLAUTHDOC",
            //       "stateProvince":null,
            //       "refCode":"SSAN"
            // }];
        }
        else {
            this.documentCategory = '';
            this.selectedListGroup = 'BC';
            this.beneficiaryData.i9WorkAuthPreparerInfo.workAuthorizationType = null;
            this.beneficiaryData.i9WorkAuthPreparerInfo.workAuthorizationType = null;
            this.beneficiaryData.i9WorkAuthPreparerInfo.workAuthorizationProof = null;
            this.beneficiaryData.i9WorkAuthPreparerInfo.isExpirationDtNa = null;
            //this.beneficiaryData.i9WorkAuthPreparerInfo.expirationDate = null;
            this.beneficiaryData.i9WorkAuthPreparerInfo.idNumber = null;
            this.beneficiaryData.i9WorkAuthPreparerInfo.countryCode = null;
            this.beneficiaryData.i9WorkAuthPreparerInfo.newVal = null;
            this.beneficiaryData.i9WorkAuthPreparerInfo.newCountryVal = null;
            this.beneficiaryData.flagUSIC = null;
            this.beneficiaryData.uscisNo = null;
            this.beneficiaryData.formI94No = null;
            this.beneficiaryData.alienNumber = null;
        }
    }


    dropdownBlisting(event) {
        // this.beneficiaryData.verificationDetails[0].refCode = event.value;
    }
    dropdownClisting(event) {
        // this.beneficiaryData.verificationDetails[1].refCode = event.value;
    }

    // LIST BC START

    uploadForm(_data: any, i) {
        let emplVerificationDocCode = '';
        let refCode = '';

        if (i == 0) {
            emplVerificationDocCode = 'I9IDDOC'
            // refCode = this.beneficiaryData.verificationDetails[0].refCode;
        }
        else {
            emplVerificationDocCode = 'I9EMPLAUTHDOC';
            // refCode = this.beneficiaryData.verificationDetails[1].refCode;
        }

        let files = _data[0];

        if (files.type !== 'application/pdf') {
            this.toastr.error("You have selected wrong document", "Please upload PDF file")
        }
        else {
            this.selectedFile = files;
            const formData = new FormData();
            formData.append('file', this.selectedFile);

            this.apiService.uploadListBCDcoument(this.companyId, this.i9FormId, emplVerificationDocCode, refCode, formData).pipe(takeUntil(this.observableSubscription$))
                .subscribe((response: ImagilityBaseResponse) => {
                    if (response.status == 200) {
                        this.toastr.success(response.message, "Success");
                    }
                })
        }
    }


    deleteDocumentBC(docId) {
        this.apiService.deleteBcDocument(this.i9FormId, docId).pipe(takeUntil(this.observableSubscription$))
            .subscribe((response: ImagilityBaseResponse) => {
                if (response.status == 200) {
                    this.toastr.success(response.message, "Success");
                    this.getIsSubmitted();
                }
            })
    }


    viewDocumentBC(item) {
        const docPath = item.substring(23);
        window.open(`${environment.docs}${docPath}`, '_blank');
    }

    // LIST BC END


    formDocumentsDropdown(event) {
        ;
        this.getAuthorizationType = event.value;
        if (event.value == 'PERMRESIDENT') {
            this.workAuthorizationProof = 'USCISNUM';
        }
        this.beneficiaryData.forgnPassportNum = null;
        this.selectedValue = null;
        this.selectedCountry = null;
        this.beneficiaryData.categoryNumber = null;
    }

    selectForeignPassport(event) {
        this.beneficiaryData.i9WorkAuthPreparerInfo.idNumber = event;
    }

    uscisNumberChange(event) {
        this.beneficiaryData.i9WorkAuthPreparerInfo.idNumber = event;
    }

    getDocumentsEvaluation() {
        this.apiService.getI9BeneficiaryDocument(this.i9FormId).pipe().subscribe((res: any) => {
            if (res['data'] && res['data'].length > 0) {
                const data = res['data'];
                if (data.fileCategory?.code == 'EVALREPORT' && data.i9FormDetails?.status?.code == 'SEC1VERFD') {
                    this.isEvalReport = true;
                    this.fileName = data.fileName + "." + data.fileType;
                    this.url = data.fileLocation;
                    this.id = data.id;
                    this.type = data.fileCategory.code;
                    this.statusEval = data.i9FormDetails.status.code;
                }
                else {
                    this.isEvalReport = false;
                }
            }
        })
    }

    viewEvalDocument(item) {
        const docPath = item.substring(23);
        window.open(`${environment.docs}${docPath}`, '_blank');
    }

    viewImageDocument() {
        const imageURL = '/application/list.png';
        let url = environment.appUrl;
        let isProd = url;
        window.open(`${isProd}${imageURL}`, '_blank');
    }

    goBack() {
        window.history.back();
    }

    onEnterPress(event: KeyboardEvent) {
        event.preventDefault(); // Prevent form submission on Enter
    }

}