<div>
  <!-- <div class="col-md-6 mt-4">
  </div> -->
  <div class="row">
    <div class="col-md-9 pl-0 hd-clr-ovrde">
      <!-- <h2 class="mt-2">Beneficiary Details</h2> -->
    <h5 class="mt-4 mb-3 heading">Private access files for each beneficiary.</h5>

    </div>
    <div class="col-md-3 pr-0">
      <div class="mb-3 input-group mt-3 search-input-group">
        <input class="form-control py-2 rounded-pill" #searchAccessListTable 
        (search)="resetSearch()"  type="search" placeholder="Search Beneficiary / Company">
        <span class="input-group-append">
            <button class="btn rounded-pill border-0 ml-n4r" type="button">
                <i class="fa fa-search"></i>
            </button>
        </span>
      </div>
    </div>
  </div>

  <div class="">
    <table class="im-card-no-border-table im-card-table-noborder-spacing table table-striped-odd" mat-table [dataSource]="dataSource" multiTemplateDataRows>
      <ng-container  matColumnDef="{{ column.columnDef }}"  *ngFor="let column of columns" >
        <th mat-header-cell *matHeaderCellDef class="aqua">
          {{ column.header }}
        </th>
        <td mat-cell *matCellDef="let element">
          <span *ngIf="column.columnDef == 'toggle'" class="toggle-col">
            <mat-icon class="mr-1 icn">expand_more</mat-icon>
          </span>
          <span *ngIf="column.columnDef != 'toggle'">
            {{ element[column.columnDef] }}
          </span>
        </td>
      </ng-container>

      <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" class="inner-table-with-nopadding" [attr.colspan]="columns.length">
          <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
            <div class="row">
              <div class="col-md-12 ml-2">
                
                <select class="form-control float-left doc-sec" [(ngModel)]="fileCategory" placeholder="Select Document Type"
                  name="documentType" #documentType="ngModel" (ngModelChange)="handleFileCategorySelection()">
                  <option value="" [selected]="fileCategory === ''">Select Document Type</option>
                  <option *ngFor="let option of docType.documents" [value]="option.fileCat">{{option.name}}</option>
                </select>
                <div class="upload-btn-wrapper"  style="cursor:pointer">
                  <button type="button" [disabled]="!isFileCategory" class="btn btn-primary mb-2 float-left ml-2">Upload Document</button>
                  <input #uploadDoc type="file" accept="application/pdf" (change)="uploadDocument($event.target.files)" />
                </div>
              </div>
            </div>
            <div class="row documents-margin">
              <div class="col-md-12 pl-0">
                <div class="doc-container" *ngFor="let item of element.documents; let i = index">
              <button
                (click)="displayDocument(item.fileLocation)"
                class="btn btn-primary mb-2 mt-2 float-left btn-xs">
                {{ item?.fileCategory?.description }}
              </button>
              <i class="fa fa-close delete-doc" (click)="deleteDoc(item)"></i>
            </div>
            </div>
          </div>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let element; columns: displayedColumns"
        class="example-element-row"
        [class.example-expanded-row]="expandedElement === element"
        (click)="expandedElement = expandedElement === element ? null : element;getRecord(element, expandedElement)"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: ['expandedDetail']"
        class="example-detail-row"
      ></tr>
    </table>
    <mat-paginator [pageSizeOptions]="pageSizeOptions"
    [pageSize]="pageSize"
    [length]="totalRecordCount"
    (page)="paginationEvent($event)"
    ></mat-paginator>
  </div>
</div>
