import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ImagilityBaseResponse } from 'app-models';
import { map } from 'rxjs/internal/operators/map';
import { EncryptService } from 'src/app/modules/core/http/http-encrypt.service';
import * as CryptoJS from 'crypto-js';
@Injectable({ providedIn: 'root' })
export class EmploymentHistoryService {
  encriptedData:any;
  constructor(
    private http: HttpClient, private encryptService: EncryptService
  ) { }

  syncBeneWorkExperienceAll(visatype: string, caseType: string, caseId: number, synctype: string, superTaskId: number, subTaskId: number, familyId?) {
    const url = familyId ? `/${visatype}/${caseType}/${caseId}/${synctype}/sync?superTaskId=${superTaskId}&taskId=${subTaskId}&familyId=${familyId}`: `/${visatype}/${caseType}/${caseId}/${synctype}/sync?superTaskId=${superTaskId}&taskId=${subTaskId}`;
    return this.http.post(url, {}).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }

  deletBeneWorkExperience(visaType: string, caseType: string, caseId: number, type: string, superTaskId: number, subTaskId: number, experienceId: number) {
    return this.http.delete(`/${visaType}/${caseType}/${caseId}/EXPERIENCE/${experienceId}?superTaskId=${superTaskId}&taskId=${subTaskId}`, {}).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }

  deletBeneWorkExperienceTools(superTaskId, subTaskId, toolId) {
    return this.http.delete(`/superTask/${superTaskId}/subTask/${subTaskId}/gc/i140Case/workExperienceDetails/tools/${toolId}`, {}).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }

  deletBeneWorkExperienceDuties(visaType: string, caseType: string, caseId: number, type: string, superTaskId: number, subTaskId: number, experienceId: number, dutyId) {
    return this.http.delete(`/${visaType}/${caseType}/${caseId}/EXPERIENCE/${experienceId}/jobduty/${dutyId}?superTaskId=${superTaskId}&taskId=${subTaskId}`, {}).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }

  deleteBeneWorkExperienceDocuments(visaType: string, caseType: string, caseId: number, documentId: number) {
    return this.http.delete(`/${visaType}/${caseType}/${caseId}/document/${documentId}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response && response.status === 200) {
          return response;
        }
      })
    );
  }

  getBeneExperienceInformation(
    visatype: string, caseType: string, caseId: number, synctype: string, superTaskId: number, subTaskId: number, familyId?) {
    const url = familyId ? `/${visatype}/${caseType}/${caseId}/${synctype}?superTaskId=${superTaskId}&taskId=${subTaskId}&familyId=${familyId}` : `/${visatype}/${caseType}/${caseId}/${synctype}?superTaskId=${superTaskId}&taskId=${subTaskId}`;
    return this.http.get(url).pipe(
      map((response: ImagilityBaseResponse) => {
        return response.data;
      })
    );
  }

  syncBeneWorkExperience(
    visatype: string, caseType: string, caseId: number, experienceId: number,
    superTaskId: number, subTaskId: number, syncType: string, familyId?) {
    const url = familyId ? `/${visatype}/${caseType}/${caseId}/${syncType}/${experienceId}/sync?superTaskId=${superTaskId}&taskId=${subTaskId}&familyId=${familyId}`:`/${visatype}/${caseType}/${caseId}/${syncType}/${experienceId}/sync?superTaskId=${superTaskId}&taskId=${subTaskId}`;
    return this.http.post(url, {}).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }

  syncI130(visatype: string, caseType: string, caseId: number, synctype: string, superTaskId: number, subTaskId: number, familyId?) {
    let url = `/petition/updateProfile/${caseId}/${this.toEncrypt('experience')}`;
    if(caseType == 'I130') {
      url = familyId ? `/gcCase/updateProfile/I130/${caseId}/${this.toEncrypt('experience')}?familyId=${familyId}` : `/gcCase/updateProfile/I130/${caseId}/${this.toEncrypt('experience')}`;
    } else if(caseType === 'I485'){
      url = familyId ? `/petition/updateProfile/${caseId}/${this.toEncrypt('experience')}?familyId=${familyId}` : `/petition/updateProfile/${caseId}/${this.toEncrypt('experience')}`;
    } else if(visatype == 'I192U' || visatype == 'UQ'){
      url = familyId ? `/${visatype}/${caseType}/${caseId}/${synctype}/sync?superTaskId=${superTaskId}&taskId=${subTaskId}&familyId=${familyId}`: `/${visatype}/${caseType}/${caseId}/${synctype}/sync?superTaskId=${superTaskId}&taskId=${subTaskId}`;
    }else{
      url = familyId ? `/petition/updateProfile/${caseId}/${this.toEncrypt('experience')}?familyId=${familyId}` : `/petition/updateProfile/${caseId}/${this.toEncrypt('experience')}`;
    }
    return this.http.post(url, {});
  }

  mergeI130(visaType: string, caseId: number, caseType: string, superTaskId: number, taskId: number|string, familyId: number, experienceType: string) {
    let url = `/${visaType}/${caseType}/${caseId}/${this.toEncrypt('experience')}/sync?superTaskId=${superTaskId}&taskId=${taskId}&syncType=merge&familyId=${familyId}`;
    if(caseType === 'I130') {
      url = `/GC/I130/${caseId}/${experienceType}/sync?superTaskId=${superTaskId}&taskId=${taskId}&familyId=${familyId}&syncType=Merge`;
    } else if(caseType === 'I485') {
      url = `/GCFLY/I485/${caseId}/${this.toEncrypt('experience')}/sync?superTaskId=${superTaskId}&taskId=${taskId}&familyId=${familyId}`;
    }
    return this.http.post(url, {});
  }

  resetI130(visaType: string, caseId: number, caseType: string, superTaskId: number, taskId: number|string, familyId: number, experienceType: string) {
    let url = `/${visaType}/${caseType}/${caseId}/${this.toEncrypt('experience')}/sync?superTaskId=${superTaskId}&taskId=${taskId}&syncType=reset`;
    if(caseType === 'I130') {
      url = `/GC/I130/${caseId}/${experienceType}/sync?superTaskId=${superTaskId}&taskId=${taskId}&familyId=${familyId}&syncType=Reset`;
    } else if(caseType === 'I485') {
      url = `/GCFLY/I485/${caseId}/${this.toEncrypt('experience')}/sync?syncType=Merge&superTaskId=${superTaskId}&taskId=${taskId}&familyId=${familyId}`;
    }
    return this.http.post(url, {});
  }

  toEncrypt(_data) {
    var pharase = 'c63a423527b24f65'
    var data = _data;
    var key  = CryptoJS.enc.Latin1.parse(pharase);
    var iv   = CryptoJS.enc.Latin1.parse(pharase);  
    var encrypted = CryptoJS.AES.encrypt(
      data,
      key,
      {iv:iv,mode:CryptoJS.mode.CBC,
        padding:CryptoJS.pad.ZeroPadding
    }).toString();

    var e64 = CryptoJS.enc.Base64.parse(encrypted);
    var eHex = e64.toString(CryptoJS.enc.Hex);

   return this.encriptedData = eHex
  }
  syncI140(caseId) {
    return this.http.post(`/gcCase/updateProfile/I140/${caseId}/${this.toEncrypt('experience')}`, {});
  }
  mergeI140(superTaskId: number, subTaskId: number|string) {
    return this.http.post(`/superTask/${superTaskId}/subTask/${subTaskId}/gc/i140Case/workExperienceDetails/sync?syncTypeGC=Merge`, {});
  }
  resetI140(superTaskId: number, subTaskId: number|string) {
    return this.http.post(`/superTask/${superTaskId}/subTask/${subTaskId}/gc/i140Case/workExperienceDetails/sync?syncTypeGC=Reset`, {});
  }
  getI140BeneExperienceInformation(superTaskId, subTaskId, isUsedForBenEdit = null) {
    return this.http.get(`/superTask/${superTaskId}/subTask/${subTaskId}/gc/i140Case/workExperienceDetails${isUsedForBenEdit != null ? '?isUsedForBenEdit='+isUsedForBenEdit: ''}`).pipe(
      map((response: ImagilityBaseResponse) => {
        return response.data;
      })
    );
  }
}

