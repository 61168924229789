import { Component, OnInit, Input, Output, AfterViewInit, OnChanges } from '@angular/core';
import { MultiUploadDocumentsServiceService } from './multi-upload-documents-service.service';
import { SelectUploadDocumentListComponent } from 'src/app/modules/i9-evaluators/bulk-upload/select-upload-document-list/select-upload-document-list.component';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { ConfirmationService } from 'primeng/api';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { isDebuggerStatement } from 'typescript';
import moment from 'moment';


@Component({
  selector: 'app-multi-upload-documents',
  templateUrl: './multi-upload-documents.component.html',
  styleUrls: ['./multi-upload-documents.component.scss']
})
export class MultiUploadDocumentsComponent implements OnInit, OnChanges {

  getDocumentArray: any = [];
  multiUploadDocumentArray: any = [];
  currentSelectedItem: any;
  observableSubscription$ = new Subject();

  columns: any;

  @Input() sectionName;
  @Input() companyId;
  @Input() beneficiaryId;
  @Input() I9FormId;
  @Input() isViewButton;
  @Input() showListBC;
  @Input() listBC;
  expirationNotHavingArray: any[] = [];
  instanceId: number;

  constructor(
    private documentService: MultiUploadDocumentsServiceService,
    private matDailog: MatDialog,
    private toastrService: ToastrService,
    private confirmationService: ConfirmationService,
  ) { 
    this.instanceId = Math.floor(Math.random() * 100);
  }

  ngAfterContentInit() {
    if (this.columns) {
      this.displayedColumns = this.columns.map(c => c.columnDef);
    }
  }

  ngOnInit(): void {
    var x = this.listBC;
    this.getUploadedDocuments();
  }


  ngOnChanges() {
    this.getUploadedDocuments();
  }

  getDisplayedColumns(): string[] {
    return this.displayedColumns.map(column => column.columnDef);
  }

  displayedColumns = [
    { columnDef: 'listType', header: 'Document from List' },
    { columnDef: 'docName', header: 'Document Name' },
    { columnDef: 'docType', header: 'Document Type' },
    { columnDef: 'docIssuingAuthority', header: 'Issue Authority' },
    { columnDef: 'docNumber', header: 'Document Number' },
    { columnDef: 'docExpirationDate', header: 'Expiry Date' },
    { columnDef: 'action', header: 'Action' }
  ];


  getUploadedDocuments() {
    this.documentService.getUploadedDataForBeneficiary(this.companyId, this.I9FormId).subscribe(res => {
      if (this.showListBC == undefined) {
        return
      }
      if (this.showListBC == 'BC') {
        this.getDocumentArray = [];
        var x = res.data.filter((_data) => _data.listType == 'BC');
        if (x != undefined) {
          this.getDocumentArray = [...this.getDocumentArray, ...x];
        }
      }
      else if (this.showListBC == 'A') {
        this.getDocumentArray = [];
        var x = res.data.filter((_data) => _data.listType == 'A');
        if (x != undefined) {
          this.getDocumentArray = [...this.getDocumentArray, ...x];
        }
      }

      // else if(this.showListBC == 'SECTION2'){
      //   this.getDocumentArray = [];         
      //   this.getDocumentArray = res.data;          
      // }
      // return this.getDocumentArray;
    });
  }


  selectDocuments(res) {
    for (let i = 0; i < res.length; i++) {
      let obj = {
        docExpirationDate: "",
        docIssuingAuthority: null,
        docName: res[i].name,
        docNumber: null,
        docType: "",
        viewMode: true,
        upload: true,
        groupCode: "",
        refCode: "",
        signDate: ""
      };
      this.multiUploadDocumentArray.push(res[i]);
      this.getDocumentArray = [...this.getDocumentArray, obj];
    }
  }

  openDocumentPopup(document) {
    this.currentSelectedItem = document
    const dialogRef = this.matDailog.open(SelectUploadDocumentListComponent, {
      width: '800px',
      maxHeight: '550px',
      backdropClass: 'backdropBackground',
      hasBackdrop: false,
      data: {
        docType: document.docType,
        sectionName: this.showListBC,
        listBC: this.showListBC
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == null) {
        return
      }
      document.docType = result.data.name;
      document.refCode = result.data.code;
      document.groupCode = result.data.group;

    });
  }


  // saveDocument() {
  //   this.expirationNotHavingArray = this.getDocumentArray.filter((x)=> x.upload && !x.docExpirationDate);
  //   if(this.expirationNotHavingArray.length > 0){
  //     this.confirmationService.confirm({
  //       message:'You have not entered the expiration date. Are you sure that you want to proceed?',
  //       key: `uploaddocs-${this.instanceId}`,
  //       accept: () => {
  //         this.finalUpload();
  //       },
  //       reject: () => {
  //       }
  //     });
  //   }else{
  //     this.finalUpload();
  //   }
  // }

  saveDocument() {
    // Filter documents that need validation
    const invalidDocuments = this.getDocumentArray.filter(
      (x) => x.upload && !x.docExpirationDate && !x.noExpiry
    );
  
    // Check if there are invalid documents
    if (invalidDocuments.length > 0) {
      // Show toaster message if validation fails
      this.toastrService.error("Please provide either an expiry date or select the 'D/S' checkbox for Duration of Status.");
      return; // Stop further execution
    }
  
    // Proceed with the final upload if all documents are valid
    this.finalUpload();
  }

  finalUpload(){
    for (let i = 0; i < this.multiUploadDocumentArray.length; i++) {
      var findIndex = this.getDocumentArray.map((res) => {
        return res.docName;
      }).indexOf(this.multiUploadDocumentArray[i].name);

      let payload = new FormData();
      payload.append('file', this.multiUploadDocumentArray[i]);
      const obj = {
        'company_id': this.companyId,
        'beneficiary_id': this.beneficiaryId,
        'refgroupcode': this.getDocumentArray[findIndex].groupCode,
        'i9Formid': this.I9FormId,
        'refcode': this.getDocumentArray[findIndex].refCode,
        'issAuth': this.getDocumentArray[findIndex].docIssuingAuthority,
        'docNo': this.getDocumentArray[findIndex].docNumber,
        'expDate': this.getDocumentArray[findIndex].docExpirationDate,
        'listType': this.showListBC,
        'sectionName': this.sectionName
      };
      payload.append('detailsString', JSON.stringify(obj));

      this.documentService.saveDocumentsNew(payload).subscribe(res => {
        this.toastrService.success("Documents Uploaded Successfully");
        this.getUploadedDocuments();
        this.multiUploadDocumentArray = [];
      });
    }
  }


  deleteRow(index) {
    let objArr = this.getDocumentArray;
    objArr.splice(index, 1);

    this.getDocumentArray = objArr.length > 0 ? [...objArr] : [];
    this.multiUploadDocumentArray = objArr.length > 0 ? [...objArr] : [];
  }


  openDoc(item) {
    const docPath = item.docPath.substring(23);
    window.open(`${environment.docs}${docPath}`, '_blank');
  }

  editRow(item) {
    item.viewMode = true;
    if (item.docExpirationDate) {
      item.docExpirationDate = moment(item.docExpirationDate).format("YYYY-MM-DD")
    }
  }

  cancelRow(item) {
    item.viewMode = false;
  }

  // updateDocument(_row) {
  //   let obj = {
  //     docId: _row?.docId,
  //     docName: "",
  //     docPath: "",
  //     docType: "",
  //     id: _row.id,
  //     refCode: _row.refCode,
  //     docNumber: _row.docNumber,
  //     docExpirationDate: _row.docExpirationDate ? moment(_row.docExpirationDate).format('YYYY-MM-DD'): '',
  //     docIssuingAuthority: _row.docIssuingAuthority,
  //     groupCode: _row.groupCode,
  //   }

  //   this.documentService.updateDocumentBulkUpload(obj, this.companyId, this.beneficiaryId, this.I9FormId).subscribe(res => {
  //     this.toastrService.success("Document Details Updated Successfully");
  //     this.getUploadedDocuments();
  //   })
  // }

  updateDocument(_row) {
    // Check if both expiry date and checkbox are not selected
    if (!_row.docExpirationDate && !_row.noExpiry) {
      this.toastrService.error("Please provide either an expiry date or select the 'D/S' checkbox for Duration of Status.");
      return; // Exit the method without proceeding
    }
  
    // Create the object for the update
    let obj = {
      docId: _row?.docId,
      docName: "",
      docPath: "",
      docType: "",
      id: _row.id,
      refCode: _row.refCode,
      docNumber: _row.docNumber,
      docExpirationDate: _row.docExpirationDate ? moment(_row.docExpirationDate).format('YYYY-MM-DD') : '',
      docIssuingAuthority: _row.docIssuingAuthority,
      groupCode: _row.groupCode,
    };
  
    // Call the service to update the document
    this.documentService.updateDocumentBulkUpload(obj, this.companyId, this.beneficiaryId, this.I9FormId).subscribe(
      (res) => {
        this.toastrService.success("Document Details Updated Successfully");
        this.getUploadedDocuments();
      }
    );
  }
  

  deleteI9PastDoc(item) {
    this.documentService.deleteSingleDocument(item, this.I9FormId).pipe(takeUntil(this.observableSubscription$))
      .subscribe((response) => {
        this.getUploadedDocuments();
        this.toastrService.success(response.message);
      });
  }

  confirmSingleDocDelete(item) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete?',
      key: 'deleteSingleDoc',
      accept: () => {
        this.deleteI9PastDoc(item);
      },
      reject: () => {
      }
    });
  }

  handleNoExpiryChange(emp: any): void {
    if (emp.noExpiry) {
      emp.docExpirationDate = null;
    }
  }

}
