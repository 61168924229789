import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { pluck, map, take } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { ImagilityBaseResponse } from 'app-models';
import { saveAs } from 'file-saver';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class I9EverifyService {

  encriptedData: string;

  constructor(private httpClient: HttpClient) { } 
  getEvaluatorsList(createdId, payload) {
    return this.httpClient.post(`/company/listOfCompanies?createdId=`+ createdId, payload).pipe(
      pluck('data'),
      map((users: Array<any>) => {
        return users;
      })
    );
  } 

  deleteCompany(companyId) {
    console.log('companyId ::-' + companyId);
    return this.httpClient.delete(`/i9admin/i9Company/${companyId}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    )
  }
  
  // Create Evaluators API will call here
  getCompaniesById(companyId: number): Observable<any> {
    return this.httpClient.get(`/i9admin/i9Company/${companyId}`).pipe(
      map((response: ImagilityBaseResponse) => response.data)
    );
  }

  // Initions screen
//-------- /petitioner/dashboard/beneficiaries/company/' + companyId (changed by Rahul)
  getBeneficiariesListForPetitioner(companyId, payload) {
    return this.httpClient.post(`/i9admin/nonInitiatedBeneficiaryList?i9AdminCompanyId=${companyId}`,payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        }
        else {
          return null;
        }
      }));
  }


// SEND EMAIL TO BENEFICIARY --- INITIATE I-9 PAGE - SECTION 1
sendEmailToBeneficiariesToGetDetails(companyId, payload) {
  return this.httpClient.post(`/i9admin/${companyId}/sendEmailToBeneficiary`, payload).pipe(
    map((response: ImagilityBaseResponse) => {
      if (response.status === 200) {
        return response;
      }
      else {
        return null;
      }
    }));
}

// SEND EMAIL TO EVALUATOR --- INITIATE I-9 PAGE - SECTION 2B
sendEmailToEvaluatorToVerification(companyId, payload) {
  return this.httpClient.post(`/i9admin/${companyId}/sendEmailToEvaluator`, payload).pipe(
    map((response: ImagilityBaseResponse) => {
      if (response.status === 200) {
        return response;
      }
      else if (response.status === 204) {
        return response;
      }
      else {
        return null;
      }
    }));
}

// GET EVALUATOR COMPANY --- INITIATE I-9 PAGE -- SECTION 2B COMPANY DROPDOWN
getEvaluatorCompaniesByBeneficiryId(beneficiaryId: number): Observable<any> {
  let getUserId = sessionStorage.getItem("userId");
  return this.httpClient.get('/company/listOfCompaniesByUserID/' + getUserId).pipe(
    map((response: ImagilityBaseResponse) => response.data)
  );
}

  // GET EVALUATOR USERS BY COMPANY ID --- INITIATE I-9 PAGE -- SECTION 2B USER DROPDOWN
  getEvaluatorUserDetails(companyId): Observable<any> {
    return this.httpClient.get(`/listOfEmployeesByCompanyID?companyID=${companyId}`).pipe(
      map((response: ImagilityBaseResponse) => response.data)
    );
  } 

  // GET EVALUATION LIST DATA --- INITIATE I-9 PAGE -- SECTION 2B USER DROPDOWN
  getEvaluationRequestData(pageNum, pSize, searchTxt): Observable<any> {
    const payload = {
      'pageNumber': pageNum,
      'pageSize': pSize,
      'searchText': searchTxt
    }
    let companyId = sessionStorage.getItem("companyId");
    return this.httpClient.post(`/i9admin/evalautionRequest?companyId=${companyId}`, payload).pipe(
      map((response: ImagilityBaseResponse) => response.data)
    );
  }



  getUsersList(companyId, payload) {
    return this.httpClient.post(`/company/${companyId}/user/roles/list`, payload).pipe(
      pluck('data'),
      map((users: Array<any>) => {
        return users;
      })
    );
  }
 

  saveUser(companyId, payload) {
    return this.httpClient.post(`/company/${companyId}/employee`, payload).pipe( // /createUser
      map((response: ImagilityBaseResponse) => response)
    );
  }
 

  deleteUser(companyId, employeeId) {
    console.log('companyId >>>>' + companyId, employeeId);
    return this.httpClient.delete(`/i9admin/${companyId}/User/${employeeId}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    )
  }


  //////////////////Update Empoyment/////////////////////

  getOnboardingEmploymentFromBenList(benificiaryId, companyId) {
    return this.httpClient.get(`/getWorkExperienceByI9?beneficiaryId=${benificiaryId}&companyId=${companyId}`);
  }

  getOnboardingEmploymentFromBenList2(benificiaryId, companyId) { 
    return this.httpClient.get(`/getWorkExperienceByI9?beneficiaryId=${benificiaryId}&companyId=${companyId}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        }
      })
    );
  }

  updateEmployment(payload: any) {
    return this.httpClient.post('/updateI9WorkExperience', payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      }));
  }

  /////////// Verify Beneficiary ///////////////////

  getI9EmployerDetails(companyId: number, i9FormId: number) {
    return this.httpClient.get(`/i9/company/${companyId}/i9form/${i9FormId}/employerAndVerifyDocInfo`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        }
      })
    );
  }




  saveI9Section2(companyId: number, i9FormId: number, payload) {
    return this.httpClient.post(`/i9/company/${companyId}/i9form/${i9FormId}/employerAndVerifyDocInfo`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }



  generateI9Form( companyId: number, i9FormId: number) {///I9/Company/359/I9form/3/generate/
    return this.httpClient.post(`/I9/Company/${companyId}/I9form/${i9FormId}/generate`, {}).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }

  updateI9Status(i9FormId: number, stausCode) {
    return this.httpClient.post(`/i9/${i9FormId}/status/${stausCode}/`, {}).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }


  // GET UPDATE EMPLOYMENT ID

  getUpdateEmploumentFormID(companyId, empId) {
    return this.httpClient.get(`/getWorkExperienceByI9?companyId=${companyId}&beneficiaryId=${empId}`).pipe(
      map((response: ImagilityBaseResponse) => {
        return response;
      })
    )
  }


  // for I-9 Dashboard
  evalautionRequest(i9AdminCompanyId, payload) { 
    return this.httpClient.post(`/i9admin/i9InitiatedList?i9AdminCompanyId=${i9AdminCompanyId}`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        }
        else {
          return null;
        }  
      })
    );
  }

  getEvaluationRequest(companyId,payload){
    return this.httpClient.post(`/i9admin/evalautionRequest?companyId=${companyId}`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        }
        else {
          return null;
        }  
      })
    ); 
  }

  // for I-9 Dashboard
  evalautionFormList(i9AdminCompanyId, payload) { 
    return this.httpClient.post(`/i9admin/evalautionFormList?i9AdminCompanyId=${i9AdminCompanyId}`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        }
        else {
          return null;
        }  
      })
    );
  }

 



  



  //////////////// Manage Employees //////////////////////


  getbeneficiaryListI9(companyId, requestType, payload) {
    //  /i9/company/{companyId}/employeeList/{requestType} INITIATIED + companyId
   // return this.httpClient.post('/petitioner/dashboard/beneficiaries/company/' + companyId, payload).pipe(
      return this.httpClient.post(`/i9/company/${companyId}/employeeList/${requestType}` , payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        }
        else {
          return null;
        }
      }));
  }


  // getbeneficiaryListI9(companyId, requestType, payload) { 
  //     return this.httpClient.get(`/i9/company/${companyId}/employeeList/${requestType}`).pipe(
  //     map((response: ImagilityBaseResponse) => {
  //       if (response.status === 200) {
  //         return response.data;
  //       }
  //       else {
  //         return null;
  //       }
  //     }));
  // }



  // VERIFY BY ADMIN
  getI9EmployeeDetails(companyId: number, i9FormId: number) {
    // debugger
    return this.httpClient.get(`/i9admin/company/${companyId}/i9form/${i9FormId}/employerAndVerifyDocInfo`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        }
      })
    );
  }


  getSignPic(employeeId: number = 0, companyId: number = 0) {
    // /api/v1/company/{companyId}/employee/{employeeId}/signPic
    return this.httpClient.get(`/company/${companyId}/employee/${employeeId}/signPic`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        }
      })
    );
  }
 
  verifyBeneficiary(companyId, i9FormId, payload: any) {    
    return this.httpClient.post(`/i9admin/company/${companyId}/i9form/${i9FormId}/employerAndVerifyDocInfo`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      }));
  }

  //http://50.19.45.219:5600/api/v1/i9admin/Company/343/I9form/43/generate/
  getPDF(companyId: number, i9FormId:number){ //post
    return this.httpClient.get(`/i9admin/Company/${companyId}/I9form/${i9FormId}/generate`).pipe(
      map((response: ImagilityBaseResponse) => {
       // if (response.status === 200) {
          return response;
       // }
      })
    );
  }

  //http://50.19.45.219:5600/api/v1/i9admin/Company/343/I9form/43/generate/
  savePDF(companyId: number, i9FormId:number,payload:any){ //post
    return this.httpClient.post(`/i9admin/Company/${companyId}/I9form/${i9FormId}/generate`,payload ).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      }));
  }

  getFormDetails(companyId: number, i9FormId: number) {
    // debugger  
    return this.httpClient.get(`/i9admin/company/${companyId}/i9form/${i9FormId}/employerAndVerifyDocInfo`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        }
      })
    );
  }


  getPDFFile(companyId: number, i9FormId: number) { 
    return this.httpClient.get(`/i9admin/document/i9/company/${companyId}/i9form/${i9FormId}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        }
      })
    );
  }

//new api for stage part 1

getI9VerificationDocuemt(file, i9FormId: number) { 
  return this.httpClient.get(`/document/i9/${i9FormId}/category/${file.group}/fileCategory/${file.code}`).pipe(
    map((response: ImagilityBaseResponse) => {
      if (response.status === 200) {
        return response.data;
      }
    })
  );
}

getdocumentList(groupReferenceCode) {
  return this.httpClient.get(`/referenceLibrary/${groupReferenceCode}`).pipe(
    map((response: ImagilityBaseResponse) => {
      if (response.status === 200) {
        debugger
        return response.data;
      }
    })
  );
}

uploadI9Document(companyId: number, i9FormId: number, fileDetails, payload) {
  return this.httpClient.post(`/document/i9/company/${companyId}/i9form/${i9FormId}/category/${fileDetails.group}/fileCategory/${fileDetails.code}`, payload).pipe(
    map((response: ImagilityBaseResponse) => {
      if (response.status === 200) {
        return response;
      }
    })
  );
}

//new api for stage part 1.2 
getExistingDocuments( i9FormId: number) {
  return this.httpClient.get(`/i9admin/document/i9/${i9FormId}`).pipe(
    map((response: ImagilityBaseResponse) => {
      if (response.status === 200) {
        return response.data;
      }
    })
  );
}

 

verifyDocuments(i9FormId: number){
  return this.httpClient.post(`/i9admin/${i9FormId}/status/SEC1VERFD`,{}).pipe(
    map((response: ImagilityBaseResponse) => {
      //if (response.status === 200) {
        return response;
    //  }
    })
  );
}


 
//  api/v1/i9admin/company/{companyId}/i9form/{i9FormId}/I9Section1Info
i9Section1Info(companyId: number, i9FormId: number) {
  return this.httpClient.get(`/i9admin/company/${companyId}/i9form/${i9FormId}/I9Section1Info`).pipe(
    map((response: ImagilityBaseResponse) => { 
        return response; 
    })
  );
}


 

////////////////////DOWNLOAD PDF FROM PATH/////////////////////
downloadDocuments(path: string) {
  if(!path) return;
  let fileName = path.replace(/^.*[\\\/]/, '')
  this.httpClient.get(path, { responseType: "arraybuffer" })
  .pipe(take(1))
  .subscribe(pdf => {
    const blob = new Blob([pdf], { type: 'application/pdf' });
    saveAs(blob, fileName);
  });
}

getRemoteI9Verification(companyId, payload) {
  return this.httpClient.post(`/i9admin/i9EverifyList?i9AdminCompanyId=${companyId}`, payload).pipe(
    map((response: ImagilityBaseResponse) => {
      if (response.status === 200) {
        return response.data;
      }
      else {
        return null;
      }
    }));
}

deleteI9Form(companyId, i9FormId) {
  debugger;
  return this.httpClient.delete(`/i9admin/company/${companyId}/i9form/${i9FormId}`).pipe(
    map((response: ImagilityBaseResponse) => {
      debugger;
      if (response.status === 200) {
        return response;
      }
    })
  )
}

sendEmailConfirmationToI9Admin(payload) {
  // return this.httpClient.post(`/i9admin/sendMailToI9AdminOrBeneficiary?i9FormId=${formId}&url=${url}`, {}).pipe(
    return this.httpClient.post(`/i9admin/sendMailToI9AdminOrBeneficiary`, payload).pipe(
    map((response: ImagilityBaseResponse) => {
      if (response.status === 200) {
        return response;
      }
      else {
        return null;
      }
    }));
}
  getAuthRepresentativeData(companyId, i9FormId, beneficiaryId): Observable<any> {
    return this.httpClient.get(`/i9admin/getAuthRepresentaviveDetails/${companyId}/${beneficiaryId}/${i9FormId}`).pipe(
      map((response: ImagilityBaseResponse) => response.data)
    );
  } 

  toEncrypt(_data) {
    var pharase = 'c63a423527b24f65'
    var data = _data;
    var key  = CryptoJS.enc.Latin1.parse(pharase);
    var iv   = CryptoJS.enc.Latin1.parse(pharase);  
    var encrypted = CryptoJS.AES.encrypt(
      data,
      key,
      {iv:iv,mode:CryptoJS.mode.CBC,
        padding:CryptoJS.pad.ZeroPadding
    }).toString();

    var e64 = CryptoJS.enc.Base64.parse(encrypted);
    var eHex = e64.toString(CryptoJS.enc.Hex);

   return this.encriptedData = eHex
  }

  
}
