import { LogoutComponent } from './logout/logout.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SelectivePreloadingStrategyService } from './selective-preloading-strategy.service';
import { LandingComponent } from './modules/landing/landing.component';
import { CreateClientComponent } from './modules/clients/create-client/create-client.component';
import { AuthGuardService } from './auth-guard.service';
import { InvitedCollectedLeadsComponent } from './modules/invited-collected-leads/invited-collected-leads.component';
// import { TempJsonManupilateComponent } from './temp-json-manupilate/temp-json-manupilate.component';

const appRoutes: Routes = [
  {
    path: 'platform-admin',
    loadChildren: () => import('./modules/platform-admin-landing/platform-admin.module').then(m => m.LandingPlatformAdminModule),
    data: { preload: true }
  },
  {
    path: 'login',
    loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule),
    data: { preload: true },
    canLoad: [AuthGuardService]
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'landing',
    component: LandingComponent
  },
  {
    path: 'logout',
    component: LogoutComponent
  },
  {
    path: 'free',
    loadChildren: () => import('./modules/free-trial/freetrial.module').then(m => m.FreeTrialModule),
  },
  {
    path: 'beneficiary-profile',
    loadChildren: () => import('./modules/beneficiary-questionnaire/beneficiary-questionnaire.module').then(m => m.BeneficiaryQuestionnaireModule),
  },
  {
    path: 'signature-upload-verify',
    loadChildren: () => import('./modules/signature-upload/signature-upload.module').then(m => m.SignatureUploadModule),
  },
  {
    path: 'petitioner-landing/case-request/beneficiary-profile',
    loadChildren: () => import('./modules/beneficiary-questionnaire/beneficiary-questionnaire.module').then(m => m.BeneficiaryQuestionnaireModule),
  },
  {
    path: 'beneficiary-profile/authentication',
    loadChildren: () => import('./modules/beneficiary-verify/beneficiary-verify.module').then(m => m.BeneficiaryVerifyModule),
  },
  {
    path: 'signature-upload-verify/authentication',
    loadChildren: () => import('./modules/signature-upload-verify/signature-upload-verify.module').then(m => m.SignatureUploadVerifyModule),
  },
  {
    path: 'attorney-profile/authentication',
    loadChildren: () => import('./modules/beneficiary-verify/beneficiary-verify.module').then(m => m.BeneficiaryVerifyModule),
  },
  // {
  //   path: 'billing',
  //   loadChildren: () => import('./modules/billing-payment/billing-payment.module').then(m => m.BillingPaymentModule),
  // },
  {
    path: 'company-request-details/authentication/:id',
    loadChildren: () => import('./modules/beneficiary-verify/beneficiary-verify.module').then(m => m.BeneficiaryVerifyModule),
  },
  {
    path: 'external-client/data-and-docs',
    loadChildren: () => import('./modules/data-and-docs/external-client-data-docs.module').then(m => m.ExternalClientDataDocsModule),
  },
  {
    path: 'lead/collected-lead',
    component: InvitedCollectedLeadsComponent
  },
  {
    path: 'module-login',
    loadChildren: () => import('./modules/fb-module/fb-module.module').then(m => m.FbModuleModule),
    data: { preload: true }
  },
  {
    path: 'fb-preview',
    loadChildren: () => import('./modules/fb-module/fb-module.module').then(m => m.FbModuleModule),
    data: { preload: true }
  },
  {
    path: 'modules-landing',
    loadChildren: () => import('./modules/fb-module/fb-module.module').then(m => m.FbModuleModule),
    data: { preload: true }
  },
  // {
  //   path: 'json-manipulate',
  //   component: TempJsonManupilateComponent
  // }
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes,
      {
        enableTracing: false, // <-- debugging purposes only
        preloadingStrategy: SelectivePreloadingStrategyService,
      }
    )
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
