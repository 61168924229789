import {
  Component,
  OnInit,
  ViewChild,
  Output,
  Input,
  EventEmitter,
  ElementRef
} from "@angular/core";
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { environment } from "../../../../environments/environment";
import { AuthenticationService } from "src/app/services/authentication.service";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import {  MatSort } from "@angular/material/sort";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ConfirmationModalComponent } from "src/app/modules/shared/modal/confirmation-modal/confirmation-modal.component";
import { UploadDocumentService } from "src/app/services/upload-document.service";
import * as Roles from "../../../data/constants/roles";
import { PetitionerService } from "src/app/services/petitioner.service";
import { LCA } from "src/app/data/models/lca.model";
import { PageEvent } from "@angular/material/paginator";
import { debounceTime, map, filter, take } from 'rxjs/operators';
import { fromEvent,Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { Subject } from 'rxjs/internal/Subject';
import { ImagilityBaseResponse } from 'app-models';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: "app-public-access",
  templateUrl: "./public-access.component.html",
  styleUrls: ["./public-access.component.scss"],
  animations: [
    trigger("detailExpand", [
      state("collapsed", style({ height: "0px", minHeight: "0" })),
      state("expanded", style({ height: "*" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      ),
    ]),
  ],
})
export class PublicAccessComponent implements OnInit {
  lcaList: LCA[] = [];
  displayedColumns: string[] = [];
  dataSource: MatTableDataSource<any>;

  pageEvent: PageEvent;
  pageSizeOptions = [25,50,100];
  pageSize = 25; //default
  pageIndex = 0; //default
  searchText:string = '';
  searchSubscription: Subscription;
  totalRecordCount: number = 0;
  validityEndDate;
  validityStartDate;

  updateBenProfile: Boolean = true;
  educationDetails: any = [];
  beneficiaryId: any;
  expandedElement: any | null;
  envPath: any;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild("searchAccessListTable", { static: true }) searchAccessListTable: ElementRef;
  companyId: string;
  categoryName: any;
  entityId: number;
  fileCategory: string;
  selectedOption: string = '';
  selectedRowData: any;
  isFileCategory: boolean = false;

  option: any;
  i: number;
  selectedFile='';
  observableSubscription$ = new Subject();

  columns = [
    { columnDef: "toggle", header: "", columnType: "icon", customClass: "" },
    { columnDef: "id", header: "ID", columnType: "text", customClass: "" },
    {
      columnDef: "caseNumber",
      header: "Case No.",
      columnType: "text",
      customClass: "",
    },
    {
      columnDef: "jobTitle",
      header: "SOC Job Title",
      columnType: "text",
      customClass: "",
    },
    {
      columnDef: "socCode",
      header: "SOC Code",
      columnType: "text",
      customClass: "",
    },
    {
      columnDef: "visaType",
      header: "Visa Type",
      columnType: "text",
      customClass: "",
    },
    {
      columnDef: "companyName",
      header: "Company Name",
      columnType: "text",
      customClass: "",
    },
    {
      columnDef: "employmentStartDate",
      header: "Start",
      columnType: "text",
      customClass: "",
    },
    {
      columnDef: "employmentEndDate",
      header: "End",
      columnType: "text",
      customClass: "",
    },
    {
      columnDef: "status",
      header: "Status",
      columnType: "text",
      customClass: "",
    },
    {
      columnDef: "statusChangeDate",
      header: "Withdrawn",
      columnType: "text",
      customClass: "",
    }
  ];

  docType = {
    documents: [
      { code: '1', name: 'LCA (Labor Condition Application)', fileCat:'LCA' },
      { code: '2', name: 'Wage Determination', fileCat:'WAGE' },
      { code: '3', name: 'LCA Acknowledgment', fileCat:'LCAACKNWLDGMNT' },
      { code: '4', name: 'LCA Posting', fileCat:'LCAPOSTDCLRTN' },
      { code: '5', name: 'Workforce Notification', fileCat:'LCAWRKFRCNOTF' },
      { code: '6', name: 'Benefit Summary', fileCat:'LCAPOSTDCLRTN' }
    ]
  };

  constructor(
    private authenticationService: AuthenticationService,
    private petitionerService: PetitionerService,
    public dialog: MatDialog,
    private toastr: ToastrService
    
  ) {
      this.categoryName = [
      'LCADOC',
      'LCAWRKFRCNOTF',
      'LCAACKNWLDGMNT',
      'LCAPOSTDCLRTN'
    ];
  }

  ngOnInit() {
    this.companyId = sessionStorage.getItem("companyId");
    this.envPath = environment.docs;
    this.searchSetUp();
    if(sessionStorage.getItem('userTypeRole') != 'Beneficiary'){
      this.loadLcaCodes();
    }
    this.fileCategory = '';
  }
  handleFileCategorySelection(): void {
    switch (this.fileCategory) {
      case 'LCA':
        this.categoryName = 'LCADOC';
        break;
      case 'WAGE':
        this.categoryName = 'LCADOC';
        break;
        case 'LCAACKNWLDGMNT':
          this.categoryName = 'LCAACKNWLDGMNT';
          break;
          case 'LCAPOSTDCLRTN':
            this.categoryName = 'LCAPOSTDCLRTN';
            break;
        case 'LCAWRKFRCNOTF':
          this.categoryName = 'LCAWRKFRCNOTF';
          break;
          case 'LCAPOSTDCLRTN':
            this.categoryName = 'LCAPOSTDCLRTN';
            break;
      default:
        // Default logic if no match is found
    }
    this.isFileCategory = this.fileCategory !== '';
  }
  searchSetUp() {
    this.searchSubscription = fromEvent(this.searchAccessListTable.nativeElement, 'keyup').pipe(
      map((event: any) => { return event.target.value; })
      , filter(res => res.length > 2)
      , debounceTime(1000)).subscribe((text: string) => {
        if (this.dataSource.paginator) {
          this.dataSource.paginator.firstPage();
        }
        this.searchText = text.trim();
        this.loadLcaCodes();
      });
  }
  
  ngAfterContentInit() {
    this.displayedColumns = this.columns.map((c) => c.columnDef);
  }

  loadLcaCodes() {
    let payload = {
      pageNumber: this.pageIndex+1,
      pageSize: this.pageSize,
      searchText: this.searchText,
      status: [
        "LCAASSIGN","LCAWTHDR"
      ]  //IM-7862 fix removing LCAUNASSI
    };
    this.petitionerService
      .getPublicAccessFiles(this.companyId, payload)
      .subscribe((data) => {
        this.lcaList = data["lcaApplicationResponseDto"];
        this.totalRecordCount = data["totalNumberOfRecords"];
        this.dataSource = new MatTableDataSource(this.lcaList);
        this.dataSource.sort = this.sort;
      });
  }

  getRecord(row, element) {
    debugger;
    this.selectedRowData = row;
    this.entityId = row.id; 
    if(element=='updatedDocument')
    {
      this.petitionerService.getLcaDataById(this.entityId).subscribe((data) => {
        debugger;
        row.documents="";
        row.documents = data.documents;
      });
    }
    else  {
      if(element)
      {
      debugger;
      this.petitionerService.getLcaDetailsById(row.id).subscribe((data) => {
        debugger;
        row.documents = data.documents;
      });
    }
    } 
  }
  displayDocument(item) {
    const docPath = item.fileLocation.substring(23);
    window.open(`${environment.docs}${docPath}`, "_blank");
   // console.log(item);
   // window.open(`${item.fileLocation}`, "_blank");
  }
  paginationEvent(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.loadLcaCodes();
  }
  resetSearch(){
      this.searchText =''; //reset search
      this.pageIndex = 0;//reset search
      this.loadLcaCodes();
  }
  ngOnDestroy() {
    this.searchSubscription.unsubscribe();
  }

  uploadDocument(_data: any){
    debugger;
    let row : any;
    let files = _data[0];   
    this.selectedFile = files; 
    const formData = new FormData();
    formData.append('file', this.selectedFile);
    this.petitionerService.uploadPublicDocument(this.companyId, this.categoryName, this.entityId, this.fileCategory, formData)
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe((response: ImagilityBaseResponse) => {
        debugger;
        if (response.status == 200) {
          debugger;
          this.toastr.success(response.message, "File Uploaded Successfully");
          this.getRecord(this.selectedRowData, 'updatedDocument')
        
        }
        else{
          this.toastr.warning(response.message);
        }
      })
  }

  deleteDoc(item: any) {
    debugger;
    this.petitionerService.deleteUploadDocument(this.companyId, item.fileCategory.group, item.fileCategory.code, item.id)
      .pipe(take(1))
      .subscribe((response: boolean) => {
        if (response) {
          debugger;
          this.getRecord(this.selectedRowData, 'updatedDocument')
        }
      });
  }
}
