<div class="fixed-height visit-task-widget shadow-sm">
    <div class="card border-0">
      <div class="card-header d-flex justify-content-between align-items-center">
        <div class="col-7 pl-0 pr-2">Recent Activity</div>
      </div>
      <div class="card-body">
        <div class="card-content">
          <mat-tab-group (selectedTabChange)="onTabChange($event)">
            <!-- Individual Tab -->
            <mat-tab label="Individual">
              <div class="table-container">
                <table mat-table [dataSource]="limitedIndividualData" class="mat-elevation-z8">
                  <!-- Time Column -->
                  <ng-container matColumnDef="time">
                    <th mat-header-cell *matHeaderCellDef> Time </th>
                    <td mat-cell *matCellDef="let element"> 
                        <div>{{ element.time  }}</div> 
                    </td>
                  </ng-container>
  
                  <!-- Individual Name Column -->
                  <ng-container matColumnDef="individualName">
                    <th mat-header-cell *matHeaderCellDef> Individual Name </th>
                    <td mat-cell *matCellDef="let element">
                      <a href="javascript:void(0)" (click)="onIndividualClick(element)">
                        {{ element.beneficiaryName }}
                      </a>
                    </td>
                  </ng-container>
  
                  <!-- Company Column -->
                  <ng-container matColumnDef="company">
                    <th mat-header-cell *matHeaderCellDef> Company </th>
                    <td mat-cell *matCellDef="let element"> {{ element.companyName }} </td>
                  </ng-container>
  
                  <!-- Header Row -->
                  <tr mat-header-row *matHeaderRowDef="individualDisplayedColumns"></tr>
                  <!-- Data Rows -->
                  <tbody class="scrollable-tbody">
                    <tr mat-row *matRowDef="let row; columns: individualDisplayedColumns;"></tr>
                  </tbody>
                </table>
              </div>
            </mat-tab>
  
            <!-- Company Tab -->
            <mat-tab label="Company">
              <div class="table-container">
                <table mat-table [dataSource]="limitedCompanyData" class="mat-elevation-z8">
                  <!-- Time Column -->
                  <ng-container matColumnDef="time">
                    <th mat-header-cell *matHeaderCellDef> Time </th>
                    <td mat-cell *matCellDef="let element">
                      <div>{{ element.time }}</div>  
                    </td>
                  </ng-container>
  
                  <!-- Company Column -->
                  <ng-container matColumnDef="company">
                    <th mat-header-cell *matHeaderCellDef> Company </th>
                    <td mat-cell *matCellDef="let element">
                      <a href="javascript:void(0)" (click)="onCompanyClick(element)">
                        {{ element.companyName }}
                      </a>
                    </td>
                  </ng-container>
  
                  <!-- Header Row -->
                  <tr mat-header-row *matHeaderRowDef="companyDisplayedColumns"></tr>
                  <!-- Data Rows -->
                  <tbody class="scrollable-tbody">
                    <tr mat-row *matRowDef="let row; columns: companyDisplayedColumns;"></tr>
                  </tbody>
                </table>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </div>
  </div>