<div *ngIf="showEditBtn" class="row">
    <div class="col-12">
        <button class="im-btn im-btn-with-icon im-btn-light btn-pos-top-right" (click)="routeToProfile()">
            <img src="../../../../../assets/images/edit.png" alt="">
             <span class="common-bene-class-for-translation">Edit</span></button>
    </div>
</div>
<div class="mt-4">
    <div class="row">
        <div class="col-md-12 p-0">
            <div class="main-timeline" *ngIf="immigrationData.length>0">
                <div class="triangle-box position-relative text-center">
                    <div class='arrow-icon'></div>
                </div>
                <div class="timeline" *ngFor="let item of immigrationData">
                    <!-- <h3 class="title" >{{item?.outcomeDate | date:"dd-MMM-yyyy"}}</h3>                   -->
                    <a class="timeline-content">
                        <!-- <div class="timeline-duration">
                            <span>{{item?.immigrationStatusCode}} - {{item?.petitionTypeName}}</span>
                        </div>
                        <h3 class="title">{{item?.companyName}}</h3> -->
                        <div class="row">
                            <div class="col">
                                <h3 class="title" title="{{item?.immigrationStatusCode}} - {{item?.petitionTypeName}}">{{item?.immigrationStatusCode}} - {{item?.petitionTypeName}}</h3>
                                <p class="description" title="{{item?.companyName}}">
                                    {{item?.companyName}}
                                </p>
                            </div>
                        </div>

                        <!-- <h3 class="title" *ngIf="item.outcomeIdCode === 'VSAAPP'">Approved On: {{item?.outcomeDate | date:"dd-MMM-yyyy" }}</h3>
                        <h3 class="title" *ngIf="item.outcomeIdCode === 'VSADENY'">Denied On: {{item?.outcomeDate | date:"dd-MMM-yyyy"}}</h3>
                        <h3 class="title" *ngIf="item.outcomeIdCode === 'VSPROCESS'">Receipt On: {{item?.receiptDate | date:"dd-MMM-yyyy"}}</h3> -->
                        <div class="timeline-duration ed-duration position-absolute">
                            <span class="d-block" *ngIf="item.outcomeIdCode === 'VSAAPP'">{{item?.validityStartDate | date:"dd-MMM-yyyy"}}</span>
                            <span class="d-block" *ngIf="item.outcomeIdCode === 'VSADENY'">{{item?.outcomeDate | date:"dd-MMM-yyyy"}}</span>
                            <span class="d-block" *ngIf="item.outcomeIdCode === 'VSPROCESS'">{{item?.receiptDate | date:"dd-MMM-yyyy"}}</span>
                            <span class="d-block mt-1">{{item?.validityEndDate | date:"dd-MMM-yyyy"}}</span>
                        </div>
                    </a>
                </div>
            </div>
            <div class="rounded-area">
                <div class="rounded-area-inner"></div>
            </div>
            <div *ngIf="immigrationData.length==0" class="no-data common-bene-class-for-translation">-- No Data --</div>
        </div>
    </div>
</div>
