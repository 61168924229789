<div class="container">
    <div class="float-left" class="bulk-pdialog">
        <!-- <label class="required"> <b>Uploading Section Forms is mandatory</b></label> -->
        <!-- <div class="input-group"> 
            <div class="i9Inputs">
                <label class=" docCheckbox">I9 Forms
                <input name="listA" type="radio" class="btnRadio" name="docTypeitems" [checked]="i9Section.isCheked"
                (change)="section_Change(i9Section)" value="i9Section.code">
                <span class="checkmark"></span> -->
                <!-- <input type="checkbox" [(ngModel)]="i9Section" (change)="list_A_Change(i9Section)" /><span>I-9 Section 1</span> -->
                <!-- </label>
            </div>
        </div> -->
        <label class="pt-3"><b>Supporting documents for section 1 form</b></label>
    </div>


    <div class="row rowBorders mb-2">
        <!-- [ngClass]="{'col-md-6': currentSection == 'Section-3'}" -->

        <div class="col-md-4 p-0 rightBorder" *ngIf="['A','section2','Section-2','section3', 'Section-3'].includes(listBC)" [ngClass]="{'col-md-12': listBC == 'A', 'col-md-6': listBC === 'section3' || listBC === 'Section-3'}">
            <h6 class="doclist-heading"><b>List A</b></h6>
            <ul>
                <li *ngFor="let item of List_A" class="d-block">
                    <div class="w-100 d-flex ">
                        <label class=" docCheckbox">{{item.name}}
                        <input name="listA" type="radio" class="btnRadio" name="docTypeitems" [checked]="item.isCheked"
                            (change)="list_A_Change(item)" value="item.code">
                            <span class="checkmark"></span>
                        </label>
                        
                    </div>
                    <div class="w-100" *ngIf="item?.subcategories">
                        <ul class="i9-docs-subcategory">
                            <li *ngFor="let subItem of item.subcategories">
                                {{subItem}}
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>
        </div>
       
        <div  class="col-md-4 p-0 rightBorder" *ngIf="['BC','section2','Section-2'].includes(listBC)" [ngClass]="{'col-md-6': listBC == 'BC'}" >
            <h6 class="doclist-heading"><b>List B</b></h6>
            <ul>
                <li *ngFor="let item of List_B" class="d-block">
                    <div class="w-100 d-flex">
                        <label class="docCheckbox">{{item.name}}
                        <input name="listB" type="radio" class="btnRadio" name="docTypeitems" value="item.code"
                            (change)="list_B_Change(item)" [checked]="item.isCheked">
                            <span class="checkmark"></span>
                        </label>
                    </div>
                    <div class="w-100" *ngIf="item?.subcategories">
                        <ul class="i9-docs-subcategory">
                            <li *ngFor="let subItem of item.subcategories">
                                {{subItem}}
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>
            <ul>
                <li *ngFor="let item of List_B1" class="d-block">
                    <div class="w-100 d-flex">
                        <label class="docCheckbox">{{item.name}}
                        <input name="listB" type="radio" name="docTypeitems" class="btnRadio" value="item.code"
                            (change)="list_B_Change(item)" [checked]="item.isCheked">
                            <span class="checkmark"></span>
                        </label>
                    </div>

                    <div class="w-100" *ngIf="item?.subcategories">
                        <ul class="i9-docs-subcategory">
                            <li *ngFor="let subItem of item.subcategories">
                                {{subItem}}
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>
            
        </div>

        <div class="col-md-4 p-0 rightBorder" *ngIf="['BC','section2','Section-2','section3','Section-3'].includes(listBC)" [ngClass]="{'col-md-6': listBC == 'BC' || listBC === 'section3' || listBC === 'Section-3'}" >
            <h6 class="doclist-heading" *ngIf="['BC','section2','Section-2','section3','Section-3'].includes(listBC)"><b>List C</b></h6>
            <ul>
                <li *ngFor="let item of List_C" class="d-block">
                    <div class="w-100 d-flex">
                        <label class="docCheckbox">{{item.name}}
                        <input name="listC" type="radio" name="docTypeitems" class="btnRadio" value="item.code"
                            (change)="list_C_Change(item)" [checked]="item.isCheked">
                            <span class="checkmark"></span>
                        </label>
                    </div>
                    <div class="w-100" *ngIf="item?.subcategories">
                        <ul class="i9-docs-subcategory">
                            <li *ngFor="let subItem of item.subcategories">
                                {{subItem}}
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>
        </div>
        
    </div>

    <div class="row mt-4 p-0 pb-2">
        <div class="mr-3 p-0">
          <button type="button" label="ADD" title="ADD" pButton class="p-button-info" (click)="addDoc()">
          </button>
        </div>
        <div class="p-0">
          <button type="button" label="CANCEL" title="CANCEL" pButton class="p-button-secondary"
            (click)="handleCancel()"></button>
        </div>
      </div>

</div>