import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ChangeDetectorRef } from "@angular/core";
import { FormGroup, FormBuilder, NgForm, Validators, AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { Subscription, Observable, of } from 'rxjs';
import { EditorComponent, schema } from "@progress/kendo-angular-editor";
import { UploadImageDialogComponent } from "editor-library";
const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/';
import { ToastrService } from 'ngx-toastr';
import { EmailClientService } from "../../service/email-client.service";
import { TemplateService } from "src/app/services/template.service";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AttorneyProfileService } from 'src/app/services/attorney-profile.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from "environment-configurations";
import { PetitionerService } from "src/app/services/petitioner.service";
import { debug } from "console";

@Component({
  selector: "imagility-compose-email",
  templateUrl: "./imagility-compose-email.component.html",
  styleUrls: ["./imagility-compose-email.component.scss"],
})
export class ImagilityComposeEmailComponent implements OnInit, OnDestroy {
  categoryDialog:boolean = true;
  fromEmail: any;
  unifiedTaskId: any; 
  userId: any = sessionStorage.getItem('userId');
  isReply: boolean = false;
  ifEmailData: any = 'SENT'; 
  templateListModel:any;

  isUnified: boolean;
  isSelectCategory:boolean = false;

  commonkendoeditorStr: string;
  @Output() @ViewChild("commonkendoeditor") public commonkendoeditor: EditorComponent;
  @ViewChild("commonkendoeditorupload") public commonkendoeditorupload: UploadImageDialogComponent;
  @Output() deleteMailDetails = new EventEmitter<any>()
  replyForm: FormGroup;
  discardDialog: boolean = false;
  userName: string = '';
  userEmail: string = '';
  submitted: boolean = false;
  replyType: string = '';
  uploadedFile: any;
  attachFileList: any[] = [];
  mailId: string = '';
  subjectId: string = '';
  uploadedFiles$: Observable<any>;
  acceptedFiles: string = ".pdf, .jpg, .png, .doc, .docx, .xls, .xlsx,.csv";
  emailDetailSubscription: Subscription;
  templateList = [];
  // emailTemplateModel:any = {templateName:'Select', value:0}; 

  getEmailDetailsSub: Subscription;
  id: string;
  isEmailToError: boolean = false;
  isEmailFromError: boolean = false;
  emailData: any;

  imagilityAttachment: any = [];

  isemailActivated: boolean = true;
  currentSelected: any;
  beneEmail:string = '';
  categories:any=[
    {name:'GENERIC'}, 
    {name:'BENEFICIARY'}, 
    {name:'PETITIONER'}
  ];
  categoryPlaceholder:any;
  benPlaceholder:any;
  petPlaceholder:any;
  beneficiaryListData:any = [];
  petetionerListData:any = [];
  isbenActive:Boolean = false;
  ispetActive:Boolean = false;

  editor: EditorComponent; 
  placeholderErrorDialog:Boolean = false;
  currentCompanyId:any;
  currentSelection:string;
  currentUserRole:string;
  taskLevelData:any=[];
  userRole = sessionStorage.getItem('userTypeRole');

  constructor(private fb: FormBuilder,
    private toastr: ToastrService,
    private templateService: TemplateService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private emailClientService: EmailClientService,
    private modalService: NgbModal,
    private attorService: AttorneyProfileService,
    private activeModal: NgbActiveModal,
    private ref: ChangeDetectorRef,
    private petitionerService: PetitionerService
  ) {
  }

  ngOnInit(): void {

    this.currentUserRole = sessionStorage.getItem('userTypeRole');
   if( this.unifiedTaskId){
    this.categories=[
      {name:'GENERIC'}, 
      {name:'PETITION'}
    ];
   }
   else if(this.currentUserRole == 'Attorney'){
    this.categories=[
      {name:'GENERIC'}, 
      {name:'INDIVIDUAL'}, 
      {name:'PETITIONER'}
    ];
   }


    this.categoryPlaceholder = this.categories[0];

    let email = localStorage.getItem('userEmail');
    this.fromEmail = email;
    
    this.beneEmail = sessionStorage.getItem('beneficiaryEmailId');
 
    let emailregex: RegExp = /^(([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})+([,.](([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})+)*$/;
    this.replyForm = this.fb.group({
      from: [email, [Validators.required, Validators.email]],
      priority: ['normal'],
      templateName: [''],
      to: ['', {
        validators: [Validators.required, Validators.pattern(emailregex), this.checkduplicateEmail()],
        updateOn: 'blur'
      }],
      cc: ['', {
        validators: [Validators.pattern(emailregex), this.checkduplicateEmail()],
        updateOn: 'blur'
      }],
      subject: ['', Validators.required],
      editor: ['']
    });

    if (this.currentSelected == 'Compose') {
      this.isemailActivated = false;      
    }
    if (this.currentSelected == 'Compose' && this.unifiedTaskId) {  

      this.isSelectCategory = true; 

      let payload = {"superTaskTypeId":"EMALTEMPLT","isDefault":"false","pageNumber":1,"pageSize":1000,"searchText":""};


      this.petitionerService.getAllTemplateListForTask(payload).subscribe(res => {
        this.taskLevelData = res;
        debugger
        this.taskLevelData?.data['documentTemplate'].forEach(element => {
          element.documentType = element.documentType.code;
          element.templateType = element.templateType.code;
          this.templateList.push(element);
         
        }, error => {
          console.log(error.message.message);
        });

        this.templateList.unshift({id:0, templateName:'Select Template'});
        this.templateListModel = this.templateList[0].id;
  
      })


      if(this.beneEmail){
        this.replyForm.controls['to'].setValue([this.beneEmail]);
      }        
    }

    if (this.emailData) {
      
      let payload = { "threadIds": [this.emailData.id], "loginId": this.emailData.loginId, "isRead": true };

      if (this.emailData?.folder == 'DRAFT') {
        this.isemailActivated = false;
      }

      this.attorService.saveUnifiedEmail(payload, ' ', this.imagilityAttachment).subscribe(res => {
        console.log('isRead')
      })

      this.isReply = true;
      this.replyForm.patchValue({
        from: this.emailData?.senderEmail,
        priority: this.emailData.isImportant,
        to: ((this.emailData.folder == 'SENT' || this.emailData.folder == 'INBOX' || this.emailData.folder == 'Trash') && this.emailData.taskId) ? this.emailData.recipientEmails : (this.emailData.folder == 'SENT' && !this.emailData.taskId) ? this.emailData.recipientEmails : (this.emailData.folder == 'INBOX' && !this.emailData.taskId) ? this.emailData.recipientEmails : this.emailData.folder == 'DRAFT' ? this.emailData.recipientEmails : [this.emailData.senderEmail],
        cc: this.emailData.ccEmail,
        subject: this.emailData.taskId ? 'Re: ' + this.emailData.subject : this.emailData.subject,
        editor: this.emailData.body
      });

      this.fromEmail = this.emailData?.senderEmail;

     

      if (this.emailData?.attachments && this.emailData?.attachments.length > 0) {
        this.imagilityAttachment = this.emailData?.attachments
        this.uploadedFiles$ = of(this.imagilityAttachment);
      }

      // Red error body
     

    }

    

  }

 


  confirmCategorySelection(){
    
    this.isSelectCategory = true;
    if(this.currentSelection == undefined){
      this.currentSelection = 'GENERIC';
    }
    this.getTemplateList();
  }

  handleCloseCategory(){
    this.categoryDialog = false;
    this.activeModal.close();
  }




  templateOnChange(event){
    if(this.unifiedTaskId){
      this.currentCompanyId = this.unifiedTaskId;

      var x = this.currentSelection;
      
      if(this.currentSelection != 'GENERIC'){
        this.currentSelection= 'PETITION';
      }   
    }

    
    this.petitionerService.getEmailTemplateByIdBenId(Number(event.value), Number(this.currentCompanyId), this.currentSelection).subscribe(res => {

      if(res){
        this.replyForm.patchValue({
          from: this.fromEmail,
          priority: '',
          cc: '',
          subject: res?.contentMap?.header,
          editor:  res?.contentMap?.body + '<br>' +  res?.contentMap?.footer
        });
      }

      var split  = this.commonkendoeditor.value.split("<p>");
     
      for(var i=0; i<split.length; i++){
        if(split[i].includes('${')){
          split[i] = "<p style='color:red'>"+split[i];
        }
        else{
          split[i] = "<p>"+split[i];
        }
      }

      this.commonkendoeditor.value = split.join('');

    })
  }


  async getPDFFileObject(pdfUrl, fileName) {
    try {
      const response = await fetch(pdfUrl);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const pdfBlob = await response.blob();
      const pdfFile = new File([pdfBlob], fileName, { type: 'application/pdf' });
      return pdfFile;
    } catch (error) {
      console.error('Error creating PDF file object:', error);
      return null;
    }
  }




  openFile(file) {
    
    window.open(environment.docs + file.fileLocation.replace('/var/imagility/uploads/', ''), '_blank');
  }


  getTemplateList() {
    const payload = {
      "superTaskTypeId": "EMALTEMPLT",
      "isDefault": "false",
      "pageNumber": 1,
      "pageSize": 1000,
      "searchText": ""
    }
    this.templateService.getEmailTemplateList(payload, this.categoryPlaceholder).subscribe(data => {
      

      data['documentTemplate'].forEach(element => {
        element.documentType = element.documentType.code;
        element.templateType = element.templateType.code;
        this.templateList.push(element);
        
       
      }, error => {
        console.log(error.message.message);
      });

      this.templateList.unshift({id:0, templateName:'Select Template'});

      this.templateListModel = this.templateList[0].id;

    });
  }


  navigate() {
    if (sessionStorage.getItem('userTypeRole') === 'Attorney') {
      window.open(window.location.origin + '/attorney-landing/template/template-form', '_blank');
    }
    else if (sessionStorage.getItem('userTypeRole') === 'Petitioner') {
      window.open(window.location.origin + '/petitioner-landing/template/template-form', '_blank');
    }
  }

  onSelectEmailTemplate(event) {
    console.log(event.value);
    this.templateService.fetchEmailTemplate(event.value).subscribe((res: any) => {
      if (res) {
        this.replyForm.controls['to'].setValue(res.communicationDTO?.toMail);
        this.replyForm.controls['subject'].setValue(res?.contentMap?.header);
        this.replyForm.controls['editor'].setValue(res?.contentMap?.body + '\n' + res.contentMap?.footer);
      }
    });
  }

  validateEmailAddress(event, item) {
    let inValidEmail = false;
  }

  getEmailDetails(mailId: string) {
    this.getEmailDetailsSub = this.emailClientService.getMessageDetails(mailId).pipe().subscribe(emailData => {
      this.setFormData(emailData);
      this.subjectId = '[3126IMAGILITY]';
    })
  }

  setFormData(emailData: any) {
    let toEmail = '';
    let ccEmail = '';
    let subject = '';
    this.replyType = this.emailClientService.selectedReplyType;
    if (this.replyType == 'replyAll') {
      let emailToAppend = '';
      toEmail = this.combineEmails(emailData.toRecipients);
      if (emailData.From && toEmail) {
        emailToAppend = toEmail.includes(emailData.From)
          ? toEmail
          : toEmail + "," + emailData.From;
        toEmail = emailToAppend;
      }
      ccEmail = this.combineEmails(emailData.ccRecipients);
      subject = "RE: " + emailData?.subject;
    }
    else if (this.replyType == 'reply') {
      toEmail = emailData?.from?.emailAddress?.address;
      ccEmail = '';
      subject = "RE: " + emailData?.subject;
    }
    else if (this.replyType == 'forward') {
      toEmail = '';
      ccEmail = '';
      subject = "FW: " + emailData?.subject;
    }
    this.setAttachments(emailData.id);
    this.replyForm.patchValue({
      from: this.emailClientService.selectedUsername,
      to: toEmail,
      cc: ccEmail,
      subject: subject,
      priority: emailData.importance,
      editor: emailData?.body?.content
    });
    this.replyForm.markAsTouched();
  }

  setAttachments(id: string) {
    this.emailClientService.getEmailAttachments(id).subscribe(attachments => {
      for (let attachment of attachments.value) {
        this.attachFileList.push(
          {
            "@odata.type": "#microsoft.graph.fileAttachment",
            "name": attachment['name'],
            "contentType": attachment['contentType'],
            "contentBytes": attachment['contentBytes']
          }
        )
      }
      this.uploadedFiles$ = of(this.attachFileList);
    })
  }

  combineEmails(emailAddress: any): any {
    let emailArr = [];
    for (let email of emailAddress) {
      emailArr.push(email.emailAddress.address);
    }
    return emailArr;
  }


  deleteFile(index: number) {
    this.uploadedFiles$.subscribe(attachmentData => {
      attachmentData.splice(index, 1);
      this.uploadedFiles$ = of(attachmentData);
      this.attachFileList = attachmentData;
    })
  }
 
  onEditSend() {
    if (this.isReply) {
      this.isReply = false;
      if(this.currentSelected == 'INBOX'){
        
        var toString = this.emailData.recipientEmails.toString();
        this.replyForm.controls['to'].setValue([this.emailData.senderEmail]);
        this.replyForm.controls['from'].setValue(toString);       
        this.fromEmail = toString;
      }

      if(this.currentSelected != 'DRAFT'){
        this.imagilityAttachment = [];
        this.uploadedFiles$ = of(this.imagilityAttachment);
      }
      
      this.isemailActivated = false;
    }
    this.ref.detectChanges();
  }

  onSend(folder) {

    
    if( this.commonkendoeditor.value.includes('${') && this.emailData){
      this.placeholderErrorDialog = true;


      var split  = this.emailData.body.split("<p>");
     
      for(var i=0; i<split.length; i++){
        if(split[i].includes('${')){
          split[i] = "<p style='color:red'>"+split[i];
        }
        else{
          split[i] = "<p>"+split[i];
        }
      }      

        this.commonkendoeditor.value = split.join('');


      return false
    }
    
    if( this.commonkendoeditor.value.includes('${') && folder != 'DRAFT'){
      this.placeholderErrorDialog = true;
      return false
    }

    this.submitted = true;
    if (this.replyForm.valid) {
      let composeFormData = this.replyForm.value;
      this.emailClientService.selectedReplyType = '';
      let toEmails = this.replyForm.value.to;
      let ccEmails = this.replyForm.value.cc ? this.replyForm.value.cc : '';
      let ccEmailArr = ccEmails ? this.commonEmailBuild(ccEmails) : [];
      let toEmailArr = this.commonEmailBuild(toEmails);

      if (this.replyForm.controls.to.value == this.replyForm.controls.cc.value) {
        this.toastr.error('Email To and CC can not be the same.');
        return
      }
      else {

        let payload = {
          recipientEmails: toEmailArr,
          loginId: this.userId,
          taskId: this.unifiedTaskId,
          folder: folder,
          senderEmail: this.fromEmail,
          subject: this.replyForm.value.subject,
          ccEmail: ccEmailArr,
          bccEmails: [],
          isRead: false,
          isImportant: false,
        }

        if (this.emailData) {
          this.ifEmailData = this.emailData.folder;

          if (this.emailData.folder == 'DRAFT') {
            
            payload['threadIds'] = [this.emailData.id];
          
            
          }

          if (this.isReply) {
            payload['threadIds'] = [this.emailData.id];
          }
        }

        body: this.replyForm.value.editor,

        

        this.attorService.saveUnifiedEmail(payload, this.replyForm.value.editor, this.imagilityAttachment).subscribe(res => {
          let _data = res;
          if (payload.folder == 'DRAFT') {
            this.toastr.success('Mail saved to draft successfully')
          }
          else {
            this.toastr.success('Mail Sent Successfully')
          }

          this.activeModal.close({ data: 'close' })
        })
        this.activeModal.close({ data: 'close' })

      }



    }


  }

  commonEmailBuild(emails: string[]) {
    const emailArr = [];
    for (let email of emails) {
      emailArr.push(email);
    }
    return emailArr;
  }


  onCancel() {
    this.discardDialog = true;
    // this.activeModal.close({ data: 'close' })
  }

  discardDialogBox(){
    this.discardDialog = true;
    // this.activeModal.close({ data: 'close' })
  }


  Upload(event, fileUpload) {
    
    fileUpload.clear();


    if (this.emailData && this.currentSelected == 'DRAFT') {
      var tempArray = [];
      if(this.emailData?.attachments){
        for (let i = 0; i < this.emailData?.attachments.length; i++) {

          if(this.emailData?.attachments[i].fileLocation){
            let url = environment.docs + this.emailData?.attachments[i].fileLocation.replace('/var/imagility/uploads/', '');

           this.getPDFFileObject(url, this.emailData?.attachments[i].name)
              .then((pdfFile)  => {
                
                if (pdfFile) {
                  tempArray.push(pdfFile) 
                }
              });;
          }
          else{
            tempArray.push(this.emailData?.attachments[i]);
          }
        }  
        this.imagilityAttachment = tempArray;
      }
    }

    for (var i = 0; i < event.files.length; i++) {
      this.imagilityAttachment.push(event.files[i]);
    }


    this.attachFileList = this.imagilityAttachment;
    this.uploadedFiles$ = of(this.attachFileList);


  }

  discard() {
    this.replyForm.patchValue({
      to: '',
      priority: 'normal',
      cc: '',
      subject: '',
      editor: " "
    });
    // this.commonkendoeditor.value = "";
    this.replyForm.controls['editor'].setValue('');
    this.uploadedFiles$ = of([]);
    this.discardDialog = false;
    this.replyForm.markAsUntouched();
    this.emailClientService.folderChangeSubject$.next(true);

    // this.modalService.dismissAll({data:{'null'});
    this.activeModal.close({ data: 'close' })
  }


  commonkendoeditorimageuploaddialog() {
    this.commonkendoeditorupload.open();
  }

  checkduplicateEmail(): ValidatorFn {
    return (group: FormGroup): { [s: string]: boolean } => {
      if (this.replyForm) {
        const to = this.replyForm.get('to').value
        const cc = this.replyForm.get('cc').value
        if (to != null && cc != null) {
          if (to && cc) {
            let toArr = to;//.split(",")
            for (let toEmail of toArr) {
              if (cc.includes(toEmail)) {
                return { duplicateEmail: true };
              }
            }
          }
        }
      }
      return null;
    };
  }


  onChangeCategory(event){
    
    let companyId = sessionStorage.getItem('companyId');   

    this.currentSelection = event?.value?.name;

    let userRole = sessionStorage.getItem('userTypeRole');

    debugger
    if(event.value.name == 'INDIVIDUAL'){
      this.petitionerService.getBeneficiariesListForIndividual(companyId).subscribe(res => {        
          this.isbenActive = true;
          this.ispetActive = false;
          this.beneficiaryListData = res?.beneficiaryList;
          this.benPlaceholder = res?.beneficiaryList[0].beneficiaryEmployeeId;
          this.currentCompanyId = this.benPlaceholder;          
      })
    }


    else if(event.value.name == 'BENEFICIARY'){
      this.petitionerService.getBeneficiariesListForPetitioner(companyId).subscribe(res => {        
          this.isbenActive = true;
          this.ispetActive = false;
          this.beneficiaryListData = res?.beneficiaryList;
          this.benPlaceholder = res?.beneficiaryList[0].beneficiaryEmployeeId;
          this.currentCompanyId = this.benPlaceholder;          
      })
    }

    else if(event.value.name == 'PETITIONER' && userRole == 'Petitioner'){
     
      this.petitionerService.getListForPetitioner(companyId).subscribe(res => {        
          this.isbenActive = false;
          this.ispetActive = true;
          debugger
          this.petetionerListData = res;
          this.petPlaceholder = res[0].id;
          this.currentCompanyId = this.petPlaceholder;         
      })
    }

    else if(event.value.name == 'PETITIONER' && userRole == 'Attorney'){
      debugger
      this.petitionerService.getListForAttorney(companyId).subscribe(res => {        
          this.isbenActive = false;
          this.ispetActive = true;
          this.petetionerListData = res?.petetionerList;
          this.petPlaceholder = res?.petetionerList[0].attorneyId;
          this.currentCompanyId = this.petPlaceholder;   
          debugger      
      })
    }

    else{
      this.isbenActive = false;
      this.ispetActive = false;
    }

   
  }

  onChangeAttorney(event){
    this.currentCompanyId = this.petPlaceholder;   
  }


  onChangeBeneficiary(event){
    this.currentCompanyId = this.benPlaceholder;  
  }



  ngOnDestroy() {
    if (this.getEmailDetailsSub)
      this.getEmailDetailsSub.unsubscribe();
  }

}