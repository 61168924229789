import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as RolesRouteMap from '../../../data/constants/roles';
import { ReferenceLibraryService } from 'src/app/services/referenceLibrary.service';
import { ReferenceLibrary } from 'src/app/data/models/referenceLibrary.model';
import { debounceTime, filter, map, take, takeUntil } from 'rxjs/operators';
import { CaseStatusService } from '../services/case-status.service';
import { AttorneyService } from '../../../services/attorney.service';
import { ApiServicesService } from '../../beneficiary/services/api-services.service';
import { combineLatest, fromEvent, Subject, Subscription } from 'rxjs';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import * as errorUtils from "src/app/modules/utility/global-utils";
import { Store } from '@ngrx/store';
import * as Task from 'src/app/modules/superTask/task-state/actions/task.actions';

@Component({
  selector: 'app-add-new-case',
  templateUrl: './add-new-case.component.html',
  styleUrls: ['./add-new-case.component.scss']
})


export class AddNewCaseComponent implements OnInit {
  subTaskTypes:any;
  newcaseForm: FormGroup;
  observableSubscription$ = new Subject();
  taskType: ReferenceLibrary[];
  beneFiciaries: ReferenceLibrary[];
  petitionTypes: ReferenceLibrary[];
  visaTypes: ReferenceLibrary[];
  immCategoryTypes: ReferenceLibrary[];
  immClassificationTypes: ReferenceLibrary[];
  processTypes: any[] = [{ name: 'Regular', code: 'REGULAR' }, { name: 'Premium', code: 'PREMIUM' }];
  serviceCenters: any[];
  totalElementCount = 0;
  pageSizeOptions = [25, 50, 100];
  pageIndex = 1; // default
  pageSize = 25;
  beneficiaryId: number;
  receiptNumber: string;
  companyId: any;
  listOfClientCompanies: any = []
  selectedCompanyId: 0;
  userTypeRole: any;
  clientId: number;
  receiptNum: string;
  editCaseData:any;
  currentCaseId:any;
  processType: string = "REGULAR";
  receiptNumberRegexp = new RegExp('^[A-Za-z]{3}.[0-9]{9}$');
  constructor(private router: Router,
    private referenceLibraryService: ReferenceLibraryService,
    private APIService: CaseStatusService,
    private apiService: AttorneyService,
    private beneService: ApiServicesService,
    private fb: FormBuilder,
    private toaster: ToastrService,
    private Ref: ChangeDetectorRef,
    private activeRoute: ActivatedRoute,
    protected store: Store<any>
  ) {
    this.companyId = parseInt(sessionStorage.getItem('companyId'));
    this.userTypeRole = sessionStorage.getItem('userTypeRole');

    this.newcaseForm = this.fb.group({
      beneficiaryId: [null, Validators.required],
      companyId: [null, Validators.required],
      id: 0,
      petitionType: [null, Validators.required],
      processType: [null, Validators.required],
      receiptDate: "",
      receiptNumber: [null, Validators.required],
      serviceCenterId: [null, Validators.required],
      superTaskType: [null, Validators.required],
      subTaskType: [null, Validators.required],
      uiscisCaseStatus: null,
      visaType: [null, Validators.required],
      immCategoryCode: [null, Validators.required],
      immClassificationCode: [null, Validators.required],
    });
    if(this.userTypeRole === 'Petitioner'){
      this.newcaseForm.get('companyId').setValue(this.companyId);
    }
    this.currentCaseId = this.activeRoute.snapshot.paramMap.get('id');
    if(this.currentCaseId !== null){
      this.getEditData(this.currentCaseId);
    }  

  }


  ngOnInit(): void {
    if(this.currentCaseId == null){
      this.loadInitialData();
    }
  }

  loadInitialData(loadAttroneyBen: boolean = false){
    this.getTaskType();
    this.getClientsList();
    if(loadAttroneyBen && this.userTypeRole === 'Attorney'){
      this.loadAttorney();
    }
    if(this.userTypeRole === 'Petitioner'){
      this.clientId = this.companyId;
      this.loadBenficiaries();
    }
    //this.getServiceCenters(this.processType);
  }

  getEditData(caseId){
      this.APIService.caseEdit(caseId).pipe(takeUntil(this.observableSubscription$)).subscribe((response) => {
        this.editCaseData= response;
        this.newcaseForm.get("receiptNumber").setValue(this.editCaseData.receiptNumber);
        if(this.editCaseData.shortStatus){
          this.newcaseForm.get("receiptNumber").disable();
        }else{
          this.newcaseForm.get("receiptNumber").enable();
        }
        // GET PROCESS TYPE
        let getIndex = this.processTypes.map((item) => {
            return item.name;
        }).indexOf(this.editCaseData.processType);
        this.newcaseForm.get("processType").setValue(this.processTypes[getIndex].code);
        this.processType = this.processTypes[getIndex].code;
        this.getServiceCenters(this.processType);
        if(this.editCaseData && this.editCaseData.taskId){
          this.newcaseForm.disable();
          this.getSubTaskDetails();
        }
        let loadAttBen = false;
        if(this.editCaseData && this.editCaseData.companyId === this.companyId && this.userTypeRole === 'Attorney'){
          loadAttBen = true;
        }
        this.loadInitialData(loadAttBen);
      });
  }


  setValidatorForNONIMMPET() {
    if (this.newcaseForm.get('superTaskType').value === 'NONIMMPET') {
      this.newcaseForm.get('visaType').setValidators(Validators.required);
      this.newcaseForm.get('petitionType').setValidators(Validators.required);
    } else {
      this.newcaseForm.get('immCategoryCode').clearValidators();
      this.newcaseForm.get('immClassificationCode').clearValidators();     
    }
  }

  setValidatorForIMMPET() {
    if (this.newcaseForm.get('superTaskType').value === 'IMMPET') {
      this.newcaseForm.get('immCategoryCode').setValidators(Validators.required);
      this.newcaseForm.get('immClassificationCode').setValidators(Validators.required);
    }
    else {
      this.newcaseForm.get('visaType').clearValidators();
      this.newcaseForm.get('petitionType').clearValidators();
    }
  }

  updateValidators(){
    if (this.newcaseForm.get('superTaskType').value === 'NONIMMPET') {
      this.newcaseForm.get('visaType').setValidators(Validators.required);
      this.newcaseForm.get('petitionType').setValidators(Validators.required);
      this.newcaseForm.get('subTaskType').clearValidators();
      this.newcaseForm.get('subTaskType').setValue(null);
      this.newcaseForm.get('immCategoryCode').clearValidators();
      this.newcaseForm.get('immCategoryCode').setValue(null);
      this.newcaseForm.get('immClassificationCode').clearValidators();
      this.newcaseForm.get('immClassificationCode').setValue(null);
    } else {
      this.newcaseForm.get('immCategoryCode').setValidators(Validators.required);
      this.newcaseForm.get('immClassificationCode').setValidators(Validators.required);
      this.newcaseForm.get('subTaskType').setValidators(Validators.required);
      this.newcaseForm.get('visaType').clearValidators();
      this.newcaseForm.get('visaType').setValue(null);
      this.newcaseForm.get('petitionType').clearValidators();
      this.newcaseForm.get('petitionType').setValue(null);
    }
    Object.keys(this.newcaseForm.controls).forEach(key => {
      this.newcaseForm.controls[key].updateValueAndValidity();
    });
  }

  onVisaTypeChange() {

  }
  getTaskType() {
    this.referenceLibraryService.getReferenceData('SUPTSKTYP')
      .pipe(take(1), takeUntil(this.observableSubscription$))
      .subscribe((response: ReferenceLibrary[]) => {
        this.taskType = response;
        if(this.editCaseData && this.editCaseData.superTaskType){
          this.newcaseForm.get("superTaskType").setValue(this.editCaseData.superTaskType);
          this.onTaskTypeChange();
        }
      });
  }

  onTaskTypeChange() {
    this.updateValidators();
    if (this.newcaseForm.get('superTaskType').value === 'NONIMMPET') {
      this.getVisaTypes();
    } else {
      this.getIMMCategory();
    }
    this.Ref.detectChanges();
  }
  getVisaTypes() {
    if (!this.visaTypes) {
      this.referenceLibraryService.getReferenceData('VISATYP')
        .pipe(take(1), takeUntil(this.observableSubscription$))
        .subscribe((response: ReferenceLibrary[]) => {
          this.visaTypes = [...new Map(response.map(item =>
            [item['code'], item])).values()];
          if(this.editCaseData && this.editCaseData.visaType){
            this.newcaseForm.get("visaType").setValue(this.editCaseData.visaType);
          }
          this.getPetitionType();
        });
    } else {
      if(this.editCaseData && this.editCaseData.visaType){
        this.newcaseForm.get("visaType").setValue(this.editCaseData.visaType);
      }
      this.getPetitionType();
    }

    
  }
  getIMMCategory() {
    this.immCategoryTypes = [];
    this.referenceLibraryService.getReferenceData('GCIMMCATG')
      .pipe(take(1), takeUntil(this.observableSubscription$))
      .subscribe((response: ReferenceLibrary[]) => {
      this.immCategoryTypes = response;
    
      if(this.currentCaseId !== null && this.editCaseData.immCategoryType && this.newcaseForm.get('superTaskType').value == 'IMMPET'){
        this.newcaseForm.get("immCategoryCode").setValue(this.editCaseData.immCategoryType);
        this.getIMMClassification();
      }
    });
    // if (!this.immCategoryTypes) {
    //   this.referenceLibraryService.getReferenceData('GCIMMCATG')
    //     .pipe(take(1))
    //     .subscribe((response: ReferenceLibrary[]) => {
    //       this.immCategoryTypes = response;
        
    //       if(this.currentCaseId !== null && this.editCaseData.immCategoryType && this.newcaseForm.get('superTaskType').value == 'IMMPET'){
    //         this.newcaseForm.get("immCategoryCode").setValue(this.editCaseData.immCategoryType);
    //       }
    //       this.getIMMClassification();
    //     });
    // } else {
    //   if(this.currentCaseId !== null && this.editCaseData.immCategoryType && this.newcaseForm.get('superTaskType').value == 'IMMPET'){
    //     this.newcaseForm.get("immCategoryCode").setValue(this.editCaseData.immCategoryType);
    //   }
    //   this.getIMMClassification();
    // }
  }

  onIMMCategory(){
    this.getIMMClassification();
  }

  getIMMClassification() {
    let categoryCode = this.newcaseForm.get("immCategoryCode").value;
    this.referenceLibraryService.getReferenceData(categoryCode).pipe(take(1)).subscribe((response: any[]) => {
        this.immClassificationTypes = response;
        if(this.currentCaseId !== null && this.editCaseData.immClassificationType && this.newcaseForm.get('superTaskType').value == 'IMMPET'){
          this.newcaseForm.get('immClassificationCode').setValue(this.editCaseData.immClassificationType);
          this.getSubTaskTypes();
        }
    });
    // if (!this.immClassificationTypes && this.immCategoryTypes) {
    //   const req = [];
    //   this.immClassificationTypes = [];
    //   this.immCategoryTypes.forEach(immCategory => {
    //     req.push(this.referenceLibraryService.getReferenceData(immCategory.code));
    //   });
    //   combineLatest(req)
    //     .pipe(take(1))
    //     .subscribe((response: any[]) => {
    //       response.forEach((res, k) => {
    //         this.immClassificationTypes = [...this.immClassificationTypes, ...res];
    //         if(this.currentCaseId !== null && this.editCaseData.immClassificationType && this.newcaseForm.get('superTaskType').value == 'IMMPET'){
    //           this.newcaseForm.get('immClassificationCode').setValue(this.editCaseData.immClassificationType);
    //         }
    //       });
    //     });
    // } else {
    //   if(this.currentCaseId !== null && this.editCaseData.immClassificationType){
    //     this.newcaseForm.get('immClassificationCode').setValue(this.editCaseData.immClassificationType);
    //   }
    // }
  }

  onIMMClassificationChange(){
    this.getSubTaskTypes();
  }

  getSubTaskTypes(){
    const immCategoryCode = this.newcaseForm.get('immCategoryCode').value;
    const immClassificationCode = this.newcaseForm.get('immClassificationCode').value;   
    this.subTaskTypes = [];
    this.APIService.getSubTasksTypeListImmigrant(immCategoryCode, immClassificationCode)
        .pipe(takeUntil(this.observableSubscription$))
        .subscribe((result) => {
        if (result) {
          this.subTaskTypes = result['subTaskTypeList'];
          if(this.currentCaseId !== null && this.editCaseData.subTaskType){
            this.newcaseForm.get("subTaskType").setValue(this.editCaseData.subTaskType);
          }
        }
    });
  }

  getPetitionType() {
    this.referenceLibraryService.getReferenceData('PETTYP')
      .pipe(take(1), takeUntil(this.observableSubscription$))
      .subscribe((response: ReferenceLibrary[]) => {
        this.petitionTypes = response;
        // second
        if(this.currentCaseId !== null && this.editCaseData.petitionType){
          this.newcaseForm.get("petitionType").setValue(this.editCaseData.petitionType);
        }
      });
  }

  getServiceCenters(processType) {
    let flag = processType == 'PREMIUM' ? 'true' : 'false';
    this.APIService.getServiceCenters(flag).pipe(take(1), takeUntil(this.observableSubscription$)).subscribe((response) => {
      this.serviceCenters = response;
      if(this.currentCaseId !== null && this.editCaseData.serviceCenterId){
        this.newcaseForm.get("serviceCenterId").setValue(this.editCaseData.serviceCenterId);
      }
    });
  }

  getClientsList() {
    this.apiService.getClientsList(parseInt(sessionStorage.getItem('companyId')), {
      searchText: '',
      searchCriteria: '',
      sortBy: '',
      sortOrder: '',
      pageNumber: 0,
      recordsPerPage: 0
    })
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe((response: any) => {
        this.clientId = this.editCaseData ? this.editCaseData.companyId : null;
        this.listOfClientCompanies = [...response['petetionerList'], {
          petitionerId: 0,
          petitionerName: 'Individual/Sponsor'
        }];
        if(this.userTypeRole === 'Attorney'){
          if(this.editCaseData  && this.editCaseData.companyId != this.companyId){
            this.newcaseForm.get("companyId").setValue(this.editCaseData.companyId);
            this.loadBenficiaries();
          }else if(this.editCaseData){
            this.newcaseForm.get("companyId").setValue(0);
          }else{
            this.newcaseForm.get("companyId").setValue(null);
          }
        }else{
          if(this.editCaseData && this.editCaseData.companyId){
            this.newcaseForm.get("companyId").setValue(this.editCaseData.companyId);
            this.loadBenficiaries();
          }
        }
       });
  }

  handleCompanyChange(ev) {
    if (ev.value) {
      this.clientId = ev.value;
      this.loadBenficiaries();
    }else {
      this.loadAttorney();
    }
  }
  loadBenficiaries() {
    const payload = {
      pageNumber: this.pageIndex,
      recordsPerPage: this.pageSize,
      searchCriteria: '',
      searchText: '',
      status: ['ACTIVE'],
      sortBy: 'beneficiaryName',
      sortOrder: 'asc'
    };
    this.beneService.getBeneficiariesListForPetitioner(this.clientId, payload)
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe((result) => {
        if (result) {
          this.beneFiciaries = result.beneficiaryList.map((bene)=>{
            bene['beneficiaryEmployeeFullName'] = `${bene.beneficiaryEmployeeFName} ${bene.beneficiaryEmployeeMName} ${bene.beneficiaryEmployeeLName}`;
            return bene;
          });
          if(this.currentCaseId !== null && this.editCaseData.beneficiaryId){
            this.newcaseForm.get("beneficiaryId").setValue(this.editCaseData.beneficiaryId);
          }
        }
      });
  }

  loadAttorney() {
    const payload = {
      pageNumber: this.pageIndex,
      recordsPerPage: this.pageSize,
      searchCriteria: '',
      searchText: '',
      status: ['ACTIVE'],
      sortBy: 'beneficiaryName',
      sortOrder: 'asc'
    };
    this.beneService.getBeneficiariesListForAttorney(parseInt(sessionStorage.getItem('companyId')), payload)
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe((result) => {
        if (result) {
          this.beneFiciaries = result.beneficiaryList.map((bene)=>{
            bene['beneficiaryEmployeeFullName'] = `${bene.beneficiaryEmployeeFName} ${bene.beneficiaryEmployeeMName} ${bene.beneficiaryEmployeeLName}`;
            return bene;
          });;
          if(this.currentCaseId !== null && this.editCaseData.beneficiaryId){
            this.newcaseForm.get("beneficiaryId").setValue(this.editCaseData.beneficiaryId);
          }
        }
      });
  }
  saveCase() {
    let payload =
    {
      beneficiaryId: this.newcaseForm.get('beneficiaryId').value,
      companyId: 0,
      attorneyId:0,
      id: this.currentCaseId ? parseInt(this.currentCaseId) : 0,
      petitionType: this.newcaseForm.get('petitionType').value,
      processType: this.newcaseForm.get('processType').value,
      receiptDate: null,
      receiptNumber: this.newcaseForm.get('receiptNumber').value,
      serviceCenterId: this.newcaseForm.get('serviceCenterId').value,
      taskType: this.newcaseForm.get('superTaskType').value,
      subTaskType: this.newcaseForm.get('subTaskType').value,
      uiscisCaseStatus: null,
      immCategoryCode: this.newcaseForm.get('immCategoryCode').value,
      immClassificationCode: this.newcaseForm.get('immClassificationCode').value,
      visaType: this.newcaseForm.get('visaType').value
    }
    if(this.userTypeRole === 'Petitioner'){
      payload.companyId = this.newcaseForm.get('companyId').value;
      payload.attorneyId = 0;
    }
    if(this.userTypeRole === 'Attorney'){
      payload.companyId = this.newcaseForm.get('companyId').value;
      payload.attorneyId = this.companyId;
    }
    this.APIService.addNewCase(payload).subscribe(res =>{
      this.toaster.success(res.message, 'Success');
      this.router.navigate([`../../${RolesRouteMap.userRoleRouteMapping[sessionStorage.getItem('userTypeRole')]}/case-status/manage-case-status`]);
    })

  }

  onKey(event: KeyboardEvent) { // with type info
    this.receiptNum = ((<HTMLInputElement>event.target).value)
    this.receiptNumber = this.receiptNum.replace(/-/g, "");
  }

  cancelHandler(){
    //this.router.navigate(["manage-case-status"]);
    this.router.navigate([`../../${RolesRouteMap.userRoleRouteMapping[sessionStorage.getItem('userTypeRole')]}/case-status/manage-case-status`]);
  }


  getSubTaskDetails(){
    if(this.editCaseData && this.editCaseData.taskId){
      this.APIService.getTask(this.editCaseData.taskId).pipe(takeUntil(this.observableSubscription$))
      .subscribe((result) => {
        if(result && result.superTaskId){
          this.editCaseData = {
            ...this.editCaseData,
            superTaskId: result.superTaskId
          }
        }
      });
    }
  }

  goToTask(){
    if(this.editCaseData && this.editCaseData.superTaskId){
      //redirect to task page
      this.store.dispatch(new Task.SetTaskRouteId(this.editCaseData.superTaskId));
      this.router.navigate([`${RolesRouteMap.userRoleRouteMapping[sessionStorage.getItem('userTypeRole')]}/dashboard/superTask/task-details/summary`]);
    }
  }

  breadCrumbNavigation() {
    this.router.navigate([`../../${RolesRouteMap.userRoleRouteMapping[sessionStorage.getItem('userTypeRole')]}`]);
  }

  getGlobalErrorMessages(error: string) {
    return errorUtils.errorMessages.get(error);
  }
}
