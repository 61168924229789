<div class="container-fluid">
  <div class="row d-flex align-items-center">
    <div class="col-md-7 step-title">
      <h5 class="mb-0 step-tab-header common-bene-class-for-translation">Immigration Details</h5>      
      <p class="mb-0 step-tab-sub-header common-bene-class-for-translation">Data fetched from the Beneficiary's profile.</p>
    </div>
    <div class="col-md-5 align-top">  
      <div class="d-flex justify-content-end">       
        <button button pButton class="p-button-outlined  btnData" [pTooltip]="isEditImmigration ? 'Close' : 'Edit'"
          (click)="updateImmigration()">
          <mat-icon class="mr-1 icn">{{ isEditImmigration ? 'cancel' : 'edit' }}</mat-icon>
          <span class="common-bene-class-for-translation">{{ isEditImmigration ? 'Close' : 'Edit' }}</span>
        </button>
      </div>
    </div>
  </div>
  <p-divider class="tab-header-divider"></p-divider>

  <div class="row" *ngIf="isEditImmigration">
    <!-- <div class="col-12 text-right mb-3" >
      <mat-button-toggle-group [value]="selectedState" (change)="onChange($event)">
          <mat-button-toggle value="simple">Simple Profile</mat-button-toggle>
          <mat-button-toggle value="complete">Complete Profile</mat-button-toggle>
      </mat-button-toggle-group>
    </div> -->
    <div class="col-12">
      <lib-generic-beneficiary-immigration-details-library [inputData]="primaryData"></lib-generic-beneficiary-immigration-details-library>
    </div>
  </div>
  <!-- <div *ngIf="isEditImmigration">
    <ng-container *ngIf="selectedState === 'simple'">
      <simple-immigration-detail-library [primaryData]="primaryData"></simple-immigration-detail-library>
    </ng-container>
    <ng-container *ngIf="selectedState === 'complete'">
      <beneficiary-documents-edit-library [inputData]="primaryData"></beneficiary-documents-edit-library>
    </ng-container>
    
  </div> -->


  <div *ngIf="!isEditImmigration">
      <div class="main-body">
        <div class="row pt-3">
          <div class="col-9 mb-2">
            <label class="step-tab-label required common-bene-class-for-translation">Was the beneficiary EVER in the United States?</label>
            <div class="step-tab-label-value common-bene-class-for-translation">{{beneficiaryPersonalDetails?.hasBeenTravelledToUs ? 'Yes' : 'No' }}</div>
          </div>
        </div>
        <!-- <div class="row">
            <div class="col-9 mb-2">
              <p-radioButton name="groupname" [disabled]="true" [(ngModel)]="beneficiaryPersonalDetails.hasBeenTravelledToUs" [value]="true"
              class="letter-radio" label="Yes">
              </p-radioButton>
              <p-radioButton name="groupname" [disabled]="true" [(ngModel)]="beneficiaryPersonalDetails.hasBeenTravelledToUs" [value]="false"  label="No">
              </p-radioButton>
            </div>
          </div> -->
          <!-- <div>  -->
        <div *ngIf="beneficiaryPersonalDetails.hasBeenTravelledToUs">
          <!-- <div class="row pt-3">
            <div class="col-9 mb-2">
              <label class="step-tab-label"><span *ngIf="!isDependentVisa"></span>Non Immigrant Visa Number from Passport (if any)</label>
              <div class="step-tab-label-value">{{beneficiaryPersonalDetails?.passport}}</div>
              <div class="requiredFields"
                *ngIf="beneficiaryPersonalDetails?.passport?.length<=0 && requiredFields?.passport">
                Missing
              </div>
            </div>
          </div> -->
          <div class="row pt-3">
            <div class="col-8 mb-2">
              <label class="step-tab-label required common-bene-class-for-translation">Place of Last Arrival into the United States</label>
              <div class="step-tab-label-value">{{beneficiaryPersonalDetails?.immigrationDetails?.placeOfLastArrival}}</div>
              <div class="requiredFields"
                *ngIf="beneficiaryPersonalDetails?.immigrationDetails?.placeOfLastArrival?.length<=0 && requiredFields?.immigrationDetails?.placeOfLastArrival">
                Missing
              </div>
            </div>
            <div class="col-3 mb-2">
              <label class="step-tab-label required common-bene-class-for-translation">Mode of Travel</label>
              <div class="step-tab-label-value">{{beneficiaryPersonalDetails?.immigrationDetails?.modeOfTravel}}</div>
              <div class="requiredFields"
                *ngIf="beneficiaryPersonalDetails?.immigrationDetails?.modeOfTravel?.length<=0 && requiredFields?.immigrationDetails?.modeOfTravel">
                Missing
              </div>
            </div>
          </div>
          <div class="row pt-3">
            <div class="col-4 mb-2">
              <label class="step-tab-label required common-bene-class-for-translation">Date of last arrival</label>
              <div class="step-tab-label-value">{{beneficiaryPersonalDetails?.immigrationDetails?.arrivalDate ? (beneficiaryPersonalDetails.immigrationDetails.arrivalDate | VisaDateFormat) : ''}}</div>
              <div class="requiredFields"
                *ngIf="beneficiaryPersonalDetails?.immigrationDetails?.arrivalDate?.length<=0 && requiredFields?.immigrationDetails?.arrivalDate">
                Missing
              </div>
            </div>
            <div class="col-4 mb-2">
              <label class="step-tab-label required common-bene-class-for-translation">Admitted as</label>
              <div class="step-tab-label-value">{{beneficiaryPersonalDetails?.immigrationDetails?.admittedAs}}</div>
              <div class="requiredFields"
                *ngIf="beneficiaryPersonalDetails?.immigrationDetails?.admittedAs?.length<=0 && requiredFields?.immigrationDetails?.admittedAs">
                Missing
              </div>
            </div>
            <div class="col-4 mb-2">
              <label class="step-tab-label required common-bene-class-for-translation">Class of Admission</label>
              <div class="step-tab-label-value">{{beneficiaryPersonalDetails?.immigrationDetails?.classOfAdmission}}</div>
              <div class="requiredFields"
                *ngIf="beneficiaryPersonalDetails?.immigrationDetails?.classOfAdmission?.length<=0 && requiredFields?.immigrationDetails?.admittedAs">
                Missing
              </div>
            </div>
          </div>
          <div class="row pt-3">

            <div class="col-8 mb-2">
              <label class="step-tab-label required common-bene-class-for-translation">Form I-94 Arrival-Departure Record Number</label>
              <div class="step-tab-label-value">{{beneficiaryPersonalDetails?.immigrationDetails?.i94Number}}</div>
              <div class="requiredFields"
                *ngIf="beneficiaryPersonalDetails?.immigrationDetails?.i94Number?.length<=0 && requiredFields?.immigrationDetails?.i94Number">
                Missing
              </div>
            </div>
            <div class="col-3 mb-2">
              <label class="step-tab-label required common-bene-class-for-translation">I-94 Expiration Date</label>
              <div class="step-tab-label-value">{{ beneficiaryPersonalDetails?.immigrationDetails?.i94ExpiryDate ? (beneficiaryPersonalDetails.immigrationDetails.i94ExpiryDate | VisaDateFormat) : '' }}</div>
              <div class="requiredFields"
                *ngIf="beneficiaryPersonalDetails?.immigrationDetails?.i94ExpiryDate?.length<=0 && requiredFields?.immigrationDetails?.i94ExpiryDate">
                Missing
              </div>
            </div>
          </div>
        </div>
      </div>
      <p-divider></p-divider>
      <div class="row pt-3">
        <div class="col-md-6">
          <button pButton [disabled]="disabledControls" title="Verify" type="button"  class="save-btn"
            (click)="handleVerify()">
          <span class="common-bene-class-for-translation">VERIFY</span>
          </button>
        </div>
      </div>
  </div>

</div>
