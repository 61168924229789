import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { I9RemediationService } from '../../i9-remediation.service';
import { AuthenticationService } from 'authentication';
import { environment } from 'src/environments/environment';

export interface RemediationRecord {
  noOfIterations: number;
  dateTime: string;
  noOfEmployees: number;
  status: string;
  action: string;
}

export const MOCK_DATA: RemediationRecord[] = [
  {
    noOfIterations: 1,
    dateTime: '2024-07-12T10:00:00',
    noOfEmployees: 25,
    status: 'Completed',
    action: ''
  },
  {
    noOfIterations: 2,
    dateTime: '2024-07-13T14:30:00',
    noOfEmployees: 30,
    status: 'In Progress',
    action: ''
  },
  {
    noOfIterations: 3,
    dateTime: '2024-07-14T09:15:00',
    noOfEmployees: 20,
    status: 'Pending',
    action: ''
  },
  {
    noOfIterations: 4,
    dateTime: '2024-07-15T11:45:00',
    noOfEmployees: 50,
    status: 'Failed',
    action: ''
  },
  {
    noOfIterations: 5,
    dateTime: '2024-07-16T16:00:00',
    noOfEmployees: 40,
    status: 'Completed',
    action: ''
  }
];


@Component({
  selector: 'app-manage-remediations',
  templateUrl: './manage-remediations.component.html',
  styleUrls: ['./manage-remediations.component.scss']
})
export class ManageRemediationsComponent implements OnInit {
  companyId;
  displayedColumns: string[] = ['noOfIterations', 'dateTime', 'noOfEmployees', 'status', 'action'];
  dataSource: MatTableDataSource<any>;

  pageSize = 25; // default
  pageIndex = 0; // default
  searchText = '';
  totalElementCount = 0;
  searchSubscription: Subscription;
  observableSubscription$ = new Subject();


  remediationStatus = '';
  remediationRunStatus = ''
  imgSrc: any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private i9RemediationService: I9RemediationService,
    private authenticationService: AuthenticationService,
  ) { }

  ngOnInit(): void {
    let imageURL = '/assets/online-complaint.jpg';
    let url=environment.appUrl;
    if(url.includes('http://localhost:4200')){
      this.imgSrc = url + imageURL;
    }else{
      url = url;
      this.imgSrc = url + imageURL;
    }
    this.companyId = parseInt(sessionStorage.getItem('companyId'));
    this.getRemediationStatus();
  }

  getRemediationStatus() {
    this.i9RemediationService.getRemediationStatus(this.companyId).subscribe(data => {
      if (data) {
        this.remediationStatus = data.status;
        this.remediationRunStatus = data.remediationRunStatus;
        if (this.remediationRunStatus == 'INITIATED') {
          this.initiateNewRemediation();
        }
        // Enable after landing page table introduced
        // if (this.remediationStatus == 'COMPLETED') {
        //   this.getRemediationData();
        // }
      } else {
        this.remediationStatus = '';
      }
    });

  }

  // getRemediationData() {
  //   this.i9RemediationService.getRemediationData().subscribe(data => {
  //     this.dataSource.data = data;
  //     this.totalElementCount = data.length;
  //   });
  // }
  

  backToDashboard() {
    this.router.navigate(['petitioner-landing/dashboard/petitioner-dashboard']);
  }

  onSearchChange(event){
    console.log(event);
  }
  remediateRecord(row) {
    console.log(row);
  }

  viewErrors(row) {
    console.log(row);
  }

  viewDocuments(row) {
    console.log(row);
  }

  deleteRecord(row) {
    console.log(row);
  }


  paginationEvent(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
  }

  initiateNewRemediation() {
    const queryParamsObj = {}
    this.router.navigate(['../initiate-i9-remediation'], { queryParams: queryParamsObj, relativeTo: this.route });
  }
}
