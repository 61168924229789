<div class="container-fluid">
  <div class="row step-title">
    <div class="col-md-8">
      <h5 class="mb-0 step-tab-header">Personal Details</h5>
      <p class="mb-0 step-tab-sub-header">Data fetched from the Beneficiary's profile.</p>
    </div>
    <div class="clearfix"></div>
  </div>

  <p-divider class="tab-header-divider"></p-divider>

  <div class="col-12 text-right mb-3">
    <mat-button-toggle-group [value]="selectedState" (change)="onChange($event)">
      <mat-button-toggle value="simple">Simple Profile</mat-button-toggle>
      <mat-button-toggle value="complete">Complete Profile</mat-button-toggle>
    </mat-button-toggle-group>
  </div>

  <div class="row">
    <div class="col-md-6">
      <h5 class="labelText">Personal Details</h5>
    </div>
    <div class="col-md-6 form-group pull-right"
      *ngIf="!questionnaire && !isEditMode  && selectedState == 'complete'">
      <button type="button" [disabled]="disabledControls"  class="btn btn-sm btn-outline-primary customEdit float-right" (click)="editDetails()">
        <i class="fa fa-pencil" aria-hidden="true"></i> Edit
      </button>
    </div>
  </div>

  <div *ngIf="isEditMode && type =='family' && selectedState == 'complete'">
    <lib-edit-complete-personal-detail-library (closePersonalEdit)="receiveData($event)"
      [beneImmigrant]="type"></lib-edit-complete-personal-detail-library>
  </div>

  <div *ngIf="isEditMode && type !='family' && selectedState == 'complete'">
    <lib-gci140-edit-personal-detail-library
      (closePersonalEdit)="receiveData($event)"></lib-gci140-edit-personal-detail-library>
  </div>

  <div *ngIf="selectedState == 'simple'">    
    <lib-simple-personal-detail-library [primaryData]="primaryData" [type]="type"></lib-simple-personal-detail-library>
  </div>

  <!-- *ngIf="!isEditMode && selectedState == 'complete'" -->
  <div class="main-body" *ngIf="!isEditMode && selectedState == 'complete'">
    <div class="row row-cols-4 pt-3">
      <div class="col mb-2">
        <label class="step-tab-label required">Title </label>
        <div class="step-tab-label-value">{{beneficiaryPersonalDetails?.title}}</div>
        <div class="requiredFields"
          *ngIf="(!beneficiaryPersonalDetails || beneficiaryPersonalDetails?.title?.length<=0) && requiredFields?.title">
          Missing
        </div>
      </div>
      <div class="col mb-2">
        <label class="step-tab-label required">First Name</label>
        <div class="step-tab-label-value">{{beneficiaryPersonalDetails?.firstName}}</div>
        <div class="requiredFields"
          *ngIf="(!beneficiaryPersonalDetails || beneficiaryPersonalDetails?.firstName?.length<=0) && requiredFields?.firstName">
          Missing</div>
      </div>
      <div class="col mb-2">
        <label class="step-tab-label required">Last Name</label>
        <div class="step-tab-label-value">{{beneficiaryPersonalDetails?.lastName}}</div>
        <div class="requiredFields"
          *ngIf="(!beneficiaryPersonalDetails || beneficiaryPersonalDetails?.lastName?.length<=0) && requiredFields?.lastName">
          Missing</div>
      </div>
      <div class="col mb-2">
        <label class="step-tab-label">Middle Name</label>
        <div class="step-tab-label-value">{{beneficiaryPersonalDetails?.middleName}}</div>
      </div>
      <div class="col mb-2 pt-3">
        <label class="step-tab-label required">Gender</label>
        <div class="step-tab-label-value">{{beneficiaryPersonalDetails?.gender}}</div>
        <div class="requiredFields"
          *ngIf="(!beneficiaryPersonalDetails || beneficiaryPersonalDetails?.gender?.length<=0) && requiredFields?.gender">
          Missing</div>
      </div>
      <div class="col mb-2 pt-3">
        <label class="step-tab-label required">Date of Birth</label>
        <div class="step-tab-label-value">{{beneficiaryPersonalDetails?.dob | VisaDateFormat}}</div>
        <div class="requiredFields"
          *ngIf="(!beneficiaryPersonalDetails || beneficiaryPersonalDetails?.dob?.length<=0) && requiredFields?.dob">
          Missing</div>
      </div>
      <div class="col mb-2 pt-3">
        <label class="step-tab-label required">Mobile No.</label>
        <div class="step-tab-label-value">{{beneficiaryPersonalDetails?.mobileNo}}</div>
        <div class="requiredFields"
          *ngIf="(!beneficiaryPersonalDetails || beneficiaryPersonalDetails?.mobileNo?.length<=0) && requiredFields?.mobileNo">
          Missing</div>
      </div>
      <div class="col mb-2 pt-3">
        <label class="step-tab-label required">Email ID</label>
        <div class="step-tab-label-value">{{beneficiaryPersonalDetails?.emailId}}</div>
        <div class="requiredFields"
          *ngIf="(!beneficiaryPersonalDetails || beneficiaryPersonalDetails?.emailId?.length<=0) && requiredFields?.emailId">
          Missing</div>
      </div>
      <div class="col mb-2 pt-3">
        <label class="step-tab-label">Social Security number</label>
        <div class="step-tab-label-value">{{beneficiaryPersonalDetails?.ssn}}</div>
      </div>
    </div>
    <div class="row col-md-12 pt-4">
      <h5 class="step-tab-form-sub-header">Place of Birth</h5>
    </div>
    <div class="row row-cols-4">
      <div class="col mb-2">
        <label class="step-tab-label required">Country</label>
        <div class="step-tab-label-value">{{beneficiaryPersonalDetails?.birthCountry}}</div>
        <div class="requiredFields"
          *ngIf="(!beneficiaryPersonalDetails || beneficiaryPersonalDetails?.birthCountry?.length<=0) && requiredFields?.birthCountry">
          Missing
        </div>
      </div>
      <div class="col mb-2">
        <label class="step-tab-label required">State</label>
        <div class="step-tab-label-value">{{beneficiaryPersonalDetails?.birthStateProvinceName ? beneficiaryPersonalDetails?.birthStateProvinceName : (beneficiaryPersonalDetails?.birthStateProvinceCode ? getStateName(beneficiaryPersonalDetails?.birthStateProvinceCode,'Birth') : '--')}}</div>
        <div class="requiredFields"
          *ngIf="(!beneficiaryPersonalDetails || (beneficiaryPersonalDetails?.birthStateProvinceName?.length<=0 && beneficiaryPersonalDetails?.birthStateProvinceCode?.length<=0)) && requiredFields?.birthState">
          Missing
        </div>
      </div>
      <div class="col mb-2">
        <label class="step-tab-label required">City</label>
        <div class="step-tab-label-value">{{beneficiaryPersonalDetails?.birthCity}}</div>
        <div class="requiredFields"
          *ngIf="(!beneficiaryPersonalDetails || beneficiaryPersonalDetails?.birthCity?.length<=0) && requiredFields?.birthCity">
          Missing</div>
      </div>
      <div class="col mb-2">
        <label class="step-tab-label required">Citizenship</label>
        <div class="step-tab-label-value">{{beneficiaryPersonalDetails?.citizenShip}}</div>
        <div class="requiredFields"
          *ngIf="(!beneficiaryPersonalDetails || beneficiaryPersonalDetails?.citizenShip?.length<=0) && requiredFields?.citizenShip">
          Missing
        </div>
      </div>
    </div>
    <div>
      <div class="row col-md-12 pt-4">
        <h5 class="step-tab-form-sub-header">Alien Number Details</h5>
      </div>
      <div class="row row-cols-2">
        <div class="col mb-2">
          <label class="step-tab-label">Alien Registration Number (A#)</label>
          <div class="step-tab-label-value">{{beneficiaryPersonalDetails?.alienRegNo}}</div>
          <!-- <div class="requiredFields"
          *ngIf="(!beneficiaryPersonalDetails || (!beneficiaryPersonalDetails.alienRegNo || (beneficiaryPersonalDetails.alienRegNo && beneficiaryPersonalDetails.alienRegNo.length <= 0))) && requiredFields?.alienRegNo">
          Missing
        </div> -->
        </div>
        <div class="col mb-2">
          <label class="step-tab-label">Alien Admission Number (I-94)</label>
          <div class="step-tab-label-value">{{beneficiaryPersonalDetails?.immigrationDetails?.i94Number}}</div>
        </div>
        <div class="col mb-2">
          <label class="step-tab-label">Class of Admission</label>
          <div class="step-tab-label-value">{{beneficiaryPersonalDetails?.immigrationDetails?.classOfAdmission}}</div>
        </div>
      </div>
    </div>
    <div class="row col-md-12 pt-4">
      <h5 class="step-tab-form-sub-header">Physical Address Details</h5>
    </div>
    <div class="row row-cols-4">
      <div class="col mb-2">
        <label class="step-tab-label required">Address Line 1</label>
        <div class="step-tab-label-value">{{beneficiaryPersonalDetails?.benCurrentAddress?.addressLine1}}</div>
        <div class="requiredFields"
          *ngIf="(!beneficiaryPersonalDetails || beneficiaryPersonalDetails?.benCurrentAddress?.addressLine1?.length<=0) && requiredFields?.benCurrentAddress?.addressLine1">
          Missing</div>
      </div>
      <div class="col mb-2">
        <label class="step-tab-label">Address Line 2</label>
        <div class="step-tab-label-value">{{beneficiaryPersonalDetails?.benCurrentAddress?.addressLine2}}</div>
      </div>
      <div class="col mb-2">
        <label class="step-tab-label required">Country</label>
        <div class="step-tab-label-value">{{beneficiaryPersonalDetails?.benCurrentAddress?.countryName}}</div>
        <div class="requiredFields"
          *ngIf="(!beneficiaryPersonalDetails || beneficiaryPersonalDetails?.benCurrentAddress?.countryName?.length<=0) && requiredFields?.benCurrentAddress?.countryName">
          Missing</div>
      </div>
      <div class="col mb-2">
        <label class="step-tab-label required">State</label>
        <div class="step-tab-label-value">{{beneficiaryPersonalDetails?.benCurrentAddress?.stateProvinceName ? beneficiaryPersonalDetails?.benCurrentAddress?.stateProvinceName : (beneficiaryPersonalDetails?.benCurrentAddress?.stateProvinceCode ? getStateName(beneficiaryPersonalDetails?.benCurrentAddress?.stateProvinceCode,'Current') : '--')}}</div>
        <div class="requiredFields"
          *ngIf="(!beneficiaryPersonalDetails || (beneficiaryPersonalDetails?.benCurrentAddress?.stateProvinceName?.length<=0 && beneficiaryPersonalDetails?.benCurrentAddress?.stateProvinceCode?.length<=0)) && requiredFields?.benCurrentAddress?.state">
          Missing</div>
      </div>
      <div class="col mb-2 pt-3">
        <label class="step-tab-label required">City</label>
        <div class="step-tab-label-value">{{beneficiaryPersonalDetails?.benCurrentAddress?.city}}</div>
        <div class="requiredFields"
          *ngIf="(!beneficiaryPersonalDetails || beneficiaryPersonalDetails?.benCurrentAddress?.city?.length<=0) && requiredFields?.benCurrentAddress?.city">
          Missing</div>
      </div>
      <div class="col mb-2 pt-3">
        <label class="step-tab-label">Locality</label>
        <div class="step-tab-label-value">{{beneficiaryPersonalDetails?.benCurrentAddress?.locality}}</div>
      </div>
      <div class="col mb-2 pt-3">
        <label class="step-tab-label required">ZIP / Postal Code</label>
        <div class="step-tab-label-value">{{beneficiaryPersonalDetails?.benCurrentAddress?.postCode}}</div>
        <div class="requiredFields"
          *ngIf="(!beneficiaryPersonalDetails || beneficiaryPersonalDetails?.benCurrentAddress?.postCode?.length<=0) && requiredFields?.benCurrentAddress?.postCode">
          Missing</div>
      </div>
    </div>
    <div class="row col-md-12 pt-4">
      <h5 class="step-tab-form-sub-header">Mailing Address Details</h5>
    </div>
    <div class="row row-cols-4">
      <div class="col mb-2">
        <label class="step-tab-label required">Address Line 1</label>
        <div class="step-tab-label-value">{{beneficiaryPersonalDetails?.benMailingAddress?.addressLine1}}</div>
        <div class="requiredFields"
          *ngIf="(!beneficiaryPersonalDetails || beneficiaryPersonalDetails?.benMailingAddress?.addressLine1?.length<=0) && requiredFields?.benMailingAddress?.addressLine1">
          Missing</div>
      </div>
      <div class="col mb-2">
        <label class="step-tab-label">Address Line 2</label>
        <div class="step-tab-label-value">{{beneficiaryPersonalDetails?.benMailingAddress?.addressLine2}}</div>
      </div>
      <div class="col mb-2">
        <label class="step-tab-label required">Country</label>
        <div class="step-tab-label-value">{{beneficiaryPersonalDetails?.benMailingAddress?.countryName}}</div>
        <div class="requiredFields"
          *ngIf="(!beneficiaryPersonalDetails || beneficiaryPersonalDetails?.benMailingAddress?.countryName?.length<=0) && requiredFields?.benMailingAddress?.countryName">
          Missing</div>
      </div>
      <div class="col mb-2">
        <label class="step-tab-label required">State</label>
        <div class="step-tab-label-value">{{beneficiaryPersonalDetails?.benMailingAddress?.stateProvinceName ? beneficiaryPersonalDetails?.benMailingAddress?.stateProvinceName : (beneficiaryPersonalDetails?.benMailingAddress?.stateProvinceCode ? getStateName(beneficiaryPersonalDetails?.benMailingAddress?.stateProvinceCode,'Mailing') :'--')}}</div>
        <div class="requiredFields"
          *ngIf="(!beneficiaryPersonalDetails || (beneficiaryPersonalDetails?.benMailingAddress?.stateProvinceName?.length<=0 && beneficiaryPersonalDetails?.benMailingAddress?.stateProvinceCode?.length<=0)) && requiredFields?.benMailingAddress?.state">
          Missing</div>
      </div>
      <div class="col mb-2 pt-3">
        <label class="step-tab-label required">City</label>
        <div class="step-tab-label-value">{{beneficiaryPersonalDetails?.benMailingAddress?.city}}</div>
        <div class="requiredFields"
          *ngIf="(!beneficiaryPersonalDetails || beneficiaryPersonalDetails?.benMailingAddress?.city?.length<=0) && requiredFields?.benMailingAddress?.city">
          Missing</div>
      </div>
      <div class="col mb-2 pt-3">
        <label class="step-tab-label">Locality</label>
        <div class="step-tab-label-value">{{beneficiaryPersonalDetails?.benMailingAddress?.locality}}</div>
      </div>
      <div class="col mb-2 pt-3">
        <label class="step-tab-label required">ZIP / Postal Code</label>
        <div class="step-tab-label-value">{{beneficiaryPersonalDetails?.benMailingAddress?.postCode}}</div>
        <div class="requiredFields"
          *ngIf="(!beneficiaryPersonalDetails || beneficiaryPersonalDetails?.benMailingAddress?.postCode?.length<=0) && requiredFields?.benMailingAddress?.postCode">
          Missing</div>
      </div>
    </div>
    <div class="row col-md-12 pt-4">
      <h5 class="step-tab-form-sub-header">Foreign Address Details</h5>
    </div>
    <div class="row row-cols-4">
      <div class="col mb-2">
        <label class="step-tab-label">Address Line 1</label>
        <div class="step-tab-label-value">{{beneficiaryPersonalDetails?.benForeignAddress?.addressLine1}}</div>
        <div class="requiredFields"
          *ngIf="(!beneficiaryPersonalDetails || beneficiaryPersonalDetails?.benForeignAddress?.addressLine1?.length<=0) && requiredFields?.benForeignAddress?.addressLine1">
          Missing</div>
      </div>
      <div class="col mb-2">
        <label class="step-tab-label">Address Line 2</label>
        <div class="step-tab-label-value">{{beneficiaryPersonalDetails?.benForeignAddress?.addressLine2}}</div>
      </div>
      <div class="col mb-2">
        <label class="step-tab-label">Country</label>
        <div class="step-tab-label-value">{{beneficiaryPersonalDetails?.benForeignAddress?.countryName}}</div>
        <div class="requiredFields"
          *ngIf="(!beneficiaryPersonalDetails || beneficiaryPersonalDetails?.benForeignAddress?.countryName?.length<=0) && requiredFields?.benForeignAddress?.countryName">
          Missing</div>
      </div>
      <div class="col mb-2">
        <label class="step-tab-label">State</label>
        <div class="step-tab-label-value">{{beneficiaryPersonalDetails?.benForeignAddress?.stateProvinceName ? beneficiaryPersonalDetails?.benForeignAddress?.stateProvinceName : ( beneficiaryPersonalDetails?.benForeignAddress?.stateProvinceCode ? getStateName(beneficiaryPersonalDetails?.benForeignAddress?.stateProvinceCode,'Foreign') : '--')}}</div>
        <div class="requiredFields"
          *ngIf="(!beneficiaryPersonalDetails || (beneficiaryPersonalDetails?.benForeignAddress?.stateProvinceName?.length<=0 && beneficiaryPersonalDetails?.benForeignAddress?.stateProvinceCode?.length<=0)) && requiredFields?.benForeignAddress?.state">
          Missing</div>
      </div>
      <div class="col mb-2 pt-3">
        <label class="step-tab-label">City</label>
        <div class="step-tab-label-value">{{beneficiaryPersonalDetails?.benForeignAddress?.city}}</div>
        <div class="requiredFields"
          *ngIf="(!beneficiaryPersonalDetails || beneficiaryPersonalDetails?.benForeignAddress?.city?.length<=0) && requiredFields?.benForeignAddress?.city">
          Missing</div>
      </div>
      <div class="col mb-2 pt-3">
        <label class="step-tab-label">Locality</label>
        <div class="step-tab-label-value">{{beneficiaryPersonalDetails?.benForeignAddress?.locality}}</div>
      </div>
      <div class="col mb-2 pt-3">
        <label class="step-tab-label">ZIP / Postal Code</label>
        <div class="step-tab-label-value">{{beneficiaryPersonalDetails?.benForeignAddress?.postCode}}</div>
        <div class="requiredFields"
          *ngIf="(!beneficiaryPersonalDetails || beneficiaryPersonalDetails?.benForeignAddress?.postCode?.length<=0) && requiredFields?.benForeignAddress?.postCode">
          Missing</div>
      </div>
    </div>
    <div class="row col-md-12 pt-4">
      <h5 class="step-tab-form-sub-header">Permanent Address Details</h5>
    </div>
    <div class="row row-cols-4">
      <div class="col mb-2">
        <label class="step-tab-label">Address Line 1</label>
        <div class="step-tab-label-value">{{beneficiaryPersonalDetails?.benPermanentAddress?.addressLine1}}</div>
        <div class="requiredFields"
          *ngIf="(!beneficiaryPersonalDetails || beneficiaryPersonalDetails?.benPermanentAddress?.addressLine1?.length<=0) && requiredFields?.benPermanentAddress?.addressLine1">
          Missing</div>
      </div>
      <div class="col mb-2">
        <label class="step-tab-label">Address Line 2</label>
        <div class="step-tab-label-value">{{beneficiaryPersonalDetails?.benPermanentAddress?.addressLine2}}</div>
      </div>
      <div class="col mb-2">
        <label class="step-tab-label">Country</label>
        <div class="step-tab-label-value">{{beneficiaryPersonalDetails?.benPermanentAddress?.countryName}}</div>
        <div class="requiredFields"
          *ngIf="(!beneficiaryPersonalDetails || beneficiaryPersonalDetails?.benPermanentAddress?.countryName?.length<=0) && requiredFields?.benPermanentAddress?.countryName">
          Missing</div>
      </div>
      <div class="col mb-2">
        <label class="step-tab-label">State</label>
        <div class="step-tab-label-value">{{beneficiaryPersonalDetails?.benPermanentAddress?.stateProvinceName ? beneficiaryPersonalDetails?.benPermanentAddress?.stateProvinceName : (beneficiaryPersonalDetails?.benPermanentAddress?.stateProvinceCode ? getStateName(beneficiaryPersonalDetails?.benPermanentAddress?.stateProvinceCode,'Permanent') : '--')}}</div>
        <div class="requiredFields"
          *ngIf="(!beneficiaryPersonalDetails || (beneficiaryPersonalDetails?.benPermanentAddress?.stateProvinceName?.length<=0 && beneficiaryPersonalDetails?.benPermanentAddress?.stateProvinceCode?.length<=0)) && requiredFields?.benPermanentAddress?.state">
          Missing</div>
      </div>
      <div class="col mb-2 pt-3">
        <label class="step-tab-label">City</label>
        <div class="step-tab-label-value">{{beneficiaryPersonalDetails?.benPermanentAddress?.city}}</div>
        <div class="requiredFields"
          *ngIf="(!beneficiaryPersonalDetails || beneficiaryPersonalDetails?.benPermanentAddress?.city?.length<=0) && requiredFields?.benPermanentAddress?.city">
          Missing</div>
      </div>
      <div class="col mb-2 pt-3">
        <label class="step-tab-label">Locality</label>
        <div class="step-tab-label-value">{{beneficiaryPersonalDetails?.benPermanentAddress?.locality}}</div>
      </div>
      <div class="col mb-2 pt-3">
        <label class="step-tab-label">ZIP / Postal Code</label>
        <div class="step-tab-label-value">{{beneficiaryPersonalDetails?.benPermanentAddress?.postCode}}</div>
        <div class="requiredFields"
          *ngIf="(!beneficiaryPersonalDetails || beneficiaryPersonalDetails?.benPermanentAddress?.postCode?.length<=0) && requiredFields?.benPermanentAddress?.postCode">
          Missing</div>
      </div>
    </div>
    <div *ngIf="false">
      <div class="row col-md-12 pt-4">
        <h5 class="step-tab-form-sub-header">Immigration Details</h5>
      </div>
      <div class="row row-cols-4">
        <div class="col mb-2">
          <label class="step-tab-label required">Place of Last Arrival into US</label>
          <div class="step-tab-label-value">{{beneficiaryPersonalDetails?.immigrationDetails?.placeOfLastArrival}}</div>
          <div class="requiredFields"
            *ngIf="(!beneficiaryPersonalDetails || beneficiaryPersonalDetails?.immigrationDetails?.placeOfLastArrival?.length<=0) && requiredFields?.immigrationDetails?.placeOfLastArrival">
            Missing</div>
        </div>
        <div class="col mb-2">
          <label class="step-tab-label required">Mode of Travel</label>
          <div class="step-tab-label-value">{{beneficiaryPersonalDetails?.immigrationDetails?.modeOfTravel}}</div>
          <div class="requiredFields"
            *ngIf="(!beneficiaryPersonalDetails || beneficiaryPersonalDetails?.immigrationDetails?.modeOfTravel?.length<=0) && requiredFields?.immigrationDetails?.modeOfTravel">
            Missing</div>
        </div>
        <div class="col mb-2">
          <label class="step-tab-label required">City</label>
          <div class="step-tab-label-value">{{beneficiaryPersonalDetails?.immigrationDetails?.city}}</div>
          <div class="requiredFields"
            *ngIf="(!beneficiaryPersonalDetails || beneficiaryPersonalDetails?.immigrationDetails?.city?.length<=0) && requiredFields?.immigrationDetails?.city">
            Missing</div>
        </div>
        <div class="col mb-2">
          <label class="step-tab-label required">State</label>
          <div class="step-tab-label-value">{{beneficiaryPersonalDetails?.immigrationDetails?.state}}</div>
          <div class="requiredFields"
            *ngIf="(!beneficiaryPersonalDetails || beneficiaryPersonalDetails?.immigrationDetails?.state?.length<=0) && requiredFields?.immigrationDetails?.state">
            Missing</div>
        </div>
      </div>
      <div class="row row-cols-4 pt-3">
        <div class="col mb-2">
          <label class="step-tab-label required">Date of Arrival</label>
          <div class="step-tab-label-value">{{beneficiaryPersonalDetails?.immigrationDetails?.arrivalDate |
            VisaDateFormat}}
          </div>
          <div class="requiredFields"
            *ngIf="(!beneficiaryPersonalDetails || beneficiaryPersonalDetails?.immigrationDetails?.arrivalDate?.length<=0) && requiredFields?.immigrationDetails?.arrivalDate">
            Missing</div>
        </div>
        <div class="col mb-2">
          <label class="step-tab-label required">Admitted as</label>
          <div class="step-tab-label-value">{{beneficiaryPersonalDetails?.immigrationDetails?.admittedAs}}</div>
          <div class="requiredFields"
            *ngIf="(!beneficiaryPersonalDetails || beneficiaryPersonalDetails?.immigrationDetails?.admittedAs?.length<=0) && requiredFields?.immigrationDetails?.admittedAs">
            Missing</div>
        </div>
        <div class="col mb-2">
          <label class="step-tab-label required">Class of Admission</label>
          <div class="step-tab-label-value">{{beneficiaryPersonalDetails?.immigrationDetails?.classOfAdmission}}</div>
          <div class="requiredFields"
            *ngIf="(!beneficiaryPersonalDetails || beneficiaryPersonalDetails?.immigrationDetails?.classOfAdmission?.length<=0) && requiredFields?.immigrationDetails?.classOfAdmission">
            Missing</div>
        </div>
        <div class="col mb-2">
          <label class="step-tab-label required">I-94 Expiration Date</label>
          <div class="step-tab-label-value">{{beneficiaryPersonalDetails?.immigrationDetails?.i94ExpiryDate |
            VisaDateFormat}}
          </div>
          <div class="requiredFields"
            *ngIf="(!beneficiaryPersonalDetails || beneficiaryPersonalDetails?.immigrationDetails?.i94ExpiryDate?.length<=0) && requiredFields?.immigrationDetails?.i94ExpiryDate">
            Missing</div>
        </div>
      </div>
    </div>
    <div class="row col-md-12 pt-4">
      <h5 class="step-tab-form-sub-header">Biographic Information</h5>
    </div>
    <div class="row row-cols-4">
      <div class="col mb-2">
        <label class="step-tab-label"><span *ngIf="requiredFields?.ethnicity?.name"
            class="required"></span>Ethnicity</label>
        <div class="step-tab-label-value">{{beneficiaryPersonalDetails?.ethnicity?.name}}</div>
        <div class="requiredFields"
          *ngIf="(!beneficiaryPersonalDetails || beneficiaryPersonalDetails?.ethnicity?.name?.length<=0) && requiredFields?.ethnicity?.name">
          Missing</div>
      </div>
      <div class="col mb-2">
        <label class="step-tab-label"><span *ngIf="requiredFields?.race" class="required"></span>Race</label>
        <div class="step-tab-label-value" *ngFor="let i of beneficiaryPersonalDetails?.race;let last = last">
          <span>{{i?.refName}}</span><span *ngIf="!last">,</span>
        </div>
        <div class="requiredFields"
          *ngIf="(!beneficiaryPersonalDetails || beneficiaryPersonalDetails?.race?.length<=0) && requiredFields?.race">
          Missing</div>
      </div>
      <div class="col mb-2">
        <label class="step-tab-label"><span *ngIf="requiredFields?.hairColor" class="required"></span>Hair Color</label>
        <div class="step-tab-label-value">{{beneficiaryPersonalDetails?.hairColor}}</div>
        <div class="requiredFields"
          *ngIf="(!beneficiaryPersonalDetails || beneficiaryPersonalDetails?.hairColor?.length<=0) && requiredFields?.hairColor">
          Missing</div>
      </div>
      <div class="col mb-2">
        <label class="step-tab-label"><span *ngIf="requiredFields?.eyeColor" class="required"></span>Eyes Color</label>
        <div class="step-tab-label-value">{{beneficiaryPersonalDetails?.eyeColor}}</div>
        <div class="requiredFields"
          *ngIf="(!beneficiaryPersonalDetails || beneficiaryPersonalDetails?.eyeColor?.length<=0) && requiredFields?.eyeColor">
          Missing</div>
      </div>
    </div>
    <div class="row row-cols-4">
      <div class="col mb-2">
        <label class="step-tab-label"><span *ngIf="requiredFields?.height" class="required"></span>Height</label>
        <div class="step-tab-label-value">{{beneficiaryPersonalDetails?.height}}<small
            *ngIf="beneficiaryPersonalDetails?.height">inches</small></div>
        <div class="requiredFields"
          *ngIf="(!beneficiaryPersonalDetails || beneficiaryPersonalDetails?.height?.length<=0) && requiredFields?.height">
          Missing</div>
      </div>
      <div class="col mb-2">
        <label class="step-tab-label"><span *ngIf="requiredFields?.weight" class="required"></span>Weight</label>
        <div class="step-tab-label-value">{{beneficiaryPersonalDetails?.weight}}<small
            *ngIf="beneficiaryPersonalDetails?.weight">pounds</small></div>
        <div class="requiredFields"
          *ngIf="(!beneficiaryPersonalDetails || beneficiaryPersonalDetails?.weight?.length<=0) && requiredFields?.weight">
          Missing</div>
      </div>
      <div class="col mb-2">
        <label class="step-tab-label">Marks of identification</label>
        <div class="step-tab-label-value">{{beneficiaryPersonalDetails?.identificationMark}}</div>
      </div>
    </div>
    <div class="row col-md-12 pt-4">
      <h5 class="step-tab-form-sub-header">Signature</h5>
    </div>
    <div class="row">
      <div class="col mb-2">
        <div *ngIf="signatureImage">
          <img [src]="signatureImage" height="30" width="100" alt="Signature">
        </div>
      </div>
    </div>
  </div>

  <p-divider></p-divider>

  <div class="row pt-3">
    <div class="col-md-6 space">
      <button pButton [disabled]="disabledControls" type="button" label="VERIFY" title="Verify" class="save-btn"
        (click)="handleVerify()"></button>
    </div>
  </div>
</div>