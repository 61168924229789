import { Component, Input, Output, OnChanges, OnDestroy, OnInit, AfterViewInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ImagilityBaseResponse, PrimaryData, ReferenceLibrary, TaskStep } from 'app-models';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs/internal/Subject';
import { StepStatusUpdatePayloadService } from 'step-status-update-payload-service';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { getStepDetails, updateStepStatus } from 'visa-store';
import { ApiSponserPersonalDetailService } from './api-sponser-personal-detail.service';
import { Router } from '@angular/router';
import * as RolesRouteMap from 'app-models';
import { AppServicesLibraryService } from 'app-services-library';
import { ReferenceLibraryService } from 'reference-library';
import { ApiServicesService } from 'src/app/modules/beneficiary/services/api-services.service';
import moment from 'moment';
// import { EditCompletePersonalDetailLibraryService } from 'edit-complete-personal-detail-library'; 
@Component({
  selector: 'app-sponser-personal-detail',
  templateUrl: './sponser-personal-detail.component.html',
  styleUrls: ['./sponser-personal-detail.component.scss']
})
export class SponserPersonalDetailComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @Input() primaryData: PrimaryData;


  //beneficiaryPersonalDetails: any;
  requiredFields: any;
  disabledControls: boolean;
  // typeOfButton: string; // This is required for status update button
  stepDetails: TaskStep;
  sponser = 'sponser';
  missingValueFalg: boolean;

  observableSubscription$ = new Subject();
  userType: string = ''
  // public inputData: PrimaryData;
  signatureImage: string;
  @Input() userId: number;
  @Input() type: string;
  questionnaire: boolean;
  listTitle: ReferenceLibrary[] = [];
  selectedState = 'simple';
  isEditMode: boolean = false;
  beneficiaryImmigPersonalDetails: any;
  selectedDetailAbout: any;
  showUploadPassport: boolean = true;
  personalDetailsEdit: boolean = false;
  beneficiaryProfileUpdate: any;
  profileEducationUpdate: boolean = true;
  profileUpdate: boolean = true;
  editClicked: boolean = false;
  beneficiaryPersonalDetails = {
    "beneficiaryId": "",
    "relationshipCode": "",
    "addressLine1": "",
    "addressLine2": "",
    "alienRegNo": "",
    "benCurrentAddress": {
      "addressHistoryId": null,
      "addressLine1": "",
      "addressLine2": "",
      "addressLine2NoType": null,
      "addressTypeCode": "",
      "beneficiaryId": null,
      "city": "",
      "clientEmail": null,
      "clientName": null,
      "clientPhoneCode": null,
      "clientPhoneNo": null,
      "companyId": null,
      "countryCode": {

        "createdBy": 0,
        "modifiedBy": 0,
        "countryName": "",
        "countryCode": "",
        "shortCountryCode": "",
        "phoneCode": "",
        "sequenceNumber": 0

      },
      "countryName": "",
      "county": null,
      "createdBy": 0,
      "dateRangeFrom": null,
      "dateRangeTo": null,
      "doorNo": null,
      "employeeId": null,
      "familyId": null,
      "hasLivedTogthr": null,
      "id": 0,
      "immigrationId": null,
      "inCareOfName": null,
      "liveTogetherFromDate": null,
      "liveTogetherToDate": null,
      "locality": "",
      "modifiedBy": null,
      "postCode": "",
      "sequenceNo": "",
      "state": "",
      "stateProvinceCode": null,
      "stateProvinceName": "",
      "streetNo": null,
      "type": {
        "code": "",
        "createdBy": 0,
        "description": "",
        "group": "",
        "id": 0,
        "isGenerated": true,
        "isUploaded": false,
        "modifiedBy": null,
        "name": "",
        "refGroupdesc": ""
      },
      "workLocationId": null,
      "workLocationTypeCode": null,
      "workLocationTypeId": null
    },
    "benForeignAddress": {
      "addressHistoryId": null,
      "addressLine1": "",
      "addressLine2": "",
      "addressLine2NoType": null,
      "addressTypeCode": "",
      "beneficiaryId": null,
      "city": "dgdfgf",
      "clientEmail": null,
      "clientName": null,
      "clientPhoneCode": null,
      "clientPhoneNo": null,
      "companyId": null,
      "countryCode": {

        "createdBy": 0,
        "modifiedBy": 0,
        "countryName": "",
        "countryCode": "",
        "shortCountryCode": "",
        "phoneCode": "",
        "sequenceNumber": 0

      },
      "countryName": "",
      "county": null,
      "createdBy": 0,
      "dateRangeFrom": null,
      "dateRangeTo": null,
      "doorNo": null,
      "employeeId": null,
      "familyId": null,
      "hasLivedTogthr": null,
      "id": 0,
      "immigrationId": null,
      "inCareOfName": null,
      "liveTogetherFromDate": null,
      "liveTogetherToDate": null,
      "locality": "",
      "modifiedBy": null,
      "postCode": "",
      "sequenceNo": "",
      "state": "",
      "stateProvinceCode": null,
      "stateProvinceName": "",
      "streetNo": null,
      "type": {
        "code": "",
        "createdBy": 1,
        "description": "",
        "group": "",
        "id": 0,
        "isGenerated": true,
        "isUploaded": false,
        "modifiedBy": null,
        "name": "",
        "refGroupdesc": ""
      },
      "workLocationId": null,
      "workLocationTypeCode": null,
      "workLocationTypeId": null
    },
    "benMailingAddress": {
      "addressHistoryId": null,
      "addressLine1": "",
      "addressLine2": "",
      "addressLine2NoType": null,
      "addressTypeCode": "",
      "beneficiaryId": null,
      "city": "",
      "clientEmail": null,
      "clientName": null,
      "clientPhoneCode": null,
      "clientPhoneNo": null,
      "companyId": null,
      "countryCode": {

        "createdBy": 0,
        "modifiedBy": 0,
        "countryName": "",
        "countryCode": "",
        "shortCountryCode": "",
        "phoneCode": "",
        "sequenceNumber": 0

      },
      "countryName": "",
      "county": null,
      "createdBy": 0,
      "dateRangeFrom": null,
      "dateRangeTo": null,
      "doorNo": null,
      "employeeId": null,
      "familyId": null,
      "hasLivedTogthr": null,
      "id": 0,
      "immigrationId": null,
      "inCareOfName": null,
      "liveTogetherFromDate": null,
      "liveTogetherToDate": null,
      "locality": "",
      "modifiedBy": null,
      "postCode": "",
      "sequenceNo": "",
      "state": "",
      "stateProvinceCode": null,
      "stateProvinceName": "",
      "streetNo": null,
      "type": {
        "code": "",
        "createdBy": 1,
        "description": "",
        "group": "",
        "id": 5,
        "isGenerated": true,
        "isUploaded": false,
        "modifiedBy": null,
        "name": "",
        "refGroupdesc": ""
      },
      "workLocationId": null,
      "workLocationTypeCode": null,
      "workLocationTypeId": null
    },
    "benPermanentAddress": {
      "addressHistoryId": null,
      "addressLine1": "",
      "addressLine2": "",
      "addressLine2NoType": null,
      "addressTypeCode": "",
      "beneficiaryId": null,
      "city": "dgdfgf",
      "clientEmail": null,
      "clientName": null,
      "clientPhoneCode": null,
      "clientPhoneNo": null,
      "companyId": null,
      "countryCode": {

        "createdBy": 0,
        "modifiedBy": 0,
        "countryName": "",
        "countryCode": "",
        "shortCountryCode": "",
        "phoneCode": "",
        "sequenceNumber": 0

      },
      "countryName": "",
      "county": null,
      "createdBy": 592,
      "dateRangeFrom": null,
      "dateRangeTo": null,
      "doorNo": null,
      "employeeId": null,
      "familyId": null,
      "hasLivedTogthr": null,
      "id": 0,
      "immigrationId": null,
      "inCareOfName": null,
      "liveTogetherFromDate": null,
      "liveTogetherToDate": null,
      "locality": "",
      "modifiedBy": null,
      "postCode": "",
      "sequenceNo": "",
      "state": "",
      "stateProvinceCode": null,
      "stateProvinceName": "",
      "streetNo": null,
      "type": {
        "code": "",
        "createdBy": 0,
        "description": "",
        "group": "",
        "id": 0,
        "isGenerated": true,
        "isUploaded": false,
        "modifiedBy": null,
        "name": "",
        "refGroupdesc": ""
      },
      "workLocationId": null,
      "workLocationTypeCode": null,
      "workLocationTypeId": null
    },
    "birthCity": "",
    "birthCountry": "",
    "birthCountryCode": "",
    "birthState": "",
    "birthStateCode": null,
    "birthStateProvinceCode": null,
    "birthStateProvinceName": null,
    "childSpecificRelationship": "",
    "citizenShip": "",
    "city": "",
    "cityOfResidence": "",
    "classOfAdmission": null,
    "country": "",
    "countryCode": {

      "createdBy": 0,
      "modifiedBy": 0,
      "countryName": "",
      "countryCode": "",
      "shortCountryCode": "",
      "phoneCode": "",
      "sequenceNumber": 0

    },
    "countryOfCitizenship": "",
    "countryOfIssuranceOfPassport": null,
    "countryOfResidence": "",
    "countryOfResidenceName": null,
    "county": "",
    "dateOfIssuranceOfPassport": null,
    "dateOfMarriage": null,
    "dayTimeTelephoneNumber": null,
    "dob": "",
    "emailId": "0",
    "ethnicity": {
      "code": "",
      "createdBy": 0,
      "description": "",
      "group": "",
      "id": 0,
      "isGenerated": true,
      "isUploaded": false,
      "modifiedBy": null,
      "name": "",
      "refGroupdesc": ""
    },
    "expiryDateOfIssuranceOfPassport": null,
    "eyeColor": "",
    "firstName": "",
    "gender": "",
    "genderCode": "",
    "hairColor": "",
    "hasBeenTravelledToUs": false,
    "height": "",
    "i94Number": null,
    "id": "",
    "identificationMark": "",
    "immigrationDetails": null,
    "lastName": "Kumar",
    "livingStateCode": null,
    "livingStateName": "",
    "maritalStatus": "",
    "marriageDate": null,
    "middleName": "",
    "mobileNo": "",
    "otherNameDTO": [
    ],
    "passport": null,
    "placeOfMarriage": "",
    "race": [
      {
        "createdBy": null,
        "id": 0,
        "modifiedBy": null,
        "raceId": null,
        "refCode": "",
        "refName": ""
      }
    ],
    "relatedToId": null,

    "relationshipName": "",
    "signImage": "",
    "ssn": "",
    "title": "",
    "weight": 0,
    "zipCode": "0"


  };
  listStates: any[];
  listStatesCurrent: any[];
  listStatesPermanent: any[];
  listStatesMailing: any[];
  listStatesBirth: any[];
  listStatesForeign: any;
  listStatesMarriage: any;
  constructor(
    public store: Store<any>,
    public apiService: ApiSponserPersonalDetailService,
    private personalDetailsApiService: ApiServicesService,
    public toastr: ToastrService,
    public stepStatusUpdatePayloadCostructionService: StepStatusUpdatePayloadService,
    public router: Router,
    private appServicesLibraryService: AppServicesLibraryService,
    private referenceLibraryService: ReferenceLibraryService,
    // private editPersonalDetailService: EditCompletePersonalDetailLibraryService
  ) {
    this.questionnaire = false;
    this.disabledControls = false;
    // this.typeOfButton = null;
    this.requiredFields = {};
    this.missingValueFalg = false;
    this.questionnaire = window.location.pathname === "/beneficiary-profile/profile" ? true : false;
    sessionStorage.setItem('selectedState', 'simple');
    this.selectedState = 'simple';
  }

  ngOnInit(): void {
    localStorage.setItem("currentUserSelection", JSON.stringify(this.selectedDetailAbout));
    this.personalDetailsEdit = (this.beneficiaryProfileUpdate == 'true');
    this.getBeneDetailsLibrary();
    if (((this.type == undefined || this.type ==='self') && this.primaryData?.beneficiaryId != null) || (!this.type && !this.primaryData?.beneficiaryId && this.primaryData?.sponsorId)) {
      this.type = 'self';      
    }else if(this.type && this.type !='self'){
      this.type = 'family';
      this.primaryData = { ...this.primaryData, familyId: (this.primaryData.familyId) ? this.primaryData.familyId : this.userId };
    }
    this.selectedDetailAbout = {
      showDetailFor: this.type,
    };
    this.userType = sessionStorage.getItem('userTypeRole');
    this.referenceLibraryService.getReferenceData('TITL').pipe(takeUntil(this.observableSubscription$))
      .subscribe((data: ReferenceLibrary[]) => {
        this.listTitle = data;
      });
    // Get Specific step details by passing step code
    this.store.pipe(select(getStepDetails, { id: this.primaryData.stepId }))
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe((data: TaskStep) => {
        if (data && data.id) {
          this.stepDetails = data;
          this.toggleControlsStatus();
          // this.getBeneProfileInformation();
        }
      });
     
  }

  personalDetails(param) {
    this.getBeneDetailsLibrary();
}

getBeneDetailsLibrary() {
  if (this.beneficiaryProfileUpdate === 'true' || (sessionStorage.getItem('listBeneficiaryId') && sessionStorage.getItem('relativeId'))) {   //beneficiaryId
    this.profileEducationUpdate = false;
    this.profileUpdate = true;
  } else {
    this.profileEducationUpdate = true;
    this.profileUpdate = false;
  }
}

onEditClicked(status) {
  this.editClicked = status;
}


onHasPassportChange(flag: boolean) {
  this.showUploadPassport = flag;
}


  receiveData(data: string) {
    this.isEditMode = false;
    this.userType = sessionStorage.getItem('userTypeRole');
    // Get Specific step details by passing step code
    this.getBeneProfileInformation();


  }
  editDetails() {
    this.isEditMode = true;
    this.primaryData = { ...this.primaryData, familyId: (this.primaryData.familyId) ? this.primaryData.familyId : this.userId };
    sessionStorage.setItem('primaryData', JSON.stringify(this.primaryData));
  }

  onChange($event) {
    // this.selectedState = $event.value;
    // if (this.selectedState === 'complete') {
    //   sessionStorage.setItem('selectedState', 'complete');
    // } else if (this.selectedState === 'simple') {
    //   this.isEditMode = false;
    //   sessionStorage.setItem('selectedState', 'simple');
    // }
    // this.getBeneProfileInformation();
  }

  onClosePersonalEdit() {
    this.isEditMode = false;
    //super.getBenDataProfile();
  }

  handleEditClose() {
    this.isEditMode = false;
    //super.getBenDataProfile();
  }

  ngOnChanges() {
    if (this.primaryData && this.userId) {
      // this.getBeneProfileInformation();
    }
  }
  dataMapping(response: any) {
    if (!response) return;
    // this.beneficiaryPersonalDetails=response;
    this.beneficiaryPersonalDetails.dob = response.dob || null;
    this.beneficiaryPersonalDetails.firstName = response?.fName;
    this.beneficiaryPersonalDetails.lastName = response?.lName;
    this.beneficiaryPersonalDetails.middleName = response?.mName ? response?.mName : null;
    this.beneficiaryPersonalDetails.emailId = response.emailContacts.find(e => e.type.code == 'PRIM')?.email;
    this.beneficiaryPersonalDetails.gender = response?.gender?.name;
    this.beneficiaryPersonalDetails.mobileNo = response?.phoneContacts[0]?.phoneNo;
    this.beneficiaryPersonalDetails.birthCountry = response?.birthCountryCode?.countryName;
    this.beneficiaryPersonalDetails.birthStateProvinceCode = response?.birthStateProvinceCode;
    this.beneficiaryPersonalDetails.birthStateProvinceName = response?.birthStateProvinceName;
    this.beneficiaryPersonalDetails.birthCity = response?.birthCity;
    if(this.beneficiaryPersonalDetails?.birthStateProvinceCode || this.beneficiaryPersonalDetails?.birthStateProvinceName){
      this.handleCountryChange(response?.birthCountryCode?.countryCode,'Birth');
    }

    this.beneficiaryPersonalDetails.citizenShip = response?.ctznshipCountryCode?.countryName;
    this.beneficiaryPersonalDetails.benCurrentAddress = response?.address?.find(e => e.type.code == 'CURR');
    this.beneficiaryPersonalDetails.benForeignAddress = response?.address?.find(e => e.type.code == 'FORN');
    this.beneficiaryPersonalDetails.benPermanentAddress = response?.address?.find(e => e.type.code == 'PERM');
    this.beneficiaryPersonalDetails.benMailingAddress = response?.address?.find(e => e.type.code == 'MAIL');
    this.beneficiaryPersonalDetails.race = response?.race?.map(e => this.mapRaceItemToEmpty(e));

    this.signatureImage = "data:image/jpeg;base64," + this.beneficiaryPersonalDetails?.signImage;
    if (this.beneficiaryPersonalDetails?.benCurrentAddress) {
      this.beneficiaryPersonalDetails.benCurrentAddress.countryName = this.beneficiaryPersonalDetails?.benCurrentAddress?.countryCode?.countryName;
      this.beneficiaryPersonalDetails.benCurrentAddress.countryName = this.beneficiaryPersonalDetails?.benCurrentAddress?.countryCode?.countryName;
      this.handleCountryChange(this.beneficiaryPersonalDetails.benCurrentAddress.countryCode?.countryCode,'Current');
    }
    if (this.beneficiaryPersonalDetails?.benForeignAddress) {
      this.beneficiaryPersonalDetails.benForeignAddress.countryName = this.beneficiaryPersonalDetails?.benForeignAddress?.countryCode?.countryName;
      this.handleCountryChange(this.beneficiaryPersonalDetails.benForeignAddress.countryCode?.countryCode,'Foreign');
    }
    if (this.beneficiaryPersonalDetails?.benPermanentAddress) {
      this.beneficiaryPersonalDetails.benPermanentAddress.countryName = this.beneficiaryPersonalDetails?.benPermanentAddress?.countryCode?.countryName;
      this.handleCountryChange(this.beneficiaryPersonalDetails.benForeignAddress.countryCode?.countryCode,'Permanent');
    }
    if (this.beneficiaryPersonalDetails?.benMailingAddress) {
      this.beneficiaryPersonalDetails.benMailingAddress.countryName = this.beneficiaryPersonalDetails?.benMailingAddress?.countryCode?.countryName;
      this.handleCountryChange(this.beneficiaryPersonalDetails.benMailingAddress.countryCode?.countryCode,'Mailing');
    }
    const titleObjMaster = this.listTitle.find((x) => x.code === response?.title || x.name === response?.title);
    this.beneficiaryPersonalDetails = { ...this.beneficiaryPersonalDetails, title: titleObjMaster ? titleObjMaster.name : response?.title, hairColor:response?.hairColor, ethnicity: response?.ethnicity, eyeColor: response?.eyeColor, height: response?.height, weight: response?.weight  };

  }
  mapRaceItemToEmpty(raceItem: any) {
    return {
      createdBy: raceItem.createdBy,
      modifiedBy: raceItem.modifiedBy,
      id: raceItem.id,
      raceId: raceItem.raceId ? null : raceItem.raceId,
      refCode: raceItem.raceId.code,
      refName: raceItem.raceId.name
    };
  }
  getBeneProfileInformation() {
    let url;
    if ((this.primaryData.familyId || this.primaryData.familyId === this.userId || this.userId)  && this.type === 'family') {
      this.primaryData = { ...this.primaryData, familyId: (this.primaryData.familyId) ? this.primaryData.familyId : this.userId };
      this.personalDetailsApiService.getBeneficiaryDetails(this.primaryData.sponsorId || this.primaryData.beneficiaryId, 'family', this.userId).pipe(takeUntil(this.observableSubscription$)).subscribe((beneficiaryFamilyInfo: any) => {
        let response = beneficiaryFamilyInfo.find(e => e.id = this.primaryData.familyId || this.userId);
        this.dataMapping(response);
      });
    } else if (this.primaryData.beneficiaryId && this.primaryData.beneficiaryId === this.userId || this.type === 'self') {
      this.personalDetailsApiService.getBeneficiaryDetails(this.primaryData.beneficiaryId, 'self').pipe(takeUntil(this.observableSubscription$)).subscribe((beneficiary: any) => {
        this.beneficiaryImmigPersonalDetails = { ...beneficiary, dob: beneficiary.dob && moment(beneficiary.dob, 'YYYY-MM-DD').toDate(), marriageDate: beneficiary.marriageDate && moment(beneficiary.marriageDate, 'YYYY-MM-DD').toDate(), ctznCrtfctIssDate: beneficiary.ctznCrtfctIssDate && moment(beneficiary.ctznCrtfctIssDate, 'YYYY-MM-DD').toDate(), passport: beneficiary.passport?.length ? [{ ...beneficiary.passport[0], expiryDate: beneficiary.passport[0].expiryDate && moment(beneficiary.passport[0].expiryDate, 'YYYY-MM-DD').toDate(), issueDate: beneficiary.passport[0].issueDate && moment(beneficiary.passport[0].issueDate, 'YYYY-MM-DD').toDate() }] : beneficiary.passport };
        this.beneficiaryPersonalDetails = this.beneficiaryImmigPersonalDetails;
        this.beneficiaryPersonalDetails.firstName = this.beneficiaryImmigPersonalDetails.fName;
        this.beneficiaryPersonalDetails.lastName = this.beneficiaryImmigPersonalDetails.lName;
        this.beneficiaryPersonalDetails.middleName = this.beneficiaryImmigPersonalDetails?.mName ? this.beneficiaryImmigPersonalDetails?.mName : null;
        this.beneficiaryPersonalDetails.emailId = this.beneficiaryImmigPersonalDetails.emailContacts.find(e => e.type.code == 'PRIM').email;
        this.beneficiaryPersonalDetails.gender = this.beneficiaryImmigPersonalDetails?.gender?.name;
        this.beneficiaryPersonalDetails.mobileNo = this.beneficiaryImmigPersonalDetails?.phoneContacts[0]?.phoneNo;
        this.beneficiaryPersonalDetails.birthCountry = this.beneficiaryImmigPersonalDetails?.countryCode?.countryName;

        if(this.beneficiaryImmigPersonalDetails?.birthStateProvinceCode || this.beneficiaryImmigPersonalDetails?.birthStateProvinceName){
          this.handleCountryChange(this.beneficiaryImmigPersonalDetails?.countryCode?.countryCode,'Birth');
        }

        this.beneficiaryPersonalDetails.citizenShip = this.beneficiaryImmigPersonalDetails?.ctznshipCountryCode?.countryName;
        this.beneficiaryPersonalDetails.benCurrentAddress = this.beneficiaryImmigPersonalDetails.address.find(e => e.type.code == 'CURR');
        this.beneficiaryPersonalDetails.benForeignAddress = this.beneficiaryImmigPersonalDetails.address.find(e => e.type.code == 'FORN');
        this.beneficiaryPersonalDetails.benPermanentAddress = this.beneficiaryImmigPersonalDetails.address.find(e => e.type.code == 'PERM');
        this.beneficiaryPersonalDetails.benMailingAddress = this.beneficiaryImmigPersonalDetails.address.find(e => e.type.code == 'MAIL');
        this.beneficiaryPersonalDetails.race = this.beneficiaryImmigPersonalDetails?.race.map(e => this.mapRaceItemToEmpty(e));

        this.signatureImage = this.beneficiaryPersonalDetails?.signImage ? "data:image/jpeg;base64," + this.beneficiaryPersonalDetails?.signImage : null;
        if (this.beneficiaryPersonalDetails?.benCurrentAddress) {
          this.beneficiaryPersonalDetails.benCurrentAddress.countryName = this.beneficiaryPersonalDetails?.benCurrentAddress?.countryCode?.countryName;
          this.beneficiaryPersonalDetails.benCurrentAddress.countryName = this.beneficiaryPersonalDetails?.benCurrentAddress?.countryCode?.countryName;
          this.handleCountryChange(this.beneficiaryPersonalDetails.benCurrentAddress.countryCode.countryCode,'Current');
        }
        if (this.beneficiaryPersonalDetails?.benForeignAddress) {
          this.beneficiaryPersonalDetails.benForeignAddress.countryName = this.beneficiaryPersonalDetails?.benForeignAddress?.countryCode?.countryName;
          this.handleCountryChange(this.beneficiaryPersonalDetails.benForeignAddress.countryCode.countryCode,'Foreign');
        }
        if (this.beneficiaryPersonalDetails?.benPermanentAddress) {
          this.beneficiaryPersonalDetails.benPermanentAddress.countryName = this.beneficiaryPersonalDetails?.benPermanentAddress?.countryCode?.countryName;
          this.handleCountryChange(this.beneficiaryPersonalDetails.benForeignAddress.countryCode.countryCode,'Permanent');
        }
        if (this.beneficiaryPersonalDetails?.benMailingAddress) {
          this.beneficiaryPersonalDetails.benMailingAddress.countryName = this.beneficiaryPersonalDetails?.benMailingAddress?.countryCode?.countryName;
          this.handleCountryChange(this.beneficiaryPersonalDetails.benMailingAddress.countryCode.countryCode,'Mailing');
        }
        const titleObjMaster = this.listTitle.find((x) => x.code === beneficiary?.title || x.name === beneficiary?.title);
        this.beneficiaryPersonalDetails = { ...this.beneficiaryPersonalDetails, title: titleObjMaster ? titleObjMaster.name : beneficiary?.title, hairColor:beneficiary?.hairColor, ethnicity: beneficiary?.ethnicity, eyeColor: beneficiary?.eyeColor, height: beneficiary?.height, weight: beneficiary?.weight  };
        // let response=beneficiary.find(e=>e.id=this.primaryData.beneficiaryId);
        // this.dataMapping(response);
      });
    }
    else {
      url = this.apiService.getSponsorProfileInformation(
        this.primaryData.visatype,
        this.userId ? this.userId : this.primaryData.beneficiaryId);
      url
        .pipe(takeUntil(this.observableSubscription$))
        .subscribe((response: ImagilityBaseResponse) => {
          if (response) {
            const titleObjMaster = this.listTitle.find((x) => x.code === response?.data?.title || x.name === response?.data?.title);
            this.beneficiaryPersonalDetails = { ...response.data, title: titleObjMaster ? titleObjMaster.name : response?.data?.title, birthStateProvinceName: response?.data?.birthState };
            if (this.beneficiaryPersonalDetails?.benCurrentAddress) {
              this.handleCountryChange(response?.data?.benCurrentAddress?.countryCode,'Current');
            }
            if (this.beneficiaryPersonalDetails?.benForeignAddress) {
              this.handleCountryChange(response?.data?.benForeignAddress?.countryCode,'Foreign');
            }
            if (this.beneficiaryPersonalDetails?.benPermanentAddress) {
              this.handleCountryChange(response?.data?.benForeignAddress?.countryCode,'Permanent');
            }
            if (this.beneficiaryPersonalDetails?.benMailingAddress) {
              this.handleCountryChange(response?.data?.benMailingAddress?.countryCode,'Mailing');
            }
            this.signatureImage = (response['data']['signImage'] !== null && response['data']['signImage'] !== undefined) ? `data:image/jpeg;base64,${response['data']['signImage']}` : '';
          }
        });
    }
  }

  // To Verify Missing Fields
  handleVerify() {
    if (this.primaryData.immigrationClassification && this.primaryData.immigrationClassification === "ONLINEIMMVISAREG") {
      this.missingValueFalg = false;
      this.requiredFields = {
        alienRegNo: false,
        benForeignAddress: { // IM-7655 fix as per userstory this fields are not mandataory
          addressLine1: false,
          city: false,
          countryName: false,
          postCode: false,
          state: false,
        },
        benCurrentAddress: {
          addressLine1: true,
          city: true,
          countryName: true,
          postCode: true,
          state: true,
        },
        birthCity: true,
        birthCountry: true,
        birthState: true,
        citizenShip: true,
        countryCode: false,
        dob: true,
        emailId: true,
        firstName: true,
        title: true,
        gender: true,
        immigrationDetails: {
          placeOfLastArrival: false,
          modeOfTravel: false,
          city: false, // there is not field in UI
          state: false, // there is not field in UI
          arrivalDate: false,
          admittedAs: false,
          classOfAdmission: false,
          i94ExpiryDate: false,
          i94Number: false
        },
        lastName: true,
        mobileNo: true,
        signImage: false,
        ethnicity: {
          name: false
        },
        //  hairColor: this.primaryData.caseType === 'I130',
        hairColor: false,
        eyeColor: false,
        height: false,
        weight: false,
        race: false
      };
    } else {
      this.missingValueFalg = false;
      this.requiredFields = {
        alienRegNo: false,
        benForeignAddress: { // IM-7655 fix as per userstory this fields are not mandataory
          addressLine1: false,
          city: false,
          countryName: false,
          postCode: false,
          state: false,
        },
        benCurrentAddress: {
          addressLine1: true,
          city: true,
          countryName: true,
          postCode: true,
          state: true,
        },
        birthCity: true,
        birthCountry: true,
        birthState: true,
        citizenShip: true,
        countryCode: false,
        dob: true,
        emailId: true,
        firstName: true,
        title: true,
        gender: true,
        immigrationDetails: {
          placeOfLastArrival: false,
          modeOfTravel: false,
          city: false, // there is not field in UI
          state: false, // there is not field in UI
          arrivalDate: false,
          admittedAs: false,
          classOfAdmission: false,
          i94ExpiryDate: false,
          i94Number: false
        },
        lastName: true,
        mobileNo: true,
        signImage: false,
        ethnicity: {
          name: true
        },
        //  hairColor: this.primaryData.caseType === 'I130',
        hairColor: true,
        eyeColor: true,
        height: true,
        weight: true,
        race: true
      };
    }
    if (!this.iterateRequiredFileds(this.requiredFields, null)) {
      if (this.stepDetails.stepStatusDetails.stepStatusCode === 'NEW') {
        this.updateStepStatus('INPROGRESS');
      }
      this.toastr.success('Fields validation is successful', 'Success');
    } else {
      this.toastr.info('Check the missing fields');
    }
  }

  iterateRequiredFileds(obj, parentKey) {
    if (!this.missingValueFalg) {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          if (parentKey) {
            // for object inside Obj
            if (obj[key] && key !== 'state' && (this.beneficiaryPersonalDetails[parentKey] === null ||
              this.beneficiaryPersonalDetails[parentKey][key] === null ||
              this.beneficiaryPersonalDetails[parentKey][key] === undefined ||
              this.beneficiaryPersonalDetails[parentKey][key] === '')) {
              this.missingValueFalg = true;
              break;
            }else if(key === 'state' && this.beneficiaryPersonalDetails[parentKey] && this.beneficiaryPersonalDetails[parentKey]['stateProvinceName']?.length <= 0 && this.beneficiaryPersonalDetails[parentKey]['stateProvinceCode']?.length <= 0 ){
              this.missingValueFalg = true;
              break;
            }
          } else if (key === 'race' && !(this.primaryData.immigrationClassification && this.primaryData.immigrationClassification === "ONLINEIMMVISAREG")) {
            if (!this.beneficiaryPersonalDetails[key] ||
              (this.beneficiaryPersonalDetails[key] && this.beneficiaryPersonalDetails[key].length === 0)) {
              this.missingValueFalg = true;
              break;
            }
          } else if (key === 'immigrationDetails' || key === 'benForeignAddress' || key === 'benCurrentAddress') {
            this.iterateRequiredFileds(this.requiredFields[key], key);
          } else if (this.beneficiaryPersonalDetails[key] && (typeof this.beneficiaryPersonalDetails[key] === 'object')) {
            this.iterateRequiredFileds(this.requiredFields[key], key);
          } else if(key === 'birthState' && this.beneficiaryPersonalDetails['birthStateProvinceName']?.length <= 0 && this.beneficiaryPersonalDetails['birthStateProvinceCode']?.length <= 0){
            this.missingValueFalg =  true;
            break;
          } else {
            // for dirct child within Obj
            if (!['ethnicity','birthState'].includes(key) && obj[key] && (this.beneficiaryPersonalDetails[key] === null ||
              this.beneficiaryPersonalDetails[key] === undefined ||
              this.beneficiaryPersonalDetails[key] === '')) {
              this.missingValueFalg =  true;
              break;
            }
          }
        }
      }
    }
    return this.missingValueFalg;
  }

  updateStepStatus(status) {
    this.store.dispatch(updateStepStatus({
      payload: this.stepStatusUpdatePayloadCostructionService.payloadConstruction(this.stepDetails, status),
      subTaskId: this.stepDetails.taskId,
      visaType: this.primaryData.caseType,
      stepId: this.primaryData.stepId
    }));
  }

  // Toggle controls status based on step status
  toggleControlsStatus() {
    this.disabledControls = this.appServicesLibraryService.formToBeDisabled(this.stepDetails.stepStatusDetails.stepStatusCode);
    // this.disabledControls = this.stepDetails.stepStatusDetails.stepStatusCode === "NEW" || this.stepDetails.stepStatusDetails.stepStatusCode === 'COMPLETE' ||
    //   this.stepDetails.stepStatusDetails.stepStatusCode === 'SUBMIT';
  }

  updateProfile() {
    let a = window.location.href
    if (a.toString().includes('/beneficiary-profile')) {
      window.location.href = window.location.origin + '/beneficiary-profile/profile?prop=BENE&section=1';
    } else {
      let spuoseDetails = this.beneficiaryPersonalDetails.beneficiaryId;
      let relationshipCode = this.beneficiaryPersonalDetails?.relationshipCode;
      if (!spuoseDetails) {
        let beneficiaryId = sessionStorage.getItem('beneficiaryId');
        sessionStorage.setItem('navigationFamilySection', 'true');
        sessionStorage.setItem('listBeneficiaryId', beneficiaryId);

      } else {
        sessionStorage.setItem('listBeneficiaryId', spuoseDetails);

      }
      if (relationshipCode) {
        if (this.beneficiaryPersonalDetails && this.beneficiaryPersonalDetails.id) {

          sessionStorage.setItem('relativeId', this.beneficiaryPersonalDetails.id);
        }
        sessionStorage.setItem('relationshipCode', relationshipCode);
      }
      let url;
      if (this.userType === RolesRouteMap.ATTORNEY) {
        url = [`${RolesRouteMap.userRoleRouteMapping[this.userType]}/attorney/beneficiary/profile`];
      } else {
        url = [`${RolesRouteMap.userRoleRouteMapping[this.userType]}/beneficiaries/profile`];
      }
      sessionStorage.setItem('bene-update-profile', 'true');
      sessionStorage.removeItem('bene-update-experience');
      this.router.navigate(url, { state: { redirectUrl: this.router.url, redirectPage: 'Back to Petition Builder' } });
    }
  }
  handleCountryChange(countryCode: string, stateIndex) {
    // IM-3402 - Added to update state blank for US and India
    if (stateIndex === 'Current') {
      this.listStatesCurrent = [];
    } else if (stateIndex === 'Permanent') {
      this.listStatesPermanent = [];
    } else if (stateIndex === 'Mailing') {
      this.listStatesMailing = [];
    }
    else if (stateIndex === 'Birth') {
      this.listStatesBirth = [];
    }
    if (!countryCode) {
      return;
    }
    // this.editPersonalDetailService.getStates(countryCode).pipe(takeUntil(this.observableSubscription$)).subscribe((states: []) => {
    //   switch (stateIndex) {
    //     case 'Current':
    //       this.listStatesCurrent = states;
    //       break;
    //     case 'Permanent':
    //       this.listStatesPermanent = states;
    //       break;
    //     case 'Foreign':
    //       this.listStatesForeign = states;
    //       break;
    //     case 'Birth':
    //       this.listStatesBirth = states;
    //       break;
    //     case 'Mailing':
    //       this.listStatesMailing = states;
    //       break;
    //   }
    // });
  }

  getStateName(stateProvinceCodes, stateIndex) {
    switch (stateIndex) {
      case 'Current':
        const selectedCurrStateObj = this.listStatesCurrent?.find(x => x.stateProvinceCode === stateProvinceCodes);
        return (selectedCurrStateObj) ? selectedCurrStateObj.stateProvinceName : '--';
      case 'Permanent':
        const selectedPermStateObj = this.listStatesPermanent.find(x => x.stateProvinceCode === stateProvinceCodes);
        return (selectedPermStateObj) ? selectedPermStateObj.stateProvinceName : '--';
      case 'Foreign':
        const selectedFornStateObj = this.listStatesForeign.find(x => x.stateProvinceCode === stateProvinceCodes);
        return (selectedFornStateObj) ? selectedFornStateObj.stateProvinceName : '--';
      case 'Birth':
        const selectedBirthStateObj = this.listStatesBirth.find(x => x.stateProvinceCode === stateProvinceCodes);
        return (selectedBirthStateObj) ? selectedBirthStateObj.stateProvinceName : '--';
      case 'Mailing':
        const selectedMailingStateObj = this.listStatesMailing.find(x => x.stateProvinceCode === stateProvinceCodes);
        return (selectedMailingStateObj) ? selectedMailingStateObj.stateProvinceName : '--';
    }
  }

  ngAfterViewInit(){
    this.translet();    
  }

  translet(){
    setTimeout(() => {
      this.referenceLibraryService.transletConversion();
    }, 600);  
  }

  ngOnDestroy() {
    this.observableSubscription$.next();
    this.observableSubscription$.complete();
  }

}