import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DashboardWidgetsService } from 'src/app/services/dashboard-widgets.service';
import { ToastrService } from 'ngx-toastr';
import { LookupService } from 'src/app/services/lookup-data.service';
import { PetitionerService } from 'src/app/services/petitioner.service';
import { initialConfig } from 'ngx-mask';
import moment from 'moment';
import { ReferenceLibraryService } from 'reference-library';

export interface Food {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'dashboard-processing-time',
  templateUrl: './processing-time.component.html',
  styleUrls: ['./processing-time.component.scss']
})
export class ProcessingTimeComponent implements OnInit {
  serviceCenterNamelist:any;
  selectedserviceCenter:string;
  I29ProcessingTimeDisplayRecords:Object[];
  I29ProcessingTimeRecords:any;
  
  constructor( private lookupService:LookupService,
                private petitionerService:PetitionerService,
                 private referenceLibraryService: ReferenceLibraryService,
             ) {
                this.serviceCenterNamelist = [];
                this.selectedserviceCenter = '';
                this.I29ProcessingTimeDisplayRecords =[];
                this.I29ProcessingTimeRecords =[];
              }

  ngOnInit() {
   this.getAllServiceCenters();
  
  }
  
  loadI29ForServiceCenterName(serviceCenterName) {
    this.getI29ProcessingTimeNotifications(serviceCenterName); 
  }

  getAllServiceCenters() {
    this.lookupService.getServiceCentersList().subscribe((data)=>{
      this.serviceCenterNamelist = data; },
      ()=> { },
      ()=>{
         //default load  with initial record
         this.selectedserviceCenter = this.serviceCenterNamelist[0]
         this.getI29ProcessingTimeNotifications(this.serviceCenterNamelist[0]);
      });
  }
 
  getI29ProcessingTimeNotifications(serviceCenterName) {
    this.selectedserviceCenter = serviceCenterName;//select box ngmodel
    this.petitionerService.getI29ProcessingTimeNotifications(serviceCenterName).subscribe((data)=>{
      this.I29ProcessingTimeRecords =data;

    },
    ()=>{},
    ()=>{
      this.I29ProcessingTimeDisplayRecords = []; //reset
      for (let item of  this.I29ProcessingTimeRecords){
        this.I29ProcessingTimeDisplayRecords.push({
          estimatedTimeRange: item.estimatedTimeRange,
          text:`${item.formNo.name}\n${item.formNo.description}`,
          receiptDateForEnquiry: moment(item.receiptDateForEnquiry).format('Do MMM,YYYY')
        });

      }
     // console.log(this.I29ProcessingTimeDisplayRecords);
     });
  }
   
  splitDate(date,index) {
    let part:[];
    part = date.toString().split(',');
    return part[index];
  }

  splitDateRange(daterange,index) {
    let part:[];
    part = daterange.toString().split('to');
    return part[index];
  }


  ngAfterViewInit() {
    this.translet();
  }

  translet() {
    setTimeout(() => {
      this.referenceLibraryService.transletConversion();
    }, 600);
  }

}//class end



/*
 <h6>
             <strong>H1B speciality Occupation</strong>
           </h6>
           <p class="answer">CVisa to be issued abroad</p>


 this.authenticationService.currentUser.subscribe((data) => {
      this.companyType = data['companyType'];
    });
    this.userType = sessionStorage.getItem('userTypeRole');
    //this.notificationsList();
*/