<div class="container-fluid">
  <div>
    <div class="row d-flex align-items-center">
      <div class="col-md-6 step-title">
        <h5 class="mb-0 common-bene-class-for-translation">Required Documents</h5>
      </div>
      <div class="col-6">
        <button pButton type="button" title="SYNC REQUIRED DOCUMENTS"
          class="p-button-outlined  pull-right" [disabled]="disabledControls" (click)="handleSync()">
         <span class="common-bene-class-for-translation">SYNC REQUIRED DOCUMENTS</span>
        </button>
      </div>
    </div>
    <p-divider></p-divider>
    <!-- <div class="row mb-2">
      <div class="col-8"></div>
      <div class="col-4 pr-0">
          <button pButton type="button" class="p-button-outlined  pull-right btnData"
              [disabled]="disabledControls" pTooltip="SYNC DOCUMENTS" (click)="syncDocuments()">SYNC DATA</button>
      </div>
  </div> -->
    <div class="col-12 pl-0 mb-4">
      <div>
        <h6 class="sub-title font-weight-bold common-bene-class-for-translation">Documents Available in Profile</h6>
      </div>
      <div>
        <p-table tableStyleClass="i130-address-table" [value]="availableDocList" name="availableDocList">
          <ng-template pTemplate="header">
            <tr>
              <th scope="col common-bene-class-for-translation">Document Type</th>
              <th scope="col common-bene-class-for-translation">Document Name</th>
              <th scope="col common-bene-class-for-translation">Actions </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-file>
            <tr>
              <td>
                {{
                file.fileCategory ? (file.fileCategory.code === "OTHER" ?
                (file.fileCtaegoryOtherName ?
                file.fileCategory.name + "-" + file.fileCtaegoryOtherName :
                file.fileCategory.name) : file.fileCategory.name) : '-'
                }}
              </td>
              <td>
                <span *ngIf="!file.isPresent" class="doc-missing"><span class="pi pi-exclamation-triangle pr-2"></span>
                  Document Missing</span>
                <span *ngIf="file.isPresent"><a style="cursor: pointer" (click)="openDoc(file)">
                    {{ file?.fileName }}</a></span>
              </td>
              <td>
                <div *ngIf="!file.isPresent" class="d-flex flex-row documents-icons d-flex align-items-center">
                  <div class="mr-2 padding0">
                    <notification-component-library *ngIf="userType !== 'Beneficiary'"
                      [destination]="notifyDestinationUser" [disabledControls]="disabledControls"
                      [stepDetails]="stepDetails" [primaryData]="primaryData" [item]="file">
                    </notification-component-library>
                  </div>
                </div>
                <div *ngIf="file.isPresent" class="d-flex flex-row documents-icons d-flex align-items-center">
                  <div class="mr-2 padding0">
                    <button *ngIf="userType !== 'Beneficiary'" pButton pRipple type="button" style=" color: #75777A;"
                      title="Send Back" icon="pi pi-directions-alt icons-arrow" class="p-button-rounded p-button-text"
                      pTooltip="Send Back" [disabled]="disabledControls" (click)="handleNotification(file)"></button>
                  </div>
                  <div class="mr-2 padding0">
                    <button pButton type="button" icon="pi pi-eye" pTooltip="View" style=" color: #75777A;" [disabled]="!file.fileLocation"
                      title="View" (click)="openDoc(file)" class="p-button-text p-button-rounded"></button>
                  </div>
                  <div *ngIf="file.fileLocation" class="mr-2 padding0">
                    <button pButton type="button" icon="pi pi-download" pTooltip="Download" style=" color: #75777A;"  (click)="download(file)"
                      title="Download" class="p-button-text p-button-rounded"></button>
                  </div>
                  <div class="mr-2 padding0">
                    <button pButton type="button" icon="pi pi-trash" pTooltip="Delete" title="Delete" style=" color: #75777A;"
                      (click)="deleteDocumentTypeLis(file)" [disabled]="disabledControls"
                      class="p-button-text p-button-rounded"></button>
                  </div>
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
        <!-- <file-upload-library [fileUploadConfigurations]="availableDocFileUploadConfigurations" class="w-100"></file-upload-library> -->
      </div>
    </div>
    <div class="col-12 pl-0">
      <div class="mb-3">
        <h6 class="sub-title font-weight-bold common-bene-class-for-translation">Upload Documents</h6>
      </div>
      <div class="row">
        <file-upload-library [fileUploadConfigurations]="requiredDocFileUploadConfigurations" class="w-100">
        </file-upload-library>
      </div>
    </div>
  </div>
</div>