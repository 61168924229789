import { Component, OnInit } from '@angular/core';
import { FdnsVisitComponent } from '../../compliance/fdns-visit/fdns-visit.component';
import { FdnsService } from 'src/app/services/fdns.service';
import { FDNSQA, FDNSVisit } from 'src/app/data/models/fdns.model';
import { PetitionerService } from 'src/app/services/petitioner.service';
import * as requiredDocPublic from 'src/app/data/constants/public-access.json';
import * as requiredDocPrivate from 'src/app/data/constants/private-access.json';
import { environment } from '../../../../environments/environment';
import { I140FileUploadService } from 'src/app/services/i140-file-upload.service';
import { Router, ActivatedRoute } from '@angular/router';
import { I9EverificationService } from '../../i9-evaluators/i9-everification.service';
import { ToastrService } from 'ngx-toastr';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { Subject } from 'rxjs';
import { ConfirmationService } from 'primeng/api';
import { AnyAaaaRecord } from 'dns';
@Component({
  selector: 'app-i9-employee-details',
  templateUrl: './i9-employee-details.component.html',
  styleUrls: ['./i9-employee-details.component.scss']
})
export class I9EmployeeDetailsComponent implements OnInit {

  lcaList: any[];
  cols: any[];
  i9formsList: any[];
  fdnsVisitList: FDNSVisit[] = [];
  fdnsVisistcols: any[];
  payload: any = {
    "pageNumber": 1,
    "recordsPerPage": 10,
    "searchLocationText": "",
    "visitDate": ""
  }
  fdnsQAList: FDNSQA[] = [];
  totalElementCount: number = 0;
  searchText: string;
  pageSizeOptions = [1, 20, 30];
  pageIndex: number = 0;//default
  pageSize: number = 1;//default
  employeeId: any;
  companyId: any;
  LcaDocuments: any;
  LcaTotalList: any;
  publicAccessList: any[];
  reqDocLCAPublic: any;
  reqDocLCAPublicNew: any;
  privateAccessList: any[];
  reqDocLCAPrivate: any;
  reqDocLCAPrivateNew: any;
  fdnsQaCols: any[];
  activeIndex: number = 0;
  employeeDetails: any;
  beneficiaryId;
  status;
  beneficiaryFName;
  beneficiaryLName;
  documentArraySec1: [];
  documentArraySec2: [];
  documentArraySec3: [];
  observableSubscription$ = new Subject();
  i9cols: { field: string; header: string; }[];
  beneficiaryData: [];
  beneFirstName;
  beneLastName;

  constructor(private fdnsService: FdnsService,
    private i9Service: I9EverificationService,
    private petitionerService: PetitionerService,
    private uploadService: I140FileUploadService,
    private toastr: ToastrService,
    private confirmationService: ConfirmationService,
    private router: Router,
    private activeRoute: ActivatedRoute,) {
    this.employeeId = sessionStorage.getItem("employeeId");
  }

  ngOnInit(): void {

    this.getQueryParams();
    this.getEmployeeDetails();
    this.cols = [
      { field: 'name', header: 'Name' },
    ];
    this.i9cols = [
      { field: 'docType', header: 'Name' },
    ];
    this.fdnsVisistcols = [
      { field: 'visitDate', header: 'Date' },
      { field: 'companyName', header: 'Company Name' },
      { field: 'visitLocationName', header: 'Visit Location' },
      { field: 'visitOfficerName', header: 'Visit Officer' },
      { field: 'modeOfVistName', header: 'Mode Of Visit' },
      { field: 'visitOutcomeStatusName', header: 'Status' }
    ];
    this.fdnsQaCols = [
      { field: 'questionText', header: 'Questions' },
      { field: 'answerText', header: 'Answers' }

    ];
    this.reqDocLCAPublic = (requiredDocPublic as any).default;
    this.reqDocLCAPrivate = (requiredDocPrivate as any).default;
    this.getFdnsVistitList();
    this.getFdnsQAByPetitioner();
    this.getI9FormDetails();
    this.getBeneDetails();
  }

  getQueryParams() {
    this.activeRoute.queryParams
      .subscribe(params => {
        this.beneficiaryId = params.beneficiaryId;
        this.beneficiaryFName = params.beneficiaryFName;
        this.beneficiaryLName = params.beneficiaryLName;
        this.companyId = params.companyId;
      });
  }

  getI9FormDetails() {
    this.i9Service.getUploadedDataForBeneficiary(this.companyId, this.beneficiaryId).subscribe(res => {
      const documents = [...res.data];
      const sectionMap = {};

      documents.forEach(doc => {
        let { sectionName } = doc;
        sectionName = sectionName.replace(/-/g, '').toLowerCase()
        if (!sectionMap[sectionName]) {
          sectionMap[sectionName] = { sectionName, documents: [] };
        }
        sectionMap[sectionName].documents.push(...doc.documents);
      });

      const sectionArrays = Object.values(sectionMap);

      console.log(sectionArrays)

      const targetSection1: any = sectionArrays.find((section: any) => section?.sectionName === 'section1');
      const targetDocuments1 = targetSection1 ? targetSection1?.documents : [];
      this.documentArraySec1 = targetDocuments1;

      const targetSection2: any = sectionArrays.find((section: any) => section?.sectionName === 'section2');
      const targetDocuments2 = targetSection2 ? targetSection2?.documents : [];
      this.documentArraySec2 = targetDocuments2;

      const targetSection3: any = sectionArrays.find((section: any) => section?.sectionName === 'section3');
      const targetDocuments3 = targetSection3 ? targetSection3.documents : [];
      this.documentArraySec3 = targetDocuments3;

      console.log(targetSection1 ,targetSection2 , this.documentArraySec3 )
    });
  }

  getEmployeeDetails() {
    this.fdnsService.getEmployeeI9Details(this.companyId, this.beneficiaryId)
      .subscribe((data) => {
        this.employeeDetails = data;
        if (this.employeeDetails.lcaId) {
          this.getLCAList();
        }
        if (this.employeeDetails.taskId) {
          this.getPrivateAccess();
        }
      });
  }

  getLCAList() {
    this.petitionerService.getLcaDataById(this.employeeDetails.lcaId).subscribe((data) => {
      this.LcaDocuments = data.documents;
      this.LcaTotalList = data.lcaApplication;
      let lcadocument = []
      this.LcaDocuments.forEach(element => {
        lcadocument.push(element.fileCategory.name)
      });
      this.checkRequiredDocument(lcadocument)
    });
  }

  checkRequiredDocument(data) {
    this.reqDocLCAPublicNew = [...new Set(this.reqDocLCAPublic.map(element => element.name).filter(name => !data.includes(name)))];
  }


  getPrivateAccess() {
    this.petitionerService.getLcaDetailsPrivateById(this.companyId, this.employeeDetails.taskId).subscribe((data) => {
      this.privateAccessList = data;
      let privateDocument = []
      this.privateAccessList.forEach(element => {
        privateDocument.push(element.fileCategory.name)
      });
      this.checkRequiredDocumentPrivate(privateDocument)
    });

  }

  checkRequiredDocumentPrivate(data) {
    this.reqDocLCAPrivateNew = [...new Set(this.reqDocLCAPrivate.map(element => element.name).filter(name => !data.includes(name)))];
  }


  getFdnsQAByPetitioner() {
    this.fdnsService.getFdnsQAForPetitioner(this.employeeId, this.beneficiaryId)
      .subscribe((data) => {
        this.fdnsQAList = data ? data : [];
      });
  }

  getFdnsVistitList() {
    this.payload.searchLocationText = this.searchText;
    this.payload.pageNumber = this.pageIndex + 1;
    this.payload.recordsPerPage = this.pageSize;
    this.fdnsService.getFdnsVisitList(this.payload).subscribe((response) => {
      this.fdnsVisitList = response.fdns;
      this.totalElementCount = response.totalNumberOfRecords;
    })
  }


  openLcaDoc(item) {
    const docPath = item.fileLocation.substring(23);
    window.open(`${environment.docs}${docPath}`, '_blank');
  }

  downloadLcaDoc(item) {
    const docPath = item.fileLocation.substring(23);
    this.uploadService.downloadDocuments(`${environment.docs}${docPath}`, item.docName);
  }

  openI9FormDoc(item) {
    const docPath = item.docPath.substring(23);
    window.open(`${environment.docs}${docPath}`, '_blank');
  }

  downloadI9FormDoc(item) {
    const docPath = item.docPath.substring(23);
    this.uploadService.downloadDocuments(`${environment.docs}${docPath}`, item.docName);
  }


  deleteI9aDoc(item) {
    this.i9Service.deleteSingleDocument(item.docId).pipe(takeUntil(this.observableSubscription$))
      .subscribe((response) => {
        this.getI9FormDetails();
        this.toastr.success(response.message);
      });
  }

  confirmSingleDocDelete(item) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete?',
      key: 'deleteSingleDoc',
      accept: () => {
        this.deleteI9aDoc(item);
      },
      reject: () => {
      }
    });

  }

  getBeneDetails() {
    const payload = {
      "pageNumber": "",
      "recordsPerPage": 20,
      "searchCriteria": "",
      "searchText": "",
      "sortBy": "beneficiaryName",
      "sortOrder": "asc",
      "status": ["ACTIVE"]
    }
    this.i9Service.getBeneficiaryList(this.companyId, payload).subscribe(res => {
      this.beneficiaryData = res.beneficiaryList;
    });
  }

  getAllData(beneficiaryDetails) {
    this.fdnsService.getEmployeeI9Details(this.companyId, beneficiaryDetails.beneficiaryEmployeeId)
      .subscribe((data) => {
        this.employeeDetails = data;
        this.beneficiaryFName = beneficiaryDetails.beneficiaryEmployeeFName;
        this.beneficiaryLName = beneficiaryDetails.beneficiaryEmployeeLName;
        this.petitionerService.getLcaDataById(this.employeeDetails.lcaId).subscribe((data) => {
          this.LcaDocuments = data.documents;
          this.LcaTotalList = data.lcaApplication;
          let lcadocument = []
          this.LcaDocuments.forEach(element => {
            lcadocument.push(element.fileCategory.name)
          });
          this.checkRequiredDocument(lcadocument)
        });

        this.i9Service.getUploadedDataForBeneficiary(this.companyId, beneficiaryDetails.beneficiaryEmployeeId).subscribe(res => {
          const documents = [...res.data];
          const sectionMap = {};

          documents.forEach(doc => {
            let { sectionName } = doc;
            sectionName = sectionName.replace(/-/g, '').toLowerCase()
            if (!sectionMap[sectionName]) {
              sectionMap[sectionName] = { sectionName, documents: [] };
            }
            sectionMap[sectionName].documents.push(...doc.documents);
          });

          const sectionArrays = Object.values(sectionMap);

          console.log(sectionArrays)

          const targetSection1: any = sectionArrays.find((section: any) => section?.sectionName === 'section1');
          const targetDocuments1 = targetSection1 ? targetSection1?.documents : [];
          this.documentArraySec1 = targetDocuments1;

          const targetSection2: any = sectionArrays.find((section: any) => section?.sectionName === 'section2');
          const targetDocuments2 = targetSection2 ? targetSection2?.documents : [];
          this.documentArraySec2 = targetDocuments2;

          const targetSection3: any = sectionArrays.find((section: any) => section?.sectionName === 'section3');
          const targetDocuments3 = targetSection3 ? targetSection3.documents : [];
          this.documentArraySec3 = targetDocuments3;

          console.log(targetSection1 ,targetSection2 , this.documentArraySec3 )

        });
        this.getFdnsVistitList();
        this.fdnsService.getFdnsQAForPetitioner(this.employeeId, beneficiaryDetails.beneficiaryEmployeeId)
          .subscribe((data) => {
            this.fdnsQAList = data ? data : [];
          });

        this.petitionerService.getLcaDetailsPrivateById(this.companyId, this.employeeDetails.taskId).subscribe((data) => {
          this.privateAccessList = data;
          let privateDocument = []
          this.privateAccessList.forEach(element => {
            privateDocument.push(element.fileCategory.name)
          });
          this.checkRequiredDocumentPrivate(privateDocument)
        });
      });


  }

  gridView() {
    this.router.navigate(['/petitioner-landing/beneficiaries/list-grid']);
  }

}
