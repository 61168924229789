<div style="position: relative" class="dashboard-regular-card h-100">
  <mat-card class="mat-card text-center rounded h-100" [class.bene-notification]="userType==='Beneficiary'">
    <mat-card-header class="border-bottom">
      <mat-card-title class="common-bene-class-for-translation">Notifications </mat-card-title>
      <app-help-video-overlay #helpVideoComponent *ngIf="isI9Admin !== 'I9Admin'"></app-help-video-overlay>
    </mat-card-header>
    <mat-card-content class="pb-2 pt-2" [ngClass]="{'no-data-found': (!notificationCount || notificationCount === 0) }">

      <div class="table-container">
        <table *ngIf="notificationCount > 0" class="notification-table" mat-table [dataSource]="dataSource" matSort>
          <ng-container matColumnDef="notifications">
            <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
            <td mat-cell *matCellDef="let row">
              <div class="request-container">
                <div class="row mt-2">
                  <div class=" no-border row rounded border pt-2 pb-2 col-12 pl-0 pr-0">
                    <div class="col-8 text-left notify-header px-0">
                      <div class="float-left notify-img"> <img src="../../../assets/star.svg" alt=""></div>
                      <div class="notify-details">
                        <div class="col a-label wordwrap mb-2 pr-0  cursor-pointer"><span
                            (click)="getAlertDetail(row.id)">{{row.title}}</span></div>
                        <div *ngIf="!isI9Admin" class="col material-icon-text wordwrap pr-0"><span
                            class="notification-dec-text">{{row.shortText}}</span></div>
                        <div *ngIf="isI9Admin" class="col material-icon-text wordwrap pr-0"><span
                            class="notification-dec-text">{{row.text}}</span></div>
                        <!-- <div class="col material-icon-text wordwrap pr-0"><span>{{row.data.invoiceId}}</span></div> -->
                      </div>
                      <div *ngIf="row?.data?.isProofOfPayment === true" class=" mt-2 ml-4">
                        <i class="pi pi-paperclip" (click)="showproofofPaymentDialog(row?.data?.invoiceId)"></i><span
                          style="display: inline !important;" class="col material-icon-text wordwrap ml-2 common-bene-class-for-translation">Uploaded
                          proof of payment</span>
                      </div>
                    </div>
                    <div class="col" *ngIf="row.alertTypeCode == 'MEETREMIND' && row.data">
                      <div *ngIf="isI9Admin">
                        <button pButton label="Start call" title="Start call" (click)="joinMeeting(row)"
                          class="p-button-success"></button>
                        <button pButton label="Dismiss" title="Dismiss" class="p-button-danger" (click)="ignoreZoomNotification(row.id)"></button>
                      </div>
                    </div>
                    <div class="col" *ngIf="row['actionable']||false">
                      <!-- <a mat-flat-button *ngIf="isGoTo('connect',row['notificationType']['code'],row['actioned'])"
                      class="material-icons material-icon-text" (click)="connectNow(row)"> -->

                      <!-- <div class="action-icon"> <img src="../../../assets/goto-action-icon.svg" alt="">

                      </div> -->

                      <div *ngIf="row.notificationType.code ==='INVOICE_PAYMENT_DUE'"><button class="updateButton common-bene-class-for-translation"
                          (click)="navigateToInvoicingPage(row.data.invoiceId, row.id)">update</button></div>
                      <div *ngIf="row.notificationType.code ==='CLIENT_UPDATED_PAYMENT_DTL'"><button class="viewButton common-bene-class-for-translation"
                          (click)="navigateToBilling()">view</button></div>
                      <div class="mt-3" style="font-family: 'Source Sans Pro';"
                        *ngIf="row.notificationLink ==='INVOICE_RECEIVED'">
                        <div style="cursor: pointer;"><i class="pi pi-eye"
                            (click)="clientUpdatingPaymentStatus(row)"></i></div>
                        <div class="common-bene-class-for-translation">View Invoice</div>
                      </div>
                      <div>
                        <img src={proofofPaymentImg} alt="">
                      </div>
                      <!-- <div *ngIf="row.notificationType.code ==='INVOICE_RECEIVED'"><button class="updateButton"(click)="clientUpdatingPaymentStatus()">View</button></div> -->
                      <!-- <button *ngIf="row.notificationType.code ==='INVOICE_RECEIVED'" type="button" (click)="showDialog()" icon="pi pi-eye" label="Show"></button> -->

                      <a mat-flat-button
                        *ngIf="row.notificationLink === 'CONNECT_NOW' && isEditLink && !(row.actioned) && !!row.data?.status && row.data.status !== 'ACTV'"
                        class="material-icons material-icon-text" (click)="connectNow(row)">
                        <div class="icon-div">
                          <mat-icon matTooltip="Click to connect now" class="common-bene-class-for-translation">link</mat-icon>
                          <span class="a-label margin1 common-bene-class-for-translation">Connect Now</span>
                        </div>
                      </a>

                      <!-- <a *ngIf="row.notificationType.code !== linkreq" (click)="navigateTask(row['notificationType']['code'],row)">
                      <div class="flex-row go-link">
                        <span>Go To Task</span>
                      </div>
                    </a> -->

                      <button
                        *ngIf="row.notificationLink == 'GOTO_TASK'  && row['notificationType']['code'] !== 'CASE_REQUEST_BY_PETITIONER' && row['notificationType']['code'] !== 'BEN_QUEST_SUBMIT_NOTF' && row['notificationType']['code'] !== 'QUEST_ATTORNEY_NOTF' && row['notificationType']['code'] !== 'QUEST_BEN_NOTF' && row['notificationType']['code'] !== 'UPDATE_NOOFUSERS_NOTF' && row['notificationType']['code'] !== 'PET_DATA_DOCS_SUBMIT_NOTF' && row.notificationLink !=='GOTO_WIZARD_TASK' && row['notificationType']['code'] !=='SIGN_NOTIFICATION' && row['notificationType']['code'] !=='SIGN_SUBMIT_NOTIFICATION' && row['notificationType']['code'] !=='IMG_SIGN_UPLD_NOTIFICATION' && row['notificationType']['code'] !=='DLV_SERVICE_UPLD_NOTIFICATION'"
                        class="btn btn-link noMargin" (click)="navigateTask(row['notificationType']['code'],row)">
                        <span class="common-bene-class-for-translation"> Go To Task </span> 
                      </button>
                      <button p-button *ngIf="row['notificationType']['code'] === 'SIGN_NOTIFICATION'"
                        class="btn btn-link noMargin goToProfile" (click)="navigateToCustomProfile(row)">
                        <span class="common-bene-class-for-translation"> Go to Profile </span> 
                      </button>
                      <!-- CASE_REQUEST_BY_PETITIONER -->
                      <button p-button *ngIf="row['notificationType']['code'] === 'CASE_REQUEST_BY_PETITIONER'"
                        class="btn btn-link noMargin" (click)="navigateToCaseRequest(row)">
                        <span class="common-bene-class-for-translation">  View Details </span>
                      </button>
                      <button p-button *ngIf="row['notificationType']['code'] === 'PET_DATA_DOCS_SUBMIT_NOTF'"
                        class="btn btn-link noMargin" (click)="navigateToCompanyInfo(row)">
                        <span class="common-bene-class-for-translation">  View Details </span>
                      </button>
                      <button *ngIf="row['notificationType']['code'] === 'QUEST_ATTORNEY_NOTF'"
                        class="btn btn-link noMargin" (click)="showMessage(row)">
                        <span class="common-bene-class-for-translation"> View Message </span>  
                      </button>
                      <button
                        *ngIf="row['notificationType']['code'] === 'BEN_QUEST_SUBMIT_NOTF' || row['notificationType']['code'] === 'ECONSENT_NOTIFICATION' && row.notificationLink !=='GOTO_WIZARD_TASK'"
                        class="btn btn-link noMargin" (click)="navigateToProfile(row)">
                        <span class="common-bene-class-for-translation">  View Profile </span> 
                      </button>
                      <span *ngFor="let type of ['SIGN_SUBMIT_NOTIFICATION', 'IMG_SIGN_UPLD_NOTIFICATION', 'DLV_SERVICE_UPLD_NOTIFICATION']">
                        <button  *ngIf="row['notificationType']['code'] === type"
                          class="btn btn-link noMargin"
                          (click)="navigateTask(type, row)">
                          <span class="common-bene-class-for-translation"> Go To Task </span> 
                        </button>
                      </span>
                      <button *ngIf="row.notificationLink === 'GOTO_WIZARD_TASK'" class="btn btn-link noMargin"
                        (click)="navigateTask(row['notificationType']['code'],row)">
                        <span class="common-bene-class-for-translation"> Go To Task </span> 
                      </button>
                      <div
                        *ngIf="['ATTR_BEN_LINK', 'PETNR_CREATE_BENEFICIARY_NOTIF'].includes(row['notificationType']['code']) && !(row.actioned)">
                        <span class="accept mr-2" pTooltip="Accept"
                          (click)="changeStatus(row.data.linkRequestId, 'ACTV', row.id)">
                          <i class="fa fa-check"></i>
                        </span>
                        <span class="ignore" pTooltip="Ignore"
                          (click)="changeStatus(row.data.linkRequestId, 'INAC', row.id)">
                          <i class="fa fa-times"></i>
                        </span>
                      </div>

                      <div
                        *ngIf="row.notificationLink == 'ETA_NOTICE' && !row.data.statusResponse && userType == 'Petitioner'">
                        <button pButton label="Accept" title="Accept" (click)="acceptNotice(row.id)"
                          class="p-button-success"></button>
                        <button pButton label="Ignore" title="Ignore" (click)="ignoreNotice(row.id)"
                          class="p-button-danger"></button>
                      </div>
                      <div *ngIf="row.notificationLink == 'GOTO_LCA_ACKNOWLEDGMENT' && userType == 'Beneficiary'">
                        <button pButton type="button" (click)="viewDetail(row)" matTooltip="View Lca Acknowledge"
                          class="p-button-text">
                          <img src="../../../../assets/lca_ack_icon.svg" alt="">
                        </button>

                      </div>
                      <button *ngIf="row.notificationLink == 'GOTO_I9' && userType === 'Beneficiary'"
                        class="btn btn-link noMargin" (click)="navigateToForms()">
                        <span class="common-bene-class-for-translation"> Go To My Forms </span>
                      </button>
                      <button *ngIf="row.notificationLink == 'GOTO_I9' && userType !== 'Beneficiary'"
                        class="btn btn-link noMargin" (click)="navigateToI9(row)">
                        <span class="common-bene-class-for-translation"> Go To I9 </span>
                      </button>
                      <button *ngIf="row.notificationLink == 'PET_DOC_MISS'" class="btn px-0 btn-link"
                        (click)="navigateToRoute(row)">
                        <span class="common-bene-class-for-translation"> To Requested Documents </span> 
                      </button>

                      <div *ngIf="row.notificationLink == 'LCA_TAKE_DOWN_REMINDER_NOTF' && userType == 'Petitioner'">
                        <button pButton label="Take Down" title="Take Down"
                          (click)="openActionAlertModal(row, 'take down')" class="p-button-success"></button>
                        <button pButton label="Ignore" title="Ignore" class="p-button-danger"></button>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;">
          </tr>
        </table>
        <div *ngIf="!notificationCount" class="text-secondary nodataNotice text-center common-bene-class-for-translation"> {{noDataFoundMsg}}</div>
      </div>
      <!--IM-3396--->
      <!-- <mat-paginator class="align-paginator" [ngClass]="{'hide-paginator': notifications?.length <= 0 }"
        [pageSizeOptions]="[25, 50, 100]"></mat-paginator> -->

    </mat-card-content>
    <mat-card-actions *ngIf="notificationCount > 0">
      <p class="show-more"><a routerLink="../../notifications" class="common-bene-class-for-translation">Show More</a></p>
    </mat-card-actions>
  </mat-card>
</div>

<p-dialog header="Title" [(visible)]="InvoiceDialog">
 <span class="common-bene-class-for-translation"> Content</span>
</p-dialog>