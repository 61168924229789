<div class="mt-4 btn-group row" *ngIf="showFileUploadSection && !modeVal">
  <div class="col-7"></div>
  <div class="col viewType">
    <button class="childBtn firstBtn common-bene-class-for-translation" [class.activebtn]="isTimeLineView" (click)="toggleListView(false)">
      <mat-icon matTooltip="Change to timeline view mode " class="btn-icn">timeline</mat-icon>
      Timeline View
    </button>
    <button class="childBtn lastBtn common-bene-class-for-translation" [class.activebtn]="isListView" (click)="toggleListView(true)">
      <mat-icon matTooltip="Change to list view mode" class="btn-icn">list</mat-icon>
      List View
    </button>
  </div>
</div>
<div class="row" *ngIf="isTimeLineView && educationDetailsList?.length > 0 && showFileUploadSection">
  <app-education-timeline class="col-12" [educationData]="educationDetailsList"></app-education-timeline>
</div>
<div class="add-placeholder" *ngIf="isTimeLineView && educationDetailsList?.length == 0 && showFileUploadSection">
  <button mat-fab color="primary" class="btn" (click)="toggleListView(true)">
    <mat-icon matTooltip="Add education">add_circle_outline</mat-icon>
  </button>
</div>

<div *ngIf="isListView && showFileUploadSection "> 
  <ng-container *ngIf="!isExternalEmployeeId">
  <div class="row">
    <button *ngIf="updateBenProfile && !viewMode && showEditButton" class="btn btn-primary mb-2 common-bene-class-for-translation" type="button" 
      (click)="toggleExpansion(); stepper?.reset();">
      Add / Edit Education
    </button>
  </div>
  <mat-accordion  *ngIf="showEditButton">
    <mat-expansion-panel hideToggle [expanded]="expandPanel">
      <mat-card>
        <div class="row">
          <mat-horizontal-stepper [linear]="isLinear" #stepper labelPosition="bottom"
            class="pb-mat-stepper-custom-horizontal">
            <!--<mat-step [stepControl]="addDegreeCourceForm">-->
            <mat-step [completed]="hasID" *ngIf="iscaseRequest ? (currentItemDisplay.includes('DGRENAME') ? true: (currentItemDisplay.includes('EDUDTLS') ? true: (currentItemDisplay.includes('GRADE') ? true: (currentItemDisplay.includes('STUDYFIELD') ? true: (currentItemDisplay.includes('UNIVERSITYADR') ? true: (currentItemDisplay.includes('UNIVERSITYNAME') ? true: false)))))) : true">
              <form [formGroup]="addDegreeCourceForm">
                <ng-template matStepLabel class="common-bene-class-for-translation">Degree Details</ng-template>
                <!-- <ng-template matStepLabel>Fill out your name</ng-template>
              <mat-form-field>
                <mat-label>Name</mat-label>
                <input matInput placeholder="Last name, First name" formControlName="firstCtrl" required>
              </mat-form-field> -->
                <div class="formBox" [ngClass]="{disabledView: isViewMode}">
                  <div class="row paddingTop10">
                    <div class="col-4" *ngIf="iscaseRequest ? (currentItemDisplay.includes('DGRENAME') ? true: false) : true">
                      <!-- <div class="form-group">
                          <label>Degree</label>
                          <input type="text" class="im-input" [ngClass]="{'im-input-error': name="degree" formControlName="degree"
                            placeholder="Enter" />
                          <span class="invalid-message"
                            *ngIf="(addDegreeCourceForm.get('degree').touched  && addDegreeCourceForm.get('degree').errors?.required)">
                            **Field is required
                          </span>
                        </div> -->
                      <div class="form-group">                        
                        <label class="label-cls common-bene-class-for-translation">Degree</label>
                        <p-dropdown class="w-100" [options]="degreeType" formControlName="degreetype" optionLabel="name" optionValue="id" placeholder="Select Degree" appendTo="body"></p-dropdown>                        
                      </div>
                    </div>
                    <div class="col-4" *ngIf="iscaseRequest ? (currentItemDisplay.includes('STUDYFIELD') ? true: false) : true">
                      <div class="form-group">                        
                        <label class="label-cls common-bene-class-for-translation">Field of study</label>
                          <input class="im-input mw-100" formControlName="fieldOfStudy" type="text" form placeholder="Enter" />                        
                      </div>
                    </div>
                    <div class="col-4" *ngIf="iscaseRequest ? (currentItemDisplay.includes('GRADE') ? true: false) : true">
                      <div class="form-group">
                        <label class="label-cls common-bene-class-for-translation">Grade/CGPA/Percentage</label>
                          <input type="text" class="im-input mw-100" name="grade" formControlName="grade" placeholder="Enter" />                        
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-4" *ngIf="iscaseRequest ? (currentItemDisplay.includes('UNIVERSITYNAME') ? true: false) : true">
                      <div class="form-group">                        
                        <label class="label-cls common-bene-class-for-translation">University Name</label>
                          <input class="im-input mw-100" formControlName="universityName" type="text" form placeholder="Enter" />                        
                      </div>
                    </div>
                  </div>
                  <div class="row" *ngIf="iscaseRequest ? (currentItemDisplay.includes('UNIVERSITYADR') ? true: false) : true">
                    <div class="col-8">
                      <div class="form-group">                        
                        <label class="label-cls common-bene-class-for-translation">Address Line 1</label>
                        <!-- IM-3668 : Incorrect error message reflecting -->
                          <input class="im-input mw-100" maxlength="99" formControlName="addressLine1" type="text" form placeholder="Enter" />                        
                      </div>
                    </div>
                  </div>
                  <div class="row" *ngIf="iscaseRequest ? (currentItemDisplay.includes('UNIVERSITYADR') ? true: false) : true">
                    <div class="col-8">
                      <div class="form-group">
                        <!-- IM-3668 : Incorrect error message reflecting -->
                        <label class="label-cls common-bene-class-for-translation">Address Line 2</label>
                        <input class="im-input mw-100" maxlength="99" formControlName="addressLine2" type="text" form
                          placeholder="Enter" />
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="form-group">
                        <label class="label-cls common-bene-class-for-translation">
                          ZIP / Postalcode
                        </label>
                          <input class="im-input mw-100" formControlName="postCode" type="text" form placeholder="Enter" maxlength="10" />                        
                      </div>
                    </div>
                  </div>
                  <div class="row" *ngIf="iscaseRequest ? (currentItemDisplay.includes('UNIVERSITYADR') ? true: false) : true">
                    <div class="col-4">
                      <div class="form-group">                        
                        <label class="label-cls common-bene-class-for-translation">Country</label>
                          <p-dropdown class="w-100" [options]="listCountry" formControlName="countryCode" optionLabel="countryName" optionValue="countryCode" placeholder="Select" (onChange)="handleCountryChange()" appendTo="body" [filter]="true" filterBy="countryName"></p-dropdown>                        
                      </div>
                    </div>
                    <div class="col-4" *ngIf="listStates.length > 0">
                      <div class="form-group">                        
                        <label class="label-cls common-bene-class-for-translation">State</label>
                          <p-dropdown class="w-100" [options]="listStates" formControlName="stateProvinceCode" optionLabel="stateProvinceName" optionValue="stateProvinceCode" placeholder="Select State" appendTo="body"[filter]="true" filterBy="stateProvinceName"></p-dropdown>                        
                      </div>
                    </div>
                    <div class="col-4" *ngIf="listStates.length <= 0">
                      <div class="form-group">
                        <label class="label-cls common-bene-class-for-translation">State</label>
                          <input class="im-input mw-100" formControlName="stateProvinceName" type="text" placeholder="State" />                        
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="form-group">                        
                        <label class="label-cls common-bene-class-for-translation">City</label>
                          <input class="im-input mw-100" formControlName="city" type="text" form placeholder="Enter" />                        
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-4" *ngIf="iscaseRequest ? (currentItemDisplay.includes('EDUDTLS') ? true: false) : true">
                      <div class="form-group">                        
                        <label class="label-cls d-block common-bene-class-for-translation">Start Year</label>
                          <year-date-picker [yearFormControl]="addDegreeCourceForm.controls.startYear"></year-date-picker>                                                
                      </div>
                    </div>
                    <div class="col-4" *ngIf="iscaseRequest ? (currentItemDisplay.includes('EDUDTLS') ? true: false) : true">
                      <div class="form-group">                        
                        <label class="label-cls d-block common-bene-class-for-translation">End Year</label>
                          <year-date-picker [yearFormControl]="addDegreeCourceForm.controls.endYear"></year-date-picker>
                      </div>
                    </div>
                    <div class="col-4" *ngIf="iscaseRequest ? (currentItemDisplay.includes('EDUDTLS') ? true: false) : true">
                      <div class="form-group">
                        <label class="label-cls d-block common-bene-class-for-translation">Degree Awarded Date</label>
                        <!---IM-3568 -->
                        <mat-form-field appearance="outline" class="mt-0 date-field w-100">
                          <input formControlName="degreeAwardedDate" matInput [min]="endYearValue"
                            [matDatepicker]="degreeAwardedDate" placeholder="MM/DD/YYYY"
                            (click)="degreeAwardedDate.open()" />
                          <mat-datepicker-toggle matSuffix [for]="degreeAwardedDate"></mat-datepicker-toggle>
                          <mat-datepicker #degreeAwardedDate></mat-datepicker>
                        </mat-form-field>
                        <!-- IM-3568  <mat-form-field>
                          <input matInput [matDatepicker]="picker" placeholder="Choose a date"
                            readonly (click)="picker.open()">
                          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                          <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field> :IM-3568  -->                       
                        <!-- //IM-3493 - System accepting Degree awarded date lesser than education end year -->                       
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div *ngIf="beneficiaryType == 'self'">
                      <div *ngFor="let ques of customQuestions?.sectionList">
                        <div *ngIf="ques.name == 'Education Details'">
                          <div class="customSections" *ngFor="let item of ques.stakeHoldersSelected">
                            <div *ngIf="(item?.key == 'QTPROFILEBEN') && item.selected == true"> 
                              <app-custom-questions 
                                [isNewPortion]="false" 
                                [viewQuestions]="false" 
                                [loadedIn]="'Education Details'" 
                                [beneficiaryId]="beneficiaryId" 
                                [isSection]="true"
                                [familyId]="familyId"
                                (refreshList)="onRefreshPage($event)"
                                ></app-custom-questions>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="beneficiaryType !== 'self'">
                      <div *ngFor="let ques of customQuestions?.sectionList">
                        <div *ngIf="ques.name == 'Education Details'">
                          <div class="customSections" *ngFor="let item of ques.stakeHoldersSelected">
                            <div *ngIf="(selectedUser?.dependentType?.code == item.key) && item.selected == true"> 
                              <app-custom-questions 
                                [isNewPortion]="false" 
                                [viewQuestions]="false" 
                                [loadedIn]="'Education Details'" 
                                [beneficiaryId]="beneficiaryId" 
                                [isSection]="true"
                                [familyId]="familyId"
                                (refreshList)="onRefreshPage($event)"
                                ></app-custom-questions>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>                    
                    <hr />
                    <div class="d-flex flex-row-reverse">                      
                      <div class="p-2" *ngIf="iscaseRequest ? (currentItemDisplay.includes('EDUADLCRS') ? true: false) : true">
                        <button class="btn btn-primary common-bene-class-for-translation" matStepperNext
                          [disabled]="!hasID">
                          Next
                        </button>
                      </div>
                      <div class="p-2">
                        <button class="back-button common-bene-class-for-translation" *ngIf="!modeVal" (click)="closeForm()">
                          Close
                        </button>
                      </div>
                      <div class="p-2" *ngIf="iscaseRequest ? (currentItemDisplay.includes('DGRENAME') ? true: (currentItemDisplay.includes('STUDYFIELD') ? true: (currentItemDisplay.includes('GRADE') ? true: (currentItemDisplay.includes('UNIVERSITYNAME') ? true: (currentItemDisplay.includes('UNIVERSITYADR') ? true: (currentItemDisplay.includes('EDUDTLS') ? true: false)))))) : true">
                        <button class="btn btn-success common-bene-class-for-translation" (click)="saveDegreeDetails()" *ngIf="!viewMode">
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </mat-step>
            <mat-step [stepControl]="addRelatedCourceForm" *ngIf="iscaseRequest ? (currentItemDisplay.includes('EDUADLCRS') ? true: false) : true">
              <form [formGroup]="addRelatedCourceForm">
                <ng-template matStepLabel class="common-bene-class-for-translation">Related Courses</ng-template>
                <!--   <ng-template matStepLabel>Fill out your address</ng-template>
             <mat-form-field>
                <mat-label>Address</mat-label>
                <input matInput formControlName="secondCtrl" placeholder="Ex. 1 Main St, New York, NY"
                       required>
              </mat-form-field> -->
                <div class="formBox" [ngClass]="{disabledView: isViewMode}">
                  <div class="row">
                    <label class="common-bene-class-for-translation">List of Courses</label>
                    <!-- <p>Enter all relevant courses taken as part of your degree programme using comma seperate</p> -->
                  </div>
                  <div class="row">
                    <div class="col-10">
                      <mat-form-field appearance="outline" class="example-chip-list chips-contianer w100">
                        <mat-chip-list #chipList aria-label="Course selection">
                          <mat-chip *ngFor="let cource of courses" [selectable]="selectable" [removable]="removable"
                            (removed)="remove(cource)">
                            {{cource.courseName}}
                            <mat-icon matTooltip="Remove this item" matChipRemove *ngIf="removable">cancel</mat-icon>
                          </mat-chip>
                          <input placeholder="Enter Course" [matChipInputFor]="chipList" (paste)="pasteChips($event)"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="add($event)">
                        </mat-chip-list>
                      </mat-form-field>
                      <mat-hint class="hint-text common-bene-class-for-translation">Please enter comma separated values</mat-hint>
                    </div>
                    <div class="col-2 pt-3">
                      <button class="btn btn-success common-bene-class-for-translation" (click)="addCourses()" [disabled]="courses.length === 0"
                        *ngIf="!viewMode">
                        Add
                      </button>
                    </div>
                  </div>
                  <div class="row mt-4" *ngIf="listCourses?.length > 0">
                    <h5 class="common-bene-class-for-translation">Added Courses</h5>
                    <table class="im-card-no-border-table" style="width: 100%">
                      <tbody>
                        <tr class="col-md-12" *ngFor="let course of listCourses; let i = index">
                          <td class="w40">
                            <div class="circleIndex rounded-circle">
                              {{ i + 1 }}
                            </div>
                          </td>
                          <td class="col-md-10">{{ course.courseName }}</td>
                          <td class="col-md-2" (click)="removeAt(i)" style="cursor: pointer">
                            <mat-icon matTooltip="Delete" *ngIf="!viewMode">delete_forever</mat-icon>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <!-- <mat-accordion class="w100">

                      <ng-container *ngFor="let course of listCourses; let i = index;">

                        <mat-expansion-panel>

                          <mat-expansion-panel-header>
                           text  {{course.id}}
                           </mat-expansion-panel-header>
                          <p>{{course.value.courseName}}</p>
                        </mat-expansion-panel>

                    </ng-container>
                      </mat-accordion> -->
                  </div>                  
                  <hr />
                  <div class="row">
                    <div class="col-6">
                      <button class="back-button common-bene-class-for-translation" matStepperPrevious *ngIf="iscaseRequest ? (currentItemDisplay.includes('DGRENAME') ? true: (currentItemDisplay.includes('EDUDTLS') ? true: (currentItemDisplay.includes('GRADE') ? true: (currentItemDisplay.includes('STUDYFIELD') ? true: (currentItemDisplay.includes('UNIVERSITYADR') ? true: (currentItemDisplay.includes('UNIVERSITYNAME') ? true: false)))))) : true">
                        Back
                      </button>
                    </div>
                    <div class="col-6 text-right">
                      <button  *ngIf="!modeVal" class="back-button mr-3 common-bene-class-for-translation" (click)="closeForm(); stepper.reset()">
                        Close
                      </button>
                      <button class="btn btn-success common-bene-class-for-translation" matStepperNext>
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </mat-step>
            <mat-step *ngIf="iscaseRequest ? (currentItemDisplay.includes('EDUADLCRS') ? true: false) : true">
              <ng-template matStepLabel class="common-bene-class-for-translation">Related Documents</ng-template>
              <!-- <ng-template matStepLabel>Done</ng-template>
            <p>You are now done.</p> -->

              <div class="formBox" [ngClass]="{disabledView: isViewMode}">
                <div class="row">
                  <!--  3391 -->
                  <p class="common-bene-class-for-translation">
                    Select the related Degree documents and then click upload to
                    save
                  </p>
                </div>
                <div class="row">
                  <div class="col-12">
                    <app-file-upload *ngIf="!isWizard" (fileUploadSuccess)="fileUploadSuccess()" style="width: 100%"
                      [configurations]="degreeFileUploadConfigurations"></app-file-upload>
                    <file-upload-deafult-library *ngIf="isWizard" class="w-100" [primaryData]="wizardData" [configurations]="degreeFileUploadConfigurations"
                    (fileUploadSuccess)="fileUploadSuccess()">
                    </file-upload-deafult-library>
                  </div>
                </div>               
                <hr />
                <div class="row">
                  <div class="col-4">
                    <button class="back-button common-bene-class-for-translation" matStepperPrevious>Back</button>
                  </div>
                  <div *ngIf="!viewMode" class="col-8 text-right">
                    <button class="back-button mr-3 common-bene-class-for-translation" *ngIf="!modeVal" (click)="closeForm(); stepper.reset()">
                      Close
                    </button>
                    <button class="btn btn-primary common-bene-class-for-translation" matStepperNext (click)="addNewDegree(); stepper.reset();"
                      *ngIf="!viewMode">
                      Add New Degree
                    </button>
                  </div>
                </div>
              </div>

              <!-- <div>
              <button mat-button matStepperPrevious>Back</button>
              <button mat-button (click)="stepper.reset()">Reset</button>
            </div> -->
            </mat-step>
          </mat-horizontal-stepper>
        </div>
      </mat-card>
    </mat-expansion-panel>
  </mat-accordion>
 </ng-container>
  <mat-card class="row">
    <app-list-degree class="col" [isLocked]="isLocked" [educationList]="educationDetailsList" (enableEdit)="editDegreeHandler($event)"
      (removeElement)="deleteDegreeHandler($event)"></app-list-degree>
  </mat-card>
  <!-- <div class="row m-0 p-0">
  <app-list-degree class="col" [educationList]="educationDetailsList" (enableEdit)="editDegreeHandler($event)" (removeElement)="deleteDegreeHandler($event)"></app-list-degree>
</div> -->
</div>