import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ReferenceLibrary } from 'src/app/data/models/referenceLibrary.model';
import { CacheCountryService } from 'src/app/services/cacheCountries.service';
import { ReferenceLibraryService } from 'src/app/services/referenceLibrary.service';
import { select, Store } from '@ngrx/store';
import { AppState } from 'src/app/data/models/app-state';
import * as I9SectionOneSelector  from 'src/app/app-state/selectors/i9-section-one.selector';
import { I9Service } from 'src/app/services/i9-service';
import { ToastrService } from 'ngx-toastr';
import {ConfirmationService} from 'primeng/api';
import { environment } from '../../../../environments/environment';
import * as errorUtils from 'src/app/modules/utility/global-utils';
import moment from 'moment';
import { MatRadioChange } from '@angular/material/radio';
import { acceptedFileTypes } from 'app-models';
import { CustomErrorToastComponent } from 'custom-error-toast';
@Component({
  selector: 'app-i9-form-details',
  templateUrl: './i9-form-details.component.html',
  styleUrls: ['./i9-form-details.component.scss']
})
export class I9FormDetailsComponent implements OnInit {
  i9FormId;
  workAuthTypes: ReferenceLibrary[] = [];
  citizenDocumentTypes: ReferenceLibrary[] = [];
  nonCitizenDocumentTypes: ReferenceLibrary[] = [];
  permDocumentTypes: ReferenceLibrary[] = [];
  alienDocumentTypes: ReferenceLibrary[] = [];

  uploadedDocumentList = [];
  uploadedFile: any;
  listCountry:any = [];
  @Input() isEdit;
  i9WorkAuthPreparerForm: FormGroup;
  category; 
  i9WorkAuthPreparerInfo;
  showSection;
  i9SelectedOption;
  docType;
  currentSelectedDocument;
  companyId;
  selectedAuthorizationProof;
  hasAlienValue:boolean = false;
  hasUscisValue:boolean = false;
  hasi94Value:boolean = false;
  hasPassportValue:boolean = false;
  permRegNum;
  permUscisNum;
  expirationDate = moment().utc().format('MM-DD-YYYY'); 
  acceptedTypes = acceptedFileTypes;
  constructor(
    private referenceLibraryService: ReferenceLibraryService,
    private cacheCountryService: CacheCountryService,
    private store: Store<AppState>,
    private i9Service: I9Service,
    private fb: FormBuilder,
    private confirmationService:ConfirmationService,
    private toastr: ToastrService
    ) { 

      this.i9WorkAuthPreparerForm = this.fb.group({
        workAuthorizationType:  ['', Validators.required],
        workAuthorizationProof: ['',Validators.required],
        isExpirationDtNa: [''],
        expirationDate: [this.expirationDate],
        idNumber:[{value: '', disabled: true},Validators.required],
        countryCode: [{value: '', disabled: true}],
        newVal: [{value: '', disabled: true}],
        newCountryVal: [{value: '', disabled: true}]
      });

      this.i9WorkAuthPreparerForm
      .valueChanges.subscribe((values) => {
        this.showSection = values.i9WorkAuthPreparerForm.workAuthorizationType;
      });

      this.cacheCountryService.getCountry().subscribe();
      this.cacheCountryService.getCachedCountryList().subscribe(data => {
      if (data.listCountries.length > 0) {
        this.listCountry = data.listCountries;
      }
    });

        this.i9WorkAuthPreparerForm.controls['idNumber'].disable();
        this.i9WorkAuthPreparerForm.controls['newVal'].disable();
        this.i9WorkAuthPreparerForm.controls['newCountryVal'].disable();


        this.i9WorkAuthPreparerForm.get('workAuthorizationType').valueChanges.subscribe(val => {
          if (val == 'CITZN' || val == 'NONCITZNNATIONAL') {
            this.i9WorkAuthPreparerForm.get('idNumber').setValue('1');
            this.selectedAuthorizationProof = '';
          }
        });

        this.i9WorkAuthPreparerForm.get('workAuthorizationProof').valueChanges.subscribe(val => {
          if (val == 'FORGNPASSPORTNUM') {
            this.i9WorkAuthPreparerForm.controls['countryCode'].setValidators([Validators.required]);
          } else {
            this.i9WorkAuthPreparerForm.controls['countryCode'].clearValidators();
          }
          
        });
        this.i9WorkAuthPreparerForm.controls['countryCode'].updateValueAndValidity();
    }

  ngOnInit(): void {
    this.referenceLibraryService.getReferenceData('WORKAUTHTYPE').subscribe((response: ReferenceLibrary[]) => {
      this.workAuthTypes = response;    
      this.getWorkAuthInfo();
    });
    
  }

  getRelatedReferencedAndDocs(){
    this.referenceLibraryService.getReferenceData('CITZN').subscribe((response: ReferenceLibrary[]) => {
      this.citizenDocumentTypes = response;  
    });
    this.referenceLibraryService.getReferenceData('NONCITZNNATIONAL').subscribe((response: ReferenceLibrary[]) => {
      this.nonCitizenDocumentTypes = response;  
    });
    this.referenceLibraryService.getReferenceData('PERMRESIDENT').subscribe((response: ReferenceLibrary[]) => {
      this.permDocumentTypes = response;  
    });
    this.referenceLibraryService.getReferenceData('WORKAUTHALIEN').subscribe((response: ReferenceLibrary[]) => {
      this.alienDocumentTypes = response;  
      this.getAllUploadedDocuments();
    });
  }

  ngOnChanges(e: SimpleChanges) {
    if (e.isEdit.currentValue) {
        this.i9WorkAuthPreparerForm.controls['idNumber'].enable();
        this.i9WorkAuthPreparerForm.controls['countryCode'].enable();
        this.i9WorkAuthPreparerForm.controls['newVal'].enable();
        this.i9WorkAuthPreparerForm.controls['newCountryVal'].enable();
    }
    else {
      this.i9WorkAuthPreparerForm.controls['idNumber'].disable();
        this.i9WorkAuthPreparerForm.controls['newVal'].disable();
        this.i9WorkAuthPreparerForm.controls['newCountryVal'].disable();
    }
  }

  handleToggle(type){
    switch(type){
      case 'sectionSwitch':
        this.showSection = this.i9WorkAuthPreparerForm.get('workAuthorizationType').value;
        break;
      case 'citizenDocSwitch':
        this.docType = this.i9WorkAuthPreparerForm.get('workAuthorizationProof').value;
    }
  }

  getGlobalErrorMessages(error: string) {
    return errorUtils.errorMessages.get(error);
  }

  getWorkAuthInfo(){
    this.store.pipe(select(I9SectionOneSelector.selectI9SectionOne)).subscribe( state =>{
      if(state){
        this.i9FormId = state?.i9FormDtlId;
        this.companyId = state?.companyId;
        this.i9WorkAuthPreparerInfo = state.i9WorkAuthPreparerInfo || null;
        if(state.i9WorkAuthPreparerInfo){
          this.showSection = state?.i9WorkAuthPreparerInfo?.workAuthorizationType;
          this.i9WorkAuthPreparerForm.patchValue(state?.i9WorkAuthPreparerInfo);
          this.expirationDate = (this.i9WorkAuthPreparerInfo?.expirationDate) ? moment(this.i9WorkAuthPreparerInfo.expirationDate).format('DD-MM-YYYY') : moment().format('DD-MM-YYYY');
          this.i9WorkAuthPreparerForm.get('expirationDate').setValue(this.expirationDate);

        }else{
          this.showSection = this.workAuthTypes[0];
          this.i9WorkAuthPreparerForm.patchValue(this.workAuthTypes[0]);
        }       
        this.getRelatedReferencedAndDocs();   
       }
       
     });
  }

  getAllUploadedDocuments(){
    this.uploadedDocumentList = [];
    this.citizenDocumentTypes.forEach(item => {
      this.fetchAlreadyUploadedDocument(item);
    });
    this.nonCitizenDocumentTypes.forEach(item => {
      this.fetchAlreadyUploadedDocument(item);
    });
  }

  fetchAlreadyUploadedDocument(item){
    let result = {};
    result = item;
    if(!this.i9FormId) return;

    this.i9Service.fetchUploadedDocument(this.i9FormId, item.group, item.code).subscribe((response) => {
      if (response) {
          result['data'] = response;
          result['exist'] = true;
       }
       else {
          result['exist'] = false;
       }

       let index = this.uploadedDocumentList.findIndex(doc=> doc.code === result['code']);

      if(index < 0){
          this.uploadedDocumentList.push(result);
      }
       
       if(this.uploadedDocumentList.length == this.citizenDocumentTypes.length + this.nonCitizenDocumentTypes.length)
          this.preselectRow();
   }); 
  }

  preselectRow(){
    this.store.pipe(select(I9SectionOneSelector.selectI9SectionOne)).subscribe( state =>{
      if(state){
        if(this.i9WorkAuthPreparerInfo.workAuthorizationType == 'NONCITZNNATIONAL' || this.i9WorkAuthPreparerInfo.workAuthorizationType == 'CITZN'){
          this.uploadedDocumentList.forEach(item=>{
            if(item.code == this.i9WorkAuthPreparerInfo.workAuthorizationProof){
              this.selectedAuthorizationProof = item;
            }
          });
        }
      }
    });
  }

  viewDocument(item) {
    const docPath = item.substring(23);
    window.open(`${environment.docs}${docPath}`, "_blank");
  }
  
confirmDelete(item) {
  this.confirmationService.confirm({
      message: 'Do you want to delete this record?',
      header: 'Delete Confirmation',
      accept: () => {
        this.deleteDocument(item);
        this.getAllUploadedDocuments();
      },
      reject: () => {
        
      }
  });
}

deleteDocument(item){
  this.i9Service.deleteDoc(this.i9FormId,item).subscribe((response: any) => {
    if (response.status == 200) {
      this.toastr.success('File Deleted successfully', 'Success');
      this.i9WorkAuthPreparerForm.get('workAuthorizationProof').setValue('');
      this.getAllUploadedDocuments();
    }
  })
}

onUpload(event, fileUpload, item) {
  for (let file of event.files) {
    this.uploadedFile = file;
    this.uploadedFile["fileName"] = file.name;
  }
  fileUpload.clear();
  let payload = new FormData();

  payload.append("file", this.uploadedFile, this.uploadedFile.name);
  this.i9Service.uploadDocument(this.companyId, this.i9FormId, item.group, item.code ,payload).subscribe((response: any) => {
    if (response.status == 200) {
      this.toastr.success('File Uploaded successfully', 'Success');
      this.getAllUploadedDocuments();
    }
  })
}

resetValue(e:MatRadioChange){
  if(e.value != undefined && this.isEdit){
    this.i9WorkAuthPreparerForm.get('idNumber').setValue('');
    if(this.i9WorkAuthPreparerForm.get('workAuthorizationProof').value != 'FORGNPASSPORTNUM'){
      this.i9WorkAuthPreparerForm.get('countryCode').setValue('');
    }
  }
}

resetParent(e:MatRadioChange){
  if(e.value != undefined && this.isEdit){
    this.i9WorkAuthPreparerForm.get('idNumber').setValue('');
    this.i9WorkAuthPreparerForm.get('workAuthorizationProof').setValue('');
  }
}

handleRowSelect(item,event) {

  if(event.target.localName == 'td' || event.target.localName == 'div'){
    if(!item.exist){
      CustomErrorToastComponent.showErrorToast(this.toastr, 'Please upload relevant document');
    }
    else {
      this.i9WorkAuthPreparerForm.get('workAuthorizationProof').setValue(item.code);
    }
  }
}

  
}
