import { Component, OnInit ,ViewChild} from "@angular/core";
import { DashboardWidgetsService } from "src/app/services/dashboard-widgets.service";
import { AuthenticationService } from "src/app/services/authentication.service";
import { MatTableDataSource} from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import {  MatSort } from '@angular/material/sort';
import { ReferenceLibraryService } from "reference-library";

@Component({
  selector: 'contact-details-attorney',
  templateUrl: './contact-details-attorney.component.html',
  styleUrls: ['./contact-details-attorney.component.scss']
})
export class ContactDetailsAttorneyComponent implements OnInit {

  beneficiaryId: number = 0;
  displayedColumns: string[] = ['attorneyDetails'];
  dataSource: MatTableDataSource<any>;
  attorneyDetails:any = [];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private dashboardWidgetsService: DashboardWidgetsService,
    private authenticationService: AuthenticationService,
     private referenceLibraryService: ReferenceLibraryService,
  ) {
    this.beneficiaryId = sessionStorage.getItem("listBeneficiaryId")
      ? parseInt(sessionStorage.getItem("listBeneficiaryId"))
      : this.authenticationService.currentUserValue["beneficiaryId"];
  }

  ngOnInit() {
    this.dashboardWidgetsService
      .getContactDetailsAttorney(this.beneficiaryId)
      .subscribe((Response) => {
        Response['data'].map((item: any) => {
          let address = item.address.filter(x => (x.type.code === "REGS"));
          item.companyAddress = address[0].addressLine1+' '+address[0].addressLine2+' '+address[0].city+' '+address[0].countryCode.countryName+' '+address[0].postCode
          item.email = item.emailContacts.filter(x => (x.type.code === "PRIM"))[0].email;
          item.phone = item.phoneContacts.filter(x => (x.type.code === "OFFC"))[0].phoneNo;
        });
        this.attorneyDetails = Response['data'];
        this.dataSource = new MatTableDataSource(this.attorneyDetails);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });
  }

  ngAfterViewInit() {
    this.translet();
  }

  translet() {
    setTimeout(() => {
      this.referenceLibraryService.transletConversion();
    }, 600);
  }
}
