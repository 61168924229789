
<div class="bgWhite noMargin w100 bxShadow">
    <div class="row p-4">
        <div class="col-md-12 row border-bottom pb-3">
            <div class="col-md-10">
                <h5 class="m-0 mt-2">What is I-9 Remediation?</h5>
            </div>
            <div class="col-md-2 text-right">
                <button type="button" (click)="initiateNewRemediation()" class="initiate-remediation-button">
                    <i class="fa fa-plus-circle ml-0 p-0 "></i> 
                    <span>Initiate</span>
                </button>
            </div>
        </div>
        
        <div class="col-md-12 row pt-4">
            <div class="col-md-8">
                <p class="m-0 mb-4">Imagility's I-9 Remediation feature is designed to ensure employers comply with the United States Citizenship and Immigration Services (USCIS) requirements for Form I-9, which is used for verifying the identity and employment authorization of individuals hired for employment in the United States. This feature simplifies the process of managing and correcting I-9 documentation, making it easier for employers to adhere to federal regulations and avoid the risk of legal and financial penalties associated with non-compliance.</p>
                <p class="m-0 b-3">
                    The key components of this feature include:
                </p>
                <ul>
                    <li>**Document Verification for Data Remediation**: This involves verifying the accuracy and completeness of I-9 documents. If any discrepancies or errors are found, the system helps correct them to ensure all data is accurate and up-to-date.</li>
                    <li>**Analysis and Generation of Gap Reports**: These reports provide a detailed analysis of the compliance status of each employee. They highlight any missing or incomplete documentation, making it easier for employers to identify and address gaps in compliance.</li>
                    <li>**Iterative Remediation Process**: This is a continuous process that ensures ongoing compliance with USCIS requirements. It involves regularly reviewing and updating I-9 documentation to ensure it remains current and compliant.</li>
                </ul>
                <p class="m-0 b-3">
                    Additionally, the system prioritizes transparency and security, ensuring that employee information is handled securely and that employers have clear visibility into their compliance status. This comprehensive approach helps employers maintain compliance more effectively and reduces the risk of potential penalties.
                </p>
            </div>
            <div class="col-md-4">
                <!-- <img class="remediation-image" src="../../../../../assets/online-complaint.jpg" alt="What is Remediation?" /> -->
                <img class="remediation-image" [src]="imgSrc" alt="What is Remediation?" />
            </div>
        </div>
    </div>
</div>


<!-- Uncomment based on requirement -->
<!-- <div class="container-fluid">
    <div class="row pl--1rem8  d-flex align-items-center">
        <div class="col-md-8 pb-2">
            <small class="link" (click)="backToDashboard()">
                Home > I9 Remediations</small>
            <div class="page-header">
                <h2>Manage Remediations</h2>
                <p> Resolve, view & manage document issues with ease</p>
            </div>
        </div>
        <div class="col-md-4 pb-2 text-right">
            <button type="button" label="Initiate Remediation" (click)="initiateNewRemediation()" class="btn btn-primary mt-0">Initiate Remediation</button>
        </div>
    </div>

    <div class="bgWhite noMargin w100 bxShadow">
        <div class="row">
            <div class="col-md-9">
                <div class="mt-2 row tab-container">
                    <div class="col-4 tab-label tab-height activeI9Records">
                        <div class="lbl-text">All Remediations</div>
                    </div>
                </div>
            </div>

            <div class="col-md-3 mt-3 mb-3">
                <div class="input-group">
                    <input class="form-control py-2 rounded-pill" type="search" #pastDocTable
                        (input)="onSearchChange($event.target.value)" placeholder="Search Beneficiary">
                    <span class="input-group-append">
                        <button class="btn rounded-pill border-0 ml-n4r search-icon-btn" type="button">
                            <i class="fa fa-search"></i>
                        </button>
                    </span>
                </div>
            </div>
        </div>

        <div class="mat-elevation-z8 mt-2">
            <table class="im-card-no-border-table table im-card-table-noborder-spacing table-striped-simple" mat-table
                [dataSource]="dataSource" matSort>

                <ng-container matColumnDef="noOfIterations">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Remediation Iterations </th>
                    <td mat-cell *matCellDef="let row"> {{row.noOfIterations}} </td>
                </ng-container>

                <ng-container matColumnDef="dateTime">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Remediation Date / Time</th>
                    <td mat-cell *matCellDef="let row"> {{row.dateTime}} </td>
                </ng-container>

                <ng-container matColumnDef="noOfEmployees">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header># of Employees</th>
                    <td mat-cell *matCellDef="let row"> {{row.noOfEmployees}} </td>
                </ng-container>

                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
                    <td mat-cell *matCellDef="let row"> {{row.status}} </td>
                </ng-container>

                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Action </th>
                    <td mat-cell *matCellDef="let row">
                        <div class="row">
                            <mat-icon matTooltip="settings menu" class="mr-1 icn settingsBorder"
                                [matMenuTriggerFor]="menu">
                                <mat-icon>settings</mat-icon>
                                <mat-icon>keyboard_arrow_down</mat-icon>
                            </mat-icon>
                            <mat-menu #menu="matMenu">
                                <button mat-menu-item (click)="remediateRecord(row)">Remediate</button>
                                <button mat-menu-item (click)="viewErrors(row)">View Errors</button>
                                <button mat-menu-item (click)="viewDocuments(row)">View Documents</button>
                                <button mat-menu-item (click)="deleteRecord(row)">Delete</button>
                            </mat-menu>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                </tr>
            </table>
            <mat-paginator [pageSizeOptions]="[25, 50, 100]" [pageSize]="pageSize" [length]="totalElementCount"
                (page)="paginationEvent($event)"></mat-paginator>
        </div>
    </div>
</div> -->