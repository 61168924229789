import { Injectable } from '@angular/core';
import { ImagilityBaseResponse } from '../data/models/response.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, pluck, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { EMPTY, Observable, throwError } from 'rxjs';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class QuestionnaireService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  public deletedfile$ = new BehaviorSubject<any>({});

  constructor(private http: HttpClient) { }

  getRequestDataDocuments(data: any) {
    // beneficiaryId=1&companyId=1&immigrationType=asd&petitionType=asd&taskType=ww&visaType=ss
    let tempData;
    if(data.immigrationType==='VISA'){
      tempData = `beneficiaryId=${data.beneficiaryId}&companyId=${data.companyId}&immigrationType=${data.immigrationType}&petitionType=${data.petitionType}&taskType=${data.taskType}&visaType=${data.visaType}`;
    } else {
      tempData = `beneficiaryId=${data.beneficiaryId}&companyId=${data.companyId}&immigrationType=${data.immigrationType}&immigrationCategory=${data.immigrationCategory}&immigrationClassfication=${data.immigrationClassfication}&taskType=${data.taskType}`;
    }
    
    return this.http.get(`/attrorpetnr/qdisplaySections?${tempData}`).pipe(
      map((response: ImagilityBaseResponse) => response.data)
    );
  }

  getPlatformDisplaySection(data: any) {
    // beneficiaryId=1&companyId=1&immigrationType=asd&petitionType=asd&taskType=ww&visaType=ss
    if(!data.taskTypeId){
      data.taskTypeId = 0;
    }
    let tempData;
    if(data.immigrationType ==='VISA'){
      tempData = `immigrationType=${data.immigrationType}&petitionType=${data.petitionType}&taskType=${data.taskType}&visaType=${data.visaType}&taskTypeId=${data.taskTypeId}`;
      if(data?.version){
        tempData += `&version=${data.version}`;
      }
    } else {
      tempData = `immigrationCategory=${data.immigrationCategory}&immigrationClassfication=${data.immigrationClassfication}&taskType=${data.taskType}&taskTypeId=${data.taskTypeId}`;
    }
    
    return this.http.get(`/platformadmin/displaySections?${tempData}`);
  }

  getCustomPlatformDisplaySection(data: any) {
    // beneficiaryId=1&companyId=1&immigrationType=asd&petitionType=asd&taskType=ww&visaType=ss
    let tempData;
    if(data.immigrationType ==='VISA'){
      tempData = `immigrationType=${data.immigrationType}&petitionType=${data.petitionType}&taskType=${data.taskType}&visaType=${data.visaType}`;
    } else {
      tempData = `immigrationCategory=${data.immigrationCategory}&immigrationClassfication=${data.immigrationClassfication}&taskType=${data.taskType}`;
    }
    if(data.taskTypeId){
      tempData = tempData+`&taskTypeId=${data.taskTypeId}`
    }
    return this.http.get(`/platformadmin/displaySections?${tempData}`);
  }

  saveRequestDataDocuments(reqLoad: any, payLoad: any) {
    return this.http.post(`/attor/saveQtsections?benenficiaryId=${reqLoad.beneficiaryId}&companyId=${reqLoad.companyId}`, payLoad).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status && response.status === 200) {
          return response['data'];
        }
      })
    );
  }

  saveQuestionnairePlatformAdmin( payLoad: any) {
    return this.http.post(`/platformadmin/saveQtsections`, payLoad).pipe(
      map((data) => {
        return data;
      })
    );
  }

  sendNotificationAttorney(userId, beneficiaryId, dataLogId:any = null, superTaskId: any = null, skipNotofication: string = null) {
    let slug = !!(dataLogId) ? `?dataLogId=${dataLogId}` : '';
    if(superTaskId){
      slug = slug ? `${slug}&superTaskId=${superTaskId}` : `?superTaskId=${superTaskId}`;
    }
    if(skipNotofication){
      slug = slug ? `${slug}&appName=${skipNotofication}` : `?appName=${skipNotofication}`;
    }
    return this.http.post(`/attorney/notification/${userId}/${beneficiaryId}${slug}`, {}).pipe(
      map((response) => response)
    );
  }

  deletequestionnaireDetails(payload) {
    return this.http.delete('/platformadmin/questionnaire/delete/' + payload).pipe(
      map((data) => {
        return data;
      })
    );
  }

  getPlatformDisplaySectionEdit(tempData) {
    // beneficiaryId=1&companyId=1&immigrationType=asd&petitionType=asd&taskType=ww&visaType=ss 
    return this.http.get(`/platformadmin/getQuestionnaryByPolicyId/${tempData}`).pipe(
      map((data) => {
        return data;
      })
    );
  }

  notifyBeneficiary(payload, data) {
    return this.http.post(`/sendNotificationToBeneficiary/${payload.beneficiaryId}/${payload.companyId}`,data).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        } 
      })
    ); 
  } 

  beneficiaryNotify(payload, data) {
    return this.http.post(`/sendNotificationToBeneficiary/${payload.beneficiaryId}/${payload.companyId}?isBeneficiarySent=${payload.isBeneficiarySent}&userId=${payload.userId}`,data).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        } 
      })
    ); 
  }

  getEconsentMessage(companyId: number){
    return this.http.get(`/econsent/details/company/${companyId}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response.data;
        }
      })
    );
  }

  getEconsent(beneficiaryId: number){
    return this.http.get(`/econsent/details/beneficiary/${beneficiaryId}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response) {
          return response;
        }
      })
    );
  }

  saveEconsent(payload: any){
    return this.http.post(`/beneficiary/econsent/details`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }

}
