import { Component, OnInit, ChangeDetectorRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../services/authentication.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ChangepasswordComponent } from '../changepassword/changepassword.component';
import { UserRoleService } from '../../../services/user-role.service';
import * as RolesRouteMap from 'src/app/data/constants/roles';
import { DashboardWidgetsService } from 'src/app/services/dashboard-widgets.service';
import { JoyrideService } from 'ngx-joyride';
import { VideoTourService } from 'src/app/services/video-tour.service';
import { environment } from 'src/environments/environment';
import { UserService } from 'src/app/services/user.service';
import { CustomButtonActionService } from 'custom-button-action';
import { NgForm } from '@angular/forms';
import { AttorneyService } from 'src/app/services/attorney.service';
import { ToastrService } from 'ngx-toastr';
import { CustomErrorToastComponent } from 'custom-error-toast';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() isHeaderIncluded: boolean;
  isLoggedIn: boolean;
  profileMenu: any;
  userType: string;
  userFN: string;
  userLN: string;
  isAdmin: boolean;
  userInitials = '';
  avatarSrc = '';
  notificationCount: number;
  beneficiaryGuidedTourContent: any[] = [];
  whiiteLabelLogo: string = '';
  universalSearchbar: any = '';
  selectedUsersCodeArr: any[];
  selectedRoles: any;
  @ViewChild('multiSelect') multiSelect: any;
  

  rolebasedProfileMenu = [
    {
      key: 'Beneficiary',
      value: [
        {
          id: 1,
          name: 'Beneficiary Profile',
          path: 'beneficiary-landing/beneficiaries/beneficiary-profile-data/bene-personal-details'
        }
      ]
    },
    {
      key: 'Petitioner',
      value: [
        {
          id: 1,
          name: 'Petitioner Profile',
          path: 'petitioner-landing/companies/profile'
        }
      ]
    },
    {
      key: 'PetitionerAdmin',
      value: [
        {
          id: 1,
          name: 'Petitioner Admin Profile',
          path: ''
        }
      ]
    },
    {
      key: 'Attorney',
      value: [
        {
          id: 1,
          name: 'Attorney Profile',
          path: 'attorney-landing/attorney/attorneys/profile'
        }
      ]
    }
  ];
  isFBUser: boolean;
  showViewProfile: boolean;
  designation: string;
  fbBeneficiaryId: number | null;
  fbEmpId: number | null;
  roleList: string[];
  roleName: string;
  presentApp: string = '';
  users: any = [];
  selectedUsers: any = [];

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private ref: ChangeDetectorRef,
    public changePwdDialog: MatDialog,
    private userRoleService: UserRoleService,
    private dashboardWidgetsService: DashboardWidgetsService,
    private joyride: JoyrideService,
    private videoTourService: VideoTourService,
    private userService: UserService,
    private apiService: CustomButtonActionService,
    private attorneyService: AttorneyService,
    private toast: ToastrService
  ) {
    this.isLoggedIn = false;
    this.profileMenu = [];
    this.notificationCount = 0;
    this.videoTourService.videoTourStarted$.subscribe(value => {
      if (value === 'Beneficiary') {
        this.startVideoTour();
      }
    });
  }

  ngOnInit() {
    var domainName = this.userService.domainName;
    this.authenticationService.logoCalling(domainName).subscribe(data => {
      if (data.data == "" || data.data == undefined) {
        this.whiiteLabelLogo = '';
        return
      }
      if (data.data.logo !== '' || data.data.logo !== null) {
        this.whiiteLabelLogo = 'data:image/jpeg;base64,' + data.data.logo;
      }
    });

    this.authenticationService.currentUser.subscribe((data) => {
      if (data) {
        this.isLoggedIn = true;
        this.userFN = data?.firstname;
        this.userLN = data?.lastName;
        this.userInitials = this.userFN?.substring(0, 1) + this.userLN?.substring(0, 1);
        this.userType = data['userType'];
        this.isFBUser = 'isFBUser' in sessionStorage ? sessionStorage.getItem('isFBUser') === 'true' : false;        
        this.fbBeneficiaryId = this.getSessionStorageNumber('loggedInBeneficiaryId');
        this.fbEmpId = this.getSessionStorageNumber('fbEmpId');
        this.showViewProfile = Boolean(!this.isFBUser || (this.isFBUser && data.fbCreatedUser && this.fbBeneficiaryId && this.fbEmpId));
        this.roleList = 'roleList' in sessionStorage ? JSON.parse(sessionStorage.getItem('roleList')) : [];
        this.roleName = 'roleName' in sessionStorage ? sessionStorage.getItem('roleName') : null;
        if (this.isFBUser) {
          this.designation = 'fbDesignation' in sessionStorage ? sessionStorage.getItem('fbDesignation') : '';
        }
        this.profileMenu = this.rolebasedProfileMenu.find(y => y.key === data['userType']) ?
          this.rolebasedProfileMenu.find(y => y.key === data['userType']).value : '';
        this.ref.markForCheck();
        this.ref.detectChanges();
        // IM-4310
        this.isAdmin = false;
        sessionStorage.setItem("ISADMIN", 'false');
        data['permissions'].forEach(el => {
          if (el === 'ISADMIN') {
            this.isAdmin = true;
            sessionStorage.setItem("ISADMIN", 'true');
          }
        });
      }
      else {
        this.isLoggedIn = false;
        sessionStorage.setItem("ISADMIN", 'false');
        this.isAdmin = false;
        this.ref.markForCheck();
        this.ref.detectChanges();
      }
      this.avatarSrc = ('avatar' in sessionStorage && sessionStorage.getItem('avatar') != 'undefined') ?
        `data:image/jpeg;base64,${sessionStorage.getItem('avatar')}` : '';
    });

    this.userRoleService.profilePicUpdated.subscribe((noUpdateFlag: boolean) => {
      if (!noUpdateFlag) {
        this.avatarSrc = ('avatar' in sessionStorage && sessionStorage.getItem('avatar') != 'undefined') ? `data:image/jpeg;base64,${sessionStorage.getItem('avatar')}` : '';
      }
    });
    this.getBellNotifyCount();

    this.beneficiaryGuidedTourContent = [
      {
        title: 'Building your Profile',
        headerOne: 'A Beneficiary detailed profile can help in the efficient processing of a visa petition. Through Edit Profile on dashboard or through <strong>View Profile</strong> dropdown option next to your profile image.Fill in personal,education, work experience and immigration details here and <strong>Save.</strong>',
        image: '',
        videoLink: 'Building Beneficiary Profile',
        nextButtonText: 'Click Next to continue with the tour',
        watchLabel: ''
      },
      {
        title: 'Working with Timelines',
        headerOne: 'Beneficiaries can view their Petition timeline,Immigration timeline nd project timeline on the dashboard itself. Click on the Petition timeline steps to complete your actions. Track the progress of the petition here!',
        image: '',
        videoLink: 'Using Timelines and Notifications for Beneficiary',
        nextButtonText: 'Our tour ends here!',
        watchLabel: ''
      }];

      this.users = [
        {name: 'Company', code: 'COMP'},
        {name: 'Individual', code: 'INDI'},
        {name: 'Leads', code: 'LEAD'},
    ];
    this.selectedUsers = [... this.users];
  }

  private getSessionStorageNumber(key: string): number | null {
    const value = sessionStorage.getItem(key);
    return value !== null && !isNaN(Number(value)) ? Number(value) : null;
  }

  redirectTo(path: string): void {
    if (!this.isFBUser && path) {
      this.router.navigate([path]);
    } else if (this.isFBUser) {
      sessionStorage.setItem('FbEmployeeId', `${this.fbBeneficiaryId}`);
      this.apiService.onShowBeneficiaryProfile.next({
        pageFrom: 'FB',
        fbEmpId: this.fbEmpId
      });
    }
  }

  startVideoTour() {
    this.joyride.startTour(
      {
        steps: ['beneficiaryFirstStep', 'beneficiarySecondStep@beneficiary-landing/dashboard'],
        waitingTime: 1000
      },

    );
  }

  onEnter(){
    this.universalSearch();
  }

  universalSearch(){
    if(this.universalSearchbar.length < 3){
      CustomErrorToastComponent.showErrorToast(this.toast, 'Please enter a valid search query with at least 3 characters.');
    }
    else {
      if(this.selectedUsers && !this.selectedUsers.length){
        this.selectedUsersCodeArr = ['INDI','LEAD','COMP'];
      }
      else {
        this.selectedUsersCodeArr =this.selectedUsers.map(function(item) {
          return item['code'];
        });
      }
      this.attorneyService.universalSearchSubject.next({ selectedUsers: this.selectedUsersCodeArr, searchText: this.universalSearchbar });
      this.router.navigateByUrl('attorney-landing/attorney/universal-search');
    }
    return false;
  }

  onSelect(event: any): void {
    this.selectedRoles='';
    if ( this.selectedUsers.length===3 || this.selectedUsers.length===0) {
      // this.selectedRoles ="ALL";
    }
    else {
      this.selectedRoles = this.selectedUsers.map(this.trimData.bind(this)).join(', ');
    }
  }

  trimData(item) {
    if(this.selectedUsers.length==1){
      return item.name;
    }
    else {
      return item.name.substring(0,2)+ '..';
    }
  }


  ChangePwdDialogueOpen() {
    //  const cordinates: MouseEventInit = event;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    // dialogConfig.height = 'auto';
    dialogConfig.width = '600px';
    dialogConfig.data = { caption: 'Change Password', expiredCred: false };
    dialogConfig.panelClass = 'changePassword';
    dialogConfig.disableClose = true;
    const dialogRef = this.changePwdDialog.open(ChangepasswordComponent, dialogConfig).afterClosed().subscribe(result => {

    });

  }

  handleNavigationCLick(destination?:string) {
    const landing = RolesRouteMap.userRoleRouteMapping[sessionStorage.getItem('userTypeRole')];
    const url = `${environment.appUrl}/${landing}/${destination}`;
    switch (destination) {      
      case 'notifications':
      case 'settings':  
      case 'help':
        this.router.navigate([`./${landing}/${destination}/`]);
        break;  
      default:
        let presentApp = sessionStorage.getItem('presentApp');            
        switch(presentApp){
          case 'HRMS':
          case 'Talent Aquisition':
          this.router.navigate([`modules-landing/fb/module`]);
          break;  
          case 'Immigration':
            this.router.navigate([`${landing}/dashboard`]);
          break;           

          default:
          this.router.navigate([`${landing}/${destination}`]);
          break;
        }  
    }
  }

  handleLogout() {
    this.router.navigate(['/logout']);
  }

  getBellNotifyCount() {
    this.dashboardWidgetsService.getBellNotifyCount().subscribe(count => {
      this.notificationCount = Number(count);
    });
  }

  ngOnDestroy() {
    this.videoTourService.videoTourStarted$.next(false);
  }



}
