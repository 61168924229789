<div class="page-caption row ml-4 mr-4">
    <div class="col-md-8 px-0">
        <h5 class="mb-0">Manage Case Status</h5>
        <small>Manage existing case status</small>
    </div>
    <div class="col-md-4 px-0 justify-content-end d-flex align-items-center">
        <button class="btn btn-primary mr-2" (click)="updateCaseStatus()">Update Case Status</button>
        <a target="_blank" href="https://egov.uscis.gov/">View Case Status</a>
    </div>
</div>
<div class="row ml-4 mr-4">
    <div class="mat-elevation-z8 mt-3 manage-case-status">
    <div class="row py-0">
        <div class="col-md-2 pt-3 pb-3">
            <h6 class="pl-4">List of Cases</h6>
        </div>
        <div class="col-md-2 pt-3 pb-3 checkbox-inline">
            <input type="checkbox" id="checkedStatus" [(ngModel)]="checkedStatus" name="checkedStatus" (change)="getCheckedData()">
            <label for="checkedStatus">Missing Case Status</label>
        </div>
        <div class="col-md-8 pt-3 pb-3 justify-content-end d-flex">
            <div class="container-fluid row">
                <div class="col-md-5">
                    <div class="filter-manage-case-status">
                        <p-multiSelect 
                        [showToggleAll]="true"
                        [displaySelectedLabel]="true"
                        [options]="companyList"
                        [(ngModel)]="selectedCompanies"
                        (onChange)="onCompanySelectionChange()"
                        optionLabel="companyName"
                        placeholder="All"
                        [filter]="true"
                        display="chip"
                      >
                      <ng-template pTemplate="header">
                        <div class="checkbox-all-text">All</div>
                      </ng-template>
                      <ng-template let-selectedItems pTemplate="selectedItems" >
                        <span > {{selectedCompaniesText}}</span>
                      </ng-template>
                    
                      </p-multiSelect>
                    </div>
                </div>
                <div class="col-md-7">
                    <div class="search-btn">
                        <div class="input-group search-text">
                            <input class="form-control im-pt-3 pr-2rem" #searchCaseStatusListTable  type="search"
                            placeholder="Search Case By Receipt Number / Beneficiary Name / Visa Type / Service Centre / Short Status">
                            <span class="input-group-search-icon">
                            <button class="btn rounded-pill" title="search" type="button">
                                <i class="fa fa-search border-0" alt=""></i>
                            </button>
                            </span>   
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <p-table [columns]="cols" class="pa-table im-card-no-border-table im-card-table-noborder-spacing table"
         styleClass="p-datatable-gridlines" [value]="caseStatusList" dataKey="id">
        
    <ng-template pTemplate="header" let-columns>
        <tr>
            <th style="width: 1.5rem;padding: 0 !important;"></th>
            <th *ngFor="let col of columns" [style.width]="col.width" [attr.class]="col.cssClass">
                {{ col.header }}
            </th>
            <th width="10%" class="text-center">Actions</th>
        </tr>
    </ng-template>

    <ng-template pTemplate="body" let-case let-columns="columns">
        <tr>
            <td></td>
            <td *ngFor="let col of columns" class="text-center">
                <!-- If it's the merged column, display both shortStatus and updatedOn -->
                <div *ngIf="col.field === 'shortStatusUpdatedOn'" class="d-flex align-items-center justify-content-center">
                    <span class="case-short-status text-right mb-1">
                        {{ case.shortStatus }} 
                        <br/>
                        <a *ngIf="case.shortStatus" href="javascript:void(0)" (click)="showUSCISDialog(case)"  pTooltip="View Status Summary" class="popup-link">Details</a>
                    </span>
                    <small class="text-secondary time-sec text-left">
                        {{ case.updatedOn ? (case.updatedOn | date: 'dd MMM yyyy') : '' }} 
                        <br/> 
                        {{ case.updatedOn ? (case.updatedOn | date: 'hh:mm a') : '' }}
                    </small>
                </div>
            
                <!-- Default column rendering -->
                <span *ngIf="col.field !== 'shortStatusUpdatedOn'">
                    {{ case[col.field] }}
                </span>
            
                <!-- Show extra text if visaType or immClassification is missing -->
                <small *ngIf="['visaType','immClassification'].includes(col.field) && !case.taskId" class="text-secondary d-block">
                    (Petition not filed in Imagility)
                </small>
            </td>            

            <td>
                <div class="d-flex justify-content-center">
                    <button pButton type="button" aria-label="Edit" icon="pi pi-pencil" 
                            (click)="editCase(case, case.id)" pTooltip="Edit" class="p-button-text">
                    </button>
                </div>
            </td>
        </tr>
    </ng-template> 

    <ng-template pTemplate="emptymessage">
        <tr>
            <td [attr.colspan]="9" class="text-center">
                No Data Available
            </td>
        </tr>
    </ng-template>

</p-table>
    <p-paginator [rows]="pageSize" (onPageChange)="paginationEvent($event)" *ngIf="this.caseStatusList?.length > 0"
        [totalRecords]="totalElementCount" [rowsPerPageOptions]="pageSizeOptions" length="totalElementCount">
    </p-paginator>
    </div>
</div>

<p-dialog [(visible)]="display" [modal]="true" [style]="{width: '43vw'}"
    [draggable]="false" [resizable]="false">

    <ng-template pTemplate="header">
      <div class="w-100">
        <div class="row w-100">
            <div class="col-6 p-0 m-0">
                <h6 class="case-heading"><b>USCIS Case Status</b></h6>
            </div>       
        </div>
        
      </div>     
    </ng-template>
    
    <div class="divider mb-4"></div>

    <div class="row">
        <div class="col-6 p-0 m-0">
          <h5 class="shortcase">{{ shortCaseData }}</h5>
        </div> 
        <div class="col-6 p-0 m-0 text-right">
            <h6 class="date-sec">Updated on: {{ caseUpdatedTime ? (caseUpdatedTime | date: 'hh:mm a') : '' }} {{ caseUpdatedTime ? (caseUpdatedTime | date: 'dd MMM yyyy') : '' }}</h6>
        </div>
    </div>
    <div class="row">
        <div class="col-12 p-0 m-0">
            <h6 class="detail-sec">{{receiptNumberContent}}</h6>
        </div>
    </div>

    <div class="divider mt-2 mb-3"></div>

    <div class="row d-flex">
      <button type="submit" style="padding: 0px 22px !important; height:31px!important" class="btn btn-primary pull-left" (click)="cancelDialog()">Okay</button>
    </div>
</p-dialog>