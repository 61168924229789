<div class="fluid-container">
  <div class="row pt-4 d-flex align-items-center" *ngIf="!simplifiedH1b">
    <div class="col-md-8 step-title">
      <h5 class="mb-0">Await Lottery Selection</h5>
    </div>
    <div class="col-md-4 editBtn headerSubPortion px-0"> 
      <ul>
        <li class="col-auto px-0 firstPortion"> 
          <lib-step-status-update-component-library
      [visaType]="inputData.caseType != 'I130' ? inputData.visatype : inputData.caseType" [stepDetails]="stepDetails"
      type="EDIT" class="float-right" class="float-right" [isViewMode]="this.inputData['isViewMode']" [isCaseRequest]="this.inputData['isCaseRequest']">
      </lib-step-status-update-component-library>
        </li>
        <li class="col-auto pr-0 pl-2 secondPortion">
          <lib-maximize-dialog-library [configData]="{'layoutCode':inputData?.layoutCode}"></lib-maximize-dialog-library>
      <i (click)="handleClose();" class="close-icon pi pi-times pull-right"></i>
        </li>
      </ul>
    </div>
  </div>
  <div class="row" *ngIf="!simplifiedH1b">
    <div class="col-md-12 p-0">
      <lib-assigning-task-library [visaType]="inputData.visatype" [stepDetails]="stepDetails"
      (updateStatus)="updateStepStatus($event)" [isViewMode]="this.inputData['isViewMode']" [isCaseRequest]="this.inputData['isCaseRequest']">
      </lib-assigning-task-library>
    </div>
  </div>
  <p-divider *ngIf="!simplifiedH1b" class="header-divider"></p-divider>
  <form (ngSubmit)="postAwaitLottery()" #awaitLotteryForm="ngForm" class="pb-3">
    <div class="main-body footer-adjust-v2">
      <lib-last-update-library *ngIf="!simplifiedH1b" [inputDetails]="stepDetails" [primaryData]="inputData" sectionCode="AWAIT_LOTTERY">
      </lib-last-update-library>
      <div class="row mb-3">
        <div class="col-md-4 form-group processing-dropdown">
          <label class="label-cls required"> Response </label>
          <p-dropdown [options]="statusTypeList" [filter]="false" name="responseCode" [(ngModel)]="responseCode"
            optionLabel="stepResponseName" optionValue="stepResponseCode" [disabled]="disabledControls"
            placeholder="Select Status" required appendTo="body">
          </p-dropdown>
          <div
            *ngIf="awaitLotteryForm.controls['responseCode'].touched && awaitLotteryForm.controls['responseCode'].invalid">
            <span class="invalid-message" *ngIf="awaitLotteryForm.controls['responseCode'].errors?.required">
              {{getGlobalErrorMessages('REQUIRED')}}
            </span>
          </div>
        </div>
        <div class="col-md-4 form-group input-disabled" *ngIf="!simplifiedH1b">
          <label class="label-cls required"> Beneficiary Confirmation Number</label>
          <input type="text" name="receiptNumber" class="im-input  mw-100" disabled [(ngModel)]="receiptNumber" [readOnly]="disabledControls" pInputText required />
          <div
            *ngIf="awaitLotteryForm.controls['receiptNumber'].touched && awaitLotteryForm.controls['receiptNumber'].invalid">
            <span class="invalid-message" *ngIf="awaitLotteryForm.controls['receiptNumber'].errors?.required">
              {{getGlobalErrorMessages('REQUIRED')}}
            </span>
          </div>
        </div>
        <div class="col-md-4 form-group input-disabled" *ngIf="simplifiedH1b">
          <label class="label-cls required"> Beneficiary Confirmation Number </label>
          <input type="text" name="receiptNumber" class="im-input  mw-100"  [(ngModel)]="receiptNumber"
             pInputText required />
          <div
            *ngIf="awaitLotteryForm.controls['receiptNumber'].touched && awaitLotteryForm.controls['receiptNumber'].invalid">
            <span class="invalid-message" *ngIf="awaitLotteryForm.controls['receiptNumber'].errors?.required">
              {{getGlobalErrorMessages('REQUIRED')}}
            </span>
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-8 form-group input-disabled">
          <label class="label-cls"> Reason </label>
          <textarea pInputTextarea name="responseNote" class="im-input  mw-100" [(ngModel)]="responseNote"
            [readOnly]="disabledControls"></textarea>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <h6>Upload Selection Receipt</h6>
        </div>
      </div>
      <div class="row mb-2">
        <file-upload-library style="width: 100%;" [fileUploadConfigurations]="fileUploadConfigurations">
        </file-upload-library>
      </div>
      <p-divider type="dashed"></p-divider>
      <div class="row">
        <div class="col-md-3 decision-check">
          <p-checkbox value="Notify Beneficiary" binary="true" [(ngModel)]="isBeneficiaryNotify"
            name="isBeneficiaryNotify" [disabled]="disabledControls" label="Notify Beneficiary"></p-checkbox>
        </div>
      </div>
    </div>
    <p-divider type="dashed"></p-divider>
    <div *ngIf="!simplifiedH1b" class="row" [ngClass]="{hideView : (inputData['isViewMode'] ||inputData['isCaseRequest'] ) && userType =='Attorney' }">
      <div class="col-md-2">
        <button pButton type="submit" label="Save" class="save-btn"
          [ngClass]="awaitLotteryForm.invalid  || disabledControls ? 'btn-success button-width-icon-left mr-3 btn-success_disabled':'btn-success button-width-icon-left mr-3'"
          [disabled]="awaitLotteryForm.invalid  || disabledControls"></button>
      </div>
      <div class="col-md-2">
        <button pButton type="button" label="Cancel"
          class="p-button-secondary cancel-btn button-width-icon-left d-flex alogn-items-center"
          [disabled]="disabledControls" (click)="handleCancel()">
        </button>
      </div>
      <div class="col-md-2"></div>
      <div class="col-md-6 completeStep">
        <lib-step-status-update-component-library [isViewMode]="this.inputData['isViewMode']" [isCaseRequest]="this.inputData['isCaseRequest']"
          [visaType]="inputData.caseType != 'I130' ? inputData.visatype : inputData.caseType"
          [stepDetails]="stepDetails" [type]="typeOfButton" [showPreviousStepBtn]="true" [showNextStepBtn]="true" class="d-flex col-12 justify-content-end"></lib-step-status-update-component-library>
      </div>
    </div>
    <div class="row">
      <div class="col-md-9"></div>
      <div *ngIf="simplifiedH1b" class="col-md-3 alignRight">
        <button pButton type="submit" label="Save & Continue" class="save-btn"
          [ngClass]="awaitLotteryForm.invalid  || disabledControls ? 'btn-success button-width-icon-left mr-3 btn-success_disabled':'btn-success button-width-icon-left mr-3'"
          [disabled]="awaitLotteryForm.invalid  || disabledControls"></button>
      </div>
    </div>
  </form>
</div>
