<h5 mat-dialog-title>
    <div class="col-md-12 p-0 row delete-header">
        Guidance on Maintaining Folder Structure for I-9 Remediation Process
    </div>
    <button mat-icon-button (click)="onDismiss()">
        <mat-icon>close</mat-icon>
    </button>
</h5>
<div class="dialog-sec" mat-dialog-content>
    <p>To ensure proper organization and easy access during the I-9 Remediation process, it's important to maintain a consistent and clear folder structure either on Google Drive or AWS Share path location. Follow the step-by-step guide below:</p>
    
    <h2 class="step-sec">Step 1: Create Company Folder</h2>
    <h3 class="step-header">Create a Root Folder:</h3>
    <p>Name the root folder with the company's name.</p>
    <p><strong>Example:</strong> CompanyABC</p>
    
    <h2 class="step-sec">Step 2: Create Employee Folders</h2>
    <h3 class="step-header">Create Folders for Each Employee:</h3>
    <p>Within the company folder, create individual folders for each employee.</p>
    <p>Name each folder using the employee’s first name and last name.</p>
    <p><strong>Example:</strong> John Doe, Jane Smith, etc.</p>
    
    <h2 class="step-sec">Step 3: Organize Documents within Employee Folders</h2>
    <h3 class="step-header">Save I-9 Documents:</h3>
    <p>Inside each employee's folder, save the I-9 form and supporting documents.</p>
    <p>Ensure documents are clearly named for easy identification.</p>
    <p><strong>Example:</strong> I-9.pdf, Passport.pdf, DL.pdf, etc.</p>
    
    <!-- <h2>Step 4: Maintain Security</h2>
    <h3>AWS Shared Path:</h3>
    <ul>
        <li>Go to the Permissions tab of the company root folder.</li>
        <li>Set bucket policies or IAM policies to restrict access to authorized users only.</li>
    </ul> -->
    
    <h3 class="step-header">Example Folder Structure:</h3>
    <pre>
CompanyABC
├── JohnDoe
│   ├── I-9.pdf
│   ├── Passport.pdf
│   ├── DL.pdf
│   └── EAD.pdf
├── JaneSmith
│   ├── I-9.pdf
│   ├── Passport.pdf
│   ├── SSN.pdf
│   └── I-94.pdf
    </pre>
    
    <h2 class="step-sec">Step 5: Update and Review Regularly for every new run of remediation process</h2>
    <h3 class="step-header">Regular Updates:</h3>
    <p>Regularly update the folders with any new or revised documents.</p>
    <p>Ensure the structure remains organized and up to date.</p>
    <h3 class="step-header">Periodic Reviews:</h3>
    <p>Conduct periodic reviews of the folder structure to ensure compliance and organization.</p>
    <p>Remove outdated documents as necessary.</p>
    
    <p>By following this structured approach, you can efficiently manage I-9 and supporting documents, ensuring a smooth remediation process and maintaining compliance with USCIS requirements.</p>
</div>

<div mat-dialog-actions class="float-right">
    <button mat-stroked-button color="primary" (click)="onDismiss()">Close</button>
</div>

<div class="clearfix"></div>